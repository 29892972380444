import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom';
import Api from "../../../common/APIUtils"
import '../../../styles/forms.css';
import '../../../styles/components.css';
import { getMetricDisplayValue } from '../../../common/Helpers';
import { formatDistanceToNow, isAfter, format, isValid, addDays } from 'date-fns'
import { BsCalendarEvent, BsArrowRight } from 'react-icons/bs';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar } from 'react-date-range';

export default function NewKeyResult({workspaceId}) {
    const api = new Api();
    let history = useHistory();   
    const dateSelector = useRef()
    const [name, setName] = useState(() => { return "" })
    const [scope, setScope] = useState(() => { return 'team' })
    const [type, setType] = useState(() => { return 'manual' })
    const [page, setPage] = useState(() => { return 1 })
    const [description, setDescription] = useState(() => { return "" })
    const [valTarget, setValTarget] = useState(() => { return 0 })
    const [chosenMetricId, setChosenMetricId] = useState(() => { return "" })
    const [isShowingdueDatePicker, setIsShowingdueDatePicker] = useState(() => { return false })
    const [dueDate, setdueDate] = useState(() => { return addDays(new Date(), 30) })
    const [projectIdAlignment, setProjectIdAlignment] = useState(() => { return "" })
    const [potentialParents, setPotentialParents] = useState(() => { return [] })
    const [potentialChildren, setPotentialChildren] = useState(() => { return [] })
    const [linkableKpis, setLinkableKpis] = useState(() => { return [] })
    const [parentsLoaded, setParentsLoaded] = useState(() => { return false })
    const getAdditionalScopeClass = (scopeName) => {
        let additionalClassIfSelected = "form-network-create-form-section-radio-item-selected"
        if (scope === scopeName ) return additionalClassIfSelected
        return ""
    }
    const publishKeyResult = () => {
        let payload = {
            workspace_id: workspaceId,
            display_name: name,
            is_published: true
        }
        let attributes = {
            type: type,
            date_due: format(dueDate, 'yyyy-MM-dd')
        }
        if (description) attributes['description'] = description
        if (type === 'manual') {
            attributes['val_start'] = 0
            attributes['val_target'] = 100
        }
        if (type === 'kpi' && getChosenMetricData()) {
            attributes['val_start'] = getChosenMetricData().attributes.val_current
            attributes['val_target'] = valTarget
        } 
        if (projectIdAlignment && scope === 'project') payload['proposed_parent_ids'] = [projectIdAlignment]
        payload['attributes'] = attributes
        const path = `/components/plan/kresult`;
        if (name !== "") {
            api.createWorkspaceComponent(payload, path)
            .then( (res) => {
                if (res.status === 200) {
                    createBond(res.data.response.component._id)
                    history.push(`/workspaces/${workspaceId}/c/${res.data.response.component._id}`);
                }
            })
            .catch((err) => {
                console.log(err);
                alert(err);
            })
        }
    }
    const createBond = (newComponentId) => {
        if (type !== 'kpi' || !getChosenMetricData()) return
        let payload = {
            workspace_id: workspaceId,
            child_id: chosenMetricId,
            parent_id: newComponentId
        }
        api.createBond(payload)
        .then((res) => {
            return
        });
    }
    const handleCodeForNameType = (e) => {
        if (e.code.toLowerCase() === 'enter') {
            setPage(2)
        }
    }
    const getAdditionalTypeClass = (typeName) => {
        let additionalClassIfSelected = "form-network-create-form-section-radio-item-selected"
        if (typeName === type ) return additionalClassIfSelected
        return ""
    }
    const advancePage = () => {
        setPage(page + 1)
    }
    const retreatPage = () => {
        setPage(page - 1)
    }
    const getPageTitle = () => {
        if (name === "") return "New Goal"
        return name
    }
    const fetchPotentialParents = () => {
        api.getPotentialAlignmentsBasedOnType({type: 'kresult', workspaceId: workspaceId})
        .then((res) => {
            if (res.data) {
                setPotentialParents(res.data.response.potential_parents)
                setPotentialChildren(res.data.response.potential_children)
                setParentsLoaded(true)
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }
    const getChosenMetricData = () => {
        if (!chosenMetricId || chosenMetricId === "") return false
        return potentialChildren.find(a => a._id === chosenMetricId)
    }
    const getMetricUnits = () => {
        if (!chosenMetricId) return false
        const metricData = getChosenMetricData()
        if (!metricData.attributes) return false
        if (!metricData.attributes.units || metricData.attributes.units === "") return false
        return metricData.attributes.units
    }
    const getFormattedChosenMetric = () => {
        const metricData = getChosenMetricData()
        if (!metricData) return
        return getMetricDisplayValue(metricData)
    }
    const getDescriptionRowCount = () => {
        const MIN_ROW_COUNT = 4;
        // assume roughly 50 characters per row
        const breaks = description.split(/\r\n|\r|\n/).length + 1
        const rowsFromCharacters = Math.round(description.length/50) + 1
        return Math.max(breaks, rowsFromCharacters, MIN_ROW_COUNT);
    }
    const getScopeText = () => {
        if (scope === 'team') return "Creates a key result for the entire workspace.  Ideal for measuring items that apply to your entire team."
        if (scope === 'project') return "Creates a key result for a single project.  Great for tracking project-specific measurements and outcomes."
    }
    const getScopeTextExamples = () => {
        if (scope === 'team') return "Examples: Customer Acquisition Cost (CAC), Gross Profit Margin, Utilization Rate"
        if (scope === 'project') return "Examples: Total Project Cost, Cost per Lead, Social Media Engagement Rate"
    }
    const getTimelineText = () => {
        if (!isValid(dueDate)) return
        if (!isAfter(dueDate, new Date())) return
        return `Total time: ${formatDistanceToNow(dueDate)}`
    }
    const getTypeText = () => {
        if (type === 'kpi') return "Progress is based on a goal set for an existing KPI"
        if (type === 'manual') return "Progress is based on a percentage you manually update (0% - 100%)"
    }
    const updateDueDate = (newStartDate) => {
        setIsShowingdueDatePicker(false)
        setdueDate(newStartDate)
    }
    const showStartDatePicker = () => {
        setIsShowingdueDatePicker(true)
    }
    const getFancyDateString = (dateString) => {
        if (!isValid(dateString)) return 'Choose a date'
        return format(dateString, 'MMM do, yyyy')
    }
    const hasProjects = () => {
        if (!parentsLoaded) return false
        if (potentialParents.length === 0) return false
        return true
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dateSelector.current && !dateSelector.current.contains(event.target)) {
                setIsShowingdueDatePicker(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [dateSelector])
    useEffect(() => {
        fetchPotentialParents()
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (scope === 'project' && !hasProjects()) setScope('team')
    // eslint-disable-next-line
    }, [scope])
    useEffect(() => {
        if (!potentialChildren || potentialChildren.length === 0) return
        const copyOfChildren = Array.from(potentialChildren)
        const justKpis = copyOfChildren.filter(a => a.name === 'kpi')
        setLinkableKpis(justKpis)
    // eslint-disable-next-line
    }, [potentialChildren])
    return (
        <div className="network-page">
            <div className="network-page-header-row">
                <div className="network-page-header-text">
                    {name !== "" && <p>New Goal:</p>}
                    {name === "" && <p style={{color: "transparent"}}>New Goal</p>}
                    <h1>{getPageTitle()}</h1>
                    <p>Set specific, measurable, and attainable targets for items you're measuring</p>
                </div>
            </div>

            <div className="form-network-create-form-container">

                {page === 1 &&
                <>
                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-section-label">What do you want to accomplish?</div>
                    <div className="form-network-create-form-section-input">
                        <input maxLength={120} autoFocus={true} style={{fontSize: "14px"}} value={name} onKeyPress={(e) => handleCodeForNameType(e)} onChange={(e) => setName(e.target.value)} type="text" placeholder="Example: Improve landing page conversion rate" />
                    </div>
                </div>
                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-section-label">Add a description:</div>
                    <div className="form-network-create-form-section-input">
                        <textarea rows={getDescriptionRowCount()} placeholder="Additional details for this key result" value={description} onChange={(e) => setDescription(e.target.value)} style={{fontSize: "14px"}}></textarea>
                    </div>
                </div>
                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-section-label">By when?</div>
                    <div className="form-network-create-form-section-row" style={{gap: "14px", boxSizing: "border-box"}}>
                        <div className="form-create-date-selection-container" onClick={showStartDatePicker}>
                            <div className="form-create-date-selection-clickable-display">
                                <BsCalendarEvent/>
                                <div>
                                    <div className="form-create-date-description">{getFancyDateString(dueDate)}</div>
                                </div>
                            </div>

                            {isShowingdueDatePicker && (
                                <div className="form-create-date-calendar-picker" ref={dateSelector}>
                                    <Calendar date={dueDate} onChange={updateDueDate} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="form-network-create-form-radio-item-subtitle">{getTimelineText()}</div>
                </div>
                </>
                }

                {page === 2 &&
                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-section-label">How do you want to track your progress?</div>
                    <div className="form-network-create-form-section-radio-items">
                        <div className={`form-network-create-form-section-radio-item ${getAdditionalTypeClass('manual')}`} onClick={() => setType('manual')}>
                            <span>By manually updating it</span>
                        </div>
                        <div className={`form-network-create-form-section-radio-item ${getAdditionalTypeClass('kpi')}`} onClick={() => setType('kpi')}>
                            <span>Using an existing KPI</span>
                        </div>
                    </div>
                    <div className="form-network-create-form-radio-item-subtitle">{getTypeText()}</div>
                </div>}

                {(type === 'kpi' && page === 2 && parentsLoaded && linkableKpis.length > 0) &&
                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-section-label">Choose a metric:</div>
                    <div className="form-network-create-form-section-input">
                        <select value={chosenMetricId} onChange={(e) => setChosenMetricId(e.target.value)}>
                            <option value="">None</option>
                            {linkableKpis.map((kpiComponent) => (
                                <option key={kpiComponent._id} value={kpiComponent._id}>{kpiComponent.display_name}</option>
                            ))}
                        </select>
                    </div>
                </div>
                }

                {(type === 'kpi' && getChosenMetricData() && page === 2) &&
                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-section-label">Set a target goal</div>

                    <div className="form-network-create-form-section-row" style={{gap: "1em", boxSizing: "border-box"}}>
                        <div style={{fontSize: "14px"}}>{getFormattedChosenMetric()}</div>
                        <BsArrowRight/>
                        <div className="form-network-create-form-metric-input-container">
                        {getMetricUnits() === "$" && <span style={{fontSize: "14px"}}>$</span> }
                        <input type="number" value={valTarget} onChange={(e) => setValTarget(e.target.value)} />
                        {getMetricUnits() !== "$" && <span style={{fontSize: "14px"}}>{getMetricUnits()}</span> }
                        </div>
                        

                    </div>
                </div>
                }

                {page === 3 &&
                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-section-label">Select scope</div>
                    <div className="form-network-create-form-section-radio-items">
                        <div className={`form-network-create-form-section-radio-item ${getAdditionalScopeClass('team')}`} onClick={() => setScope('team')}>
                            <span>Team</span>
                        </div>
                        {hasProjects() &&
                        <div className={`form-network-create-form-section-radio-item ${getAdditionalScopeClass('project')}`} onClick={() => setScope('project')}>
                            <span>Project</span>
                        </div>
                        }
                    </div>
                    <div className="form-network-create-form-radio-item-subtitle">{getScopeText()}</div>
                    <div className="form-network-create-form-radio-item-subtitle">{getScopeTextExamples()}</div>
                </div>
                }

                {(scope === 'project' && page === 3 && hasProjects()) &&
                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-section-label">Select a project:</div>
                    <div className="form-network-create-form-section-input">
                        <select value={projectIdAlignment} onChange={(e) => setProjectIdAlignment(e.target.value)}>
                            <option value="">None</option>
                            {potentialParents.map((parentProject) => (
                                <option key={parentProject._id} value={parentProject._id}>{parentProject.display_name}</option>
                            ))}
                        </select>
                    </div>
                    {projectIdAlignment === "" && <div className="form-network-create-form-radio-item-subtitle">If you don't select a project, the scope will default to Team instead</div>}
                    
                </div>
                }

                <div className="wizard-navigation-section wizard-navigation-section-2" style={{justifyContent: "center", gap: "24px", marginTop: "48px"}}>
                    {page > 1 && <div className="wizard-navigation-button wizard-navigation-button-secondary" onClick={retreatPage}>Back</div>}
                    {(page < 3 && name !== "") && <div className="wizard-navigation-button wizard-navigation-button-primary" onClick={advancePage}>Next</div>}
                    {(page === 1 && name === "") && <div className="wizard-navigation-button wizard-navigation-button-primary" style={{cursor: "not-allowed", backgroundColor: "grey"}}>Enter a name to continue</div>}
                    {page === 3 && <div className="wizard-navigation-button wizard-navigation-button-primary" onClick={publishKeyResult}>Create Key Result</div>}
                </div>

            </div>

        </div>
    )
}
