import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import { BsFillGearFill } from 'react-icons/bs'

export default function SimpleListActionMenu({actionsList, symbol}) {
    const [collapsed, setCollapsed] = useState(() => { return true })
    const menuRef = useRef(null)
    const getSymbol = () => {
        if (!symbol) return <BiDotsHorizontalRounded className="component-page-settings-symbol" onClick={() => setCollapsed(!collapsed)}/>
        let symbolMap = {
            gear: <BsFillGearFill className="component-page-settings-symbol" onClick={() => setCollapsed(!collapsed)}/>
        }
        if (!symbolMap[symbol]) return <BiDotsHorizontalRounded className="component-page-settings-symbol" onClick={() => setCollapsed(!collapsed)}/>
        return symbolMap[symbol]
    }
    const hide = () => {
        setCollapsed(true)
    }
    const doAction = (actionItem) => {
        if (actionItem.action) {
            hide()
            actionItem.action()
        }
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                hide()
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [menuRef])
    return (
        <div className="simple-list-action-menu-container" ref={menuRef}>
            {getSymbol()}
            
                <div className="simple-list-action-menu-dropdown">
                {!collapsed && 
                    <div className="simple-list-action-menu-primary-display">
                        <div className="simple-list-action-menu-items">
                            {actionsList.map((actionItem) => (
                                <div className="simple-list-action-menu-item" key={actionItem.id} onClick={() => doAction(actionItem)}>{actionItem.text}</div>
                            ))}
                        </div>
                    </div>
                }
                </div>
            
        </div>
    )
}
