import React from 'react'
import { format } from 'date-fns';
import { FaTimes } from 'react-icons/fa'

export default function CalendarDayModal({events, date, closeModal, goToItem, getEventStyles, getBackgroundColor}) {
    const getCircleStyles = (component) => {
        return {
            backgroundColor: getBackgroundColor(component)
        }
    }
    const getAdditionalFloatStyle = () => {
        if (!date) return {}
        const daysToShowOnRight = ['Sun', 'Mon', 'Tue']
        if (daysToShowOnRight.includes(format(date, 'eee'))) return { right: "-200%" }
        return {}
    }
    const getAdditionalStyles = (component) => {
        if (component.status !== 'active') return {
            textDecoration: 'line-through'
        }
        return {}
    }
    return (
        <div className="calendar-day-modal-container" style={getAdditionalFloatStyle()}>
            <div className="calendar-day-modal-header">
                <div className="calendar-day-modal-title">
                    <div className="calendar-day-modal-title-weekday">{format(date, 'EEEE')}</div>
                    <div className="calendar-day-modal-title-number">{format(date, 'd')}</div>
                </div>
            </div>
            <FaTimes className="calendar-day-modal-cancel" onClick={() => closeModal()}/>
            <div className="calendar-day-modal-items">
                {events.map((component) => (
                    <div className="calendar-day-modal-line-item" key={component._id}>
                        <span style={getCircleStyles(component)} className="calendar-day-modal-colored-circle"></span>
                        <span onClick={(e) => goToItem(component, e)} key={component._id} className="calendar-day-modal-line-item-name" style={getAdditionalStyles(component)}>{component.display_name}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}
