import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Api from '../../common/APIUtils';
import SimpleListActionMenu from '../components/SimpleListActionMenu';
import ConfirmAction from '../modals/ConfirmAction';
import ProfPicCircle from '../profiles/ProfPicCircle';
import ModalManageTeam from '../modals/ModalManageTeam';
import { hasPermission, getUserDataFromId, translatePermissionString } from '../../common/Helpers';
import { AiOutlinePlus } from 'react-icons/ai'

export default function NetworkTeamManagementSection({team, network, fetchUsers, permissions, fetchNetwork, networkWorkspaces}) {
    const api = new Api();
    const [teamName, setTeamName] = useState(() => { return team.name ? team.name : ""})
    const [proposedRole, setProposedRole] = useState(() => { return ""})
    const [networkTeam, setNetworkTeam] = useState(() => { return {} })
    const [isAddingMemberToTeam, setIsAddingMemberToTeam] = useState(() => { return false })
    const [isAddingWorkspaceToTeam, setIsAddingWorkspaceToTeam] = useState(() => { return false })
    const [isShowingConfirmMenu, setIsShowingConfirmMenu] = useState(() => { return false })
    const [confirmMenuContent, setConfirmMenuContent] = useState(() => { return "" })
    const [confirmMenuAction, setConfirmMenuAction] = useState(() => { return false })
    const teamNameLastUpdatedTimestamp = useRef(false);
    const teamTitleFieldHandler = (event) => {
        if (event.code === 'Enter') {
            event.preventDefault()
            event.target.blur()
        }
    }
    const askForDeleteConfirmation = () => {
        setConfirmMenuContent(`Are you sure you want to permanently delete ${team.name}?`)
        setConfirmMenuAction(() => deleteNetworkTeam)
        setIsShowingConfirmMenu(true)
    }
    // const deactivateTeam = (workspaceId) => {
    //     if (!network.slug) return
    //     const payload = {
    //         team_id: team._id,
    //         workspace_id: workspaceId
    //     }
    //     api.removeWorkspaceFromTeam(payload, network.slug)
    //     .then((res) => {
    //         if (res.data) {
    //             fetchNetworkTeam()
    //         }
    //     })
    //     .catch((err) => {
    //         console.log('Toast')
    //     })
    // }
    const getValidTeammates = () => {
        try {
            if (!team) return []
            const userIds = Array.from(team.user_ids)
            return userIds.filter((id) => {
                const teammate = getUserDataFromId(network.network_users, id)
                if (!teammate.firstName) return false
                if (!teammate.lastName) return false
                return true
            })
        } catch (error) {
            return []
        }
    }
    const fetchNetworkTeam = () => {
        if (!team) return
        api.getNetworkTeam(network.slug, team._id)
        .then((res) => {
            if (res.data) {
                setNetworkTeam(res.data.response)
                if (res.data.response.membership) {
                    if (res.data.response.membership.role_id) setProposedRole(res.data.response.membership.role_id)
                }
            }
        })
        .catch((err) => {
            console.log('Toast')
        })
    }
    const getActionList = () => {
        let actions = [];
        if (hasPermission(permissions, 'network_admin')) {
            actions = actions.concat([
                {
                    text: 'Delete Team',
                    action: askForDeleteConfirmation,
                    id: 'delete'
                }
            ])
        }
        return actions
    }
    const hideModal = () => {
        setIsShowingConfirmMenu(false)
    }
    const hasMembers = () => {
        if (!team.user_ids) return false
        if (team.user_ids.length === 0) return false
        return true 
    }
    const hasWorkspaces = () => {
        if (!networkTeam) return false
        if (!networkTeam.workspaces) return false
        if (networkTeam.workspaces.length > 0) return true
        return false
    }
    const getRoles = () => {
        if (!network) return []
        if (!network.network_roles) return []
        return network.network_roles
    }
    const getRolePermissions = () => {
        try {
            if (proposedRole === "" || !proposedRole) return <div>None</div>
            const roleData = network.network_roles.filter(a => a._id === proposedRole)
            const rolePermissions = roleData[0].permissions
            return rolePermissions.map((permission) => (
                <span key={permission}>{translatePermissionString(permission)}</span>
            )) 
        } catch (error) {
            return <div>None</div>
        }
        
    }
    const updateTeamName = () => {
        if (!team) return
        if (!team.name) return
        if (teamName === "" | teamName === team.name) return
        const payload = {
            name: teamName
        }
        api.updateNetworkTeam(network.slug, team._id, payload)
        .then((res) => {
            fetchUsers();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const updateTeamRole = () => {
        let payload = {}
        if (proposedRole || proposedRole === "") payload['role_id'] = proposedRole
        api.updateNetworkTeam(network.slug, team._id, payload)
        .then((res) => {
            fetchUsers();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const getTeamRole = () => {
        try {
            if (!networkTeam || !network) return ""
            if (!networkTeam.membership) return ""
            if (!networkTeam.membership.role_id) return ""
            if (!network.network_roles) return ""
            const teamCurrentMembershipId = networkTeam.membership.role_id
            const role = network.network_roles.find(r => r._id === teamCurrentMembershipId)
            if (role._id) return role._id
            return ""
        } catch (error) {
            return ""
        }
        
    }
    const deleteNetworkTeam = () => {
        api.deleteNetworkTeam(network.slug, team._id)
        .then((res) => {
            if (res) {
                hideModal()
                fetchUsers();
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }
    const removeUserFromTeam = (userId) => {
        if (!network || !team) return
        const payload = {
            team_id: team._id,
            user_id: userId
        }
        api.removeUserFromTeam(payload, network.slug)
        .then((res) => {
            if (res.data) {    
                fetchNetwork()
                fetchUsers()           
            }
        })
        .catch((err) => {
            console.log(err.response)
            console.log('Toast')
        })
    }
    const getTeamNamePlaceholder = () => {
        if (!team) return "Enter a team name"
        if (!team.name) return "Enter a team name"
        return team.name
    }
    const removeWorkspaceFromTeam = (workspaceId) => {
        if (!network.slug) return
        const payload = {
            team_id: team._id,
            workspace_id: workspaceId
        }
        api.removeWorkspaceFromTeam(payload, network.slug)
        .then((res) => {
            if (res.data) {
                fetchNetworkTeam()
                fetchNetwork()
                fetchUsers()
            }
        })
        .catch((err) => {
            console.log('Toast')
        })
    }
    const getRoleNameFromId = (roleId) => {
        if (!roleId) return "None"
        const roles = getRoles();
        const role = roles.find(r => { return r._id === roleId})
        if (!role) return "None"
        if (!role.name) return "None"
        return role.name
    }
    useEffect(() => {
        teamNameLastUpdatedTimestamp.current = Date.now()
        const timer = setTimeout(() => {
            if (Date.now() - teamNameLastUpdatedTimestamp.current < 900) return
            updateTeamName()
        }, 1000)
        return () => clearTimeout(timer)
    // eslint-disable-next-line
    }, [teamName])
    useEffect(() => {
        fetchNetworkTeam()
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (!hasPermission(permissions, 'network_admin')) return
        if (proposedRole !== getTeamRole()) updateTeamRole()
    // eslint-disable-next-line
    }, [proposedRole])
    return (
        <div className="network-page-teams-team-section">
            {isShowingConfirmMenu && <ConfirmAction mainText={confirmMenuContent} onconfirm={confirmMenuAction} hideMe={hideModal} />}          
            {isAddingMemberToTeam && <ModalManageTeam fetchNetwork={fetchNetwork} network={network} team={team} hideModal={() => setIsAddingMemberToTeam(false)} fetchUsers={fetchUsers} view="add_users"/>}
            {isAddingWorkspaceToTeam && <ModalManageTeam fetchNetwork={fetchNetwork} fetchNetworkTeam={fetchNetworkTeam} network={network} team={team} hideModal={() => setIsAddingWorkspaceToTeam(false)} networkWorkspaces={networkWorkspaces} fetchUsers={fetchUsers} view="add_workspaces"/>}
            <div className="network-page-teams-team-header">
                {hasPermission(permissions, 'network_admin') ? (
                    <input type="text" onKeyUp={(e) => teamTitleFieldHandler(e)} className="network-page-teams-team-name-editable" placeholder={getTeamNamePlaceholder()} value={teamName} onChange={(e) => setTeamName(e.target.value)} />
                ) : (
                    <div className="network-page-teams-team-name-noneditable">{teamName}</div>
                )}
                {hasPermission(permissions, 'network_admin') && <SimpleListActionMenu actionsList={getActionList()}/>}
            </div>

            <div className="network-page-subsection-container-split">

                <div className="network-page-subsection-split-titles">
                    <div className="network-page-subsection-title">
                        Team Users
                        {hasPermission(permissions, 'network_admin') && <AiOutlinePlus onClick={() => setIsAddingMemberToTeam(true)}/>}
                    </div>
                    {hasPermission(permissions, 'network_admin') &&
                        <p style={{fontSize: "14px", maxWidth: "80%", margin: "0"}}>Click a user to remove them from the team</p>
                    }
                </div>

                <div className="network-page-subsection-split-details">
                    {hasMembers() ? ( 
                        <div className="modal-team-users-all-container">
                            <div className="network-team-users-teammate-cards">
                                {getValidTeammates().map((userId) => (
                                    <TeamUserCard key={userId} userId={userId} getUserDataFromId={getUserDataFromId} network={network} permissions={permissions} removeUserFromTeam={removeUserFromTeam}/>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className="modal-team-users-all-container">
                            <div className="network-page-table-empty-state-text">There's nobody on this team yet</div>
                        </div>
                    )
                    }
                </div>

            </div>

            <div className="network-page-subsection-container-split">

                <div className="network-page-subsection-split-titles">
                    <div className="network-page-subsection-title">
                        Workspaces
                        {hasPermission(permissions, 'network_admin') && <AiOutlinePlus onClick={() => setIsAddingWorkspaceToTeam(true)}/>}
                    </div>
                    {hasPermission(permissions, 'network_admin') &&
                        <p style={{fontSize: "14px", maxWidth: "80%", margin: "0"}}>Click a workspace to remove it from the team</p>
                    }
                </div>

                <div className="network-page-subsection-split-details">

                    {hasWorkspaces() ? ( 
                        <div className="network-team-users-teammate-cards">
                            {networkTeam.workspaces.map((workspace) => (
                                <TeamWorkspaceCard key={workspace._id} permissions={permissions} removeWorkspaceFromTeam={removeWorkspaceFromTeam} workspace={workspace} />
                            ))}
                        </div>
                    ) : (
                        <div className="modal-team-users-all-container">
                            <div className="network-page-table-empty-state-text">This team isn't assigned to any workspaces yet</div>
                        </div>
                    )
                    }

                </div>

            </div>

            <div className="network-page-subsection-container-split">

                <div className="network-page-subsection-split-titles">
                    <div className="network-page-subsection-title">
                        Permissions
                    </div>
                </div>

                <div className="network-page-subsection-split-details">
                    <div className="modal-manage-user-membership-section">
                        <div className="modal-manage-user-membership-section-title">Role</div>

                        {hasPermission(permissions, 'network_admin') ? (
                            <form className="modal-network-manage-users-form">
                                <select className="modal-manage-user-membership-dropdown" value={proposedRole} onChange={(e) => setProposedRole(e.target.value)}>
                                    <option value="">--None--</option>
                                    {getRoles().map((r) => (
                                        <option value={r._id} key={r._id}>{r.name}</option>
                                    ))}
                                </select>
                            </form>
                        ) : (
                            <div>{getRoleNameFromId(proposedRole)}</div>
                        )}
                        
                    </div>

                    <div className="modal-network-manage-users-section modal-manage-user-membership-role-container">
                        <div className="modal-manage-user-membership-role">
                            <p>Permissions</p>
                            <div className="modal-manage-user-membership-role-permissions-box">
                                {getRolePermissions()}
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

function TeamUserCard({userId, getUserDataFromId, network, permissions, removeUserFromTeam}) {
    const user = getUserDataFromId(network.network_users, userId)
    const attemptRemoval = () => {
        if (!hasPermission(permissions, 'network_admin')) return
        removeUserFromTeam(userId)
    }
    return (
        <div className="network-team-users-teammate-card" onClick={attemptRemoval}>
            <ProfPicCircle src={user.profile_picture_url} userId={user._id} userName={`${user.firstName} ${user.lastName}`} height={40} />
            <div className="network-team-users-teammate-card-names">
                <div>{user.firstName} {user.lastName}</div>
                <span>@{user.handle}</span>
            </div>

        </div>
        
    )
}

function TeamWorkspaceCard({workspace, permissions, removeWorkspaceFromTeam}) {
    const attemptRemoval = () => {
        if (!hasPermission(permissions, 'network_admin')) return
        removeWorkspaceFromTeam(workspace._id)
    }
    return (
        <div className="network-team-users-workspace-card" onClick={attemptRemoval}>
            {workspace.name}
        </div>
        
    )
}