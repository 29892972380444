import React from 'react'
import ProfPicCircle from '../profiles/ProfPicCircle'
import { getUserProfileSrcFromId, getUserNameFromId } from '../../common/Helpers'

export default function TeamProfPicPreviews({team, count}) {
    const getAdditionalClass = (index) => {
        if (index === 0) return ""
        return "gadget-team-prof-pic-preview-image-shifter"
    }
    return (
        <div className="gadget-team-prof-pic-previews">
            {team.slice(0, count).map((teammate, index) => (
                <div className="gadget-team-prof-pic-preview-image-container" key={teammate._id}>
                    <div className={getAdditionalClass(index)} style={{left: `${(index*-8)}px`}}>
                        <ProfPicCircle src={getUserProfileSrcFromId(team, teammate._id)} userId={teammate._id} userName={getUserNameFromId(team, teammate._id)} height={40}/>
                    </div>
                </div>
            ))}
        </div>
    )
}
