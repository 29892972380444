import * as axios from "axios";
import { Buffer } from 'buffer';
const CryptoJS = require('crypto-js')

if (process.env.NODE_ENV === "production") {
  var BASE_URL = "https://tetheros.com/api"
} else {
  BASE_URL = "http://localhost:3000"
}

export default class Api {
  constructor() {
    this.api_token = null;
    this.client = null;
  }

  init = () => {

    let headers = {
      Accept: "application/json",

    };

    this.client = axios.create({
      baseURL: BASE_URL,
      timeout: 31000,
      headers: headers,
      withCredentials: true // allows cookies
    });

    return this.client;
  };

  // Check if logged in
  isAuthenticated = () => {
    return this.init().get("/auth");
  }

  // Log user out
  logout = () => {
    return this.init().get("/logout");
  }

  // Make the call to authenticate a user
  loginUser = (params) => {
    const b64Pw = Buffer.from(params.password).toString('base64');
    const maskedPassword = CryptoJS.AES.encrypt(b64Pw, process.env.REACT_APP_CRYPTO).toString();
    const payload = {
      emailAddress: params.emailAddress.toLowerCase(),
      password: maskedPassword
    }
    return this.init().post("/login", payload);
  };

  // Create a new user
  registerNewUser = (params) => {
    const payload = {
      firstName: params.firstName,
      lastName: params.lastName,
      emailAddress: params.emailAddress.toLowerCase(),
      password: params.password
    }
    return this.init().post("/user", payload);
  }

  // Request password reset
  passwordRequestPasswordReset = (params) => {
    const payload = {
      email: params.email
    }
    return this.init().post("/request-password-reset", payload);
  }

  // Change Password
  passwordChange = (params) => {
    const payload = {
      password: params.password
    }
    return this.init().post(`/reset-password?email=${params.email}&token=${params.token}`, payload);
  }

  passwordChangeAuth = (payload) => {
    let p = {}
    p['password'] = payload.new_password
    const b64CurrentPw = Buffer.from(payload.old_password).toString('base64');
    const maskedCurrentPassword = CryptoJS.AES.encrypt(b64CurrentPw, process.env.REACT_APP_CRYPTO).toString();
    p['current_password'] = maskedCurrentPassword
    return this.init().post(`/user/reset-password`, p);
  }

  // Finally got invited
  getInvitationInformation = (params) => {
    return this.init().get(`/user/invitation?i=${params.i}&d=${params.d}`)
  }

  acceptInvitation = (payload) => {
    return this.init().post('/user/invite/accept', payload)
  }

  getActivationSuccessMessage = () => {
    return this.init().get('/user/activation-success')
  }

  // Regenerate API Key
  regenerateApiKey = () => {
    return this.init().post('/user/api-key/regenerate', {})
  }

  // User status
  updateUserStatus = (payload) => {
    return this.init().post('/user/status', payload)
  }

  // Notifications
  getNotifications = () => {
    return this.init().get("/notifications");
  }

  deleteNotification = (params) => {
    return this.init().delete('/notification', { data: {notification_id: params.notification_id} })
  }

  deleteAllNotifications = () => {
    return this.init().delete('/notifications')
  }

  // Workspaces
  getWorkspaces = () => {
    return this.init().get("/workspaces");
  }

  getWorkspace = (params) => {
    return this.init().get(`/workspaces/${params.id}`);
  }

  createWorkspace = (params) => {
    return this.init().post("/workspace", params);
  }

  createNetworkWorkspace = (params) => {
    return this.init().post("/n/workspace", params);
  }

  deleteWorkspace = (params) => {
    return this.init().delete(`/workspaces/${params.workspaceId}`)
  }

  updateWorkspace = (payload) => {
    return this.init().post(`/workspaces/${payload.workspaceId}`, payload)
  }

  updateWorkspaceBannerImage = (formData, workspaceId) => {
    return this.init().post(`/workspaces/${workspaceId}/banner`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  pinWorkspaceForUser = (payload) => {
    return this.init().post('/user/pins/workspace', payload)
  }

  // Component Criteria
  addComponentCriteria = (params) => {
    return this.init().post('/component-criteria', params)
  }

  updateComponentCriteria = (params) => {
    return this.init().patch('/component-criteria', params)
  }

  deleteComponentCriteria = (params) => {
    return this.init().delete('/component-criteria', { data: {criteria_id: params.criteria_id} })
  }

  // Component Context
  getComponentContext = (params) => {
    return this.init().get(`/context/component/${params.id}`)
  }

  // Workspace Feed
  getWorkspaceFeed = (params) => {
    return this.init().get(`/feeds/workspaces/${params.workspaceId}`);
  }

  getWorkspacePost = (postId) => {
    return this.init().get(`/post/${postId}`)
  }

  // Workspace Chats
  getWorkspaceChats = (workspaceId) => {
    return this.init().get(`/workspace/${workspaceId}/chats`);
  }

  updateWorkspaceChatLastSeen = (workspaceId) => {
    return this.init().post(`/workspace/${workspaceId}/chats`);
  }

  deleteWorkspaceChatMessage = (payload) => {
    return this.init().delete(`/workspace/${payload.workspace_id}/chat/${payload.message_id}`)
  }

  // User Posts
  getUserPosts = () => {
    return this.init().get("/user/feed");
  }

  createUserPost = (params) => {
    console.log(params)
    return this.init().post("/user/post", params);
  }

  // User Notes
  getUserNotes = (interestingUserId) => {
    return this.init().get(`/user/notes/${interestingUserId}`);
  }

  createUserNote = (params) => {
    const payload = {
      to_id: params.to_id,
      text: params.text
    }
    return this.init().post("/user/notes", payload);
  }

  // User Trays
  getUserTrayItems = () => {
    return this.init().get('/user/tray')
  }

  updateUserTrayItems = (params) => {
    return this.init().post('/user/tray', params)
  }

  updateSpecificTrayItem = (params) => {
    return this.init().patch('/user/tray', params)
  }

  // Posts
  getComponentPosts = (params) => {
    return this.init().get(`/posts?component-id=${params.componentId}`);
  }

  getWorkspacePosts = (params) => {
    return this.init().get(`/posts?workspace-id=${params.workspaceId}`);
  }

  createBasicTextPost = (params) => {
    return this.init().post("/post", params)
  }

  createPostComment = (params) => {
    return this.init().post("/post-comment", params)
  }

  deletePost = (params) => {
    return this.init().delete(`/post/${params.post_id}`)
  }

  updatePost = (params) => {
    return this.init().post(`/post/${params.post_id}`, params)
  }

  // Post Reactions
  createPostReaction = (params) => {
    return this.init().post(`/reaction/create/${params.post_id}`, params)
  }

  removePostReaction = (params) => {
    return this.init().post(`/reaction/remove/${params.post_id}`, params)
  }

  // Comments
  createComponentComment = (params) => {
    return this.init().post('/comment', params)
  }

  updateComponentComment = (params) => {
    return this.init().patch('/comment', params)
  }

  deleteComponentComment = (params) => {
    return this.init().delete('/comment', { data: {comment_id: params.comment_id} })
  }

  // Workspace Components
  createWorkspaceComponent = (params, path) => {
    return this.init().post(path, params);
  }

  getWorkspaceComponent = (params) => {
    if (params.workspaceId) {
      return this.init().get(`/workspace/components?component-id=${params.componentId}&workspace-id=${params.workspaceId}`);
    }
    return this.init().get(`/workspace/components?component-id=${params.componentId}`);
  }

  getAllWorkspaceComponents = (params) => {
    // Note: this does not fetch tasks
    if (params.workspaceId) return this.init().get(`/workspace/components?workspace-id=${params.workspaceId}`)
    if (params.networkId) return this.init().get(`/workspace/components?network-id=${params.networkId}`)
  }

  getWorkspaceWorkstreams = (workspaceId, workstreamStatus) => {
    let status = "active"
    if (workstreamStatus) status = workstreamStatus
    return this.init().get(`/workspace/${workspaceId}/workstreams?status=${status}`)
  }

  updateWorkspaceComponent = (params, path) => {
    return this.init().patch(path, params);
  }

  transformWorkspaceComponent = (payload) => {
    return this.init().post('/components/transform', payload)
  }

  deleteWorkspaceComponent = (params) => {
    return this.init().delete('/workspace/components', { data: {componentId: params.componentId} })
  }

  updateMultipleComponents = (params) => {
    return this.init().post('/components/batch-update', params)
  }

  getComponentsByType = (params) => {
    return this.init().get(`/workspace/${params.workspaceId}/c?name=${params.name}`)
  }

  // Component Templates
  getComponentTemplates = (params) => {
    return this.init().get(`/component-templates?workspace-id=${params.workspaceId}&type=${params.type}`)
  }

  getNetworkComponentTemplates = (params) => {
    return this.init().get(`/networks/templates?network-id=${params.network_id}`)
  }

  createTemplateFromComponent = (params) => {
    return this.init().post('/component-template', params)
  }

  deleteComponentTemplate = (params) => {
    return this.init().delete('/component-template', { data: {template_id: params.templateId} })
  }

  updateComponentTemplate = (params, templateId) => {
    return this.init().patch(`/component-template/${templateId}`, params)
  }

  promoteComponentTemplate = (params) => {
    return this.init().post('/component-template/promote', params)
  }

  // Workspace Components: LISTs
  getListTasks = (params) => {
    return this.init().get(`/workspace/component/list?component-id=${params.componentId}`)
  }

  // Workspace Components: BOARDs
  getBoardCards = (params, limit) => {
    if (!limit) return this.init().get(`/workspace/component/board?component-id=${params.componentId}`)
    return this.init().get(`/workspace/component/board?component-id=${params.componentId}&limit=${limit}`)
  }

  createBoardColumn = (params) => {
    return this.init().post(`/workspace/component/board/column`, params)
  }

  removeBoardColumn = (params) => {
    return this.init().delete('/workspace/component/board/column', { data: {column_id: params.column_id} })
  }

  updateBoardColumn = (params) => {
    return this.init().patch('/workspace/component/board/column', params)
  }

  // Bonds
  createBond = (params) => {
    return this.init().post('/workspace/component/bond', params)
  }

  deleteBond = (params) => {
    if (params.child_id) return this.init().delete(`/workspace/components/bond?parent_id=${params.parent_id}&child_id=${params.child_id}`)
    return this.init().delete(`/workspace/components/bond?workspace-id=${params.workspaceId}&bond-id=${params.bondId}`)
  }

  // Team Management
  addUserToWorkspace = (params) => {
    return this.init().post('/user/workspace', params)
  }

  inviteUserToWorkspace = (params) => {
    return this.init().post('/user/workspace/invite', params)
  }

  inviteUsersToNetwork = (params) => {
    return this.init().post('/user/network/invite', params)
  }

  removeUserFromWorkspace = (params) => {
    return this.init().delete('/user/workspace', { data: {userId: params.userId, workspaceId: params.workspaceId} })
  }

  leaveWorkspace = (params) => {
    return this.init().delete('/user/workspace/leave', { data: {userId: params.userId, workspaceId: params.workspaceId} })
  }

  getAllUsers = () => {
    return this.init().get('/users')
  }

  updateUserWorkspaceMembership = (payload) => {
    return this.init().post('/user/workspace/membership', payload)
  }

  // Public Components
  getPublicComponent = (componentId) => {
    return this.init().get(`/public/c/${componentId}`)
  }

  // User Profiles
  getUserInfo = (userId) => {
    return this.init().get(`/user/id/${userId}`)
  }

  getUserInfoHandle = (userHandle) => {
    return this.init().get(`/user/handle/${userHandle}`)
  }

  updateUserProfile = (params) => {
    return this.init().post('/user/profile', params)
  }

  updateUserSettings = (params) => {
    return this.init().post('/user/settings', params)
  }

  uploadUserProfilePicture = (formData) => {
    return this.init().post('/user/profile/pic', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  getUserProfilePicture = () => {
    return this.init().get('/profile-picture', { responseType: 'arraybuffer' })
  }

  checkHandleAvailability = (handle) => {
    return this.init().get(`/users/handles/${handle}/check`)
  }

  getUserBillingInformation = () => {
    return this.init().get('/users/billing')
  }
  
  // Optics
  getUserAssignments = (userId) => {
    return this.init().get(`/user/workload/${userId}`)
  }

  getWorkspaceAlignments = (params) => {
    return this.init().get(`/alignment/workspace?workspace-id=${params.workspaceId}`)
  }

  getComponentAlignments = (params) => {
    return this.init().get(`/alignment/component/${params.componentId}/`)
  }

  getPotentialAlignmentsBasedOnType = (params) => {
    return this.init().get(`/alignment/type?type=${params.type}&workspace=${params.workspaceId}`)
  }

  createFeedback = (params) => {
    return this.init().post(`/app/feedback`, params)
  }

  getWorkspaceDashboard = (params) => {
    return this.init().get(`/workspace/${params.workspaceId}/dashboard`);
  }

  getWorkspaceTeam = (workspaceId) => {
    return this.init().get(`/workspace/${workspaceId}/team`)
  }

  conductWorkspaceSearch = (workspaceId, query) => {
    return this.init().get(`/workspace/${workspaceId}/search?q=${query}`)
  }

  getWorkspaceTaggedItems = (params) => {
    return this.init().get(`/workspace/${params.workspace_id}/t/${params.tag_name}`)
  }

  updateWorkspaceBuckets = (workspaceId, payload) => {
    return this.init().post(`/workspace/${workspaceId}/buckets`, payload)
  }

  // Networks
  checkNetworkSlugAvailability = (params) => {
    return this.init().get(`/networks/slugs/${params.slug}/check`)
  }

  createNetwork = (params) => {
    return this.init().post(`/networks/create`, params)
  }

  getNetwork = (params) => {
    return this.init().get(`/network/${params.networkSlug}`)
  }

  updateNetwork = (params, slug) => {
    return this.init().patch(`/network/${slug}`, params)
  }

  getNetworkLicenseUsersActivity = (slug) => {
    return this.init().get(`/network/${slug}/license/users/activity`)
  }

  getNetworkWorkspaces = (slug) => {
    return this.init().get(`/network/${slug}/workspaces`)
  }

  getNetworkDashboard = (slug) => {
    return this.init().get(`/network/${slug}/dashboard`)
  }

  getNetworkTeams = (slug) => {
    return this.init().get(`/network/${slug}/teams`)
  }

  fetchNetworkUserData = (networkId) => {
    return this.init().get(`/n/${networkId}/users`)
  }

  getNetworkTeam = (slug, teamId) => {
    return this.init().get(`/network/${slug}/teams/${teamId}`)
  }

  updateNetworkTeam = (slug, teamId, payload) => {
    return this.init().patch(`/network/${slug}/teams/${teamId}`, payload)
  }

  deleteNetworkTeam = (slug, teamId) => {
    return this.init().delete(`/network/${slug}/teams/${teamId}`)
  }

  createNetworkTeam = (params, slug) => {
    return this.init().post(`/network/${slug}/team`, params);
  }

  addUserToTeam = (params, slug) => {
    return this.init().post(`/network/${slug}/teams/users`, params);
  }

  removeUserFromTeam = (params, slug) => {
    return this.init().delete(`/network/${slug}/teams/users/${params.user_id}`, { data: {user_id: params.user_id, team_id: params.team_id} })
  }

  createNetworkRole = (payload) => {
    return this.init().post(`/n/${payload.network_id}/role`, payload);
  }

  updateCustomRole = (payload) => {
    return this.init().patch(`/n/${payload.network_id}/role`, payload);
  }

  deleteCustomRole = (payload) => {
    return this.init().delete(`/n/${payload.network_id}/role`, { data: payload });
  }

  importPrivateWorkspaceToNetwork = (params, slug) => {
    return this.init().post(`/network/${slug}/workspace/import`, params);
  }

  addUsersToNetwork = (params, slug) => {
    return this.init().post(`/network/${slug}/users`, params);
  }
  
  deleteUserFromNetworkByEmail = (params, slug) => {
    return this.init().delete(`/network/${slug}/user`, { data: {user_email: params.user_email} })
  }

  addWorkspaceToTeam = (params, slug) => {
    return this.init().post(`/network/${slug}/workspaces/teams`, params);
  }
  
  removeWorkspaceFromTeam = (params, slug) => {
    return this.init().delete(`/network/${slug}/workspaces/teams`, { data: {workspace_id: params.workspace_id, team_id: params.team_id} })
  }

  getLicensePermission = (params) => {
    return this.init().get(`/networks/permissions?permission=${params.permission}`)
  }

  registerForTetherosPro = () => {
    return this.init().get('/pro/register')
  }

  manageTetherosProLicenseSettings = () => {
    return this.init().get('/pro/manage')
  }

  cancelActiveTetherosPlan = (payload) => {
    return this.init().post('/pro/cancel', payload)
  }

  getPermissionsAssociatedWithComponent = (componentId) => {
    return this.init().get(`/permissions/component/${componentId}`)
  }

  fetchWorkspacePermissions = (workspaceId) => {
    return this.init().get(`/permissions/workspace/${workspaceId}`)
  }

  createNetworkWorkspaceComponent = (payload, slug) => {
    return this.init().post(`/networks/${slug}/components`, payload)
  }

  getNetworkInvitationData = (networkId) => {
    return this.init().get(`/n/${networkId}/invitation-data`)
  }

  updateNetworkUserPermissions = (payload) => {
    return this.init().post(`/n/${payload.network_id}/user/${payload.user_id}/permissions`, payload)
  }

  removeNetworkInvitation = (payload) => {
    return this.init().post('/user/invite/remove', payload)
  }

  triggerUserOnboardingWorkflow = (payload) => {
    return this.init().post(`/onboarding/user`, payload)
  }

  getTrelloBoards = (params) => {
    return this.init().get(`/trello/boards?api_key=${params.api_key}&api_token=${params.api_token}`)
  }

  importTrelloBoard = (payload) => {
    return this.init().post(`/trello/boards/import`, payload)
  }

  notifySalesTeamOfLead = (payload) => {
    return this.init().post('/request-license-upgrade', payload)
  }

  createEventPlanMagnet = (payload) => {
    return this.init().post('/lm/event-plan', payload)
  }

  getEventPlanMagnet = (params) => {
    return this.init().get(`/lm/event-plan?id=${params.id}&email=${params.email}`)
  }

  updateEventPlanMagnet = (payload) => {
    return this.init().patch(`/lm/event-plan/${payload._id}`, payload)
  }

  checkForLeadMagnet = (params) => {
    return this.init().get(`/lm-check?type=${params.type}&email=${params.email}`)
  }

}