import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import Api from '../../common/APIUtils'
import { isValidHttpUrl, hasPermission } from '../../common/Helpers';

import ToggleSwitch from '../gadgets/ToggleSwitch';
import SimpleSearchBar from '../gadgets/SimpleSearchBar';
import ResourceSymbol from '../gadgets/ResourceSymbol';

import { MdInsertLink, MdFolderCopy, MdFileUpload, MdClose } from "react-icons/md";

import { createWorkspaceFileResource } from '../../common/Api';

import ModalSimpleHeader from './simple/ModalSimpleHeader';
import ModalSimpleSingleButton from './simple/ModalSimpleSingleButton';
import ModalSimpleMultiRadioSelection from './simple/ModalSimpleMultiRadioSelection';

export default function ModalCreateNewResourceInProject({ hideModal, parentProjectComponent, fetchAlignments, projectResources, workspaceResources, unlinkResource, linkResource, permissions, workspaceId }) {
    const api = new Api()
    const [name, setName] = useState(() => { return "" })
    const [workspaceResourcesLocal, setWorkspaceResourcesLocal] = useState(() => {
        if (workspaceResources) {
            return Array.from(workspaceResources)
        } else {
            return []
        }
    })
    const [page, setPage] = useState(() => { return 1 })
    const [query, setQuery] = useState(() => { return "" })
    const [file, setFile] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [progress, setProgress] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(() => { return false })
    const [resourceType, setResourceType] = useState(() => { return "file" })
    const [activatedExistingResources, setActivatedExistingResources] = useState(() => { return [] })
    const [loadingActivatedResources, setLoadingActivatedResources] = useState(() => { return true })
    const [filteredResourceList, setFilteredResourceList] = useState(() => { return [] })
    const [isValid, setIsValid] = useState(() => { return false })
    const [fileFeedbackError, setFileFeedbackError] = useState(() => { return false })
    const [proposedDestination, setProposedDestination] = useState(() => { return "" })
    const MAX_FILE_SIZE = 5 * 1024 * 1024; 
    const handleEnterKeyPress = (e) => {
        if (e.code.toLowerCase() === 'enter') {
            createResource()
        }
    }
    const toggleResourceActivation = (resourceId, isActive) => {
        try {
            let copyOfActivatedResources = Array.from(activatedExistingResources)
            if (isActive) {
                linkResource(resourceId)
                if (copyOfActivatedResources.includes(resourceId)) return // already in
                copyOfActivatedResources.push(resourceId)
                return setActivatedExistingResources(copyOfActivatedResources)
            }
            if (!copyOfActivatedResources.includes(resourceId)) return
            unlinkResource(resourceId)
            const index = copyOfActivatedResources.indexOf(resourceId)
            if (index < 0) return
            copyOfActivatedResources.splice(index, 1)
            setActivatedExistingResources(copyOfActivatedResources)
        } catch (error) {
            return
        }
    }
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        handleFile(selectedFile);
    };
    const handleFile = (selectedFile) => {
        setFileFeedbackError(false)
        if (selectedFile.size > MAX_FILE_SIZE) {
            setFileFeedbackError(true)
            return
        }
        setFile(selectedFile);
        // Simulate upload progress
        let currentProgress = 0;
        const interval = setInterval(() => {
          currentProgress += 10;
          setProgress(currentProgress);
          if (currentProgress >= 100) clearInterval(interval);
        }, 200);
    };
    const createResource = () => {
        if (!isValid) return
        if (resourceType === "link") return createLinkResource()
        if (resourceType === "file") return createFileResource()
    }
    const createLinkResource = () => {
        if (isSubmitting) return
        setIsSubmitting(true)
        let workspaceIdString = false
        if (parentProjectComponent) workspaceIdString = parentProjectComponent.workspace_id
        if (!workspaceIdString && workspaceId) workspaceIdString = workspaceId
        if (!workspaceIdString) return
        if (!isValidHttpUrl(proposedDestination)) return hideModal()
        let payload = {
            workspace_id: workspaceIdString,
            is_published: true,
            display_name: name,
            attributes: {
                description: "",
                destination: proposedDestination
            }
        }
        if (parentProjectComponent) payload['proposed_parent_ids'] = [parentProjectComponent._id]
        if (name === "") payload['display_name'] = "Untitled Resource"
        api.createWorkspaceComponent(payload, `/components/resource/link`)
        .then((res) => {
            if (res.data) {
                if (res.data.response) {
                    if (res.data.response.component) addNewlyCreatedResourceToProject(res.data.response.component)
                }
            }
            setIsSubmitting(false)
            if (fetchAlignments) fetchAlignments()
            if (!parentProjectComponent) return hideModal()
            setPage(1)
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const createFileResource = () => {
        try {
            if (!file) return
            if (isSubmitting) return
            setIsSubmitting(true)
            let workspaceIdString = false
            if (parentProjectComponent) workspaceIdString = parentProjectComponent.workspace_id
            if (!workspaceIdString && workspaceId) workspaceIdString = workspaceId
            if (!workspaceIdString) return
            const formData = new FormData()
            formData.append('file', file)
            formData.append('display_name', name)
            if (parentProjectComponent) formData.append('proposed_parent_id', parentProjectComponent._id)
            createWorkspaceFileResource(formData, workspaceIdString)
            .then((res) => {
                if (res.data) {
                    if (res.data.response) {
                        if (res.data.response.component) addNewlyCreatedResourceToProject(res.data.response.component)
                    }
                }
                setIsSubmitting(false)
                if (fetchAlignments) fetchAlignments()
                if (!parentProjectComponent) return hideModal()
                setPage(1)
            })
            .catch((err) => { return setPage(1) })
            return
        } catch (error) {
            return
        }
    }
    const updateSortedWorkspaces = () => {
        try {
            if (!query) return setFilteredResourceList(workspaceResourcesLocal)
            if (query.trim() === "") return setFilteredResourceList(workspaceResourcesLocal)
            let filtered = workspaceResourcesLocal.filter((r) => {
                const searchText = `${r.display_name}${r.attributes.description}`
                if (searchText.toLowerCase().indexOf(query.trim().toLowerCase()) !== -1) return true
                return false
            })
            setFilteredResourceList(filtered)
        } catch (error) {
            return setFilteredResourceList(workspaceResourcesLocal)
        }
    }
    const updateProposedResourceValidity = () => {
        try {
            if (!name) return setIsValid(false)
            if (name.trim() === "") return setIsValid(false)
            if (resourceType === "link") {
                if (!proposedDestination) return setIsValid(false)
                if (proposedDestination.trim() === "") return setIsValid(false)
                if (isValidHttpUrl(proposedDestination)) return setIsValid(true)
                return setIsValid(false)
            }
            if (resourceType === "file") {
                if (file.size > MAX_FILE_SIZE) {
                    setIsValid(false)
                    setFileFeedbackError(true)
                    return
                }
                if (progress < 99) return setIsValid(false)
                return setIsValid(true)
            }

            return setIsValid(false)
        } catch (error) {
            return setIsValid(false)
        }
    }
    const addNewlyCreatedResourceToProject = (newComponent) => {
        try {
            toggleResourceActivation(newComponent._id, true)
            setWorkspaceResourcesLocal(prevArray => [...prevArray, newComponent]);
            setName("")
            setProposedDestination("")
        } catch (error) {
            return
        }
    }
    const handleDragEnter = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    // eslint-disable-next-line
    }, []);
    const handleDragLeave = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    // eslint-disable-next-line
    }, []);
    const handleDragOver = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    // eslint-disable-next-line
    }, []);
    const handleDrop = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        const droppedFile = e.dataTransfer.files[0];
        handleFile(droppedFile);
    // eslint-disable-next-line
    }, []);
    const updateCurrentActivatedResources = () => {
        if (!loadingActivatedResources) return
        try {
            const projectResourceIds = projectResources.map(r => r._id)
            setActivatedExistingResources(projectResourceIds)
            setLoadingActivatedResources(false)
        } catch (error) {
            return
        }
    }
    const determineStartingPoint = () => {
        if (!workspaceResources) return setPage(2)
        if (workspaceResources.length === 0) return setPage(2)
        if (!hasPermission(permissions, "component_create")) return setPage(2)
        setPage(1)
    }
    const clearFile = () => {
        setFile(null)
        setFileFeedbackError(false)
        setProgress(0)
    }
    const slideOptions = [
        {_id: "file", display_name: "File", description: "Attach any file up to 5 MB in size", symbol: <MdFolderCopy/>},
        {_id: "link", display_name: "Link", description: "Bookmark to anywhere", symbol: <MdInsertLink/>}
    ]
    useEffect(() => {
        updateProposedResourceValidity()
    // eslint-disable-next-line
    }, [name, proposedDestination, file, progress])
    useEffect(() => {
        updateCurrentActivatedResources()
    // eslint-disable-next-line
    }, [workspaceResourcesLocal])
    useEffect(() => {
        updateSortedWorkspaces()
    // eslint-disable-next-line
    }, [workspaceResourcesLocal, query])
    useEffect(() => {
        determineStartingPoint()
    // eslint-disable-next-line
    }, [])
    return (
        <div className="modal-mgmt-background-overlay" style={{zIndex: 200}}>
            <div className="modal-mgmt-container modal-mgmt-container-creator" style={page === 1 ? {maxWidth: "800px"} : {}}>
                {page === 1 &&
                <div>
                    <ModalSimpleHeader hideModal={hideModal} title="Manage project resources" />
                    <div className="modal-manage-project-resource-header">
                        <SimpleSearchBar query={query} setQuery={setQuery} maxWidth={300} placeholder="Search resources..." />
                        {hasPermission(permissions, "component_create") && <ModalSimpleSingleButton isValid={true} buttonText="Add new resource" onclick={() => setPage(2)} invertStyle={true} />}
                    </div>
                    <div className="modal-manage-project-resource-resources app-thin-scrollbar">
                        {filteredResourceList.map((resource) => (
                            <ExistingResource resource={resource} proposedExistingResources={activatedExistingResources} toggleResourceActivation={toggleResourceActivation} key={resource._id} />
                        ))}
                        {query && filteredResourceList.length === 0 &&
                        <div>No resources found matching your search term</div>
                        }
                        {!query && filteredResourceList.length === 0 &&
                        <div>Resources give your team quick access to important project links</div>
                        }
                    </div>
                    <div className="modal-workstream-settings-buttons">
                        <ModalSimpleSingleButton isValid={true} buttonText="Done" onclick={hideModal} />
                    </div>
                </div>
                }
                {page === 2 &&
                <div>
                    <ModalSimpleHeader hideModal={hideModal} title="New resource" />
                    <div className="modal-workstream-settings-setting-single modal-workstream-settings-setting-single-vertical">
                        <div className="modal-workstream-settings-setting-names">
                            <div className="modal-workstream-settings-setting-name">Resource Type</div>
                        </div>
                    </div>
                    <div>
                        <ModalSimpleMultiRadioSelection options={slideOptions} selectedOption={resourceType} setSelectedOption={setResourceType} />
                    </div>

                    <div className="modal-workstream-settings-setting-single modal-workstream-settings-setting-single-vertical">
                        <div className="modal-workstream-settings-setting-names">
                            <div className="modal-workstream-settings-setting-name">Name</div>
                        </div>
                        <div className="modal-workstream-settings-setting-value">
                            <div className="modal-workstream-settings-text-input-container">
                                <input onKeyUp={(e) => handleEnterKeyPress(e)} autoFocus={true} type="text" placeholder="What do you want to call this resource?" value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    {resourceType === "link" &&
                    <div className="modal-workstream-settings-setting-single modal-workstream-settings-setting-single-vertical">
                        <div className="modal-workstream-settings-setting-names">
                            <div className="modal-workstream-settings-setting-name">Destination</div>
                            <div className="modal-workstream-settings-setting-name-description">Enter a valid website URL for this</div>
                        </div>
                        <div className="modal-workstream-settings-setting-value">
                            <div className="modal-workstream-settings-text-input-container">
                                <input type="text" placeholder="e.g. https://" value={proposedDestination} onChange={(e) => setProposedDestination(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    }
                    {resourceType === "file" && !isSubmitting &&
                    <div className="modal-workstream-settings-setting-single modal-workstream-settings-setting-single-vertical">
                        <div className="modal-workstream-settings-setting-names">
                            <div className="modal-workstream-settings-setting-name">Upload file</div>
                            <div className="modal-workstream-settings-setting-name-description">Select a file from your computer to add to Tetheros</div>
                        </div>
                        <div className="modal-workstream-settings-setting-value">
                            {!file &&
                            <div className={`modal-workstream-settings-file-input-container ${isDragging ? 'modal-workstream-settings-file-dragging' : ''}`} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave} onDragOver={handleDragOver} onDrop={handleDrop}>
                                <MdFileUpload className="modal-workstream-settings-file-upload-icon" />
                                <input type="file" onChange={handleFileChange} className="modal-workstream-settings-file-input" id="fileInput" accept=".csv, .xlsx, .txt, .pdf, .doc, .docx, image/jpeg, image/png"/>
                                <p>Drag your file here or <label htmlFor="fileInput" className="modal-workstream-settings-file-label">select from computer</label></p>
                                
                            </div>
                            }
                            {file &&
                            <div className="modal-workstream-settings-file-progress-container">
                                <div className="modal-workstream-settings-file-info">
                                    <span className="modal-workstream-settings-file-name">{file.name}</span>
                                    <MdClose className="modal-workstream-settings-file-close-icon" onClick={clearFile} />
                                </div>
                                <div className="modal-workstream-settings-file-progress-bar-container">
                                    <div className="modal-workstream-settings-file-progress-bar" style={{ width: `${progress}%` }}></div>
                                </div>
                                <div className="modal-workstream-settings-file-progress-text">{progress}% - {progress < 100 ? `Uploading...` : 'Ready'}</div>
                            </div>
                            }
                            {fileFeedbackError && <p className="modal-workstream-settings-file-feedback-error">File needs to be less than 5 MB</p>}
                        </div>
                    </div>
                    }
                    {resourceType === "file" && isSubmitting &&
                    <div>Submitting...</div>
                    }
                    <div className="modal-workstream-settings-buttons util-row util-align-center util-row-reverse" style={{gap: "24px"}}>
                        <ModalSimpleSingleButton isValid={isValid} buttonText="Create" onclick={createResource} />
                        {workspaceResourcesLocal.length > 0  && <ModalSimpleSingleButton isValid={true} invertStyle={true} buttonText="Back" onclick={() => setPage(1)} />}
                    </div>
                </div>
                }
            </div>
        </div>
    )
}

function ExistingResource({ resource, proposedExistingResources, toggleResourceActivation }) {
    const [isChecked, setIsChecked] = useState(() => { return false })
    const updateIsChecked = () => {
        if (proposedExistingResources.includes(resource._id)) return setIsChecked(true)
        return setIsChecked(false)
    }
    const updateCheckState = (checkState) => {
        toggleResourceActivation(resource._id, checkState)
    }
    useEffect(() => {
        updateIsChecked()
    // eslint-disable-next-line
    }, [proposedExistingResources])
    return (
        <div className="modal-workstream-settings-setting-single" style={{marginTop: "0px"}}>
            
            <div className="util-row util-align-center" style={{flexGrow: 1, gap: "12px"}}>
                <span style={{fontSize: "20px"}}><ResourceSymbol resource={resource} /></span>
                <div className="modal-workstream-settings-setting-names">
                    <div className="modal-workstream-settings-setting-name" style={{flexBasis: "unset"}}>{resource.display_name}</div>
                    {resource.attributes.description && <div className="modal-workstream-settings-setting-name-description">{resource.attributes.description}</div>}
                </div>
            </div>
            
            <div className="modal-workstream-settings-setting-value">
                <div style={{display: "flex", flexDirection: "row-reverse"}}><ToggleSwitch isChecked={isChecked} setIsChecked={updateCheckState} /></div>
            </div>
        </div>
    )
}