import React from 'react'
import { useState, useEffect } from 'react';
import Api from "../common/APIUtils"
import DataManager from "../common/DataManager";
import {  useHistory, useParams, useLocation } from 'react-router-dom';
import { FaLongArrowAltLeft } from 'react-icons/fa';
import '../styles/workspace.css';
import '../styles/components.css';

// Workspace Components
import NewGoal from './forms/proj_components/NewGoal';
import NewInitiative from './forms/proj_components/NewInitiative';
import NewList from './forms/proj_components/NewList';
import NewBoard from './forms/proj_components/NewBoard';
import NewBoardImport from './forms/imports/NewBoardImport';
import NewKeyResult from './forms/proj_components/NewKeyResult';
import NewTimeline from './forms/proj_components/NewTimeline';

export default function NewWorkspaceItem() {
    const api = new Api();
    // eslint-disable-next-line
    const [workspace, setWorkspace] = useState({});

    // Validate user is logged in
    let history = useHistory();
    let dataManager = DataManager.getInstance();
    if (!dataManager.getAuth()) {
        history.push('/login');
    }

    const query = new URLSearchParams(useLocation().search);
    const com_name = query.get("name");
    const templateId = query.get("template-id");
    const importType = query.get("import");
    const proposedParentAlignmentIds = query.get("parents");

    const { workspaceId } = useParams();

    const fetchWorkspace = () => {
        const payload = {
            id: workspaceId
        }
        api.getWorkspace(payload)
        .then((res) => {
            setWorkspace(res.data.workspace);
        })
        .catch((err) => {
            console.log(err);
        })  
    }
    const backToCatalog = () => {
        history.goBack()
    }
    useEffect(() => {
        fetchWorkspace()
        
        // Fetch posts every 10 seconds
        const interval = setInterval(() => {
            fetchWorkspace()
        }, 10000)

        return() => clearInterval(interval)
        // eslint-disable-next-line
    }, [])

    return (
        <div id="component-menu-container">

            {/* Top navigation bar */}
            <div id="component-menu-top-bar">
                <div onClick={backToCatalog} className="component-text-link"><FaLongArrowAltLeft/> Go Back</div>
            </div>

            {/* Component Menu Items */}
            <div id="componentnew-form-container">

                {/* Strategy */}
                {com_name === "initiative" && <NewInitiative workspaceId={workspaceId} proposedTemplate={templateId} />}
                
                {/* Planning */}
                {com_name === "goal" && <NewGoal workspaceId={workspaceId} />}
                {com_name === "kresult" && <NewKeyResult workspaceId={workspaceId} />}
                {com_name === "timeline" && <NewTimeline workspaceId={workspaceId} />}
                
                {/* Work */}
                {com_name === "list" && <NewList workspaceId={workspaceId} proposedTemplate={templateId} proposedParentAlignmentIds={proposedParentAlignmentIds} />}
                {com_name === "board" && <NewBoard workspaceId={workspaceId} proposedTemplate={templateId} proposedParentAlignmentIds={proposedParentAlignmentIds}/>}
                {importType === "trello-board" && <NewBoardImport workspaceId={workspaceId}/>}

            </div>

        </div>
    )
}
