import ResponsiveSidebar from "./ResponsiveSidebar";
import HomeFeed from "./HomeFeed";
import { useHistory } from "react-router-dom";
import WizardUserOnboarding from "./wizards/WizardUserOnboarding";
import { updateHeadTags } from "../common/Helpers";
import Api from "../common/APIUtils";
import { useState, useEffect, useRef } from 'react';
import '../styles/home.css';
import '../styles/forms.css';
import '../styles/wizards.css';

export default function Home({sidebarCollapsed, setSidebarCollapsed, userData, authenticated, fetchAuthStatus}) {
    const api = new Api();
    let history = useHistory();
    const [privateWorkspaceCount, setPrivateWorkspaceCount] = useState(() => { return 1 })
    const [newWorkspacesAndNetworks, setNewWorkspacesAndNetworks] = useState(() => { return {} })
    const workspacesFetched = useRef(false)
    const fetchWorkspacesAndNetworks = () => {
        try {
            if (!workspacesFetched.current) {
                api.getWorkspaces()
                .then((res) => {
                    if (res.data) {
                        setNewWorkspacesAndNetworks({
                            licensedNetworks: res.data.network_owned,
                            adminNetworks: res.data.networks_admin,
                            unmobilizedNetworks: res.data.networks_setup_needed,
                            suspendedNetworks: res.data.networks_suspended,
                            workspaces: res.data.workspaces,
                            privateWorkspaceCount: res.data.private_workspace_count,
                            licenseStatus: res.data.license_status,
                            licenseTier: res.data.license_tier,
                            licenseActive: res.data.license_active,
                            hasNetwork: res.data.has_network
                        })
                        if (res.data.private_workspace_count || res.data.private_workspace_count === 0) setPrivateWorkspaceCount(res.data.private_workspace_count);
                        workspacesFetched.current = true;
                    }
                })
                .catch((err) => {
                    console.log(err);
                })  
            }  
        } catch (error) {
            return
        }

    }
    const getProjectContainerClass = () => {
        if (sidebarCollapsed) return "project-container-sidebar-hidden"
        return ""
    }
    const hasCompletedOnboarding = () => {
        if (!userData) return false
        if (userData.onboarding_complete) return true
        return false
    }
    useEffect(() => {
        fetchWorkspacesAndNetworks()
        updateHeadTags("Home | Tetheros")
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (!authenticated) return history.push('/login')
        // eslint-disable-next-line
    }, [authenticated])
    return (
        <div id="home-container">
            {hasCompletedOnboarding() &&
            <div id="home-left">
                <ResponsiveSidebar page="home" privateWorkspaceCount={privateWorkspaceCount} sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} workspacesAndNetworks={newWorkspacesAndNetworks}/>
            </div>
            }
            {hasCompletedOnboarding() && (
                <div className={`home-main ${getProjectContainerClass()}`}>
                    <HomeFeed setSidebarCollapsed={setSidebarCollapsed} userData={userData}/>
                </div>
            )}
            {!hasCompletedOnboarding() && (
                <div className={`home-main ${getProjectContainerClass()}`} style={{marginLeft: 0}}>
                    <WizardUserOnboarding fetchAuthStatus={fetchAuthStatus} />
                </div>
            )}
        </div>
    )
}