import React from 'react'

// Is clickable and changes style based on a boolean
export default function ModalSimpleSingleButton({isValid, buttonText, onclick, invertStyle}) {
    return (
        <div className="modal-mgmt-section modal-mgmt-section-advance">
            {isValid && <div className={`modal-mgmt-button-advance ${invertStyle ? "modal-mgmt-button-advance-invert" : ""}`} onClick={onclick}>{buttonText}</div>}
            {!isValid && <div className="modal-mgmt-button-advance" style={{backgroundColor: "grey", border: "1px solid grey"}}>{buttonText}</div>}
        </div>
    )
}
