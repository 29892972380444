import React from 'react'
import '../../styles/modals.css';
import { useState, useEffect, useReducer, useRef } from 'react'
import ModalSimpleHeader from '../modals/simple/ModalSimpleHeader'
import LexicalTextEditor from '../gadgets/LexicalTextEditor';
import { IoCheckmarkCircle, IoEllipse } from 'react-icons/io5'
import { AiOutlineDown } from 'react-icons/ai'
import BlurProofInput from '../gadgets/BlurProofInput';

export default function NewComponentTask({hideCreateTaskForm, createTask, team, parentId, taskname, parentComponent, states, existingPositions}) {
    const taskNameInputRef = useRef(null)
    const [editorKey, setEditorKey] = useState(() => { return 0 });
    const prevPosition = useRef(-1)
    const [proposedTask, proposedTaskDispatch] = useReducer((state, action) => {
        if (action.type === "reset") {
            return { description_editor: false, description_html: false}
        }
        if (action.type === "save") {
            const safeHtml = action.value.description_html
            const editorState = action.value.description_editor
            return { ...state, description_editor: editorState, description_html: safeHtml }
        }
        return state
    }, { description_editor: false, description_html: false })
    const [proposedState, setProposedState] = useState(() => { return false })
    const [name, setName] = useState(() => {
        if (taskname) return taskname
        return ""
    })
    const getPosition = () => {
        try {
            if (existingPositions) {
                const onlyNumbers = existingPositions.filter(n => parseInt(n))
                if (onlyNumbers.length === 0) return prevPosition.current
                const min = Math.min(...onlyNumbers)
                if (!min) return prevPosition.current
                return min - 1
            }
            if (parentIsBoard() && proposedState) {
                const chosenState = states.find(s => s._id === proposedState)
                if (!chosenState) return prevPosition.current
                const taskPositionsInState = chosenState.tasks.map(t => t.position)
                const onlyNumbers = taskPositionsInState.filter(n => parseInt(n))
                if (onlyNumbers.length === 0) return prevPosition.current
                const min = Math.min(...onlyNumbers)
                if (!min) return prevPosition.current
                return min - 1
            }

        } catch (error) {
            return prevPosition.current
        }
        return prevPosition.current
    }
    const [ownerId, setOwnerId] = useState(() => { return "" })
    const [dueDate, setDueDate] = useState(() => { return "" })
    const validateNewTaskSubmission = (keepModalOpen) => {
        if (!name) return
        let status = "active"
        if (parentIsBoard()) {
            if (proposedState === parentComponent.attributes.column_id_done) status = "completed"
        }
        let payload = {
            display_name: name,
            owner_id: ownerId,
            status: status,
            position: getPosition(),
            attributes: {
                date_due: dueDate,
                active: true,
                parent: parentId,
                progress: 0,
                details: {
                    description_editor: proposedTask.description_editor,
                    description_html: proposedTask.description_html
                },
                state: proposedState
            }
        }
        createTask(payload)
        if (!keepModalOpen) return hideCreateTaskForm()
        setEditorKey(prevKey => prevKey + 1);
        prevPosition.current = prevPosition.current - 1 // subtract
        resetNewTaskForm()
    }
    const getCreateTaskButtonStyle = () => {
        if (name) return {}
        return {
            backgroundColor: "grey",
            border: "1px solid grey"
        }
    }
    const resetNewTaskForm = () => {
        setName("")
        proposedTaskDispatch({type: "reset"})
    }
    const parentIsBoard = () => {
        if (!parentComponent) return false
        try {
            if (parentComponent.name === "board" && states) return true
            return false
        } catch (error) {
            return false
        }
    }
    const handleEnterKey = (code) => {
        const submissionCodes = ["Enter", "NumpadEnter"]
        if (submissionCodes.includes(code)) return validateNewTaskSubmission()
    }
    return (
        <div style={{position: "fixed", left: 0, top: 0, right: 0, zIndex: 100}}>
            <div className="project-post-modal-background">
            <div className="modal-task-create-container-overflows">
                <ModalSimpleHeader title="Create new task" hideModal={() => hideCreateTaskForm()} />

                <div className="modal-task-create-section">
                    <div className="modal-task-create-input-name">Name</div>
                    <BlurProofInput inputRef={taskNameInputRef}>
                        <input type="text" onKeyDown={(e) => handleEnterKey(e.code)} value={name} onChange={(e) => setName(e.target.value)} placeholder="What do you want to do?" autoFocus maxLength={80} ref={taskNameInputRef}/>
                    </BlurProofInput>
                </div>

                <div className="modal-task-create-section">
                    <div className="modal-task-create-input-name">Description</div>
                    <div style={{position: "relative"}}>
                        <LexicalTextEditor key={editorKey} isUpgrade={false} getInitialState={() => { return false}} mainDescriptionDispatch={proposedTaskDispatch} legacyDescription={false}/> 
                    </div>
                </div>

                <div className="modal-task-create-section-split">
                    <div className="modal-task-create-section">
                        <div className="modal-task-create-input-name">Due Date</div>
                        <input type="date" value={dueDate} onChange={(e) => setDueDate(e.target.value)} />
                    </div>

                    <div className="modal-task-create-section">
                        <div className="modal-task-create-input-name">Owner</div>
                        <select value={ownerId} onChange={(e) => setOwnerId(e.target.value)} name="owner">
                            <option key="none" value="">None</option>
                            {team.map((teammate) => (
                                <option key={teammate._id} value={teammate._id}>{`${teammate.firstName} ${teammate.lastName}`}</option>
                            ))}
                        </select>
                    </div>
                </div>

                {parentIsBoard() &&
                <div className="modal-task-create-section">
                    <div className="modal-task-create-input-name">Status</div>
                    <ComplexSelectWithOptionsStates states={states} currentState={proposedState} setCurrentState={setProposedState} parentComponent={parentComponent}/>
                </div>
                }

                <div className="modal-task-create-submission-section" style={{alignItems: "center", gap: "20px"}}>
                    <div className="modal-task-create-button-create" style={getCreateTaskButtonStyle()} onClick={() => validateNewTaskSubmission(false)}>Create Task</div>
                    {name && <div className="modal-task-create-button-create-save-and-next" onClick={() => validateNewTaskSubmission(true)}>Save And Next</div>}
                </div>               
            </div>
            </div>
            
        </div>
    )
}

function ComplexSelectWithOptionsStates({states, currentState, setCurrentState, parentComponent}) {
    const [isSelecting, setIsSelecting] = useState(() => { return false })
    const getCurrentStateName = () => {
        let currentStateObject = states.find((state => state._id === currentState))
        if (!currentStateObject) return "None"
        return currentStateObject.name
    }
    const updateState = (stateId) => {
        setCurrentState(stateId)
        setIsSelecting(false)
    }
    useEffect(() => {
        if (currentState) return
        if (!parentComponent) return
        if (!parentComponent.attributes) return
        setCurrentState(parentComponent.attributes.column_id_new)
    // eslint-disable-next-line
    },[parentComponent])
    return (
        <div className="modal-task-create-complex-select-container">
            <div className="modal-task-create-complex-select-selected" onClick={() => setIsSelecting(!isSelecting)}>
                <div style={{display: "flex", alignItems: "center", gap: "12px"}}>
                    <IoCheckmarkCircle className="modal-mgmt-checkmark modal-mgmt-checkmark-selected" />
                    {getCurrentStateName()}
                </div>
                <AiOutlineDown/>
            </div>
            {isSelecting && 
            <div className="modal-task-create-complex-select-options">
                {states.map((state) => (
                    <span key={state._id} onClick={() => updateState(state._id)}>
                        {currentState === state._id && <IoCheckmarkCircle className="modal-mgmt-checkmark modal-mgmt-checkmark-selected" />}
                        {currentState !== state._id && <IoEllipse className="modal-mgmt-checkmark"/>}
                        {state.name}
                    </span>
                ))}
            </div>
            }
            
        </div>
    )
}