import React from 'react'
import Api from '../../../common/APIUtils';
import { useState } from "react";
import WorkspaceGoalMarker from './WorkspaceGoalMarker';
import WorkspaceCompGoalAddMarker from './WorkspaceCompGoalAddMarker';
import ModalEditGoalMarker from '../../modals/ModalEditGoalMarker';
import { hasPermission } from '../../../common/Helpers';

export default function WorkspaceCompGoalMarkers({componentData, permissions, allComponents, childrenComponents, fetchComponent}) {
    const api = new Api();
    const [isAddingNewGoalMarker, setIsAddingNewGoalMarker] = useState(() => { return false })
    const [isEditingGoalMarker, setIsEditingGoalMarker] = useState(() => { return false })
    const [editingComponent, setEditingComponent] = useState(() => { return "" })
    const removeMarker = (bondId) => {
        const payload = {
            workspaceId: componentData.component.workspace_id,
            bondId: bondId
        }
        api.deleteBond(payload)
        .then((res) => {
            if (res) {
                fetchComponent()
            } else {
                console.log('Problem deleting bond')
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }
    const beginGoalMarkerEdit = (c) => {
        setEditingComponent(c)
        setIsEditingGoalMarker(true)
    }
    const updateGoalMarker = (payload, id) => {
        const path = `/workspace/components/${id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const addGoalMarker = (cId) => {
        const payload = {};
        payload['workspace_id'] = componentData.component.workspace_id;
        payload['child_id'] = cId;
        payload['parent_id'] = componentData.component['_id'];
        api.createBond(payload)
        .then((res) => {
            fetchComponent();
        });
    }
    const canAddNewMarker = () => {
        if (!hasPermission(permissions, 'component_update')) return false
        if (!componentData) return false
        if (!componentData.component) return false
        if (!componentData.component.status) return false
        if (componentData.component.status === 'active') return true
        return false
    }
    return (
        <div className="workcomp-initiative-alignments">
            {/* Add New Goal Marker Modal */}
            {isAddingNewGoalMarker && <WorkspaceCompGoalAddMarker parentId={componentData.component._id} fetchComponent={fetchComponent} allComponents={allComponents}  setIsAddingNewGoalMarker={setIsAddingNewGoalMarker} potentialBondIds={componentData.potential_bonds.as_parent} createNewBond={addGoalMarker}/>}
            {/* Edit Goal Marker Modal */}
            {isEditingGoalMarker && <ModalEditGoalMarker updateGoalMarker={updateGoalMarker} parentId={componentData.component._id} setIsEditingGoalMarker={setIsEditingGoalMarker} component={editingComponent}/>}
            
            {/* Goal Marker Display */}
            <div className="workcomp-alignment-marker-container">
                {childrenComponents.length > 0 ? (
                    <>
                    {childrenComponents.map((childComponent) => (
                        <WorkspaceGoalMarker permissions={permissions} beginGoalMarkerEdit={beginGoalMarkerEdit} key={childComponent._id} parentId={componentData.component._id} component={allComponents[childComponent.child_id]} removeMarker={removeMarker} bondId={childComponent._id}/>
                    ))}
                    </>
                ) : (
                    <div>Goal Markers are a great way to track a lot of items related to your goal.</div>
                )}
            </div>

            {canAddNewMarker() &&
            <div style={{marginTop: "2em"}}>
                <span className="workcomp-alignment-add-marker-button" onClick={() => setIsAddingNewGoalMarker(true)}>Add new subgoal</span>
            </div>
            }
            
        </div>
    )
}
