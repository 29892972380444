import React from 'react';
import { BiCalendar } from 'react-icons/bi';
import { MdSchema } from 'react-icons/md'
import { GiCheckMark } from 'react-icons/gi'
import { format, parseISO, isPast, addDays } from 'date-fns'
import { getUserNameFromId, getUserProfileSrcFromId, getColorHexMapFromName } from '../../../common/Helpers';
import { Draggable } from 'react-beautiful-dnd'
import ProfPicCircle from '../../profiles/ProfPicCircle';

export default function WorkspaceCompBoardCard({setSelectedTask, setIsEditingModal, data, team, index, completeTask, fetchBoard, isInDoneColumn }) {
    const getCardClassNames = (dragging) => {
        if (dragging) return "workspace-board-column-card workspace-board-column-card-dragging"
        if (isInDoneColumn) return "workspace-board-column-card workspace-board-column-card-completed"
        return "workspace-board-column-card"    
    }
    const getCardColorStyle = (provided) => {
        let addedStyles = {}
        // const percentageShade = "45"
        try {
            let cardColor = data.attributes.color_name
            if (!cardColor || cardColor === "none") return {...provided.draggableProps.style}
            const col = getColorHexMapFromName(cardColor).hex
            // addedStyles['backgroundColor'] = `${col}${percentageShade}`
            addedStyles['borderLeft'] = `3px solid ${col}`
            addedStyles['borderTopLeftRadius'] = "0px"
            addedStyles['borderBottomLeftRadius'] = "0px"
            addedStyles['borderRight'] = `3px solid transparent`
        } catch (error) {
            addedStyles = {}   
        }
        return {...provided.draggableProps.style, ...addedStyles}
    }
    const getDateSection = () => {
        try {
            if (isInDoneColumn) {
                return (
                <div className="workspace-board-card-summary-icon">
                    <GiCheckMark style={{color: "#9F6ADB"}}/>
                    {data.completed && <span>Completed {format(parseISO(data.completed), 'LLLL do')}</span>}
                    {!data.completed && <span>{format(new Date(), 'LLLL do')}</span>}
                </div>
                )
            }
            if (!data.attributes.date_due) return
            const overDueColor = "#E60053"
            let styles = {}
            if (isPast(addDays(parseISO(data.attributes.date_due), 1))) styles['color'] = overDueColor
            return (
                <div className="workspace-board-card-summary-icon" style={styles}>
                    <BiCalendar style={styles}/>
                    <span>{format(parseISO(data.attributes.date_due), 'LLLL do')}</span>
                </div>
            )
        } catch (error) {
            console.log(error)
        }

    }
    const setIsEditingCard = () => {
        setSelectedTask(data);
        setIsEditingModal(true)
    }
    const getSubtasksText = () => {
        try {
            if (!data.criteria) return
            if (data.criteria.length === 0) return
            let completed = 0
            const copyOfCriteria = Array.from(data.criteria)
            const completedCriteria = copyOfCriteria.filter((c) => c.status === "completed")
            if (completedCriteria.length > 0) completed = completedCriteria.length
            return `${completed}/${data.criteria.length}`
        } catch (error) {
            return
        }
    }
    const getDetails = () => {
        try {
            if (typeof data.attributes.details === "string") return data.attributes.details
            if (!data.attributes.details.description_html) return ""
            return data.attributes.details.description_html.replace(/<\/?[^>]+(>|$)/g, '')
        } catch (error) {
            return ""
        }
    }
    return (
        <>
        <Draggable key={data._id} draggableId={data._id} index={index}>
            {(provided, snapshot) => (
                <div className={getCardClassNames(snapshot.isDragging)} {...provided.draggableProps} ref={provided.innerRef} {...provided.dragHandleProps} style={getCardColorStyle(provided)} onClick={() => setIsEditingCard(true)}>
                    {data.display_name ? (
                        <>
                    {/* Card content */}
                    <div onClick={() => setIsEditingCard(true)} className={`workspace-board-card-content-description`}>{data.display_name}</div>

                    {/* Card Details */}
                    <div className="workspace-board-card-content-expanded-container" onClick={() => setIsEditingCard(true)}>
                        {/* card details */}
                        <div className="workspace-board-card-content-expanded">
                        {getDetails() && <div className="workspace-board-card-content-expanded-details">{getDetails()}</div>}
                        </div>                
                    </div>

                    <div className="workspace-board-card-show-display" onClick={() => setIsEditingCard(true)}>
                        <div className="workspace-board-card-summary-icons">
                            <div className="workspace-board-card-summary-icons-group">
                                {data.criteria.length > 0 && !isInDoneColumn && (
                                    <div className="workspace-board-card-summary-icon">
                                        <MdSchema/>
                                        <span>{getSubtasksText()} tasks</span>
                                    </div>
                                )}
                                {getDateSection()}
                            </div>
                            {((data.owner_id) && (data.status !== 'completed')) ? (
                                <div className="workspace-board-card-summary-owner-pic">
                                    <ProfPicCircle src={getUserProfileSrcFromId(team, data.owner_id)} userId={data.owner_id} userName={getUserNameFromId(team, data.owner_id)} height="24px" disableClick={true}/>
                                </div>
                            ) : (
                                <></>
                            )} 
                                
                        </div>
                    </div>

                        </>
                    ) : (
                        <></>
                    )}
                </div>
            )}
        </Draggable>
        </>

    )
}
