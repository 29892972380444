import React from 'react'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import Api from "../../../common/APIUtils"
import '../../../styles/forms.css';
import '../../../styles/components.css';
import NewComponentTemplateChoice from './NewComponentTemplateChoice';
import LoadingSymbolGrid from '../../gadgets/LoadingSymbolGrid';
import { BiCheck } from 'react-icons/bi'
import { MdOutlineArrowRightAlt } from 'react-icons/md'
import { parseISO, formatDistanceStrict, isAfter, format } from 'date-fns'
import ConfirmAction from '../../modals/ConfirmAction';
import ComponentMainDescription from '../../components/ComponentMainDescription';

export default function NewBoard({workspaceId, proposedTemplate, proposedParentAlignmentIds}) {
    const api = new Api();
    let history = useHistory();
    const [name, setName] = useState(() => { return "" })
    const [page, setPage] = useState(() => { return 1 })
    const [chosenTemplate, setChosenTemplate] = useState(() => {
        if (proposedTemplate) {
            return proposedTemplate
        } else {
            return "123"
        }
    })
    const [isShowingConfirmMenu, setIsShowingConfirmMenu] = useState(() => { return false })
    const [projectIdAlignments, setProjectIdAlignments] = useState(() => { return [] })
    const [parentSelectedForDetailedView, setParentSelectedForDetailedView] = useState(() => { return false })
    const [potentialParents, setPotentialParents] = useState(() => { return [] })
    const [parentsLoaded, setParentsLoaded] = useState(() => { return false })
    const [templatesLoaded, setTemplatesLoaded] = useState(() => { return false })
    const [templates, setTemplates] = useState(() => { return [] })
    const blankBoardTemplate = {
        template_name: 'Blank',
        _id: "123",
        template_description: "Start from scratch"
    }
    const updateOrSubmitBoard = (e) => {
        if (e.code.toLowerCase() === 'enter') {
            proceedFromPageOne()
        }
    }
    const attemptToBuild = () => {
        if (projectIdAlignments.length === 0) return setIsShowingConfirmMenu(true)
        return publishBoard()
    }
    const isValidTemplateId = (id) => {
        if (id === "123") {
            return true;
        }
        const foundTemplatesWithId = templates.filter(t => {
            return t._id === id
        })
        if (foundTemplatesWithId.length > 0) {
            return true
        } else {
            return false;
        }
    }
    const handleKeyBoardPress = (code) => {
        if (code === "Escape") history.goBack()
    }
    const publishBoard = () => {
        if (chosenTemplate === "123") {
            var payload = {
                workspace_id: workspaceId,
                is_published: true,
                display_name: name,
                attributes: {
                    tasks: [],
                    name: name
                }
            }
        } else if (isValidTemplateId(chosenTemplate)) {
            payload = {
                workspace_id: workspaceId,
                display_name: name,
                template_id: chosenTemplate
            }
        } else {
            payload = {
                workspace_id: workspaceId,
                is_published: true,
                display_name: name,
                attributes: {
                    tasks: [],
                    name: name
                }
            }
        }
        if (projectIdAlignments.length > 0) payload['proposed_parent_ids'] = projectIdAlignments
        
        const path = `/components/work/board`;
        if (name !== "") {
            api.createWorkspaceComponent(payload, path)
            .then( (res) => {
                if (res.status === 200) {
                    history.push(`/workspaces/${workspaceId}/c/${res.data.response.component._id}`);
                }
            })
            .catch((err) => {
                console.log(err);
                alert(err);
            })
        }
    }
    const getComponentTemplates = () => {
        const payload = {
            workspaceId: workspaceId,
            type: 'board'
        }
        try {
            api.getComponentTemplates(payload)
            .then((res) => {
                if (res.data.response.templates) {
                    setTemplatesLoaded(true)
                    setTemplates(res.data.response.templates)
                } else {
                    setTemplatesLoaded(false)
                    setTemplates([])
                }
            }) 
        } catch (error) {
            console.log(error)
        }
    }
    const getProjectAlignmentClass = (projectId) => {
        let projectContainerClass = "form-network-alignment-container"
        if (projectId === parentSelectedForDetailedView._id) projectContainerClass += " form-network-alignment-container-selected"
        return projectContainerClass
    }
    const addProjectAlignment = (projectId) => {
        let projectAlignments = Array.from(projectIdAlignments)
        if (projectAlignments.includes(projectId)) return
        projectAlignments.push(projectId)
        setProjectIdAlignments(projectAlignments)
    }
    const fetchPotentialParents = () => {
        api.getPotentialAlignmentsBasedOnType({type: 'board', workspaceId: workspaceId})
        .then((res) => {
            if (res.data) {
                setPotentialParents(res.data.response.potential_parents)
                setParentsLoaded(true)
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }
    const changeParentAlignment = (c) => {
        if (!c) {
            setParentSelectedForDetailedView(false)
            setProjectIdAlignments([])
            return
        }
        setParentSelectedForDetailedView(c)
        setProjectIdAlignments([c._id])
    }
    const getDatesOfParentFlownOutInDetail = () => {
        try {
            const formattedDateStart = format(parseISO(parentSelectedForDetailedView.attributes.date_start), 'PP')
            const formattedDateEnd = format(parseISO(parentSelectedForDetailedView.attributes.date_end), 'PP')
            return (
                <div className="form-network-alignment-project-detailed-date-range">{formattedDateStart} <MdOutlineArrowRightAlt/> {formattedDateEnd}</div>
            )
        } catch (error) {
            return false
        }
    }
    const additionalPageOneStyles = () => {
        if (!name || name.length === 0) return {
            cursor: 'not-allowed',
            backgroundColor: "rgb(233, 233, 233)",
            color: "grey"
        }
        return {}
    }
    const getLinkedSymbol = (id) => {
        if (projectIdAlignments.includes(id)) return <BiCheck/>
        return ""
    }
    const attemptToProceedToPageTwo = () => {
        if (!name || name.length === 0) return
        proceedFromPageOne()
    }
    const getItemProgress = (c) => {
        if (c.progress || c.progress === 0) return `${Math.floor(Math.round(c.progress*100))}`
        return "0"
    }
    const getRemainingDays = (c) => {
        if (!c.attributes) return "0 days"
        if (!c.attributes.date_end) return "0 days"
        const proposedDateEnd = c.attributes.date_end
        const currentEndDate = parseISO(proposedDateEnd)
        if (isAfter(currentEndDate, new Date())) return formatDistanceStrict(currentEndDate, new Date(), { roundingMethod: "ceil"})
        return "0 days"
    }
    const getProjects = () => {
        if (potentialParents.length === 0) return []
        return potentialParents.filter(a => a.name === 'initiative')
    }
    const proceedFromPageOne = () => {
        // check if there is already a linked project going into the form
        // if not, check that there are any projects available to link
        if (getProjects().length === 0) {
            return publishBoard()
        }
        // if (there is already a linked project) return publishBoard()
        return setPage(2)
    }
    const goToSelectedParentPage = () => {
        if (!parentSelectedForDetailedView) return
        history.push(`/workspaces/${workspaceId}/c/${parentSelectedForDetailedView._id}`)
    }
    const goBackToCatalog = () => {
        history.push(`/workspaces/${workspaceId}/catalog`)
    }
    useEffect(() => {
        getComponentTemplates()
        fetchPotentialParents()
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (!parentsLoaded) return
        if (!proposedParentAlignmentIds) return
        const splitParentIds = proposedParentAlignmentIds.split(',')
        for (let i = 0; i < splitParentIds.length; i++) {
            const match = potentialParents.filter(a => a._id === splitParentIds[i])
            if (match) {
                addProjectAlignment(splitParentIds[i])
            }
        }
    // eslint-disable-next-line
    }, [proposedParentAlignmentIds, parentsLoaded])
    return (
        <div className="network-page">
            <div className="network-page-header-row">
                <div className="network-page-header-text">
                    <h1>New Board</h1>
                    <p>A simple kanban with customizable columns and cards</p>
                </div>
            </div>
            {isShowingConfirmMenu && <ConfirmAction mainText="Continue without creating any project alignments?" onconfirm={publishBoard} hideMe={() => setIsShowingConfirmMenu(false)} />}


            {page === 1 &&
            <div className="form-network-create-form-container">

                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-section-label">Name</div>
                    <div className="form-network-create-form-section-input">
                        <input autoFocus={true} style={{fontSize: "16px"}} value={name} onChange={(e) => setName(e.target.value)} onKeyPress={(e) => updateOrSubmitBoard(e)} type="text" onKeyDown={(e) => handleKeyBoardPress(e.code)} placeholder="Provide a name for your task board" />
                    </div>
                </div>

                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-section-label">Choose a template</div>
                    {templatesLoaded ? (
                        <div className="form-templates-grid">
                            <NewComponentTemplateChoice chosenTemplate={chosenTemplate} template={blankBoardTemplate} setAsTemplate={setChosenTemplate} />

                            {templates.map((template) => (
                                <NewComponentTemplateChoice key={template._id} chosenTemplate={chosenTemplate} template={template} setAsTemplate={setChosenTemplate}/>
                            ))}
                        </div>
                    ) : (
                        <LoadingSymbolGrid/>
                    )}
                </div>

                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-page-navigation-button-container">
                        <div className="form-network-create-form-create" style={additionalPageOneStyles()} onClick={attemptToProceedToPageTwo}>
                            {getProjects().length > 0 && <div>Continue</div>}
                            {getProjects().length === 0 && <div>Create Board</div>}
                        </div>
                        <div className="form-network-create-form-cancel" onClick={goBackToCatalog}>Cancel</div>
                    </div>
                </div>

            </div>
            }

            {page === 2 && 
            <div className="form-network-create-form-container">

                <div className="form-network-create-form-section">
                    {projectIdAlignments.length === 0 && <div className="form-network-create-form-section-label-large">Align <span style={{fontWeight: "800"}}>{name}</span> with an existing project</div>}
                    {projectIdAlignments.length > 0 && <div className="form-network-create-form-section-label-large">Aligned with {parentSelectedForDetailedView.display_name} <span style={{color: "#2A73AE", textDecoration: "underline", cursor: "pointer", marginLeft: "12px" }} onClick={() => changeParentAlignment(false)}>Remove</span> </div>}

                    <div className="form-network-alignment-outer">

                        <div className="form-network-alignment-project-list">
                        {parentsLoaded ? (
                            <div className="form-network-alignments-vertical-grid">
                                {getProjects().map((c) => (
                                    <div key={c._id} className={getProjectAlignmentClass(c._id)} onClick={() => changeParentAlignment(c)}>
                                        <div className="form-network-alignment-project-option-summary-group">
                                            <div className="form-network-alignment-project-option-summary-icon">{getLinkedSymbol(c._id)}</div>
                                            <div className="form-network-alignment-project-option-summary">
                                                <div className="form-network-alignment-project-option-name">{c.display_name}</div>
                                                <div className="form-network-alignment-project-option-description">
                                                    <span>{getItemProgress(c)}% complete</span>
                                                    <span>&middot;</span>
                                                    <span>{getRemainingDays(c)} remaining</span>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <LoadingSymbolGrid/>
                        )}
                        </div>
                        <div className="form-network-alignment-project-selected-information">
                            {parentSelectedForDetailedView ? (
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <div className="form-network-alignment-project-detailed-name" onClick={goToSelectedParentPage}>{parentSelectedForDetailedView.display_name}</div>
                                    {getDatesOfParentFlownOutInDetail()}
                                    <div className="form-network-alignment-project-detailed-description">
                                        <ComponentMainDescription component={parentSelectedForDetailedView} permissions={[]} updateDetails={false} />
                                    </div>
                                    <div className="form-network-alignment-project-detailed-progress">Project is {getItemProgress(parentSelectedForDetailedView)}% complete with {getRemainingDays(parentSelectedForDetailedView)} to go</div>
                                </div>
                            ) : (
                                <div style={{display: "flex", flexDirection: "column", gap: "18px"}}>
                                    <div>Align <span style={{fontWeight: "700"}}>{name}</span> with an existing project?</div>
                                    <div>Tasks on the board will be treated as a work stream and contribute to the overall progress of the project.</div>
                                    <div>You can always change these settings later.</div>
                                </div>
                            )}
                        </div>
                    
                    </div>
                </div>


                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-page-navigation-button-container">
                        {/* Add a "Create" but have it prompt the user that they are building an isolated checklist --- nudge */}
                        <div className="form-network-create-form-create" onClick={attemptToBuild}>Create Board</div>
                        <div className="form-network-create-form-cancel" onClick={() => setPage(1)}>Go Back</div>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}
