import React from 'react'
import { useReducer } from 'react'
import Api from '../../common/APIUtils'

import ModalSimpleHeader from './simple/ModalSimpleHeader'
import ModalSimpleSingleButton from './simple/ModalSimpleSingleButton'
import ModalToolMultiSelectWorkspaceDropdownWithSearch from './tools/ModalToolMultiSelectWorkspaceDropdownWithSearch'
import ModalToolSingleSelectRoleDropdownWithSearch from './tools/ModalToolSingleSelectRoleDropdownWithSearch'

export default function ModalNetworkTeamCreate({hideModal, network, networkUserData}) {
    const api = new Api()
    const [proposedTeam, proposedTeamDispatch] = useReducer((state, action) => {
        if (action.type === "update_name") return { ...state, name: action.value }
        if (action.type === "toggle_workspace") {
            if (!action.value) return state
            let workspaceIds = Array.from(state.workspace_ids)
            // remove it
            if (workspaceIds.includes(action.value)) {
                const index = workspaceIds.indexOf(action.value)
                if (index < 0) return state
                workspaceIds.splice(index, 1)
                return { ...state, workspace_ids: workspaceIds }
            }
            // add it
            workspaceIds.push(action.value)
            return { ...state, workspace_ids: workspaceIds }
        }
        if (action.type === "toggle_user") {
            if (!action.value) return state
            let userIds = Array.from(state.user_ids)
            // remove it
            if (userIds.includes(action.value)) {
                const index = userIds.indexOf(action.value)
                if (index < 0) return state
                userIds.splice(index, 1)
                return { ...state, user_ids: userIds }
            }
            // add it
            userIds.push(action.value)
            return { ...state, user_ids: userIds }
        }
        if (action.type === "change_role") {
            return { ...state, role_id: action.value }
        }
        return state
    }, { name: "", user_ids: [], workspace_ids: [], role_id: "" })
    const updateRole = (roleId) => proposedTeamDispatch({ type: "change_role", value: roleId })
    const toggleWorkspace = (workspaceId) => proposedTeamDispatch({type: "toggle_workspace", value: workspaceId})
    const toggleUser = (userId) => proposedTeamDispatch({type: "toggle_user", value: userId})
    const getWorkspaceOptions = () => {
        if (!networkUserData) return []
        if (!networkUserData.workspaces) return []
        return networkUserData.workspaces
    }
    const getRoleOptions = () => {
        try {
            return networkUserData.roles
        } catch (error) {
            return []
        }
    }
    const getAllUsers = () => {
        try {
            return networkUserData.users
        } catch (error) {
            return []
        }
    }
    const isCreatable = () => {
        if (!proposedTeam.name) return false
        return true
    }
    const createTeam = () => {
        if (!isCreatable()) return hideModal()
        let payload = {
            network_id: network._id,
            name: proposedTeam.name,
            team: proposedTeam.name,
            workspace_ids: proposedTeam.workspace_ids,
            role_id: proposedTeam.role_id,
            user_ids: proposedTeam.user_ids
        }
        api.createNetworkTeam(payload, network.slug)
        .then((res) => { return hideModal() })
        .catch((err) => { return hideModal() })
    }
    return (
        <div className="modal-mgmt-background-overlay">
            <div className="modal-mgmt-container">
                <ModalSimpleHeader title="Create a new team" hideModal={hideModal} />

                <div className="modal-mgmt-sections">
                    <div className="modal-mgmt-section">
                        <h3>Team Name</h3>
                        <input type="text" placeholder="UX Design Team" value={proposedTeam.name} onChange={(e) => proposedTeamDispatch({ type: "update_name", value: e.target.value })} />
                    </div>

                    <div className="modal-mgmt-section">
                        <div className="modal-mgmt-section-heading-feedback">
                            <h3>Add Team Members</h3>
                            <span>{proposedTeam.user_ids.length} of {getAllUsers().length} team members selected</span>
                        </div>
                        <ModalToolMultiSelectWorkspaceDropdownWithSearch addWorkspace={toggleUser} removeWorkspace={toggleUser} allWorkspaces={getAllUsers()} selectedWorkspaceIds={proposedTeam.user_ids} type="user"/>
                    </div>

                    <div className="modal-mgmt-section">
                        <div className="modal-mgmt-section-heading-feedback">
                            <h3>Select workspaces</h3>
                            <span>{proposedTeam.workspace_ids.length} of {getWorkspaceOptions().length} selected</span>
                        </div>
                        <ModalToolMultiSelectWorkspaceDropdownWithSearch addWorkspace={toggleWorkspace} removeWorkspace={toggleWorkspace} allWorkspaces={getWorkspaceOptions()} selectedWorkspaceIds={proposedTeam.workspace_ids}/>
                    </div>

                    <div className="modal-mgmt-section">
                        <h3>What role do these team members have?</h3>
                        <ModalToolSingleSelectRoleDropdownWithSearch setSelectedId={updateRole} allOptions={getRoleOptions()} selectedId={proposedTeam.role_id}/>
                    </div>

                    <ModalSimpleSingleButton isValid={isCreatable()} buttonText="Create Team" onclick={createTeam}/>

                </div>
            </div>
        </div>
    )
}
