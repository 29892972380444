import React from 'react'
import { useState, useEffect } from 'react';
import Api from '../../common/APIUtils'
import AssignmentTable from '../assignments/AssignmentTable'
import LoadingSymbolFacebook from '../gadgets/LoadingSymbolFacebook'
import { IoSadOutline } from 'react-icons/io5'
import { getPageTabInitiativeClassName } from '../../common/Helpers';
import Calendar from '../calendars/Calendar';

export default function WorkspaceTags({permissions, workspace, workspaceId, team, tagName}) {
    const api = new Api()
    const [isLoaded, setIsLoaded] = useState(() => { return false })
    const [view, setView] = useState(() => { return "assignments" })
    const [sortBy, setSortBy] = useState(() => { return "date" })
    const [taggedComponents, setTaggedComponents] = useState(() => { return [] })
    const updateWorkspaceComponent = (payload, componentId) => {
        if (!componentId) return
        if (!payload) return
        api.updateWorkspaceComponent(payload, `/workspace/components/${componentId}`)
        .then((res) => fetchWorkspaceTaggedItems())
        .catch((err) => console.log(err))
    }
    const fetchWorkspaceTaggedItems = () => {
        if (!tagName) return
        if (!workspace._id) return
        const payload = {
            workspace_id: workspace._id,
            tag_name: tagName
        }
        api.getWorkspaceTaggedItems(payload)
        .then((res) => {
            setTaggedComponents(res.data.response.components)
            setIsLoaded(true)
        })
        .catch((err) => console.log(err))
    }
    useEffect(() => {
        fetchWorkspaceTaggedItems()
    // eslint-disable-next-line
    }, [tagName, workspace])
    useEffect(() => {
        document.title = `#${tagName}`
    // eslint-disable-next-line
    }, [])
    return (
        <div className="workspace-tags-page">
            <div className="workspace-tags-header-row">
                <h1>#{tagName}</h1>
            </div>

            {!isLoaded &&
            <div className="workspace-tags-not-loaded">
                <LoadingSymbolFacebook/>
            </div>}

            {taggedComponents.length === 0 && isLoaded &&
            <div className="workspace-tags-empty-container">
                <div className="workspace-tags-empty-text"><IoSadOutline/>Welp, there's nothing here</div>
                <p>Tag items by using <span style={{fontSize: "14px"}} className="gadget-hashtag">#{tagName}</span> in their descriptions to get started.</p>
                <div className="workspace-tags-empty-text-instructions">
                    <div>Use tags to organize your ideas and work into different buckets, even when they don't share a project.</div>
                    <div>For example, create a new workstream from <span style={{fontSize: "16px"}} className="gadget-hashtag">#{tagName}</span> in a project to automatically track work from that tag!</div>
                    <div>Tags make searching for items much easier, especially when your workspace has a lot of activity and you don't want to lose track of where everything is at with <span style={{fontSize: "16px"}} className="gadget-hashtag">#{tagName}</span></div>
                </div>
            </div>
            }

            {taggedComponents.length > 0 && isLoaded &&
            <div>
                <div className="component-page-tabs-initiative" style={{justifyContent: "center"}}>
                    {/* <span onClick={() => setView('overview')} className={`component-page-tabs-initiative-tab ${getPageTabInitiativeClassName('overview', view)}`}>Overview</span> */}
                    <span onClick={() => setView('assignments')} className={`component-page-tabs-initiative-tab ${getPageTabInitiativeClassName('assignments', view)}`}>Assignments</span>
                    <span onClick={() => setView('calendar')} className={`component-page-tabs-initiative-tab ${getPageTabInitiativeClassName('calendar', view)}`}>Calendar</span>
                </div>

                {view === "calendar" &&
                <div className="workspace-tags-component-table">
                    <Calendar team={team} fetchEvents={fetchWorkspaceTaggedItems} events={taggedComponents} vertical={true} componentTypeMap={{task: "Tasks", goal: "Goals", initiative: "Projects"}} workspace={workspace} workspaceWorkstreamPermissionMap={false}/>
                </div>
                }

                {view === "assignments" &&
                <div className="workspace-tags-component-table">
                    <AssignmentTable updateWorkspaceComponent={updateWorkspaceComponent} components={taggedComponents} workspaces={[workspace]} sortBy={sortBy} setSortBy={setSortBy} team={team} />
                </div>
                }
            </div>
            }
            
        </div>
    )
}
