import React from 'react'
import { useState } from 'react'
import HProgressBar from '../HProgressBar';
import { useEffect } from 'react';

export default function ModalUpdateKeyResultProgress({hideModal, keyResultComponent, updateComponent}) { 
    const [shownProgress, setShownProgress] = useState(() => { return 0 })
    const [krComponentId, setKrComponentId] = useState(() => { return false })
    const getCurrentProgress = () => {
        if (!keyResultComponent) return 0
        if (!keyResultComponent.progress || keyResultComponent.progress === 0) return 0
        return keyResultComponent.progress * 100
    }
    const getCurrentProgressString = () => {
        if (!keyResultComponent) return "0%"
        if (!keyResultComponent.progress || keyResultComponent.progress === 0) return "0%"
        return `${keyResultComponent.progress * 100}%`
    }
    const getProgressBlockClassNames = (p) => {
        if (p <= shownProgress) return "modal-key-result-update-progress-block modal-key-result-update-progress-block-shaded"
        return "modal-key-result-update-progress-block"
    }
    const updateProgress = () => {
        if (!updateComponent) return hideModal()
        updateComponent({progress: (shownProgress/100)})
        hideModal()
    }
    const progressBlocks = [0,10,20,30,40,50,60,70,80,90,100]
    useEffect(() => {
        setShownProgress(getCurrentProgress())
    // eslint-disable-next-line
    }, [krComponentId])
    useEffect(() => {
        if (!keyResultComponent) return
        if (!keyResultComponent._id) return
        if (krComponentId === keyResultComponent._id) return
        setKrComponentId(keyResultComponent._id)
    // eslint-disable-next-line
    }, [keyResultComponent])
    return (
        <div className="alignment-modal-background">
            <div className="modal-key-result-update-container">
                <h1>Update Progress</h1>
                <div className="modal-project-create-child-form-options">
                    <h2>{getCurrentProgressString()} complete</h2>
                    <HProgressBar percentage={getCurrentProgress()} height={4} />
                </div>
                <div className="modal-project-create-child-form-options modal-key-result-update-form-options">
                    {/* <h2>Update Progress</h2> */}
                    <div className="modal-key-result-update-progress-blocks">
                        {progressBlocks.map((progressBlock) => (
                            <span onClick={() => setShownProgress(progressBlock)} key={`p-${progressBlock}`} className={getProgressBlockClassNames(progressBlock)}>{progressBlock}%</span>
                        ))}
                    </div>
                    <p>Select new progress</p>
                </div>
                <div className="modal-project-create-child-form-buttons">
                    <div className="modal-project-create-child-form-button modal-project-create-child-form-button-create" onClick={updateProgress}>Update Progress</div>
                    <div className="modal-project-create-child-form-button" onClick={hideModal}>Cancel</div>
                </div>                
            </div>
        </div>
    )
}
