import React from 'react'
import { useState, useEffect } from 'react'
import ModalSimpleHeader from './simple/ModalSimpleHeader'
import ModalToolSingleSelectRoleDropdownWithSearch from './tools/ModalToolSingleSelectRoleDropdownWithSearch'
import ModalSimpleMultiRadioSelection from './simple/ModalSimpleMultiRadioSelection'
import ModalSimpleSingleButton from './simple/ModalSimpleSingleButton'
import ToggleSwitch from '../gadgets/ToggleSwitch'
import Api from '../../common/APIUtils'

import { MdChecklist, MdOutlineViewKanban } from "react-icons/md";

export default function ModalCreateWorkstreamInProject({ hideModal, parentProjectComponent }) {
    const api = new Api()
    const [useTemplate, setUseTemplate] = useState(() => { return false })
    const [workstreamName, setWorkstreamName] = useState(() => { return "" })
    const [workstreamType, setWorkstreamType] = useState(() => { return "list" })
    const [isValid, setIsValid] = useState(() => { return false })
    const [templatesLoaded, setTemplatesLoaded] = useState(() => { return false })
    const [templateFetches, setTemplateFetches] = useState(() => { return [] })
    const [availableTemplates, setAvailableTemplates] = useState(() => { return [] })
    const [availableBoardTemplates, setAvailableBoardTemplates] = useState(() => { return [] })
    const [availableListTemplates, setAvailableListTemplates] = useState(() => { return [] })
    const [chosenTemplate, setChosenTemplate] = useState(() => { return false })
    const getComponentTemplates = () => {
        setTemplatesLoaded(false)
        setUseTemplate(false)
        setChosenTemplate(false)
        if (templateFetches.includes(workstreamType)) {
            if (workstreamType === "list") setAvailableTemplates(availableListTemplates)
            if (workstreamType === "board") setAvailableTemplates(availableBoardTemplates)
            return setTemplatesLoaded(true)
        }
        if (!parentProjectComponent) return
        const payload = {
            workspaceId: parentProjectComponent.workspace_id,
            type: workstreamType
        }
        try {
            api.getComponentTemplates(payload)
            .then((res) => {
                if (res.data.response.templates) {
                    const templates = res.data.response.templates
                    // Cache the templates fetched
                    if (workstreamType === "list") {
                        setAvailableListTemplates(templates)
                    } else if (workstreamType === "board") {
                        setAvailableBoardTemplates(templates)
                    }
                    setAvailableTemplates(templates)

                    // Cache the current fetch type
                    let currentTemplateFetches = Array.from(templateFetches)
                    if (!currentTemplateFetches.includes(workstreamType)) {
                        currentTemplateFetches.push(workstreamType)
                        setTemplateFetches(currentTemplateFetches)
                    }
                    setTemplatesLoaded(true)
                } else {
                    setTemplatesLoaded(false)
                    setAvailableTemplates([])
                }
            }) 
        } catch (error) {
            console.log(error)
        }
    }
    const handleSaveButton = () => {
        if (isValid) return publishWorkstream()
    }
    const handleEnterKeyPress = (e) => {
        if (['enter', 'numpadenter'].includes(e.code.toLowerCase())) {
            if (isValid) return publishWorkstream()
        }
    }
    const publishWorkstream = () => {
        if (!isValid) return
        setIsValid(false) // set to false to avoid double submissions
        if (!parentProjectComponent) return hideModal()
        let payload = {
            workspace_id: parentProjectComponent.workspace_id,
            is_published: true,
            display_name: workstreamName,
            proposed_parent_ids: [parentProjectComponent._id],
            attributes: {}
        }
        if (chosenTemplate) payload['template_id'] = chosenTemplate
        api.createWorkspaceComponent(payload, `/components/work/${workstreamType}`)
        .then( (res) => {
            if (res.status === 200) {
            }
            hideModal()
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const slideOptions = [
        {_id: "list", display_name: "List", description: "Create and complete simple tasks", symbol: <MdChecklist/>},
        {_id: "board", display_name: "Board", description: "Track work with cards and columns", symbol: <MdOutlineViewKanban/>},
    ]
    const reviewValidState = () => {
        if (!workstreamName) return setIsValid(false)
        if (workstreamName.trim() === "") return setIsValid(false)
        return setIsValid(true)
    }
    useEffect(() => {
        reviewValidState()
    // eslint-disable-next-line
    }, [workstreamName])
    useEffect(() => {
        getComponentTemplates()
    // eslint-disable-next-line
    }, [workstreamType])
    return (
        <div className="modal-mgmt-background-overlay" style={{zIndex: 200}}>
            <div className="modal-mgmt-container modal-mgmt-container-creator">
                <div>
                    <ModalSimpleHeader hideModal={hideModal} title="Create Workstream" />

                    {/* WORKSTREAM TYPE */}
                    <div className="modal-workstream-settings-setting-single modal-workstream-settings-setting-single-vertical">
                        <div className="modal-workstream-settings-setting-names">
                            <div className="modal-workstream-settings-setting-name">Workstream Type</div>
                        </div>
                    </div>
                    <div>
                        <ModalSimpleMultiRadioSelection options={slideOptions} selectedOption={workstreamType} setSelectedOption={setWorkstreamType} />
                    </div>


                    {/* WORKSTREAM NAME */}
                    <div className="modal-workstream-settings-setting-single modal-workstream-settings-setting-single-vertical">
                        <div className="modal-workstream-settings-setting-names">
                            <div className="modal-workstream-settings-setting-name">Name</div>
                        </div>
                        <div className="modal-workstream-settings-setting-value">
                            <div className="modal-workstream-settings-text-input-container">
                                <input onKeyUp={(e) => handleEnterKeyPress(e)} autoFocus={true} type="text" placeholder="What are these tasks for?" value={workstreamName} onChange={(e) => setWorkstreamName(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    {/* TEMPLATE */}
                    {availableTemplates.length > 0 && templatesLoaded &&
                    <div className="modal-workstream-settings-setting-single">
                        <div className="modal-workstream-settings-setting-names">
                            <div className="modal-workstream-settings-setting-name">Use a template</div>
                            <div className="modal-workstream-settings-setting-name-description">Create your {workstreamType} using an existing set of tasks{workstreamType === "board" ? " and columns" : "."}</div>
                        </div>
                        <div className="modal-workstream-settings-setting-value">
                            <div style={{display: "flex", flexDirection: "row-reverse"}}><ToggleSwitch isChecked={useTemplate} setIsChecked={setUseTemplate} /></div>
                        </div>
                    </div>
                    }

                    {/* TEMPLATE OPTIONS */}
                    {useTemplate && availableTemplates.length > 0 && templatesLoaded &&
                    <div className="modal-workstream-settings-setting-single modal-workstream-settings-setting-single-vertical">
                        <div className="modal-workstream-settings-setting-names">
                            <div className="modal-workstream-settings-setting-name">Select a template</div>
                        </div>
                        <div className="modal-workstream-settings-setting-value">
                            <div className="modal-workstream-settings-text-input-container">
                                <ModalToolSingleSelectRoleDropdownWithSearch displayAttributeName="template_name" searchText="Search templates..." setSelectedId={setChosenTemplate} allOptions={availableTemplates} selectedId={chosenTemplate} positionTop={true}/>
                            </div>
                        </div>
                    </div>
                    }
                </div>

                <div className="modal-workstream-settings-buttons">
                    <ModalSimpleSingleButton isValid={isValid} buttonText="Create Workstream" onclick={handleSaveButton} />
                </div>
            </div>
        </div>
    )
}
