import '../styles/header.css';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import { ReactComponent as Logo } from '../static/assets/logo2.svg';
import { ReactComponent as TetherosBoxedBlueLogo } from '../static/assets/tetheros-boxed-blue.svg';
import { getAcronym } from '../common/Helpers'
import NotificationBell from './header/NotificationBell';
// eslint-disable-next-line
import { BsChatLeftTextFill } from 'react-icons/bs'
import { MdDesktopWindows } from "react-icons/md";
import { IoAddSharp } from "react-icons/io5";

export default function Header({ authenticated, logout, userData, updateStatus }) {
    let history = useHistory();
    const location = useLocation();
    const [path, setPath] = useState(() => { return false })
    const [profileMenuShowing, setProfileMenuShowing] = useState(() => { return false })
    const profileMenuRef = useRef(null)
    const toggleProfileMenuShowing = () => {
        setProfileMenuShowing(!profileMenuShowing)
    }
    const clickLogout = () => {
        hideProfileMenu()
        logout();
        history.push("/login");
    }
    const goToUserPage = () => {
        hideProfileMenu()
        history.push(`/user/${localStorage.getItem("gId")}`)
    }
    const goToSettings = () => {
        hideProfileMenu()
        history.push('/settings')
    }
    const goToFeedback = () => {
        hideProfileMenu()
        history.push('/app/feedback')
    }
    const hideProfileMenu = () => {
        setProfileMenuShowing(false)
    }
    const goToPage = (url) => {
        return history.push(url)
    }
    const getLink = () => {
        if (authenticated) return '/home'
        return '/welcome'
    }
    const openPrivacyPolicy = () => {
        window.open('/privacy-policy', '_blank', 'noopener,noreferrer');
    }
    const getLogoLink = () => {
        if (authenticated) return (
            <Link to={getLink()}>
                <Logo/>
            </Link>
        )
        return (
            <a href={getLink()}><Logo/></a>
        )
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) {
                hideProfileMenu()
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [profileMenuRef])
    useEffect(() => {
        setPath("")
        if (location.pathname === '/desk') setPath("desk")
    }, [location.pathname]);
    return (
        <div>
            {!authenticated && <HeaderUnauthenticated/> }

            {authenticated && (
                <div id="header" className="sticky">
                    <div id="header-left-side" className="header-left-side-responsive">
                        <div data-testid="header-title" id="header-title">
                            {getLogoLink()}
                        </div>
                        <div className={`header-nav-text ${path === "desk" ? "header-nav-text-active" : ""}`}><Link className="unlink" to={`/desk`}>My Desk</Link></div>
                        {/* <div className="header-nav-text"><Link className="unlink" to={`/messages`}>Messages</Link></div> */}
                    </div>
                    <div className="header-left-side-icon-navigation">
                        <div className="header-nav-text-icon" onClick={() => goToPage('/')}><Logo/></div>
                        <div className="header-nav-text-icon" onClick={() => goToPage('/desk')}><MdDesktopWindows/></div>
                        {/* <div className="header-nav-text-icon" onClick={() => goToPage('/messages')}><BsChatLeftTextFill/></div> */}
                    </div>
                    <div className="header-right-side">
                        <div className="header-right-side-auth-items">

                            {/* Notifications Panel */}
                            <NotificationBell/>

                            <div className="header-right-side-auth-items-inner" ref={profileMenuRef}>
                                <div className="header-profile-name-row" onClick={toggleProfileMenuShowing}>
                                    <div className="u-profile-circle-grey-header">
                                        {localStorage.getItem("gProfPicUrl") ? (
                                            <img src={localStorage.getItem("gProfPicUrl")} alt="profile" />
                                        ) : (
                                            <>
                                            {getAcronym(localStorage.getItem("gFullName"))}
                                            </>
                                        )}
                                        
                                    </div>
                                </div>
                                {profileMenuShowing &&
                                <div className="header-profile-dropdown-container">
                                    <div className="header-profile-dropdown-item" onClick={goToUserPage}>My Profile</div>
                                    <div className="header-profile-dropdown-item" onClick={goToSettings}>Settings</div>
                                    <div className="header-profile-dropdown-item" onClick={goToFeedback}>Submit Feedback</div>
                                    <div className="header-profile-dropdown-item" onClick={openPrivacyPolicy}>Privacy Policy</div>
                                    <div className="header-profile-dropdown-item" onClick={clickLogout}>Log Out</div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    {/* <HeaderSmartBar userData={userData} updateStatus={updateStatus} /> */}
                </div>
            )}
        </div>
    )
}

function HeaderUnauthenticated() {
    const history = useHistory();
    const [isShowingMenu, setIsShowingMenu] = useState(() => { return false })
    // const toggleMenu = () => {
    //     setIsShowingMenu(!isShowingMenu)
    // }
    const goToLink = (path) => {
        setIsShowingMenu(false)
        history.push(path)
    }
    return (
        <div id="header-light">
            <div className="header-light-left-links">
                {isShowingMenu && (
                <div className="header-light-left-link-outer">
                    <span><a href="https://docs.tetheros.com" target="_blank" rel="noopener noreferrer" className="unlink">Docs</a></span>
                    <span onClick={() => goToLink("/welcome?q=pricing")}>Pricing</span>
                    <span onClick={() => goToLink("/welcome?q=about")}>About</span>
                </div>
                )}
                <div className="header-light-left-link-wide">
                    <span><a href="https://docs.tetheros.com" target="_blank" rel="noopener noreferrer" className="unlink">Docs</a></span>
                    <span onClick={() => goToLink("/welcome?q=pricing")}>Pricing</span>
                    <span onClick={() => goToLink("/welcome?q=about")}>About</span>
                </div>
            </div>
            <div className="header-light-center-logo-container">
                <TetherosBoxedBlueLogo onClick={() => goToLink("/welcome")}/>
            </div>
            <div className="header-light-right-buttons">
                <div className="header-light-button-login" onClick={() => goToLink("/login")}>Log In</div>
            </div>
        </div>
    )
}

// eslint-disable-next-line
function HeaderSmartBar({ userData, updateStatus }) {
    const [status, setStatus] = useState(() => { return false })
    const [proposedStatus, setProposedStatus] = useState(() => { return "" })
    const [isShowingSmartUpdatePanel, setIsShowingSmartUpdatePanel] = useState(() => { return false })
    const notificationsRef = useRef(null)
    const updateStatusInformation = () => {
        try {
            if (!userData) return
            if (userData.status) {
                setStatus(userData.status)
            } else {
                setStatus(false)
            }
        } catch (error) {
            return
        }
    }
    // TODO: Handle PASTING
    const handleKeyPress = (e) => {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
            return submitStatusUpdate()        
        }
    }
    const handlePaste = (e) => {
        e.preventDefault();
        const pastedText = e.clipboardData.getData('text');
        const maxLength = 140;
        const slicedText = pastedText.slice(0, maxLength);
        // Assuming you have a state variable for the input value, let's call it inputValue
        // Update the state with the sliced text
        setProposedStatus((prevValue) => prevValue + slicedText);
    };
    const submitStatusUpdate = () => {
        if (!proposedStatus || proposedStatus === "") {
            setIsShowingSmartUpdatePanel(false)
            return
        }
        setStatus(proposedStatus)
        updateStatus(proposedStatus)
        setProposedStatus("")
        setIsShowingSmartUpdatePanel(false)
    }
    const cancelShowingStatus = () => {
        setIsShowingSmartUpdatePanel(false)
        setProposedStatus("")
    }
    useEffect(() => {
        updateStatusInformation()
    // eslint-disable-next-line
    }, [userData])
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (notificationsRef.current && !notificationsRef.current.contains(event.target)) {
                cancelShowingStatus()
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [notificationsRef])
    return (
        <div className="header-smart-bar">
            <div className="header-smart-bar-inner" >
                {!status && !isShowingSmartUpdatePanel &&
                <div className="header-smart-bar-display-text" onClick={() => setIsShowingSmartUpdatePanel(true)}>
                    <IoAddSharp/>
                    <span style={{fontWeight: 400}}>Status Update</span>
                </div>
                }
                {isShowingSmartUpdatePanel &&
                <div className="header-smart-bar-display-text" onClick={() => setIsShowingSmartUpdatePanel(false)}>
                    <span style={{fontWeight: 400}}>Cancel</span>
                </div>
                }
                {status && !isShowingSmartUpdatePanel &&
                <div className="header-smart-bar-display-text" title={status} onClick={() => setIsShowingSmartUpdatePanel(true)}>
                    {/* <MdCircle style={getStatusStyle()}/> */}
                    <span>{status}</span>
                </div>
                }
            </div>

            {isShowingSmartUpdatePanel &&
            <div className="header-smart-bar-update-panel" ref={notificationsRef}>
                <div className="header-smart-bar-update-panel-inner">
                    <div className="header-smart-bar-update-panel-controls">
                        <input className="header-smart-bar-update-status-input" maxLength={140} onKeyDown={(e) => handleKeyPress(e)} placeholder="What's on your mind?" autoFocus={true} type="text" value={proposedStatus} onChange={(e) => setProposedStatus(e.target.value)} onPaste={(e) => handlePaste(e)} />
                        <button onClick={submitStatusUpdate}>Update</button>
                    </div>
                </div>
            </div>
            }
            
        </div>
    )
}