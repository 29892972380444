import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom';
import { MdClose } from 'react-icons/md';
import { IoSearchOutline } from 'react-icons/io5'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi'
import Api from '../../common/APIUtils';
import { toCapitalize, getFormattedDisplayWithUnits } from '../../common/Helpers';
import LoadingSymbolInline from '../gadgets/LoadingSymbolInline';
import { parseISO, format } from 'date-fns'

export default function WorkspaceSearch({ workspace, hideWorkspaceSearch, searchResults, searchResultsDispatch }) {
    const api = new Api()
    const history = useHistory()
    const titleLastUpdatedTimestamp = useRef(false);
    const [query, setQuery] = useState(() => { return "" })
    const [recentSearches, setRecentSearches] = useState(() => { return [] })
    const [isAwaitingSearchResults, setIsAwaitingSearchResults] = useState(() => { return false })
    const queryKeyHandler = (e) => {
        if (e.key.toLowerCase() === "escape") hideWorkspaceSearch()
    }
    const addRecentSearch = (term) => {
        let copyOfRecentSearches = Array.from(recentSearches)
        copyOfRecentSearches.push(term)
        const uniqueElements = [...new Set(copyOfRecentSearches)];
        setRecentSearches(uniqueElements.slice(-3))
    }
    const initializeSearch = (term) => {
        setQuery(term)
    }
    const getResultDestination = (result) => {
        if (result.name === "link") return result.attributes.destination
        let destination = `/workspaces/${workspace._id}/c/${result._id}`
        return destination
    }
    const searchForSomething = () => {
        if (!query) return
        if (query.trim() === "") return
        if (query.trim().length < 3) return
        if (!workspace) return
        if (!workspace._id) return
        setIsAwaitingSearchResults(true)
        api.conductWorkspaceSearch(workspace._id, query.trim())
        .then((res) => {
            searchResultsDispatch({ type: "update_results", results: res.data })
            setIsAwaitingSearchResults(false)
            if (res.data.length > 0) addRecentSearch(query)
        })
        .catch((err) => {
            setIsAwaitingSearchResults(false)
        })
    }
    const mouseDownHandler = (event, result) => {
        const destination = getResultDestination(result)
        // Open in a new tab if clicked with middle button on mouse or it is a resource
        if (event.button === 1 || result.name === "link") {
            event.preventDefault()
            const tempLink = document.createElement('a');
            tempLink.href = destination;
            tempLink.target = '_blank'
            tempLink.click();
            return
        }
        history.push(destination)
        hideWorkspaceSearch()
        return
    }
    useEffect(() => {
        if (!query.trim()) return
        setIsAwaitingSearchResults(true)
        titleLastUpdatedTimestamp.current = Date.now()
        const timer = setTimeout(() => {
            if (Date.now() - titleLastUpdatedTimestamp.current < 900) return setIsAwaitingSearchResults(false)
            searchForSomething()
        }, 1000)
        return () => clearTimeout(timer)
    // eslint-disable-next-line
    }, [query])
    return (
        <div className="alignment-modal-background" style={{zIndex: 101}}>
            <div className="modal-intermediate-outer-container">
                <div className="workspace-search-container-responsive-width">
                    <div className="workspace-search-header">
                        <div className="workspace-search-header-inner">
                            <div className="workspace-search-main-searchbar">
                                <div className="workspace-search-main-searchbar-icon"><IoSearchOutline/></div>
                                <input type="text" placeholder={`Search ${workspace.name}`} autoFocus={true} value={query} onKeyUp={(e) => queryKeyHandler(e)} onChange={(e) => setQuery(e.target.value)}/>
                            </div>
                            <div className="workspace-search-header-actions">
                                {query && <div className="workspace-search-header-action" style={{paddingLeft: "8px", paddingRight: "8px", fontSize: "14px"}} onClick={() => setQuery("")}>Clear</div>}
                                <div className="workspace-search-header-action" onClick={hideWorkspaceSearch}><MdClose/></div>
                            </div>
                        </div>
                    </div>
                    <div className="workspace-search-content app-thin-scrollbar">

                        {!query && recentSearches.length > 0 &&
                            <div className="workspace-search-content-section" style={{marginBottom: "20px"}}>
                                <h2>Recent Searches</h2>
                                <div className="workspace-search-recent-searches">
                                    {recentSearches.map((searchTerm) => (
                                        <div className="workspace-search-recent-search" key={searchTerm} onClick={() => {initializeSearch(searchTerm)}}>{searchTerm}</div>
                                    ))}
                                </div>
                            </div>
                        }


                        {!query && searchResults.results.length === 0 &&
                        <div className="workspace-search-empty-searches-container">

                            <div className="workspace-search-content-section">
                                <div style={{color: "#8A8F9F", textAlign: "center", marginTop: "80px"}}>Search results will display here</div>
                            </div>
                            {/* <div className="workspace-search-content-section">
                                <h2>Quick Search</h2>
                                <div className="workspace-search-recent-searches">
                                    <div className="workspace-search-recent-search">My tasks</div>
                                    <div className="workspace-search-recent-search">Due this week</div>
                                    <div className="workspace-search-recent-search">Recently created</div>
                                </div>
                            </div> */}
                        </div>
                        }
                        {((query && searchResults.results.length === 0) || (query && isAwaitingSearchResults)) &&
                        <div className="workspace-search-content-section">
                            <h2>Results</h2>
                            {!isAwaitingSearchResults && <div>Nothing found. Try searching for something else.</div>}
                            {isAwaitingSearchResults && <div style={{width: "40%", borderRadius: "4px", overflow: "hidden", marginTop: "24px"}}>
                                <LoadingSymbolInline/>
                            </div>}
                        </div>
                        }
                        {searchResults.results.length > 0 && !isAwaitingSearchResults &&
                        <div className="workspace-search-content-section">
                            <h2>Results</h2>
                            <div className="workspace-search-results">
                                {searchResults.results.map((result) => (
                                    <SearchResult key={result._id} result={result} handleClick={mouseDownHandler} />
                                ))}
                            </div>
                        </div>
                        }
                    </div>
                    <div className="workspace-search-footer">
                        <div className="workspace-search-footer-inner">
                            {/* <span>Find tasks, messages, and more in the {workspace.name} workspace</span> */}
                            <span>Find tasks, projects, workstreams, and goals in the {workspace.name} workspace</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function SearchResult({ result, handleClick }) {
    const transformComponentDisplayName = (componentName) => {
        if (componentName === "initiative") return "Project"
        if (componentName === "kresult") return "Goal"
        if (componentName === "kpi") return "KPI"
        return toCapitalize(componentName)
    }
    const getStatusDecoration = () => {
        if (["link", "kpi"].includes(result.name)) return <></>
        return <span className={`component-view-title-meta-status-${result.status}`}>{result.status}</span>
    }
    const getResultSubtitle = () => {
        try {
            if (result.name === "initiative") {
                let progress = 0;
                if (result.progress) progress = Math.round(result.progress * 100, 2)
                if (result.status === "complete") progress = 100
                return (
                    <>
                    <span>•</span>
                    <span>{format(parseISO(result.attributes.date_start), 'PP')} - {format(parseISO(result.attributes.date_end), 'PP')}</span>
                    <span>•</span>
                    <span>{progress}% complete</span>
                    </>
                )
            }
            if (["list", "board"].includes(result.name)) {
                let progress = 0;
                if (result.progress) progress = Math.round(result.progress * 100, 2)
                if (result.status === "complete") progress = 100
                return (
                    <>
                    <span>•</span>
                    <span>{progress}% complete</span>
                    </>
                )
            }
            if (result.name === "kresult") {
                return (
                    <>
                    <span>•</span>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "6px"}}>
                        <span>{getFormattedDisplayWithUnits(false, result.attributes.val_start)}</span>
                        <HiOutlineArrowNarrowRight/>
                        <span>{getFormattedDisplayWithUnits(false, result.attributes.val_target)}</span>
                    </div>
                    <span>•</span>
                    <span>{format(parseISO(result.attributes.date_due), 'PP')}</span>
                    </>
                )
            }
            if (result.name === "task") {
                let due = false
                if (result.attributes.date_due) due = result.attributes.date_due
                if (result.status === "completed") {
                    if (result.completed) return <><span>•</span><span>Completed {format(parseISO(result.completed), 'PP')}</span></>
                }
                if (due) return <><span>•</span><span>Due {format(parseISO(due), 'PP')}</span></>
                return <></>
            }
            return <></>
        } catch (error) {
            return <></>
        }
    }
    return (
        <div key={result._id} className="workspace-search-result" onMouseDown={(e) => handleClick(e, result)}>
            <div className="workspace-search-result-info">
                <div className="workspace-search-result-info-name">{result.display_name}</div>
                <div className="workspace-search-result-info-subtitle"><span>{transformComponentDisplayName(result.name)}</span>{getResultSubtitle()}</div>
                
            </div>
            <div className="workspace-search-result-meta">
                {getStatusDecoration()}
            </div>

        </div>
    )

}