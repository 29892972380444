import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md'
import { sortByProperty } from '../../../common/Helpers';

export default function SidebarWorkspaceNavDropdown({workspaces, currentWorkspace}) {
    const history = useHistory();
    const [isShowingWorkspaceMenu, setIsShowingWorkspaceMenu] = useState(() => { return false })
    const [otherWorkspaces, setOtherWorkspaces] = useState(() => { return [] })
    const [workspaceName, setWorkspaceName] = useState(() => {
        if (currentWorkspace.name) return currentWorkspace.name
        return " "
    })
    const menuRef = useRef(null)
    const getRemainingWorkspaces = () => {
        let pinnedWorkspaces = []
        try {
            const storedPins = localStorage.getItem("gPinnedWorkspaceIds")
            pinnedWorkspaces = storedPins.split(",")
        } catch (error) {
            pinnedWorkspaces = []
        }
        let finalPinnedWorkspaceIds = []
        for (let i = 0; i < pinnedWorkspaces.length; i++) {
            const workspaceId = pinnedWorkspaces[i];
            if (workspaceId !== "") finalPinnedWorkspaceIds.push(workspaceId)
        }
        try {
            if (!workspaces) return []
            if (workspaces.length === 0) return []
            // Order by name
            let orderedWorkspaces = sortByProperty(workspaces, "name")
            return orderedWorkspaces.filter((w) => {
                // Filter workspaces not pinned, if pinned workspaces exist
                if (finalPinnedWorkspaceIds.length > 0) {
                    if (!finalPinnedWorkspaceIds.includes(w._id)) {
                        return false
                    }
                }
                // filter the current workspace
                if (w._id === currentWorkspace._id) return false
                return true
            })
        } catch (error) {
            return []
        }

    }
    const manageWorkspaces = () => {
        history.push(`/desk?v=workspaces`)
    }
    const toggleMenuShowing = () => {
        setIsShowingWorkspaceMenu(!isShowingWorkspaceMenu)
    }
    const hideMenu = () => {
        setIsShowingWorkspaceMenu(false)
    }
    const goToOtherWorkspace = (workspace) => {
        if (!workspace._id) return
        hideMenu()
        history.push(`/workspaces/${workspace._id}`)
    } 
    useEffect(() => {
        setWorkspaceName(currentWorkspace.name)
    // eslint-disable-next-line
    }, [currentWorkspace])
    useEffect(() => {
        setOtherWorkspaces(getRemainingWorkspaces())
    // eslint-disable-next-line
    }, [workspaces, currentWorkspace])
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                hideMenu()
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [menuRef])
    return (
        <div className="sidebar-left-workspace-nav-dropdown-container" ref={menuRef}>
            <div className="sidebar-left-workspace-nav-dropdown-workspace-name-group" onClick={toggleMenuShowing}>
                <div className="sidebar-left-workspace-nav-dropdown-workspace-name">
                    <p>{workspaceName}</p>
                </div>
                {otherWorkspaces.length > 0 && !isShowingWorkspaceMenu && <MdOutlineKeyboardArrowDown/>}
                {otherWorkspaces.length > 0 && isShowingWorkspaceMenu && <MdOutlineKeyboardArrowUp/>}
            </div>

            {(isShowingWorkspaceMenu && otherWorkspaces.length > 0) &&
            <div className="sidebar-left-workspace-nav-dropdown-workspace-dropdown-container">
                {otherWorkspaces.map((workspace) => (
                    <div key={workspace._id} className="sidebar-left-workspace-nav-dropdown-workspace-dropdown-item" onClick={() => goToOtherWorkspace(workspace)}>
                        {workspace.name}
                    </div>
                ))}
                <div className="sidebar-left-workspace-nav-dropdown-see-more" onClick={manageWorkspaces}>
                    <span>Manage Workspaces</span>
                </div>
            </div>
            }

        </div>
    )
}
