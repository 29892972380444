import React from 'react'
import { useState, useEffect, useReducer, useRef, useMemo } from 'react'
import { useHistory } from "react-router-dom";

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { startOfWeek, endOfWeek, addDays, format, addWeeks, parseISO, formatDistanceToNowStrict, isFuture } from 'date-fns';
import { MdClose } from "react-icons/md";
import { BiCalendar } from 'react-icons/bi'
import { GiCheckMark } from 'react-icons/gi'
import { IoChevronBack, IoChevronForward, IoChevronDown, IoChevronUp, IoSettingsOutline, IoCheckmark, IoCalendarClear, IoListSharp, IoSearchOutline } from "react-icons/io5";
import { MdAccessTime, MdOutlineMessage } from "react-icons/md";

import GenericToolHeader from './GenericToolHeader'
import LoadingSymbolGrid from '../gadgets/LoadingSymbolGrid'
import PromotionCalendarModal from './PromotionCalendarModal';
import PromotionCalendarSettingsModal from './PromotionCalendarSettingsModal';
import { Calendar } from 'react-date-range';

import { sortByDateStringAttribute } from '../../common/Helpers';
import Api from '../../common/APIUtils';

export default function PromotionCalendar({ authenticated, toolId }) {
    const history = useHistory()
    const api = new Api()
    const [eventData, eventDataDispatch] = useReducer((state, action) => {
        if (action.type === "init") {
            return { ...state, ...action.data }
        }
        if (action.type === "reset") {
            return { loaded: false }
        }
        if (action.type === "update_name") {
            return { ...state, display_name: action.value }
        }
        if (action.type === "update_events") {
            return { ...state, events: action.events }
        }
        if (action.type === "create_event") {
            let existingEvents = Array.from(state.events)
            existingEvents.push(action.proposed_event)
            return { ...state, events: existingEvents }
        }
        if (action.type === "update_event") {
            // Get event by event id in existing state matching action.proposed_event
            let events = Array.from(state.events)
            const existingEvent = events.find((event) => event._id.toString() === action.proposed_event._id.toString())
            const index = events.indexOf(existingEvent)
            if (index < 0) return state
            events[index] = action.proposed_event
            return { ...state, events: events }
        }
        if (action.type === "delete_event") {
            // Get event by event id in existing state matching action.proposed_event
            let events = Array.from(state.events)
            const existingEvent = events.find((event) => event._id.toString() === action.proposed_event._id.toString())
            const index = events.indexOf(existingEvent)
            if (index < 0) return state
            events.splice(index, 1)
            return { ...state, events: events }
        }
        return state
    }, { calendar_name: false })
    const [isBuilding, setIsBuilding] = useState(() => { return false })
    const [isLoading, setIsLoading] = useState(() => { return true })
    const [errorMessage, setError] = useState(() => { return false })
    const fetchEventData = () => {
        try {
            if (!toolId) return setError("This event no longer exists")
            api.getEventPlanMagnet({id: toolId, email: "kevin@tetheros.com"})
            .then((res) => {
                if (res.data.code === 500) {
                    return setError("Something went wrong fetching the event plan")
                } else {
                    if (res.data.status === "building") {
                        setIsLoading(true)
                        setIsBuilding(true)
                    } else {
                        eventDataDispatch({ type:"init", data: res.data.data })
                    setIsLoading(false)
                    }
                }
            })
            .catch((err) => { return setError("Something went wrong fetching the event plan") })
        } catch (error) {
            return setError("This event no longer exists")
        }
    }
    const updateEventData = () => {
        if (!toolId) return
        if (isLoading || isBuilding) return
        api.updateEventPlanMagnet({ _id: toolId, events: eventData.events, display_name: eventData.display_name, event_date: eventData.event_date })
        .then((res) => {
            return
        })
        .catch((err) => { return })
    }
    const getTitleSuffix = () => {
        try {
            if (!eventData) return false
            if (!eventData.event_date) return false
            const startDate = parseISO(eventData.event_date)
            if (isFuture(startDate)) return `${formatDistanceToNowStrict(startDate, { unit: "day"})} away → ${format(startDate, 'M/d/yyyy')}`
            return format(startDate, "M/d/yyyy")
        } catch (error) {
            return false
        }
    }
    const reloadEventData = () => {
        setIsLoading(true)
        fetchEventData()
    }
    useEffect(() => {
        reloadEventData()
    // eslint-disable-next-line
    }, [toolId])
    useEffect(() => {
        if (!errorMessage) return
        setIsLoading(false)
    // eslint-disable-next-line
    }, [errorMessage])
    useEffect(() => {
        if (eventData.display_name) document.title = eventData.display_name
        updateEventData()
    // eslint-disable-next-line
    }, [eventData])
    return (
        <div className="tools-container">
            <GenericToolHeader symbol="events" authenticated={authenticated} title={eventData.display_name ? eventData.display_name : "Event Plan"} subtitle="Interactive Event Plan (Beta)" titleSuffix={getTitleSuffix()}/>
            {isLoading && !errorMessage && !isBuilding &&
            <div className="util-row util-justify-center" style={{marginTop: "40px"}}><LoadingSymbolGrid/></div>
            }
            {!isLoading && errorMessage &&
            <div className="tools-error-message">{errorMessage}</div>
            }
            {isLoading && isBuilding &&
            <div className="tools-error-message">
                <div className="util-row util-col util-align-center util-justify-center" style={{gap: "40px"}}>
                    <div className="tools-loading-loader"></div>
                    Your event plan is still building. We will email you when it's ready!
                    {authenticated && <div style={{color: "#2a73ae"}} onClick={() => history.push('/home')}>Go to Tetheros</div>}
                    {!authenticated && <div style={{color: "#2a73ae"}}  onClick={() => history.push('/login')}>Log in to Tetheros</div>}
                </div>
                
            </div>
            }
            {!isLoading && !errorMessage &&
            <div className="public-tool-promotion-calendar-container">

                <div className="public-tool-promotion-calendar-main">
                    <BeautifulCalendar eventData={eventData} eventDataDispatch={eventDataDispatch} toolId={toolId} />
                </div>

                <div className="public-tool-promotion-calendar-responsive">
                    <h2>Ope!</h2>
                    <span>That's how we say "our mobile version isn't ready" in <span style={{fontStyle: "italic", fontWeight: 500}}>Midwestern</span></span>
                    <span style={{marginTop: "20px", marginBottom: "20px"}}>To view the <span style={{fontWeight: 600}}>{eventData.display_name}</span> event calendar, please use a larger device.</span>
                    
                    {authenticated && <div style={{color: "#2a73ae"}} onClick={() => history.push('/home')}>Go to Tetheros</div>}
                    {!authenticated && <div style={{color: "#2a73ae"}}  onClick={() => history.push('/login')}>Log in to Tetheros</div>}
                </div>
            </div>
            }
        </div>
    )
}

function BeautifulCalendar({ eventData, eventDataDispatch, toolId }) {
    const [isShowingNewItemModal, setIsShowingNewItemModal] = useState(false);
    const [isShowingCalendarSelect, setIsShowingCalendarSelect] = useState(false);
    const [proposedDay, setProposedDay] = useState(() => { return false });
    const [isDragging, setIsDragging] = useState(() => { return false });
    const [view, setView] = useState(() => { return "calendar" });
    const [movedEvent, setMovedEvent] = useState(() => { return false });
    const [isShowingManageModal, setIsShowingManageModal] = useState(() => { return false })
    const [isShowingSettings, setIsShowingSettings] = useState(() => { return false })
    const [selectedEventForManagement, setSelectedEventForManagement] = useState(() => { return false })
    const [isShowingTimespanOptions, setIsShowingTimespanOptions] = useState(() => { return false })
    const [isShowingListFilterOptions, setIsShowingListFilterOptions] = useState(() => { return false })
    const [query, setQuery] = useState(() => { return "" });
    const [listFilter, setListFilter] = useState(() => { return "all" });
    const [renderedWeeks, setRenderedWeeks] = useState(() => { return 3 });
    const [orderedEvents, setOrderedEvents] = useState(() => { return eventData.events });
    const [dateRange, setDateRange] = useState(() => {
        const start = startOfWeek(new Date());
        const end = endOfWeek(addWeeks(start, 2));
        return { startDate: start, endDate: end };
    });
    const availableColors = [
        "#FFFFFF", // White
        "#EBECFF", // Light Purple
        "#EDF7EE", // Light Green
        "#FFFAE7", // Light Yellow
        "#FEECEC", // Light Red
        "#EEF2F3", // Light Blue-Gray
        "#C4E7D4", // Soft mint
        "#F4B5BD", // Pastel Coral
        "#C9E1F2", // Light sky blue
        // "#E8B6CF", // Soft Pink
        // "#F2D22E", // Vibrant Yellow
        // "#8D909B", // Cool Gray
        // "#559CAC", // Calm Blue
        // "#3A9A5F", // Professional Green
        // "#9B79B9", // Bold Purple
        // "#398CD0", // Brand Blue
        // "#F29C1F", // Complementary Orange
        // "#D94E1F", // Alert Red
    ];
    const createProposedItem = (newItem) => {
        eventDataDispatch({ type: 'create_event', proposed_event: newItem })
      };
    const navigateWeek = (direction) => {
        const currentStartDate = dateRange.startDate
        if (direction === "forward") {
            const proposedStart = addWeeks(currentStartDate, 1)
            const proposedEnd = endOfWeek(addWeeks(proposedStart, renderedWeeks - 1))
            setDateRange( {startDate: proposedStart, endDate: proposedEnd })
            return
        }
        if (direction === "backward") {
            const proposedStart = addWeeks(currentStartDate, -1)
            const proposedEnd = endOfWeek(addWeeks(proposedStart, renderedWeeks - 1))
            setDateRange( {startDate: proposedStart, endDate: proposedEnd })
            return
        }
        const start = startOfWeek(new Date());
        const end = endOfWeek(addWeeks(start, renderedWeeks - 1));
        setDateRange({ startDate: start, endDate: end })
    };
    const moveEvent = (eventId, newDate) => {
        // Remove the event from the events list initially
        setMovedEvent({ id: eventId, date: newDate })
        const updatedEvents = eventData.events.map(event => {
            if (event._id.toString() === eventId) {
                return { ...event, attributes: {...event.attributes, date_due: newDate} };
            }
            return event;
        });
        eventDataDispatch({ type: 'update_events', events: updatedEvents });
        setIsDragging(false)
        // setMovedEvent(false)
    };
    const onDragStart = (start) => {
        setIsDragging(true)
    };
    const onDragEnd = (result) => {
        const { source, destination, draggableId } = result;
        if (!destination) return setIsDragging(false);
        if (source.droppableId === destination.droppableId) return setIsDragging(false);
        moveEvent(draggableId, destination.droppableId);
    };
    const createNewEvent = (day) => {
        if (isShowingManageModal || isShowingSettings) return
        setIsShowingNewItemModal(true)
        setProposedDay(day)
    }
    const daysHeader = useMemo(() => {
        return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].map(day => (
            <div className="public-tool-promotion-calendar-col public-tool-promotion-calendar-col-heading" key={day}>
                {day}
            </div>
        ));
    }, []);
    const timespanOptions = [
        { display_name: "Week", value: 1 },
        { display_name: "Fortnight", value: 2 },
        { display_name: "3 weeks", value: 3 },
        { display_name: "4 weeks", value: 4 },
        { display_name: "5 weeks", value: 5 },
    ]
    const listFilterOptions = [
        { display_name: "All tasks", value: "all" },
        { display_name: "In progress", value: "active" },
        { display_name: "Completed", value: "completed" },
    ]
    const handleDateChange = (d) => {
        try {
            setIsShowingCalendarSelect(false)
            const proposedStart = startOfWeek(d);
            const proposedEnd = endOfWeek(addWeeks(proposedStart, renderedWeeks - 1))
            setDateRange( {startDate: proposedStart, endDate: proposedEnd })
        } catch (error) {
            return setIsShowingCalendarSelect(false)
        }
    }
    const updateTimespanSelection = (opt) => {
        setIsShowingTimespanOptions(false)
        setRenderedWeeks(opt.value)
        // Updated dateRange
        const currentStart = dateRange.startDate
        const end = endOfWeek(addWeeks(currentStart, opt.value - 1));
        setDateRange({ startDate: currentStart, endDate: end })
    }
    const getCurrentTimespanText = () => {
        const ts = timespanOptions.find((opt) => opt.value === renderedWeeks)
        return ts.display_name
    }
    const getCurrentListFilterName = () => {
        const ts = listFilterOptions.find((opt) => opt.value === listFilter)
        return ts.display_name
    }
    const updateListFilter = (opt) => {
        setListFilter(opt)
        setIsShowingListFilterOptions(false)
    }
    const closeManageModal = () => {
        setIsShowingManageModal(false)
        setSelectedEventForManagement(false)
    }
    const getColorFromSelection = (proposedView) => {
        if (view === proposedView) return {}
        return { color: "grey" }
    }
    const updateOrderedEvents = () => {
        try {
            const eventCopy = Array.from(eventData.events)
            const eventsWithDates = eventCopy.filter((event) => {
                if (!event.attributes) return false
                if (!event.attributes.date_due) return false
                return true
            })
            let orderedByDate = sortByDateStringAttribute(eventsWithDates, true, "display_name")
            if (query) {
                orderedByDate = orderedByDate.filter((content) => {
                    const searchableText = `${content.display_name}${content.attributes.description}`
                    if (searchableText.toLowerCase().indexOf(query.toLowerCase()) !== -1) return true
                    return false
                })
            }
            if (listFilter === "all") return setOrderedEvents(orderedByDate)
            orderedByDate = orderedByDate.filter((content) => {
                if (content.status === listFilter) return true
                return false
            })
            return setOrderedEvents(orderedByDate)
        } catch (error) {
            console.log(error)
            return setOrderedEvents(eventData.events)
        }
    }
    const selectItemToManage = (e, item) => {
        if (e) e.stopPropagation()
        setSelectedEventForManagement(item)
        setIsShowingManageModal(true)
    }
    const createItemFromListView = () => {
        setProposedDay(new Date())
        setIsShowingNewItemModal(true)
    }
    const cells = useMemo(() => {
        let day = dateRange.startDate;
        const rows = [];
        while (day <= dateRange.endDate) {
            const days = [];
            for (let i = 0; i < 7 && day <= dateRange.endDate; i++) {
                days.push(
                    <PromotionCalendarCell createNewEvent={createNewEvent} selectItemToManage={selectItemToManage} movedEvent={movedEvent} isDragging={isDragging} day={day} currentEvents={eventData.events} key={day.toISOString()} startDate={dateRange.startDate} />
                );
                day = addDays(day, 1);
            }
            rows.push(<div className="public-tool-promotion-calendar-row" key={day.toISOString()}>{days}</div>);
        }
        return rows;
    // eslint-disable-next-line
    }, [dateRange, eventData.events])
    useEffect(() => {
        updateOrderedEvents()
    // eslint-disable-next-line
    }, [eventData, listFilter, query])
    return (
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>

            {isShowingSettings && <PromotionCalendarSettingsModal hideModal={() => setIsShowingSettings(false)} eventData={eventData} eventDataDispatch={eventDataDispatch} toolId={toolId}/>}
            {isShowingManageModal && selectedEventForManagement && <PromotionCalendarModal hideModal={closeManageModal} event={selectedEventForManagement} eventDataDispatch={eventDataDispatch} availableColors={availableColors} /> }
            {isShowingNewItemModal && proposedDay && <NewItemModal hideModal={() => setIsShowingNewItemModal(false)} createProposedItem={createProposedItem} proposedDay={proposedDay} availableColors={availableColors}/>}

            <div className="public-tool-promotion-calendar-calendar">
                <div className="public-tool-promotion-calendar-header">
                    {/* LEFT */}
                    <div className="public-tool-promotion-calendar-header-actions util-row-reverse">
                        <div className="util-row util-align-end" style={{gap: "20px"}}>
                            <div className="public-tool-promotion-calendar-header-action-icon-text" onClick={() => setView("calendar")}>
                                <div className="public-tool-promotion-calendar-header-action-icon"><IoCalendarClear style={getColorFromSelection("calendar")}/></div>
                                <span style={getColorFromSelection("calendar")}>Calendar</span>
                            </div>
                            <div className="public-tool-promotion-calendar-header-action-icon-text" onClick={() => setView("list")}>
                                <div className="public-tool-promotion-calendar-header-action-icon"><IoListSharp style={getColorFromSelection("list")} /></div>
                                <span style={getColorFromSelection("list")}>List</span>
                            </div>
                        </div>

                    </div>

                    {/* MIDDLE */}
                    {view === "calendar" &&
                    <div className="public-tool-promotion-calendar-header-nav">
                        {!isShowingCalendarSelect && <div className="public-tool-promotion-calendar-header-nav-button" onClick={() => navigateWeek("backward")}><IoChevronBack/></div>}
                        <div className="public-tool-promotion-calendar-header-nav-text" onClick={() => setIsShowingCalendarSelect(!isShowingCalendarSelect)}>
                            {isShowingCalendarSelect ? "Select a date" : `${format(dateRange.startDate, "MMM d")} - ${format(dateRange.endDate, "PP")}`}
                        {isShowingCalendarSelect &&
                        <div className="public-tool-promotion-calendar-header-selection-container" onClick={(e) => { e.stopPropagation() }}>
                            <Calendar date={dateRange.startDate} onChange={handleDateChange} />
                        </div>
                        }
                        </div>
                        {!isShowingCalendarSelect && <div className="public-tool-promotion-calendar-header-nav-button" onClick={() => navigateWeek("forward")}><IoChevronForward/></div>}
                    </div>
                    }
                    {view === "list" &&
                    <div className="public-tool-promotion-calendar-header-nav">
                        <div className="public-tool-promotion-calendar-header-create-item" onClick={createItemFromListView}>+ New task</div>
                    </div>
                    }

                    {/* RIGHT */}
                    <div className="public-tool-promotion-calendar-header-actions">
                        {view === "calendar" &&
                        <div className="public-tool-promotion-calendar-header-timespan-container">
                            <div className="public-tool-promotion-calendar-header-timespan-selected" onClick={() => setIsShowingTimespanOptions(!isShowingTimespanOptions)}>{getCurrentTimespanText()} {isShowingTimespanOptions ? <IoChevronUp/> : <IoChevronDown/>}</div>
                            {isShowingTimespanOptions &&
                            <div className="public-tool-promotion-calendar-header-timespan-menu">
                                {timespanOptions.map((timespanOption) => (
                                    <span key={timespanOption.value} onClick={() => updateTimespanSelection(timespanOption)}>{timespanOption.display_name}</span>
                                ))}
                            </div>
                            }
                        </div>
                        }

                        {view === "list" &&

                            <div className="public-tool-promotion-calendar-header-action-search" style={{maxWidth: "300px"}}>
                                <IoSearchOutline/>
                                <input style={{backgroundColor: "rgba(242, 242, 242, 1)"}} type="text" placeholder="Search..." value={query} onChange={(e) => setQuery(e.target.value)} />
                            </div>

                        }

                        {view === "list" &&
                        <div className="public-tool-promotion-calendar-header-timespan-container">
                            <div className="public-tool-promotion-calendar-header-timespan-selected" style={{width: "160px"}} onClick={() => setIsShowingListFilterOptions(!isShowingListFilterOptions)}>{getCurrentListFilterName()} {isShowingListFilterOptions ? <IoChevronUp/> : <IoChevronDown/>}</div>
                            {isShowingListFilterOptions &&
                            <div className="public-tool-promotion-calendar-header-timespan-menu" style={{width: "160px"}}>
                                {listFilterOptions.map((option) => (
                                    <span key={option.value} onClick={() => updateListFilter(option.value)}>{option.display_name}</span>
                                ))}
                            </div>
                            }
                        </div>
                        }


                        <div className="public-tool-promotion-calendar-icon-button" onClick={() => setIsShowingSettings(true)}>
                            <IoSettingsOutline/>
                        </div>
                    </div>
                </div>
                {view === "calendar" &&
                <div className="public-tool-promotion-calendar-primary-container">
                    <div className="public-tool-promotion-calendar-days">{daysHeader}</div>
                    <div className="public-tool-promotion-calendar-calendar-body">{cells}</div>
                </div>
                }
                {view === "list" &&
                <div className="public-tool-promotion-calendar-primary-container-list app-thin-scrollbar">
                    <div className="public-tool-promotion-calendar-primary-container-list-tasks app-thin-scrollbar">
                        {orderedEvents.map((event) => (
                            <ListItem key={event._id} task={event} selectItemToManage={selectItemToManage} eventDataDispatch={eventDataDispatch} />
                        ))}
                        {orderedEvents.length === 0 && query &&
                        <div style={{textAlign: "center", fontSize: "14px"}}>No items match your search term</div>
                        }
                        {orderedEvents.length === 0 && !query &&
                        <div style={{textAlign: "center", fontSize: "14px"}}>There's nothing here...</div>
                        }
                    </div>                    
                </div>
                }
            </div>
        </DragDropContext>
    );
}

function PromotionCalendarCell({ day, currentEvents, startDate, isDragging, movedEvent, selectItemToManage, createNewEvent }) {
    const [eventsForDay, setEventsForDay] = useState(() => { return []})
    const formattedDate = format(day, 'yyyy-MM-dd')
    const [isShowingMoreEvents, setIsShowingMoreEvents] = useState(() => { return false })
    const [calculatedAllowedEvents, setCalculatedAllowedEvents] = useState(() => { return -1 } )
    // const eventsForDay = currentEvents.filter((event) => event.date === formattedDate);
    const calendarCellRef = useRef(null)
    const [displayedEvents, setDisplayedEvents] = useState(() => { return eventsForDay })
    const getDayNumberClass = () => {
        try {
            if(format(day, 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd')) return "public-tool-promotion-calendar-number-today"
            return ""
        } catch (error) {
            return ""
        }
    }
    const getMonthDecorator = () => {
        const num = format(day, 'd')
        if (num.toString() === "1") return format(day, 'MMMM')
        if (format(day, 'yyyy-MM-dd') === format(startDate, 'yyyy-MM-dd')) return format(day, 'MMMM')
        return false
    }
    const calculateDisplayEvents = (height) => {
        if (isDragging) return
        let node = calendarCellRef.current;
        if (!node) return
        const CELL_NUMBER_HEIGHT_PX = 28;
        const EVENT_HEIGHT_PX = 32;
        const SHOW_MORE_BUTTON_HEIGHT_ALLOWANCE_PX = 22;
        try {
            const allowedEventCount = Math.floor((height - CELL_NUMBER_HEIGHT_PX - SHOW_MORE_BUTTON_HEIGHT_ALLOWANCE_PX) / EVENT_HEIGHT_PX)
            setCalculatedAllowedEvents(allowedEventCount)
            // if (calculatedAllowedEvents >= eventsForDay.length) return setDisplayedEvents(eventsForDay)
            // setDisplayedEvents(eventsForDay.slice(0, calculatedAllowedEvents))
        } catch (error) {
            return
        }
    }
    const updateDailyEvents = () => {
        try {
            if (movedEvent) {
                setEventsForDay(currentEvents.filter((event) => {
                    if (event.attributes.date_due !== formattedDate) return false
                    if (event._id.toString() === movedEvent.id && event.attributes.date_due === movedEvent.date) return true
                    if (event._id.toString() === movedEvent.id && event.attributes.date_due !== movedEvent.date) return false
                    return true
                }))
                // remove from the displayed events
                const currentDisplayedEvents = Array.from(displayedEvents)
                const foundEvent = currentDisplayedEvents.find((e) => e._id.toString() === movedEvent.id.toString())
                const index = currentDisplayedEvents.indexOf(foundEvent)
                if (index < 0) return
                setDisplayedEvents(currentDisplayedEvents.splice(index, 1))
                return
            }
            if (!movedEvent && !isDragging) {
                setEventsForDay(currentEvents.filter((event) => event.attributes.date_due === formattedDate))
                return
            }
        } catch (error) {
            console.log(error)
        }

    }
    const selectItemFromModal = (event) => {
        setIsShowingMoreEvents(false)
        selectItemToManage(false, event)
    }
    const showMoreEventsModal = (e) => {
        if (e) e.stopPropagation()
        setIsShowingMoreEvents(true)
    }
    const handleCellClick = () => {
        if (isShowingMoreEvents) return
        createNewEvent(day)
    }
    useEffect(() => {
        if (isDragging) return
        const observer = new ResizeObserver(entries => {
            for (const entry of entries) {
                const { height } = entry.contentRect;
                calculateDisplayEvents(height);
            }
        });
        if (calendarCellRef.current) observer.observe(calendarCellRef.current)
        return () => {
            observer.disconnect();
        };
    // eslint-disable-next-line
    }, [eventsForDay]);
    useEffect(() => {
        updateDailyEvents()
    // eslint-disable-next-line
    }, [ currentEvents, isDragging ])
    return (
        <div className="public-tool-promotion-calendar-col public-tool-promotion-calendar-cell" ref={calendarCellRef} onClick={handleCellClick}>
            <span className={`public-tool-promotion-calendar-number ${getDayNumberClass()}`}>{format(day, 'd')}</span>
            <div className="public-tool-promotion-calendar-month-decorator">{getMonthDecorator()}</div>
            <Droppable droppableId={formattedDate} key={formattedDate}>
                {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps} className={`public-tool-promotion-calendar-events ${snapshot.isDraggingOver ?   'dragging-over' : ''}`}>
                        
                    {eventsForDay.slice(0, calculatedAllowedEvents).map((event, index) => (
                        <Draggable key={event._id} draggableId={`${event._id}`} index={index}>
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={`public-tool-promotion-calendar-event-single ${snapshot.isDragging ? 'dragging' : ''}`} onClick={(e) => selectItemToManage(e, event)} style={{...provided.draggableProps.style, backgroundColor: event.color_hex ? event.color_hex : "#FFFFFF"}}>
                                {event.status === "completed" && <div className="public-tool-promotion-calendar-event-single-icon"><IoCheckmark/></div>}
                                <div className={`public-tool-promotion-calendar-event-single-name ${event.status === "completed" ? "complete" : ""}`}>{event.display_name}</div>
                            </div>
                        )}
                        </Draggable>
                    ))}
                    
                    {provided.placeholder}
                    </div>
            )}
            </Droppable>
            {eventsForDay.length > eventsForDay.slice(0, calculatedAllowedEvents).length &&
                <div className="public-tool-promotion-calendar-cell-overflow-button">
                    <div onClick={(e) => showMoreEventsModal(e)}>+{eventsForDay.length - eventsForDay.slice(0, calculatedAllowedEvents).length} more</div>
                    {isShowingMoreEvents && <PromotionCalendarShowMoreModal selectItemFromModal={selectItemFromModal} events={eventsForDay} hideModal={() => setIsShowingMoreEvents(false)} day={day} />}
                </div>
            }
        </div>
    )
}

function PromotionCalendarShowMoreModal({ events, hideModal, day, selectItemFromModal }) {
    return (
        <div className="public-tool-promotion-calendar-modal-overlay" style={{cursor: "default"}}>
            <div className="public-tool-promotion-calendar-modal-container public-tool-promotion-calendar-modal-container-settings" style={{paddingBottom: "20px"}}>
                <div className="public-tool-promotion-calendar-modal-header">
                    <h2>{format(day, 'MMMM d, yyyy')}</h2>
                    <div className="util-row util-align-center" style={{ gap: "20px" }}>
                        <div onClick={hideModal} className="public-tool-promotion-calendar-modal-header-action"><MdClose /></div>
                    </div>
                </div>
                <div className="public-tool-promotion-calendar-cell-overflow-button-items">
                    {events.map((event) => (
                        <div key={event._id} className="public-tool-promotion-calendar-cell-overflow-button-item" onClick={() => selectItemFromModal(event)}>
                            {event.status === "completed" && <div className="public-tool-promotion-calendar-event-single-icon"><IoCheckmark/></div>}
                            {event.status === "active" && <div className="public-tool-promotion-calendar-cell-overflow-button-item-color" style={{backgroundColor: event.color_hex}}></div>}
                            <span>{event.display_name}</span>
                        </div>
                    ))}
                </div>
            </div>
            
        </div>

    )
}

function NewItemModal({ hideModal, createProposedItem, proposedDay, availableColors }) {
    const [title, setTitle] = useState(() => { return "" });
    const [description, setDescription] = useState(() => { return "" });
    const [dueDate, setDueDate] = useState(() => { return format(proposedDay, 'yyyy-MM-dd') });
    const [selectedColor, setSelectedColor] = useState(() => { return '#FFFFFF' });
    const [isShowingColorSelect, setIsShowingColorSelect] = useState(() => { return false });
    const handleColorSelect = (color) => {
        setSelectedColor(color);
        setIsShowingColorSelect(false);
    };
    const handleSave = () => {
        if (title.trim() === '') return
        let tempId = Math.round(new Date().getTime() / 1000)
        const newItem = {
            display_name: title,
            _id: tempId,
            attributes: {
                description,
                date_due: dueDate,
            },
            color_hex: selectedColor,
            status: 'active',
        }
        createProposedItem(newItem)
        hideModal()
    };
    return (
        <div className="public-tool-promotion-calendar-modal-overlay" style={{ cursor: 'default' }}>
            <div className="public-tool-promotion-calendar-modal-container public-tool-promotion-calendar-modal-container-settings">
                <div className="public-tool-promotion-calendar-modal-header">
                    <h2>New Event Task</h2>
                    <div className="util-row util-align-center" style={{ gap: '20px' }}>
                        <div className="public-tool-promotion-calendar-modal-header-action" onClick={hideModal}><MdClose /></div>
                    </div>
                </div>
                <div className="public-tool-promotion-calendar-modal-titles public-tool-promotion-calendar-modal-edits">

                    <input placeholder="What needs to get done?" autoFocus={true} value={title} onChange={(e) => setTitle(e.target.value)}/>

                    <div className="public-tool-promotion-calendar-modal-edits-label" style={{marginTop: "20px"}}><MdAccessTime/>Due Date</div>
                    <input type="date" value={dueDate} onChange={(e) => setDueDate(e.target.value)} required={true} />

                    <div className="public-tool-promotion-calendar-modal-edits-label"><MdOutlineMessage/>Task Description</div>
                    <textarea rows={6}  placeholder="Provide description, instructions, or ideas here" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>

                </div>

                <div className="public-tool-promotion-calendar-modal-footer">
                    <div className="public-tool-promotion-calendar-modal-color-selection-container">
                        <div className="public-tool-promotion-calendar-modal-color-selection-selected" onClick={() => setIsShowingColorSelect(!isShowingColorSelect)} style={{ backgroundColor: selectedColor }}></div>
                        {isShowingColorSelect && (
                        <div className="public-tool-promotion-calendar-modal-color-selections" style={{ top: "unset", bottom: 0 }}>
                            {availableColors.map((color) => (
                            <div key={color} className="public-tool-promotion-calendar-modal-color-selection" style={{ backgroundColor: color }} onClick={() => handleColorSelect(color)}></div>
                            ))}
                        </div>
                        )}
                    </div>
                    <div className="public-tool-promotion-calendar-modal-footer-save" onClick={handleSave}>Save</div>
                </div>
            </div>
        </div>
    );
}

function ListItem({ task, selectItemToManage, eventDataDispatch }) {
    const getActionCircle = () => {
        if (task.status === 'active') {
            return (
                <span className="component-list-task-action-circle" onClick={completeTask}></span>
            )
        }
        return (
            <span className="component-list-task-action-circle component-list-task-action-circle-completed" onClick={restoreTask}>
                <GiCheckMark style={{color: "#9F6ADB"}}/>
            </span>
        )
    }
    const getAdditionalStyles = () => {
        return {}
    }
    const completeTask = (e) => {
        e.stopPropagation()
        eventDataDispatch({ type: "update_event", proposed_event: {
            ...task,
            status: "completed"
        }})
    }
    const restoreTask = (e) => {
        e.stopPropagation()
        eventDataDispatch({ type: "update_event", proposed_event: {
            ...task,
            status: "active"
        }})
    }
    const getAdditionalNameStyles = () => {
        let baseStyle = { width: "100%", whiteSpace: "pre", overflow: "hidden", textOverflow: "ellipsis" }
        if (task.status === "completed") return {...baseStyle, fontStyle: "italic", color: "rgb(78, 78, 78)"}
        return {}
    }
    const getDate = () => {
        try {
            return format(parseISO(task.attributes.date_due), 'L/d/yyyy')
        } catch (error) {
            return ""
        }
    }
    return (
        <div className="public-tool-promotion-calendar-list-task-container" style={getAdditionalStyles()} onClick={(e) => selectItemToManage(e, task)}>
            <div className="component-list-task-container-section-summary" style={{overflow: "hidden", flexBasis: "unset", flexGrow: "1", textOverflow: "ellipsis"}}>
                {getActionCircle()}
                <div className="public-tool-promotion-calendar-list-task-titles">
                    <h3 style={getAdditionalNameStyles()}>
                        <div className="public-tool-promotion-calendar-list-task-title-color" style={{backgroundColor: task.color_hex ? task.color_hex : "#FFFFFF"}}></div>
                        {task.display_name}
                    </h3>
                </div>
            </div>
            <div className="public-tool-promotion-calendar-list-task-meta">
                <BiCalendar/>
                <span>{getDate()}</span>
            </div>
        </div>
    )
}