import React from 'react'
import { MdClose } from 'react-icons/md';
import { useState, useEffect } from "react";

export default function ModalUpdateKpi({setIsShowingUpdateMeasurement, updateKpi, getMetricDisplayValue, component, team}) {
    const [proposedCurrentValue, setProposedCurrentValue] = useState(() => { return 0 })
    const getCurrentMetricValueStyle = () => {
        return {
            border: "none",
            color: "grey",
            fontSize: "48px",
            paddingBottom: "4px",
            paddingLeft: "4px",
            paddingTop: "4px",
            height: "unset",
            fontWeight: "400",
            width: "100%",
            fontFamily: "-apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
        }
    }
    const getMetricValueStyle = () => {
        return {
            border: "none",
            backgroundColor: "rgba(164, 194, 240, 0.534)",
            borderRadius: "4px",
            fontSize: "48px",
            paddingBottom: "4px",
            paddingLeft: "4px",
            paddingTop: "4px",
            height: "unset",
            fontWeight: "400",
            width: "100%",
            fontFamily: "-apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
        }
    }
    const updateMeasurement = () => {
        setIsShowingUpdateMeasurement(false);
        updateKpi({
            attributes: {
                val_current: proposedCurrentValue
            }
        })
    }
    useEffect(() => {
        if (!component) return
        if (!component.attributes) return
        setProposedCurrentValue(component.attributes.val_current ? component.attributes.val_current : component.attributes.val_start)
    // eslint-disable-next-line
    }, [])
    return (
        <div className="alignment-modal-background">
            <div className="modal-task-create-container" style={{maxWidth: "1200px", width: "85%"}}>

                {/* Header */}
                <div className="modal-task-create-header-row">
                    <div className="workcomp-modal-title">Update {component.display_name}</div>
                    <div>
                        <MdClose className="component-template-edit-modal-header-close" onClick={() => setIsShowingUpdateMeasurement(false)}/>
                    </div>
                </div>

                <div className="modal-workspace-component-edit-section modal-workspace-component-edit-section-doublewide">
                    <div className="modal-workspace-component-edit-subsection">
                        <h4>Current</h4>
                        <div style={getCurrentMetricValueStyle()}>{getMetricDisplayValue(component)}</div>
                    </div>
                    <div className="modal-workspace-component-edit-subsection">
                        <h4>New</h4>
                        <input autoFocus={true} value={proposedCurrentValue} onChange={(e) => setProposedCurrentValue(e.target.value)} style={getMetricValueStyle()} type="number" placeholder={component.attributes.val_current}/>
                    </div>
                </div>

                <div className="modal-edit-big-button-container">
                    <div className="modal-edit-big-button-main" onClick={updateMeasurement}>Save</div>
                    <div className="modal-edit-big-button-cancel" onClick={() => setIsShowingUpdateMeasurement(false)}>Cancel</div>
                </div>

            </div>
        </div>
    )
}
