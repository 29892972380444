import React from 'react'
import { useState } from 'react';
import { IoCaretDownOutline, IoCaretUpOutline, IoSearchOutline } from 'react-icons/io5';

export default function ModalToolSingleSelectRoleDropdownWithSearch({selectedId, allOptions, setSelectedId, searchText, displayAttributeName, positionTop}) {
    const [isSelecting, setIsSelecting] = useState(() => { return false })
    const [searchFilterText, setSearchFilterText] = useState(() => { return "" })
    const getFilteredRoles = () => {
        if (!searchFilterText || searchFilterText === "") return allOptions
        let displayAttribute = "name"
        if (displayAttributeName) displayAttribute = displayAttributeName
        return allOptions.filter((roleOpt) => {
            if (roleOpt[displayAttribute].toLowerCase().indexOf(searchFilterText.toLowerCase()) !== -1) return true
            return false
        })
    }
    const getAdditionalModalStyles = () => {
        try {
            if (positionTop) {
                return {
                    bottom: "100%",
                    left: 0,
                    right: 0,
                    maxHeight: "300px",
                    overflowY: "auto",
                    overflowX: "hidden"
                }
            }
            return {}
        } catch (error) {
            return {}
        }
    }
    const getAdditionalStyles = (id) => {
        if (id === selectedId) return { fontWeight: 700, color: "black" }
        return {}
    }
    const updateRole = (proposedId) => {
        setSelectedId(proposedId)
        setIsSelecting(false)
    }
    const getPlaceholderText = () => {
        if (!selectedId) return "Select from Dropdown"
        try {
            const optionObject = allOptions.find(r => r._id === selectedId)
            let displayAttribute = "name"
            if (displayAttributeName) displayAttribute = displayAttributeName
            if (optionObject) return optionObject[displayAttribute]
            return "Select from Dropdown"
        } catch (error) {
            return "Select from Dropdown"
        }
    }
    return (
        <div style={{overflow: "visible", position: "relative"}}>
            {!isSelecting &&
            <div className="modal-mgmt-select-dropdown-mode-display" onClick={() => setIsSelecting(true)}>
                <p>{getPlaceholderText()}</p>
                <IoCaretDownOutline/>
            </div>
            }
            {isSelecting &&
            <div className="modal-mgmt-select-dropdown-mode-display" onClick={() => setIsSelecting(false)}>
                <p>Select from Dropdown</p>
                <IoCaretUpOutline/>
            </div>
            }
            {isSelecting &&
            <div className="modal-mgmt-select-dropdown-options" style={getAdditionalModalStyles()}>
                <div className="modal-mgmt-select-dropdown-search">
                    <IoSearchOutline/>
                    <input autoFocus={true} type="text" placeholder={searchText ? searchText : "Search roles..."} value={searchFilterText} onChange={(e) => setSearchFilterText(e.target.value)}/>
                </div>
                
                <div className="modal-mgmt-select-dropdown-options-container">
                    {getFilteredRoles().map((optionObject) => (
                        <div className="modal-mgmt-select-dropdown-option" onClick={() => updateRole(optionObject._id)} style={getAdditionalStyles(optionObject._id)} key={optionObject._id}>
                            {!displayAttributeName && optionObject.name}
                            {displayAttributeName && optionObject[displayAttributeName]}
                        </div>
                    ))}
                </div>

            </div>
            }
        </div>
    )
}
