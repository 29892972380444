import React from 'react'
import { useState, useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import Api from '../../common/APIUtils';
import DataTableRow from '../tables/DataTableRow';
import { toCapitalize } from '../../common/Helpers';

export default function ModalMoveTaskToNewParent({task, finishMoving, updateTask }) {
    const api = new Api()
    const [taskData, setTaskData] = useState(() => { return task })
    const [parentData, setParentData] = useState(() => { return "" })
    const [parents, setParents] = useState(() => { return [] })
    const parentMeetsFilter = (id) => {
        if (id === parentData._id) return false
        return true
    }
    const getAllParents = () => {
        if (!parents) return []
        const potentialParents = parents.filter((parent) => {
            return parentMeetsFilter(parent._id)
        })
        return potentialParents
    }
    const fetchTaskData = () => {
        try {
            const payload = {
                componentId: task._id,
                workspaceId: task.workspace_id
            }
            api.getWorkspaceComponent(payload)
            .then((res) => {
                if (res.data.response.component) {
                    setTaskData(res.data.response.component)
                    setParentData(res.data.response.parent)
                    setParents(res.data.response.potential_parents)
                }                
            })
            .catch((err) => {
                console.log(err)
            }) 
        } catch (error) {
            console.log(error)
        }
    }
    const getComponentTypeName = () => {
        if (!parentData) return 'Parent'
        if (!parentData.name) return 'Parent'
        return toCapitalize(parentData.name)
    }
    const formatParentRowData = (component) => {
        return [
            {
                width: "lg",
                content: (
                    <div style={{fontWeight: "600", marginTop: "0.5em", marginBottom: "0.5em"}}>{component.display_name}</div>
                ),
                _id: `${component._id}-name`
            },
            {
                width: "sm",
                content: <button className="network-team-open-manage-button">Select</button>,
                _id: `${component._id}-d`
            }
        ]
    }
    const moveToNewParent = (newParentData) => {
        if (!newParentData) finishMoving()
        if (!newParentData.name) finishMoving()
        if (newParentData.name === 'board') {
            var payload = {
                attributes: {
                    parent: newParentData._id,
                    state: newParentData.attributes.column_id_new
                }
            }
            if (task.status === "completed") payload['attributes']['state'] = newParentData.attributes.column_id_done
        }
        if (newParentData.name === 'list') {
            payload = {
                attributes: {
                    parent: newParentData._id,
                }
            }
        }
        updateTask(payload, taskData._id)
        finishMoving()
    }
    useEffect(() => {
        fetchTaskData()
    // eslint-disable-next-line
    }, [task])
    return (
        <div className="alignment-modal-background">
            <div className="modal-task-create-container" style={{paddingBottom: "2em"}}>

                {/* Header */}
                <div className="modal-task-create-header-row" style={{marginBottom: "1em"}}>
                    <div className="modal-task-create-header-title">Move task to a new {getComponentTypeName()}</div>
                    <div className="modal-side-edit-header-row" style={{borderBottom: "none"}}>
                        <MdClose className="project-post-form-cancel" onClick={finishMoving}/>
                    </div>
                </div>

                <div className="modal-network-workspace-view-container">
                    <div className="data-table-header-container" style={{marginTop: "1em"}}>
                        <span className="data-table-col-lg">Select a new {getComponentTypeName()}:</span>
                        <span className="data-table-col-sm"> </span>
                    </div>

                    {getAllParents().map((component) => (
                        <DataTableRow key={component._id} rowData={formatParentRowData(component)} clickFunction={() => moveToNewParent(component)}/>
                    ))
                    }
                </div>


            </div>
        </div>
    )
}
