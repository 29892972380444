import React from 'react'
import { useParams, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Api from '../common/APIUtils'
import DataManager from '../common/DataManager';
import '../styles/network.css'
import ModalNewNetworkWorkspace from './modals/ModalNewNetworkWorkspace';

import ResponsiveSidebar from './ResponsiveSidebar';
import { MdKeyboardBackspace } from 'react-icons/md'

export default function NewNetworkItem({sidebarCollapsed, setSidebarCollapsed}) {
    const api = new Api();
    let history = useHistory();
    let dataManager = DataManager.getInstance();
    const { networkSlug, item } = useParams();
    const [network, setNetwork] = useState(() => { return {} })
    const [teamName, setTeamName] = useState(() => { return "" })
    const getTeamCreateButtonStyle = () => {
        if (teamName === "" || !teamName) return {
            backgroundColor: "grey",
            cursor: "not-allowed"
        }
    }
    const getNetworkContainerClass = () => {
        if (sidebarCollapsed) {
            return "network-container-sidebar-hidden"
        } else {
            return ""
        }
    }
    const fetchNetwork = () => {
        const payload = {
            networkSlug: networkSlug
        }
        api.getNetwork(payload)
        .then((res) => {
            if (res.data) {
                setNetwork(res.data.response.network)
            }
        })
        .catch((err) => {
            if (err.response) {
                if (err.response.status === 400) {
                    history.push(`/home`)
                } else {
                    history.push(`/home`)
                }
            }
            history.push(`/home`)
        })  
    }
    const goBack = () => {
        if (item === 'teams') return history.push(`/n/${networkSlug}/users?v=teams`)
        if (item === 'workspaces') return history.push(`/n/${networkSlug}/workspaces`)
    }
    const maybeSubmitTeamForm = (code) => {
        if (code === 'Enter') attemptTeamCreation()
    }
    const attemptTeamCreation = () => {
        if (teamName === '' || !teamName) return
        let payload = {
            network_id: network._id,
            name: teamName
        }
        api.createNetworkTeam(payload, networkSlug)
        .then((res) => {
            if (res.data) {
                history.push(`/n/${networkSlug}/users?v=teams`)
            }
        })
        .catch((err) => {
            if (err.response) {
                if (err.response.status === 400) {
                    console.log('Toast goes here')
                    // history.push(`/home`)
                } else {
                    console.log('Toast goes here')
                }
            }
            console.log('Toast goes here')
        })
    }
    const attemptWorkspaceCreation = (payload) => {
        api.createNetworkWorkspace(payload)
        .then((res) => {
            if (res.data) {
                history.push(`/workspaces/${res.data.response.workspace._id}`)
            }
        })
        .catch((err) => {
            if (err.response) {
                if (err.response.status === 400) {
                    console.log('Toast goes here')
                    // history.push(`/home`)
                } else {
                    console.log('Toast goes here')
                }
            }
            console.log('Toast goes here')
        })
    }
    const getFormHeader = () => {
        if (item === 'workspaces') return "New Workspace"
        if (item === 'teams') return "New Team"
        return ""
    }
    const getFormSubheader = () => {
        if (item === 'workspaces') return "Workspaces organize your projects, events, meetings, and ongoing team operations."
        if (item === 'teams') return "Manage all groups of users, their permissions, and their assignments from one place"
        return ""
    }
    useEffect(() => {
        if (!dataManager.getAuth()) {
            history.push('/login');
            return
        }
        if (item === "teams") {
            history.push(`/n/${networkSlug}/users?v=teams`)
            return
        }
        const ALLOWED_ITEMS = ['workspaces', 'teams']
        if (!ALLOWED_ITEMS.includes(item)) {
            history.push(`/n/${networkSlug}`)
        }
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        fetchNetwork()
    // eslint-disable-next-line
    }, [networkSlug])
    return (
        <div className={`network-outer-container ${getNetworkContainerClass()}`} style={{marginTop: "48px"}}>
            <ResponsiveSidebar page="network" network={network} sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed}/>
            <div className="network-page">
                <div className="network-page-action-header">
                    <span onClick={goBack} className="network-page-action-header-nav-back">
                        <MdKeyboardBackspace/>
                    </span>
                </div>

                <div className="network-page-header-row">
                    <div className="network-page-header-text">
                        <h1>{getFormHeader()}</h1>
                        <p>{getFormSubheader()}</p>
                    </div>
                </div>

                {item === 'workspaces' && <ModalNewNetworkWorkspace cancelModal={goBack} network={network} attemptWorkspaceCreation={attemptWorkspaceCreation}/> }

                {item === 'teams' &&
                    <div className="form-network-create-form-container">

                        <div className="form-network-create-form-section">
                            <div className="form-network-create-form-section-label">Team Name</div>
                            <div className="form-network-create-form-section-input">
                                <input onKeyDown={(e) => maybeSubmitTeamForm(e.code)} autoFocus={true} value={teamName} onChange={(e) => setTeamName(e.target.value)} type="text" placeholder='Product Development' />
                            </div>
                        </div>

                        {/* <div className="form-network-create-form-section">
                            <div className="form-network-create-form-section-label">Add members to the team</div>
                            <div className="form-network-create-form-section-input">
                                <div className="form-network-create-form-section-input-placeholder-text">Coming Soon</div>
                            </div>
                        </div> */}

                        <div className="form-network-create-form-create" style={getTeamCreateButtonStyle()} onClick={attemptTeamCreation}>Create Team</div>

                    </div>
                }

            </div>
        </div>
    )
}
