import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { MdKeyboardBackspace, MdOutlineExpandMore, MdOutlineCheck, MdOutlineClose } from 'react-icons/md'
import { cleanSlug } from '../../common/Helpers';
import Api from '../../common/APIUtils';
import '../../styles/forms.css'

export default function NewNetwork({userData}) {
    let history = useHistory();
    const api = new Api();
    const [networkName, setNetworkName] = useState(() => { return "" })
    const [networkSlug, setNetworkSlug] = useState(() => { return "" })
    const [slugAvailable, setSlugAvailable] = useState(() => { return false })
    const [slugNetworkActivityText, setSlugNetworkActivityText] = useState(() => { return "" })
    const manuallyModifiedSlug = useRef(false)
    const lastSlugCheckText = useRef("") // used to signal whether a re-check is necessary compared to networkSlug
    const isCheckingSlugAvailability = useRef(false) // used to understand if there is an ongoing request to check availability
    const goBack = () => {
        history.push('/home');
    }
    const getContinueButtonStyle = () => {
        if (networkName === "" || !slugAvailable) return {
            backgroundColor: "grey",
            cursor: "not-allowed"
        }
    }
    const buildApprovedSlug = (proposedSlug) => {
        setNetworkSlug(cleanSlug(proposedSlug))
    }
    const getSlugCheckText = () => {
        if (isCheckingSlugAvailability.current) return <span>Checking availability...</span>
        if (networkSlug === "") return
        return slugNetworkActivityText
    }
    const getSlugAvailability = () => {
        try {
            if (networkSlug) {
                if (lastSlugCheckText.current !== networkSlug) {
                    lastSlugCheckText.current = networkSlug;
                    isCheckingSlugAvailability.current = true
                    api.checkNetworkSlugAvailability({
                        slug: networkSlug
                    })
                    .then((res) => {
                        isCheckingSlugAvailability.current = false
                        if (res.data) {
                            if (res.data.available) {
                                if (res.data.original_slug === lastSlugCheckText.current) {
                                    setSlugAvailable(true)
                                    setSlugNetworkActivityText(
                                        <div className="form-network-create-form-slug-feedback form-network-create-form-slug-feedback-good">
                                            <MdOutlineCheck/> https://tetheros.com/n/{res.data.original_slug} is available
                                        </div>
                                    )
                                    return
                                }
                                setSlugAvailable(false)
                            } else {
                                setSlugNetworkActivityText(
                                    <div className="form-network-create-form-slug-feedback form-network-create-form-slug-feedback-bad">
                                        <MdOutlineClose/> https://tetheros.com/n/{res.data.original_slug} is not available
                                    </div>
                                )
                            }
                        }
                    })
                }  
            }
            
        } catch (error) {
            console.log(error)
            isCheckingSlugAvailability.current = false
        }
    }
    const attemptNetworkCreation = () => {
        if (networkName !== "" && slugAvailable && networkSlug !== "") {
            submitNetworkRequest()
        }
    }
    const submitNetworkRequest = () => {
        try {
            api.createNetwork({
                name: networkName,
                slug: networkSlug
            })
            .then((res) => {
                if (res.data) {
                    if (res.data.response.network.slug) {
                        history.push(`/n/${res.data.response.network.slug}`)
                        return
                    }
                    history.push('/home');
                    return
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
    const validateNetworkCreationPermission = () => {
        if (!userData) return history.push('/home')
        if (!userData.has_network || !userData.active) return history.push('/home')
        api.getLicensePermission({permission: 'create'})
        .then((res) => {
            if (res.data) {
                if (!res.data.allowed) return history.push(`/home`)
            }
        })
        .catch((err) => {
            console.log('Toast')
            return history.push(`/home`)
        })
    }
    useEffect(() => {
        if (!manuallyModifiedSlug.current) {
            setNetworkSlug(cleanSlug(networkName))
        }
    }, [networkName])
    useEffect(() => {
        if (networkSlug === '') {
            manuallyModifiedSlug.current = false;
        }
    }, [networkSlug])
    useEffect(() => {
        getSlugAvailability()
    // eslint-disable-next-line
    }, [networkSlug])
    useEffect(() => {
        validateNetworkCreationPermission()
        // eslint-disable-next-line
    },[])
    return (
        <div className="form-network-create-container">
            <div className="form-network-create-header">
                <span className="form-network-create-header-nav-back" onClick={goBack}>
                    <MdKeyboardBackspace/>
                </span>
            </div>

            <div className="form-network-create-main">
                <div className="form-network-create-main-header">
                    <MdOutlineExpandMore/>
                    <h2>Network Setup</h2>
                </div>

                <div className="form-network-create-main-description">
                    <strong>Welcome to Tetheros Pro!</strong> A network allows you to create all your workspaces, manage your teams, and coordinate all your important resources in one place.
                </div>

                <div className="form-network-create-form-container">

                    <div className="form-network-create-form-section">
                        <div className="form-network-create-form-section-label">What do you want to call your network?</div>
                        <div className="form-network-create-form-section-input">
                            <input value={networkName} onChange={(e) => setNetworkName(e.target.value)} type="text" placeholder={`${localStorage.getItem("gFirstName")}'s Organization`} />
                        </div>
                    </div>

                    <div className="form-network-create-form-section">
                        <div className="form-network-create-form-section-label">Slug</div>
                        <div className="form-network-create-form-section-input form-network-create-form-section-row" style={{gap: "8px"}}>
                            <span>/</span>
                            <input value={networkSlug} onKeyDown={() => manuallyModifiedSlug.current=true} onChange={(e) => buildApprovedSlug(e.target.value)} type="text" placeholder="network-url" />
                        </div>
                        <div className="form-network-create-form-slug-feedback">{getSlugCheckText()}</div>
                        
                    </div>

                    <div style={{marginTop: "2em"}}>
                        <span className="form-network-create-form-continue" style={getContinueButtonStyle()} onClick={attemptNetworkCreation}>Continue</span>
                    </div>


                </div>
                
            </div>
        </div>
    )
}
