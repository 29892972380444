import React from 'react'

export default function FeedToolbar({feedFilter, setFeedFilter}) {
    const getAdditionalClass = (filterName) => {
        if (filterName === feedFilter) return "gadget-feed-toolbar-selected"
        return ""
    }
    return (
        <div className="gadget-feed-toolbar">
            <span onClick={() => setFeedFilter("all")} className={getAdditionalClass("all")}>Everything</span>
            <span onClick={() => setFeedFilter("posts")} className={getAdditionalClass("posts")}>User posts</span>
            <span onClick={() => setFeedFilter("updates")} className={getAdditionalClass("updates")}>Work updates</span>
        </div>
    )
}
