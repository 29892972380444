import React from 'react'
import SidebarSection from './SidebarSection'
import { useState, useEffect } from 'react'
import SidebarUserInfo from './SidebarUserInfo';
import { sortByProperty } from '../../common/Helpers';

export default function SidebarHome({workspacesAndNetworks, setSidebarCollapsed, privateWorkspaceCount}) {
    const [sidebar, setSidebar] = useState(() => {
        if (localStorage.getItem("gHomeSidebar")) {
            return JSON.parse(localStorage.getItem("gHomeSidebar"));
        } else {
            return {
                selected: "none",
                sections: []
            }
        } 
    })
    const hasNetworkAccess = () => {
        if (!workspacesAndNetworks) return false
        if (!workspacesAndNetworks.hasNetwork) return false // license needs to have a network included
        if (!workspacesAndNetworks.licenseActive) return false // license needs to be active
        return workspacesAndNetworks.hasNetwork
    }
    const updateSidebar = () => {
        // Order of this data matters!
        const updatedSidebar = {
            selected: "none",
            sections: []
        }

        const networksSection = {
            key: "sidebar-networks",
            title: "My Network",
            symbol: "network",
            items: []
        }

        const workspacesSection = {
            key: "sidebar-workspaces",
            title: "Workspaces",
            symbol: "workspaces",
            items: []
        }

        if (hasNetworkAccess()) {
            let licensedNetworks = []
            if (workspacesAndNetworks.licensedNetworks) licensedNetworks = workspacesAndNetworks.licensedNetworks

            // Licensed networks with setup complete
            if (licensedNetworks.length > 0) { 
                for (let i = 0; i < licensedNetworks.length; i++) {
                    const network = licensedNetworks[i];
                    networksSection.items.push({
                        key: network._id,
                        link: `/n/${network.slug}`,
                        title: network.name
                    })
                }
            }
        }

        // Private workspaces
        if (workspacesAndNetworks) {
            if (workspacesAndNetworks.workspaces) {
                if (workspacesAndNetworks.workspaces.length > 0) {
                    let workspaceSectionItems = []
                    for (let i = 0; i < workspacesAndNetworks.workspaces.length; i++) {
                        const workspace = workspacesAndNetworks.workspaces[i];
                        workspaceSectionItems.push({
                            key: workspace._id,
                            link: `/workspaces/${workspace._id}`,
                            title: workspace.name
                        })
                    }
                    let sortedWorkspaceSectionItems = sortByProperty(workspaceSectionItems, "title")
                    workspacesSection.items = sortedWorkspaceSectionItems
                }
            }
        }
        
        if (networksSection.items.length > 0) { updatedSidebar.sections.push(networksSection) }
        updatedSidebar.sections.push(workspacesSection)
        
        // The theory goes that this may save on performance when there are a lot of workspaces to sort through
        localStorage.setItem("gHomeSidebar", JSON.stringify(updatedSidebar))
    }
    useEffect(() => {
        updateSidebar();
        setSidebar(JSON.parse(localStorage.getItem("gHomeSidebar")));
    // eslint-disable-next-line
    },[workspacesAndNetworks, privateWorkspaceCount])

    return (
        <>
        <SidebarUserInfo setSidebarCollapsed={setSidebarCollapsed}/>
        
        {sidebar.sections.map((section) => (
            <SidebarSection key={section.key} sectionData={section}/>
        ))}
        </>
    )
}
