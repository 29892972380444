import React from 'react'
import { useState, useEffect } from 'react'
import Api from "../../../common/APIUtils"
import { FaLongArrowAltLeft} from 'react-icons/fa';
import { MdClose } from 'react-icons/md'
import { isValidHttpUrl } from '../../../common/Helpers';

export default function NewResource({goToAllResources, workspaceId}) {
    const api = new Api();
    const [name, setName] = useState(() => { return ""})
    const [canSubmit, setCanSubmit] = useState(() => { return false })
    const [description, setDescription] = useState(() => { return "" })
    const [destination, setDestination] = useState(() => { return "" })
    const [proposedKeyword, setProposedKeyword] = useState(() => { return "" })
    const [keywords, setKeywords] = useState(() => { return [] })
    const getDescriptionRowCount = () => {
        const MIN_ROW_COUNT = 2;
        // assume roughly 50 characters per row
        const breaks = description.split(/\r\n|\r|\n/).length + 1
        const rowsFromCharacters = Math.round(description.length/50) + 1
        return Math.max(breaks, rowsFromCharacters, MIN_ROW_COUNT);
    }
    const addResource = () => {
        if (!localStorage.getItem("gId")) return
        if (!name) return
        const attributes = {};
        // attributes['description'] = description;
        attributes['description'] = `${description} ${keywords.map((keyword) => (keyword.startsWith('#') ? keyword : `#${keyword}`)).join(' ')}`;
        attributes['destination'] = destination.trim().replaceAll(" ", "%20");
        attributes['active'] = true;
        attributes['keywords'] = keywords
        const payload = {
            workspace_id: workspaceId,
            owner_id: localStorage.getItem("gId"),
            is_published: true,
            display_name: name,
            attributes: attributes,
        }
        const path = `/components/resource/link`;
        api.createWorkspaceComponent(payload, path)
        .then( (res) => {
            if (res.status === 200) {
                goToAllResources()
            }
        })
        .catch((err) => {
            console.log(err);
        })
        
    }
    const updateDestination = (t) => {
        setDestination(t.trim().replaceAll(" ", "%20"))
    }
    const addKeywordToProposedKeywords = () => {
        let currentKeywords = Array.from(keywords)
        if (currentKeywords.includes(proposedKeyword)) return
        currentKeywords.push(proposedKeyword)
        setKeywords(currentKeywords)
        setProposedKeyword("")
    }
    const removeKeywordFromProposedKeywords = (word) => {
        let currentKeywords = Array.from(keywords)
        if (!currentKeywords.includes(word)) return
        const index = currentKeywords.indexOf(word)
        if (index > -1) {
            currentKeywords.splice(index, 1)
            setKeywords(currentKeywords)
        }

    }
    const handleKeywordInputButtonPress = (code) => {
        if (code === 'Enter') addKeywordToProposedKeywords()
    }
    useEffect(() => {
        if (!name || !isValidHttpUrl(destination)) {
            setCanSubmit(false)
            return
        }
        setCanSubmit(true)
    }, [name, destination])
    return (
        <div className="network-page">
            <div className="network-page-header-row">
                <div onClick={goToAllResources} className="network-page-header-back-navigation">
                    <FaLongArrowAltLeft />
                    <span>All Resources</span>
                </div>
            </div>
            <div className="network-page-header-row">
                <div className="network-page-header-text">
                    <h1>New Resource</h1>
                    <p>Workspace assets to improve your efficiency.</p>
                </div>
            </div>

            <div className="form-network-create-form-container">

                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-section-label">Name</div>
                    <div className="form-network-create-form-section-input">
                        <input style={{fontSize: "1.1em"}} autoFocus={true} value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder='Resource name' />
                    </div>
                </div>

                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-section-label">Link</div>
                    <div className="form-network-create-form-section-input">
                        <input style={{fontSize: "1.1em"}} value={destination} onChange={(e) => updateDestination(e.target.value)} type="text" placeholder='Paste link here' />
                    </div>
                </div>

                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-section-input">
                        <div className="form-network-create-form-section-label" style={{marginBottom: "0.5em"}}>Notes</div>
                        <textarea rows={getDescriptionRowCount()} placeholder="Describe the resource" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                    </div>
                </div>

                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-section-input">
                        <div className="form-network-create-form-section-label" style={{marginBottom: "0.5em"}}>Keywords</div>
                        <input onKeyDown={(e) => handleKeywordInputButtonPress(e.code)} style={{fontSize: "1.1em"}} value={proposedKeyword} onChange={(e) => setProposedKeyword(e.target.value)} type="text" placeholder='Add keyword and press enter' />
                        {keywords.length > 0 && 
                        <div className="form-network-create-form-keyword-bank">
                            {keywords.map((keyword) => (
                                <div key={keyword} className="form-network-create-form-keyword-proposed" onClick={() => removeKeywordFromProposedKeywords(keyword)}>
                                    {keyword}
                                    <MdClose/>
                                </div>
                            ))}
                        </div>
                        }
                    </div>
                </div>

                {canSubmit ? <div className="form-network-create-form-create" onClick={addResource}>Add Resource to Workspace</div> : <div className="form-network-create-form-create" style={{backgroundColor: "grey"}}>Add Resource to Workspace</div>}
            </div>

        </div>
    )
}
