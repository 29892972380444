import React, { useState, useRef, useEffect } from 'react';
import { hasPermission } from '../../common/Helpers';
import LoadingSymbolInline from './LoadingSymbolInline';

export default function ComponentStatusDropdown({ component, permissions, updateComponent, componentStatus }) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(() => { return false })
    const [focusedIndex, setFocusedIndex] = useState(null);
    const dropdownRef = useRef(null);
    const statusOptions = ['active', 'archived', 'completed'].filter(
      (status) => status !== componentStatus
    );
    const toggleDropdown = () => {
      if (hasPermission(permissions, "component_update")) {
        setIsOpen(!isOpen);
        setFocusedIndex(null);
      }
    };
    const handleStatusChange = (status) => {
      setSelectedStatus(status);
      setIsOpen(false);
      setFocusedIndex(null);
      if (!selectedStatus) return
      updateComponent({ status: status })
    };
    const statusColor = (status) => {
        switch (status) {
          case 'active':
            return 'gadget-component-status-dropdown-active-status';
          case 'archived':
            return 'gadget-component-status-dropdown-archived-status';
          case 'completed':
            return 'gadget-component-status-dropdown-completed-status';
          default:
            return 'gadget-component-status-dropdown-active-status';
        }
    };
    const handleKeyDown = (event) => {
      if (isOpen && hasPermission(permissions, "component_update")) {
        switch (event.key) {
          case 'ArrowUp':
            event.preventDefault();
            setFocusedIndex((prevIndex) => (prevIndex === null ? statusOptions.length - 1 : (prevIndex - 1 + statusOptions.length) % statusOptions.length));
            break;
          case 'ArrowDown':
            event.preventDefault();
            setFocusedIndex((prevIndex) => (prevIndex === null ? 0 : (prevIndex + 1) % statusOptions.length));
            break;
          case 'Enter':
          case 'NumpadEnter':
            if (focusedIndex !== null) {
              handleStatusChange(statusOptions[focusedIndex]);
            }
            break;
          default:
            break;
        }
      }
    };
    const initializeComponent = () => {
        setSelectedStatus(componentStatus)
    }
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setFocusedIndex(null);
      }
    };
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    // eslint-disable-next-line
    }, []);
    useState(() => {
      initializeComponent()
    // eslint-disable-next-line
    }, [componentStatus])
    return (
      <div
        className={`gadget-component-status-dropdown ${
            hasPermission(permissions, "component_update") ? 'gadget-component-status-dropdown-interactive' : 'gadget-component-status-dropdown-non-interactive'
        }`}
        onClick={toggleDropdown}
        onKeyDown={handleKeyDown}
        ref={dropdownRef}
      >
        {selectedStatus && 
        <div className={`gadget-component-status-dropdown-status-display ${statusColor(componentStatus)}`} tabIndex={0}>
          {componentStatus.charAt(0).toUpperCase() + componentStatus.slice(1)}
        </div>
        }
        {!selectedStatus &&
        <div className="gadget-component-status-dropdown-status-display">
          <LoadingSymbolInline />
        </div>
        }
        {isOpen && hasPermission(permissions, "component_update") && (
          <ul className="gadget-component-status-dropdown-dropdown-menu">
            {statusOptions.map((status, index) => (
              <li
                key={status}
                className={`gadget-component-status-dropdown-dropdown-item ${
                  focusedIndex === index ? 'gadget-component-status-dropdown-focused' : ''
                } gadget-component-status-dropdown-dropdown-item-${status}`}
                onClick={() => handleStatusChange(status)}
                tabIndex={0}
              >
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </li>     
            ))}
          </ul>
        )}
      </div>
    );
  };