import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import ConfirmAction from '../modals/ConfirmAction';
import Api from "../../common/APIUtils"
import ComponentMainComments from './ComponentMainComments';
import ComponentMainDescription from './ComponentMainDescription';
import CProgressBar from './CProgressBar';
import WorkspaceCompGoalMarkers from './goals/WorkspaceCompGoalMarkers';
import { getPercentageComplete, isCompletable, hasPermission } from '../../common/Helpers';
import { BsCheck2, BsBookmarkFill, BsFillBarChartFill, BsFillTrophyFill } from 'react-icons/bs'
import { parseISO, format, isValid, formatDistanceToNow } from 'date-fns'
import DateBoxEditable from './misc/DateBoxEditable';
import OwnerSelectorDropdownSmall from '../gadgets/OwnerSelectorDropdownSmall';
import SimpleListActionMenu from './SimpleListActionMenu';
import ContextBreadcrumb from '../gadgets/ContextBreadcrumb';
import WorkspaceCompHashtags from '../gadgets/WorkspaceCompHashtags';

export default function WorkspaceCompGoal({team, component, fetchComponent, permissions, componentData, workspaceName}) {
    let history = useHistory();
    const api = new Api();
    const titleLastUpdatedTimestamp = useRef(false);
    const [goalId, setGoalId] = useState(() => { return "" })
    const [proposedOwnerId, setProposedOwnerId] = useState(() => { return component.owner_id ? component.owner_id : "" })
    const [proposedDueDate, setProposedDueDate] = useState(() => { return component.attributes.date_due ? component.attributes.date_due : "" })
    const [displayName, setDisplayName] = useState(() => { return component.display_name ? component.display_name : ""})
    const [comments, setComments] = useState(() => { return [] })
    const [allComponents, setAllComponents] = useState(() => { return {} })
    const [childrenComponents, setChildrenComponents] = useState(() => { return [] })
    const [isShowingConfirmMenu, setIsShowingConfirmMenu] = useState(() => { return false })
    const [confirmMenuContent, setConfirmMenuContent] = useState(() => { return "" })
    const [confirmMenuAction, setConfirmMenuAction] = useState(() => { return false })
    const [tags, setTags] = useState(() => { return [] })
    const updateGoal = (payload) => {
        if (!component) return
        const path = `/workspace/components/${component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const getCreationDate = () => {
        if (!component) return ""
        if (!component.createdAt) return ""
        if (!isValid(parseISO(component.createdAt))) return ""
        return format(parseISO(component.createdAt), 'PP')
    }
    const getDistanceFromCreationDate = () => {
        if (!component) return ""
        if (!component.createdAt) return ""
        if (!isValid(parseISO(component.createdAt))) return ""
        return formatDistanceToNow(parseISO(component.createdAt))
    }
    const isComplete = () => {
        if (!component) return false
        if (!component.status) return false
        if (component.status === 'completed') return true
        return false
    }
    const taskTitleFieldHandler = (event) => {
        if (["Enter", "NumpadEnter"].includes(event.code)) {
            event.preventDefault()
            event.target.blur()
        }
    }
    const getDisplayNamePlaceholder = () => {
        if (!component) return "Enter a goal name"
        if (!component.display_name) return "Enter a goal name"
        return component.display_name
    }
    const updateDescription = (newDescription) => {
        updateGoal({
            attributes: {
                description: newDescription
            }
        })
    }
    const hideConfirmPopup = () => {
        setIsShowingConfirmMenu(false)
    }
    const archiveGoal = () => {
        hideConfirmPopup()
        const payload = {
            status: 'archived'
        };
        const path = `/workspace/components/${componentData.component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const restoreGoal = () => {
        hideConfirmPopup()
        const payload = {
            status: 'active'
        };
        const path = `/workspace/components/${componentData.component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const deleteGoal = () => {
        hideConfirmPopup()
        const payload = {
            componentId: componentData.component._id
        }
        const workspaceId = componentData.component.workspace_id;

        api.deleteWorkspaceComponent(payload)
        .then((res) => {
            if (workspaceId) return history.push(`/workspaces/${workspaceId}`);
            history.push('/home')
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const completeGoal = () => {
        hideConfirmPopup()
        const payload = {
            status: 'completed',
            progress: 1
        };
        const path = `/workspace/components/${componentData.component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const getActionList = () => {
        let actions = [];
        if (hasPermission(permissions, 'component_delete')) {
            actions = actions.concat([
                {
                    text: 'Delete',
                    action: askForDeleteConfirmation,
                    id: 'delete'
                }
            ])
        }

        if (component.status === 'active' && hasPermission(permissions, 'component_update')) {
            actions = [
                        {
                            text: 'Complete',
                            action: askForCompleteConfirmation,
                            id: 'complete'
                        },
                        {
                            text: 'Archive',
                            action: askForArchiveConfirmation,
                            id: 'archive'
                        }
                    ].concat(actions)
        }
        if (component.status !== 'active' && hasPermission(permissions, 'component_update')) {
            actions = [
                {
                    text: 'Reactivate',
                    action: askForReactivateConfirmation,
                    id: 'reactivate'
                }
            ].concat(actions)
        }
        return actions
    }
    const askForArchiveConfirmation = () => {
        setConfirmMenuContent("Archive goal?  Goal will be hidden in the sidebar and only shown in relevant locations.  You can restore it later.")
        setConfirmMenuAction(() => archiveGoal)
        setIsShowingConfirmMenu(true)
    }
    const askForDeleteConfirmation = () => {
        setConfirmMenuContent("Are you sure you want to permanently delete this goal?  All markers, alignments, and posts will be deleted with it.")
        setConfirmMenuAction(() => deleteGoal)
        setIsShowingConfirmMenu(true)
    }
    const askForCompleteConfirmation = () => {
        setConfirmMenuContent("Mark goal as complete?")
        setConfirmMenuAction(() => completeGoal)
        setIsShowingConfirmMenu(true)
    }
    const askForReactivateConfirmation = () => {
        setConfirmMenuContent("Reactivate goal?")
        setConfirmMenuAction(() => restoreGoal)
        setIsShowingConfirmMenu(true)
    }
    const getAllComponents = () => {
        if (componentData.component.workspace_id) {
            var payload = { workspaceId: componentData.component.workspace_id }
        }
        if (componentData.component.network_id) {
            payload = { networkId: componentData.component.network_id }
        }
            
        api.getAllWorkspaceComponents(payload)
        .then((res) => {
            if (res.data) {
                setAllComponents(res.data.response.components);
                setChildrenComponents(componentData.children_components)
            } else {
                console.log('Problem fetching components')
            }
        })
        .catch((err) => {
            console.log(err);
        });        
    }
    const changeDueDate = (d) => {
        setProposedDueDate(format(d, 'yyyy-MM-dd'))
    }
    const updateDisplayName = () => {
        if (!component) return
        if (!component.display_name) return
        if (displayName === "" | displayName === component.display_name) return
        const payload = {
            display_name: displayName
        }
        const path = `/workspace/components/${component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const updateOwner = () => {
        if (proposedOwnerId !== component.owner_id) {
            updateGoal({
                owner_id: proposedOwnerId
            })
        }
    }
    const updateDueDate = () => {
        if (proposedDueDate !== component.attributes.date_due) {
            updateGoal({
                attributes: {
                    date_due: proposedDueDate
                }
            })
        }
    }
    useEffect(() => {
        if (!componentData.component) return
        getAllComponents()
        setComments(componentData.component.comments)
        setGoalId(componentData.component._id)
        if (Date.now() - titleLastUpdatedTimestamp.current > 900) setDisplayName(component.display_name ? component.display_name : "")
        setProposedOwnerId(component.owner_id ? component.owner_id : "")
        setProposedDueDate(component.attributes.date_due ? component.attributes.date_due : "")
    // eslint-disable-next-line
    }, [componentData])
    useEffect(() => {
        titleLastUpdatedTimestamp.current = Date.now()
        const timer = setTimeout(() => {
            if (Date.now() - titleLastUpdatedTimestamp.current < 900) return
            updateDisplayName()
        }, 1000)
        return () => clearTimeout(timer)
    // eslint-disable-next-line
    }, [displayName])
    useEffect(() => {
        updateOwner({
        })
    // eslint-disable-next-line
    }, [proposedOwnerId])
    useEffect(() => {
        updateDueDate()
    // eslint-disable-next-line
    }, [proposedDueDate])
    useEffect(() => {
        if (!component) return
        if (!component.display_name) return
        setDisplayName(component.display_name)
    // eslint-disable-next-line
    }, [goalId])
    useEffect(() => {
        if (!componentData) return
        if (!componentData.component) return
        if (!componentData.component.component_tags) {
            setTags([])
            return 
        }
        setTags(componentData.component.component_tags)
    }, [componentData])
    return (
        <div className="workcomp-page-container">
            {/* Modals */}
            {isShowingConfirmMenu && <ConfirmAction mainText={confirmMenuContent} onconfirm={confirmMenuAction} hideMe={hideConfirmPopup} />}

            <div className="component-page-outer" style={{marginTop: "12px"}}>

                {/* Header top row */}
                <div className="component-page-row" style={{alignItems: "center", justifyContent: "space-between"}}>
                    {/* Top header - left side */}
                    <div className="component-page-row" style={{alignItems: "center", justifyContent: "flex-start", gap: "2em"}}>
                        <div>
                            <ContextBreadcrumb component={component} componentData={componentData} workspaceName={workspaceName} />
                        </div>
                    </div>

                    {/* Top header - right side */}
                    <div>
                        <SimpleListActionMenu actionsList={getActionList()} symbol="gear"/>
                    </div>
                </div>

                <div className="component-page-title component-page-row" style={{alignItems: "center", gap: "0.5em"}}>
                    <CProgressBar height={60} percentage={getPercentageComplete(component)}/>
                    {hasPermission(permissions, 'component_update') ? (
                        <input type="text" onKeyUp={(e) => taskTitleFieldHandler(e)} className="workcomp-page-task-title-editable" placeholder={getDisplayNamePlaceholder()} value={displayName} onChange={(e) => setDisplayName(e.target.value)} />
                    ) : (
                        <div className="workcomp-page-task-title-editable" style={{border: "2px solid transparent"}}>{displayName}</div>
                    )}
                </div>
                <WorkspaceCompHashtags tags={tags} workspaceId={component.workspace_id}/>

                <div className="workcomp-page-main-display-split-container">
                    <div className="workcomp-page-main-display-split-primary">
                        <div className="component-page-task-tab-section-inner">

                            <div className="component-view-summary-card-lg component-view-summary-card-borderless">
                                <div className="component-view-card-header">
                                    <h2>Goal Markers</h2>
                                </div>
                                <WorkspaceCompGoalMarkers permissions={permissions} fetchComponent={fetchComponent} componentData={componentData} allComponents={allComponents} childrenComponents={childrenComponents}/>
                            </div>

                            <div className="component-view-summary-card-lg component-view-summary-card-borderless">
                                <div className="component-view-card-header">
                                    <h2>Comments</h2>
                                </div>
                                <ComponentMainComments team={team} fetchComponent={fetchComponent} comments={comments} componentId={goalId}/>
                            </div>
                        </div>
                    </div>

                    <div className="workcomp-page-main-display-split-sidebar">
                        <div className="workspace-panel-settings-section">
                            {/* Goal */}
                            <div className="workspace-panel-setting workspace-panel-setting-mini workspace-panel-setting-borders">
                                <div className="workspace-panel-settings-section-heading">About</div>
                                <ComponentMainDescription permissions={permissions} component={component} updateDetails={updateDescription} team={team}/>
                                <div className="workspace-panel-settings-status-items" style={{marginTop: "12px"}}>
                                    <div className="workspace-panel-setting-iconography-and-text">
                                        <BsFillTrophyFill/>
                                        <div>Goal</div>
                                    </div>
                                    <div className="workspace-panel-setting-iconography-and-text">
                                        <BsFillBarChartFill/>
                                        <div className={`component-view-title-meta-about-status-${component.status}`}>{component.status}</div>
                                    </div>
                                    <div className="workspace-panel-setting-iconography-and-text">
                                        <BsBookmarkFill/>
                                        <div>Created {getCreationDate()} &middot; {getDistanceFromCreationDate()} ago</div>
                                    </div>
                                    {(isCompletable(component) && hasPermission(permissions, 'component_update')) && (
                                        <div className="workspace-panel-setting-action-launch" style={{backgroundColor: "green", width: "100%"}} onClick={askForCompleteConfirmation}>
                                            <BsCheck2/> Mark Goal Complete
                                        </div>
                                    )}
                                    {isComplete() && (
                                        <div className="workspace-panel-setting-iconography-and-text">
                                            <BsCheck2 style={{color: "green"}} />
                                        <div>Completed {format(parseISO(component.completed), 'PP')}</div>
                                    </div> 
                                    )}
                                </div>
                            </div>
                            {/* Due Date */}
                            <div className="workspace-panel-setting workspace-panel-setting-mini workspace-panel-setting-borders" style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                                <div className="workspace-panel-settings-section-heading">Due Date</div>
                                {hasPermission(permissions, 'component_update') ? (
                                    <DateBoxEditable editable={!isComplete()} component={component} date={parseISO(proposedDueDate)} label="" onupdate={changeDueDate} clearDate={() => setProposedDueDate("")} />
                                ) : (
                                    <DateBoxEditable component={component} editable={false} date={parseISO(proposedDueDate)} label="" />
                                )}
                            </div>
                            <div className="workspace-panel-setting workspace-panel-setting-mini workspace-panel-setting-borders" style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                                <div className="workspace-panel-settings-section-heading">Owner</div>
                                <div style={{width: "100%"}}>
                                    <OwnerSelectorDropdownSmall permissions={permissions} proposedOwnerId={proposedOwnerId} team={team} setProposedOwnerId={setProposedOwnerId}/>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}
