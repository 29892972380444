import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { HiOutlineFilter } from 'react-icons/hi'
import ProfPicCircle from '../profiles/ProfPicCircle';

export default function ComponentUserFilterButton({filteredOutTeammates, setFilteredOutTeammates, team}) {
    const filterMenuFlyoutRef = useRef(false);
    const [isShowingFilterMenu, setIsShowingFilterMenu] = useState(() => { return false })
    const getFilterMenuStyles = () => {
        let baseStyle = {position: "relative"}
        if (filteredOutTeammates.length === 0) return baseStyle
        return {...baseStyle, backgroundColor: "#FFB90150"}
    }
    const teammateIsActivelyShownOnBoard = (teammateId) => {
        const copyOfTeammateFilter = Array.from(filteredOutTeammates)
        if (copyOfTeammateFilter.includes(teammateId)) return false
        return true
    }
    const toggleTeammateFilter = (teammateId) => {
        let copyOfTeammateFilter = Array.from(filteredOutTeammates)
        if (!copyOfTeammateFilter.includes(teammateId)) {
            copyOfTeammateFilter.push(teammateId)
            setFilteredOutTeammates(copyOfTeammateFilter)
            return 
        }
        const index = copyOfTeammateFilter.indexOf(teammateId)
        if (index < 0) return
        copyOfTeammateFilter.splice(index, 1)
        setFilteredOutTeammates(copyOfTeammateFilter)
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterMenuFlyoutRef.current && !filterMenuFlyoutRef.current.contains(event.target)) {
                setIsShowingFilterMenu(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [filterMenuFlyoutRef])
    return (
        <div className="workcomp-board-control-panel-symbol-button" ref={filterMenuFlyoutRef} style={getFilterMenuStyles()}>
            <div className="workcomp-board-control-panel-filter-display" onClick={() => setIsShowingFilterMenu(!isShowingFilterMenu)}>
                <HiOutlineFilter/>
            </div>
            {isShowingFilterMenu &&
                <div className="workcomp-board-control-panel-filter-flyout-menu-container">
                    <p>Filter by user</p>
                    <div className="workcomp-board-control-panel-filter-flyout-menu-users">
                        {team.map((teammate) => (
                            <BoardFilterTeammateActive key={teammate._id} teammate={teammate} filteredOutTeammates={filteredOutTeammates} isChecked={teammateIsActivelyShownOnBoard(teammate._id)} toggleTeammate={toggleTeammateFilter} />
                        ))}
                    </div>
                </div>
            }
        </div>
    )
}


function BoardFilterTeammateActive({teammate, isChecked, toggleTeammate, filteredOutTeammates}) {
    const handleClick = () => {
        toggleTeammate(teammate._id)
    }
    const getOutlineColor = () => {
        if (filteredOutTeammates.length === 0) return "white"
        if (isChecked) return "white"
        return "#3D72AA"
    }
    return (
        <div className="workcomp-board-control-panel-filter-flyout-teammate" onClick={handleClick}>
            <ProfPicCircle disableClick={true} src={teammate.profile_picture_url} userId={teammate._id} userName={`${teammate.firstName} ${teammate.lastName}`} height={32} outlineColor={getOutlineColor()} outlineWidth={2}/>
        </div>
    )
}