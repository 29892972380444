import React from 'react'
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { capitalizeFirstLetter } from '../../common/Helpers';
import LoadingSymbolFacebook from '../gadgets/LoadingSymbolFacebook';
import { FaCheck } from "react-icons/fa6";
import { format, parseISO } from 'date-fns'

import { getCurrentPlanInformation, getManagementLinkForPlan } from '../../common/Api';

export default function AccountBilling({ userData }) {
    const history = useHistory()
    const [planInformation, setPlanInformation] = useState(() => { return false })
    const [planInformationError, setPlanInformationError] = useState(() => { return false })
    const handleManagePlanClick = () => {
        if (!isPeriodBasedPlan()) return handleSelectPlanClick()
        getManagementLinkForPlan()
            .then((res) => {
                if (!res.data) return setPlanInformationError(true)
                if (res.data.success) {
                    window.location.replace(res.data.url)
                    return
                }
                setPlanInformationError(true)
            })
        return
    }
    const handleSelectPlanClick = () => {
        return history.push('/account/plans')
    }
    const loadPlanInformation = () => {
        // Load plan information from the backend setPlanInformation(..)
        getCurrentPlanInformation()
        .then((res) => {
            if (res.data.success) setPlanInformation(res.data.plan)
            if (!res.data.success) {
                setPlanInformation(false)
                setPlanInformationError(true)
            }
        })
        .catch((err) => {
            return setPlanInformationError(true)
        })

    }
    const isPeriodBasedPlan = () => {
        try {
            if (["pro", "founder", "basic"].includes(planInformation.tier)) return false
            return true
        } catch (error) {
            return true
        }
    }
    useEffect(() => {
        loadPlanInformation()
    // eslint-disable-next-line
    }, [])
    return (
        <div className="user-settings-plan">
            {planInformationError &&
            <div className="util-row util-justify-center util-col util-align-center" style={{gap: "24px", marginTop: "80px"}}>
                <div><strong>Something went wrong fetching your plan information</strong></div>
                <div>Please contact Tetheros Support if this continues to happen by sending an email to support@tetheros.com</div>
            </div>
            }
            {!planInformation && !planInformationError &&
            <div className="util-row util-justify-center util-col util-align-center" style={{gap: "14px", marginTop: "24px"}}>
                <LoadingSymbolFacebook/>
                <div>Fetching your plan information....</div>
            </div>
            }
            {planInformation && !planInformationError &&
            <div className="user-settings-plan-benefits">
                <div className="user-settings-plan-benefit">
                    <h4>Tetheros membership</h4>
                    <div className="user-settings-plan-benefit-inner">
                        <div>
                            <div>Plan name: {capitalizeFirstLetter(planInformation.tier)}</div>
                            {userData.license_is_trial && !userData.license_tier === "Pro" && <div>Trial days remaining: {planInformation.remaining_trial_days}</div>}
                        </div>
                        <div className="user-settings-plan-actions">
                            {!userData.license_is_trial && planInformation.tier === "social" && <div className="user-settings-plan-action" onClick={handleSelectPlanClick}>Upgrade</div>}
                            {!userData.license_is_trial && planInformation.tier !== "social" && <div className="user-settings-plan-action" onClick={handleManagePlanClick}>Manage Plan</div>}
                            {userData.license_is_trial && <div className="user-settings-plan-action" onClick={handleSelectPlanClick}>Add payment information</div>}
                        </div>
                    </div>
                </div>
                <div className="user-settings-plan-benefit">
                    <h4>Cost</h4>
                    <div className="user-settings-plan-benefit-inner">
                        <div>
                            <div>${parseFloat(planInformation.cost_dollars).toLocaleString()} <span>/ {planInformation.is_annual ? "year" : "month"}</span> </div>
                            {planInformation.formatted_renewal_date && !planInformation.plan_to_cancel && <div>Renews: {planInformation.formatted_renewal_date}</div>}
                            {planInformation.plan_to_cancel && <div>Ending: {format(parseISO(planInformation.plan_to_cancel), 'PPP')}</div>}
                        </div>
                        <div className="user-settings-plan-actions">
                            {planInformation.cost_dollars > 0 && !planInformation.plan_to_cancel && <div className="user-settings-plan-action user-settings-plan-action-grey" onClick={handleManagePlanClick}>Cancel Plan</div>}
                            {planInformation.plan_to_cancel && <div className="user-settings-plan-action user-settings-plan-action-grey" onClick={handleManagePlanClick}>Renew Plan</div>}
                        </div>

                    </div>
                </div>
                <div className="user-settings-plan-benefit">
                    <h4>Plan benefits</h4>
                    {isPeriodBasedPlan() &&
                    <div className="user-settings-plan-benefits-list">
                        <div><FaCheck/> {planInformation.network_users}/{planInformation.network_users_limit} network users</div>
                        <div><FaCheck/> Priority Support</div>
                        <div><FaCheck/> Unlimited workspaces</div>
                        <div><FaCheck/> Social channels</div>
                    </div>}
                    {!isPeriodBasedPlan() &&
                    <div className="user-settings-plan-benefit-inner">
                        <div>
                            <div>You are on a legacy or unsupported plan. Please contact support@tetheros.com if you have any questions about your membership.</div>
                        </div>
                        <div className="user-settings-plan-actions">
                        </div>

                    </div>
                    }
                </div>
            </div>
            }
        </div>
    )
}