import React from 'react'
import { useHistory } from 'react-router-dom'
import { useState, useRef, useEffect } from 'react'
import { getAcronym, getColorFromPaletteBasedOnFirstName, getContrastColor } from '../../common/Helpers';
import UserInfoTooltip from './UserInfoTooltip';

export default function ProfPicCircle({src, userId, userName, height, outlineWidth, outlineColor, disableClick}) {
    const history = useHistory();
    const [providedHeight, setProvidedHeight] = useState(() => { return 32 })
    const [tooltipVisible, setTooltipVisible] = useState(() => { return false })
    const hoverRef = useRef()
    const goToUserPage = () => {
        if (!userId) return
        if (disableClick) return
        history.push(`/user/${userId}`)
    }
    const getAdditionalStyles = () => {
        let height = getHeight();
        let s = {...height}
        if (outlineWidth && outlineColor) s = { ...s, border: `${outlineWidth}px solid ${outlineColor}` } 
        // if (src) return {...height}
        if (src) return s
        const backgroundColor = getColorFromPaletteBasedOnFirstName(userName)
        const textColor = getContrastColor(backgroundColor)
        const backgroundColorStyle = { backgroundColor: backgroundColor, color: textColor }
        return {...s, ...{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}, ...backgroundColorStyle }
    }
    const getHeight = () => {
        if (height) {
            return {
                minHeight: height,
                minWidth: height,
                width: height,
                height: height
            }
        } else {
            return {}
        }
    }
    const updateHeightNumber = () => {
        try {
            if (height) return setProvidedHeight(height)
        } catch (error) {
            return
        }
    }
    const getFontStyle = () => {
        if (!height) return {}
        return {fontSize: `${height/2.5}px`}
    }
    const getLetters = () => {
        try {
            if (!userName) return ""
            if (userName.toLowerCase() === "none") {
                return ""
            } else {
                return getAcronym(userName)
            }
        } catch (error) {
            return ""
        }

    }
    useEffect(() => {
        let timeoutId;
        const node = hoverRef.current;
        const handleMouseEnter = () => {
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => setTooltipVisible(true), 500); // 0.5 second delay
        };
        const handleMouseLeave = () => {
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => setTooltipVisible(false), 500); // 0.5 second delay
        };
        node.addEventListener('mouseenter', handleMouseEnter);
        node.addEventListener('mouseleave', handleMouseLeave);
        return () => {
          node.removeEventListener('mouseenter', handleMouseEnter);
          node.removeEventListener('mouseleave', handleMouseLeave);
          clearTimeout(timeoutId);
        };
    }, []);
    useEffect(() => {
        updateHeightNumber()
    // eslint-disable-next-line
    }, [height])
    return (
        <div style={getAdditionalStyles()} className="u-profile-circle-grey-header" ref={hoverRef}>
            {src ? (
                <img src={src} alt="profile" onClick={goToUserPage}/>
            ) : (
                <span style={getFontStyle()} onClick={goToUserPage}>
                {getLetters()}
                </span>
            )}
            {tooltipVisible && !disableClick && <UserInfoTooltip userName={userName} parentRef={hoverRef} userId={userId} providedHeight={providedHeight} />}
        </div>
    )
}
