import axios from 'axios';

var baseUrl = "http://localhost:3000"
if (process.env.NODE_ENV === "production") baseUrl = "https://tetheros.com/api"

let headers = {
  Accept: "application/json",
};

const axiosConfiguration = {
  baseURL: baseUrl,
  timeout: 31000,
  headers: headers,
  withCredentials: true
}

const api = axios.create(axiosConfiguration)

export const registerForTetherosLinkedIn = async (payload) => {
  try {
    window.open(`${baseUrl}/persona/v1/accounts/register/linkedin`, "_self")
  } catch (error) {
    console.log(error)
    return false
  }
};

export const fetchAuth = async () => {
  try {
    const response = await api.get('/auth');
    return response;
  } catch (error) {
    return false
  }
};

export const initializeAccount = async (payload) => {
  try {
    const response = await api.post('/persona/v1/accounts/initialize-account-assets', payload)
    return response
  } catch (error) {
    return false
  }
}

export const proposeInitialPlan = async (payload) => {
  try {
    const response = await api.post('/persona/v1/accounts/proposed-plan', payload)
    localStorage.removeItem("gPlanInterest")
    return response
  } catch (error) {
    return false
  }
}

export const getAccountInitializationStatus = async () => {
  try {
    const response = await api.get('/persona/v1/accounts/initialization/status')
    return response
  } catch (error) {
    return false
  }
}

export const activateTrial = async () => {
  try {
    const response = await api.post('/persona/v1/accounts/trial/activate')
    return response
  } catch (error) {
    return false
  }
}

export const completeOnboarding = async () => {
  try {
    const response = await api.post('/persona/v1/accounts/onboarding/complete')
    return response
  } catch (error) {
    return false
  }
}

export const getCurrentPlanInformation = async () => {
  try {
    const response = await api.get('/persona/v1/accounts/plan')
    return response
  } catch (error) {
    return false
  }
}

export const getCheckoutLinkForPlan = async (payload) => {
  try {
    const response = await api.post('/persona/v1/accounts/plan/checkout', payload)
    return response
  } catch (error) {
    return false
  }
}

export const getManagementLinkForPlan = async () => {
  try {
    const response = await api.get('/persona/v1/accounts/plan/manage')
    return response
  } catch (error) {
    return false
  }
}

export const submitWorkspaceProjectIdeaTetherosAssist = async (payload) => {
  try {
    const response = await api.post('/persona/v1/workspace/project', payload)
    return response
  } catch (error) {
    return false
  }
}

export const createWorkspaceFileResource = async (formData, workspaceId) => {
  try {
    const response = await api.post(`/persona/v1/workspaces/${workspaceId}/components/file-resource`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    return response
  } catch (error) {
    return false
  }
}

export const getWorkspaceFileUrl = async (workspaceId, componentId) => {
  try {
    const response = await api.get(`/persona/v1/workspaces/${workspaceId}/components/file/${componentId}`)
    return response
  } catch (error) {
    return false
  }
}

export const createMultiMediaPost = async (formData) => {
  try {
    const response = await api.post('/persona/v1/workspace/post', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    return response
  } catch (error) {
    return false
  }
}

export const updateComponentDescriptionWithFileSupport = async (formData, workspaceId, componentId) => {
  try {
    const response = await api.patch(`/persona/v1/workspaces/${workspaceId}/component/${componentId}/description`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    return response
  } catch (error) {
    return false
  }
}

export const getPostMultimedia = async (workspaceId, postId) => {
  try {
    const response = await api.get(`/persona/v1/workspaces/${workspaceId}/posts/media/${postId}`)
    return response
  } catch (error) {
    return false
  }
}

export const getWorkspaceComponentMultimedia = async (workspaceId, componentId) => {
  try {
    const response = await api.get(`/persona/v1/workspaces/${workspaceId}/components/media/${componentId}`)
    return response
  } catch (error) {
    return false
  }
}

export const removeWorkspaceComponentMedia = async (payload) => {
  try {
    const response = await api.post('/persona/v1/workspaces/components/remove-media', payload)
    return response
  } catch (error) {
    return false
  }
}

export const getJobToken = async (tokenId) => {
  try {
    const response = await api.get(`/persona/v1/token/${tokenId}/status`)
    return response
  } catch (error) {
    return false
  }
}

export const getUserNotes = async () => {
  try {
    const response = await api.get(`/persona/v1/user/notes`)
    return response
  } catch (error) {
    return false
  }
}

export const createUserNote = async (payload) => {
  try {
    const response = await api.post(`/persona/v1/user/notes`, payload)
    return response
  } catch (error) {
    return false
  }
}

export const updateUserNote = async (payload) => {
  try {
    const response = await api.patch(`/persona/v1/user/notes`, payload)
    return response
  } catch (error) {
    return false
  }
}

export const deleteUserNote = async (noteId) => {
  try {
    const response = await api.delete(`/persona/v1/user/note/${noteId}`)
    return response
  } catch (error) {
    return false
  }
}