import React from 'react'
import { useState, useEffect } from 'react'
// eslint-disable-next-line
import { getDisplayDescriptionHtml, getPercentageComplete, sortByProperty, getFormattedDisplayWithUnits } from '../../common/Helpers'

import HProgressBar from '../HProgressBar'
// import CProgressBar from '../components/CProgressBar'
import ProfPicCircle from '../profiles/ProfPicCircle'
import LoadingSymbolInline from '../gadgets/LoadingSymbolInline'

// import { HiOutlineArrowNarrowRight } from 'react-icons/hi'

export default function PublicInitiative({component}) {
    const [workstreams, setWorkstreams] = useState(() => { return [] })
    // const [goals, setGoals] = useState(() => { return [] })
    const [comments, setComments] = useState(() => { return [] })
    const [isLoaded, setIsLoaded] = useState(() => { return false })
    const getSummaryBoxDescription = () => {
        try {
            if (component) {
                if (component.attributes) {
                    if (component.attributes.description) {
                        if (!component.attributes.description.description_html) return false
                    }
                }
            }
            return (
                <div className="public-component-heading-description">
                    <div dangerouslySetInnerHTML={{ __html: getDisplayDescriptionHtml(component) }}></div>
                </div>
            )
        } catch (error) {
            return false
        }
    }
    const updateChildren = () => {
        try {
            if (!component.children) return setIsLoaded(true)
            if (component.children.length === 0) return setIsLoaded(true)
            // Update workstreams
            const allChildren = Array.from(component.children)
            const childWorkstreams = allChildren.filter((child) => {
                if (child.status === "archived") return false
                if (["list", "board"].includes(child.name)) return true
                return false
            })
            const sortedWorkstreams = sortByProperty(childWorkstreams, "display_name")
            setWorkstreams(sortedWorkstreams)
            // Update goals
            // const childGoals = allChildren.filter((child) => {
            //     if (child.status === "archived") return false
            //     if (["kresult"].includes(child.name)) return true
            //     return false
            // })
            // const sortedGoals = sortByProperty(childGoals, "display_name")
            // setGoals(sortedGoals)
            if (component.comments) {
                const componentComments = Array.from(component.comments)
                setComments(componentComments)
            }
            setIsLoaded(true)
        } catch (error) {
            return
        }
    }
    // const getKpi = (goalId) => {
    //     try {
    //         if (!component.kpis) return false
    //         if (component.kpis.length === 0) return false
    //         const kpisCopy = Array.from(component.kpis)
    //         let relatedKpi = false
    //         for (let i = 0; i < kpisCopy.length; i++) {
    //             const kpi = kpisCopy[i];
    //             if (!kpi.parent_data) continue
    //             if (kpi.parent_data[goalId]) {
    //                 relatedKpi = kpi
    //                 break
    //             }
    //         }
    //         if (relatedKpi) return relatedKpi
    //         return false
    //     } catch (error) {
    //         return false
    //     }
    // }
    useEffect(() => {
        updateChildren()
    // eslint-disable-next-line
    }, [component])
    return (
        <div>
            <div className="public-component-summary-section">
                <div className="public-component-heading-summary">
                    {getSummaryBoxDescription()}
                </div>
            </div>

            <div className="public-component-display-area">
                {/* <div className="public-component-double-wide-sections"> */}
                    <div className="public-component-double-wide-section">
                        <h3 className="public-component-display-heading">Workstreams</h3>
                        {!isLoaded && <LoadingState/>}
                        {isLoaded && workstreams.length === 0 && <div className="public-component-empty-text">This project has no workstreams</div> }
                        {isLoaded && workstreams.length > 0 &&
                        <div className="public-component-display-workstreams">
                            {workstreams.map((workstream) => (
                                <WorkstreamDisplay key={workstream._id} workstream={workstream}/>
                            ))}
                        </div>
                        }
                    </div>
                    {/* <div className="public-component-double-wide-section">
                        <h3 className="public-component-display-heading">Goals</h3>
                        {!isLoaded && <LoadingState/>}
                        {isLoaded && goals.length === 0 && <div className="public-component-empty-text">This project has no goals set</div> }
                        {isLoaded && goals.length > 0 &&
                        <div className="public-component-display-workstreams">
                            {goals.map((goal) => (
                                <GoalDisplay key={goal._id} goal={goal} getKpi={getKpi}/>
                            ))}
                        </div>
                        }
                    </div> */}
                {/* </div> */}
                {comments.length > 0 &&
                <div className="public-component-single-section">
                    <h3 className="public-component-display-heading">Discussion</h3>
                    <div className="public-component-display-comments">
                        {comments.map((comment) => (
                            <div key={comment._id} className="public-component-display-comment">
                                <ProfPicCircle height={36} src={comment.author_profile_pic_src} userId={comment.author_id} userName={`${comment.author_first_name} ${comment.author_last_name}`} />
                                <div className="public-component-display-comment-content-container">
                                    <div className="public-component-display-comment-content-author">{comment.author_first_name} {comment.author_last_name}</div>
                                    <div className="public-component-display-comment-content" style={{textWrap: "wrap"}}>{comment.content}</div>
                                    
                                </div>
                                
                            </div>
                        ))}
                    </div>
                    
                </div>
                }
            </div>
        </div>
    )
}

function LoadingState() {
    return (
        <div style={{width: "100%", marginLeft: "auto", marginRight: "auto", maxWidth: "120px", display: "flex", flexDirection: "column", gap: "36px", justifyContent: "center", marginTop: "36px"}}>
            <LoadingSymbolInline />
            <LoadingSymbolInline />
            <LoadingSymbolInline />
        </div>
    )
}

function WorkstreamDisplay({workstream}) {
    return (
        <div>
            <div className="public-component-child-heading">{workstream.display_name}</div>
            <div className="public-component-workstream-progress-container">
                <HProgressBar percentage={getPercentageComplete(workstream)} height={8} />
                <span className="public-component-child-subtext">{Math.round(getPercentageComplete(workstream))}%</span>
            </div>
            
        </div>
    )
}

// function GoalDisplay({goal, getKpi}) {
//     const [kpi, setKpi] = useState(() => { return false })
//     const updateGoalKpi = () => {
//         try {
//             const relatedKpi = getKpi(goal._id)
//             setKpi(relatedKpi)
//         } catch (error) {
//             setKpi(false)
//         }
//     }
//     useEffect(() => {
//         updateGoalKpi()
//     // eslint-disable-next-line
//     }, [])
//     return (
//         <div className="public-component-goal-container">
//             <CProgressBar percentage={getPercentageComplete(goal)} height={60} extraStyles={{fontSize: "12px"}} />
//             <div>
//                 <div className="public-component-child-heading" style={{flexGrow: 1}}>{goal.display_name}</div>
//                 <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "6px"}}>
//                     {kpi && <span className="public-component-child-subtext">{kpi.display_name}:</span>}
//                     {!kpi && <span className="public-component-child-subtext">Qualitative:</span>}
//                     <span className="public-component-child-subtext">{getFormattedDisplayWithUnits(false, goal.attributes.val_start)}</span>
//                     <HiOutlineArrowNarrowRight style={{ color: "grey", fontSize: "12px" }}/>
//                     <span className="public-component-child-subtext">{getFormattedDisplayWithUnits(false, goal.attributes.val_target)}</span>
//                 </div>
//             </div>
            
//         </div>
//     )
// }