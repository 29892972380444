import React from 'react'
import { useState } from 'react'
import ModalSimpleHeader from './simple/ModalSimpleHeader'
import ModalSimpleSingleButton from './simple/ModalSimpleSingleButton'
import LoadingSymbolGrid from '../gadgets/LoadingSymbolGrid'

export default function ModalCreateWorkstreamTemplate({ hideModal, createTemplate, isTemplatizing, name }) {
    const [proposedTemplateName, setProposedTemplateName] = useState(() => { return "" })
    const [page, setPage] = useState(() => { return 1 })
    const ALLOWED_CODES = ["Enter", "NumpadEnter"]
    const handleKeyBoardPress = (code) => {
        if (ALLOWED_CODES.includes(code)) submitNewTemplate()
    }
    const submitNewTemplate = () => {
        if (!proposedTemplateName) return
        setPage(2)
        createTemplate(proposedTemplateName)
    }
    return (
        <div className="modal-mgmt-background-overlay" style={{zIndex: 200}}>
            <div className="modal-mgmt-container">
                <ModalSimpleHeader title={name ? `Create template from ${name}?` : "Create a new template"} hideModal={hideModal} />

                {!isTemplatizing && page === 1 &&
                <div className="modal-mgmt-sections">
                    <div className="modal-mgmt-section">
                        <h3>Important template information</h3>
                        {name === "board" &&
                        <>
                        <p>Columns, tasks, and board settings will be saved. <strong>Task owners, due dates, and comments will not be saved.</strong></p>
                        <p>All boards created with this template will include all current and completed tasks, but they will be reset to the left-most column of the board.</p>
                        </>
                        }
                        {name === "list" &&
                        <>
                        <p>Tasks, and list settings will be saved. <strong>Task owners, due dates, and comments will not be saved.</strong></p>
                        <p>All lists created with this template will include all current and completed tasks, but they will be reset to active (To Do) again.</p>
                        </>
                        }
                        {name === "project" &&
                        <>
                        <p>Project description, settings, workstreams, and goals will be saved. <strong>Task owners, due dates, and comments will not be saved.</strong></p>
                        <div className="modal-mgmt-info-box">
                            <p><strong>Please Note:</strong> Items that reference outside items may not be included if the references no longer exists when using this template.<br/><br/>This may impact goals that rely on a specific KPI or resources in your workspace.</p>
                        </div>
                        </>
                        }
                    </div>
                    <div className="modal-mgmt-section">
                        <h3>Provide a name for this template</h3>
                        <input type="text" placeholder="Enter a name for this template" value={proposedTemplateName} onChange={(e) => setProposedTemplateName(e.target.value)} onKeyDown={(e) => handleKeyBoardPress(e.code)} />
                    </div>

                    <ModalSimpleSingleButton isValid={proposedTemplateName} buttonText="Create Template" onclick={submitNewTemplate}/>
                </div>
                }
                {isTemplatizing &&
                <div className="modal-mgmt-sections">
                    <div className="modal-mgmt-section">
                        <div className="util-row util-justify-center" style={{marginTop: "20px", marginBottom: "20px"}}><LoadingSymbolGrid/></div>
                    </div>
                </div>
                }
                {!isTemplatizing && page === 2 &&
                <div className="modal-mgmt-sections">
                    <div className="modal-mgmt-section">
                        <h3>All done!</h3>
                        <p>You can find this template in the Templates section of the workspace.</p>
                    </div>
                    <ModalSimpleSingleButton isValid={proposedTemplateName} buttonText="Back to work" onclick={hideModal}/>
                </div>
                }
            </div>
        </div>
    )
}
