import React from 'react';
import { useRef, useEffect, useState, forwardRef } from 'react';
import DOMPurify from 'dompurify';

import { $getRoot, $createParagraphNode, $createTextNode } from 'lexical'
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { BeautifulMentionsPlugin, BeautifulMentionNode } from 'lexical-beautiful-mentions'

import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';

import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { HeadingNode } from '@lexical/rich-text';
import { $generateHtmlFromNodes } from '@lexical/html';

import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {AutoLinkPlugin} from '@lexical/react/LexicalAutoLinkPlugin';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import LexicalListToolbarPlugin from './LexicalListToolbarPlugin';
import LexicalImagePastePlugin from './LexicalImagePastePlugin';

import ProfPicCircle from '../profiles/ProfPicCircle';


export default function LexicalTextEditor({mainDescriptionDispatch, getInitialState, isUpgrade, legacyDescription, team, tags, placeholder, handleMultimediaPasteAction }) {
    const editorStateRef = useRef();
    const [mentionItems, setMentionItems] = useState(() => { return {} })
    const upgradeCompleteRef = useRef(false)
    const theme = {
        text: {
            bold: "gadget-lexical-bold",
            italic: "gadget-lexical-italic",
            underline: "gadget-lexical-underline"
        }
    }
    const URL_MATCHER = /\bhttps?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;
    const MATCHERS = [
    (text) => {
        const match = URL_MATCHER.exec(text);
        if (match === null) {
        return null;
        }
        const fullMatch = match[0];
        return {
        index: match.index,
        length: fullMatch.length,
        text: fullMatch,
        url: fullMatch.startsWith('http') ? fullMatch : `https://${fullMatch}`,
        attributes: { rel: 'noopener', target: '_blank' }, // Optional link attributes
        };
    },
    ];
    function onError(error) {
        console.error(error);
    }
    function UpgradeLegacyDescriptionPlugin() {
        const [editor] = useLexicalComposerContext();
        useEffect(() => {
            if (!upgradeCompleteRef.current) {
                editor.update(() => {
                    if (!legacyDescription) return
                    const nodeStrings = legacyDescription.split("\n");
                    const root = $getRoot()
                    for (let i = 0; i < nodeStrings.length; i++) {
                        const paragraphNode = $createParagraphNode()
                        const textNode = $createTextNode(nodeStrings[i])
                        if (textNode === "") continue
                        paragraphNode.append(textNode)
                        root.append(paragraphNode)
                    }
                })
                upgradeCompleteRef.current = true
            }
        }, [editor]);
      
        return null;
    }
    const updateDescription = (editorState, editor) => {
        editorStateRef.current = editorState
        editor.update(() => {
            const raw = $generateHtmlFromNodes(editor, null)
            const safeHtml = DOMPurify.sanitize(raw, {
                FORBID_ATTR: ["class", "dir"]
            })
            if (!editorStateRef.current) return
            mainDescriptionDispatch({
                type: "save",
                value: {
                    description_html: safeHtml,
                    description_editor: JSON.stringify(editorStateRef.current)
                }
            })
        })
    }
    const beautifulMentionsTheme = {
        "@": "gadget-beautiful-mentions",
        "@Focused": "gadget-beautiful-mentions-focused"
    }
    const CustomMenu = forwardRef(({ open, loading, ...props }, ref) => (
        <ul
            className="gadget-beautiful-mentions-menu" 
            {...props}
            ref={ref}
        />
    ));
    const CustomMenuItem = forwardRef(({ selected, item, ...props }, ref) => (
    <li className={`gadget-beautiful-mentions-menu-item ${selected ? "gadget-beautiful-mentions-menu-item-selected" : ""}`} {...props} ref={ref}>
        <ProfPicCircle height={32} src={item.data.profile_picture_url} userId={item.data._id} userName={item.data.name}  />
        <div className="gadget-beautiful-mentions-menu-item-names">
            <p>{item.data.name}</p>
            <span>@{item.data.handle}</span>
        </div>
        
    </li>
    ));

    const initialConfig = {
        namespace: 'MyEditor',
        theme: { ...theme, beautifulMentions: beautifulMentionsTheme },
        nodes: [
            HeadingNode,
            AutoLinkNode,
            LinkNode,
            ListNode,
            ListItemNode,
            BeautifulMentionNode
        ],
        onError
    };
    const updateMentionState = () => {
        try {
            let library = {}
            if (team) {
                if (team.length > 0) {
                    const teamArray = team.map(t => ({ _id: t._id, handle: t.handle, value: t.handle, name: `${t.firstName} ${t.lastName}`, profile_picture_url: t.profile_picture_url } ));
                    library["@"] = teamArray
                }
            }
            setMentionItems(library)
        } catch (error) {
            return
        }
    }
    if (getInitialState()) initialConfig.editorState = getInitialState() || undefined
    useState(() => {
        updateMentionState()
    }, [tags, team])
    return (
    <LexicalComposer initialConfig={initialConfig}>
        {isUpgrade && <UpgradeLegacyDescriptionPlugin/>}
        <LexicalListToolbarPlugin />
        <RichTextPlugin
          contentEditable={<ContentEditable className="gadget-lexical-editor app-thin-scrollbar"/>}
          placeholder={<div className="gadget-lexical-placeholder">{placeholder ? placeholder : "Add a description"}</div>}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <HistoryPlugin/>
        <LinkPlugin />
        <ListPlugin />
        <BeautifulMentionsPlugin items={mentionItems} creatable={false} insertOnBlur={false} menuComponent={CustomMenu} menuItemComponent={CustomMenuItem} />
        <AutoLinkPlugin matchers={MATCHERS} />
        <OnChangePlugin onChange={updateDescription} ignoreSelectionChange />
        <LexicalImagePastePlugin handleMultimediaPasteAction={handleMultimediaPasteAction}  />
    </LexicalComposer>
    );
}