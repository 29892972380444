import React from 'react'

export default function HProgressBar({percentage, height}) {
    // percentage: number between 0 and 100
    const getColor = () => {
      if (percentage > 99) {
        return "#049921"
      } else if (percentage > 69) {
        return "rgb(41, 115, 175)"
      } else if (percentage > 49) {
        return "#ffb900"
      } else {
        return "#fc636b"
      }
    }

    const containerStyles = {
        height: height ? height : 10,
        width: '100%',
        backgroundColor: "#e0e0de",
        borderRadius: 50,
      }
    
      const fillerStyles = {
        height: '100%',
        width: `${percentage}%`,
        backgroundColor: getColor(),
        borderRadius: 'inherit',
        textAlign: 'right',
        transition: 'width 1s ease-in-out'
      }
    
    return (
        <div style={containerStyles}>
            <div style={fillerStyles}></div>
        </div>
    )
}
