import React from 'react'
import Api from '../../common/APIUtils'
import { useEffect, useState, useRef } from 'react';
import { hasPermission } from '../../common/Helpers';
import { BsLightningChargeFill } from 'react-icons/bs'
import { useHistory } from 'react-router-dom';

export default function ContextBreadcrumb({component, componentData, workspaceName, orderedParents, permissions}) {
    const history = useHistory()
    const [parentAlignmentMenuShowing, setParentAlignmentMenuShowing] = useState(() => { return false })
    const [contextLoaded, setContextLoaded] = useState(() => { return false })
    const [context, setContext] = useState(() => { return false })
    const [potentialParents, setPotentialParents] = useState(() => { return [] })
    const [parentsLoaded, setParentsLoaded] = useState(() => { return false })
    const api = new Api()
    const parentAlignmentMenuRef = useRef(null)
    const fetchContext = () => {
        if (!component) return
        if (!component._id) return
        const componentId = component._id
        const params = {
            id: componentId
        }
        api.getComponentContext(params)
        .then((res) => {
            if (res.data.response) {
                setContext(res.data.response.context_tree)
                setContextLoaded(true)
            }
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const hideParentAlignmentMenu = () => {
        setParentAlignmentMenuShowing(false)
    }
    const createBond = (parentId) => {
        if (!parentId) return hideParentAlignmentMenu()
        if (!component) return hideParentAlignmentMenu()
        if (!component.workspace_id) return hideParentAlignmentMenu()
        let payload = {
            parent_id: parentId,
            child_id: component._id,
            workspace_id: component.workspace_id
        }
        api.createBond(payload)
        .then((res) => {
            if (res.data) {
                fetchContext()
            }
        })
        .catch((err) => {
            console.log(err)
        })
        hideParentAlignmentMenu()
    }
    const toggleParentAlignmentMenuShowing = () => {
        setParentAlignmentMenuShowing(!parentAlignmentMenuShowing)
    }
    const fetchPotentialParents = () => {
        if (!component) return
        if (!component.workspace_id) return
        api.getPotentialAlignmentsBasedOnType({type: component.name, workspaceId: component.workspace_id})
        .then((res) => {
            if (res.data) {
                setPotentialParents(res.data.response.potential_parents)
                setParentsLoaded(true)
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }
    const getParentProjectName = () => {
        if (!hasProjectAlignment()) return false
        return context.parents.initiative[0].display_name
    }
    const hasProjectAlignment = () => {
        try {
            if (!contextLoaded) return false
            if (!context) return false
            if (context.parents.initiative) {
                return true
            }
        return false
        } catch (error) {
            return false
        }
        
    }
    const goToWorkspace = () => {
        if (!component) return
        if (!component.workspace_id) return
        history.push(`/workspaces/${component.workspace_id}`)
    }
    const goToParentProject = () => {
        if (!hasProjectAlignment) return
        const parentProject = context.parents.initiative[0]
        if (!parentProject.workspace_id) return
        history.push(`/workspaces/${parentProject.workspace_id}/c/${parentProject._id}`)
    }
    const getPotentialParents = () => {
        if (!parentsLoaded) return []
        if (!potentialParents) return []
        const p = Array.from(potentialParents)
        const projectsAvailable = p.filter(a => a.name === 'initiative')
        return projectsAvailable
    }
    const goToSpecificComponent = (c) => {
        if (!c._id) return
        if (!c.workspace_id) return
        history.push(`/workspaces/${c.workspace_id}/c/${c._id}`)
    }
    const getMiddleAlignmentItems = () => {
        if (!contextLoaded) return false
        if (hasProjectAlignment()) return <div className="gadget-breadcrumb-component" onClick={goToParentProject}>{getParentProjectName()}</div>
        if (!parentsLoaded) return false
        if (getPotentialParents().length === 0) return false
        return (
            <div className="gadget-breadcrumb-link-new-button-container" ref={parentAlignmentMenuRef}>
                {hasPermission(permissions, 'component_update') ? (
                    <div className="gadget-breadcrumb-link-new-button gadget-breadcrumb-component" onClick={toggleParentAlignmentMenuShowing}>
                        <BsLightningChargeFill/>
                        <div>Set Project Alignment</div>
                    </div>
                ) : (
                    <div className="gadget-breadcrumb-link-new-button gadget-breadcrumb-component">
                        <div style={{color: "black"}}>No Project Alignment</div>
                    </div>
                )}
                
                {parentAlignmentMenuShowing &&
                <div className="gadget-breadcrumb-link-new-parents-container">
                    {getPotentialParents().map((par) => (
                        <div key={par._id} className="gadget-breadcrumb-link-new-parents-item" onClick={() => createBond(par._id)}>{par.display_name}</div>
                    ))}
                </div>
                }
            </div>
        )
    }
    const getOrderedParentItems = () => {
        if (!orderedParents) return false
        if (orderedParents.length === 0) return false
        const orderedParentDisplay = orderedParents.map((orderedComponent) => (
            <div key={orderedComponent._id} className="gadget-breadcrumb-container-main">
                <div className="gadget-breadcrumb-component" onClick={() => goToSpecificComponent(orderedComponent)}>{orderedComponent.display_name}</div>
                <div className="gadget-breadcrumb-separator">/</div>
            </div>
        ))
        return orderedParentDisplay
    }
    const getWorkspaceName = () => {
        if (!workspaceName) return false
        return workspaceName
    }
    useEffect(() => {
        fetchContext()
        fetchPotentialParents()
    // eslint-disable-next-line
    }, [component])
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (parentAlignmentMenuRef.current && !parentAlignmentMenuRef.current.contains(event.target)) {
                hideParentAlignmentMenu()
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [parentAlignmentMenuRef])
    return (
        <div className="gadget-breadcrumb-container-main">
            {getWorkspaceName() &&
            <>
                <div className="gadget-breadcrumb-component" onClick={goToWorkspace}>{getWorkspaceName()}</div>
                <div className="gadget-breadcrumb-separator">/</div>
            </>
            }
            
            {getMiddleAlignmentItems() }
            {getMiddleAlignmentItems() && <div className="gadget-breadcrumb-separator">/</div>}
            {getOrderedParentItems() }
            
            <div className="gadget-breadcrumb-component gadget-breadcrumb-component-current">{component.display_name}</div>
        </div>
    )
}
