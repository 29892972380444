exports.FAQ = [
    {
        question: "Why do I need Tetheros?",
        answer: "To improve teamwork, project alignment, and accountability on your team with a simple framework for managing work."
    },
    {
        question: "How does it work?",
        answer: "Track tasks with checklists, boards, and projects. Organize campaigns, operations, and initiatives as a startup, association, department, or solopreneur."
    },
    {
        question: "Do I need to pay to use Tetheros?",
        answer: "No. A paid account is only required to CREATE workspaces, not join them. We designed Tetheros to make it easy for you to collaborate with others. Your account is free and follows you to any workspaces you are a member of."
    },
    {
        question: "How many tasks/boards/projects does a workspace hold?",
        answer: "We don't believe in arbitrary limits or paywalls. Every workspace can host an unlimited amount of tasks, boards, lists, and projects."
    },
    {
        question: "What kind of support do you offer?",
        answer: "Any account on a paid plan receives priority support that can be initiated through email (details provided at sign up). Priority support means your request will be handled at the highest possible level with respect to urgency as determined by our team."
    },
    {
        question: "How many users can my workspace have?",
        answer: "Users are counted at the license (network) level, not the workspace level. This means one workspace can potentially host up to 300 users (the max license level currently offered)."
    },
    {
        question: "What's included in the 7-day free trial?",
        answer: "The entire boathouse. Roam the product like you own it during the trial period."
    },
    {
        question: "Does Tetheros integrate with other tools or platforms?",
        answer: "Not yet, but email support is rolling out in Winter 2024."
    },
    {
        question: "What measures are in place to protect user privacy and data security?",
        answer: "Our infrastructure is cloud hosted and secured, and we follow best practices with user data and privacy. Your information will never leave our servers without reason and permission from you."
    }
]