import React from 'react'
import { useState, useEffect } from 'react';
import { BiCheck } from 'react-icons/bi'
import { useHistory } from 'react-router-dom';
import { getColorFromIndex, getColorStylesFromObjectId } from '../../common/Helpers';

export default function CalendarFilter({displayText, itemId, index, filter, updateFilterState, goToLink, propertyStringToUseForColor }) {
    const history = useHistory();
    const [isChecked, setIsChecked] = useState(() => { return true })
    
    const getCheckedStyles = () => {
        if (propertyStringToUseForColor) {
            const colorStyles = getColorStylesFromObjectId(propertyStringToUseForColor)
            return {
                backgroundColor: colorStyles.backgroundColor,
                border: `2px solid ${colorStyles.backgroundColor}`
            }
        }
        return {
            backgroundColor: getColorFromIndex(index),
            border: `2px solid ${getColorFromIndex(index)}`
        }
    }
    const getUncheckedStyles = () => {
        if (propertyStringToUseForColor) {
            const colorStyles = getColorStylesFromObjectId(propertyStringToUseForColor)
            return {
                border: `2px solid ${colorStyles.backgroundColor}`
            }
        }
        return {
            border: `2px solid ${getColorFromIndex(index)}`
        }
    }
    const potentiallyNavigate = () => {
        if (goToLink) history.push(goToLink)
    }
    useEffect(() => {
        if (!filter) return
        setIsChecked(filter[itemId])
    // eslint-disable-next-line
    }, [filter])
    return (
        <div className="calendar-filter-item">
            {isChecked ? (
                <span onClick={() => updateFilterState(itemId, false)} className="calendar-filter-checkable-box calendar-filter-checked" style={getCheckedStyles()}><BiCheck/></span>
            ) : (
                <span onClick={() => updateFilterState(itemId, true)} className="calendar-filter-checkable-box calendar-filter-unchecked" style={getUncheckedStyles()}></span>
            )}
            
            <span onClick={potentiallyNavigate} title={displayText}>{displayText}</span>
        </div>
    )
}
