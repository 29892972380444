import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { BsCheck2, BsBookmarkFill, BsPencilFill, BsFillBarChartFill, BsFillPuzzleFill } from 'react-icons/bs'
import { useHistory } from 'react-router-dom';
import Api from '../../../common/APIUtils';
import ConfirmAction from '../../modals/ConfirmAction';
import ModalUpdateListTask from '../../modals/ModalUpdateListTask';
import ComponentCriteria from '../sidepanes/ComponentCriteria';
import { isCompletable, hasPermission } from '../../../common/Helpers';
import ComponentMainDescription from '../ComponentMainDescription';
import ComponentMainComments from '../ComponentMainComments';
import SimpleListActionMenu from '../SimpleListActionMenu';
import { parseISO, format, isValid, formatDistanceToNow } from 'date-fns'
import DateBoxEditable from '../misc/DateBoxEditable';
import OwnerSelectorDropdownSmall from '../../gadgets/OwnerSelectorDropdownSmall';
import ContextBreadcrumb from '../../gadgets/ContextBreadcrumb';
import WorkspaceCompHashtags from '../../gadgets/WorkspaceCompHashtags';
import WorkspaceCompInitiativeAddMarker from '../initiatives/WorkspaceCompInitiativeAddMarker';
import ModalCreateNewResourceInProject from '../../modals/ModalCreateNewResourceInProject';
import EditableComponentTitle from '../../gadgets/EditableComponentTitle';

import ResourceReference from '../../gadgets/ResourceReference';

export default function WorkspaceCompTaskMain({workspaceName, component, team, permissions, componentData, fetchComponent, workspaceComponents, showSpecificNewPostForm}) {
    const api = new Api();
    let history = useHistory();
    const resourceMenuRef = useRef(null)
    const [showEditingModal, setShowEditingModal] = useState(() => { return false })
    const [resources, setResources] = useState(() => { return [] })
    const [isAddingNewResource, setIsAddingNewResource] = useState(() => { return false })
    const [isShowingAddNewResourceMenu, setIsShowingAddNewResourceMenu] = useState(() => { return false })
    const [isShowingCreateNewResourceModal, setIsShowingCreateNewResourceModal] = useState(() => { return false })
    const [allComponents, setAllComponents] = useState(() => { return {} })
    const [proposedOwnerId, setProposedOwnerId] = useState(() => { return component.owner_id ? component.owner_id : "" })
    const [proposedDueDate, setProposedDueDate] = useState(() => { return component.attributes.date_due ? component.attributes.date_due : "" })
    const [proposedCriteria, setProposedCriteria] = useState(() => { return "" })
    const [isShowingConfirmMenu, setIsShowingConfirmMenu] = useState(() => { return false })
    const [confirmMenuContent, setConfirmMenuContent] = useState(() => { return "" })
    const [confirmMenuAction, setConfirmMenuAction] = useState(() => { return false })
    const [tags, setTags] = useState(() => { return [] })
    const updateTaskDetails = (payload) => {
        const path = `/workspace/components/${component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const openAddExistingResourceModal = () => {
        setIsShowingAddNewResourceMenu(false)
        setIsShowingCreateNewResourceModal(false)
        setIsAddingNewResource(true)
    }
    const openCreateNewResourceModal = () => {
        setIsShowingAddNewResourceMenu(false)
        setIsAddingNewResource(false)
        setIsShowingCreateNewResourceModal(true)
    }
    const getOrderedParents = () => {
        let potentialParentComponentsArray = []
        if (workspaceComponents['list']) potentialParentComponentsArray = workspaceComponents['list']
        if (workspaceComponents['board']) potentialParentComponentsArray = potentialParentComponentsArray.concat(workspaceComponents['board'])
        if (potentialParentComponentsArray.length === 0) return []
        return potentialParentComponentsArray.filter(a => a._id === component.attributes.parent)
    }
    const updateDescription = (newDescription) => {
        updateTaskDetails({
            attributes: {
                details: newDescription
            }
        })
    }
    const restoreTask = () => {
        hideConfirmPopup()
        const taskId = componentData.component._id;
        let attributes = {
            active: true,
            progress: 0
        }
        // check if parent is a board
        if (componentData.parent ? componentData.parent : false) {
            if (componentData.parent.name === 'board') {
                attributes['state'] = componentData.parent.attributes.column_id_new
            }
        }
        const payload = {
            attributes: attributes,
            status: 'active'
        }
        const path = `/workspace/components/${taskId}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
            setShowEditingModal(false);
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const completeTask = () => {
        hideConfirmPopup()
        const taskId = component._id;
        let attributes = {
            active: false,
            date_completed: new Date(),
            progress: 1
        }
        // check if parent is a board
        if (componentData.parent ? componentData.parent : false) {
            if (componentData.parent.name === 'board') {
                attributes['state'] = componentData.parent.attributes.column_id_done
            }
        }
        const payload = {
            attributes: attributes,
            status: 'completed',
            completed: new Date()
        }
        const path = `/workspace/components/${taskId}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            setShowEditingModal(false);
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const isComplete = () => {
        try {
            if (!component) return false
            if (!component.status) return false
            // if (!component.completed) return false
            if (component.status === 'completed') return true
            return false
        } catch (error) {
           return false 
        }
    }
    const getCreationDate = () => {
        if (!component) return ""
        if (!component.createdAt) return ""
        if (!isValid(parseISO(component.createdAt))) return ""
        return format(parseISO(component.createdAt), 'PP')
    }
    const getLastUpdatedText = () => {
        if (!component) return ""
        if (!component.updatedAt) return ""
        if (!isValid(parseISO(component.updatedAt))) return ""
        return formatDistanceToNow(parseISO(component.updatedAt))
    }
    const deleteTask = () => {
        hideConfirmPopup()
        const parentListId = componentData.component.attributes.parent;
        const payload = {
            componentId: componentData.component._id
        }
        const workspaceId = componentData.component.workspace_id;

        api.deleteWorkspaceComponent(payload)
        .then((res) => {
            setShowEditingModal(false);
            history.push(`/workspaces/${workspaceId}/c/${parentListId}`);
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const updateTaskOwner = () => {
        if (proposedOwnerId !== component.owner_id) {
            updateTaskDetails({
                owner_id: proposedOwnerId
            })
        }
    }
    const updateTaskDueDate = () => {
        if (proposedDueDate !== component.attributes.date_due) {
            updateTaskDetails({
                attributes: {
                    date_due: proposedDueDate
                }
            })
        }
    }
    const handleNewCriteriaPress = (e) => {
        if (e.key === 'Enter') {
            if (proposedCriteria) {
                createCriteria()
            }
        }
    }
    const createCriteria = () => {
        const taskId = componentData.component._id;
        const payload = {
            description: proposedCriteria,
            component_id: taskId
        }
        setProposedCriteria("")
        api.addComponentCriteria(payload)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const deleteCriteria = (criteriaId) => {
        const payload = {
            criteria_id: criteriaId
        }
        api.deleteComponentCriteria(payload)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const updateCriteriaStatus = (criteriaId, status) => {
        let shouldPrompt = false;
        const payload = {
            criteria_id: criteriaId,
            status: status
        }
        const currentlyComplete = getCountOfCompletedCriteria()
        if (component.status !== 'completed' && status === 'completed' && currentlyComplete === (componentData.component.criteria.length - 1)) {
            shouldPrompt = true;
        }
        api.updateComponentCriteria(payload)
        .then((res) => {
            fetchComponent();
            if (shouldPrompt) {
                askToCompleteBasedOnCriteria()
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }
    const updateCriteriaDescription = (criteriaId, description) => {
        const payload = {
            criteria_id: criteriaId,
            description: description
        }
        api.updateComponentCriteria(payload)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err)
        })
    }
    const getAllComponents = async () => {
        if (!componentData) return
        if (!componentData.component) return
        if (componentData.component.workspace_id) {
            var payload = { workspaceId: componentData.component.workspace_id }
        }
        if (componentData.component.network_id) {
            payload = { networkId: componentData.component.network_id }
        }

        api.getAllWorkspaceComponents(payload)
        .then((res) => {
            if (res.data) {
                setAllComponents(res.data.response.components);
            } else {
                console.log('Problem fetching components')
            }
        })
        .catch((err) => {
            console.log(err);
        });        
    }
    const askToCompleteBasedOnCriteria = () => {
        setConfirmMenuContent("All subtasks completed.  Do you want mark this task complete?")
        setConfirmMenuAction(() => completeTask)
        setIsShowingConfirmMenu(true)
    }
    const getCountOfCompletedCriteria = () => {
        try {
            const inactiveCriteria = componentData.component.criteria.filter(function (crit) {
                return crit.status !== 'active'
            })
            return inactiveCriteria.length
        } catch (error) {
            return 0;
        }
    }
    const askForDeleteConfirmation = () => {
        setConfirmMenuContent("Are you sure you want to permanently delete this task?")
        setConfirmMenuAction(() => deleteTask)
        setIsShowingConfirmMenu(true)
    }
    const askForCompleteConfirmation = () => {
        setConfirmMenuContent("Mark task complete?")
        setConfirmMenuAction(() => completeTask)
        setIsShowingConfirmMenu(true)
    }
    const hideConfirmPopup = () => {
        setIsShowingConfirmMenu(false)
    }
    const getActionList = () => {
        let actions = [];
        if (hasPermission(permissions, 'component_delete')) {
            actions = actions.concat([
                {
                    text: 'Delete',
                    action: askForDeleteConfirmation,
                    id: 'delete'
                }
            ])
        }
        
        if (component.status === 'active' && hasPermission(permissions, 'component_update')) {
            actions = [
                        {
                            text: 'Complete',
                            action: askForCompleteConfirmation,
                            id: 'complete'
                        }
                    ].concat(actions)
        }
        return actions
    }
    const getComments = () => {
        if (!componentData) return []
        if (!componentData.component) return []
        if (!componentData.component.comments) return []
        return componentData.component.comments
    }
    const changeDueDate = (d) => {
        setProposedDueDate(format(d, 'yyyy-MM-dd'))
    }
    const removeMarker = (componentId) => {
        if (!hasPermission(permissions, 'component_update')) return
        const childrenComps = componentData.children_components
        const bondedChild = childrenComps.find(a => a.child_id === componentId)
        if (!bondedChild) return
        const bondId = bondedChild._id
        const payload = {
            workspaceId: component.workspace_id,
            bondId: bondId
        }
        api.deleteBond(payload)
        .then((res) => {
            fetchComponent()
            fetchFullAlignmentInformation()
        })
        .catch((err) => {
            console.log(err);
        });
    }
    const addMarker = (cId) => {
        setIsAddingNewResource(false)
        const payload = {};
        payload['workspace_id'] = component.workspace_id;
        payload['child_id'] = cId;
        payload['parent_id'] = component['_id'];
        api.createBond(payload)
        .then((res) => {
            fetchComponent();
            fetchFullAlignmentInformation();
        });
    }
    const fetchFullAlignmentInformation = async () => {
        if (!componentData) return
        if (!componentData.component) return
        if (!componentData.component._id) return
        api.getComponentAlignments({componentId: componentData.component._id})
        .then((res) => {
            if (res.data) {
                if (res.data.response) {
                    setResources(res.data.response.alignments)
                }
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }
    const fetchAlignments = () => {
        getAllComponents()
        fetchComponent();
        fetchFullAlignmentInformation();
    }
    useEffect(() => {
        updateTaskOwner()
    // eslint-disable-next-line
    }, [proposedOwnerId])
    useEffect(() => {
        updateTaskDueDate()
    // eslint-disable-next-line
    }, [proposedDueDate])
    useEffect(() => {
        if (!componentData) return
        if (!componentData.component) return
        if (!componentData.component.component_tags) {
            setTags([])
            return
        }
        setTags(componentData.component.component_tags)
    }, [componentData])
    useEffect(() => {
        getAllComponents()
        fetchFullAlignmentInformation()
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (resourceMenuRef.current && !resourceMenuRef.current.contains(event.target)) {
                setIsShowingAddNewResourceMenu(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [resourceMenuRef])
    return (
        <div className="workcomp-page-container">
            {/* Modals */}
            {showEditingModal && <ModalUpdateListTask deleteTask={deleteTask} restoreTask={restoreTask} completeTask={completeTask} team={team} task={component} setShowEditingModal={setShowEditingModal} updateTask={updateTaskDetails}/>}
            {isShowingConfirmMenu && <ConfirmAction mainText={confirmMenuContent} onconfirm={confirmMenuAction} hideMe={hideConfirmPopup} />}
            {isShowingCreateNewResourceModal && <ModalCreateNewResourceInProject parentProjectComponent={component} hideModal={() => setIsShowingCreateNewResourceModal(false)} fetchAlignments={fetchAlignments}/>}
            {isAddingNewResource && <WorkspaceCompInitiativeAddMarker types={["link", "file"]} allComponents={allComponents} messaging={{title: "Add new resource", empty: "All resources have already been linked!"}} setIsAddingNewMarker={setIsAddingNewResource} potentialBondIds={componentData.potential_bonds.as_parent} createNewBond={addMarker}/> }
            <div className="component-page-outer" style={{marginTop: "12px"}}>

                <div className="component-page-row" style={{alignItems: "center", justifyContent: "space-between"}}>
                    <div className="component-page-row" style={{alignItems: "center", justifyContent: "flex-start", gap: "2em"}}>
                        <div>
                            <ContextBreadcrumb permissions={permissions} component={component} componentData={componentData} workspaceName={workspaceName} orderedParents={getOrderedParents()}/>
                        </div>
                    </div>
                    <div>
                        {hasPermission(permissions, "component_update") && <SimpleListActionMenu actionsList={getActionList()} symbol="gear"/>}
                    </div>
                </div>               

                <div className="workcomp-page-main-display-split-container">

                    <div className="workcomp-page-main-display-split-primary">
                        <div className="component-page-row" style={{alignItems: "center", gap: "0.5em", marginTop: "0.5em" }}>
                            <EditableComponentTitle canWrap={true} permissions={permissions} component={component} activeComponentId={component._id} fetchComponent={fetchComponent}/>
                        </div>
                        <WorkspaceCompHashtags tags={tags} workspaceId={component.workspace_id}/>

                        <div className="component-page-initiative-summary-section-container" style={{marginTop: "24px", paddingBottom: "12px"}}>
                            <div className="component-page-initiative-summary-item-description-container">
                                <ComponentMainDescription team={team} permissions={permissions} component={component} updateDetails={updateDescription}/>
                            </div>
                        </div>

                        <div className="component-page-initiative-alignments-section-container" style={{borderBottom: "1px solid rgb(201, 201, 201)", marginBottom: "24px"}}>
                            <div className="component-page-initiative-section-heading">Subtasks</div>

                            {(componentData.component ? componentData.component.criteria.length : 0) > 0 ? (
                                <div className="criteria-item-list" style={{maxHeight: "unset"}}>
                                {componentData.component.criteria.map((criteria) => (
                                    <ComponentCriteria permissions={permissions} updateCriteriaDescription={updateCriteriaDescription} deleteCriteria={deleteCriteria} updateCriteriaStatus={updateCriteriaStatus} key={criteria._id} data={criteria}/>
                                ))}
                                </div>
                            ) : (
                                <div style={{marginTop: "12px", fontSize: "14px"}}>Add any needed subtasks</div>
                            )}
                            
                            {hasPermission(permissions, 'component_update') ? (
                                <div className="criteria-item-new-item-container">
                                    <input type="text" placeholder="Add new subtask" value={proposedCriteria} onKeyPress={(e) => handleNewCriteriaPress(e)} onChange={(e) => setProposedCriteria(e.target.value)}/>
                                </div>
                            ) : (
                                <div style={{padding: "12px"}}></div>
                            )}
                        </div>

                        <div className="component-page-initiative-alignments-section-container">
                            <div className="component-page-initiative-section-heading">Comments</div>
                            <ComponentMainComments team={team} fetchComponent={fetchComponent} comments={getComments()} componentId={component._id}/>
                        </div>
                    </div>

                    {/* Sidebar */}
                    <div className="workcomp-page-main-display-split-sidebar">
                        <div className="workspace-panel-settings-section">
                            {/* Task */}
                            <div className="workspace-panel-setting workspace-panel-setting-mini workspace-panel-setting-borders">
                                <div className="workspace-panel-settings-section-heading">About</div>

                                <div className="workspace-panel-settings-status-items">
                                    <div className="workspace-panel-setting-iconography-and-text">
                                        <BsFillPuzzleFill/>
                                        <div>Task</div>
                                    </div>
                                    <div className="workspace-panel-setting-iconography-and-text">
                                        <BsFillBarChartFill/>
                                        <div className={`component-view-title-meta-about-status-${component.status}`}>{component.status}</div>
                                    </div>
                                    <div className="workspace-panel-setting-iconography-and-text">
                                        <BsBookmarkFill/>
                                        <div>Created {getCreationDate()}</div>
                                    </div>
                                    <div className="workspace-panel-setting-iconography-and-text">
                                        <BsPencilFill/>
                                        <div>Updated {getLastUpdatedText()} ago</div>
                                    </div>
                                    {(isCompletable(component) && hasPermission(permissions, 'component_update')) && (
                                        <div className="workspace-panel-setting-action-launch" style={{backgroundColor: "green"}} onClick={askForCompleteConfirmation}>
                                            <BsCheck2/> Mark Task Complete
                                        </div>
                                    )}
                                    {isComplete() && (
                                        <div className="workspace-panel-setting-iconography-and-text">
                                            <BsCheck2 style={{color: "green"}} />
                                        <div>Completed {format(parseISO(component.completed), 'PP')}</div>
                                    </div> 
                                    )}
                                </div>
                            </div>
                            {/* Owner */}
                            <div className="workspace-panel-setting workspace-panel-setting-mini workspace-panel-setting-borders" style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                                <div className="workspace-panel-settings-section-heading">Task Owner</div>
                                <div style={{width: "100%"}}>
                                    <OwnerSelectorDropdownSmall permissions={permissions} proposedOwnerId={proposedOwnerId} team={team} setProposedOwnerId={setProposedOwnerId}/>
                                </div>
                            </div>
                            {/* Due Date */}
                            <div className="workspace-panel-setting workspace-panel-setting-mini workspace-panel-setting-borders" style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                                <div className="workspace-panel-settings-section-heading">Due Date</div>
                                {hasPermission(permissions, 'component_update') ? (
                                    <DateBoxEditable component={component} editable={!isComplete()} date={parseISO(proposedDueDate)} label="" onupdate={changeDueDate} clearDate={() => setProposedDueDate("")} />
                                ) : (
                                    <DateBoxEditable component={component} editable={false} date={parseISO(proposedDueDate)} label="" />
                                )}
                            </div>
                            {/* Resources */}
                            <div className="workspace-panel-setting workspace-panel-setting-mini workspace-panel-setting-borders" style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                                <div className="workspace-panel-settings-section-heading" style={{display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", position: "relative"}} ref={resourceMenuRef}>
                                    Resources
                                    {hasPermission(permissions, 'component_update') && <span className="modal-full-screen-task-resources-add-button" onClick={() => setIsShowingAddNewResourceMenu(!isShowingAddNewResourceMenu)}>+ Add</span>}
                                    {isShowingAddNewResourceMenu && (
                                        <div className="component-page-initiative-section-heading-action-button-menu" style={{right: "40px", top: "20px"}}>
                                            <div className="component-page-initiative-section-heading-action-button-menu-opt" onClick={openCreateNewResourceModal}>New Resource</div>
                                            <div className="component-page-initiative-section-heading-action-button-menu-opt" onClick={openAddExistingResourceModal}>Add Existing Resource</div>
                                        </div>
                                    )}
                                </div>
                                <div className="modal-full-screen-task-resources-list" style={{width: "100%"}}>
                                    {resources.map((resource) => (
                                        <ResourceReference resource={resource} removeMarker={removeMarker} permissions={permissions} />
                                        // <TaskPageResource key={resource._id} resource={resource} removeMarker={removeMarker} permissions={permissions} />
                                    ))}
                                    {resources.length === 0 && <div style={{fontSize: "14px", color: "grey"}}>Add resources for better context</div> }
                                    
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                
            </div>
        </div>
    )
}