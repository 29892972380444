import React from 'react'
import { useState } from 'react';
import '../../../styles/modals.css';
import { FaTimes, FaTasks, FaRunning, FaThLarge } from 'react-icons/fa'
import { BiTargetLock, BiCustomize, BiTrendingUp, BiSearch, BiKey } from 'react-icons/bi'
import HProgressBar from '../../HProgressBar';
import ResourceSymbol from '../../gadgets/ResourceSymbol';

export default function WorkspaceCompInitiativeAddMarker({setIsAddingNewMarker, allComponents, potentialBondIds, createNewBond, type, types, messaging}) {
    const [query, setQuery] = useState(() => { return ""})
    const getComponentSymbol = (name, cId) => {
        const symbolMap = {
            list: <FaTasks className="gomano-card-component-symbol"/>,
            sprint: <FaRunning className="gomano-card-component-symbol"/>,
            kanban: <FaThLarge className="gomano-card-component-symbol"/>,
            goal: <BiTargetLock className="gomano-card-component-symbol"/>,
            board: <BiCustomize className="gomano-card-component-symbol"/>,
            kpi: <BiTrendingUp className="gomano-card-component-symbol"/>,
            kresult: <BiKey className="gomano-card-component-symbol"/>,
            file: <div className="gomano-card-component-symbol"><ResourceSymbol resource={allComponents[cId]} /></div>,
            link: <div className="gomano-card-component-symbol"><ResourceSymbol resource={allComponents[cId]} /></div>
        }
        return symbolMap[name]
    }
    const getProgressMetricForComponent = (component) => {
        if (component.progress) {
            return Math.round(component.progress*100);
        } else {
            return 0
        }
    }
    const getValidBondsForType = () => {
        let allowedComponentBondIds = []
        for (let i = 0; i < potentialBondIds.length; i++) {
            const componentId = potentialBondIds[i];
            if (!allComponents[componentId]) continue
            if (types) {
                if (!types.includes(allComponents[componentId]['name'])) continue
            }
            if (!type) {
                allowedComponentBondIds.push(componentId)
                continue
            }
            if (allComponents[componentId]['name'] === type) allowedComponentBondIds.push(componentId)
        }
        if (!query) return allowedComponentBondIds
        const filteredAllowedComponentBondIds = allowedComponentBondIds.filter((componentBondId) => {
            const displayName = allComponents[componentBondId]['display_name'].toLowerCase()
            if (displayName.indexOf(query.toLowerCase()) !== -1) return true
            return false
        })
        return filteredAllowedComponentBondIds
    }
    const transformName = (currentName) => {
        if (currentName === "kresult") return "Goal"
        return currentName
    }
    const getPopupTitle = () => {
        const defaultTitle = "Add project marker"
        if (!messaging) return defaultTitle
        if (!messaging.title) return defaultTitle
        return messaging.title
    }
    const getPopupEmptyStateText = () => {
        const defaultText = "Great job, you're already tracking everything!"
        if (!messaging) return defaultText
        if (!messaging.empty) return defaultText
        return messaging.empty
    }
    return (
        <div className="alignment-modal-background">
            <div className="goal-marker-modal-form-container">
                <div className="goal-marker-modal-form-header">
                    <h3>{getPopupTitle()}</h3>
                    <FaTimes className="goal-marker-modal-form-cancel" onClick={(e) => setIsAddingNewMarker(false)}/>
                </div>
                <div className="gomano-search-container">
                    <BiSearch/>
                    <input type="text" autoFocus={true} value={query} onChange={(e) => setQuery(e.target.value)} placeholder="Find something" />
                </div>

                    {/* Check if there are any potential bonds */}
                    {getValidBondsForType().length > 0 ? (
                        <>
                        {/* All the different potential children, laid out as children */}
                        <div className="goal-marker-new-option-card-container">
                            {/* Create a card for every possible BOND */}
                            {getValidBondsForType().map((cId) => (
                                <div className="goal-marker-new-option-card" key={cId}>

                                    <div className="gomano-card-top">
                                        <div className="gomano-card-row">
                                            {getComponentSymbol(allComponents[cId]['name'], cId)}
                                            <div className="gomano-card-component-type">{transformName(allComponents[cId]['name'])}</div>
                                        </div>
                                        
                                    </div>

                                    <div className="gomano-card-middle">
                                        <div className="gomano-card-component-name">{allComponents[cId]['display_name']}</div>
                                        {/* Show archived badge if the component is archived */}
                                        {allComponents[cId]['status'] === 'archived' &&
                                            <div>
                                                <span className="gomano-card-component-status-archived">{allComponents[cId]['status']}</span>
                                            </div>
                                        }
                                    </div>
                                    

                                    <div className="gomano-card-bottom">
                                        <div className="gomano-card-row">
                                            <div className="gomano-card-progress-bar"><HProgressBar percentage={getProgressMetricForComponent(allComponents[cId])}/></div>
                                            <span className="gomano-card-progress-label">{getProgressMetricForComponent(allComponents[cId])}%</span>
                                        </div>
                                        
                                    </div>
                                    <div className="gomano-card-add-button" onClick={(e) => createNewBond(cId)}>+ Add</div>
                                </div>
                            ))} 
                        </div>
                        </>
                    ) : (
                        <div>
                            <div className="gomano-message-text">{getPopupEmptyStateText()}</div>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                                <span className="gomano-message-button" onClick={(e) => setIsAddingNewMarker(false)}>Return</span>
                            </div>
                        </div>
                    )}
            </div>
        </div>
    )
}
