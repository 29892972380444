import React, { useState } from 'react'
import { hasPermission } from '../../common/Helpers'
import ResourceSymbol from './ResourceSymbol'

import { MdClose } from 'react-icons/md';
import { FaPaperclip } from "react-icons/fa6";

import { getWorkspaceFileUrl } from '../../common/Api'

export default function ResourceReference({resource, removeMarker, permissions}) {
    const [isDownloading, setIsDownloading] = useState(() => { return false })
    const [resourceUrl, setResourceUrl] = useState(() => { return false })
    const downloadFile = () => {
        if (resource.name !== "file") return
        if (isDownloading) return
        if (resourceUrl) return openFile(resourceUrl)
        setIsDownloading(true)
        getWorkspaceFileUrl(resource.workspace_id, resource._id)
        .then((res) => {
            if (res.data) {
                if (res.data.url) {
                    setResourceUrl(res.data.url)
                    openFile(res.data.url)
                }
            }
            setIsDownloading(false)
        })
        .catch((err) => {
            console.log(err)
            return setIsDownloading(false)
        })
    }
    const attemptMarkerRemoval = (e) => {
        try {
            e.stopPropagation()
            removeMarker(resource._id)
        } catch (error) {
            return
        }
    }
    const openFile = (url) => {
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        try {
            iframe.src = url;
            setTimeout(() => {
            document.body.removeChild(iframe);
            }, 2000); 
        } catch (error) {
            return 
        }
    }
    const goToResource = () => {
        if (resource.name === "link") {
            if (!resource.attributes.destination) return
            window.open(resource.attributes.destination, '_blank').focus()
            return
        }
        downloadFile()

    }
    const getDescription = () => {
        try {
            if (resource.attributes.description !== "") return resource.attributes.description
            return "Open in browser"
        } catch (error) {
            return "Open in browser"
        }
    }
    return (
        <div className="modal-edit-task-resource-reference" onClick={goToResource}>
            <div className="modal-edit-task-resource-reference-name" title={getDescription()}>
                {resource.name === "link" && <FaPaperclip style={{color: "#2A73AE", fontSize: "16px"}}/>}
                {resource.name === "file" && <ResourceSymbol resource={resource} />}
                {resource.display_name}
            </div>
            <div className="modal-edit-task-resource-actions">
                {hasPermission(permissions, 'component_update') && removeMarker && <div className="modal-edit-task-resource-remove" title="Unlink resource" onClick={(e) => attemptMarkerRemoval(e)}><MdClose/></div>}
            </div>
        </div>
    )
}
