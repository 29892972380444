import React from 'react'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import Api from "../../../common/APIUtils"
import '../../../styles/forms.css';
import '../../../styles/components.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { IoMdRadioButtonOff } from 'react-icons/io'
import { IoCheckmarkCircle } from 'react-icons/io5'

export default function NewTimeline({workspaceId}) {
    const api = new Api();
    let history = useHistory();   
    const [name, setName] = useState(() => { return "" })
    const [scope, setScope] = useState(() => { return 'team' })
    // eslint-disable-next-line
    const [type, setType] = useState(() => { return 'workstream' })
    const [page, setPage] = useState(() => { return 1 })
    const [description, setDescription] = useState(() => { return "" })
    const [linkedWorkstreamIds, setLinkedWorkstreamIds] = useState(() => { return [] })
    const [potentialParents, setPotentialParents] = useState(() => { return [] })
    const [potentialChildren, setPotentialChildren] = useState(() => { return [] })
    const [linkableWorkstreams, setLinkableWorkstreams] = useState(() => { return [] })
    const [parentsLoaded, setParentsLoaded] = useState(() => { return false })
    const publishTimeline = () => {
        if (!name || name === "") return
        let payload = {
            workspace_id: workspaceId,
            display_name: name,
            is_published: true,
            attributes: {
                description: description,
                swimlane_rule: type
            },
            proposed_child_ids: linkedWorkstreamIds
        }
        const path = `/components/plan/timeline`;
        api.createWorkspaceComponent(payload, path)
        .then( (res) => {
            if (res.status === 200) {
                history.push(`/workspaces/${workspaceId}/c/${res.data.response.component._id}`);
            }
        })
        .catch((err) => {
            console.log(err);
            alert(err);
        })
    }
    const handleCodeForNameType = (e) => {
        if (e.code.toLowerCase() === 'enter') {
            setPage(2)
        }
    }
    const handleKeyBoardPress = (code) => {
        if (code === "Escape") history.goBack()
    }
    const removeLinkedWorkstreamId = (componentId) => {
        let copyOfLinkedWorkstreamIds = Array.from(linkedWorkstreamIds)
        if (!copyOfLinkedWorkstreamIds.includes(componentId)) return
        const index = copyOfLinkedWorkstreamIds.indexOf(componentId)
        if (index < 0) return
        copyOfLinkedWorkstreamIds.splice(index, 1)
        setLinkedWorkstreamIds(copyOfLinkedWorkstreamIds)
    }
    const addLinkedWorkstreamId = (componentId) => {
        let copyOfLinkedWorkstreamIds = Array.from(linkedWorkstreamIds)
        if (copyOfLinkedWorkstreamIds.includes(componentId)) return
        copyOfLinkedWorkstreamIds.push(componentId)
        setLinkedWorkstreamIds(copyOfLinkedWorkstreamIds)
    }
    // eslint-disable-next-line
    const getAdditionalTypeClass = (typeName) => {
        let additionalClassIfSelected = "form-network-create-form-section-radio-item-selected"
        if (typeName === type ) return additionalClassIfSelected
        return ""
    }
    const advancePage = () => {
        setPage(page + 1)
    }
    const retreatPage = () => {
        setPage(page - 1)
    }
    const getPageTitle = () => {
        if (name === "") return "New Timeline"
        return name
    }
    const fetchPotentialParents = () => {
        api.getPotentialAlignmentsBasedOnType({type: 'timeline', workspaceId: workspaceId})
        .then((res) => {
            if (res.data) {
                setPotentialParents(res.data.response.potential_parents)
                setPotentialChildren(res.data.response.potential_children)
                setParentsLoaded(true)
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }

    const getDescriptionRowCount = () => {
        const MIN_ROW_COUNT = 4;
        // assume roughly 50 characters per row
        const breaks = description.split(/\r\n|\r|\n/).length + 1
        const rowsFromCharacters = Math.round(description.length/50) + 1
        return Math.max(breaks, rowsFromCharacters, MIN_ROW_COUNT);
    }
    // eslint-disable-next-line
    const getTypeText = () => {
        if (type === 'owner') return "Create swimlanes for each owner involved"
        if (type === 'tag') return "Create swimlanes for specific hashtags"
        if (type === 'workstream') return "Create swimlanes for each workstream"
        if (type === 'manual') return "You can always set up swimlanes later"
    }
    const hasProjects = () => {
        if (!parentsLoaded) return false
        if (potentialParents.length === 0) return false
        return true
    }
    useEffect(() => {
        fetchPotentialParents()
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (scope === 'project' && !hasProjects()) setScope('team')
    // eslint-disable-next-line
    }, [scope])
    useEffect(() => {
        if (!potentialChildren || potentialChildren.length === 0) return
        const copyOfChildren = Array.from(potentialChildren)
        const justWorkstreams = copyOfChildren.filter(a => a.layer === 'work')
        setLinkableWorkstreams(justWorkstreams)
    // eslint-disable-next-line
    }, [potentialChildren])
    return (
        <div className="network-page">
            <div className="network-page-header-row">
                <div className="network-page-header-text">
                    {name !== "" && <p>New Timeline:</p>}
                    {name === "" && <p style={{color: "transparent"}}>New Timeline</p>}
                    <h1>{getPageTitle()}</h1>
                    <p>Build an interactive calendar that connects workstreams, hashtags, and goals</p>
                </div>
            </div>

            <div className="form-network-create-form-container">

                {page === 1 &&
                <>
                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-section-label">What do you want to name this timeline?</div>
                    <div className="form-network-create-form-section-input">
                        <input maxLength={120} autoFocus={true} style={{fontSize: "14px"}} value={name} onKeyPress={(e) => handleCodeForNameType(e)} onKeyDown={(e) => handleKeyBoardPress(e.code)} onChange={(e) => setName(e.target.value)} type="text" placeholder="Example: Quarterly Content Campaign" />
                    </div>
                </div>
                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-section-label">Add a description:</div>
                    <div className="form-network-create-form-section-input">
                        <textarea rows={getDescriptionRowCount()} placeholder="Additional details for this timeline" value={description} onChange={(e) => setDescription(e.target.value)} style={{fontSize: "14px"}}></textarea>
                    </div>
                </div>
                </>
                }

                {/* {page === 2 &&
                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-section-label">Link Workstreams</div>
                    <div className="form-network-create-form-section-radio-items">
                        <div className={`form-network-create-form-section-radio-item ${getAdditionalTypeClass('manual')}`} onClick={() => setType('manual')}>
                            <span>None</span>
                        </div>
                        <div className={`form-network-create-form-section-radio-item ${getAdditionalTypeClass('workstream')}`} onClick={() => setType('workstream')}>
                            <span>Workstreams</span>
                        </div>
                        <div className={`form-network-create-form-section-radio-item ${getAdditionalTypeClass('owner')}`} onClick={() => setType('owner')}>
                            <span>Ownership</span>
                        </div>
                        <div className={`form-network-create-form-section-radio-item ${getAdditionalTypeClass('tag')}`} onClick={() => setType('tag')}>
                            <span>Hashtag</span>
                        </div>
                    </div>
                    <div className="form-network-create-form-radio-item-subtitle">{getTypeText()}</div>
                </div>
                } */}

                {page === 2 && type === "workstream" && linkableWorkstreams.length > 0 && parentsLoaded && 
                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-section-label">Select workstreams to connect</div>
                    <div className="form-network-create-form-section-input-multi-select">
                        {linkableWorkstreams.map((component) => (
                            <TimelineLinkedChild key={component._id} linkedIds={linkedWorkstreamIds} component={component} removeLink={removeLinkedWorkstreamId} addLink={addLinkedWorkstreamId}/>
                        ))}
                    </div>
                </div>
                }

                <div className="wizard-navigation-section wizard-navigation-section-2" style={{justifyContent: "center", gap: "24px", marginTop: "48px"}}>
                    {page > 1 && <div className="wizard-navigation-button wizard-navigation-button-secondary" onClick={retreatPage}>Back</div>}
                    {(page < 2 && name !== "") && <div className="wizard-navigation-button wizard-navigation-button-primary" onClick={advancePage}>Next</div>}
                    {(page === 1 && name === "") && <div className="wizard-navigation-button wizard-navigation-button-primary" style={{cursor: "not-allowed", backgroundColor: "grey"}}>Enter a name to continue</div>}
                    {page === 2 && linkedWorkstreamIds.length < 1 && <div className="wizard-navigation-button wizard-navigation-button-primary" style={{cursor: "not-allowed", backgroundColor: "grey"}}>Create Timeline</div>}
                    {page === 2 && linkedWorkstreamIds.length > 0 && <div className="wizard-navigation-button wizard-navigation-button-primary" onClick={publishTimeline}>Create Timeline</div>}
                </div>

            </div>

        </div>
    )
}

function TimelineLinkedChild({linkedIds, component, removeLink, addLink}) {
    const isSelected = () => {
        if (!linkedIds) return false
        if (linkedIds.includes(component._id)) return true
        return false
    }
    const handleToggle = () => {
        if (isSelected()) return removeLink(component._id)
        return addLink(component._id)
    }
    const getAdditionalClass = () => {
        if (isSelected()) return "form-network-create-form-section-input-multi-select-item-container-sel"
        return ""
    }
    const getSymbol = () => {
        if (isSelected()) return <IoCheckmarkCircle/>
        return <IoMdRadioButtonOff/>
    }
    return (
        <div className={`form-network-create-form-section-input-multi-select-item-container ${getAdditionalClass()}`} onClick={handleToggle}>
            {getSymbol()}
            <div className="form-network-create-form-section-input-multi-select-item-name">
                <p>{component.display_name}</p>
                <span>{component.name}</span>
            </div>
            
        </div>
    )
}