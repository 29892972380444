import React from 'react'
import { useHistory } from 'react-router-dom'
import { getAcronym } from '../../common/Helpers';

export default function ProfPicRoundedSquare({src, userId, userName, height}) {
    const history = useHistory();
    // Assumed we are using react-router-dom here
    const goToUserPage = () => {
        history.push(`/user/${userId}`)
    }
    const getAdditionalStyles = () => {
        let height = getHeight();
        if (src) return {...height}
        return {...height, ...{ boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px"} }
    }
    const getHeight = () => {
        if (height) {
            return {
                minHeight: height,
                minWidth: height
            }
        } else {
            return {}
        }
    }
    const hasSource = () => {
        if (src) return true
        return false
    }
    return (
        <div style={getAdditionalStyles()} className="u-profile-square-grey-header" onClick={goToUserPage}>
            {hasSource() ? (
                <img src={src} alt="profile" />
            ) : (
                <span style={{fontSize: height/2.5}}>
                    {getAcronym(userName)}
                </span>
            )}            
        </div>
    )
}
