import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Api from '../../common/APIUtils';
import { useHistory } from 'react-router-dom';
import ConfirmAction from '../modals/ConfirmAction';
import { hasPermission, getUserNameFromId } from '../../common/Helpers';
import ToggleSwitch from '../gadgets/ToggleSwitch'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { IoCheckmark, IoClose } from 'react-icons/io5'
import WorkspaceBannerUpload from './WorkspaceBannerUpload';

export default function WorkspaceSettingsDetails({ workspaceName, team, workspaceId, fetchWorkspace, fromNetwork, workspace, permissions, deleteNetworkWorkspace }) {
    const api = new Api();
    let history = useHistory();
    const aboutTextAreaRef = useRef(false);
    const onboardTextAreaRef = useRef(false);
    const [isSavingChanges, setIsSavingChanges] = useState(() => { return false })
    const [isUpdatingBanner, setIsUpdatingBanner] = useState(() => { return false })
    const [descriptionView, setDescriptionView] = useState(() => { return "raw" }) // acceptable is "raw" and "preview"
    const [onboardView, setOnboardView] = useState(() => { return "raw" }) // acceptable is "raw" and "preview"
    const [proposedWorkspaceName, setProposedWorkspaceName] = useState(() => { return "" })
    const [workspaceDescription, setWorkspaceDescription] = useState(() => { return "" })
    const [aboutTextAreaHeight, setAboutTextAreaHeight] = useState(() => { return false })
    const [onboardTextAreaHeight, setOnboardTextAreaHeight] = useState(() => { return false })
    const [shownOnboardingEnabled, setShownOnboardingEnabled] = useState(() => { return false })
    const [shownOnboardingMessage, setShownOnboardingMessage] = useState(() => { return "" })
    const [isShowingDeleteWorkspacePrompt, setIsShowingDeleteWorkspacePrompt] = useState(() => { return false })
    const [isShowingResetOnboarding, setIsShowingResetOnboarding] = useState(() => { return false })
    const [isShowingOnboardedList, setIsShowingOnboardedList] = useState(() => { return false })
    const titleLastUpdatedTimestamp = useRef(Date.now());
    const goHome = () => {
        history.push(`/workspaces/${workspaceId}`)
    }
    const updateWorkspaceName = () => {
        if (proposedWorkspaceName === "" || !proposedWorkspaceName) return
        if (proposedWorkspaceName === workspace.name) return
        const payload = {
            name: proposedWorkspaceName,
            workspaceId: workspaceId
        }
        api.updateWorkspace(payload)
        .then((res) => {
            fetchWorkspace();
        })
        .catch((e) => {
            console.log(e)
        })         
    }
    const updateWorkspaceItems = () => {
        if (isSavingChanges) return
        setIsSavingChanges(true)
        const payload = {
            description: workspaceDescription,
            onboarding_enabled: shownOnboardingEnabled,
            onboarding_message: shownOnboardingMessage,
            workspaceId: workspaceId
        }
        api.updateWorkspace(payload)
        .then((res) => {
            fetchWorkspace();
            history.push(`/workspaces/${workspaceId}`)
        })
        .catch((e) => {
            console.log(e)
        })         
    }
    const showConfirmMenu = () => {
        setIsShowingDeleteWorkspacePrompt(true);
    }
    const hideConfirmMenu = () => {
        setIsShowingDeleteWorkspacePrompt(false);
        setIsShowingResetOnboarding(false)
    }
    const resetOnboarding = () => {
        setIsShowingResetOnboarding(false)
        if (!hasPermission(permissions, 'workspace_name_update')) return
        const payload = {
            reset_onboarding: true,
            workspaceId: workspaceId
        }
        api.updateWorkspace(payload)
        .then((res) => {
            fetchWorkspace();
        })
        .catch((e) => {
            console.log(e)
        })
    }
    const taskTitleFieldHandler = (event) => {
        if (event.code === 'Enter') {
            event.preventDefault()
            event.target.blur()
        }
    }
    const getDisplayNamePlaceholder = () => {
        if (!workspace) return "Enter a workspace name"
        if (!workspace.name) return "Enter a workspace name"
        return workspace.name
    }
    const deleteWorkspace = () => {
        const payload = {
            workspaceId: workspaceId
        }
        api.deleteWorkspace(payload)
        .then((res) => {
            if (fromNetwork && deleteNetworkWorkspace) {
                deleteNetworkWorkspace(workspaceId)
            } else {
                history.push(`/home`);
            }
            
        })
        .catch((e) => {
            console.log(e)
        })        
    }
    const getAdditionalAboutTextareaStyles = () => {
        let additionalStyles = {}
        if (aboutTextAreaHeight) additionalStyles['height'] = aboutTextAreaHeight
        return additionalStyles
    }
    const updateAboutTextAreaHeight = () => {
        if (!aboutTextAreaRef.current) return
        aboutTextAreaRef.current.style.height = 'inherit'
        aboutTextAreaRef.current.style.height = `${aboutTextAreaRef.current.scrollHeight}px`
        setAboutTextAreaHeight(`${aboutTextAreaRef.current.scrollHeight}px`)
    }
    const userIdIsOnboarded = (userId) => {
        if (!workspace) return false
        if (!workspace.onboarded_user_ids) return false
        if (workspace.onboarded_user_ids.includes(userId)) return true
        return false
    }
    const getAdditionalOnboardTextareaStyles = () => {
        let additionalStyles = {}
        if (onboardTextAreaHeight) additionalStyles['height'] = onboardTextAreaHeight
        return additionalStyles
    }
    const updateOnboardTextAreaHeight = () => {
        if (!onboardTextAreaRef.current) return
        onboardTextAreaRef.current.style.height = 'inherit'
        onboardTextAreaRef.current.style.height = `${onboardTextAreaRef.current.scrollHeight}px`
        setOnboardTextAreaHeight(`${onboardTextAreaRef.current.scrollHeight}px`)
    }
    const getOnboardingMessage = () => {
        if (shownOnboardingMessage === "" || !shownOnboardingMessage) return "Welcome to the team!"
        return shownOnboardingMessage
    }
    const onboardingEnabled = () => {
        return shownOnboardingEnabled;
    }
    const getWorkspaceDescription = () => {
        if (workspaceDescription === "" || !workspaceDescription) return "No description provided"
        return workspaceDescription
    }
    useEffect(() => {
        if (!workspaceId) return
        if (!workspace) return
        if (workspace.name) setProposedWorkspaceName(workspace.name)
        if (workspace.description) setWorkspaceDescription(workspace.description)
        if (workspace.onboarding_enabled) setShownOnboardingEnabled(workspace.onboarding_enabled)

        if (workspace.onboarding_message) setShownOnboardingMessage(workspace.onboarding_message)
    // eslint-disable-next-line
    },[workspace])
    useEffect(() => {
        titleLastUpdatedTimestamp.current = Date.now()
        const timer = setTimeout(() => {
            if (Date.now() - titleLastUpdatedTimestamp.current < 900) return
            updateWorkspaceName()
        }, 1000)
        return () => clearTimeout(timer)
    // eslint-disable-next-line
    }, [proposedWorkspaceName])
    useEffect(() => {
        updateAboutTextAreaHeight()
    // eslint-disable-next-line
    }, [workspaceDescription])
    useEffect(() => {
        updateOnboardTextAreaHeight()
    // eslint-disable-next-line
    }, [shownOnboardingMessage])
    return (
        <div className="workspace-team-full-container">
            {isUpdatingBanner && <WorkspaceBannerUpload hideModal={() => setIsUpdatingBanner(false)} fetchWorkspace={fetchWorkspace} workspace={workspace} /> }

            {!fromNetwork &&
            <div className="workspace-library-section-header" style={{paddingBottom: "20px"}}>
                <h1>Overview</h1>
                <p>Update name, banner, and basic information</p>
            </div>
            }

            <div className="workspace-settings-sections">
                {/* Name */}
                <div className="workspace-settings-section">
                    <h2>Workspace Name</h2>
                    {hasPermission(permissions, 'workspace_name_update') && <input type="text" onKeyUp={(e) => taskTitleFieldHandler(e)} className="workspace-settings-section-editable-text" placeholder={getDisplayNamePlaceholder()} value={proposedWorkspaceName} onChange={(e) => setProposedWorkspaceName(e.target.value)} />}
                    {!hasPermission(permissions, 'workspace_name_update') && <div className="workspace-settings-section-editable-text" style={{paddingLeft: "0px", borderColor: "transparent"}}>{proposedWorkspaceName}</div>}
                </div>

                {/* Banner */}
                {hasPermission(permissions, 'workspace_name_update') &&
                <div className="workspace-settings-section">
                    <h2>Banner Image</h2>
                    <h3>Featured at the top of the feed (2400px x 280px recommended)</h3>
                    {workspace.banner_image_url &&
                    <div className="workspace-settings-banner-image-preview">
                        <img src={workspace.banner_image_url} alt="Workspace banner" />
                    </div>
                    }
                    <div style={{display: "flex", alignItems: "stretch", justifyContent: "flex-start", marginTop: "20px"}}>
                        <div className="workspace-settings-heading-row-button" onClick={() => setIsUpdatingBanner(true)}>Update Banner</div>
                    </div>
                    

                </div>
                }

                {/* About */}
                <div className="workspace-settings-section">
                    <div className="workspace-settings-heading-row-with-toggle">
                        <h2>About</h2>
                        {hasPermission(permissions, 'workspace_name_update') && descriptionView === "raw" && <div className="workspace-settings-heading-row-button" onClick={() => setDescriptionView("preview")}>Show Preview</div>}
                        {hasPermission(permissions, 'workspace_name_update') && descriptionView === "preview" && <div className="workspace-settings-heading-row-button" onClick={() => setDescriptionView("raw")}>Hide Preview</div>}
                    </div>
                    {hasPermission(permissions, 'workspace_name_update') && <h3>Markdown supported. <a target="_blank" rel="noopener noreferrer"  href="https://commonmark.org/help/">What is markdown?</a></h3>}
                    {hasPermission(permissions, 'workspace_name_update') && descriptionView === "raw" && <textarea style={getAdditionalAboutTextareaStyles()} placeholder="Describe this workspace" value={workspaceDescription} onChange={(e) => setWorkspaceDescription(e.target.value)} ref={aboutTextAreaRef}></textarea>}
                    {hasPermission(permissions, 'workspace_name_update') && descriptionView === "preview" && <ReactMarkdown className="markdown-wrapper-description-settings">{getWorkspaceDescription()}</ReactMarkdown>}
                    {!hasPermission(permissions, 'workspace_name_update') && <ReactMarkdown className="markdown-wrapper-description-settings">{getWorkspaceDescription()}</ReactMarkdown>}
                </div>

                {/* Enable Onboarding */}
                {hasPermission(permissions, 'workspace_name_update') &&
                <div className="workspace-settings-section">
                    <div className="workspace-settings-heading-row-with-toggle">
                        <h2>Enable User Onboarding</h2>
                        <ToggleSwitch isChecked={shownOnboardingEnabled} setIsChecked={setShownOnboardingEnabled} />
                    </div>
                    <h3>New workspace users will see this prompt the first time they log into the workspace</h3>
                </div>
                }

                {/* Onboarding Message */}
                {(onboardingEnabled() && hasPermission(permissions, 'workspace_name_update')) &&
                <>
                    <div className="workspace-settings-section">
                        <div className="workspace-settings-heading-row-with-toggle">
                            <h2>Onboarding Message</h2>
                            {hasPermission(permissions, 'workspace_name_update') && onboardView === "raw" && <div className="workspace-settings-heading-row-button" onClick={() => setOnboardView("preview")}>Show Preview</div>}
                            {hasPermission(permissions, 'workspace_name_update') && onboardView === "preview" && <div className="workspace-settings-heading-row-button" onClick={() => setOnboardView("raw")}>Hide Preview</div>}
                        </div>
                        <h3>New workspace users will see this prompt the first time they log into the workspace.</h3>
                        {hasPermission(permissions, 'workspace_name_update') && onboardView === "raw" && <textarea style={getAdditionalOnboardTextareaStyles()} ref={onboardTextAreaRef} placeholder="What do you want new teammates to know about this workspace?" value={shownOnboardingMessage} onChange={(e) => setShownOnboardingMessage(e.target.value)}></textarea>}
                        {hasPermission(permissions, 'workspace_name_update') && onboardView === "preview" && <ReactMarkdown className="markdown-wrapper-description-settings">{getOnboardingMessage()}</ReactMarkdown>}
                        {!hasPermission(permissions, 'workspace_name_update') && <ReactMarkdown className="markdown-wrapper-description-settings">{getOnboardingMessage()}</ReactMarkdown>}
                    </div>

                    {team &&
                    <div className="workspace-settings-section">
                        <h2>Onboarding Status</h2>
                        <h3>See which teammates have viewed the latest onboarding message</h3>
                        <div className="workspace-settings-buttons">
                            {!isShowingOnboardedList && <div className="workspace-settings-button" onClick={() => setIsShowingOnboardedList(true)}>View onboarding status</div>}
                            {isShowingOnboardedList && <div className="workspace-settings-button" onClick={() => setIsShowingOnboardedList(false)}>Hide onboarding status</div>}
                        </div>
                        {isShowingOnboardedList && 
                            <div className="workspace-settings-section-onboarded-list">
                                {team.map((teammate) => (
                                    <div key={teammate._id} className="workspace-settings-section-onboarded-item">
                                        {userIdIsOnboarded(teammate._id) && <IoCheckmark style={{color: "green"}} /> }
                                        {!userIdIsOnboarded(teammate._id) && <IoClose  style={{color: "red"}} /> }
                                        {getUserNameFromId(team, teammate._id)}
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                    }

                    <div className="workspace-settings-section">
                        {isShowingResetOnboarding && <ConfirmAction mainText="Reset onboarding in the workspace?  The onboarding message will be shown again to everyone next time they come to the workspace" onconfirm={resetOnboarding} hideMe={hideConfirmMenu} />}
                        <h2>Re-broadcast Onboarding Message</h2>
                        <h3>Force members to see the latest onboarding message again</h3>
                        <div className="workspace-settings-buttons">
                            <div className="workspace-settings-button" onClick={() => setIsShowingResetOnboarding(true)}>Reset onboarding</div>
                        </div>
                        
                    </div>
                </>
                }

                {/* Delete workspace */}
                {hasPermission(permissions, 'workspace_delete') &&
                <div className="workspace-settings-section">
                    {isShowingDeleteWorkspacePrompt && <ConfirmAction mainText="Are you sure you want to delete this workspace?" onconfirm={deleteWorkspace} hideMe={hideConfirmMenu} />}
                    <div className="workspace-settings-button-remove" onClick={(e) => showConfirmMenu()}>Permanently Delete Workspace</div>
                </div>
                }

                {/* Save Changes */}
                {hasPermission(permissions, 'workspace_name_update') && 
                <div className="workspace-settings-section">
                    <div className="workspace-settings-section-save-buttons">
                        <div className="workspace-settings-section-save-button" onClick={updateWorkspaceItems}>
                            {!isSavingChanges ? "Save Changes" : "Saving..."}</div>
                        <div className="workspace-settings-section-save-button" onClick={goHome}>Cancel</div>
                    </div>
                </div>
                }
            </div>
        </div>
    )
}
