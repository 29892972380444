import React from 'react';
import '../styles/profiles.css';
import { useState, useEffect } from 'react';
import Api from "../common/APIUtils"
import { useParams, useHistory } from 'react-router-dom';
import ProfPicUpload from './profiles/ProfPicUpload';
import ProfPicCircle from './profiles/ProfPicCircle';

import { MdModeEditOutline } from "react-icons/md";
import LoadingSymbolFacebook from './gadgets/LoadingSymbolFacebook';

export default function UserProfile({authenticated}) {
    const api = new Api();
    const history = useHistory()
    const { userId, userHandle } = useParams();
    const [userInfo, setUserInfo] = useState(() => { return {} })
    const [isLoaded, setIsLoaded] = useState(() => { return false })
    const [isMyPage, setIsMyPage] = useState(() => { return false })
    const [showPictureEditModal, setShowPictureEditModal] = useState(() => { return false })
    const goToUserSettings = () => {
        history.push('/settings')
    }
    const fetchUser = () => {
        if (userId) {
            api.getUserInfo(userId)
            .then((res) => {
                setUserInfo(res.data.response.user);
                setIsLoaded(true)
            })
            .catch((err) => {
                console.log(err);
            }) 
        } else if (userHandle) {
            api.getUserInfoHandle(userHandle)
            .then((res) => {
                setUserInfo(res.data.response.user);
                setIsLoaded(true)
            })
            .catch((err) => {
                console.log(err);
            }) 
        }
 
    }
    const getFormattedUrl = (url) => {
        try {
            url = url.replace(/^https?:\/\//i, '');
            const index = url.indexOf('/');
            if (index !== -1) {
                url = url.substr(0, index);
            }
            return url
        } catch (error) {
            return ""
        }
    }
    const handlePictureClick = () => {
        if (isMyPage) return setShowPictureEditModal(true)
        if (userInfo.profile_picture_url) return window.open(userInfo.profile_picture_url)
    }
    useEffect(() => {
        fetchUser()
        // eslint-disable-next-line
    }, [userId])
    useEffect(() => {
        if (localStorage.getItem("gId") === userId) {
            setIsMyPage(true)
            return
        }
        setIsMyPage(false)
    // eslint-disable-next-line 
    }, [userId]) 
    useEffect(() => {
        if (!userInfo) return
        if (!userInfo.firstName) return
        document.title = `${userInfo.firstName} ${userInfo.lastName}`
    },[userInfo])
    return (
        <div className="profile-page">
            {/* Edit Modals */}
            {isMyPage && showPictureEditModal && <ProfPicUpload userInfo={userInfo} showModal={setShowPictureEditModal} fetchUser={fetchUser}/>}

            {/* Main Profile */}
            {isLoaded && userInfo &&
            <div className="profile-page-main">
                <div className="profile-page-header">
                    <div onClick={handlePictureClick} className="profile-page-header-picture">
                        <ProfPicCircle height={140} src={userInfo.profile_picture_url} userId={userId} userName={`${userInfo.firstName} ${userInfo.lastName}`} disableClick={true}  />
                    </div>
                    <div className="profile-page-header-identification">
                        <div className="profile-page-header-primary">
                            <h2>{userInfo.firstName} {userInfo.lastName}</h2>
                            <div className="profile-page-personal-tagline">{userInfo.status}</div>
                        </div>
                        <div className="profile-page-header-headline">{userInfo.headline}</div>
                        {userInfo.website && <div className="profile-identity-website"><a target="_blank" rel="noopener noreferrer" href={userInfo.website}>{getFormattedUrl(userInfo.website)}</a> </div>}
                        <div className="profile-page-header-meta">@{userInfo.handle}</div>
                    </div>
                </div>

                {/* About */}
                <div className="profile-page-section">
                    <div className="profile-page-section-heading">
                        <h3>About</h3>
                        {isMyPage && <MdModeEditOutline onClick={goToUserSettings} title="Edit profile"/>}
                    </div>
                    <p>{userInfo.biography}</p>
                </div>
            </div>
            }

            {/* Profile Unavailable */}
            {isLoaded && !userInfo &&
            <div>
                <div className="util-row util-align-center util-justify-center" style={{marginTop: "300px", flexDirection: "column", gap: "24px"}}>
                    {userHandle && <div>You're not allowed to see @{userHandle}'s profile</div>}
                    {!userHandle && <div>You're not allowed to see this profile</div>}
                    <span style={{padding: "12px", cursor: "pointer", textDecoration: "underline"}} onClick={() => history.goBack()}>Go Back</span>
                </div>
            </div>
            }

            {/* Loading */}
            {!isLoaded &&
            <div>
                <div className="util-row util-align-center util-justify-center" style={{marginTop: "120px", flexDirection: "column"}}>
                    <LoadingSymbolFacebook />
                    Loading...
                </div>
            </div>
            }
        </div>
    )
}