import React from 'react'
import { useState, useReducer, useRef, useEffect } from 'react'
import { format } from 'date-fns';
import BlurProofInput from '../gadgets/BlurProofInput';
import LexicalTextEditor from '../gadgets/LexicalTextEditor';
import ModalSimpleHeader from '../modals/simple/ModalSimpleHeader';

export default function CalendarCreateModal({setShowCreateModal, suggestedDate, team, requiredWorkspace, autoAssignToSelf, workspaceWorkstreamPermissionMap, createTask}) {
    const taskNameInputRef = useRef(null)
    const dateInputRef = useRef(null)
    const ownerRef = useRef(null)
    const [proposedTask, proposedTaskDispatch] = useReducer((state, action) => {
        if (action.type === "reset") {
            return { description_editor: false, description_html: false}
        }
        if (action.type === "save") {
            const safeHtml = action.value.description_html
            const editorState = action.value.description_editor
            return { ...state, description_editor: editorState, description_html: safeHtml }
        }
        return state
    }, { description_editor: false, description_html: false })
    const [name, setName] = useState(() => { return "" })
    const [proposedWorkspaceId, setProposedWorkspaceId] = useState(() => { return false }) // consider auto-picking this if there is only one
    const [proposedParentId, setProposedParentId] = useState(() => { return false })
    const [ownerId, setOwnerId] = useState(() => { return "" })
    const [dueDate, setDueDate] = useState(() => { return format(suggestedDate, 'yyyy-MM-dd')})
    const getCreateTaskButtonStyle = () => {
        if (name && proposedWorkspaceId && proposedParentId) return {}
        return {
            backgroundColor: "grey",
            border: "1px solid grey"
        }
    }
    const getAssociatedWorkstreams = () => {
        try {
            if (!proposedWorkspaceId || proposedWorkspaceId === "") return []
            let associatedWorkstreams = []
            for (let i = 0; i < workspaceWorkstreamPermissionMap.length; i++) {
                const workspaceData = workspaceWorkstreamPermissionMap[i];
                if (workspaceData.workspace._id !== proposedWorkspaceId) continue
                associatedWorkstreams = workspaceData.workstreams
                break
            }
            if (associatedWorkstreams.length > 0) return associatedWorkstreams
            return []
        } catch (error) {
            return []
        }
    }
    const needsToSelectWorkspace = () => {
        try {
            if (requiredWorkspace) return false
            if (!workspaceWorkstreamPermissionMap) return false
            if (workspaceWorkstreamPermissionMap.length > 1) return true
            return false
        } catch (error) {
            return false
        }
    }
    const validateNewTaskSubmission = () => {
        try {
            if (!name || !proposedWorkspaceId || !proposedParentId) return
            let attributes = {
                date_due: dueDate,
                active: true,
                progress: 0,
                parent: proposedParentId,
                details: {
                    description_editor: proposedTask.description_editor,
                    description_html: proposedTask.description_html
                },
            }
            // retrieve the parent workstream name
            const workstreams = getAssociatedWorkstreams()
            const parent = workstreams.find(w => w._id === proposedParentId)
            if (parent.name === "board") attributes['state'] = parent.attributes.column_id_new
            let payload = {
                display_name: name,
                status: "active",
                workspace_id: proposedWorkspaceId,
                position: 0,
                attributes: attributes
            }
            if (team && ownerId) payload['owner_id'] = ownerId
            if (autoAssignToSelf) payload['owner_id'] = localStorage.getItem("gId")
            createTask(payload)
            setShowCreateModal(false)
        } catch (error) {
            setShowCreateModal(false)
        }
    }
    useEffect(() => {
        if (!workspaceWorkstreamPermissionMap) return
        if (workspaceWorkstreamPermissionMap.length === 1) setProposedWorkspaceId(workspaceWorkstreamPermissionMap[0].workspace._id)
    // eslint-disable-next-line
    }, [])
    return (
        <div className="calendar-item-modal-container" style={{paddingTop: "24px", paddingBottom: "32px", marginBottom: "20px"}}>
            <ModalSimpleHeader title="Quick Task" hideModal={() => setShowCreateModal(false)} />

            <div className="modal-task-create-section">
                <div className="modal-task-create-input-name">Name</div>
                <BlurProofInput inputRef={taskNameInputRef}>
                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="What do you want to do?" autoFocus maxLength={80} ref={taskNameInputRef}/>
                </BlurProofInput>
            </div>

            <div className="modal-task-create-section">
                <div className="modal-task-create-input-name">Description</div>
                <div style={{position: "relative"}}>
                    <LexicalTextEditor isUpgrade={false} getInitialState={() => { return false}} mainDescriptionDispatch={proposedTaskDispatch} legacyDescription={false}/> 
                </div>
            </div>

            <div className="modal-task-create-section-split" style={{marginTop: "20px", marginBottom: "32px"}}>
                <div className="modal-task-create-section">
                    <div className="modal-task-create-input-name">Due Date</div>
                    <BlurProofInput inputRef={dateInputRef}><input type="date" value={dueDate} onChange={(e) => setDueDate(e.target.value)} ref={dateInputRef} /></BlurProofInput>
                </div>
                {team &&
                <div className="modal-task-create-section">
                    <div className="modal-task-create-input-name">Owner</div>
                    <BlurProofInput inputRef={ownerRef}>
                        <select value={ownerId} onChange={(e) => setOwnerId(e.target.value)} name="owner" ref={ownerRef}>
                            <option key="none" value="">None</option>
                            {team.map((teammate) => (
                                <option key={teammate._id} value={teammate._id}>{`${teammate.firstName} ${teammate.lastName}`}</option>
                            ))}
                        </select>
                    </BlurProofInput>
                </div>
                }
            </div>

            {workspaceWorkstreamPermissionMap &&
            <div className="modal-task-create-section-split" style={{marginTop: "20px", marginBottom: "32px"}}>
                {needsToSelectWorkspace() &&
                <div className="modal-task-create-section">
                    <div className="modal-task-create-input-name">Workspace</div>
                        <select value={proposedWorkspaceId} onChange={(e) => setProposedWorkspaceId(e.target.value)} name="workspace">
                            <option key="none" value="">Select workspace</option>
                            {workspaceWorkstreamPermissionMap.map((workspaceData) => (
                                <option key={workspaceData.workspace._id} value={workspaceData.workspace._id}>{workspaceData.workspace.name}</option>
                            ))}
                        </select>
                </div>
                }
                
                {proposedWorkspaceId &&
                <div className="modal-task-create-section">
                    <div className="modal-task-create-input-name">Workstream</div>
                        <select value={proposedParentId} onChange={(e) => setProposedParentId(e.target.value)} name="parent">
                            <option key="none" value="">Select workstream</option>
                            {getAssociatedWorkstreams().length > 0 &&
                            <>
                            {getAssociatedWorkstreams().map((possibleParentComponent) => (
                                <option key={possibleParentComponent._id} value={possibleParentComponent._id}>{possibleParentComponent.display_name}</option>
                            ))}
                            </>
                            }
                        </select>
                </div>
                }
            </div>
            }

            <div className="modal-task-create-submission-section" style={{alignItems: "center", gap: "20px"}}>
                <div className="modal-task-create-button-create" style={getCreateTaskButtonStyle()} onClick={() => validateNewTaskSubmission()}>Create Task</div>
            </div>  

        </div>
    )
}
