import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom';

import { FaAngleLeft } from "react-icons/fa6";
import { AiOutlineLike } from 'react-icons/ai'
import { IoSendSharp, IoTrash } from 'react-icons/io5'

import { parseISO, formatDistanceToNow, format } from 'date-fns'

import LoadingSymbolGrid from '../gadgets/LoadingSymbolGrid';
import PostImagePreview from './PostImagePreview';
import PostMediaFullView from './PostMediaFullView';
import ProfPicCircle from '../profiles/ProfPicCircle';
import Api from '../../common/APIUtils';
import PostOptions from './PostOptions';
import ConfirmAction from '../modals/ConfirmAction';
import ModalEditPost from '../modals/ModalEditPost';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

import { wrapHashtagsInSpan, getUserProfileSrcFromId } from '../../common/Helpers';
import { getPostMultimedia } from '../../common/Api';

export default function PostPage({ workspace, permissions, fetchWorkspace, components, team, subView, sidebarCollapsed }) {
    const history = useHistory()
    const api = new Api()
    const { postId } = useParams();
    const [post, setPost] = useState(() => { return false })
    const [postImages, setPostImages] = useState(() => { return [] })
    const [youtubeVideoId, setYoutubeVideoId] = useState(() => { return null });
    const [postComments, setPostComments] = useState(() => { return [] })
    const [hasMultimedia, setHasMultimedia] = useState(() => { return false })
    const [multiMediaFocusIndex, setMultimediaFocusIndex] = useState(() => { return 0 })
    const [multiMediaFocusUrl, setMultimediaFocusUrl] = useState(() => { return false })
    const [isShowingFullView, setIsShowingFullView] = useState(() => { return false })
    const [isShowingEditPost, setIsShowingEditPost] = useState(() => { return false })
    const [isShowingDeletePost, setIsShowingDeletePost] = useState(() => { return false })
    const [isRequestingCommentDelete, setIsRequestingCommentDelete] = useState(() => { return false })
    const [interestingCommentId, setInterestingCommentId] = useState(() => { return false })
    const [imagesLoaded, setImagesLoaded] = useState(() => { return false })
    const [isLoading, setIsLoading] = useState(() => { return true })
    const [hasError, setHasError] = useState(() => { return false })
    const [likeReactions, setLikeReactions] = useState(() => { return 0 })
    const isLikedByUser = useRef(false)
    const postTitle = useRef(null)
    const returnToFeed = () => {
        // returns to workspace feed and highlights the post
        if (!workspace) return
        if (!workspace._id) return
        history.push(`/workspaces/${workspace._id}?pid=${postId}`)
    }
    const fetchPost = () => {
        try {
           api.getWorkspacePost(postId)
           .then((res) => {
            if (res.data.response) {
                if (res.data.response._id) {
                    setPost(res.data.response)
                    setIsLoading(false)
                    setHasError(false)
                }
            }
           })
           .catch((err) => {
                setHasError(true)
                setPost(false)
           })
        } catch (error) {
            setPost(false)
            setHasError(true)
        }
    }
    const isLexical = (content) => {
        try {
            if (content.description_editor && content.description_html) return true
            return false
        } catch (error) {
            return false
        }
    }
    const updateReactions = () => {
        try {
            if (!post) return
            if (!post.reactions) return 
            if (post.reactions.length === 0) return
            const filteredLikeReactions = post.reactions.filter(reaction => reaction.reaction_name_legacy === "like")
            setLikeReactions(filteredLikeReactions.length)
            const userHasLiked = filteredLikeReactions.find(r => r.user_id === localStorage.getItem("gId"))
            if (userHasLiked) isLikedByUser.current = true
            if (!userHasLiked) isLikedByUser.current = false
        } catch (error) {
            return
        }
    }
    const likePost = () => {
        if (isLikedByUser.current) return unlikePost()
        // optimistically update local
        setLikeReactions(likeReactions + 1)
        isLikedByUser.current = true
        api.createPostReaction({post_id: post._id, legacy_reaction_string: "like"})
        .then((res) => {
            fetchPost()
        })
        .catch((err) => fetchPost())
    }
    const unlikePost = () => {
        setLikeReactions(likeReactions - 1)
        isLikedByUser.current = false
        api.removePostReaction({post_id: post._id, legacy_reaction_string: "like"})
        .then((res) => {
            fetchPost()
        })
        .catch((err) => fetchPost())
    }
    const additionalReactionStyle = () => {
        if (!isLikedByUser.current) return {backgroundColor: "#ecedf3a2"}
        return {}
    }
    const leaveComment = (commentText) => {
        try {
            if (commentText.trim() === "") return
            api.createPostComment({ parent_id: post._id, text_content: commentText })
            .then((res) => {
                fetchPost()
            })
            .catch((err) => { return })
        } catch (error) {
            return
        }
    }
    const extractYouTubeVideoId = useCallback((html) => {
        try {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = html;
            const links = tempDiv.querySelectorAll('a');
            for (let link of links) {
                const href = link.getAttribute('href');
                if (href) {
                    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=|embed\/|v\/|shorts\/|live\/\?v=)?([a-zA-Z0-9_-]{11})/;
                    const match = href.match(regex);
                    if (match && match[1]) {
                        return match[1];
                    }
                }
            }
            const text = tempDiv.textContent || tempDiv.innerText;
            const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=|embed\/|v\/|shorts\/|live\/\?v=)?([a-zA-Z0-9_-]{11})/;
            const match = text.match(regex);
            return match && match[1] ? match[1] : null;
        } catch (error) {
            return null
        }
    }, []);
    const renderTextContent = () => {
        try {
            if (!post) return false
            if (post.text_content) return post.text_content
            if (!post.rich_text_content) return false
            let postContent = JSON.parse(post.rich_text_content)
            if (isLexical(postContent)) {
                return (
                    <div className="project-post-content gadget-lexical-displayed-text-container">
                        <div dangerouslySetInnerHTML={{ __html: wrapHashtagsInSpan(postContent.description_html, post.workspace_id) }}></div>
                    </div>
                )
            }
            return false  
        } catch (error) {
            return false
        }
    }
    const updateYoutubeIdentification = () => {
        try {
            if (hasMultimedia) return
            if (!post.rich_text_content) return
            if (postImages.length > 0) return
            const content = JSON.parse(post.rich_text_content);
            const videoId = extractYouTubeVideoId(content.description_html || '');
            setYoutubeVideoId(videoId);
        } catch (error) {
            return setYoutubeVideoId(null)
        }
    }
    const updateComments = () => {
        try {
            if (post.comments) {
                if (Array.isArray(post.comments)) setPostComments(post.comments)
            }
        } catch (error) {
            return
        }
    }
    const thisCommentIsANewPersonOrDay = (commentIndex) => {
        try {
            if (commentIndex === 0) return true
            // Check Creators
            const commentAuthorId = postComments[commentIndex]['creator']            
            const priorCommentAuthorId = postComments[commentIndex - 1]['creator']
            if (commentAuthorId === priorCommentAuthorId) {
                // Check dates
                const commentDayNumber = format(parseISO(postComments[commentIndex]['createdAt']), 'd')
                const priorCommentDayNumber = format(parseISO(postComments[commentIndex - 1]['createdAt']), 'd')
                if (commentDayNumber === priorCommentDayNumber) return false
                return true
            }
            return true
        } catch (error) {
            return true
        }
    }
    const isValidHttpUrl = (s) => {
        let url;
        try {
            url = new URL(s);
        } catch (e) { return false; }
        return /https?/.test(url.protocol);
    }
    const getCommentWithUrls = (sourceString) => {
        if (!sourceString) return ""
        let description = []
        // First split line breaks
        const lines = sourceString.split('\n')
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            const words = line.split(" ")
            for (let d = 0; d < words.length; d++) {
                const word = words[d];
                if (isValidHttpUrl(word)) {
                    description.push(<a rel="noreferrer" target="_blank" href={word}>{word}</a>)
                } else {
                    description.push(<>{word}</>)
                }
                description.push(<>{" "}</>)
            }
            description.push(<>{"\n"}</>)
        }
        return description
    }
    const fetchMultimedia = () => {
        try {
            getPostMultimedia(post.workspace_id, post._id)
            .then((res) => {
                if (res.data) {
                    if (res.data.signed_urls) {
                        setPostImages(res.data.signed_urls)
                        setImagesLoaded(true)
                        setHasMultimedia(true)
                    }
                }
            })
        } catch (error) {
            return
        }
    }
    const updateMultimediaSettings = () => {
        try {
            if (!post) return setHasMultimedia(false)
            if (!post.image_urls) return setHasMultimedia(false)
            if (post.image_urls.length === 0) return setHasMultimedia(false)
            setHasMultimedia(true)
        } catch (error) {
            return
        }
    }
    const updateWorkspacePost = (payload) => {
        if (!payload.post_id) return
        if (payload) {
            api.updatePost(payload)
            .then((res) => {
                fetchPost();
            })
            .catch((err) => {
                console.log(err);
            });   
        }
    }
    const deletePost = () => {
        api.deletePost({
            post_id: post._id
        })
        .then((res) => {
            returnToFeed()
        })
        .catch((err) => {
            console.log(err)
        })
    }
    const triggerDeletePostAction = () => {
        setIsShowingDeletePost(true)
    }
    const editPost = () => {
        setIsShowingEditPost(true)
    }
    const getPostOptionsForPostOwner = () => {
        let postOptions = [{
            id: 'delete-post',
            action: triggerDeletePostAction,
            text: 'Delete post'
        }]
        if (post.text_content && !post.rich_text_content) return postOptions
        return [
            {
                id: 'edit-post',
                action: editPost,
                text: 'Edit post'
            },
            {
                id: 'delete-post',
                action: triggerDeletePostAction,
                text: 'Delete post'
            }
        ]
    }
    const cancelCommentDelete = () => {
        setInterestingCommentId(false)
        setIsRequestingCommentDelete(false)
    }
    const identifyCommentToDelete = (comment) => {
        if (localStorage.getItem("gId") !== comment.creator) return
        // Validate this comment is deletable by the user
        setInterestingCommentId(comment._id)
        setIsRequestingCommentDelete(true)
    }
    const deletePostComment = () => {
        setIsRequestingCommentDelete(false)
        api.deletePost({
            post_id: interestingCommentId
        })
        .then((res) => {
            fetchPost()
        })
        .catch((err) => { return })
    }
    const selectImageToView = (e, imageUrl, imageIndex) => {
        try {
            e.stopPropagation()
            let proposedIndex = 0
            if (imageIndex) proposedIndex = imageIndex
            setMultimediaFocusIndex(proposedIndex)
            setMultimediaFocusUrl(imageUrl)
            setIsShowingFullView(true)
        } catch (error) {
            return
        }
    }
    useEffect(() => {
        if (!postId) return
        fetchPost()
    // eslint-disable-next-line
    }, [postId])
    useEffect(() => {
        updateReactions()
        updateYoutubeIdentification()
        updateComments()
        updateMultimediaSettings()
    // eslint-disable-next-line
    }, [post])
    useEffect(() => {
        if (hasMultimedia) fetchMultimedia()
    // eslint-disable-next-line
    }, [hasMultimedia])
    useEffect(() => {
        if (!postTitle.current) return
        if (!isLoading) return
        // postTitle.current.scrollIntoView({ block: 'start' });
    // eslint-disable-next-line
    }, [postTitle, post]);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0
        });
    // eslint-disable-next-line
    }, [post])
    return (
        <div className={`post-page-outer-container ${!sidebarCollapsed ? "post-page-outer-container-sidebar" : ""}`}>
            <div className="post-page-width-control" ref={postTitle}>
                {isShowingEditPost && <ModalEditPost hideEditPostForm={() => { setIsShowingEditPost(false) }} updateWorkspacePost={updateWorkspacePost} post={post} team={team} />}
                {isShowingFullView && <PostMediaFullView hideView={() => setIsShowingFullView(false)} imageUrls={postImages} focusIndex={multiMediaFocusIndex} focusUrl={multiMediaFocusUrl} post={post}  /> }
                {isShowingDeletePost && <ConfirmAction mainText="Are you sure you want to delete post?" onconfirm={deletePost} hideMe={() => setIsShowingDeletePost(false)} />}
                {isRequestingCommentDelete && interestingCommentId && <ConfirmAction mainText="Delete this comment?" onconfirm={deletePostComment} hideMe={cancelCommentDelete} />}

                <div className="post-page-header" >
                    <div className="post-page-header-back" onClick={returnToFeed}>
                        <FaAngleLeft/>
                    </div>
                </div>

                {post && !hasError && !isLoading &&
                <div className="post-page-main-container">


                    <div className="post-page-main-author-timestamp">
                        <ProfPicCircle height={42} src={post.author_profile_pic_src} userId={post.creator} userName={post.author}/>
                        <div>
                            <div className="post-page-main-author">{post.author}</div>
                            <div className="post-page-main-timestamp">{formatDistanceToNow(parseISO(post.created))} ago</div>
                        </div>
                        {localStorage.getItem("gId") === post.creator &&
                        <PostOptions actionsList={getPostOptionsForPostOwner()} />
                        }
                    </div>
                    
                    {post.post_title && <h1>{post.post_title}</h1>}

                    <div className="post-page-main-content-text">
                        {renderTextContent()}
                    </div>

                    {hasMultimedia && imagesLoaded && <PostImagePreview images={postImages} selectImageToView={selectImageToView} />}


                    {youtubeVideoId && postImages.length === 0 && <EmbeddedYouTubeVideo youtubeVideoId={youtubeVideoId} /> }

                    <div className="project-post-discussion post-page-engagement-container">
                        <div className="project-post-reactions">
                            <div className="project-post-reaction" onClick={likePost} style={additionalReactionStyle()}>
                                {isLikedByUser.current ? <>👍</> : <AiOutlineLike style={{fontSize: "15px"}}/>} <span>{likeReactions}</span>
                            </div>
                        </div>
                        <div className="feed-post-discussion-item">
                            <span>{postComments.length} comments</span>
                        </div>
                    </div>

                    <div className="post-page-comment-proposed">
                        <ProposedComment createComment={leaveComment} />
                    </div>

                    <div className="post-page-comments-section">
                        {postComments.map((comment, index) => (
                            <div className="post-page-comment-container" key={comment._id} style={(thisCommentIsANewPersonOrDay(index) && index > 0) ? {marginTop: "14px", paddingTop: "20px", borderTop: "1px solid rgb(208, 208, 208)"} : {paddingTop: "0px"}}>
                                <div style={{width: "40px", minWidth: "40px"}}>
                                    {thisCommentIsANewPersonOrDay(index) && <ProfPicCircle height={40} src={getUserProfileSrcFromId(team, comment.creator)} userId={comment.creator} userName={comment.author} />}

                                </div>
                                <div>
                                    {thisCommentIsANewPersonOrDay(index) &&
                                    <div className="post-page-comment-author-timestamp">
                                        {comment.author}
                                        <span>{format(parseISO(comment.createdAt), 'M/d/yyyy')} at {format(parseISO(comment.createdAt), 'p')}</span>
                                    </div>
                                    }
                                    <div className="post-page-comment-content">{getCommentWithUrls(comment.text_content)}</div>
                                </div>

                                {localStorage.getItem("gId") === comment.creator && <div className="post-page-comment-delete" onClick={() => identifyCommentToDelete(comment)}><IoTrash/></div>}

                            </div>
                        ))}
                    </div>

                </div>
                }

                {!hasError && isLoading &&
                <div className="post-page-loading-container">
                    <LoadingSymbolGrid />
                    <div>Fetching post...</div>
                </div>
                }

                {hasError &&
                <div className="post-page-error-container">
                    Oh, snap - something went wrong
                </div>
                }
            </div>

            {/* SIDEBAR DECORATION */}
            {workspace &&
            <div className="post-page-right-decorations app-thin-scrollbar">
                <h3>{workspace.name}</h3>
                {workspace.description &&
                <div className="post-page-right-decoration-section">
                    <ReactMarkdown className="feed-right-side-about-container post-page-right-side-about-container">{workspace.description}</ReactMarkdown>
                </div>
                }
                {/* <div className="post-page-right-decoration-section">
                    <div>Contributors</div>
                    <div>People here</div>
                </div> */}
            </div>
            }
        </div>
    )
}

function EmbeddedYouTubeVideo({ youtubeVideoId }) {
    return (
        <div className="youtube-embed-container">
            <iframe
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${youtubeVideoId}?controls=1&showinfo=0&rel=0`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded YouTube video"
            ></iframe>
        </div>
    )
}

function ProposedComment({ createComment }) {
    const MINIMUM_ROW_COUNT = 1;
    const [proposedComment, setProposedComment] = useState(() => { return "" })
    const [newCommentRows, setNewCommentRows] = useState(() => { return 1 })
    const [isValid, setIsValid] = useState(() => { return false })
    const submitNewComment = () => {
        if (!proposedComment) return
        if (proposedComment.trim() === "") return
        if (!isValid) return
        createComment(proposedComment)
        setProposedComment("")
        setNewCommentRows(1)
    }
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            if (proposedComment.trim() !== '') {
                submitNewComment()
                e.preventDefault();
            }
        } else if (e.key === 'Enter' && e.shiftKey) {
            e.preventDefault();
            if (!proposedComment) return
            if (proposedComment.trim() === "") return
            setProposedComment((prevComment) => `${prevComment}\n`);
        }
    }
    const updateLines = () => {
        if (!proposedComment) {
            setIsValid(false)
            setNewCommentRows(MINIMUM_ROW_COUNT)
            return
        }
        if (proposedComment.trim() === "") {
            setIsValid(false)
            setNewCommentRows(MINIMUM_ROW_COUNT)
            return
        }
        setIsValid(true)
        let breaks = proposedComment.split(/\r\n|\r|\n/).length
        setNewCommentRows(Math.max(breaks, MINIMUM_ROW_COUNT))
    }
    useEffect(() => {
        updateLines()
    // eslint-disable-next-line
    }, [proposedComment])
    return (
      <div className="initiative-discussions-proposal-input">
        <textarea className="initiative-discussions-textarea app-thin-scrollbar" placeholder="Share your thoughts (use ctrl+shift to add new lines)" value={proposedComment} onChange={(e) => setProposedComment(e.target.value)} rows={newCommentRows} onKeyDown={handleKeyDown}></textarea>
        {isValid && <button className="initiative-discussions-submit-button initiative-discussions-submit-button-valid" onClick={submitNewComment}><IoSendSharp/></button>}
        {!isValid && <button className="initiative-discussions-submit-button" onClick={submitNewComment}><IoSendSharp/></button>}
      </div>
    );
}