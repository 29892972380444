import React from 'react'
import { useEffect, useState } from 'react'
import '../../styles/misc.css'
import { Link, useHistory } from 'react-router-dom';
import { format, isValid, parseISO, startOfToday, isBefore, isAfter, addDays, endOfToday, formatRelative } from 'date-fns'
import CollapsibleListSection from '../gadgets/CollapsibleListSection'
import ToastText from '../gadgets/ToastText'
import UserNotebook from '../user/UserNotebook';
import UserWorkspaces from '../components/UserWorkspaces';

import Api from '../../common/APIUtils'
import DateBoxEditable from '../components/misc/DateBoxEditable';
import LoadingSymbolInline from '../gadgets/LoadingSymbolInline';
import TaskStateSelector from '../gadgets/TaskStateSelector';
import { sortByProperty, sortByDateStringAttribute } from '../../common/Helpers';

import { getUserNotes } from '../../common/Api';

import { GiCheckMark } from 'react-icons/gi'
import { BiCalendar } from 'react-icons/bi'
import { MdSchema, MdCheckBoxOutlineBlank, MdCheckBox, MdOutlineFilterList } from 'react-icons/md'

export default function MyDesk({ userAssignments, userAssignmentsDispatch, getUserAssignments, userData }) {
    const [laterAssignments, setLaterAssignments] = useState(() => { return [] })
    const [notes, setNotes] = useState(() => { return [] });
    const [notesIsLoading, setNotesIsLoading] = useState(() => { return true });
    const [notesIsError, setNotesIsError] = useState(() => { return false });
    const [assignmentsOverdue, setAssignmentsOverdue] = useState(() => { return [] })
    const [workspaces, setWorkspaces] = useState(() => { return [] })
    const [allNotifications, setAllNotifications] = useState(() => { return [] })
    const [assignmentsWeek, setAssignmentsWeek] = useState(() => { return [] })
    const [workspacesFilter, setWorkspacesFilter] = useState(() => { return [] })
    const [assignmentsToday, setAssignmentsToday] = useState(() => { return [] })
    const [showToast, setShowToast] = useState(() => { return false })
    const [page, setPage] = useState(() => {
        if (localStorage.getItem("gDeskView")) {
            if (["tasks", "mentions", "notes"].includes(localStorage.getItem("gDeskView"))) return localStorage.getItem("gDeskView")
        }
        return "tasks"
    })
    const [toastContent, setToastContent] = useState(() => { return "Marked complete" })
    const [toastTask, setToastTask] = useState(() => { return false })
    const api = new Api()
    const history = useHistory()
    const updateTask = (payload, task, hideToast) => {
        const taskId = task._id
        if (payload.status === "completed") prepareForUndoComplete(task)
        if (payload.attributes) {
            if (payload.attributes.date_due || payload.attributes.date_due === "") updateTaskDueDateLocally(task, payload.attributes.date_due)
        }
        const path = `/workspace/components/${taskId}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            getUserAssignments()
            if (!hideToast) setShowToast(true)
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const undoTaskCompletion = () => {
        if (!toastTask) return
        updateTask({
            attributes: {
                active: true,
                date_completed: "",
                progress: 0,
            },
            status: "active",
            completed: ""
        }, toastTask)
        setToastTask(false)
    }
    const fetchNotes = () => {
        getUserNotes()
        .then((res) => {
            if (res.data) {
                if (res.data.notes) {
                    setNotes(res.data.notes)
                    setNotesIsLoading(false)
                    return
                }
            }
            setNotesIsError(true)
        })
        .catch((err) => { return setNotesIsError(true) })
    }
    const fetchWorkspaces = () => {
        api.getWorkspaces()
        .then((res) => {
            if (res.data) {
                if (res.data.workspaces) {
                    const w = sortByProperty(res.data.workspaces, "name")
                    setWorkspaces(w)
                }
            }
        })
        .catch((err) => {
            return
        })  
    }
    const updateWorkspacesFilter = (workspaceId) => {
        try {
            let operatingWorkspaceFilter = Array.from(workspacesFilter)
            if (operatingWorkspaceFilter.includes(workspaceId)) {
                const index = operatingWorkspaceFilter.indexOf(workspaceId)
                operatingWorkspaceFilter.splice(index, 1)
                setWorkspacesFilter(operatingWorkspaceFilter)
            } else {
                operatingWorkspaceFilter.push(workspaceId)
                setWorkspacesFilter(operatingWorkspaceFilter)
            }
            return
        } catch (error) {
            return
        }
    }
    const prepareForUndoComplete = (task) => {
        setToastTask(task)
        setToastContent(`${task.display_name.slice(0, 30)}${task.display_name.length > 30 ? "..." : ""}  completed.`)
        removeTaskFromLocalAssignments(task)
    }
    const removeTaskFromLocalAssignments = (task) => {
        userAssignmentsDispatch({ type: "remove_task", taskId: task._id })
    }
    const updateTaskDueDateLocally = (task, dueDate) => {
        userAssignmentsDispatch({ type: "update_due_date", task_id: task._id, date_due: dueDate })
    }
    const updateAssignmentSets = () => {
        try {
            if (!userAssignments.loaded) return
            const onlyActiveTasks = userAssignments.tasks.filter((t) => {
                if (t.name !== "task") return false
                if (t.status !== "active") return false
                if (t.workspace_id) {
                    if (workspacesFilter.length > 0) {
                        if (workspacesFilter.includes(t.workspace_id)) return true
                        return false
                    }
                }
                return true
            })
            const hasDueDates = onlyActiveTasks.filter(t => isValid(parseISO(t.attributes.date_due)))
            const onlyDueDatesUnsorted = hasDueDates.sort( (a, b) => { 
                return parseISO(a.attributes.date_due) - parseISO(b.attributes.date_due)
            })
            // Sort the order
            const onlyDueDates = sortByProperty(onlyDueDatesUnsorted, "display_name")
            const overDue = onlyDueDates.filter(t => isBefore(parseISO(t.attributes.date_due), startOfToday()))
            const thisWeekFiltered = onlyDueDates.filter((t) => {
                if (isBefore(parseISO(t.attributes.date_due), addDays(startOfToday(), 1))) return false
                if (isAfter(parseISO(t.attributes.date_due), addDays(endOfToday(), 7))) return false
                return true
            })
            const thisWeek = sortByDateStringAttribute(thisWeekFiltered, true, "display_name")
            const today = onlyDueDates.filter((t) => {
                if (isBefore(parseISO(t.attributes.date_due), startOfToday())) return false
                if (isAfter(parseISO(t.attributes.date_due), endOfToday())) return false
                return true
            })
            const noDueDates = onlyActiveTasks.filter(t => !isValid(parseISO(t.attributes.date_due)))
            const later = hasDueDates.filter((t) => {
                if (isAfter(parseISO(t.attributes.date_due), addDays(endOfToday(), 7))) return true
                return false
            })
            const sortedLaterTasks = sortByDateStringAttribute(later, true, "display_name").concat(sortByProperty(noDueDates, "display_name"))
            setAssignmentsToday(today)
            setLaterAssignments(sortedLaterTasks)
            setAssignmentsWeek(thisWeek)
            setAssignmentsOverdue(overDue)
        } catch (error) {
            return
        }
    }
    const goToInterestingUrl = (notification) => {
        if (notification.url_endpoint) {
            deleteNotification(notification._id)
            history.push(notification.url_endpoint)
        }
    }
    const updateUnreadNotifications = () => {
        try {
            if (!userAssignments) return
            if (!userAssignments.notifications) return
            setAllNotifications(userAssignments.notifications)
        } catch (error) {
            return
        }
    }
    const deleteNotification = (notificationId) => {
        const payload = {
            notification_id: notificationId
        }
        try {
            api.deleteNotification(payload)
            .then((res) => {
                getUserAssignments()
            })
        } catch (error) {
            console.log(error)
        }
    }
    const updatePage = (newPage) => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('v', newPage);
        history.push(`${window.location.pathname}?${searchParams.toString()}`);
        localStorage.setItem("gDeskView", newPage)
        setPage(newPage);
    }
    const ALLOWED_VIEWS = ["tasks", "mentions", "notes" , "workspaces"]
    useEffect(() => {
        getUserAssignments()
        fetchNotes()
        fetchWorkspaces()
        document.title = "My Desk"
        const searchParams = new URLSearchParams(window.location.search);
        let proposedView = "tasks"
        const initialView = searchParams.get('v')
        if (ALLOWED_VIEWS.includes(localStorage.getItem("gDeskView"))) proposedView = localStorage.getItem("gDeskView")
        if (ALLOWED_VIEWS.includes(initialView)) proposedView = initialView
        setPage(proposedView);
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        updateUnreadNotifications()
    // eslint-disable-next-line
    }, [userAssignments])
    useEffect(() => {
        let count = 0
        if (assignmentsToday.length > 0) count = count + assignmentsToday.length
        if (assignmentsOverdue.length > 0) count = count + assignmentsOverdue.length
        if (count > 0) document.title = `My Desk (${count})`
    }, [assignmentsToday, assignmentsOverdue])
    useEffect(() => {
        updateAssignmentSets()
    // eslint-disable-next-line
    }, [userAssignments, workspacesFilter])
    return (
        <div className="user-mydesk-container">
            {showToast && toastTask && <ToastText content={toastContent} timeout={10000} actionText="Undo" actionOnClick={undoTaskCompletion} setShowToast={setShowToast} />}

            {/* HEADER */}
            <div className="user-mydesk-header-sticky-container">
                <div className="user-mydesk-header">
                    <h2>My Desk</h2>
                    {userAssignments.is_loading &&
                    <div className="user-mydesk-header-loading-container">
                        <div className="loader-mydesk"></div>
                    </div>
                    }
                </div>

                <div className="user-mydesk-nav">
                    <span onClick={() => updatePage("tasks")} className={`${page === "tasks" ? "active" : ""}`}>Tasks</span>
                    <span onClick={() => updatePage("mentions")} className={`${page === "mentions" ? "active" : ""}`}>Mentions</span>
                    <span onClick={() => updatePage("notes")} className={`${page === "notes" ? "active" : ""}`}>Notes</span>
                    <span onClick={() => updatePage("workspaces")} className={`${page === "workspaces" ? "active" : ""}`}>Workspaces</span>
                </div>
            </div>

            {page === "tasks" &&
            <div>
                {userAssignments.loaded &&
                <div className="user-mydesk-assignments-container">
                    <div className="user-mydesk-assignments-header">
                        <BasicFilter filterName="Workspace" filterOptions={workspaces} currentFilter={workspacesFilter} displayAttributeName="name" onFilterOptionClick={updateWorkspacesFilter} setFilter={setWorkspacesFilter} />
                    </div>
                    <div className="util-row util-col" style={{gap: "24px"}}>
                        <CollapsibleListSection title="Today" subtitle={format(startOfToday(), 'L/d/yy')} count={assignmentsToday.length}>
                            <div className="user-mydesk-assignments-list">
                                {assignmentsToday.map((task) => (
                                    <InteractiveAssignmentItem key={task._id} task={task} updateTask={updateTask}/>
                                ))}
                                {assignmentsToday.length === 0 && <div className="user-mydesk-assignments-list-empty">Nothing due today</div> }
                            </div>
                        </CollapsibleListSection>
                        {assignmentsOverdue.length > 0 &&
                            <CollapsibleListSection title="Overdue" subtitle="Items due before today" count={assignmentsOverdue.length}>
                                {/* <div className="user-mydesk-assginments-overdue-actions util-row util-align-center" style={{gap: "8px", marginTop: "12px"}}>
                                    <span>Mark all complete</span>
                                    <span>Make all due today</span>
                                </div> */}
                                <div className="component-list-tasks-section">
                                    {assignmentsOverdue.map((task) => (
                                        <InteractiveAssignmentItem key={task._id} task={task} updateTask={updateTask} />
                                    ))}
                                </div>
                            </CollapsibleListSection>
                        }
                        <CollapsibleListSection title="Next 7 Days" subtitle={`${format(addDays(startOfToday(), 1), 'L/d/yy')} - ${format(addDays(endOfToday(), 7), 'L/d/yy')}`} count={assignmentsWeek.length}>
                            <div className="user-mydesk-assignments-list">
                                {assignmentsWeek.map((task) => (
                                    <InteractiveAssignmentItem key={task._id} task={task} updateTask={updateTask}/>
                                ))}
                                {assignmentsWeek.length === 0 && <div className="user-mydesk-assignments-list-empty">Nothing else due this week</div> }
                            </div>
                        </CollapsibleListSection>
                        {laterAssignments.length > 0 &&
                        <CollapsibleListSection title="Later" count={laterAssignments.length}>
                            <div className="user-mydesk-assignments-list">
                                {laterAssignments.map((task) => (
                                    <InteractiveAssignmentItem key={task._id} task={task} updateTask={updateTask}/>
                                ))}
                            </div>
                        </CollapsibleListSection>
                        }
                    </div>
                </div>
                }
                {!userAssignments.loaded &&
                <div style={{marginTop: "36px", maxWidth: "200px", width: "100%", boxSizing: "border-box", marginBottom: "36px"}}>
                    <LoadingSymbolInline />
                </div>
                }
            </div>
            }

            {page === "mentions" &&
            <div className="user-mydesk-notifications-container">
                {userAssignments.loaded &&
                <div className="header-notifications-notifications-container" style={{marginTop: "16px", maxHeight: "unset"}}>
                    {allNotifications.map((notification) => (
                        <div key={notification._id} className={`header-notification-single-container-outer ${notification.status === "read" ? "header-notification-message-read" : ""}`}>
                            <div className="header-notification-single-container-inner" onClick={() => goToInterestingUrl(notification)}>
                                <span className="notification-color" style={{backgroundColor: notification.color}}></span>
                                <div>
                                    <div className={`header-notification-message`}>
                                        {notification.message}
                                    </div>
                                    <div className="header-notification-timestamp">
                                        {formatRelative(new Date(notification.createdAt), new Date())}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {allNotifications.length < 1 && <div className="header-notification-caught-up">All caught up.</div> }
                </div>
                }
                {!userAssignments.loaded &&
                <div style={{marginTop: "36px", maxWidth: "200px", marginBottom: "36px"}}>
                    <LoadingSymbolInline />
                </div>
                }
            </div>
            }

            {page === "notes" &&
                <UserNotebook notes={notes} fetchNotes={fetchNotes} isLoading={notesIsLoading} isError={notesIsError} />
            }

            {page === "workspaces" && userData &&
                <UserWorkspaces userData={userData} />
            }
        </div>
    )
}

function InteractiveAssignmentItem({task, isInCompleted, updateTask, workspaces}) {
    const [dueDate, setDueDate] = useState(() => { return "" })
    const updateDueDate = (date) => {
        if (!task) return
        const payload = {
            attributes: {
                date_due: format(date, 'yyyy-MM-dd')
            }
        }
        updateTask(payload, task, true)
    }
    const clearDueDate = () => {
        setDueDate("")
        if (!task) return
        const payload = {
            attributes: {
                date_due: ""
            }
        }
        updateTask(payload, task)
    }
    const getDateSection = () => {
        try {
            if (task.status === 'completed') {
                return (
                    <div className="component-list-task-section-meta">
                        <BiCalendar/>
                        {task.completed && <span>Completed {format(parseISO(task.completed), 'LLLL do')}</span>}
                        {!task.completed && <span>{format(new Date(), 'LLLL do')}</span>}
                    </div>
                )
            }
            return <DateBoxEditable component={task} altEmptyText="None" side="right" editable={true} date={parseISO(dueDate)} label="" onupdate={updateDueDate} clearDate={clearDueDate} /> 
        } catch (error) {
            console.log(error)
            return <div></div>
        }
    }
    const getActionCircle = () => {
        if (task.status === 'active') {
            return (
                <span className="component-list-task-action-circle" onClick={completeTask}></span>
            )
        }
        return (
            <span className="component-list-task-action-circle component-list-task-action-circle-completed" onClick={restoreTask}>
                <GiCheckMark style={{color: "#9F6ADB"}}/>
            </span>
        )
    }
    const getDetails = () => {
        try {
            return (
                <div className="user-mydesk-task-summary-subtext-clickable">
                    <span><Link to={`/workspaces/${task.workspace._id}`} className="unlink">{task.workspace.name}</Link></span> {`/`} <span><Link to={`/workspaces/${task.workspace._id}/c/${task.parent._id}`} className="unlink">{task.parent.display_name}</Link></span>
                </div>
            ) 
        } catch (error) {
            return ""
        }
    }
    const getSubtasksSection = () => {
        if (isInCompleted) return
        if (!task.criteria) return
        if (task.criteria.length === 0) return
        let taskWord = "tasks"
        if (task.criteria.length === 1) taskWord = "task"
        return (
            <div className="component-list-task-section-meta">
                <MdSchema/>
                <span>{task.criteria.length} {taskWord}</span>
            </div>
        )
    }
    const completeTask = () => {
        const payload = {
            attributes: {
                active: false,
                date_completed: new Date(),
                progress: 1,
            },
            status: "completed",
            completed: new Date(),
        }
        updateTask(payload, task)
    }
    const restoreTask = () => {
        const payload = {
            attributes: {
                active: true,
                date_completed: "",
                progress: 0,
            },
            status: "active",
            completed: ""
        }
        updateTask(payload, task) 
    }
    useEffect(() => {
        if (!task) return
        if (!task.attributes.date_due) return
        setDueDate(task.attributes.date_due)
    }, [task])
    return (
        <div className="component-list-task-container user-mydesk-interactive-assignment-item-container">
            <div className="component-list-task-container-section-summary" style={{alignItems: "center"}}>
                {getActionCircle()}
                <div className="component-list-task-summary-titles user-mydesk-task-summary-subtext-clickable">
                    <h3><Link className="unlink" to={`/workspaces/${task.workspace_id}/c/${task._id}`}>{task.display_name}</Link></h3>
                    <div className="component-list-task-summary-titles-details">
                        {getDetails()}
                        <TaskStateSelector task={task} updateTask={updateTask} />
                    </div>
                </div>
            </div>
            <div className="component-list-task-container-section-meta">
                {getSubtasksSection()}
                {getDateSection()}
            </div>
        </div>
    )
}

function BasicFilter({ filterName, filterOptions, onFilterOptionClick, currentFilter, displayAttributeName, setFilter }) {
    const [filterOpen, setFilterOpen] = useState(() => { return false })
    const handleMainClick = () => {
        try {
            if (!filterOptions) return
            if (filterOptions.length === 0) return
            return setFilterOpen(!filterOpen)
        } catch (error) {
            return
        }
    }
    const selectAll = () => {
        setFilterOpen(false)
        setFilter([])
    }
    return (
        <div className="user-mydesk-assignments-header-filter-outer">
            <div className={`user-mydesk-assignments-header-filter-inner ${currentFilter.length > 0 ? "active" : ""}`} onClick={handleMainClick}><MdOutlineFilterList/> {filterName}</div>
            {filterOpen &&
            <div className="user-mydesk-assignments-header-filter-options app-thin-scrollbar">
                <div onClick={selectAll}>Select All</div>
                {filterOptions.map((filterItem) => (
                    <div key={filterItem._id} onClick={() => onFilterOptionClick(filterItem._id)}>
                        {currentFilter.includes(filterItem._id) && <MdCheckBox style={{color: "#3D72AA"}} />}
                        {!currentFilter.includes(filterItem._id) && <MdCheckBoxOutlineBlank />}
                        <span>{filterItem[displayAttributeName]}</span>
                    </div>
                ))}
            </div>
            }
        </div>
    )
}