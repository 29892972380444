import React from 'react'
import { useState, useRef, useEffect } from 'react';
import Api from '../../common/APIUtils';
import ModalSimpleHeader from '../modals/simple/ModalSimpleHeader'

export default function WorkspaceBannerUpload({fetchWorkspace, hideModal, workspace}) {
    const hiddenFileInput = useRef(null)
    const api = new Api();
    const [file, setFile] = useState(() => { return null })
    const [filePreviewUrl, setFilePreviewUrl] = useState(() => { return null })
    const [proposedProfilePic, setProposedProfilePic] = useState(() => {
        if (workspace.banner_image_url) {
            return workspace.banner_image_url
        } else {
            return ""
        }
    })
    const isValidProfilePhoto = (proposedFile) => {
        const types = ['image/png', 'image/jpeg']
        const halfMb = 524288;
        if (proposedFile.size > halfMb*4) return false
        if (!types.includes(proposedFile.type)) return false
        return true

    }
    const prepareUpload = e => {
        const propFile = e.target.files[0];
        if (isValidProfilePhoto(propFile)) {
            setFile(propFile)
        } else {
            alert("Banner must be 2MB or smaller")
        }
    }
    const getDisplayImage = (f) => {
        if (f) return <img src={f} alt="banner" />
        return (
            <div></div>
        )
    }
    const fetchPhoto = () => {
        fetchWorkspace();
    }
    const uploadPhotoToServer = () => {
        try {
            const formData = new FormData();
            formData.append('profile', file)
            api.updateWorkspaceBannerImage(formData, workspace._id)
            .then((res) => {
                setFile(null)
                setTimeout(fetchPhoto, 2500)
                hideModal()
            })
            .catch((err) => {
                console.log(err)
            })

            // Fetch user a few seconds after
        } catch (error) {
            console.log(error)
        }

    }
    const promptFileUpload = e => {
        hiddenFileInput.current.click()
    }
    useEffect(() => {
        if (file) {
            const url = URL.createObjectURL(file)
            setFilePreviewUrl(url)
            setProposedProfilePic(url)
        }
    }, [file])
    return (
        <div className="alignment-modal-background">
            <div className="workspace-settings-banner-edit-modal-inner-container">
                <ModalSimpleHeader hideModal={hideModal} title="Edit Workspace Banner" />
                <div className="profile-user-edit-modal-picture-container-center">

                    {!file ? (
                        <>
                        <div className="workspace-settings-banner-edit-modal-picture-container" onClick={promptFileUpload}>
                            {getDisplayImage(proposedProfilePic)}
                        </div>
                        <form style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <label htmlFor="profile-pic" className="profile-pic-label" onClick={promptFileUpload}>Update banner image</label>
                            <input type="file" style={{display: "none"}} accept=".png,.jpg" ref={hiddenFileInput} htmlFor="profile-pic" onChange={prepareUpload}/>
                            <div style={{marginTop: "4px", fontSize: "14px", textAlign: "center"}}><em>File size must be less than 2MB</em></div>
                        </form>
                        </>
                    ) : (
                        <>
                        <div className="workspace-settings-banner-edit-modal-picture-container" onClick={promptFileUpload}>
                            {getDisplayImage(filePreviewUrl)}      
                        </div>
                        <input type="file" style={{display: "none"}} accept=".png,.jpg" ref={hiddenFileInput} htmlFor="profile-pic" onChange={prepareUpload}/>
                        <div style={{marginTop: "4px", fontSize: "14px", textAlign: "center"}}><em>Recommended dimensions are 2400px x 280px</em></div>
                        <div className="profile-pic-label-save" onClick={uploadPhotoToServer}>Save</div>
                        <div className="profile-pic-label-cancel" onClick={hideModal}>Cancel</div>
                        </>
                    )}
                    
                </div>

            </div>
        </div>
    )
}
