import React from 'react'
import { MdKeyboardBackspace } from 'react-icons/md'
import { useState, useEffect } from 'react';
import ProfPicCircle from '../../profiles/ProfPicCircle';
import AssignmentTable from '../../assignments/AssignmentTable';

export default function DashboardUserDeepDive({ data, team, userId, permissions, workspace, updateWorkspaceComponent, goBack }) {
    const [user, setUser] = useState(() => { return false })
    const [userViewSortBy, setUserViewSortBy] = useState(() => { return "date" })
    const [assignments, setAssignments] = useState(() => { return [] })
    const getAssignee = () => {
        try {
            if (!userId) return
            const userOfInterest = data.assignments.find(u => u._id === userId)
            setUser(userOfInterest)
        } catch (error) {
            return
        }
    }
    const updateAssignments = () => {
        if (!userId || !user) {
            const copyOfAllComponents = Array.from(data.all)
            const componentsWithoutOwners = copyOfAllComponents.filter((task) => {
                if (task.owner_id === "") return true
                if (!task.owner_id) return true
                return false
            })
            setAssignments(componentsWithoutOwners)
            return
        }
        if (!user) return
        try {
            const userAssignments = user.assignments
            setAssignments(userAssignments)
        } catch (error) {
            return
        }
    }
    useEffect(() => {
        if (!userId) return
        getAssignee()
    // eslint-disable-next-line
    }, [userId])
    useEffect(() => {
        updateAssignments()
    // eslint-disable-next-line
    }, [user])
    return (
        <div>
            {user &&
            <div className="basic-row-centered" style={{gap: "14px", marginTop: "14px", marginBottom: "20px"}}>
                <MdKeyboardBackspace style={{fontSize: "20px", padding: "12px", paddingLeft: "0px", cursor: "pointer"}} onClick={goBack}/>
                <ProfPicCircle src={user.profile_picture_url} userId={1} userName={`${user.firstName} ${user.lastName}`} height={56} />
                <div className="">
                    <div className="dashboard-view-dive-heading">{user.firstName} {user.lastName}</div>
                    <div className="dashboard-view-dive-subheading">@{user.handle}</div>
                </div>
            </div>
            }
            {!user &&
            <div className="basic-row-centered" style={{gap: "14px", marginTop: "14px", marginBottom: "20px"}}>
                <MdKeyboardBackspace style={{fontSize: "20px", padding: "12px", paddingLeft: "0px", cursor: "pointer"}} onClick={goBack}/>
                <div className="">
                    <div className="dashboard-view-dive-heading">Unassigned</div>
                </div>
            </div>
            }
            <AssignmentTable context="workspace" permissions={permissions} updateWorkspaceComponent={updateWorkspaceComponent} components={assignments} workspaces={[workspace]} sortBy={userViewSortBy} setSortBy={setUserViewSortBy} team={team} />
        </div>
    )
}
