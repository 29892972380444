import React from 'react'
import { useState, useEffect } from 'react';
import Api from "../common/APIUtils"
import { useHistory } from 'react-router-dom'
import SidebarFeedUrgentItem from './sidebars/SidebarFeedUrgentItem';
import FeedInterface from './posts/FeedInterface';
// eslint-disable-next-line
import ProfPicRoundedSquare from './profiles/ProfPicRoundedSquare';
import NewHomePost from './modals/NewHomePost';
import LoadingSymbolFacebook from './gadgets/LoadingSymbolFacebook';
import { differenceInDays } from 'date-fns';

import { activateTrial } from '../common/Api';

export default function HomeFeed({setSidebarCollapsed, userData}) {
    const api = new Api();
    const [feedFetchComplete, setFeedFetchComplete] = useState(() => { return false })
    const [isShowingNewPostForm, setIsShowingNewPostForm] = useState(() => { return false })
    const [workspaceMap, setWorkspaceMap] = useState(() => { return [] });
    const [sensitiveItems, setSensitiveItems] = useState(() => { return [] });
    const [showTetherosProAd, setShowTetherosProAd] = useState(() => { return false });
    const [dueSoon, setDueSoon] = useState(() => { return [] });
    const [stories, setStories] = useState(() => { return [] });
    const [team, setTeam] = useState(() => { return [] });
    const fetchPosts = () => {
        api.getUserPosts()
        .then((res) => {
            if (res.data.response) {
                setSensitiveItems(res.data.response.overdue);
                setDueSoon(res.data.response.dueSoon);
                setStories(res.data.response.stories)
                setWorkspaceMap(res.data.response.workspaces)
                setTeam(res.data.response.team)
                setFeedFetchComplete(true)
            }
        })
        .catch((err) => {
            console.log(err);
        })  
    }
    const createUserPost = (payload) => {
        if (!payload.text_content && !payload.rich_text_content) return
        let postInfo = {}
        if (payload.text_content) postInfo['text_content'] = payload.text_content
        if (payload.rich_text_content) postInfo['rich_text_content'] = payload.rich_text_content
        api.createUserPost(postInfo)
        .then( (res) => {
            if (res.status === 200) {
                fetchPosts();
            }
            fetchPosts();
        })
        .catch((err) => {
            console.log(err);
            alert(err);
        });;
    }
    // eslint-disable-next-line
    const showCreateNewPostForm = () => {
        setIsShowingNewPostForm(true);
    }
    const hideCreateNewPostForm = () => {
        setIsShowingNewPostForm(false);
    }
    const updateIfShowTetherosProAd = () => {
        try {
            if (!userData) return setShowTetherosProAd(false)
            if (!userData.license_tier) return setShowTetherosProAd(false)

            if (["Pro"].includes(userData.license_tier)) return setShowTetherosProAd(false)
            // Show if on trial plan
            if (userData.license_is_trial) return setShowTetherosProAd(true)

            // Show if on social plan
            if (userData.license_tier.toLowerCase() === "social") return setShowTetherosProAd(true)
            return setShowTetherosProAd(false)
        } catch (error) {
            setShowTetherosProAd(false)
        }
    }
    useEffect(() => {
        setSidebarCollapsed(false)
        fetchPosts()
        // Fetch posts every 30 seconds
        const interval = setInterval(() => {
            fetchPosts()
        }, 30000)
        return() => clearInterval(interval)
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        updateIfShowTetherosProAd()
    // eslint-disable-next-line
    }, [userData])
    return (
        <div className={`workspace-home-feed-container`}>
            {isShowingNewPostForm && <NewHomePost reqPostType="text" hideCreateNewPostForm={hideCreateNewPostForm} createPost={createUserPost} />}

            <div className="workspace-home-feed-main">
                {feedFetchComplete &&
                <div className="workspace-home-feed-main-inner">
                    <div className="workspace-home-feed-greetings">Welcome back, {localStorage.getItem("gFirstName")}!</div>
                    {/* <div className="workspace-home-feed-create-post-container">
                        <ProfPicRoundedSquare src={localStorage.getItem("gProfPicUrl")} userId={localStorage.getItem("gId")} userName={localStorage.getItem("gFullName")} height={48}/>
                        <div className="workspace-home-feed-create-post" onClick={showCreateNewPostForm}>
                            <div>Share an update with your connections</div>
                        </div>
                        <div className="workspace-home-feed-create-post-button" onClick={showCreateNewPostForm}>Post</div>
                    </div> */}
                    {stories.length > 0 &&
                    <div className="workspace-home-feed-main-post-container workspace-home-feed-main-post-container-populated">
                        {stories.map((story) => (
                            <FeedInterface team={team} key={story._id} feedItem={story} fetchFeed={fetchPosts} feedDisplay="home" workspaceMap={workspaceMap}/>
                        ))}
                    </div>}
                </div>
                }
            </div>

            <div className="workspace-home-feed-side">
                <div className="workspace-home-feed-side-inner">

                    {showTetherosProAd && <TetherosProAd userData={userData} />}

                    <div className="workspace-home-feed-side-section-container">
                        <div className="feed-right-side-heading-text">Your Spotlight</div>
                        {feedFetchComplete ? (
                        <div className="workspace-home-feed-side-urgent-items-container">
                            {(sensitiveItems.length + dueSoon.length) > 0 ? (
                                <div className="workspace-home-feed-side-urgent-items">
                                    {sensitiveItems.map((component) => (
                                        <SidebarFeedUrgentItem key={component._id} component={component} overdue={true} feedDisplay="home" workspaceMap={workspaceMap}/>
                                    ))}
                                    {dueSoon.map((component) => (
                                        <SidebarFeedUrgentItem key={component._id} component={component} feedDisplay="home" workspaceMap={workspaceMap}/>
                                    ))}

                                </div>
                            ) : (
                                <div className="workspace-home-feed-side-urgent-items-caught-up">You have no overdue assignments or urgent items to attend to.</div>
                            )}
                        </div>
                        ) : (
                            <LoadingSymbolFacebook/>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

function TetherosProAd({userData}) {
    const history = useHistory()
    const [heroText, setHeroText] = useState(() => { return "Enjoying Tetheros?" })
    const [trialDayText, setTrialDayText] = useState(() => { return false })
    const updateAdCopy = () => {
        try {
            let trialAvailable = true
            let isStillWithinTrialPeriod = false
            if (userData.license_trial_start) {
                const today = new Date()
                const trialStart = new Date(userData.license_trial_start)
                const timeLapse = differenceInDays(today, trialStart)
                if (timeLapse > 7) {
                    trialAvailable = false
                    isStillWithinTrialPeriod = false
                } else {
                    isStillWithinTrialPeriod = true
                }
            } else { 
                // Trial has not been started
                trialAvailable = true
                isStillWithinTrialPeriod = true
            }

            // Check if trial is specifically voided
            if (userData.trial_voided) trialAvailable = false
            
            // Situation: On social plan with a trial available
            if (userData.license_tier.toLowerCase() === "social" && trialAvailable) {
                setHeroText("Free 7-day trial of Tetheros Pro")
                setTrialDayText(false)
            }
            // Situation: On social plan with no trial available
            if (userData.license_tier.toLowerCase() === "social" && !trialAvailable) {
                setHeroText("Did you enjoy Tetheros Pro?")
                setTrialDayText(false)
            }
            // Situation: On an active trial plan within trial period
            if (["startup", "team", "business"].includes(userData.license_tier.toLowerCase()) && isStillWithinTrialPeriod) {
                const today = new Date()
                const trialStart = new Date(userData.license_trial_start)
                let timeLapse = 7 - differenceInDays(today, trialStart)
                if (timeLapse < 0) timeLapse = 0
                setHeroText("Your free trial is almost over!")
                setTrialDayText(`${timeLapse} day${timeLapse === 1 ? "" : "s"} left`)
            }
        } catch (error) {
            return 
        }
    }
    const startFreeTrial = () => {
        // Activates their license and redirects them to the project page with tutorial_mode turned on
        activateTrial()
        .then((res) => {
            if (res.data.project_id) return history.push(`/workspaces/${res.data.workspace_id}/c/${res.data.project_id}`)
            return history.push('/home')
        }).catch((err) => { return })

    }
    const getCta = () => {
        try {
            let trialAvailable = true
            let isStillWithinTrialPeriod = false
            if (userData.license_trial_start) {
                const today = new Date()
                const trialStart = new Date(userData.license_trial_start)
                const timeLapse = differenceInDays(today, trialStart)
                if (timeLapse > 7) {
                    trialAvailable = false
                    isStillWithinTrialPeriod = false
                } else {
                    isStillWithinTrialPeriod = true
                }
            } else { 
                // Trial has not been started
                trialAvailable = true
                isStillWithinTrialPeriod = true
            }

            if (userData.trial_voided) trialAvailable = false
            
            // Situation: On social plan with a trial available
            if (userData.license_tier.toLowerCase() === "social" && trialAvailable) {
                return <div className="workspace-home-feed-side-section-promotion-cta" onClick={startFreeTrial}> <span>Start your trial</span></div>
            }
            // Situation: On social plan with no trial available
            if (userData.license_tier.toLowerCase() === "social" && !trialAvailable) {
                return <div className="workspace-home-feed-side-section-promotion-cta" onClick={() => history.push(`/settings?v=pro`)}> <span>Sign up for Tetheros Pro</span></div>
            }
            // Situation: On an active trial plan within trial period
            if (["startup", "team", "business"].includes(userData.license_tier.toLowerCase()) && isStillWithinTrialPeriod) {
                return <div className="workspace-home-feed-side-section-promotion-cta"><span onClick={() => history.push(`/settings?v=pro`)}>Provide payment details</span> to avoid losing progress when your trial ends</div>
            }
            return <div></div>
        } catch (error) {
            return <></>
        }
    }
    useEffect(() => {
        updateAdCopy()
    // eslint-disable-next-line
    }, [userData])
    return (
        <div className="workspace-home-feed-side-section-container workspace-home-feed-side-section-container-promotion">
            <div className="workspace-home-feed-side-section-promotion-text">
                <p>{heroText}</p>
                {trialDayText && <p>{trialDayText}</p>}
                <p>Achieve greater alignment for your organization with Tetheros Pro:</p>
                <ul>
                    <li>Unlimited workspaces</li>
                    <li>Tighter permissions</li>
                    <li>Extreme clarity</li>
                    <li>Simple billing</li>
                </ul>
                {getCta()}
            </div>
            <div className="workspace-home-feed-side-section-promotion-image">
                <img src="https://storage.googleapis.com/tetheros-public/misc/tetheros-pro-promotion.jpg" alt="Man using Tetheros Pro at his desk" />
            </div>
        </div>
    )
}