import React from 'react'
import { useHistory } from "react-router-dom";
import { ReactComponent as Logo } from '../../static/assets/tetheros-boxed-blue.svg';
import { ReactComponent as TetherosSmallLogo } from '../../static/assets/logov1-basicblue.svg';

export default function GenericToolHeader({ authenticated, title, subtitle, titleSuffix }) {
    const history = useHistory()
    return (
        <div className="tools-generic-header-container">
            <div className="util-row util-align-center tools-generic-header-container-titles">
                <a href="https://tetheros.com" className="tools-generic-header-icon unlink"><TetherosSmallLogo/></a>
                <div className="tools-generic-header-container-titles-inner">
                    <div className="tools-generic-header-title">
                        {title}
                        {titleSuffix && <div className="tools-generic-header-title-suffix">{titleSuffix}</div>}
                    </div>
                    <div className="tools-generic-header-subtitle">{subtitle}</div>
                </div>
            </div>
            <div className="util-row util-col util-justify-start tools-generic-header-container-logo" style={{cursor: "pointer"}} onClick={() => history.push('/')}>
                <div className="tools-generic-header-tagline">Powered by</div>
                <div className="tools-generic-header-logo"><Logo/></div>
            </div>


        </div>
    )
}
