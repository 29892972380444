import React from 'react'
import { useState } from 'react'

import { format, parseISO } from 'date-fns'

import { MdModeEdit, MdClose, MdArrowBack, MdRestartAlt, MdAccessTime, MdOutlineMessage } from "react-icons/md";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BsTrash3Fill } from "react-icons/bs";

export default function PromotionCalendarModal({ event, eventDataDispatch, hideModal, availableColors }) {
    const [isShowingColorSelect, setIsShowingColorSelect] = useState(() => { return false })
    const [page, setPage] = useState(() => { return 1 })
    const [proposedColor, setProposedColor] = useState(() => { return event.color_hex ? event.color_hex : "FFFFFF" })
    const [proposedDate, setProposedDate] = useState(() => { return event.attributes.date_due })
    const [proposedTitle, setProposedTitle] = useState(() => { return event.display_name })
    const [proposedDescription, setProposedDescription] = useState(() => { return event.attributes.description })
    
    const saveAndClose = () => {
        hideModal()
        eventDataDispatch({ type: "update_event", proposed_event: {
            ...event,
            color_hex: proposedColor,
            display_name: proposedTitle,
            attributes: {
                description: proposedDescription,
                date_due: proposedDate
            }
        }})
    }
    const completeAndClose = () => {
        hideModal()
        eventDataDispatch({ type: "update_event", proposed_event: {
            ...event,
            status: "completed"
        }})
    }
    const deleteAndClose = () => {
        hideModal()
        eventDataDispatch({ type: "delete_event", proposed_event: event })
    }
    const reactivateTask = () => {
        hideModal()
        eventDataDispatch({ type: "update_event", proposed_event: {
            ...event,
            status: "active"
        }})
    }
    const selectColor = (colorHex) => {
        // Update color
        setProposedColor(colorHex)
        setIsShowingColorSelect(false)
    }
    const resetChanges = () => {
        setPage(1)
        setProposedTitle(event.display_name)
        setProposedDescription(event.attributes.description)
    }
    const saveColorAndClose = () => {
        hideModal()
        eventDataDispatch({ type: "update_event", proposed_event: {
            ...event,
            color_hex: proposedColor
        }})
    }
    const getEventDate = () => {
        try {
            return format(parseISO(event.attributes.date_due), 'PPPP')
        } catch (error) {
            return ""
        }
    }
    return (
        <div className="public-tool-promotion-calendar-modal-overlay">
            <div className="public-tool-promotion-calendar-modal-container">
                <div className="public-tool-promotion-calendar-modal-header">
                    {page === 1 &&
                    <div className="public-tool-promotion-calendar-modal-color-selection-container">
                        <div className="public-tool-promotion-calendar-modal-color-selection-selected" onClick={() => setIsShowingColorSelect(!isShowingColorSelect)} style={{backgroundColor: proposedColor}}></div>
                        {isShowingColorSelect &&
                        <div className="public-tool-promotion-calendar-modal-color-selections">
                            {availableColors.map((color) => (
                                <div key={color} className="public-tool-promotion-calendar-modal-color-selection" style={{ backgroundColor: color }} onClick={() => selectColor(color)}></div>
                            ))}
                        </div>
                        }
                    </div>
                    }
                    {page === 2 && <div className="public-tool-promotion-calendar-modal-header-action" onClick={resetChanges}><MdArrowBack/></div> }
                    
                    <div className="util-row util-align-center" style={{gap: "20px"}}>
                        {page === 1 && event.status === "active" && <div className="public-tool-promotion-calendar-modal-header-action" onClick={deleteAndClose}><BsTrash3Fill style={{fontSize: "20px"}}/></div>}
                        {page === 1 && event.status === "active" && <div className="public-tool-promotion-calendar-modal-header-action" onClick={() => setPage(2)}><MdModeEdit/></div>}
                        <div onClick={saveColorAndClose} className="public-tool-promotion-calendar-modal-header-action"><MdClose/></div>
                    </div>
                    
                </div>
                {page === 1 &&
                <div className="public-tool-promotion-calendar-modal-titles">
                    <div>
                        <h2>{event.display_name}</h2>
                        <div className="public-tool-promotion-calendar-modal-timedate">{getEventDate()}</div>
                    </div>

                    <div className="public-tool-promotion-calendar-modal-description">{event.attributes.description}</div>
                </div>
                }
                {page === 2 &&
                <div className="public-tool-promotion-calendar-modal-titles public-tool-promotion-calendar-modal-edits">
                    <input placeholder="What needs to get done?" value={proposedTitle} onChange={(e) => setProposedTitle(e.target.value)} />
                    <div className="public-tool-promotion-calendar-modal-edits-label" style={{marginTop: "20px"}}><MdAccessTime/>Due Date</div>
                    <input type="date" value={proposedDate} onChange={(e) => setProposedDate(e.target.value)} />
                    <div className="public-tool-promotion-calendar-modal-edits-label"><MdOutlineMessage/>Task Description</div>
                    <textarea rows={6} placeholder="Provide a description, instructions, or ideas for the task here" value={proposedDescription} onChange={(e) => setProposedDescription(e.target.value)}></textarea>

                </div>
                }
                <div className="public-tool-promotion-calendar-modal-footer">
                    {event.status === "completed" && <div onClick={reactivateTask} className="public-tool-promotion-calendar-modal-footer-save util-row util-align-center" style={{backgroundColor: "#4F5D74", gap: "6px"}}><MdRestartAlt/>Reactivate</div>}
                    {page === 2 && <div></div> }
                    <div className="util-row util-align-stretch" style={{gap: "14px"}}>
                        {event.status !== "completed" && page === 1 && <div onClick={completeAndClose} className="public-tool-promotion-calendar-modal-footer-save util-row util-align-center" style={{backgroundColor: "green", gap: "6px"}}><IoCheckmarkCircleOutline/>Complete Task</div>}
                        {page === 2 && <div className="public-tool-promotion-calendar-modal-footer-save" onClick={saveAndClose}>Save</div>}
                    </div>
                </div>
            </div>
        </div>
    )
}
