import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';

import ModalSimpleHeader from './simple/ModalSimpleHeader'
import ModalSimpleSingleButton from './simple/ModalSimpleSingleButton'
import ModalToolSingleSelectRoleDropdownWithSearch from './tools/ModalToolSingleSelectRoleDropdownWithSearch'
import ModalSimpleMultiRadioSelection from './simple/ModalSimpleMultiRadioSelection';

import { HiDocument, HiDocumentText } from "react-icons/hi2";
import { MdAutoStories } from "react-icons/md";

import { submitWorkspaceProjectIdeaTetherosAssist, getJobToken } from '../../common/Api'
import Api from '../../common/APIUtils';

export default function ModalCreateNewProject({ hideModal, workspaceId }) {
    const history = useHistory()
    const api = new Api()
    const [projectName, setProjectName] = useState(() => { return "" })
    const [page, setPage] = useState(() => { return "basic" })
    const [hasBasicInfo, setHasBasicInfo] = useState(() => { return false })
    const [templates, setTemplates] = useState(() => { return [] })
    const [templatesLoaded, setTemplatesLoaded] = useState(() => { return false })
    const [jobTokenId, setJobTokenId] = useState(() => { return false })
    const [selectedTemplateId, setSelectedTemplateId] = useState(() => { return false })
    const [projectDescription, setProjectDescription] = useState(() => { return "" })
    const [projectType, setProjectType] = useState(() => { return "tetheros_assist" })
    const handleEnterKeyPress = (e) => {
        if (['enter', 'numpadenter'].includes(e.code.toLowerCase())) {
            proceedFromBasicPage()
        }
    }
    const proceedFromBasicPage = () => {
        if (hasBasicInfo && projectType === "tetheros_assist") return setPage("description")
        if (hasBasicInfo && projectType === "blank") return setPage("description")
        if (hasBasicInfo && projectType === "template") return setPage("templates")
    }
    const projectTypeOptions = [
        {_id: "tetheros_assist", display_name: "Tetheros Assist", description: "Automatically bootstrap everything from a description", symbol: <MdAutoStories/>},
        {_id: "template", display_name: "Template", description: "Start from a workspace or network project template", symbol: <HiDocumentText/>},
        {_id: "blank", display_name: "Blank", description: "Design every aspect of your project from scratch", symbol: <HiDocument/> },
    ]
    const getComponentTemplates = () => {
        const payload = {
            workspaceId: workspaceId,
            type: 'initiative'
        }
        try {
            api.getComponentTemplates(payload)
            .then((res) => {
                if (res.data.response.templates) {
                    setTemplatesLoaded(true)
                    setTemplates(res.data.response.templates)
                } else {
                    setTemplatesLoaded(true)
                    setTemplates([])
                }
            }) 
        } catch (error) {
            console.log(error)
        }
    }
    const updateBasicInfo = () => {
        try {
            if (projectName.trim() !== "") return setHasBasicInfo(true)
            setHasBasicInfo(false)
        } catch (error) {
            return setHasBasicInfo(false)
        }
    }
    const getThreeMonthDates = () => {
        // Gather dates for an assumed 3-month long project
        const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
        const yesterdayString = `${yesterday.getFullYear()}-${(yesterday.getMonth() + 1).toString().padStart(2, '0')}-${yesterday.getDate().toString().padStart(2, '0')}`;
        const threemonthslater = new Date(yesterday.setMonth(yesterday.getMonth() + 3));
        const threemonthslaterString = `${threemonthslater.getFullYear()}-${(threemonthslater.getMonth() + 1).toString().padStart(2, '0')}-${threemonthslater.getDate().toString().padStart(2, '0')}`;
        return {
            date_start: yesterdayString,
            date_end: threemonthslaterString
        }
    }
    const submitProjectBuild = () => {
        try {
            if (projectType === "tetheros_assist") {
                setPage("generating")
                let effectiveDescription = projectDescription
                if (projectDescription.length > 2000) effectiveDescription = projectDescription.substring(0, 2000)
                setPage("generating")
                let taPayload = {
                    workspace_id: workspaceId,
                    project_name: projectName,
                    project_description: effectiveDescription
                }
                submitWorkspaceProjectIdeaTetherosAssist(taPayload)
                .then((res) => {
                    if (res.data) {
                        if (res.data.token_id) {
                            setJobTokenId(res.data.token_id)
                        } else {
                            return setPage("builderror")
                        }

                    } 
                    if (!res) return setPage("builderror")
                })
                .catch((err) => {
                    return setPage("timeout")
                })
                // build with Tetheros Assist
                return
            }

            if (projectType === "template") {
                setPage("generating")
                const projectDates = getThreeMonthDates()
                const attributes = {};
                attributes['description'] = projectDescription
                attributes['name'] = projectName
                attributes['active'] = true
                attributes['date_start'] = projectDates.date_start
                attributes['date_end'] = projectDates.date_end
                let payload = {
                    workspace_id: workspaceId,
                    is_published: true,
                    display_name: projectName,
                    attributes: attributes,
                    template_id: selectedTemplateId
                }
                const path = `/components/strategy/initiative`;
                api.createWorkspaceComponent(payload, path)
                .then( (res) => {
                    if (res.status === 200) {
                        history.push(`/workspaces/${workspaceId}/c/${res.data.response.component._id}`)
                        hideModal()
                        return
                    }
                })
                .catch((err) => {
                    return 
                })
                return
            }

            if (projectType === "blank") {
                setPage("generating")
                const projectDates = getThreeMonthDates()
                const attributes = {};
                attributes['description'] = projectDescription
                attributes['name'] = projectName
                attributes['active'] = true
                attributes['date_start'] = projectDates.date_start
                attributes['date_end'] = projectDates.date_end
                let payload = {
                    workspace_id: workspaceId,
                    is_published: true,
                    display_name: projectName,
                    attributes: attributes
                }
                // if (chosenTemplate !== "123" && isValidTemplateId(chosenTemplate)) payload['template_id'] = chosenTemplate
                const path = `/components/strategy/initiative`;
                api.createWorkspaceComponent(payload, path)
                .then( (res) => {
                    if (res.status === 200) {
                        history.push(`/workspaces/${workspaceId}/c/${res.data.response.component._id}`)
                        hideModal()
                    }
                })
                .catch((err) => {
                    return setPage("builderror")
                })
                return
            }

        } catch (error) {
            return hideModal()
        }
    }
    const proceedToTemplateDescription = () => {
        if (!selectedTemplateId) return setPage("builderror")
        try {
            const templateInfo = templates.find(t => t._id === selectedTemplateId)
            const description = templateInfo.config.description
            setProjectDescription(description)
            setPage("description")
        } catch (error) {
            return setPage("builderror")
        }
    }
    const updateJobQuery = () => {
        try {
            if (page !== "generating") return
            if (!jobTokenId) return
            getJobToken(jobTokenId)
            .then((res) => {
                if (!res.data) return setPage("builderror")
                if (res.data.status === "completed" && res.data.message) {
                    history.push(`/workspaces/${workspaceId}/c/${res.data.message}`)
                    hideModal()
                }
                if (res.data.status === "failed") return setPage("buildError")
            })
        } catch (error) {
            return setPage("builderror")
        }
    }
    useEffect(() => {
        updateBasicInfo()
    // eslint-disable-next-line
    }, [projectName])
    useEffect(() => {
        getComponentTemplates()
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (!jobTokenId) return
        if (page !== "generating") return
        updateJobQuery();
        const interval = setInterval(() => {
            updateJobQuery();
        }, 5000);
        return () => clearInterval(interval);
    // eslint-disable-next-line
    }, [jobTokenId]);
    return (
        <div className="modal-mgmt-background-overlay" style={{zIndex: 200}}>
            <div className="modal-mgmt-container modal-mgmt-container-creator">
                <div>
                    <ModalSimpleHeader hideModal={hideModal} title="Create Project" />

                    {/* BASIC INFORMATION */}
                    {page === "basic" &&
                    <div>
                        {/* PROJECT TYPE */}
                        <div className="modal-workstream-settings-setting-single modal-workstream-settings-setting-single-vertical">
                            <div className="modal-workstream-settings-setting-names">
                                <div className="modal-workstream-settings-setting-name">Project Type</div>
                            </div>
                        </div>
                        <div>
                            <ModalSimpleMultiRadioSelection options={projectTypeOptions} selectedOption={projectType} setSelectedOption={setProjectType} />
                        </div>

                        {/* PROJECT NAME */}
                        <div className="modal-workstream-settings-setting-single modal-workstream-settings-setting-single-vertical">
                            <div className="modal-workstream-settings-setting-names">
                                <div className="modal-workstream-settings-setting-name">Project Name</div>
                            </div>
                            <div className="modal-workstream-settings-setting-value">
                                <div className="modal-workstream-settings-text-input-container">
                                    <input onKeyUp={(e) => handleEnterKeyPress(e)} autoFocus={true} type="text" placeholder="Example Project" value={projectName} onChange={(e) => setProjectName(e.target.value)} />
                                </div>
                            </div>
                        </div>

                        <div className="modal-workstream-settings-buttons util-row util-row-reverse util-align-center" style={{gap: "8px"}}>
                            <ModalSimpleSingleButton isValid={hasBasicInfo} buttonText="Next" onclick={proceedFromBasicPage} />
                            <ModalSimpleSingleButton isValid={true} invertStyle={true} buttonText="Cancel" onclick={hideModal} />
                        </div>
                    </div>
                    }

                    {/* DESCRIPTION */}
                    {page === "description" && projectType === "tetheros_assist" &&
                    <div>
                        <div className="modal-workstream-settings-setting-single modal-workstream-settings-setting-single-vertical">
                            <div className="modal-workstream-settings-setting-names">
                                <div className="modal-workstream-settings-setting-name">Describe your project in a sentence or two. Provide as much detail as possible.</div>
                            </div>
                        </div>
                        <div className="wizard-inputs" style={{marginTop: "8px"}}>
                            <div className="wizard-input-basic">
                                <textarea style={{fontSize: "14px"}} maxLength={2000} rows={8} type="text" placeholder="Example: Brand redesign and strategic development for a local tech startup called Acme. The project includes digital review, asset development, and strategic roadmapping." value={projectDescription} onChange={(e) => setProjectDescription(e.target.value)} autoFocus={true} ></textarea>
                            </div>
                        </div>
                        <div className="modal-workstream-settings-buttons util-row util-row-reverse util-align-center" style={{gap: "8px"}}>
                            <ModalSimpleSingleButton isValid={true} buttonText="Create Project" onclick={submitProjectBuild} />
                            <ModalSimpleSingleButton isValid={true} invertStyle={true} buttonText="Back" onclick={() => setPage("basic")} />
                        </div>
                    </div>
                    }

                    {page === "description" && projectType === "blank" &&
                    <div>
                        <div className="modal-workstream-settings-setting-single modal-workstream-settings-setting-single-vertical">
                            <div className="modal-workstream-settings-setting-names">
                                <div className="modal-workstream-settings-setting-name">Project Description (optional)</div>
                            </div>
                        </div>
                        <div className="wizard-inputs" style={{marginTop: "8px"}}>
                            <div className="wizard-input-basic">
                                <textarea style={{fontSize: "14px"}} maxLength={2000} rows={8} type="text" placeholder="This project..." value={projectDescription} onChange={(e) => setProjectDescription(e.target.value)} autoFocus={true} ></textarea>
                            </div>
                        </div>
                        <div className="modal-workstream-settings-buttons util-row util-row-reverse util-align-center" style={{gap: "8px"}}>
                            <ModalSimpleSingleButton isValid={true} buttonText="Create Project" onclick={submitProjectBuild} />
                            <ModalSimpleSingleButton isValid={true} invertStyle={true} buttonText="Back" onclick={() => setPage("basic")} />
                        </div>
                    </div>
                    }

                    {page === "description" && projectType === "template" &&
                    <div>
                        <div className="modal-workstream-settings-setting-single modal-workstream-settings-setting-single-vertical">
                            <div className="modal-workstream-settings-setting-names">
                                <div className="modal-workstream-settings-setting-name">Project Description</div>
                            </div>
                        </div>
                        <div className="wizard-inputs" style={{marginTop: "8px"}}>
                            <div className="wizard-input-basic">
                                <textarea style={{fontSize: "14px"}} maxLength={2000} rows={8} type="text" placeholder="This project..." value={projectDescription} onChange={(e) => setProjectDescription(e.target.value)} autoFocus={true} ></textarea>
                            </div>
                        </div>
                        <div className="modal-workstream-settings-buttons util-row util-row-reverse util-align-center" style={{gap: "8px"}}>
                            <ModalSimpleSingleButton isValid={true} buttonText="Create Project" onclick={submitProjectBuild} />
                            <ModalSimpleSingleButton isValid={true} invertStyle={true} buttonText="Back" onclick={() => setPage("basic")} />
                        </div>
                    </div>
                    }
                    
                    {page === "templates" && templatesLoaded && templates.length > 0 &&
                    <div>
                        {/* PROJECT TEMPLATE */}
                        <div className="modal-workstream-settings-setting-single modal-workstream-settings-setting-single-vertical">
                            <div className="modal-workstream-settings-setting-names">
                                <div className="modal-workstream-settings-setting-name">Select Template</div>
                            </div>
                        </div>

                        <div style={{marginTop: "8px"}}>
                            <ModalToolSingleSelectRoleDropdownWithSearch selectedId={selectedTemplateId} allOptions={templates} setSelectedId={setSelectedTemplateId} displayAttributeName={"template_name"} />
                        </div>

                        <div className="modal-workstream-settings-buttons util-row util-row-reverse util-align-center" style={{gap: "8px"}}>
                            <ModalSimpleSingleButton isValid={selectedTemplateId} buttonText="Next" onclick={proceedToTemplateDescription} />
                            <ModalSimpleSingleButton isValid={true} invertStyle={true} buttonText="Back" onclick={() => setPage("basic")} />
                        </div>
                    </div>
                    }

                    {page === "templates" && !templatesLoaded &&
                    <div>
                        <div className="util-row util-col util-align-center" style={{paddingTop: "40px", paddingBottom: "40px", gap: "24px"}}>
                            <div className="wizard-onboarding-loader"></div>
                            <div>Templates are loading...</div>
                        </div>
                        <div className="modal-workstream-settings-buttons util-row util-row-reverse util-align-center" style={{gap: "8px"}}>
                            <ModalSimpleSingleButton isValid={true} invertStyle={true} buttonText="Back" onclick={() => setPage("basic")} />
                        </div>
                    </div>
                    }

                    {page === "templates" && templatesLoaded && templates.length === 0 &&
                    <div>
                        <div className="util-row util-col util-align-center" style={{paddingTop: "40px", paddingBottom: "40px", gap: "24px"}}>
                            <div>No templates available</div>
                        </div>
                        <div className="modal-workstream-settings-buttons util-row util-row-reverse util-align-center" style={{gap: "8px"}}>
                            <ModalSimpleSingleButton isValid={true} invertStyle={true} buttonText="Back" onclick={() => setPage("basic")} />
                        </div>
                    </div>
                    }

                    {page === "generating" &&
                    <div>
                        <div className="util-row util-col util-align-center" style={{paddingTop: "40px", paddingBottom: "40px"}}>
                            <div className="wizard-onboarding-loader"></div>
                        </div>
                    </div>
                    }

                    {page === "timeout" &&
                    <div>
                        <div className="util-row util-col util-align-center" style={{paddingTop: "40px", paddingBottom: "40px"}}>
                            <div className="wizard-onboarding-loader"></div>
                            <div style={{marginTop: "60px"}}>Your project is being built. You may continue using Tetheros.</div>
                        </div>
                        <div className="modal-workstream-settings-buttons">
                            <ModalSimpleSingleButton isValid={true} buttonText="Continue" onclick={hideModal} />
                        </div>
                    </div>
                    }

                    {page === "builderror" &&
                    <div>
                        <div className="util-row util-col util-align-center" style={{paddingTop: "40px", paddingBottom: "40px"}}>
                            <div>Oops! Something went wrong - please try again</div>
                        </div>
                        <div className="modal-workstream-settings-buttons">
                            <ModalSimpleSingleButton isValid={true} buttonText="Continue" onclick={hideModal} />
                        </div>
                    </div>
                    }

                </div>
            </div>
        </div>
    )
}
