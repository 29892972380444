import React from 'react'

export default function CProgressBar({percentage, height, replacementText, extraStyles, replacementStrokeWidth}) {
    const getPercentage = () => {
        const tooLow = !Number.isFinite(+percentage) || percentage < 0;
        const tooHigh = percentage > 100;
        return tooLow ? 0 : tooHigh ? 100 : +percentage;
    };
    const getText = () => {
        if (replacementText) return replacementText
        if (percentage === 100) return "100%"
        return `${getPercentage().toFixed(0)}%`
    }
    const getColor = () => {
        if (percentage === 100) {
            return "#049921"
        } else {
            return "#174061"
        }
    }
    const buildCircle = (color, percent) => {
        const radius = height/2*.8;
        const circumference = 2*Math.PI*radius;
        const strokePercentage = ((100-percent) * circumference)/100;
        let strokeWidth = "4px"
        if (replacementStrokeWidth) strokeWidth = replacementStrokeWidth 
        return (
            <circle 
                r={radius} 
                cx={height*1.5} cy={height*.5} 
                fill="transparent" 
                stroke={strokePercentage !== circumference ? color : ""}
                strokeWidth={strokeWidth}
                strokeDasharray={circumference}
                strokeDashoffset={percent ? strokePercentage : 0}
                strokeLinecap="round"
            />
        )
    }
    return (
        <svg width={height} height={height} style={{minWidth: height}}> 
            <g transform={`rotate(-90 ${height} ${height})`}>
                {percentage === 0 ? (
                    <>{buildCircle("lightgrey")}</>
                ) : (
                    <>{buildCircle(getColor(), percentage)}</>

                )}
            </g>
            <text
                x="50%"
                y="50%"
                dominantBaseline="central"
                textAnchor="middle"
                fontSize="14px" style={extraStyles}
            >
                {getText()}
            </text>
        </svg>
    )
}
