import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Api from "../../common/APIUtils"
import ComponentMainComments from './ComponentMainComments';
import { hasPermission } from '../../common/Helpers';
import { parseISO, subHours, format } from 'date-fns'
import ModalUpdateKpi from '../modals/ModalUpdateKpi';
import ModalKpiSettings from '../modals/ModalKpiSettings';
import { Chart } from 'react-google-charts';
import DataTableRow from '../tables/DataTableRow';
import ComponentMainDescription from './ComponentMainDescription';
import WorkspaceCompHashtags from '../gadgets/WorkspaceCompHashtags';
import { sortByProperty } from '../../common/Helpers';
import WorkspaceCompInitiativeAlignmentItem from './initiatives/WorkspaceCompInitiativeAlignmentItem';

export default function WorkspaceCompKpi({component, permissions, team, componentData, fetchComponent, workspaceName, workspace}) {
    let history = useHistory();
    const api = new Api();
    const titleLastUpdatedTimestamp = useRef(false);
    const [timeFrameHours, setTimeFrameHours] = useState(() => { return -1 })
    const [tableLimit, setTableLimit] = useState(() => { return 10 })
    const [isShowingUpdateMeasurement, setIsShowingUpdateMeasurement] = useState(() => { return false })
    const [isShowingKpiSettings, setIsShowingKpiSettings] = useState(() => { return false })
    const [proposedOwnerId, setProposedOwnerId] = useState(() => { return component.owner_id ? component.owner_id : "" })
    const [displayName, setDisplayName] = useState(() => { return component.display_name ? component.display_name : ""})
    const [timeSeriesData, setTimeSeriesData] = useState(() => { return [] })
    const [timeSeriesOptions, setTimeSeriesOptions] = useState(() => { return false })
    const [canFetchComponent, setCanFetchComponent] = useState(() => { return true })
    const [tags, setTags] = useState(() => { return [] })
    const [alignedGoals, setAlignedGoals] = useState(() => { return [] })
    const updateKpi = (payload) => {
        if (!component) return
        const path = `/workspace/components/${component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
            updateTimeSeriesData();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const getAdditionalFontSizeForValue = () => {
        try {
            const currentValString = String(getCurrentValue())
            if (currentValString.length > 14) return { fontSize: "24px" }
            if (currentValString.length > 10) return { fontSize: "28px" }
            if (currentValString.length > 6) return { fontSize: "36px"}
            return {}
        } catch (error) {
            return {}
        }
    }
    const getTimeSeriesData = () => {
        let d = []
        if (!component) return []
        const attributeUpdates = component.attribute_updates
        if (!attributeUpdates) return []

        const justValCurrentUpdates = attributeUpdates.filter(update => { return update.attribute_name === 'val_current' })

        const startingDataPoint = [parseISO(component.created), Number(component.attributes.val_start) ]
        d.push(startingDataPoint)
        
        for (let i = 0; i < justValCurrentUpdates.length; i++) {
            const entry = justValCurrentUpdates[i];
            d.push([parseISO(entry.time_stamp), Number(entry.attribute_value)])
        }
        d.unshift(["Date", component.display_name])
        return d
    }
    const updateTimeSeriesData = () => {
        setTimeSeriesData(getTimeSeriesData())
    }
    const updateTimeSeriesOptions = () => {
        try {
            // Determine the min value for hAxis (start time)
            const values = timeSeriesData.slice(1).map(item => item[1]); // Assuming your values are in the second position
            const maxValue = Math.max(...values);
            const minValue = Math.min(...values);
        
            // Determine a reasonable vAxis range
            let vAxisRangeBuffer = (maxValue - minValue) * 0.2; // 20% buffer
            let min = minValue - vAxisRangeBuffer
            let max = maxValue + vAxisRangeBuffer
            if (min > 0) min = 0
            if ((minValue - min) > (vAxisRangeBuffer)) max = maxValue + (minValue - min)

            let dateRangeStart = subHours(new Date(), timeFrameHours)
            if (timeFrameHours < 0) dateRangeStart = parseISO(component.created)
            let dateRangeEnd = subHours(new Date(), 0)
        
            // Determine the format for hAxis based on the range of data
            let timeFrameFormatd;
            if (timeFrameHours === -1) {
                timeFrameFormatd = 'MM/dd/yy';
            } else if (timeFrameHours <= 1) {
            timeFrameFormatd = 'h:mm a';
            } else if (timeFrameHours <= 24) {
            timeFrameFormatd = 'h a';
            } else if (timeFrameHours <= 24 * 7) {
            timeFrameFormatd = 'EEE M/dd';
            } else {
            timeFrameFormatd = 'M/dd/yy';
            }
            const options = {
                legend: { position: 'none' },
                hAxis: {
                    viewWindow: {
                        min: dateRangeStart,
                        max: dateRangeEnd
                    },
                    format: timeFrameFormatd,
                    textStyle: { color: '#757575' },
                    baselineColor: 'transparent',
                    gridlines: { color: 'transparent' },
                },
                vAxis: {
                    textStyle: { color: '#757575' },
                    format: '0',
                    baselineColor: 'transparent',
                    gridlines: {
                        color: '#e9e9e9',
                        count: 4,
                    },
                        minorGridlines: {
                            color: 'transparent',
                    },
                    viewWindow: {
                        max: max,
                        min: min,
                    },
                },
                chartArea: {
                    width: '80%',
                    height: '70%',
                    top: 50,
                    left: 100, 
                    right: 50, 
                    bottom: 50,
                },
                colors: ['#174061'],
                pointSize: 5,
                lineWidth: 2.5,
                curveType: 'none',
                tooltip: {
                    isHtml: true,
                    trigger: 'focus'
                },
                animation: {
                    startup: true,
                    easing: 'linear',
                    duration: 100,
                },
                enableInteractivity: true,
            }
            setTimeSeriesOptions(options)
        } catch (error) {
             setTimeSeriesOptions(false)   
        }
    }
    const updateDescription = (newDescription) => {
        updateDescriptionWithPayload({
            attributes: {
                description: newDescription
            }
        })
    }
    const taskTitleFieldHandler = (event) => {
        if (["Enter", "NumpadEnter"].includes(event.code)) {
            event.preventDefault()
            event.target.blur()
        }
    }
    const getDisplayNamePlaceholder = () => {
        if (!component) return "Enter a KPI name"
        if (!component.display_name) return "Enter a KPI name"
        return component.display_name
    }
    const updateDisplayName = () => {
        if (!component) return
        if (!component.display_name) return
        if (displayName === "" | displayName === component.display_name) return
        const payload = {
            display_name: displayName
        }
        const path = `/workspace/components/${component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const updateDescriptionWithPayload = (payload) => {
        const path = `/workspace/components/${component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const updateAlignedGoals = () => {
        try {
            if (!workspace) return
            if (!componentData) return
            const parentBonds = componentData.parent_components
            if (parentBonds.length === 0) return
            const allWorkspaceComponents = workspace.components
            if (allWorkspaceComponents.length === 0) return
            const parentIds = parentBonds.map(b => b.parent_id)
            const VALID_ALIGNMENT_COMPONENTS = ["kresult", "goal"]
            const alignedParentGoalsUnsorted = allWorkspaceComponents.filter((c) => {
                if (!parentIds.includes(c._id)) return false
                if (c.status !== "active") return false
                if (VALID_ALIGNMENT_COMPONENTS.includes(c.name)) return true
                return false
            })
            let sortedAlignedParentGoals = sortByProperty(alignedParentGoalsUnsorted, 'display_name')
            setAlignedGoals(sortedAlignedParentGoals)
        } catch (error) {
            return
        }
    }
    const updateOwner = () => {
        if (proposedOwnerId !== component.owner_id) {
            updateKpi({
                owner_id: proposedOwnerId
            })
        }
    }
    const getMetricDisplayValue = () => {
        const currentValue = component.attributes.val_current;
        // if no units, display raw count
        if (!component.attributes.units) return currentValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        const unit = component.attributes.units;
        // if a dollar sign, return as a currency
        if (unit === "$") {
            // Create our number formatter.
            var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                // minimumFractionDigits: 0,
                // maximumFractionDigits: 0
            });
            
            return formatter.format(currentValue);
        }
        if (unit === "%") return `${(Math.round(currentValue * 100) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${unit}`

        return currentValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    const getWorkspaceName = () => {
        if (!workspaceName) return ""
        return workspaceName
    }
    const getCurrentValue = () => {
        if (!component) return 0
        if (!component.attributes) return 0
        if (!component.attributes.val_current) return 0
        return getMetricDisplayValue()
    }
    const formatParentRowData = (datapoint) => {
        return [
            {
                width: "lg",
                content: (
                    <div style={{paddingTop: "4px", paddingBottom: "4px"}}>{format(datapoint[0], 'PPpp')}</div>
                ),
                _id: `${datapoint[0]}-time`
            },
            {
                width: "sm",
                content: <div>{datapoint[1]}</div>,
                _id: `${datapoint[0]}-val`
            }
        ]
    }
    const deleteKpi = () => {
        try {
            const payload = {
                componentId: component._id
            }
            api.deleteWorkspaceComponent(payload)
            .then((res) => {
                history.push(`/workspaces/${component.workspace_id}/measurements`)
            })
            .catch((err) => {
                console.log(err)
            })
        } catch (error) {
            console.log(error)
        }
    }
    const getComments = () => {
        if (!component) return []
        if (!component.comments) return []
        return component.comments
    }
    const getDateFilterClass = (val) => {
        if (val === timeFrameHours) return "workcomp-page-kpi-graph-date-filter-selected"
        return ""
    }
    const canShowMoreTableData = () => {
        if (tableLimit > 10) return false
        if (timeSeriesData.length > (tableLimit + 1)) return true
    }
    useEffect(() => {
        if (!componentData.component) return
        if (!canFetchComponent) return
        if (Date.now() - titleLastUpdatedTimestamp.current > 900) setDisplayName(component.display_name ? component.display_name : "")
        setProposedOwnerId(component.owner_id ? component.owner_id : "")
    // eslint-disable-next-line
    }, [componentData])
    useEffect(() => {
        updateAlignedGoals()
    // eslint-disable-next-line
    }, [componentData, workspace])
    useEffect(() => {
        if (!componentData) return
        if (!componentData.component) return
        if (!componentData.component.component_tags) {
            setTags([])
            return
        }
        setTags(componentData.component.component_tags)
    }, [componentData])
    useEffect(() => {
        setCanFetchComponent(false)
        titleLastUpdatedTimestamp.current = Date.now()
        const timer = setTimeout(() => {
            if (Date.now() - titleLastUpdatedTimestamp.current < 900) return
            setCanFetchComponent(true)
            updateDisplayName()
        }, 1000)
        return () => clearTimeout(timer)
    // eslint-disable-next-line
    }, [displayName])
    useEffect(() => {
        updateOwner({
        })
    // eslint-disable-next-line
    }, [proposedOwnerId])
    useEffect(() => {
        updateTimeSeriesData()
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        updateTimeSeriesOptions()
    // eslint-disable-next-line
    }, [timeFrameHours])
    useEffect(() => {
        updateTimeSeriesData()
    // eslint-disable-next-line
    }, [component])
    return (
        <div className="workcomp-page-container" style={{marginTop: "0.4em"}}>
            {/* Modals */}
            {isShowingUpdateMeasurement && <ModalUpdateKpi deleteKpi={deleteKpi} updateKpi={updateKpi} getMetricDisplayValue={getMetricDisplayValue} team={team} component={component} setIsShowingUpdateMeasurement={setIsShowingUpdateMeasurement}/>}
            {isShowingKpiSettings && <ModalKpiSettings deleteKpi={deleteKpi} updateKpi={updateKpi}  component={component} setIsShowingKpiSettings={setIsShowingKpiSettings} />}

            <div className="component-page-outer" style={{marginTop: "0.4em"}}>


                <div className="component-page-row" style={{alignItems: "center", justifyContent: "space-between", marginTop: "0.4em"}}>
                    <div className="component-page-row" style={{alignItems: "center", justifyContent: "flex-start", gap: "2em"}}>
                        <div className="component-view-title-meta-breadcrumb">
                            <span><Link className="unlink component-view-title-meta-breadcrumb-link" to={`/workspaces/${component.workspace_id}`}>{getWorkspaceName()}</Link></span>
                            <span>&gt;</span>
                            <span><Link className="unlink component-view-title-meta-breadcrumb-link" to={`/workspaces/${component.workspace_id}/measurements`}>Measurements</Link></span>
                            <span>&gt;</span>
                            <span>{component.display_name}</span>
                        </div>
                    </div>
                    <div></div>
                </div>

                <div className="component-page-title component-page-row" style={{alignItems: "center", gap: "0.5em"}}>
                    {hasPermission(permissions, 'component_update') ? (
                        <input type="text" onKeyUp={(e) => taskTitleFieldHandler(e)} className="workcomp-page-task-title-editable workcomp-page-task-title-editable-initiative" placeholder={getDisplayNamePlaceholder()} value={displayName} onChange={(e) => setDisplayName(e.target.value)} />
                    ) : (
                        <div className="workcomp-page-task-title-editable workcomp-page-task-title-editable-initiative" style={{border: "2px solid transparent"}}>{displayName}</div>
                    )}
                </div>
                {workspace && <WorkspaceCompHashtags tags={tags} workspaceId={workspace._id}/>}
                
                <div className="workcomp-page-main-display-split-container" style={{marginTop: "20px"}}>
                    <div className="workcomp-page-main-display-split-primary">

                        <div>
                            <div className="component-page-initiative-section-heading" >KPI Progress</div>
                            <div className="workcomp-page-kpi-shadow-box">
                                <div className="workcomp-page-kpi-graph">
                                    <div style={{textAlign: "center", fontWeight: 600}}>Time Frame</div>
                                    <div className="workcomp-page-kpi-graph-date-filters">
                                        <span onClick={() => setTimeFrameHours(1)} className={getDateFilterClass(1)}>1H</span>
                                        <span onClick={() => setTimeFrameHours(12)} className={getDateFilterClass(12)}>12H</span>
                                        <span onClick={() => setTimeFrameHours(24)} className={getDateFilterClass(24)}>1D</span>
                                        <span onClick={() => setTimeFrameHours(24*7)} className={getDateFilterClass(24*7)}>1W</span>
                                        <span onClick={() => setTimeFrameHours(24*365/12)} className={getDateFilterClass(24*365/12)}>1M</span>
                                        <span onClick={() => setTimeFrameHours(-1)} className={getDateFilterClass(-1)}>All</span>
                                    </div>

                                    <div>
                                        {timeSeriesOptions && <Chart chartType="LineChart" height="400px" data={timeSeriesData} options={timeSeriesOptions} />}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {alignedGoals.length > 0 &&
                        <div>
                            <div className="component-page-initiative-section-heading">Open Goals</div>
                            <div className="workcomp-page-kpi-shadow-box">
                                <div className="workcomp-page-kpi-aligned-goals">
                                    {alignedGoals.map((c) => (
                                        <WorkspaceCompInitiativeAlignmentItem key={c._id} component={c} permissions={permissions} removeMarker={false}/>
                                    ))}
                                </div>
                            </div>
                        </div>
                        }

                        <div>
                            <div className="component-page-initiative-section-heading" >Data</div>
                            <div className="workcomp-page-kpi-shadow-box">
                                <div className="data-table-header-container" style={{marginTop: "1em"}}>
                                    <span className="data-table-col-lg">Time</span>
                                    <span className="data-table-col-sm">Value</span>
                                </div>
                                {Array.from(timeSeriesData).slice(1).reverse().slice(0,tableLimit).map((datapoint) => (
                                    <DataTableRow key={format(datapoint[0], 'T')} rowData={formatParentRowData(datapoint)}/>
                                ))}
                                {canShowMoreTableData() && <div style={{marginTop: "8px", cursor: "pointer", textDecoration: "underline", color: "#2973af"}} onClick={() => setTableLimit(timeSeriesData.length)}>Show more</div>}
                            </div>
                        </div>
                    </div>
                    <div className="workcomp-page-main-display-split-sidebar">
                        <div>
                            <div className="component-page-initiative-section-heading">Current</div>
                            <div className="workcomp-page-kpi-shadow-box">
                                <div className="workcomp-page-kpi-value" style={getAdditionalFontSizeForValue()}>{getCurrentValue()}</div>
                                {hasPermission(permissions, 'component_update') ? (
                                    <div className="component-page-row workcomp-page-kpi-buttons">
                                        <div className="workcomp-page-kpi-button workcomp-page-kpi-button-update" onClick={() => setIsShowingUpdateMeasurement(true)}>Update</div>
                                        <div className="workcomp-page-kpi-button workcomp-page-kpi-button-settings" onClick={() => setIsShowingKpiSettings(true)}>Settings</div>
                                    </div>
                                ) : (
                                    <div className="component-page-row workcomp-page-kpi-buttons"></div>
                                )}
                            </div>
                        </div>

                        <div>
                            <div className="component-page-initiative-section-heading">Description</div>
                            <div className="workcomp-page-kpi-shadow-box">
                                <div className="component-page-initiative-summary-section-container">
                                    <div className="component-page-initiative-summary-item-description-container">
                                        <ComponentMainDescription permissions={permissions} component={component} updateDetails={updateDescription} fontSize="14" team={team}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="component-page-initiative-section-heading">Discussion</div>
                            <div className="workcomp-page-kpi-shadow-box">
                                <div className="component-view-summary-card-lg component-view-summary-card-borderless">
                                    <ComponentMainComments team={team} fetchComponent={fetchComponent} comments={getComments()} componentId={component._id}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
