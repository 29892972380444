import React from 'react'
import { useEffect, useRef } from 'react'

export default function BlurProofInput({children, inputRef}) {
    const lastNameTypeTime = useRef(null)
    const updateNameTypeTime = (e) => {
        if (e.code === "Tab") return lastNameTypeTime.current = Date.now() - 501
        lastNameTypeTime.current = Date.now()
    }
    const dealWithBlur = () => {
        if (!lastNameTypeTime.current) return
        if (Date.now() - lastNameTypeTime.current > 500) return
        affixFocus()
    }
    const affixFocus = () => {
        if (!inputRef.current) return
        inputRef.current.focus()
    }
    useEffect(() => {
        const instance = inputRef.current
        instance.addEventListener("keydown", updateNameTypeTime)
        instance.addEventListener("blur", dealWithBlur)
        return () => {
            instance.removeEventListener("keydown", updateNameTypeTime)
            instance.removeEventListener("blur", dealWithBlur)
        }
    // eslint-disable-next-line
    }, [inputRef])
    return (
        <div className="gadget-blur-proof-input">
            {children}
        </div>
    )
}
