import React from 'react'
import Api from '../../common/APIUtils';
import { useReducer, useState } from 'react';
import ModalCreateNewTaskMegabarMultiple from '../modals/ModalCreateNewTaskMegabarMultiple';

export default function CreateNewTaskMegabar({parentComponent, existingPositions, fetchParent}) {
    const api = new Api()
    const [proposedTaskList, setProposedTaskList] = useState(() => { return false })
    const [isShowingMultiTask, setIsShowingMultiTask] = useState(() => { return false })
    const [proposedTask, proposedTaskDispatch] = useReducer((state, action) => {
        if (action.type === "update_name") return { ...state, name: action.value }
        if (action.type === "reset") return { name: "" }
    }, {name: ""})
    const getPosition = () => {
        try {
            if (!existingPositions) return -1
            const onlyNumbers = existingPositions.filter((n) => {
                if (n === 0) return true
                return parseInt(n)
            })
            if (onlyNumbers.length === 0) return -1
            const min = Math.min(...onlyNumbers)
            if (!min && min !== 0) return -1
            return min - 1
        } catch (error) {
            return -1
        }
    }
    const createMultipleTasks = () => {
        setIsShowingMultiTask(false)
        for (let i = 0; i < proposedTaskList.length; i++) {
            const taskName = proposedTaskList[i];
            let payload = {
                display_name: taskName,
                position: i + 1
            }
            let attributes = {
                active: true,
                parent: parentComponent._id,
            }
            if (parentComponent.name === "board") {
                attributes['state'] = parentComponent.attributes.column_id_new
            }
            payload['workspace_id'] = parentComponent.workspace_id;
            payload['attributes'] = attributes
            createTask(payload)
        }
        setProposedTaskList([])
        proposedTaskDispatch({type: "reset"})
    }
    const extractListItems = (str) => {
        try {
            const splitLines = str.split('\n');
            const lines = splitLines.map(l => l.replace('\r', ''))
            let items = [];
            // Filter the list for things that suck
            for (let i = 0; i < lines.length; i++) {
                const line = lines[i];
                if (!line) continue
                if (line.length > 100) continue
                // eslint-disable-next-line
                const regex = /^([a-zA-Z]|\d+|\*|\-|\>)\s*\.?\)?\s+/;
                const cleanedStr = line.replace(regex, '');
                if (!cleanedStr) continue
                const finalItem = cleanedStr.trim()
                if (items.includes(finalItem)) continue
                items.push(finalItem)
            }
            // Return the list of items
            return items.slice(0, 50);
        } catch (error) {
            return []
        }
    }
    const getPastedContent = (e) => {
        try {
            const lineContent = e.clipboardData.getData('text/plain')
            const extractedItems = extractListItems(lineContent)
            if (extractedItems && extractedItems.length > 1) {
                setProposedTaskList(extractedItems)
                setIsShowingMultiTask(true)
            }
        } catch (error) {
            return
        }

    }
    const createTask = (payload) => {
        api.createWorkspaceComponent(payload, `/components/work/task`)
        .then( (res) => {
            fetchParent()
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const submitNewTask = () => {
        if (!proposedTask.name) return
        if (!parentComponent) return
        if (!fetchParent) return
        let payload = {
            display_name: proposedTask.name,
            position: getPosition()
        }
        let attributes = {
            active: true,
            parent: parentComponent._id,
        }
        if (parentComponent.name === "board") {
            attributes['state'] = parentComponent.attributes.column_id_new
        }
        payload['workspace_id'] = parentComponent.workspace_id;
        payload['attributes'] = attributes
        createTask(payload)
        // const path = `/components/work/task`;
        // api.createWorkspaceComponent(payload, path)
        // .then( (res) => {
        //     fetchParent()
        // })
        // .catch((err) => {
        //     console.log(err);
        // })
        proposedTaskDispatch({type: "reset"})
    }
    const handleKeyPress = (e) => {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
            if (isShowingMultiTask) return createMultipleTasks()
            return submitNewTask()        
        }
    }
    return (
        <div className="gadget-create-task-megabar-container">
            {isShowingMultiTask && <ModalCreateNewTaskMegabarMultiple hideModal={() => setIsShowingMultiTask(false)} createMultipleTasks={createMultipleTasks} proposedTaskList={proposedTaskList}/> }
            <input type="text" placeholder="Enter task name" value={proposedTask.name} onChange={(e) => proposedTaskDispatch({ type: "update_name", value: e.target.value })} onKeyDown={(e) => handleKeyPress(e)} onPaste={(e) => getPastedContent(e) }/>
            <span onClick={submitNewTask}>Add to {parentComponent.name}</span>
        </div>
    )
}
