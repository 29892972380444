import React from 'react'
import { useState, useEffect } from 'react';
import AccountBilling from '../accounts/AccountBilling';

export default function NetworkBilling({ userData, network }) {
    const [isLegacyUsageBased, setIsLegacyUsageBased] = useState(() => { return false })
    const isProbablyNetworkOwner = () => {
        try {
            if (!network) return false
            const userId = localStorage.getItem("gId")
            if (network.owner_id === userId) return true
            return false
        } catch (error) {
            return false
        }
    }
    const updateLicenseUnderstanding = () => {
        try {
            const USAGE_BASED_TIERS = ['founder', 'pro', 'basic']
            console.log(userData.license_tier.toLowerCase())
            if (USAGE_BASED_TIERS.includes(userData.license_tier.toLowerCase())) return setIsLegacyUsageBased(true)
            setIsLegacyUsageBased(false)
        } catch (error) {
            console.log(error)
            return setIsLegacyUsageBased(false)
        }
    }
    useEffect(() => {
        updateLicenseUnderstanding()
    // eslint-disable-next-line
    }, [])
    return (
        <div>
            {!isProbablyNetworkOwner() && <div style={{width: "100%", padding: "20px", paddingTop: "120px", textAlign: "center", fontSize: "14px", fontWeight: 500}}>You do not have permission to view this</div> }
            {isProbablyNetworkOwner() && !isLegacyUsageBased && 
            <div style={{width: "100%", padding: "20px", boxSizing: "border-box"}}>
                <AccountBilling userData={userData} />
            </div>
            }
            {isProbablyNetworkOwner() && isLegacyUsageBased && 
                <div style={{width: "100%", padding: "20px", paddingTop: "120px", textAlign: "center", fontSize: "14px", fontWeight: 500}}>You are on an unsupported version of Tetheros. Please email support@tetheros.com if you have any questions.</div>
            }
        </div>
    )
}
