import React from 'react'
import { useState } from 'react'
import { BiChevronDown, BiChevronRight } from 'react-icons/bi'

export default function CollapsibleListSection({children, title, subtitle, count}) {
    const [isCollapsed, setIsCollapsed] = useState(() => { return false })
    return (
        <div className="gadget-collapsible-list-section">
            <div className="gadget-collapsible-list-section-headings">
                {!isCollapsed && <BiChevronDown onClick={() => setIsCollapsed(true)}/>}
                {isCollapsed && <BiChevronRight onClick={() => setIsCollapsed(false)}/>}
                <div>
                    <div className="gadget-collapsible-list-section-heading-decoration-row">
                        <h4>{title}</h4>
                        {count > 0 && <span>{count}</span>}
                    </div>
                    {subtitle && <p>{subtitle}</p> }
                </div>
            </div>
            {!isCollapsed && children}
        </div>
    )
}
