exports.PRICING_TABLE = [
    // {
    //     _id: "social",
    //     name: "Social",
    //     subtitle: "Connect with others",
    //     cost_annual: "0",
    //     cost_monthly: "0",
    //     has_network: false,
    //     has_channels: false,
    //     has_priority_support: false,
    //     allowed_network_users: 0,
    //     cta_text: "Sign Up"
    // },
    {
        _id: "team",
        name: "Team",
        subtitle: "Organize your operations",
        cost_annual: "869",
        cost_monthly: "79",
        has_network: true,
        has_channels: false,
        has_priority_support: true,
        allowed_network_users: 10,
        cta_text: "Start 7-Day Trial"
    },
    {
        _id: "startup",
        name: "Startup",
        subtitle: "Achieve your mission with clarity",
        cost_annual: "4169",
        cost_monthly: "379",
        has_network: true,
        has_channels: true,
        has_priority_support: true,
        allowed_network_users: 50,
        cta_text: "Start 7-Day Trial"
    },
    {
        _id: "business",
        name: "Business",
        subtitle: "Total alignment at scale",
        cost_annual: "23639",
        cost_monthly: "2149",
        has_network: true,
        has_channels: true,
        has_priority_support: true,
        allowed_network_users: 300,
        cta_text: "Start 7-Day Trial"
    },
]