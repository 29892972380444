import React from 'react'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

export default function SidebarSection({sectionData, selected}) {
    const [elements, setElements] = useState(() => {
        return sectionData.items;
    })

    useEffect(() => {
        setElements(sectionData.items)
    }, [sectionData])

    const getClassNameForSectionItem = (key) => {
        // console.log(`Checking ${key} for match against ${selected}`)
        if (key === selected) {
            return "sidebar-left-section-item sidebar-left-section-item-selected"
        } else {
            return "sidebar-left-section-item"
        }
    }
    const getToolTipText = (item) => {
        if (item.progress || item.progress === 0) return `${item.title} (${Math.floor(Math.round(item.progress*100))}%)`
        return item.title
    }
    return (
        <>
        {elements.length > 0 ? (
        <div className="sidebar-left-section">
            {/* Section Header */}
            <div className="sidebar-left-section-heading">
                <div className="sidebar-left-section-heading-left">
                    <div className="sidebar-left-section-heading-text">{sectionData.title}</div>
                </div>
            </div>

            {/* Section Items */}
            <div className="sidebar-left-section-items">
                {elements.map((item) => (
                    <Link key={item.key} className="unlink" to={item.link}>
                        <div className={getClassNameForSectionItem(item.key)}>
                            <div className="sidebar-left-section-item-tooltip">{getToolTipText(item)}</div>
                            <div className="sidebar-left-section-item-title">
                                <div className="sidebar-left-section-item-text">{item.title}</div>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
        ) : (
            <>
            </>
        )}
        </>
    )
}
