import React from 'react'
import ProfPicCircle from '../profiles/ProfPicCircle';
import { useState } from 'react'

export default function NewPostComment({postComment, comment, setComment}) {
    const [proposedDescriptionRows, setProposedDescriptionRows] = useState(() => { return 2 })
    const updateDescriptionRows = (e) => {
        const MINIMUM_ROW_COUNT = 2;
        const breaks = e.target.value.split(/\r\n|\r|\n/).length + 2
        setProposedDescriptionRows(Math.max(breaks, MINIMUM_ROW_COUNT))
    }
    const submitComment = () => {
        if (!comment) return
        setProposedDescriptionRows(2)
        postComment()
    }
    return (
        <div className="project-post-comment-form">
            <ProfPicCircle height={42} src={localStorage.getItem("gProfPicUrl")} userId={localStorage.getItem("gId")} userName={localStorage.getItem("gFullName")}  />
            <div style={{flexGrow: 1}}>
                <textarea rows={proposedDescriptionRows} onKeyDown={(e) => updateDescriptionRows(e)} value={comment} type="text" placeholder="Respond to post" onChange={(e) => setComment(e.target.value)}></textarea>
                <div style={{display: "flex"}}>
                    <div onClick={submitComment} className="project-post-comment-content-button-submit">POST</div>
                </div>
                
            </div>
        </div>
    )
}
