import React from 'react'
import { useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";

export default function Redirect({authenticated, specificPath}) {
    let history = useHistory();
    const location = useLocation()
    const handleRedirect = () => {
        // Redirects
        if (specificPath) {
            if (location.pathname === specificPath) return
            return history.push(specificPath)
        }
        if (location.pathname === '/home') return history.push('/login')
        if (location.pathname === '/') return history.push('/welcome')
        const intendedDestination = encodeURIComponent(location.pathname + location.search);
        return history.push(`/login?redirect=${intendedDestination}`);
    }
    const handleRedirectAuth = () => {
        if (specificPath) return history.push(specificPath)
        if (location.pathname === '/') return history.push('/home')
        return history.push('/home')
    }
    useEffect(() => {
        if (authenticated === null) return // otherwise we can't trust the value of authenticated when user refreshes browser
        if (authenticated) {
            handleRedirectAuth()
            return
        }
        handleRedirect()
    // eslint-disable-next-line
    },[authenticated])
    return (
    <div></div>
    )
}
