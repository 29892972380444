import React from 'react'
import '../../styles/gadgets.css'

export default function ToggleSwitch({isChecked, setIsChecked}) {
    return (
    <label className="gadget-switch">
        <input type="checkbox" checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)}/>
        <span className="gadget-slider gadget-round"></span>
    </label>
    )
}
