import React from 'react'
import { useState, useEffect } from "react";
import Api from '../../common/APIUtils';
import TemplateLibrary from '../templates/TemplateLibrary';

export default function WorkspaceTemplate({workspaceId, components, team, workspace, permissions}) {
    const api = new Api();
    const [templates, setTemplates] = useState(() => { return [] })
    const [isLoadingTemplates, setIsLoadingTemplates] = useState(() => { return true })
    const fetchTemplates = () => {
        const payload = {
            workspaceId: workspaceId,
            type: 'all'
        }
        api.getComponentTemplates(payload)
        .then((res) => {
            if (res.data) {
                setIsLoadingTemplates(false)
                setTemplates(res.data.response.templates)
            }
        })
        .catch((err) => {
            console.log(err);
        })  
    }
    useEffect(() => {
        fetchTemplates()
    // eslint-disable-next-line
    }, [workspaceId, workspace])
    return (
        <div className="network-page" style={{width: "100%"}}>
            <div className="workspace-library-section-header" style={{paddingBottom: "20px"}}>
                <h1>Templates</h1>
                <p>Use templates to automatically setup your workspace</p>
            </div>
            <TemplateLibrary isLoadingTemplates={isLoadingTemplates} templates={templates} team={team} workspace={workspace} network={false} fetchTemplates={fetchTemplates} />
        </div>
    )
}