import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { FaCheck, FaChevronLeft } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { PRICING_TABLE } from '../../config/Pricing'
import LoadingSymbolGrid from '../gadgets/LoadingSymbolGrid';

import { getCurrentPlanInformation, getCheckoutLinkForPlan, getManagementLinkForPlan } from '../../common/Api';

export default function AccountPlans({userData}) {
    let history = useHistory();
    const [isAnnual, setIsAnnual] = useState(() => { return false })
    const [currentTier, setCurrentTier] = useState(() => { return "social" })
    const [isLoading, setIsLoading] = useState(() => { return true })
    const [isError, setIsError] = useState(() => { return false })
    const handleToggle = (cycle) => {
        setIsAnnual(cycle);
    };
    const answerCallToAction = (planId) => {
        // Determine if trial or not
        let isTrial = false
        if (userData.license_is_trial) isTrial = true

        // If planId is current Tier, and not a trial, return Management URL
        if (currentTier === planId && !isTrial) {
            getManagementLinkForPlan()
            .then((res) => {
                if (res.data.success) {
                    window.location.replace(res.data.url)
                    return
                }
                setIsError(true)
            })
            return
        }

        // If planId is social and is not a trial return the cancel plan
        if (planId === "social" && currentTier !== "social" && !isTrial) {
            getManagementLinkForPlan()
            .then((res) => {
                if (res.data.success) {
                    window.location.replace(res.data.url)
                    return
                }
                setIsError(true)
            })
            return
        }


        // Return the checkout session url
        getCheckoutLinkForPlan({ plan_name: planId, is_annual: isAnnual})
        .then((res) => {
            if (res.data.success) {
                window.location.replace(res.data.url)
            }
        })
        .catch((err) => { return setIsError(true) })
        
        return
    }
    const fetchPlanInfo = () => {
        getCurrentPlanInformation()
        .then((res) => {
            if (res.data.success) {
                const planData = res.data.plan
                setCurrentTier(planData.tier)
                setIsLoading(false)
                return
            }
            return setIsError(true)
        })
        .catch((err) => {
            return setIsError(true)
        })
    }
    useEffect(() => {
        fetchPlanInfo()
    // eslint-disable-next-line
    }, [])
    return (
        <div className="network-page" style={{marginTop: "48px"}}>
            {isLoading && !isError &&
            <div style={{marginTop: "120px", width: "100%", display: "flex", justifyContent: "center"}}>
                <div style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1em"}}>
                    <LoadingSymbolGrid/>
                    <div style={{textAlign: "center", fontWeight: "600"}}>Loading plans...</div>
                </div>
            </div>
            }
            {!isLoading && !isError &&
            <div>
                <div className="account-plans-header">
                    <FaChevronLeft onClick={() => history.push('/settings?v=pro')}/>
                    <h1>Manage your plan</h1>
                </div>
                <Toggle onToggle={handleToggle} />
                <div className="landing-base-alt-hero-texts landing-base-pricing" style={{alignItems: "unset", justifyContent: "flex-start"}}>
                    <div className="landing-plans-prices">
                        {PRICING_TABLE.map((plan) => (
                            <PricingPlan plan={plan} isAnnual={isAnnual} key={plan._id} answerCallToAction={answerCallToAction} currentTier={currentTier} userData={userData} />
                        ))}
                    </div>


                    <div className="util-row util-col" style={{gap: "20px"}}>
                        <div>Prefer to talk in person?</div>
                        <div style={{width: "120px", textAlign: "center"}} className="landing-base-home-cta landing-base-home-cta-alt" onClick={() => history.push('/forms/licenses/pro')}>Contact sales</div>
                    </div>
                </div>
            </div>
            }
            {isError &&
            <div style={{marginTop: "120px", width: "100%", display: "flex", justifyContent: "center"}}>
                <div style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1em"}}>
                    <div style={{textAlign: "center", fontWeight: "600"}}>Unfortunately, something went wrong loading available plans</div>
                    <div className="user-settings-plan-action" onClick={() => history.push('/settings?v=pro')}>Return to Tetheros</div>
                </div>
            </div>
            }
        </div>
    )
}

function PricingPlan({ plan, isAnnual, answerCallToAction, currentTier, userData }) {
    const calculatedMonthlyCost = () => {
        let annualCost = plan.cost_monthly * 12
        if (isAnnual) annualCost = plan.cost_annual
        const monthly = Math.round(annualCost/12).toFixed(0)
        const number = parseFloat(monthly);
        return number.toLocaleString();
    }
    const calculatedAnnualCost = () => {
        let annualCost = plan.cost_monthly * 12
        if (isAnnual) annualCost = plan.cost_annual
        const monthly = annualCost
        const number = parseFloat(monthly)
        return number.toLocaleString()
    }
    return (
        <div className={`pricing-plan ${plan._id === currentTier ? "featured" : "" }`}>
            {plan._id === currentTier && <div className="pricing-plan-badge-featured">Current</div> }
            <div className="pricing-plan-header">
                <div className="pricing-plan-name">{plan.name}</div>
                <div className="pricing-plan-subtitle">{plan.subtitle}</div>
            </div>
            <div className="pricing-plan-cost">
                <span className="pricing-plan-cost-amount">${calculatedMonthlyCost()}</span>
                <span className="pricing-plan-cost-period">/month</span>
            </div>
            <span className="pricing-plan-cost-period" style={{fontSize: "12px"}}>${calculatedAnnualCost()} per year</span>
            <div className="pricing-plan-description" style={{marginTop: "12px"}}>Included in {plan.name}</div>
            <div className="pricing-plan-benefits">
                <span><FaCheck /> Chat</span>
                <span>{plan.has_network ? <FaCheck /> : <IoClose/>} Network</span>
                <span>{plan.has_priority_support ? <FaCheck /> : <IoClose/>} Priority Support</span>
                <span>{plan.has_channels ? <FaCheck /> : <IoClose/>} Social Channels</span>
                {plan.allowed_network_users > 0 && <span><FaCheck /> {plan.allowed_network_users} Users</span>}
                {plan.allowed_network_users === 0 && <span><FaCheck /> Social Profile</span>}
            </div>
            {plan._id === currentTier && plan._id !== "social" && !userData.license_is_trial && <div className="pricing-plan-cta" onClick={() => answerCallToAction(plan._id)}>Manage Plan</div>}
            {plan._id === currentTier && plan._id !== "social" && userData.license_is_trial && <div className="pricing-plan-cta" onClick={() => answerCallToAction(plan._id)}>Add Payment Details</div>}
            {plan._id !== currentTier && plan._id !== "social" && <div className="pricing-plan-cta" onClick={() => answerCallToAction(plan._id)}>Select Plan</div>}
            {plan._id === "social" && <div className="pricing-plan-cta pricing-plan-cta-inverse">Included</div>}

        </div>
    );
}

function Toggle({onToggle}) {
    const [isAnnual, setIsAnnual] = useState(false);

    const handleToggle = () => {
        setIsAnnual(!isAnnual);
        onToggle(isAnnual ? false : true);
    };

    return (
        <div className="pricing-toggle">
            <span className="toggle-label">Save with annual billing</span>
            <label className="switch">
                <input type="checkbox" checked={isAnnual} onChange={handleToggle} />
                <span className="slider round"></span>
            </label>
            <span className="save-label">1 Month Free</span>
        </div>
    );
}