import React from 'react'
import { useState, useEffect } from 'react'
import ModalSimpleHeader from './simple/ModalSimpleHeader'
import { toCapitalize } from '../../common/Helpers'
import ModalToolSingleSelectRoleDropdownWithSearch from './tools/ModalToolSingleSelectRoleDropdownWithSearch'
import ModalSimpleSingleButton from './simple/ModalSimpleSingleButton'
import ToggleSwitch from '../gadgets/ToggleSwitch'
import SlideSelector from '../gadgets/SlideSelector'

export default function ModalWorkstreamSettings({ hideModal, updateWorkstream, componentData, type, transformToList, transformToBoard }) {
    const [showProgressBar, setShowProgressBar] = useState(() => { return true })
    const [workstreamType, setWorkstreamType] = useState(() => { return "list" })
    const [selectedAutoHideSetting, setSelectedAutoHideSetting] = useState(() => { return -1 })
    const initGeneralSettings = () => {
        if (!componentData) return
        if (!componentData.component) return
        try {
            setWorkstreamType(componentData.component.name)
            let hideProgressBar = false
            if (componentData.component.attributes.hide_progress_bar) hideProgressBar = true
            setShowProgressBar(!hideProgressBar)
        } catch (error) {
            return
        }
    }
    const handleSaveButton = () => {
        let attributes = {}
        attributes['auto_hide_days'] = selectedAutoHideSetting
        attributes['hide_progress_bar'] = !showProgressBar
        updateWorkstream({ attributes: attributes })
        if (type === "board") return saveBoard()
        if (type === "list") return saveList()
        hideModal()
    }
    const saveBoard = () => {
        if (workstreamType === "list") {
            transformToList()
        } else {
            hideModal()
        }
    }
    const saveList = () => {
        if (workstreamType === "board") {
            transformToBoard()
        } else {
            hideModal()
        }
    }
    const updateBoardSettings = () => {
        try {
            if (workstreamType !== "board") return
            if (componentData.component.attributes.auto_hide_days) setSelectedAutoHideSetting(componentData.component.attributes.auto_hide_days)
        } catch (error) {
            return            
        }
    }
    const workstreamSettingsProps = {
        workstreamType,
        setWorkstreamType,
        showProgressBar,
        setShowProgressBar
    };
    const boardSettingsProps = {
        selectedAutoHideSetting,
        setSelectedAutoHideSetting
    }
    useEffect(() => {
        initGeneralSettings()
        updateBoardSettings()
        // eslint-disable-next-line
    }, [componentData])
    useEffect(() => {
        if (workstreamType === "board") updateBoardSettings()
        // eslint-disable-next-line
    }, [workstreamType])
    return (
        <div className="modal-mgmt-background-overlay" style={{zIndex: 200}}>
            <div className="modal-mgmt-container modal-mgmt-container-workstream-settings">
                <div>
                    <ModalSimpleHeader hideModal={hideModal} title={`${toCapitalize(type)} Settings`} />
                    <WorkstreamSettings {...workstreamSettingsProps} />
                    {type === "board" && <BoardSettings {...boardSettingsProps} /> }
                </div>

                <div className="modal-workstream-settings-buttons">
                    <ModalSimpleSingleButton isValid={true} buttonText={"Save Settings"} onclick={handleSaveButton} />
                </div>
            </div>
        </div>
    )
}

function WorkstreamSettings({ workstreamType, setWorkstreamType, showProgressBar, setShowProgressBar }) {
    const slideOptions = [
        {_id: "list", display_name: "List"},
        {_id: "board", display_name: "Board"},
    ]
    return (
        <div>
            <div className="modal-workstream-settings-setting-single">
                <div className="modal-workstream-settings-setting-names">
                    <div className="modal-workstream-settings-setting-name">Workstream Type</div>
                    <div className="modal-workstream-settings-setting-name-description">Show tasks as a list or board.</div>
                </div>
                <div className="modal-workstream-settings-setting-value">
                    <div style={{display: "flex", flexDirection: "row-reverse"}}>
                        <SlideSelector selectedSlideOption={workstreamType} setSlideOption={setWorkstreamType} slideOptions={slideOptions} />
                    </div>
                    
                </div>
            </div>
            <div className="modal-workstream-settings-setting-single">
                <div className="modal-workstream-settings-setting-names">
                    <div className="modal-workstream-settings-setting-name">Show progress bar</div>
                    <div className="modal-workstream-settings-setting-name-description">Shows or hides the overall progress of the workstream.</div>
                </div>
                <div className="modal-workstream-settings-setting-value">
                    <div style={{display: "flex", flexDirection: "row-reverse"}}><ToggleSwitch isChecked={showProgressBar} setIsChecked={setShowProgressBar} /></div>
                    
                </div>
            </div>
        </div>
    )
}

function BoardSettings({selectedAutoHideSetting, setSelectedAutoHideSetting}) {
    const autoHideOptions = [
        {_id: -1, name: "Never hide tasks"},
        {_id: 5, name: "After a few days"},
        {_id: 14, name: "After two weeks"}
    ]
    
    return (
        <div>
            <div className="modal-workstream-settings-setting-single">
                <div className="modal-workstream-settings-setting-names">
                    <div className="modal-workstream-settings-setting-name">Auto-Hide Completed Tasks</div>
                    <div className="modal-workstream-settings-setting-name-description">When set, completed tasks will be hidden unless they are searched for.</div>
                </div>
                <div className="modal-workstream-settings-setting-value">
                    <ModalToolSingleSelectRoleDropdownWithSearch selectedId={selectedAutoHideSetting} allOptions={autoHideOptions} setSelectedId={setSelectedAutoHideSetting} searchText={"Search..."} />
                </div>
            </div>
        </div>
    )
}