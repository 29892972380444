import React from 'react'
import { IoSearchOutline } from 'react-icons/io5'

export default function SimpleSearchBar({ query, setQuery, placeholder, maxWidth }) {
    return (
        <div className="modal-mgmt-select-dropdown-search" style={{maxWidth: `${maxWidth ? maxWidth : 300}px`}}>
            <IoSearchOutline/>
            <input style={{backgroundColor: "rgba(242, 242, 242, 1)"}} type="text" placeholder={placeholder ? placeholder : "Search..."} value={query} onChange={(e) => setQuery(e.target.value)} />
        </div>
    )
}
