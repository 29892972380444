import React from 'react'
import Api from '../../common/APIUtils'
import { useReducer, useEffect } from 'react'
import ModalSimpleHeader from './simple/ModalSimpleHeader'
import ModalSimpleSingleButton from './simple/ModalSimpleSingleButton'
import { AiFillCheckSquare, AiOutlineBorder } from 'react-icons/ai'
import { translatePermissionString } from '../../common/Helpers'

export default function ModalNetworkEditRole({hideModal, network, roleId, networkUserData}) {
    const api = new Api()
    const [proposedRole, proposedRoleDispatch] = useReducer((state, action) => {
        if (action.type === "init") {
            const role = networkUserData.roles.find(r => r._id === roleId)
            console.log(role)
            return { ...state, name: role.name, permissions: role.permissions }
        }
        if (action.type === "update_name") return { ...state, name: action.value }
        if (action.type === "toggle_permission") {
            if (!action.value) return state
            let proposedPermissions = Array.from(state.permissions)
            if (proposedPermissions.includes(action.value)) {
                const index = proposedPermissions.indexOf(action.value)
                if (index < 0) return state
                proposedPermissions.splice(index, 1)
                return { ...state, permissions: proposedPermissions}
            }
            proposedPermissions.push(action.value)
            return { ...state, permissions: proposedPermissions }
        }
        return state
    }, {name: "", permissions: []})
    const PERMISSION_STRINGS_AND_DEPENDENCIES = [
        { raw_name: 'component_read', requires: [] },
        { raw_name: 'component_create', requires: [ 'component_read' ] },
        { raw_name: 'component_update', requires: [ 'component_read' ] },
        { raw_name: 'component_delete', requires: [ 'component_read', 'component_update' ] },
        { raw_name: 'workspace_name_update', requires: [] }
    ]
    const getPermissionsSet = () => {
        return (
            <>
            {PERMISSION_STRINGS_AND_DEPENDENCIES.map((permission) => (
                <div className="modal-mgmt-user-membership-team" key={permission.raw_name}>
                    {proposedRole.permissions.includes(permission.raw_name) && <AiFillCheckSquare style={{color: "#3D72AA"}} onClick={() => proposedRoleDispatch({ type: "toggle_permission", value: permission.raw_name })}/>}
                    {!proposedRole.permissions.includes(permission.raw_name) && <AiOutlineBorder onClick={() => proposedRoleDispatch({ type: "toggle_permission", value: permission.raw_name })}/>}
                    <span>{translatePermissionString(permission.raw_name)}</span>
                </div>
            ))}
            </>
        )
    }
    const isValid = () => {
        if (!proposedRole.name) return false
        if (proposedRole.permissions.length === 0) return false
        return true
    }
    const updateRole = () => {
        if (!isValid()) return hideModal()
        let payload = {
            network_id: network._id,
            role_id: roleId,
            role_name: proposedRole.name,
            permissions: proposedRole.permissions
        }
        api.updateCustomRole(payload)
        .then((res) => {
            return hideModal()
        } )
        .catch((err) => { 
            return hideModal()
        })
    }
    useEffect(() => {
        if (!roleId) return
        proposedRoleDispatch({type: "init"})
    // eslint-disable-next-line
    }, [])
    return (
        <div className="modal-mgmt-background-overlay">
            <div className="modal-mgmt-container">
                <ModalSimpleHeader title="Edit custom role" hideModal={hideModal} />
                <div className="modal-mgmt-sections">
                    <div className="modal-mgmt-section">
                        <h3>Role Name</h3>
                        <input type="text" placeholder="Enter a name for this role, e.g. 'Designers'" value={proposedRole.name} onChange={(e) => proposedRoleDispatch({ type: "update_name", value: e.target.value })} />
                    </div>
                    <div className="modal-mgmt-section">
                        <h3>Select workspace permissions</h3>
                        <div className="modal-mgmt-roles-permissions">
                            {getPermissionsSet()}
                        </div>
                    </div>
                    <div className="modal-mgmt-section">
                        <div className="modal-mgmt-instructional-text-box">
                            <p>Workspace items include: projects, tasks, goals, KPIs, resources, lists, and boards</p>
                        </div>
                    </div>
                    <ModalSimpleSingleButton buttonText="Save" isValid={isValid()} onclick={updateRole} />

                </div>
            </div>
        </div>
    )
}
