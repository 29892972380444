import React from 'react'
import Post from './Post'
// import FeedStory from './FeedStory'

export default function FeedInterface({feedItem, fetchFeed, team, feedDisplay, workspaceMap}) {
    const getFeedItemType = () => {
        // assumed to be a story if it has the key, "story" in it
        if (feedItem.story) return 'story'
        if (feedItem.type === 'component') return 'component'
        return 'post'
    }
    const isTypeWorkspace = (postType) => {
        if (feedItem.story) return false
        if (feedItem.type === postType) return true
        return false
    }
    return (
        <>
        {(getFeedItemType() === 'post' && isTypeWorkspace('workspace')) && <Post post={feedItem} fetchPosts={fetchFeed} feedDisplay={feedDisplay} workspaceMap={workspaceMap} team={team}/>}
        {(getFeedItemType() === 'post' && isTypeWorkspace('user')) && <Post post={feedItem} fetchPosts={fetchFeed} feedDisplay={feedDisplay} workspaceMap={workspaceMap} team={team}/>}
        {(getFeedItemType() === 'component') && <Post post={feedItem} fetchPosts={fetchFeed} feedDisplay={feedDisplay} workspaceMap={workspaceMap} team={team}/>}
        {/* {getFeedItemType() === 'story' && <FeedStory component={feedItem} fetchFeed={fetchFeed} team={team} feedDisplay={feedDisplay} workspaceMap={workspaceMap}/>} */}
        </>
    )
}
