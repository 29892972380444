import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Api from "../../common/APIUtils"
import ConfirmAction from '../modals/ConfirmAction';
import { useHistory } from 'react-router-dom';
import SimpleListActionMenu from './SimpleListActionMenu';
import NewComponentTask from '../modals/NewComponentTask';
import CProgressBar from './CProgressBar';
import { hasPermission, sortByDateStringAttribute, sortByProperty, sortByPropertyReverse, sortByDateProperty } from '../../common/Helpers';
import { TfiLayoutListThumb } from 'react-icons/tfi'
import { IoSearchOutline, IoAdd, IoSettingsOutline, IoCheckmarkOutline } from 'react-icons/io5'
import { AiOutlineSortAscending, AiOutlineSortDescending } from "react-icons/ai";
import { TbArrowsSort } from "react-icons/tb";
import { parseISO } from 'date-fns'
import EditableComponentTitle from '../gadgets/EditableComponentTitle';
import ContextBreadcrumb from '../gadgets/ContextBreadcrumb';
import CreateNewTaskMegabar from '../gadgets/CreateNewTaskMegabar';
import LoadingSymbolGrid from '../gadgets/LoadingSymbolGrid';
import WorkspaceCompListTask from './lists/WorkspaceCompListTask';
import ModalFullScreenEditTask from '../modals/ModalFullScreenEditTask';
import ModalMoveTaskToNewParent from '../modals/ModalMoveTaskToNewParent';
import { Player } from '@lottiefiles/react-lottie-player'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import ComponentUserFilterButton from '../gadgets/ComponentUserFilterButton';
import ModalCreateWorkstreamTemplate from '../modals/ModalCreateWorkstreamTemplate';
import ModalWorkstreamSettings from '../modals/ModalWorkstreamSettings';

export default function WorkspaceCompList({component, permissions, team, componentData, fetchComponent, workspaceName}) {
    const api = new Api();
    let history = useHistory();
    const lastCardUpdate  = useRef(false)
    const lastPendingUpdateTime  = useRef(false)
    const lottieCelebrationFile = require('../../static/assets/lottie_confetti.json')
    const [isMovingModal, setIsMovingModal] = useState(() => { return false })
    const [isShowingConfirmMenu, setIsShowingConfirmMenu] = useState(() => { return false })
    const [view, setView] = useState(() => { return "active" })
    const [isTemplatizing, setIsTemplatizing] = useState(() => { return false })
    const [isShowingCreateWorkstreamTemplateModal, setIsShowingCreateWorkstreamTemplateModal] = useState(() => { return false })
    const [searchText, setSearchText] = useState(() => { return "" })
    const [taskSelectedForEdit, setTaskSelectedForEdit] = useState(() => { return false })
    const [isShowingFullScreenTaskEdit, setIsShowingFullScreenTaskEdit] = useState(() => { return false })
    const [isShowingWorkstreamSettingsModal, setIsShowingWorkstreamSettingsModal] = useState(() => { return false })
    const [isLoaded, setIsLoaded] = useState(() => { return false })
    const [confirmMenuContent, setConfirmMenuContent] = useState(() => { return "" })
    const [celebrate, setCelebrate] = useState(() => { return false })
    const [confirmMenuAction, setConfirmMenuAction] = useState(() => { return false })
    const [filteredOutTeammates, setFilteredOutTeammates] = useState(() => { return [] }) // teammates to show exclusivel
    const [showCreateTaskForm, setShowCreateTaskForm] = useState(() => { return false })
    const [tasks, setTasks] = useState(() => { return [] });
    const [activeCardUpdateSetIsSpinning, setActiveCardUpdateSetIsSpinning] = useState(() => { return false })
    const [activeTasks, setActiveTasks] = useState(() => { return [] });
    const [completedTasks, setCompletedTasks] = useState(() => { return [] });
    const [activeCardUpdateSet, setActiveCardUpdateSet] = useState(() => { return [] })
    const [pendingTaskUpdateQueue, setPendingTaskUpdateQueue] = useState(() => { return [] })
    const [lastUpdateTime, setLastUpdateTime] = useState(() => { return false })
    const [listId, setListId] = useState(() => { return "" })   
    const hideConfirmPopup = () => {
        setIsShowingConfirmMenu(false)
    }
    const handleLottiePlayer = (event) => {
        if (event === "complete") setCelebrate(false)
    }
    const archiveList = () => {
        hideConfirmPopup()
        const payload = {
            status: 'archived'
        };
        const path = `/workspace/components/${componentData.component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const transformToBoard = () => {
        hideConfirmPopup()
        const payload = {
            component_id: listId,
            workspace_id: component.workspace_id,
            proposed_name: "board"
        }
        api.transformWorkspaceComponent(payload)
        .then((res) => {
            fetchComponent()
        })
        .catch((err) => { return })
    }
    const restoreList = () => {
        hideConfirmPopup()
        const payload = {
            status: 'active'
        };
        const path = `/workspace/components/${componentData.component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const updateList = (payload) => {
        const path = `/workspace/components/${componentData.component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const templatizeList = (templateName) => {
        hideConfirmPopup()
        setIsTemplatizing(true)
        const payload = {
            template_name: templateName,
            base_component_id: componentData.component._id,
            scope: 'workspace',
            component_type: 'list',
            workspace_id: componentData.component.workspace_id
        };
        api.createTemplateFromComponent(payload)
        .then((res) => {
            setIsTemplatizing(false)
            fetchComponent();
        })
        .catch((err) => {
            setIsTemplatizing(false)
        })
    }
    const completeList = () => {
        hideConfirmPopup()
        const payload = {
            status: 'completed',
            progress: 1
        };
        const path = `/workspace/components/${componentData.component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const deleteList = async () => {
        hideConfirmPopup()
        const payload = {
            componentId: componentData.component._id
        }
        const workspaceId = componentData.component.workspace_id;

        api.deleteWorkspaceComponent(payload)
        .then((res) => {
            history.push(`/workspaces/${workspaceId}`);
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const hideCreateTaskForm = () => {
        setShowCreateTaskForm(false)
    }
    const fetchListTasks = () => {
        const payload = {
            componentId: component._id,
        };
        api.getListTasks(payload)
        .then((res) => {
            setTasks(res.data.response.tasks); // replacement for the tasks - includes positions
            setIsLoaded(true)
        })
        .catch((err) => {
            console.log(err);
        });
    };
    const publishTask = (p) => {
        const payload = {
            workspace_id: component.workspace_id,
            is_published: true,
            position: tasks.length,
            ...p,
        };
        const path = `/components/work/task`;
        api.createWorkspaceComponent(payload, path)
        .then((res) => {
            fetchListTasks();
        })
        .catch((err) => {
        console.log(err);
        alert(err);
        });
    };
    const askForArchiveConfirmation = () => {
        setConfirmMenuContent("Archive list?  List will be hidden in the sidebar and only shown in relevant locations.  You can restore it later.")
        setConfirmMenuAction(() => archiveList)
        setIsShowingConfirmMenu(true)
    }
    const askForBoardConfirmation = () => {
        setConfirmMenuContent("Transform this list into a board?")
        setConfirmMenuAction(() => transformToBoard)
        setIsShowingWorkstreamSettingsModal(false)
        setIsShowingConfirmMenu(true)
    }
    const askForReactivateConfirmation = () => {
        setConfirmMenuContent("Reactivate list?")
        setConfirmMenuAction(() => restoreList)
        setIsShowingConfirmMenu(true)
    }
    const askForTemplateConfirmation = () => {
        setIsShowingCreateWorkstreamTemplateModal(true)
    }
    const askForCompleteConfirmation = () => {
        setConfirmMenuContent("Mark list as completed?")
        setConfirmMenuAction(() => completeList)
        setIsShowingConfirmMenu(true)
    }
    const askForDeleteConfirmation = () => {
        setConfirmMenuContent("Are you sure you want to permanently delete this list?  All tasks, alignments, and posts will be deleted with it.")
        setConfirmMenuAction(() => deleteList)
        setIsShowingConfirmMenu(true)
    }
    const canCreateTask = () => {
        if (!component) return false
        if (!component.status) return false
        if (!hasPermission(permissions, 'component_create')) return false
        if (component.status === 'active') return true
        return false
    }
    const getExistingPositions = () => {
        try {
            if (!isLoaded) return false
            const activesWithPositions = activeTasks.map(t => t.position)
            return activesWithPositions
        } catch (error) {
            return false 
        }
    }
    const updateSorts = () => {
        // Everything starts with tasks
        try {
            let allTasks = Array.from(tasks)
            if (searchText) {
                allTasks = allTasks.filter((task) => {
                    if (task.display_name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1) return true
                    if (!task.attributes.details) return false
                    // Legacy description
                    if (typeof task.attributes.details === "string") {
                        if (task.attributes.details.toLowerCase().indexOf(searchText.toLowerCase()) !== -1) return true
                    }
                    // lexical description
                    if (!task.attributes.details.description_html) return false
                    let descriptionString = task.attributes.details.description_html.replace(/<\/?[^>]+(>|$)/g, '')
                    if (descriptionString.toLowerCase().indexOf(searchText.toLowerCase()) !== -1) return true
                    return false
                })
            }
            if (filteredOutTeammates.length > 0) {
                allTasks = allTasks.filter((task) => {
                    if (!task.owner_id) return false
                    if (filteredOutTeammates.includes(task.owner_id)) return true
                    return false
                })
            }
            // active
            const activesWithPositions = allTasks.filter((t) => {
                if (t.status !== "active") return false
                if (t.position === 0) return true
                if (!t.position) return false
                if (t.position) return true
                return false
            })
            const activesNoPosition = allTasks.filter((t) => {
                if (t.status !== "active") return false
                if (t.position === 0) return false
                if (!t.position) return true
                return false
            })
            const sortedActives = activesWithPositions.sort((a, b) => {
                return a.position - b.position
            })
            const updatedActivesSorted = sortedActives.concat(activesNoPosition)
            setActiveTasks(updatedActivesSorted)

            // completed
            const completedTasks = allTasks.filter(t => t.status === "completed")
            const sortedCompleted = completedTasks.sort((a,b) => {
                let aItem = parseISO(a.updatedAt)
                let bItem = parseISO(b.updatedAt)
                if (a.completed) aItem = parseISO(a.completed)
                if (b.completed) bItem = parseISO(b.completed)
                return bItem - aItem
            })
            setCompletedTasks(sortedCompleted)

        } catch (error) {
            return
        }
        

    }
    const getNavClassName = (nav) => {
        if (nav === view) return "component-list-tasks-navigation-selected"
        return ""
    }
    const openWorkstreamSettings = () => {
        setIsShowingWorkstreamSettingsModal(true)
    }
    const getActionList = () => {
        let actions = []
        if (hasPermission(permissions, "component_update")) {
            actions = [
                {
                text: 'Settings',
                action: openWorkstreamSettings,
                id: 'settings'
                }
            ]
        }
        if (hasPermission(permissions, 'component_delete')) {
            actions = [
                {
                    text: 'Delete list',
                    action: askForDeleteConfirmation,
                    id: 'delete'
                }
            ].concat(actions)
        }
        if (hasPermission(permissions, 'component_template_create')) {
            actions = [
                {
                    text: 'Create list template',
                    action: askForTemplateConfirmation,
                    id: 'template'
                }
            ].concat(actions)
        }
        if (canCreateTask()) {
            actions = [
                {
                    text: 'Mark list complete',
                    action: askForCompleteConfirmation,
                    id: 'complete'
                },
                {
                    text: 'Move list to archive',
                    action: askForArchiveConfirmation,
                    id: 'archive'
                }
            ].concat(actions)
        }

        if (!canCreateTask()) {
            actions = [
                {
                    text: 'Reactivate list',
                    action: askForReactivateConfirmation,
                    id: 'reactivate'
                }
            ].concat(actions)
        }
        return actions
    }
    const getActualProgress = () => {
        if (!isLoaded) return 0
        try {
            if (tasks.length === 0) return 0
            const aTasks = tasks.filter(t => t.status === "active")
            const cTasks = tasks.filter(t => t.status === "completed")
            return Math.round(cTasks.length/(cTasks.length + aTasks.length)*100, 0)
        } catch (error) {
            return 0
        }
    }
    const updateTask = (payload, taskId) => {
        if (!hasPermission(permissions, 'component_update')) return
        if (payload.status === "completed") setCelebrate(true)
        const path = `/workspace/components/${taskId}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
          fetchListTasks();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const deleteTask = (taskId) => {
        setIsShowingFullScreenTaskEdit(false)
        const payload = {
            componentId: taskId
        }
        api.deleteWorkspaceComponent(payload)
        .then((res) => {
            fetchListTasks();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const toggleEditPanel = (isEditing) => {
        if (isEditing) {
            setIsShowingFullScreenTaskEdit(true);
        } else {
            setIsShowingFullScreenTaskEdit(false)
            setTaskSelectedForEdit(false)
          fetchListTasks()
        }
    }
    const selectTaskToEdit = (taskId) => {
        try {
            const allTasks = Array.from(tasks)
            const taskSearchResult = allTasks.find(t => t._id === taskId)
            if (!taskSearchResult) return setTaskSelectedForEdit(false)
            setTaskSelectedForEdit(taskSearchResult)
            setIsShowingFullScreenTaskEdit(true)
        } catch (error) {
            setTaskSelectedForEdit(false)
        }
    }
    const sendQueuedTaskUpdates = () => {
        const activeCardUpdateSetCopy = Array.from(activeCardUpdateSet)
        api.updateMultipleComponents({
          bulk_updates: activeCardUpdateSetCopy,
          workspace_id: componentData.component.workspace_id
        })
        .then((res) => {
          setActiveCardUpdateSet([])
          setLastUpdateTime(Date.now())
          setActiveCardUpdateSetIsSpinning(false)
        })
        .catch((err) => {
          setActiveCardUpdateSet([])
          setLastUpdateTime(Date.now())
          setActiveCardUpdateSetIsSpinning(false)
        })
    }
    const triggerMoveAction = () => {
        setIsShowingFullScreenTaskEdit(false)
        setIsMovingModal(true)
    }
    const finishMoving = () => {
        setIsMovingModal(false)
    }
    const captureCurrentPositionsAndPrepareSubmit = (taskSnapshot) => {
        const updatedTasks = Array.from(taskSnapshot)
        const positionUpdatePayload = []
        for (let i=0; i < updatedTasks.length; i++) {
            const taskInfo = updatedTasks[i];
            positionUpdatePayload.push({
                id: taskInfo._id,
                update: {position: i}
            })
        }
        setPendingTaskUpdateQueue([...pendingTaskUpdateQueue, positionUpdatePayload ])
    }
    const handleDragEvents = (result) => {
        if (!hasPermission(permissions, 'component_update')) return
        if (!result.destination) return
        if (searchText) return
        try {
            const updatedTasks = Array.from(activeTasks)
            const [reorderedItem] = updatedTasks.splice(result.source.index, 1)
            updatedTasks.splice(result.destination.index, 0, reorderedItem)
            setActiveTasks(updatedTasks)
            captureCurrentPositionsAndPrepareSubmit(updatedTasks)
        } catch (error) {
          return
        }
    }
    const hideProgressBar = () => {
        try {
            if (component.attributes.hide_progress_bar) return true
            return false
        } catch (error) {
            return false
        }
    }
    const sortListByParameters = (params) => {
        if (!hasPermission(permissions, 'component_update')) return
        try {
            // these two things happen at the same time:
            let ascending = params.is_ascending
            let sortMethod = params.sort_by
            const copyOfTasks = Array.from(activeTasks)
            let sortedTasks = copyOfTasks
            if (sortMethod === "date") {
                sortedTasks = sortByDateStringAttribute(copyOfTasks, ascending, "display_name")
            }
            if (sortMethod === "name") {
                if (ascending) sortedTasks = sortByProperty(copyOfTasks, "display_name")
                if (!ascending) sortedTasks = sortByPropertyReverse(copyOfTasks, "display_name")
            }
            if (sortMethod === "age") {
                sortedTasks = sortByDateProperty(copyOfTasks, "createdAt", ascending)
            }
            setActiveTasks(sortedTasks)
            captureCurrentPositionsAndPrepareSubmit(sortedTasks)
        } catch (error) {
            return
        }
    }
    useEffect(() => {
        fetchListTasks();
    // eslint-disable-next-line
    },[])
    useEffect(() => {
        if (!componentData.component) return
        setListId(componentData.component._id)
    // eslint-disable-next-line
    }, [componentData])
    useEffect(() => {
        updateSorts()
    // eslint-disable-next-line
    }, [tasks, searchText, filteredOutTeammates])
    useEffect(() => {
        if (activeCardUpdateSet.length === 0) return
        sendQueuedTaskUpdates()
    // eslint-disable-next-line
    }, [activeCardUpdateSet])
    useEffect(() => {
        // Delay calling until more updates are stuffed
        if (pendingTaskUpdateQueue.length === 0) return
        lastPendingUpdateTime.current = Date.now()
        const timer = setTimeout(() => {
          if (Date.now() - lastPendingUpdateTime.current < 900 || activeCardUpdateSetIsSpinning) return
          if (!activeCardUpdateSetIsSpinning) {
            setActiveCardUpdateSetIsSpinning(true) 
            setActiveCardUpdateSet(pendingTaskUpdateQueue)
            setPendingTaskUpdateQueue([])
          }
        }, 1000)
        return () => clearTimeout(timer)
    // eslint-disable-next-line
    }, [pendingTaskUpdateQueue, activeCardUpdateSetIsSpinning])
    useEffect(() => {
        if (!lastUpdateTime) return
        lastCardUpdate.current = Date.now()
        const timer = setTimeout(() => {
            if (Date.now() - lastCardUpdate.current < 9000) return
            fetchListTasks()
        }, 10000)
        return () => clearTimeout(timer)
    // eslint-disable-next-line
    }, [lastUpdateTime])
    return (
        <div className="workcomp-page-container workcomp-page-container-dark" style={{paddingLeft: "12px", paddingRight: "12px", backgroundColor: "#FBFBFD"}}>
            {isShowingCreateWorkstreamTemplateModal && <ModalCreateWorkstreamTemplate name="list" isTemplatizing={isTemplatizing} hideModal={() => setIsShowingCreateWorkstreamTemplateModal(false)} createTemplate={templatizeList} /> }
            {isShowingFullScreenTaskEdit && taskSelectedForEdit && <ModalFullScreenEditTask openMoveModal={triggerMoveAction} componentData={componentData} permissions={permissions} task={taskSelectedForEdit} setIsEditingCard={toggleEditPanel} team={team} updateTask={updateTask} deleteTask={deleteTask}/>}
            {isMovingModal && taskSelectedForEdit && <ModalMoveTaskToNewParent task={taskSelectedForEdit} finishMoving={finishMoving} updateTask={updateTask} />}
            {showCreateTaskForm && <NewComponentTask existingPositions={getExistingPositions()} parentId={component._id} team={team} hideCreateTaskForm={hideCreateTaskForm} createTask={publishTask} /> }
            {isShowingConfirmMenu && <ConfirmAction mainText={confirmMenuContent} onconfirm={confirmMenuAction} hideMe={hideConfirmPopup} />}
            {isShowingWorkstreamSettingsModal && <ModalWorkstreamSettings permissions={permissions} componentData={componentData} updateWorkstream={updateList} hideModal={() => setIsShowingWorkstreamSettingsModal(false)} type="list" transformToBoard={askForBoardConfirmation}/>}

            <div className="component-page-row" style={{alignItems: "center", justifyContent: "space-between", paddingLeft: "2px", marginTop: "12px", marginBottom: "14px"}}>
                <ContextBreadcrumb permissions={permissions} component={component} componentData={componentData} workspaceName={workspaceName} />

                <div className="util-row util-align-center" style={{gap: "12px"}}>
                    <div className={`component-view-title-meta-status-${component.status}`}>{component.status}</div>
                    {hasPermission(permissions, "component_update") &&
                        <SimpleListActionMenu actionsList={getActionList()}/>
                    }
                </div>
            </div>

            <div className="component-page-row" style={{alignItems: "center", justifyContent: "space-between", marginBottom: "18px", gap: "20px"}}>
                <div className="component-page-row" style={{alignItems: "center", gap: "8px", flexGrow: 1, position: "relative"}}>
                    {!hideProgressBar() &&
                    <div className="component-workstream-responsive" style={{minWidth: "52px", minHeight: "52px", cursor: "pointer"}} onClick={() => setCelebrate(true)}>
                        {!celebrate && <CProgressBar extraStyles={{fontSize: "12px"}} height={48} percentage={getActualProgress()} />}
                        {celebrate && <Player autoplay loop={false} src={lottieCelebrationFile} style={{height: "48px", width: "48px"}} onEvent={handleLottiePlayer} speed={1.5}/>}
                    </div>
                    }
                    <EditableComponentTitle permissions={permissions} component={component} activeComponentId={listId} fetchComponent={fetchComponent}/>
                </div>
                
                <div className="component-page-row" style={{alignItems: "stretch", gap: "16px"}}>
                    {/* New Task */}
                    {canCreateTask() && <div className="workcomp-board-control-panel-create-button" onClick={() => setShowCreateTaskForm(true)}><IoAdd/><span className="component-workstream-responsive">New Task</span></div>}
                    {/* Search */}
                    <div className="workcomp-board-control-panel-search-container component-workstream-responsive">
                        <IoSearchOutline/>
                        <input type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder="Search" />
                    </div>
                    {/* Filter */}
                    <div className="component-workstream-responsive">
                        <ComponentUserFilterButton filteredOutTeammates={filteredOutTeammates} setFilteredOutTeammates={setFilteredOutTeammates} team={team} />
                    </div>
                    {/* Settings */}
                    {hasPermission(permissions, "component_update") &&
                    <div className="component-workstream-responsive">
                        <div className="workcomp-board-control-panel-symbol-button" onClick={() => setIsShowingWorkstreamSettingsModal(true)}><IoSettingsOutline/></div>
                    </div>}
                </div>            
            </div>
            {hasPermission(permissions, "component_create") &&
            <div>
                <CreateNewTaskMegabar fetchParent={fetchListTasks} existingPositions={getExistingPositions()} parentComponent={component}/>
            </div>
            }

            <div className="component-list-control-bar">
                <div className="component-list-tasks-navigation">
                    <span className={getNavClassName("active")} onClick={() => setView("active")}>
                        To Do
                        <div>{activeTasks.length}</div>
                    </span>
                    <span className={getNavClassName("completed")} onClick={() => setView("completed")}>
                        Completed
                        <div>{completedTasks.length}</div>
                    </span>
                </div>
                <div className="component-list-controls-misc">
                    {hasPermission(permissions, 'component_update') && <SortListButton sortListByParameters={sortListByParameters} />}
                </div>
            </div>

            {isLoaded &&
            <div style={{maxWidth: "100%"}}>
                <DragDropContext onDragEnd={handleDragEvents}>
                    {view === "active" &&
                    
                        <Droppable droppableId='tasks' direction='vertical' type='tasks'>
                            {(provided) => (
                                <div className="component-list-tasks-section" {...provided.droppableProps} ref={provided.innerRef}>

                                    {activeTasks.map((task, index) => (
                                        <WorkspaceCompListTask key={task._id} index={index} task={task} team={team} selectTaskToEdit={selectTaskToEdit} updateTask={updateTask}/>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    
                    }

                    {view === "completed" &&
                    <Droppable droppableId='tasks' direction='vertical' type='tasks'>
                        {(provided) => (
                            <div className="component-list-tasks-section" {...provided.droppableProps} ref={provided.innerRef}>
                                {completedTasks.map((task, index) => (
                                    <WorkspaceCompListTask key={task._id} index={index} task={task} team={team} selectTaskToEdit={selectTaskToEdit} updateTask={updateTask} isInCompleted={true}/>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                    }
                </DragDropContext>
            </div>
            }

            {/* Loading Data */}
            {!isLoaded && <div style={{marginTop: "40px", display: "flex", justifyContent: "center"}}><LoadingSymbolGrid /></div> }

            {isLoaded && activeTasks.length === 0 && completedTasks.length === 0 && view === "active" && !searchText && filteredOutTeammates.length === 0 &&
            <div className="component-list-empty-text">
                <TfiLayoutListThumb/>
                Welcome to your new list!  Add tasks above to get started</div>
            }
            
            {isLoaded && activeTasks.length === 0 && completedTasks.length > 0 && view === "active" && !searchText && filteredOutTeammates.length === 0 && 
            <div className="component-list-empty-text">
                <Player autoplay loop={false} src="https://assets1.lottiefiles.com/packages/lf20_wkebwzpz.json" style={{height: "140px", width: "140px"}} speed={1}/>
                You're all caught up
            </div>
            }

        </div>
    )
}

function SortListButton({sortListByParameters}) {
    const [isShowingSortActionMenu, setIsShowingSortActionMenu] = useState(() => { return false })
    const [isAscending, setIsAscending] = useState(() => { return true })
    const [sortByMethod, setSortByMethod] = useState(() => { return "date" })
    const stateChangeMenuRef = useRef(null)
    const getSortSymbol = (sortName) => {
        if (sortName === sortByMethod) return <IoCheckmarkOutline/>
        return <div style={{minWidth: "16px"}}></div>
    }
    const submitChange = () => {
        try {
            sortListByParameters({ is_ascending: isAscending, sort_by: sortByMethod })
            setIsShowingSortActionMenu(false)
        } catch (error) {
            setIsShowingSortActionMenu(false)
        }
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (stateChangeMenuRef.current && !stateChangeMenuRef.current.contains(event.target)) {
                setIsShowingSortActionMenu(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [stateChangeMenuRef])
    return (
        <div className="component-list-controls-sort-container" ref={stateChangeMenuRef}>
            <div className={`component-list-controls-misc-symbol-button ${isShowingSortActionMenu && "component-list-controls-misc-symbol-button-selected"}`} onClick={() => setIsShowingSortActionMenu(!isShowingSortActionMenu)}>
                <TbArrowsSort/>
            </div>
            {isShowingSortActionMenu &&
                <div className="task-state-selector-menu" style={{left: "unset", right: 0}}>
                    <div className="task-state-selector-header">Rearrange</div>
                    <div className="task-state-selector-menu-items">
                        <span onClick={() => setSortByMethod("date")}>{getSortSymbol("date")}Sort by Due Date</span>
                        <span onClick={() => setSortByMethod("name")}>{getSortSymbol("name")}Sort Alphabetically</span>
                        <span onClick={() => setSortByMethod("age")}>{getSortSymbol("age")}Sort by Age</span>
                    </div>
                    <div className="task-state-selector-asc-desc">
                        <div onClick={() => setIsAscending(true)} className={`task-state-selector-option ${isAscending ? "task-state-selector-option-selected" : ""}`}><AiOutlineSortAscending/>Ascending</div>
                        <div onClick={() => setIsAscending(false)} className={`task-state-selector-option ${!isAscending ? "task-state-selector-option-selected" : ""}`}><AiOutlineSortDescending/>Descending</div>
                    </div>
                    <div className="task-state-selector-confirmation-buttons">
                        <div onClick={submitChange}>Update</div>
                        <div onClick={() => setIsShowingSortActionMenu(false)}>Cancel</div>
                    </div>
                </div>
            }
        </div>
    )
}