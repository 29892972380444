import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Api from '../../common/APIUtils'
import DashboardMain from './dashboards/DashboardMain';
import DashboardUserDeepDive from './dashboards/DashboardUserDeepDive';
import Calendar from '../calendars/Calendar';
import AssignmentTable from '../assignments/AssignmentTable';

export default function WorkspaceDashboards({ selDashboard, team, workspaceName, fetchWorkspace, workspaceId, sidebarCollapsed, permissions, workspace }) {
    const api = new Api();
    const calendarContainer = useRef();
    const query = new URLSearchParams(useLocation().search);
    const requestedPage = query.get("v");
    const [sortBy, setSortBy] = useState(() => { return "date" })
    const [dashboardView, setDashboardView] = useState(() => { return 'assignments' })
    const [selectedUserId, setSelectedUserId] = useState(() => { return "" })
    const [overviewData, setOverviewData] = useState(() => { return {} })
    const [activeWorkstreams, setActiveWorkstreams] = useState(() => { return [] })
    const fetchOverviewDashboardData = () => {
        api.getWorkspaceDashboard({workspaceId: workspaceId})
        .then((res) => {
            if (res.data) {
                setOverviewData(res.data.response);
                if (res.data.response.workstreams) setActiveWorkstreams(res.data.response.workstreams)
            } else {
                console.log('Problem fetching dashboard')
            }
        })
        .catch((err) => {
            console.log(err);
        }); 
    }
    const getEventsForCalendar = () => {
        if (!overviewData) return []
        return overviewData.all
    }
    const getTabClass = (tabName) => {
        if (tabName === dashboardView) return "network-users-view-navigation-selected"
        return ""
    }
    const getCalendarStyle = () => {
        return {
            left: `252px`,
            top: `175px`
        }
    }
    const getComponentTypeMap = () => {
        return {
            task: "Tasks",
            // list: "Lists",
            // board: "Boards",
            goal: "Goals",
            // initiative: "Projects"
        }
    }
    const goToUserPanel = (userId) => {
        if (!userId) return setDashboardView('unassigned_dive')
        setSelectedUserId(userId)
        setDashboardView('user')
    }
    const updateWorkspaceComponent = (payload, componentId) => {
        if (!componentId) return
        if (!payload) return
        api.updateWorkspaceComponent(payload, `/workspace/components/${componentId}`)
        .then((res) => fetchOverviewDashboardData())
        .catch((err) => console.log(err))
    }
    useEffect(() => {
        fetchOverviewDashboardData()
        const interval = setInterval(() => {
            fetchOverviewDashboardData()
        }, 90000)
        return() => clearInterval(interval)
        // eslint-disable-next-line
    }, [workspaceId])
    useEffect(() => {
        if (['assignments', 'calendar'].includes(requestedPage)) return setDashboardView(requestedPage)
    }, [requestedPage])
    useEffect(() => {
        try {
            if (!['assignments', 'calendar', 'distribution'].includes(dashboardView)) return
            var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + `?v=${dashboardView}`;
            window.history.pushState({path:newurl},'',newurl);
        } catch (error) {
            return
        }
    }, [dashboardView])
    return (
        <div className="network-page" style={{width: "98%"}}>
            <div className="network-users-header-row" style={{marginTop: "16px", marginBottom: "16px"}}>
                <div className="network-page-header-text">
                    <h1>{workspaceName} Dashboard</h1>
                </div>
            </div>

            <div className="network-users-view-navigation" style={{marginBottom: "32px"}}>
                <span className={getTabClass('assignments')} onClick={(e) => setDashboardView('assignments')}>Assignments</span>
                <span className={getTabClass('distribution')} onClick={(e) => setDashboardView('distribution')}>Work Distribution</span>
                <span className={getTabClass('calendar')} onClick={(e) => setDashboardView('calendar')}>Calendar</span>
            </div>

            {dashboardView === 'distribution' && <DashboardMain data={overviewData} team={team} goToUserPanel={goToUserPanel} updateWorkspaceComponent={updateWorkspaceComponent}/>}

            {dashboardView === 'assignments' && <AssignmentTable context="workspace" permissions={permissions} updateWorkspaceComponent={updateWorkspaceComponent} components={getEventsForCalendar()} workspaces={[workspace]} sortBy={sortBy} setSortBy={setSortBy} team={team} />}

            {dashboardView === 'user' && <DashboardUserDeepDive updateWorkspaceComponent={updateWorkspaceComponent} permissions={permissions} workspace={workspace} data={overviewData} team={team} userId={selectedUserId} goBack={() => setDashboardView('distribution')}/>}
            {dashboardView === 'unassigned_dive' && <DashboardUserDeepDive updateWorkspaceComponent={updateWorkspaceComponent} permissions={permissions} workspace={workspace} data={overviewData} team={team} userId={false} goBack={() => setDashboardView('distribution')}/>}

            {dashboardView === 'calendar' && (
                <div className="dashboard-calendar-container" ref={calendarContainer}>
                    <div className="dashboard-calendar-container-absolute" style={getCalendarStyle()}>
                        <Calendar componentTypeMap={getComponentTypeMap()} fetchEvents={fetchOverviewDashboardData} events={getEventsForCalendar()} vertical={true} colorByEventProperty="parent" permissions={permissions} team={team}  workspaceWorkstreamPermissionMap={[{workspace: workspace, workstreams: activeWorkstreams, permissions: permissions}]}  />
                    </div>
                </div>
                
            )}

        </div>
    )}
