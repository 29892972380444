import React from 'react'
import { formatDistanceStrict, isToday, parseISO } from 'date-fns';
import { useHistory } from "react-router-dom";

export default function SidebarFeedUrgentItem({component, overdue, feedDisplay, workspaceMap}) {
    const history = useHistory();
    const goToComponentPage = () => {
        history.push(`/workspaces/${component.workspace_id}/c/${component._id}`)
    }
    const getDateSpecificText = () => {
        
        if (overdue) {
            return `Overdue ${formatDistanceStrict(parseISO(component.attributes.date_due), new Date(), {unit: 'day', roundingMethod: 'round'})}`
        } else {
            if (isToday(parseISO(component.attributes.date_due))) {
                return 'Due today'
            } else {
                return ` Due in ${formatDistanceStrict(parseISO(component.attributes.date_due), new Date(), {unit: 'day', roundingMethod: 'ceil'})}`
            }
        }
    }
    const getIndicatorColorStyle = () => {
        if (overdue) {
            return {
                backgroundColor: "rgb(201, 59, 59)"
            }
        } else {
            return {}
        }
    }
    const getSubtitleContent = () => {
        try {
            if (feedDisplay === 'home') {
                let contextName = "Workspace"
                if (Array.isArray(workspaceMap)) {
                    const matchingWorkspace = workspaceMap.filter(a => a._id === component.workspace_id)
                    if (matchingWorkspace) contextName = matchingWorkspace[0].name
                }
                
                return (
                    <div className="workspace-home-feed-side-urgent-subtitle">
                        <span>{contextName}</span> &middot; <span>{component.name}</span> &middot; {getDateSpecificText()}
                    </div>
                )
            }
            return (
                <div className="workspace-home-feed-side-urgent-subtitle">
                    <span>{component.name}</span> &middot; {getDateSpecificText()}
                </div>  
            )
        } catch (error) {
            return (
                <>
                </>  
            )
        }

    }
    return (
        <div className="workspace-home-feed-side-urgent-item" onClick={goToComponentPage}>
            <span className="workspace-home-feed-side-urgent-item-circle" style={getIndicatorColorStyle()}></span>
            <div>
                <div className="workspace-home-feed-side-urgent-title">{component.display_name}</div>
                {getSubtitleContent()}
            </div>
        </div>
    )
}
