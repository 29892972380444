import React from 'react'
import ModalSimpleHeader from './simple/ModalSimpleHeader';
import ModalSimpleSingleButton from './simple/ModalSimpleSingleButton';
import ModalToolMultiSelectWorkspaceDropdownWithSearch from './tools/ModalToolMultiSelectWorkspaceDropdownWithSearch';
import LoadingSymbolRipple from '../gadgets/LoadingSymbolRipple';
import { useState, useEffect } from "react";
import Api from '../../common/APIUtils';

export default function ModalNewNetworkWorkspace({cancelModal, network, attemptWorkspaceCreation}) {
    const api = new Api()
    const [workspaceName, setWorkspaceName] = useState(() => { return ""})
    const [selectedTeamIds, setSelectedTeamIds] = useState(() => { return [] })
    const [networkData, setNetworkData] = useState(() => { return false })
    const [page, setPage] = useState(() => { return 1})
    const hasValidName = () => {
        try {
            if (workspaceName && workspaceName.trim() !== "") return true
            return false
        } catch (error) {
            return false
        }
    }
    const addTeamToSelected = (teamId) => {
        if (selectedTeamIds.includes(teamId)) return
        let updatedTeamIds = Array.from(selectedTeamIds)
        updatedTeamIds.push(teamId)
        setSelectedTeamIds(updatedTeamIds)
    }
    const removeTeamFromSelected = (teamId) => {
        if (!selectedTeamIds.includes(teamId)) return
        let updatedTeamIds = Array.from(selectedTeamIds)
        const index = updatedTeamIds.indexOf(teamId)
        if (index < 0) return
        updatedTeamIds.splice(index, 1)
        setSelectedTeamIds(updatedTeamIds)
    }
    const getTeamOptions = () => {
        try {
            if (!networkData) return []
            if (!networkData.teams) return []
            return networkData.teams
        } catch (error) {
            return []
        }
    }
    const advanceFromPageOne = () => {
        // If there are no teams, skip to the end
        if (getTeamOptions().length === 0) return setPage(3)
        return setPage(2)
    }
    const handleEnter = (code) => {
        if (code === 'Enter') {
            if (hasValidName()) return advanceFromPageOne()
        }
    }
    const fetchUserInvitationOptions = () => {
        if (!network) return
        if (!network._id) return
        api.getNetworkInvitationData(network._id)
        .then((res) => {
            setNetworkData(res.data)
        })
        .catch((err) => console.log(err))
    }
    const submitNewWorkspace = () => {
        setPage(3)
        if (!hasValidName()) return cancelModal()
        let payload = {
            network_id: network._id,
            name: workspaceName,
            team_ids: selectedTeamIds
        }
        attemptWorkspaceCreation(payload)
    }
    useEffect(() => {
        fetchUserInvitationOptions()
    // eslint-disable-next-line
    }, [network])
    return (
        <div className="modal-mgmt-background-overlay">

            {!networkData &&
            <div className="modal-mgmt-container">

                {/* Header */}
                <ModalSimpleHeader title="Create new workspace" hideModal={cancelModal}/>
                
                <div className="modal-mgmt-sections">
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "24px"}}> <LoadingSymbolRipple /> </div>
                </div>
                
            </div>
            }

            {networkData &&
            <div className="modal-mgmt-container">

                {/* Header */}
                <ModalSimpleHeader title="Create new workspace" hideModal={cancelModal}/>

                {page === 1 &&
                <div className="modal-mgmt-sections">
                    <div className="modal-mgmt-section">
                        <h3>What do you want to call this workspace?</h3>
                        <p>This workspace will be created in the {network.name} network</p>
                        <input type="text" placeholder="What do you want to call this workspace?" onKeyDown={(e) => handleEnter(e.code)} value={workspaceName} onChange={(e) => setWorkspaceName(e.target.value)} />
                    </div>

                    <ModalSimpleSingleButton isValid={hasValidName()} buttonText="NEXT" onclick={advanceFromPageOne} />
                </div>
                }
                
                {page === 2 &&
                <div className="modal-mgmt-sections">
                    <div className="modal-mgmt-section" style={{paddingBottom: "20px"}}>
                        <div className="modal-mgmt-section-heading-feedback">
                            <h3>Select teams to give access to {workspaceName}</h3>
                            <span>{selectedTeamIds.length} of {getTeamOptions().length} selected</span>
                        </div>
                        <ModalToolMultiSelectWorkspaceDropdownWithSearch addWorkspace={addTeamToSelected} removeWorkspace={removeTeamFromSelected} allWorkspaces={getTeamOptions()} selectedWorkspaceIds={selectedTeamIds}/>
                    </div>

                    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", gap: "24px", width: "100%"}}>
                        <ModalSimpleSingleButton isValid={true} buttonText="GO BACK" onclick={() => setPage(1)} invertStyle={true}/>
                        <ModalSimpleSingleButton isValid={true} buttonText="NEXT" onclick={submitNewWorkspace} />
                    </div>

                </div>
                }
                
                {page === 3 &&
                <div className="modal-mgmt-sections">
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "24px"}}> <LoadingSymbolRipple /> </div>
                </div>
                }

            </div>
            }
        </div>
    )
}
