import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { MdClose, MdMoreHoriz, MdLaunch } from 'react-icons/md';
import { FaRegTrashAlt, FaAngleLeft } from 'react-icons/fa'
import { ImFolder, ImCheckmark } from 'react-icons/im'

import Api from '../../common/APIUtils';

// eslint-disable-next-line
import { hasPermission, getUserNameFromId, getColorHexMapFromName, getAvailableCardColors, getContrastColor } from '../../common/Helpers';
import { format, parseISO } from 'date-fns';

import ComponentCriteria from '../components/sidepanes/ComponentCriteria';
import ComponentComment from '../components/sidepanes/ComponentComment';
import LoadingSymbolFacebook from '../gadgets/LoadingSymbolFacebook';
import OwnerSelectorDropdown from '../gadgets/OwnerSelectorDropdown';
import DateBoxEditable from '../components/misc/DateBoxEditable';
import ComponentMainDescription from '../components/ComponentMainDescription'
import WorkspaceCompHashtags from '../gadgets/WorkspaceCompHashtags';
import EditableComponentTitle from '../gadgets/EditableComponentTitle';
import WorkspaceCompInitiativeAddMarker from '../components/initiatives/WorkspaceCompInitiativeAddMarker';
import ModalCreateNewResourceInProject from './ModalCreateNewResourceInProject';
import ResourceReference from '../gadgets/ResourceReference';

import BlurProofInput from '../gadgets/BlurProofInput';
import SimpleMentionInput from '../gadgets/SimpleMentionInput';

import { updateComponentDescriptionWithFileSupport } from '../../common/Api';

export default function ModalFullScreenEditTask({task, componentData, permissions, setIsEditingCard, team, completeTask, deleteTask, restoreTask, fetchBoard, openMoveModal}) {
    const api = new Api();
    const history = useHistory();
    const resourceMenuRef = useRef(null)
    const newSubtaskInputRef = useRef(null)
    const [delayHandler, setDelayHandler] = useState(() => { return null });
    const actionMenuRef = useRef()
    const modalTaskContent = useRef(null)
    const colorChangeMenuRef = useRef(false)
    const isAddSubtaskTrigger = useRef(false)
    const [criteria, setCriteria] = useState(() => { return [] })
    const [wantsToDelete, setWantsToDelete] = useState(() => { return false })
    const [commentsLoaded, setCommentsLoaded] = useState(() => { return false })
    const [isShowingAddNewResourceMenu, setIsShowingAddNewResourceMenu] = useState(() => { return false })
    const [isShowingActionMenu, setIsShowingActionMenu] = useState(() => { return false })
    const [isShowingCreateNewResourceModal, setIsShowingCreateNewResourceModal] = useState(() => { return false })
    const [isShowingColorChangeMenu, setIsShowingColorChangeMenu] = useState(() => { return false })
    const [comments, setComments] = useState(() => { return [] })
    const [resources, setResources] = useState(() => { return [] })
    const [taskData, setTaskData] = useState(() => { return task })
    const [parentData, setParentData] = useState(() => { return "" })
    const [newComment, setNewComment] = useState(() => { return "" })
    const [taskColorName, setTaskColorName] = useState(() => { return "none" })
    const [proposedCriteria, setProposedCriteria] = useState(() => { return "" })
    const [ownerId, setOwnerId] = useState(() => { return task.owner_id })
    const [isAddingNewResource, setIsAddingNewResource] = useState(() => { return false })
    const [allComponents, setAllComponents] = useState(() => { return {} })
    const [childrenComponents, setChildrenComponents] = useState(() => { return [] })
    const [potentialBonds, setPotentialBonds] = useState(() => { return { as_child: [], as_parent: []} })
    const [dueDate, setDueDate] = useState(() => { return task.attributes.date_due })
    const [tags, setTags] = useState(() => { return [] })
    const closeTaskEdit = () => {
        setIsEditingCard(false);
    }
    const openAddExistingResourceModal = () => {
        setIsShowingAddNewResourceMenu(false)
        setIsShowingCreateNewResourceModal(false)
        setIsAddingNewResource(true)
    }
    const openCreateNewResourceModal = () => {
        setIsShowingAddNewResourceMenu(false)
        setIsAddingNewResource(false)
        setIsShowingCreateNewResourceModal(true)
    }
    const handleNewCriteriaPress = (e) => {
        if (e.key === 'Enter') {
            if (proposedCriteria) {
                createCriteria()
            }
        }
    }
    const handleNewCommentPress = (e) => {
        if (e.key === 'Enter') {
            if (newComment) {
                createComment()
            }
        }
    }
    // eslint-disable-next-line
    const updateTaskColor = (colorName) => {
        setTaskColorName(colorName)
        setIsShowingColorChangeMenu(false)
        setIsShowingActionMenu(false)
        const payload = {
            attributes: {
                color_name: colorName
            }
        }
        submitTaskUpdate(payload)
    }
    // eslint-disable-next-line
    const getColorChangeOptionStyle = (colorName) => {
        const colorInfo = getColorHexMapFromName(colorName)
        return {
            color: getContrastColor(colorInfo.hex),
            backgroundColor: colorInfo.hex
        }
    }
    const createCriteria = () => {
        const taskId = task._id;
        const payload = {
            description: proposedCriteria,
            component_id: taskId
        }
        isAddSubtaskTrigger.current = true;
        setProposedCriteria("")
        api.addComponentCriteria(payload)
        .then((res) => {
            fetchTaskData();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const deleteCriteria = (criteriaId) => {
        const payload = {
            criteria_id: criteriaId
        }
        api.deleteComponentCriteria(payload)
        .then((res) => {
            fetchTaskData();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const updateCriteriaStatus = (criteriaId, status) => {
        const payload = {
            criteria_id: criteriaId,
            status: status
        }
        api.updateComponentCriteria(payload)
        .then((res) => {
            fetchTaskData();
        })
        .catch((err) => {
            console.log(err)
        })
    }
    const updateCriteriaDescription = (criteriaId, description) => {
        const payload = {
            criteria_id: criteriaId,
            description: description
        }
        api.updateComponentCriteria(payload)
        .then((res) => {
            fetchTaskData();
        })
        .catch((err) => {
            console.log(err)
        })
    }
    const directToTaskPage = () => {
        history.push(`/workspaces/${task.workspace_id}/c/${task._id}`)
    }
    const canMoveTask = () => {
        if (!openMoveModal) return false
        if (!parentData) return false
        if (!parentData.name) return false
        if (!hasPermission(permissions, 'component_update')) return false
        if (['board', 'list'].includes(parentData.name)) return true
        return false
    }
    const canMarkComplete = () => {
        if (taskData.status === 'active') return true
        return false
    }
    const canDelete = () => {
        if (taskData.status === 'completed') return false
        if (!hasPermission(permissions, 'component_delete')) return false
        return true
    }
    const markTaskComplete = () => {
        const taskId = task._id;
        let attributes = {
            active: false,
            date_completed: new Date(),
            progress: 1
        }
        const payload = {
            attributes: attributes,
            status: 'completed',
            completed: new Date()
        }
        const path = `/workspace/components/${taskId}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchTaskData();
            if (fetchBoard) fetchBoard()
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const fetchTaskData = () => {
        try {
            const payload = {
                componentId: task._id,
                workspaceId: task.workspace_id
            }
            api.getWorkspaceComponent(payload)
            .then((res) => {
                if (res.data.response.component) {
                    setChildrenComponents(res.data.response.children_components)
                    setTaskData(res.data.response.component)
                    setComments(res.data.response.component.comments)
                    setParentData(res.data.response.parent)
                    setTags(res.data.response.component.component_tags)
                    setPotentialBonds(res.data.response.potential_bonds)
                    setCommentsLoaded(true)
                }                
            })
            .catch((err) => {
                console.log(err)
            }) 
        } catch (error) {
            console.log(error)
        }
    }
    const fetchFullAlignmentInformation = async () => {
        if (!task) return
        if (!task._id) return
        api.getComponentAlignments({componentId: task._id})
        .then((res) => {
            if (res.data) {
                if (res.data.response) {
                    setResources(res.data.response.alignments)
                }
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }
    const getPotentialBondIds = () => {
        try {
            if (resources.length === 0) return potentialBonds.as_parent
            const potentialChildrenIds = Array.from(potentialBonds.as_parent)
            const resourceIds = resources.map(r => r._id)
            return potentialChildrenIds.filter((potentialChildId) => {
                if (resourceIds.includes(potentialChildId)) return false
                return true
            })
        } catch (error) {
            return []
        }
    }
    const createComment = () => {
        if (newComment) {
            const payload = {
                content: newComment,
                target_id: task._id,
                type: "component"
            }
            setNewComment("")
            api.createComponentComment(payload)
            .then((res) => {
                fetchTaskData();
            })
            .catch((err) => {
                console.log(err);
            })
        }
    }
    const deleteComment = (commentId) => {
        const payload = {
            comment_id: commentId
        }
        api.deleteComponentComment(payload)
        .then((res) => {
            fetchTaskData();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const updateComment = (commentId, content) => {
        const payload = {
            comment_id: commentId,
            content: content
        }
        api.updateComponentComment(payload)
        .then((res) => {
            fetchTaskData();
        })
        .catch((err) => {
            console.log(err)
        })
    }
    const submitTaskUpdate = (payload) => {
        const path = `/workspace/components/${task._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchTaskData();
            if (fetchBoard) {
                fetchBoard();
            }
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const getCreatedAtText = () => {
        try {
            let suffix = ""
            const matchingTeammate = team.find(t => t._id === task.creator_id)
            if (matchingTeammate) suffix = ` by ${getUserNameFromId(team, task.creator_id)}`
            return `Created ${format(parseISO(task.createdAt), 'PPP')}${suffix}`
        } catch (error) {
            return false
        }
    }
    const getCompletedAtText = () => {
        try {
            if (taskData.status === "completed") return `Completed ${format(parseISO(task.completed), 'PPP')}`
            if (taskData.status === "archived") return "Archived"
            return ""
        } catch (error) {
            return "Complete"
        }
    }
    const updateTaskDescription = (newDescription) => {
        submitTaskUpdate({
            attributes: {
                details: newDescription
            }
        })
    }
    const saveChangesWithMultimedia = (formData) => {
        updateComponentDescriptionWithFileSupport(formData, task.workspace_id, task._id)
        return true
    }
    const moveToNewParent = () => {
        if (!openMoveModal) return
        openMoveModal()
    }
    const updateDueDate = (d) => {
        setDueDate(format(d, 'yyyy-MM-dd'))
    }
    const getAllComponents = async () => {
        if (!task) return
        if (task.workspace_id) {
            var payload = { workspaceId: componentData.component.workspace_id }
        }
        api.getAllWorkspaceComponents(payload)
        .then((res) => {
            if (res.data) {
                setAllComponents(res.data.response.components);
            } else {
                console.log('Problem fetching components')
            }
        })
        .catch((err) => {
            console.log(err);
        });        
    }
    const removeMarker = (componentId) => {
        if (!hasPermission(permissions, 'component_update')) return
        const bondedChild = childrenComponents.find(a => a.child_id === componentId)
        if (!bondedChild) return
        const bondId = bondedChild._id
        const payload = {
            workspaceId: task.workspace_id,
            bondId: bondId
        }
        api.deleteBond(payload)
        .then((res) => {
            fetchTaskData()
            fetchFullAlignmentInformation()
        })
        .catch((err) => {
            console.log(err);
        });
    }
    const addMarker = (cId) => {
        setIsAddingNewResource(false)
        const payload = {};
        payload['workspace_id'] = task.workspace_id;
        payload['child_id'] = cId;
        payload['parent_id'] = task['_id'];
        api.createBond(payload)
        .then((res) => {
            fetchTaskData();
            fetchFullAlignmentInformation();
        });
    }
    const fetchAlignments = () => {
        getAllComponents()
        fetchTaskData();
        fetchFullAlignmentInformation();
    }
    const showColorChangeMenuHandlingDelays = () => {
        if (delayHandler) clearTimeout(delayHandler)
        setIsShowingColorChangeMenu(true)
    }
    const hideColorChangeMenuWithDelay = () => {
        if (!isShowingColorChangeMenu) return
        const timeoutId = setTimeout(() => {
            setIsShowingColorChangeMenu(false);
        }, 100);
        setDelayHandler(timeoutId);
    }
    const getHeaderStyles = () => {
        try {
            if (!taskColorName || taskColorName === "none") return {}
            const cardColor = getColorHexMapFromName(taskColorName).hex
            return {
                borderBottom: `2px solid ${cardColor}`,
            }
        } catch (error) {
            return {}
        }
    }
    const getFooterStyles = () => {
        try {
            if (!taskColorName || taskColorName === "none") return {}
            const cardColor = getColorHexMapFromName(taskColorName).hex
            return {
                borderTop: `2px solid ${cardColor}`,
            }
        } catch (error) {
            return {}
        }
    }
    const scrollToTop = () => {
        if (modalTaskContent.current) modalTaskContent.current.scrollTop = 0
    }
    useEffect(() => {
        fetchTaskData()
        fetchFullAlignmentInformation()
        getAllComponents()
        scrollToTop()
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (actionMenuRef.current && !actionMenuRef.current.contains(event.target)) {
                setIsShowingActionMenu(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [actionMenuRef])
    useEffect(() => {
        if (criteria !== taskData.criteria) {
            setCriteria(taskData.criteria);
        }
        setTaskColorName(taskData.attributes.color_name)
        setOwnerId(taskData.owner_id)
        setDueDate(taskData.attributes.date_due)
    // eslint-disable-next-line
    }, [taskData])
    useEffect(() => {
        if (ownerId !== taskData.owner_id) {
            const payload = {
                owner_id: ownerId
            }
            submitTaskUpdate(payload);
        }
    // eslint-disable-next-line
    }, [ownerId])
    useEffect(() => {
        if (dueDate !== taskData.attributes.date_due) {
            const payload = {
                attributes: {
                    date_due: dueDate
                }
            }
            submitTaskUpdate(payload);
        }
    // eslint-disable-next-line
    }, [dueDate])
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (resourceMenuRef.current && !resourceMenuRef.current.contains(event.target)) {
                setIsShowingAddNewResourceMenu(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [resourceMenuRef])
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (colorChangeMenuRef.current && !colorChangeMenuRef.current.contains(event.target)) {
                setIsShowingColorChangeMenu(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [colorChangeMenuRef])
    return (
        <div className="alignment-modal-background modal-full-screen-edit-task-modal-background">
            <div className="modal-intermediate-outer-container">

                {isAddingNewResource && 
                <div style={{zIndex: 400}}>
                    <WorkspaceCompInitiativeAddMarker types={["link", "file"]} allComponents={allComponents} messaging={{title: "Add new resource", empty: "All resources have already been linked!"}} setIsAddingNewMarker={setIsAddingNewResource} potentialBondIds={getPotentialBondIds()} createNewBond={addMarker}/> 
                </div>
                 }
                 {isShowingCreateNewResourceModal &&
                 <div style={{zIndex: 400}}>
                    <ModalCreateNewResourceInProject parentProjectComponent={task} hideModal={() => setIsShowingCreateNewResourceModal(false)} fetchAlignments={fetchAlignments}/>
                 </div>
                 }

                <div className="modal-edit-task-container-responsive-width">
                    <div className="modal-edit-task-header" style={getHeaderStyles()}>
                        <div className="modal-edit-task-header-inner">
                            {canMarkComplete() &&
                            <>
                            <div className="modal-edit-task-owner-date-mobile" style={{fontSize: "16px", margin: 0, fontWeight: 600 }}>Edit Task</div>
                            <div className="modal-edit-task-owner-date-desktop">
                                <div className="modal-edit-task-header-owner-container">
                                    <OwnerSelectorDropdown permissions={permissions} proposedOwnerId={ownerId} team={team} setProposedOwnerId={setOwnerId}/>
                                </div>
                                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between"}}>
                                    <span className="modal-edit-task-header-mini-heading">Due Date</span>
                                    <DateBoxEditable component={taskData} side="right" editable={hasPermission(permissions, 'component_update')} date={parseISO(dueDate)} label="" onupdate={updateDueDate} clearDate={() => setDueDate("")} /> 
                                </div>
                            </div>
                            </>
                            }
                            {!canMarkComplete() && <div className="modal-edit-task-header-completed-text"><ImCheckmark/>{getCompletedAtText()}</div> }
                            <div className="modal-edit-task-header-actions">

                                {/* Action Menu */}
                                <div className="modal-edit-task-header-action" style={{position: "relative"}} ref={actionMenuRef}>
                                    <MdMoreHoriz onClick={() => setIsShowingActionMenu(!isShowingActionMenu)}/>
                                    {isShowingActionMenu &&
                                    <div className="modal-edit-task-header-action-dropdown">
                                        <div className="modal-edit-task-header-action-menu">
                                            <span onClick={directToTaskPage}><MdLaunch/>Go to task page</span>
                                            {canMoveTask() && <span onClick={moveToNewParent}><ImFolder/>Move task</span>}
                                            {hasPermission(permissions, 'component_update') && canMarkComplete() && <span onClick={markTaskComplete}><ImCheckmark/>Mark task complete</span>}
                                            {hasPermission(permissions, 'component_update') &&
                                                <span style={{ position: "relative" }} onMouseEnter={showColorChangeMenuHandlingDelays} onMouseLeave={hideColorChangeMenuWithDelay}>
                                                    <FaAngleLeft/>
                                                    Task color
                                                    {isShowingColorChangeMenu &&
                                                    <div className="modal-edit-task-header-action-menu-colors">
                                                        <div className="modal-full-screen-edit-task-color-change-menu">
                                                            <div className="modal-full-screen-edit-task-color-change-menu-title">Task Color</div>
                                                            <div className="modal-full-screen-edit-task-color-change-colors">
                                                                {getAvailableCardColors().map((colorName) => (
                                                                    <div style={getColorChangeOptionStyle(colorName)} className="modal-full-screen-edit-task-color-change-color-circle" key={colorName} onClick={() => updateTaskColor(colorName)}>s</div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    }
                                                </span>
                                                }
                                            {(hasPermission(permissions, 'component_delete') && canDelete()) && !wantsToDelete && <span onClick={() => setWantsToDelete(true)}><FaRegTrashAlt/>Delete task</span>}
                                            {(hasPermission(permissions, 'component_delete') && canDelete()) && wantsToDelete && <span style={{backgroundColor: "red", color: "white"}} onClick={() => deleteTask(taskData._id)}><FaRegTrashAlt style={{color: "white"}}/>Are you sure?</span>}
                                        </div>
                                    </div>}
                                </div>

                                {/* Close Modal */}
                                <div className="modal-edit-task-header-action" onClick={closeTaskEdit}><MdClose/></div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-edit-task-content app-thin-scrollbar" ref={modalTaskContent}>

                        {/* Options on Mobile */}
                        {canMarkComplete() &&
                        <div className="modal-edit-task-owner-date-mobile">
                            <div className="modal-edit-task-header-owner-container">
                                <OwnerSelectorDropdown permissions={permissions} proposedOwnerId={ownerId} team={team} setProposedOwnerId={setOwnerId}/>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between"}}>
                                <span className="modal-edit-task-header-mini-heading">Due Date</span>
                                <DateBoxEditable component={taskData} side="right" editable={hasPermission(permissions, 'component_update')} date={parseISO(dueDate)} label="" onupdate={updateDueDate} clearDate={() => setDueDate("")} /> 
                            </div>
                        </div>
                        }



                        {/* Title */}
                        <div style={{cursor: "pointer"}}>
                            <EditableComponentTitle canWrap={true} permissions={permissions} component={task} activeComponentId={task._id} fetchComponent={fetchTaskData}/>
                        </div>
                        {/* Tags */}
                        <div style={{marginTop: "4px"}}><WorkspaceCompHashtags tags={tags} workspaceId={task.workspace_id} asButtons={false}/></div>
                        {/* Description */}
                        <div className="modal-edit-task-content-section modal-edit-task-content-section-width-control">
                            <h3>Description</h3>
                            <ComponentMainDescription permissions={permissions} component={task} updateDetails={updateTaskDescription} team={team} saveChangesWithMultimedia={saveChangesWithMultimedia}/>                                    
                        </div>
                        {/* Subtasks/Criteria */}
                        <div className="modal-edit-task-content-section modal-edit-task-content-section-width-control">
                            <h3>Subtasks</h3>
                            <div className="modal-full-screen-task-edit-criteria-container">
                                <div className="modal-full-screen-task-edit-criteria-item-list" style={{marginBottom: "12px"}}>
                                    {criteria.length > 0 && 
                                        <>
                                        {criteria.map((c) => (
                                            <ComponentCriteria permissions={permissions} updateCriteriaDescription={updateCriteriaDescription} deleteCriteria={deleteCriteria} updateCriteriaStatus={updateCriteriaStatus} key={c._id} data={c}/>
                                        ))}
                                        </>
                                        }
                                </div>
                                {hasPermission(permissions, 'component_update') && 
                                <div className="modal-full-screen-task-edit-criteria-item-new-container">
                                    <BlurProofInput inputRef={newSubtaskInputRef}>
                                        <input className="modal-full-screen-task-edit-criteria-item-new" ref={newSubtaskInputRef} type="text" placeholder="Add subtask..." value={proposedCriteria} onKeyPress={(e) => handleNewCriteriaPress(e)} onChange={(e) => setProposedCriteria(e.target.value)}/>
                                    </BlurProofInput>
                                    
                                    <div className="modal-full-screen-task-edit-criteria-item-new-post" onClick={createCriteria}>Create</div>
                                </div>
                                }
                            </div>
                        </div>
                        {/* Resources */}
                        <div className="modal-edit-task-content-section modal-edit-task-content-section-width-control">
                            <h3>Resources</h3>
                            <div className="modal-edit-task-resources-list">
                                {resources.map((resource) => (
                                    <ResourceReference key={resource._id} resource={resource} removeMarker={removeMarker} permissions={permissions} />
                                ))}
                                {hasPermission(permissions, 'component_update') && <span className="modal-full-screen-task-resources-add-button" style={{position: "relative"}} onClick={() => setIsShowingAddNewResourceMenu(!isShowingAddNewResourceMenu)}>
                                    + Add Resource
                                    {isShowingAddNewResourceMenu && (
                                        <div className="component-page-initiative-section-heading-action-button-menu" style={{left: 0, top: "20px"}}>
                                            <div className="component-page-initiative-section-heading-action-button-menu-opt" onClick={openCreateNewResourceModal}>New Resource</div>
                                            <div className="component-page-initiative-section-heading-action-button-menu-opt" onClick={openAddExistingResourceModal}>Add Existing Resource</div>
                                        </div>
                                    )}
                                </span>}
                                
                            </div>                                 
                        </div>
                        {/* Discussion */}
                        <div className="modal-edit-task-content-section modal-edit-task-content-section-width-control">
                            <h3>Discussion</h3>
                            <div className="modal-full-screen-task-edit-comments-lower">
                                <SimpleMentionInput inputClassName="modal-full-screen-task-edit-comment-new" autoFocus={true} type="text" placeholderText="Add a comment..." inputValue={newComment} setInputValue={setNewComment} handleInputPress={handleNewCommentPress} team={team}/>
                                <div className="modal-full-screen-task-edit-comment-post" style={{right: "16px"}} onClick={createComment}>Comment</div>
                            </div>
                            <div className="modal-full-screen-task-edit-comments-upper">
                                {commentsLoaded ? (
                                    <div className="modal-full-screen-task-edit-comments-container-inner">
                                        {comments.length ? (
                                            <div className="modal-full-screen-task-edit-comments-list">
                                                {comments.slice(0).reverse().map((comment) => (
                                                    <ComponentComment height={32} updateComment={updateComment} deleteComment={deleteComment} key={comment._id} comment={comment} team={team}/>
                                                ))}
                                            </div>
                                        ) : (
                                            <div style={{fontSize: "14px", textAlign: "center", color: "grey"}}>
                                                Be the first to leave a comment
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "center"}}>
                                        <LoadingSymbolFacebook/>
                                    </div>
                                    
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="modal-edit-task-footer" style={getFooterStyles()}>
                        <div className="modal-edit-task-footer-inner">
                            <span>{getCreatedAtText()}</span>
                            {hasPermission(permissions, 'component_update') && canMarkComplete() &&
                            <div onClick={markTaskComplete} className="modal-full-screen-task-edit-action-row-action">
                                <ImCheckmark/>
                                Mark as Complete
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}