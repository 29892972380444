import React from 'react'
import '../../styles/modals.css';
import { FaTimes } from 'react-icons/fa'
import { useState, useEffect, useRef } from 'react'
import { Editor, EditorState, RichUtils, convertToRaw } from 'draft-js';
import 'draft-js/dist/Draft.css'

export default function NewHomePost({hideCreateNewPostForm, createPost}) {
    const [canSubmit, setCanSubmit] = useState(() => { return false })
    const [postTextContent, setPostTextContent] = useState(() => { return "" })
    const editorRef = useRef()
    const [editorState, setEditorState] = useState(() => { return EditorState.createEmpty()})
    const postContent = () => {
        createPost({
            rich_text_content: postTextContent
        });
        hideCreateNewPostForm();
    }
    const handleKeyCommand = (command) => {
        const newState = RichUtils.handleKeyCommand(editorState, command)
        if (!newState) return 'not-handled'
        setEditorState(newState)
        return 'handled'
    }
    const toggleEditingState = (styleName) => {
        console.log(convertToRaw(editorState.getCurrentContent()))
        setEditorState(RichUtils.toggleInlineStyle(editorState, styleName)) 
    }
    useEffect(() => {
        if (postTextContent !== "") {
            setCanSubmit(true)
        } else {
            setCanSubmit(false)
        }
    // eslint-disable-next-line
    }, [postTextContent])
    useEffect(() => {
        if (!editorState) {
            setPostTextContent("")
            return
        }
        if (editorState.getCurrentContent().getPlainText() === "") {
            setPostTextContent("")
            return
        }
        setPostTextContent(JSON.stringify(convertToRaw(editorState.getCurrentContent())))
    // eslint-disable-next-line
    }, [editorState])
    useEffect(() => {
        editorRef.current.focus()
    },[])
    return (
        <div className="project-post-modal-background">
            <div className="project-post-form-container">

                <div className="project-post-form-header-row">
                    <h3>Create update</h3>
                    <FaTimes className="project-post-form-cancel" onClick={(e) => hideCreateNewPostForm()}/>
                </div>
                <div className="project-post-form-rich-text-controls">
                    <div className="project-post-form-rich-text-control" onClick={() => toggleEditingState('BOLD')}><strong>B</strong></div>
                    <div className="project-post-form-rich-text-control" onClick={() => toggleEditingState('ITALIC')}><em>i</em></div>
                    <div className="project-post-form-rich-text-control" onClick={() => toggleEditingState('UNDERLINE')} style={{textDecoration: "underline"}}>U</div>
                </div>

                <div className="project-post-form-rich-text-editor">
                    <Editor ref={editorRef} placeholder="What's your update all about?" editorState={editorState} onChange={setEditorState} handleKeyCommand={handleKeyCommand}/>
                </div>

                <div className="project-post-post-button-container">
                    {canSubmit ? (
                        <div className="project-post-post-button" onClick={postContent}>POST</div>
                    ) : (
                        <div className="project-post-post-button-invalid">POST</div>
                    )}
                </div>
                
                
            </div>
        </div>
    )
}
