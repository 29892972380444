import React from 'react'
import CalendarRow from './CalendarRow';
import CalendarFilter from './CalendarFilter';
import CalendarItemModal from './CalendarItemModal';
import CalendarCreateModal from './CalendarCreateModal';
import Api from '../../common/APIUtils'
import { useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { format, startOfMonth, addMonths, subMonths, getDay, subDays, addDays, getDaysInMonth, getMonth, endOfMonth, parseISO } from 'date-fns';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { IoPodiumSharp } from "react-icons/io5";
import { BsDot, BsFilterRight } from 'react-icons/bs';
import ModalTimelineEditTask from '../modals/ModalTimelineEditTask';
import { getColorFromIndex, hasPermission } from '../../common/Helpers';

export default function Calendar({events, vertical, componentTypeMap, workspaceMap, fetchEvents, colorByEventProperty, colorCodex, team, workspace, workspaceWorkstreamPermissionMap, permissions, context}) {
    const api = new Api();
    const history = useHistory()
    const [startDate, setStartDate] = useState(() => { return startOfMonth(new Date())})
    const [isShowingComponentTypeFilter, setIsShowingComponentTypeFilter] = useState(() => { return false })
    const [isShowingWorkstreamFilter, setIsShowingWorkstreamFilter] = useState(() => { return false })
    const [componentTypeFilter, setComponentTypeFilter] = useState(() => { return {} })
    const [workspaceFilter, setWorkspaceFilter] = useState(() => { return {} })
    const [workstreamFilter, setWorkstreamFilter] = useState(() => { return {} })
    const [workstreamFilterMap, setWorkstreamFilterMap] = useState(() => { return false })
    const [localEvents, setLocalEvents] = useState(() => { return [] })
    const [showDayModal, setShowDayModal] = useState(() => { return false })
    const [calendarLayout, setCalendarLayout] = useState(() => { return [] })
    const [showItemModal, setShowItemModal] = useState(() => { return false })
    const [showCreateModal, setShowCreateModal] = useState(() => { return false })
    const [itemModalItem, setItemModalItem] = useState(() => { return false })
    const [proposedDateNewItem, setProposedDateNewItem] = useState(() => { return false })
    const [selectedModalDay, setSelectedModalDay] = useState(() => { return false })
    const [slicedUpData, setSlicedUpData] = useState(() => { return [] })
    const updateComponent = (payload, taskId) => {
        // todo: update locally first
        updateLocalComponent(taskId, payload)
        const path = `/workspace/components/${taskId}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            if (fetchEvents) return fetchEvents()
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const updateLocalComponent = (updatedComponentId, proposedUpdates) => {
        try {
            let updatedEvents = Array.from(localEvents)
            const index = updatedEvents.findIndex(c => c._id === updatedComponentId)
            if (index < 0) return
            let targetEvent = updatedEvents[index]
            if ('status' in proposedUpdates) targetEvent.status = proposedUpdates['status']
            if ('completed' in proposedUpdates) targetEvent.completed = proposedUpdates['completed']
            if ('attributes' in proposedUpdates) {
                const existingAttributes = targetEvent['attributes']
                const updatedAttributes = {...existingAttributes, ...proposedUpdates['attributes']}
                targetEvent.attributes = updatedAttributes
            }
            updatedEvents[index] = targetEvent
            setLocalEvents(updatedEvents)
        } catch (error) {
            return false
        }
    }
    const goToItem = (item) => {
        if (!item.workspace_id || !item._id) return
        history.push(`/workspaces/${item.workspace_id}/c/${item._id}`)
    }
    const createComponentLocally = (createdComponent) => {
        try {
            let updatedLocalEvents = Array.from(localEvents)
            updatedLocalEvents.push(createdComponent)
            setLocalEvents(updatedLocalEvents)
        } catch (error) {
            return 
        }
    }
    const createNewTask = (payload) => {
        const path = `/components/work/task`;
        api.createWorkspaceComponent(payload, path)
        .then( (res) => {
            createComponentLocally(res.data.response.component)
            if (fetchEvents) return fetchEvents()
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const incrementMonth = () => {
        const nextMonth = addMonths(new Date(startDate), 1);
        setStartDate(nextMonth)
    }
    const decrementMonth = () => {
        const prevMonth = subMonths(new Date(startDate), 1);
        setStartDate(prevMonth)
    }
    const resetToToday = () => {
        setStartDate(startOfMonth(new Date()))
    }
    const getCodex = () => {
        if (colorCodex) return colorCodex
        return getWorkspaceIds()
    }
    const getWorkspaceIds = () => {
        if (!workspaceMap) return []
        return Object.keys(workspaceMap)
    }
    const getComponentTypeTypes = () => {
        if (!componentTypeMap) return []
        return Object.keys(componentTypeMap)
    }
    const getWorkspaceName = (workspaceId) => {
        if (!workspaceMap) return ""
        if (!workspaceMap[workspaceId]) return ""
        return workspaceMap[workspaceId]
    }
    const getCalendarLayout = () => {
        // get the first day of the month
        const monthStartDate = startOfMonth(startDate)

        // WEEK STARTS ON SUNDAY - CHANGE THE DAY WITH THE LINE BELOW IF NEEDED
        // get index of day of the week of the first day of the month
        const dayIndexOfWeekMonthStartsOn = getDay(monthStartDate)

        // Find number of days that need to be backfilled on the first row of dates --- the top left box of the calendar
        // the math works out... if the start of the month is a Sunday (index 6), we have 6 days of the previous month to fill it with (M,T,W,TH,F,SA)
        const totalDaysToFillOnFirstRow = dayIndexOfWeekMonthStartsOn 
        const startDateOfDisplayedCalendarRange = subDays(monthStartDate, totalDaysToFillOnFirstRow);

        // Find number of days that need to be filled out on the last row of dates
        const totalDaysToFillOnLastRow = 6 - getDay(subDays(endOfMonth(startDate), 1))

        // Calculate the total number of rows
        const totalDaysInMonth = getDaysInMonth(monthStartDate)
        const totalNumberOfDaysDisplayed = totalDaysInMonth + totalDaysToFillOnFirstRow + totalDaysToFillOnLastRow
        const totalRowsOfCalendarsToShow = totalNumberOfDaysDisplayed/7

        // Get start date of each row of the calendar
        let startDates = []
        for (let i = 0; i < totalRowsOfCalendarsToShow; i++) {
            startDates.push(addDays(startDateOfDisplayedCalendarRange, 7*i))         
        }
        return startDates
    }
    const getAdditionalCalendarStyles = () => {
        if (vertical) return {
            flexDirection: "column",
            position: "relative"
        }
        return {position: "relative"}
    }
    const sliceUpData = () => {
        // Cut off all data that doesn't fall between certain dates or in current filter set
        const filteredComponents = localEvents.filter((component) => {
            if (workspaceMap) {
                if (!component.workspace_id) return false
                if (!workspaceFilter[component.workspace_id]) return false
            }

            if (componentTypeMap) {
                if (!component.name) return false
                if (!componentTypeFilter[component.name]) return false
            }

            if (workstreamFilterMap) {
                if (component.parent) {
                    if (!workstreamFilter[component.parent._id]) return false
                }
            }

            if (!component.status) return false

            // Uncomment to display completed items by their completed date instead of their due date
            // if (component.status === 'completed' && component.completed) {
            //     var date = component.completed
            // }

            // if (component.status !== 'completed') {
            //     if (!component.attributes) return false
            //     if (!component.attributes.date_due) return false
            //     if (component.attributes.date_due === "") return false
            //     date = component.attributes.date_due
            // }
            var date = false
            if (component.status === 'completed' && component.completed) {
                date = component.completed
                if (component.attributes.date_due) date = component.attributes.date_due
            }

            if (component.status === 'completed' && !component.completed) return false

            if (component.status !== 'completed') {
                if (!component.attributes) return false
                if (!component.attributes.date_due) return false
                if (component.attributes.date_due === "") return false
                date = component.attributes.date_due
            }
            if (!date) return false
            const startRange = subDays(startDate, 7)
            const endRange = addDays(endOfMonth(startDate), 7)
            if (getMonth(parseISO(date)) === getMonth(startDate)) return true
            if ((parseISO(date) > startRange) && (parseISO(date) < endRange)) return true
            return false
        })
        return filteredComponents
    }
    const updateFilterState = (itemId, checked) => {
        // Update the workspace filter
        let update = {}
        update[itemId] = checked
        if (workspaceMap) setWorkspaceFilter({...workspaceFilter,  [itemId]: checked })
        if (componentTypeMap) setComponentTypeFilter({...componentTypeFilter, [itemId]: checked })
    }
    const updateWorkstreamFilterState = (itemId, checked) => {
        // Update the workspace filter
        let update = {}
        update[itemId] = checked
        if (workstreamFilterMap) setWorkstreamFilter({...workstreamFilter,  [itemId]: checked })
    }
    const getBackgroundColor = (component) => {
        try {
            const eventProp = component["workspace_id"]
            const index = getWorkspaceIds().indexOf(eventProp)
            return getColorFromIndex(index)
        } catch (error) {
            return getColorFromIndex(99)
        }
    }
    const activateItemModal = (item) => {
        if (item.name !== "task") return
        if (!item.parent) return
        setItemModalItem(item)
        setShowDayModal(false);
        setShowCreateModal(false)
        setShowItemModal(true)
    }
    const activateNewComponentModal = (proposedStartDate) => {
        setProposedDateNewItem(proposedStartDate)
        setShowItemModal(false)
        setShowDayModal(false)
        setShowCreateModal(true)
    }
    const deleteComponent = (componentId) => {
        removeLocalComponent(componentId)
        const payload = {
            componentId: componentId
        }
        api.deleteWorkspaceComponent(payload)
        .then((res) => {
            if (fetchEvents) return fetchEvents()
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const removeLocalComponent = (componentId) => {
        try {
            let updatedEvents = Array.from(localEvents)
            const index = updatedEvents.findIndex(c => c._id === componentId)
            if (index < 0) return
            updatedEvents.splice(index, 1)
            setLocalEvents(updatedEvents)
        } catch (error) {
            return false
        }
    }
    const getExtraCalendarContainerStyles = () => {
        if (context === "network") return {width: "100%"}
        if (showItemModal) return {marginRight: "480px"}
        return {}
    }
    const setAllItemsInWorkstreamFilterToBool = (bool) => {
        let filter = {}
        for (let i = 0; i < workstreamFilterMap.length; i++) {
            const workstream = workstreamFilterMap[i];
            filter[workstream._id] = bool
        }
        setWorkstreamFilter(filter)
    }
    const updateWorkstreamFilterOptions = () => {
        try {
            const copyOfWorkstreamsPermissionMap = Array.from(workspaceWorkstreamPermissionMap)
            let proposedWorkstreamFilters = []
            let initialFilter = {}
            for (let i = 0; i < copyOfWorkstreamsPermissionMap.length; i++) {
                const singleWorkspaceSet = copyOfWorkstreamsPermissionMap[i];
                proposedWorkstreamFilters = [...singleWorkspaceSet["workstreams"], ...proposedWorkstreamFilters]
            }
            if (proposedWorkstreamFilters.length === 0) return
            setWorkstreamFilterMap(proposedWorkstreamFilters)
            for (let j = 0; j < proposedWorkstreamFilters.length; j++) {
                const workstream = proposedWorkstreamFilters[j];
                initialFilter[workstream._id] = true
            }
            setWorkstreamFilter(initialFilter)
        } catch (error) {
            setWorkstreamFilterMap(false)
        }
    }
    useEffect(() => {
        let initialFilteredWorkspaces = {}
        let workspaceIds = getWorkspaceIds()
        for (let i = 0; i < workspaceIds.length; i++) {
            const id = workspaceIds[i]
            initialFilteredWorkspaces[id] = true
        }
        setWorkspaceFilter(initialFilteredWorkspaces)
    // eslint-disable-next-line
    }, [workspaceMap])
    useEffect(() => {
        let filteredComponents = {}
        let allComponentTypes = ["task", "list", "board", "goal", "initiative"]
        for (let i = 0; i < allComponentTypes.length; i++) {
            const componentType = allComponentTypes[i];
            filteredComponents[componentType] = true
        }
        setComponentTypeFilter(filteredComponents)
    }, [])
    useEffect(() => {
        if (!events || events.length === 0) return
        setLocalEvents(events)
    }, [events])
    useEffect(() => {
        if (showDayModal) setShowItemModal(false)
    }, [showDayModal])
    useEffect(() => {
        if (!showItemModal) return
        if (!itemModalItem) return
        // Push the updated item from events into the modal... update the itemModalItem from events
        const newItem = localEvents.find(o => { return o._id === itemModalItem._id})
        if (newItem) {
            setItemModalItem(newItem)
            return
        }
        setShowItemModal(false)
    // eslint-disable-next-line
    }, [localEvents])
    useEffect(() => {
        setCalendarLayout(getCalendarLayout())
    // eslint-disable-next-line
    }, [startDate])
    useEffect(() => {
        setSlicedUpData(sliceUpData())
    // eslint-disable-next-line
    }, [calendarLayout, componentTypeFilter, workspaceFilter, localEvents, workstreamFilter])
    useEffect(() => {
        if (!workspaceWorkstreamPermissionMap) return
        updateWorkstreamFilterOptions()
    // eslint-disable-next-line
    }, [])
    return (
        <div className="calendar-container" style={getAdditionalCalendarStyles()}>
            {/* Sidebar */}
            <div className="calendar-sidebar">
                <div className="mydesk-row-typ mydesk-weekly-date-navs" style={{gap: "12px"}}>
                    <div className="mydesk-weekly-container-date-nav-buttons">
                        <BiChevronLeft onClick={() => decrementMonth()}/>
                        <BsDot onClick={resetToToday} style={{fontSize: "20px"}}/>
                        <BiChevronRight onClick={() => incrementMonth()}/>
                    </div>
                    <h2 style={{marginRight: "32px"}}>{format(startDate, 'MMM yyyy')}</h2>
                    {componentTypeMap &&
                        <div className="calendar-filter-component-dropdown-container" style={{marginRight: "32px"}}>
                            <div className="calendar-filter-component-button" onClick={() => setIsShowingComponentTypeFilter(!isShowingComponentTypeFilter)}><BsFilterRight/> Filter By</div>
                            {isShowingComponentTypeFilter &&
                            <div className="calendar-filter-component-dropdown">
                                <div className="calendar-filter-section">
                                    <div className="calendar-filter-filters-container">
                                        {getComponentTypeTypes().map((componentType, index) => (
                                            <CalendarFilter key={componentType} index={index} filter={componentTypeFilter} displayText={componentTypeMap[componentType]} itemId={componentType} updateFilterState={updateFilterState}/>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    }
                    {workstreamFilterMap &&
                        <div className="calendar-filter-component-dropdown-container">
                            <div className="calendar-filter-component-button calendar-filter-component-button-workstreams" onClick={() => setIsShowingWorkstreamFilter(!isShowingWorkstreamFilter)}><IoPodiumSharp style={{transform: "rotate(90deg)"}} /> Workstreams</div>
                            {isShowingWorkstreamFilter &&
                            <div className="calendar-filter-component-dropdown calendar-filter-component-dropdown-workstreams">
                                <div className="calendar-filter-component-dropdown-mass-buttons">
                                    <span onClick={() => setAllItemsInWorkstreamFilterToBool(true)}>Select All</span>
                                    <span onClick={() => setAllItemsInWorkstreamFilterToBool(false)}>Clear</span>
                                </div>
                                <div className="calendar-filter-section">
                                    <div className="calendar-filter-filters-container app-thin-scrollbar">
                                        {workstreamFilterMap.map((workstream, index) => (
                                            <CalendarFilter key={workstream._id} index={index} filter={workstreamFilter} displayText={workstream.display_name} itemId={workstream._id} updateFilterState={updateWorkstreamFilterState} propertyStringToUseForColor={workstream._id} goToLink={`/workspaces/${workstream.workspace_id}/c/${workstream._id}`}/>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    }
                </div>
                {workspaceMap &&
                    <div className="calendar-filter-section">
                        <div className="calendar-filter-section-title">Workspaces</div>

                        <div className="calendar-filter-filters-container">
                            {getWorkspaceIds().map((workspace_id, index) => (
                                <CalendarFilter goToLink={`/workspaces/${workspace_id}`} key={workspace_id} index={index} filter={workspaceFilter} displayText={getWorkspaceName(workspace_id)} itemId={workspace_id} updateFilterState={updateFilterState}/>
                            ))}
                        </div>
                    </div>
                }
            </div>
            <div className="util-row util-align-stretch" style={getExtraCalendarContainerStyles()}>
                {/* Calendar */}
                <div className="calendar-rows">
                    <div className="calendar-row-header calendar-row">
                        <div className="calendar-cell-width-control">Sunday</div>
                        <div className="calendar-cell-width-control">Monday</div>
                        <div className="calendar-cell-width-control">Tuesday</div>
                        <div className="calendar-cell-width-control">Wednesday</div>
                        <div className="calendar-cell-width-control">Thursday</div>
                        <div className="calendar-cell-width-control">Friday</div>
                        <div className="calendar-cell-width-control">Saturday</div>
                    </div>
                    {calendarLayout.map((date) => (
                        <CalendarRow activateItemModal={activateItemModal} activateNewComponentModal={activateNewComponentModal} selectedModalDay={selectedModalDay} setSelectedModalDay={setSelectedModalDay} setShowDayModal={setShowDayModal} showDayModal={showDayModal} key={date} dateStart={date} events={slicedUpData} colorByEventProperty={colorByEventProperty} colorCodex={getCodex()} />
                    ))}
                </div>
                {/* Item Edit Modal */}
                {showItemModal && (context !== "network") && <ModalTimelineEditTask comments={false} permissions={permissions} states={[]} component={itemModalItem} componentId={itemModalItem._id} editTask={updateComponent} keyDate={new Date()} team={team} parent={itemModalItem.parent} closeRequest={() => setShowItemModal(false)} keepOpenOnSave={false} /> }
                {showItemModal && (context === "network") && <CalendarItemModal updateComponent={updateComponent} deleteComponent={deleteComponent} goToItem={goToItem} getWorkspaceName={getWorkspaceName} getBackgroundColor={getBackgroundColor} item={itemModalItem} setShowItemModal={setShowItemModal}/> }
            </div>
            {showCreateModal && workspaceWorkstreamPermissionMap && hasPermission(permissions, "component_create") && <CalendarCreateModal createTask={createNewTask} workspace={workspace} team={team} setShowCreateModal={setShowCreateModal} suggestedDate={proposedDateNewItem} workspaceWorkstreamPermissionMap={workspaceWorkstreamPermissionMap}/> }
        </div>       
    )
}
