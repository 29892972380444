module.exports.authenticatedHeaderPaths = [
    "/forms/licenses/:tier",
    "/home",
    "/p/join",
    "/account/plans",
    "/account/plan/confirm",
    "/forgot-password",
    "/reset-password",
    "/user/:userId",
    "/u/:userHandle",
    "/public/c/:componentId",
    "/network/",
    "/n/:networkSlug/:item/create",
    "/n/:networkSlug",
    "/n/:networkSlug/:view",
    "/n/:networkSlug/c/:componentId",
    "/workspaces/:workspaceId",
    "/workspaces/:workspaceId/measurements",
    "/workspaces/:workspaceId/library",
    "/workspaces/:workspaceId/dashboards",
    "/workspaces/:workspaceId/settings",
    "/workspaces/:workspaceId/c/:componentId",
    "/workspaces/:workspaceId/t/:tagName",
    "/workspaces/:workspaceId/catalog",
    "/desk",
    "/settings",
    "/billing",
    "/messages",
    "/workspaces/:workspaceId/new",
    "/workspaces/:workspaceId/measurements/new",
    "/app/feedback",
]

module.exports.unauthenticatedHeaderPaths = [
    "/",
    "/about",
    "/blog",
    "/login",
    "/register",
    "/welcome",
    "/privacy-policy"
]