import React,  { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';

import { formatDistanceToNowStrict, parseISO } from 'date-fns'

import { wrapHashtagsInSpan } from '../../common/Helpers';

import { IoClose } from "react-icons/io5";
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from "react-icons/md";
import ProfPicCircle from '../profiles/ProfPicCircle';

export default function PostMediaFullView({ imageUrls, focusIndex, focusUrl, hideView, post }) {
    const history = useHistory()
    const [currentIndex, setCurrentIndex] = useState(() => {
        if (focusIndex) return focusIndex
        return 0
    })
    const [showPostMeta, setShowPostMeta] = useState(() => { return true })
    const preventClosing = (event) => {
        event.stopPropagation()
    }
    const goToPrevious = () => {
        const isFirstImage = currentIndex === 0;
        if (isFirstImage) return
        const newIndex = isFirstImage ? imageUrls.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    }
    const isLexical = (content) => {
        try {
            if (content.description_editor && content.description_html) return true
            return false
        } catch (error) {
            return false
        }
    }
    const renderTextContent = () => {
        try {
            if (!post) return <></>
            if (post.type === 'component') return <></>
            if (post.text_content) return <div className="project-post-content">{post.text_content}</div>
    
            // Determine if it is a legacy rich_text_content post (draft.js) or current (lexical)
            if (!post.rich_text_content) return <></>
            let postContent = JSON.parse(post.rich_text_content)
            if (!isLexical(postContent)) return <></>
            return (
                <div className="project-post-content gadget-lexical-displayed-text-container" style={{padding: 0}}>
                    <div dangerouslySetInnerHTML={{ __html: wrapHashtagsInSpan(postContent.description_html, post.workspace_id) }}></div>
                </div>
            )
        } catch (error) {
            return <></>
        }
    }
    const goToNext = () => {
        const isLastImage = currentIndex === imageUrls.length - 1;
        if (isLastImage) return
        const newIndex = isLastImage ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    }
    const navToAuthorPage = () => {
        try {
            history.push(`/user/${post.creator}`)
        } catch (error) {
            return
        }
    }
    const updateMetaSettings = () => {
        try {
            if (!post) return setShowPostMeta(false)
            if (post.text_content) return setShowPostMeta(true)
            if (!post.rich_text_content) return setShowPostMeta(false)
            if (!isLexical(JSON.parse(post.rich_text_content))) return setShowPostMeta(false) // renders false if no text included
            setShowPostMeta(true)
        } catch (error) {
            return setShowPostMeta(false)
        }
    }
    const hideViewWithoutPropagation = (e) => {
        e.stopPropagation()
        hideView()
    }
    useEffect(() => {
        const handleKeyDown = (event) => {
            switch (event.key) {
                case 'ArrowLeft':
                    goToPrevious();
                    break;
                case 'ArrowRight':
                    goToNext();
                    break;
                case 'Escape':
                    hideView();
                    break;
                default:
                    break;
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
    // eslint-disable-next-line
    }, [currentIndex]);
    useEffect(() => {
        updateMetaSettings()
    // eslint-disable-next-line
    }, [post])
    return (
        <div className="modal-mgmt-background-overlay" style={{zIndex: 200}} onClick={(e) => hideViewWithoutPropagation(e)}>
            <div className="post-media-full-view-container" onClick={preventClosing}>
                <div className="post-media-full-view-panes">
                    <div className="post-media-full-view-pane-media">
                        {currentIndex > 0 && imageUrls.length > 0 && <div onClick={goToPrevious} className="post-image-carousel-arrow post-image-carousel-left-arrow"><MdOutlineArrowBackIosNew/></div>}
                        {currentIndex < (imageUrls.length - 1) && imageUrls.length > 0 && <div onClick={goToNext} className="post-image-carousel-arrow post-image-carousel-right-arrow"><MdOutlineArrowForwardIos/></div>}
                        <div className="post-media-full-view">
                            <img src={imageUrls[currentIndex]} alt="Full screen" />
                        </div>
                    </div>
                    {showPostMeta && post &&
                    <div className="post-media-full-view-pane-meta">
                        <div className="post-media-full-view-pane-meta-close" onClick={hideView}><IoClose/></div>
                        <div className="post-media-full-view-pane-meta-header">
                            <ProfPicCircle height={48} src={post.author_profile_pic_src} userId={post.creator} userName={post.author} />
                            <div className="post-media-full-view-meta-header-texts">
                                <div className="post-media-full-view-meta-author" onClick={navToAuthorPage}>{post.author}</div>
                                <div className="post-media-full-view-meta-created">{formatDistanceToNowStrict(parseISO(post.created))} ago</div>
                            </div>
                        </div>
                        <div className="post-media-full-view-content app-thin-scrollbar">
                            {renderTextContent()}
                        </div>
                    </div>
                    }
                    {!showPostMeta && post &&
                    <>
                    <div className="post-media-full-view-watermark post-media-full-view-watermark-pic" onClick={navToAuthorPage}>
                        <ProfPicCircle height={48} src={post.author_profile_pic_src} userId={post.creator} userName={post.author} disableClick={true} />
                    </div>
                    <div className="post-media-full-view-watermark post-media-full-view-watermark-name" onClick={navToAuthorPage}>{post.author}</div>
                    </>
                    }
                </div>

            </div>
        </div>
    )
}
