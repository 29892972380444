import React from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

export default function ModalWorkspaceOnboardingMessage({mainText, onconfirm}) {
    return (
        <div className="alignment-modal-background">
            <div className="modal-interaction-confirm-container">
                <ReactMarkdown className="feed-right-side-about-container modal-workspace-onboarding-message-container">{mainText}</ReactMarkdown>
                <div className="modal-task-create-submission-section">
                    <div className="modal-task-create-submit" onClick={(e) => onconfirm()}>Continue to Workspace</div>
                </div> 
            </div>
        </div>
    )
}
