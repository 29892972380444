import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';

import InternalMenuSelections from '../gadgets/InternalMenuSelections'
import WorkspaceResources from './WorkspaceResources';
import WorkspaceTemplate from './WorkspaceTemplate';

export default function WorkspaceLibrary({fetchWorkspace, components, team, workspaceId, subview, permissions, workspace}) {
    const history = useHistory()
    const [hubView, setHubView] = useState(() => { return "resources" })
    const updateView = (newView) => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('v', newView);
        history.push(`${window.location.pathname}?${searchParams.toString()}`);
        setHubView(newView);
    };
    const viewOptions = [
        {
            display_name: "Resources",
            id: "resources"
        },
        {
            display_name: "Templates",
            id: "templates"
        }
    ]
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const initialView = searchParams.get('v') || 'resources'
        setHubView(initialView);
    }, []);
    return (
        <div className="network-page">
            <div className="network-users-header-row" style={{marginBottom: "16px", marginTop: "16px"}}>
                <div className="network-page-header-text">
                    <h1>Library</h1>
                    <p>Workspace assets like resources and templates</p>
                </div>
            </div>
            <div className="workspace-library">
                <div className="workspace-library-menu-selectors">
                    <InternalMenuSelections updateView={updateView} view={hubView} viewOptions={viewOptions} />
                </div>
                <div className="workspace-library-display-container">
                    {hubView === "resources" && <WorkspaceResources fetchWorkspace={fetchWorkspace} components={components} team={team} workspaceId={workspaceId} subview={false} permissions={permissions} />}
                    {hubView === "templates" && <WorkspaceTemplate workspaceId={workspaceId} team={team} workspace={workspace} />}
                </div>
            </div>
        </div>
    )
}
