import React from 'react'
import { FaTimes, FaRegTrashAlt } from 'react-icons/fa'
import { MdOpenInNew } from 'react-icons/md'
import { useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import Api from '../../common/APIUtils';
import { hasPermission, toCapitalize } from '../../common/Helpers';
import { ImCheckmark, ImUndo } from 'react-icons/im'
import ComponentMainDescription from '../components/ComponentMainDescription';
import DateBoxEditable from '../components/misc/DateBoxEditable';

export default function CalendarItemModal({item, setShowItemModal, getBackgroundColor, getWorkspaceName, goToItem, updateComponent, deleteComponent}) {
    const api = new Api()
    const history = useHistory()
    const [itemId, setItemId] = useState(() => { return item._id })
    const [dueDate, setDueDate] = useState(() => { return item.attributes.date_due })
    const [permissions, setPermissions] = useState(() => { return [] })
    const [wantsToDelete, setWantsToDelete] = useState(() => { return false })
    const getCircleStyles = (component) => {
        return {
            backgroundColor: getBackgroundColor(component)
        }
    }
    const markAsComplete = () => {
        const payload = {
            attributes: {
                active: false,
                date_completed: new Date(),
                progress: 1,
            },
            status: "completed",
            completed: new Date(),
        }
        updateComponent(payload, item._id)
    }
    const updateDueDate = (d) => {
        setDueDate(format(d, 'yyyy-MM-dd'))
    }
    const reactivateItem = () => {
        const payload = {
            attributes: {
                active: true,
                progress: 0,
            },
            status: "active"
        }
        updateComponent(payload, item._id)
    }
    const attemptDelete = () => {
        if (!wantsToDelete) return setWantsToDelete(true)
        deleteComponent(item._id)
    }
    const fetchPermissionsForComponent = () => {
        if (!item._id) return
        api.getPermissionsAssociatedWithComponent(item._id)
        .then((res) => {
            setPermissions(res.data.response)
        })
        .catch((err) => {
            console.log(err.message)
        })
    }
    const updateDescription = (newDescription) => {
        const payload = {
            attributes: {
                details: newDescription
            }
        }
        updateComponent(payload, item._id)
    }
    const goToWorkspace = () => {
        try {
            if (!item.workspace_id) return
            history.push(`/workspaces/${item.workspace_id}`)
        } catch (error) {
            return
        }
    }
    const goToParent = () => {
        goToItem(item.parent)
    }
    const getParentInformation = () => {
        try {
            return (
                <div className="calendar-item-modal-item-parent-name">
                    <span onClick={goToParent}>{item.parent.display_name} &middot; {toCapitalize(item.parent.name)}</span>
                </div>
            )
        } catch (error) {
            return false
        }
    }
    useEffect(() => {
        if (!hasPermission(permissions, 'component_update')) return
        if (!item) return
        if (dueDate === item.attributes.date_due) return
        const payload = {
            attributes: {
                date_due: dueDate
            }
        }
        updateComponent(payload, item._id)
    // eslint-disable-next-line
    }, [dueDate])
    useEffect(() => {
        setWantsToDelete(false)
    // eslint-disable-next-line
    }, [item])
    useEffect(() => {
        fetchPermissionsForComponent()
    // eslint-disable-next-line
    }, [item])
    useEffect(() => {
        if (!hasPermission(permissions, 'component_update')) return
        if (!item) return
        if (dueDate === item.attributes.date_due) return
        setDueDate(item.attributes.date_due)
    // eslint-disable-next-line
    }, [itemId])
    useEffect(() => {
        if (!item) return
        if (item._id === itemId) return
        setItemId(item._id)
    // eslint-disable-next-line
    }, [item])
    return (
        <div className="calendar-item-modal-container">
            <div className="calendar-item-modal-filter-identifier" style={getCircleStyles(item)}>
            </div>
            <div className="calendar-item-modal-header">
                <FaTimes className="calendar-item-modal-cancel calendar-item-modal-action-icon" onClick={() => setShowItemModal(false)}/>
                <MdOpenInNew className="calendar-item-modal-action-icon" onClick={() => goToItem(item)}/>
                {!wantsToDelete && hasPermission(permissions, 'component_delete') && <FaRegTrashAlt className="calendar-item-modal-action-icon" onClick={attemptDelete}/>}
                {wantsToDelete && hasPermission(permissions, 'component_delete') && <span className="calendar-item-modal-action-icon calendar-item-modal-delete" onClick={attemptDelete}>Delete item?</span>}
                {item.status === 'active' && hasPermission(permissions, 'component_update') && <ImCheckmark className="calendar-item-modal-action-icon" onClick={markAsComplete}/>}
                {item.status === 'completed' && hasPermission(permissions, 'component_update') && <ImUndo className="calendar-item-modal-action-icon" onClick={reactivateItem}/>}
            </div>

            <div className="calendar-item-modal-item-identifiers">
                <span className="calendar-item-modal-item-type">{item.name}</span>
                <div className={`component-view-title-meta-status-${item.status}`}>{item.status}</div>
            </div>

            <div className="calendar-item-modal-item-display-name">
                {item.display_name}
            </div>
            
            {getParentInformation()}

           <div className="calendar-item-modal-item-parent-name">
                <span onClick={goToWorkspace}>{getWorkspaceName(item.workspace_id)}</span>
           </div> 

           <div className="calendar-item-modal-item-description">
                <ComponentMainDescription permissions={permissions} component={item} updateDetails={updateDescription}/>                                    
           </div>

            <div className="calendar-item-modal-item-date-container">
                <div className="calendar-item-modal-item-descriptor-title">Due Date</div>
                <DateBoxEditable side="right" component={item} editable={hasPermission(permissions, 'component_update')} date={parseISO(dueDate)} label="" onupdate={updateDueDate} clearDate={() => setDueDate("")} /> 
           </div>


        </div>
    )
}
