import React from 'react'
import { BiPlus } from 'react-icons/bi'
import { useState, useEffect } from 'react';
import Api from '../../common/APIUtils';
import { hasPermission } from '../../common/Helpers';

import ModalNetworkTeamCreate from '../modals/ModalNetworkTeamCreate';
import NetworkTeamManagementSection from './NetworkTeamManagementSection';
import ModalManageTeam from '../modals/ModalManageTeam';

export default function NetworkTeams({network, fetchNetwork, permissions, networkWorkspaces, networkUserData }) {
    let api = new Api()
    const [teams, setTeams] = useState(() => { return "" })
    const [selectedTeam, setSelectedTeam] = useState(() => { return {}})
    const [showTeamModal, setShowTeamModal] = useState(() => { return false })
    const [showCreateTeamModal, setShowCreateTeamModal] = useState(() => { return false })
    const fetchUsers = () => {
        if (!network.slug) return
        api.getNetworkTeams(network.slug)
        .then((res) => {
            if (res.data) {
                setTeams(res.data.response.teams)
            }
        })
        .catch((err) => {
            if (err.response) {
                if (err.response.status === 400) {
                    // Toast
                    console.log('Have a toast')
                } else {
                    // Toast
                    console.log('Have a toast')
                }
            }
            // Toast
            console.log('Have a toast')
        })
    }
    const goToCreateTeamPage = () => {
        setShowCreateTeamModal(true)
    }
    const hideModal = () => {
        setShowTeamModal(false)
        setShowCreateTeamModal(false)
        fetchUsers()
    }
    useEffect(() => {
        fetchUsers()
    // eslint-disable-next-line
    }, [network])
    useEffect(() => {
        if (!selectedTeam._id) return
        const selectedTeamId = selectedTeam._id
        let t = [...teams].filter((team) => {
            if (team._id === selectedTeamId) return true
            return false
        })
        setSelectedTeam(t[0])
    // eslint-disable-next-line
    }, [teams])
    return (
        <div className="network-hub-page">
            {showCreateTeamModal && <ModalNetworkTeamCreate hideModal={hideModal} network={network} networkUserData={networkUserData} /> }
            {showTeamModal && <ModalManageTeam network={network} team={selectedTeam} hideModal={hideModal} fetchUsers={fetchUsers} fetchNetwork={fetchNetwork}/> }
            
            <div className="network-hub-page-header-row">
                <div className="network-hub-page-header-text">Teams</div>
                <div className="network-page-header-actions">
                    {(teams.length > 0 && hasPermission(permissions, 'network_admin')) && 
                        <div className="network-page-add-workspace" onClick={goToCreateTeamPage}>
                            <BiPlus/>
                            <span>Create team</span>
                        </div>
                    }
                </div>
            </div>

            {network &&
                <div className="network-page-table-container">
                {/* Empty teams - getting started */}
                {((network) && (teams.length === 0)) && 
                    <div className="network-page-table-empty">
                        {hasPermission(permissions, 'network_admin') ? (
                            <>
                            <div className="network-page-table-empty-heading">
                                <h2>Build your first team</h2>
                                <p>Teams allow you to organize users in your network.  Then, you can assign teams to workspaces or permissions.</p>
                            </div>
                            
                            <div className="network-page-add-workspace" onClick={goToCreateTeamPage}>
                                <BiPlus/>
                                <span>Create your first team</span>
                            </div>
                            </>
                        ) : (
                            <>
                            <div className="network-page-table-empty-heading">
                                <h2>No teams have been created</h2>
                                <p>Teams allow you to organize users in your network.  Then, you can assign teams to workspaces or permissions.</p>
                            </div>
                            
                            <div>You don't have permission to create teams.</div>
                            </>
                        )}
                        
                    </div>
                }

                {((network) && (teams.length > 0)) && 
                    <div>
                        {teams.map((team) => (
                            <NetworkTeamManagementSection networkWorkspaces={networkWorkspaces} fetchNetwork={fetchNetwork} key={team._id} team={team} network={network} fetchUsers={fetchUsers} permissions={permissions}/>
                        ))}
                    </div>
                }

            </div>
            }
        </div>
    )
}
