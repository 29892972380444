import React from 'react'
import { useEffect, useState, useRef } from 'react';
import UserActivityDot from '../gadgets/UserActivityDot';
import Api from '../../common/APIUtils'
import ProfPicCircle from './ProfPicCircle';

export default function UserInfoTooltip({ userName, parentRef, userId, providedHeight }) {
    const api = new Api()
    const tooltipRef = useRef();
    const isLoaded = useRef(false);
    const [position, setPosition] = useState(() => { return { left: 0, top: 0 } });
    const [userData, setUserData] = useState(() => { return false });
    const tooltipWidth = 280;
    const tooltipHeight = 80;
    const tooltipStyle = {
        top: position.top,
        minHeight: `${tooltipHeight}px`,
        left: position.left,
        width: `${tooltipWidth}px`,
    };
    const fetchUserActivity = () => {
        try {
            if (!userId) return
            if (isLoaded.current) return
            api.getUserInfo(userId)
            .then((res) => {
                setUserData(res.data.response.user)
                isLoaded.current = true
            })
            .catch((err) => { return })
        } catch (error) {
            return
        }
    }
    useEffect(() => {
        if (parentRef.current && tooltipRef.current) {
            const parentRect = parentRef.current.getBoundingClientRect();
            let calculatedLeft = parentRect.right - tooltipWidth
            let calculatedTop = parentRect.top - tooltipHeight - (providedHeight * 1.2)
            if (calculatedLeft + tooltipWidth > window.innerWidth) { calculatedLeft = window.innerWidth - tooltipWidth }
            if (calculatedLeft < 0) { calculatedLeft = parentRect.left }
            if (calculatedTop < 0) { calculatedTop = parentRect.bottom }
            setPosition({ top: calculatedTop, left: calculatedLeft });
        }
    // eslint-disable-next-line
    }, [parentRef]);
    useEffect(() => {
        if (!userId) return
        fetchUserActivity()
    // eslint-disable-next-line
    }, [userId])
    return (
        <div ref={tooltipRef} style={tooltipStyle} className="prof-tooltip-container">
            <div className="prof-tooltip-header">
                <div className="prof-tooltip-header-information">
                    {userData && <ProfPicCircle height={56} src={userData.profile_picture_url} userId={userData._id} userName={`${userData.firstName} ${userData.lastName}`} disableClick={true} />}
                    <div className="prof-tooltip-username">
                        {userName}
                        <span className="prof-tooltip-handle">@{userData.handle}</span>
                    </div>
                </div>
                {userData.status && <div className="prof-tooltip-status">{userData.status}</div>}
                {userData && <span className="prof-tooltip-dot"><UserActivityDot height={8} lastLogin={userData.last_login} /></span>}
            </div>
        </div>
    )
}