import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Api from '../../common/APIUtils';
import ProfPicRoundedSquare from '../profiles/ProfPicRoundedSquare';
import FeedInterface from '../posts/FeedInterface';
import SidebarFeedUrgentItem from '../sidebars/SidebarFeedUrgentItem';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { getUserNameFromId } from '../../common/Helpers';
import { formatDistanceStrict, parseISO } from 'date-fns'
import LoadingSymbolFacebook from '../gadgets/LoadingSymbolFacebook';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import FeedToolbar from '../gadgets/FeedToolbar';
import TeamProfPicPreviews from '../gadgets/TeamProfPicPreviews';
import { createMultiMediaPost } from '../../common/Api';

import ModalCreateMultiMediaWorkspacePost from '../modals/ModalCreateMultiMediaWorkspacePost';
import ModalCreateNewProject from '../modals/ModalCreateNewProject';

import { RiCheckboxCircleFill, RiDeleteBin7Fill, RiAddCircleFill, RiDirectionFill, RiShieldKeyholeFill } from 'react-icons/ri'

export default function WorkspaceHomeFeed({workspaceId, workspace, team}) {
    const api = new Api();
    const history = useHistory()
    const location = useLocation()
    const [feedFilter, setFeedFilter] = useState(() => { return false })
    const [showCreateNewProjectModal, setShowCreateNewProjectModal] = useState(() => { return false })
    const [feedFetchComplete, setFeedFetchComplete] = useState(() => { return false })
    const [isShowingNewPostForm, setIsShowingNewPostForm] = useState(() => { return false })
    const [sensitiveItems, setSensitiveItems] = useState(() => { return [] })
    const [dueSoon, setDueSoon] = useState(() => { return [] })
    const [stories, setStories] = useState(() => { return [] })
    const [filteredStories, setFilteredStories] = useState(() => { return [] })
    const [componentPostsLength, setComponentPostsLength] = useState(() => { return 7 })
    const postRefs = useRef({});
    const searchParams = new URLSearchParams(location.search);
    const pid = searchParams.get('pid');
    const actionToSymbolMap = {
        created: <RiAddCircleFill style={{color: "#174061"}}/>,
        reactivated: <RiDirectionFill style={{color: "#2973af"}}/>,
        archived: <RiShieldKeyholeFill style={{color: "rgb(255, 140, 105)"}}/>,
        deleted: <RiDeleteBin7Fill style={{color: "darkred"}}/>,
        completed: <RiCheckboxCircleFill style={{color: "green"}}/>
    }
    const getWorkspaceDescription = () => {
        try {
            if (workspace.network_id && workspace.network) {
                return <span>Workspace in the <Link className="unlink" style={{fontWeight: 700}} to={`/n/${workspace.network.slug}`}>{workspace.network.name}</Link> network</span>
            }
            if (workspace.workspace_type === "private") return <span>Private workspace created by <Link className="unlink" style={{fontWeight: 700}} to={`/user/${workspace.creator_id}`}>{getUserNameFromId(team, workspace.creator_id)}</Link></span>
        } catch (error) {
            return "Workspace"
        }
        
    }
    const getRelatedUpdateSymbol = (post) => {
        if (actionToSymbolMap[post.toLowerCase()]) return actionToSymbolMap[post.toLowerCase()]
        return false
    }
    const fetchWorkspaceFeed = () => {
        if (workspaceId) {
            const payload = {
                workspaceId: workspaceId
            }
            api.getWorkspaceFeed(payload)
            .then((res) => {
                if (res.data.response) {
                    setSensitiveItems(res.data.response.overdue);
                    setDueSoon(res.data.response.dueSoon);
                    setStories(res.data.response.stories);
                    setFeedFetchComplete(true)
                }
            })
            .catch((err) => {
                console.log(err);
            });   
        }
    }
    const createWorkspacePost = (formData) => {
        if (!formData) return
        createMultiMediaPost(formData)
        .then((res) => {
            setTimeout(() => { fetchWorkspaceFeed() }, 1500); // Non-deterministic - this stinks
        })
        .catch((err) => { return })
    }
    const showCreateNewPostForm = () => {
        setIsShowingNewPostForm(true);
    }
    const hideCreateNewPostForm = () => {
        setIsShowingNewPostForm(false);
    }
    const getUserProfPicUrl = () => {
        if (localStorage.getItem("gProfPicUrl")) {
            return localStorage.getItem("gProfPicUrl")
        } else {
            return false
        }
    }
    const goToLink = (link) => {
        if (!link) return
        history.push(link)
    }
    const navigateFromUpdatesBar = (post) => {
        if (post.action.toLowerCase() === 'deleted') return
        goToLink(`/workspaces/${post.workspace_id}/c/${post.component_id}`)
    }
    const updateFilteredStories = () => {
        if (feedFilter === "posts") {
            const onlyWorkspaceTypePosts = stories.filter(s => s.type === "workspace")
            setFilteredStories(onlyWorkspaceTypePosts)
            return
        }
        if (feedFilter === "updates") {
            const onlyUpdates = stories.filter((story) => {
                if (story.type === "workspace") return false
                return true
            })
            setFilteredStories(onlyUpdates)
            return
        }
        return setFilteredStories(stories)
    }
    const getComponentPosts = (count) => {
        const onlyComponentPosts = stories.filter((st) => {
            if (st.story) return false
            if (!st.action) return false
            if (st.type === 'component') return true
            return false
        })
        if (count) return onlyComponentPosts.slice(0,count)
        return onlyComponentPosts
    }
    const navigateToPage = (page) => {
        if (!page) return
        history.push(page)
    }
    const getAdditionalUpdateClassName = (post) => {
        if (post.action.toLowerCase() !== 'deleted') return "workspace-feed-side-workspace-update-clickable"
        return ""
    }
    const getPostTimeStampText = (post) => {
        if (!post) return ""
        if (!post.created) return ""
        try {
            return `${formatDistanceStrict(parseISO(post.created), new Date())} ago`
        } catch (error) {
            return ""
        }
    }
    useEffect(() => {
        if (!feedFilter) return
        localStorage.setItem("gFeedFilterName", feedFilter)
        // eslint-disable-next-line
    }, [feedFilter])
    useEffect(() => {
        if (localStorage.getItem("gFeedFilterName")) {
            setFeedFilter(localStorage.getItem("gFeedFilterName"))
        }
        if (!localStorage.getItem("gFeedFilterName")) setFeedFilter("all")
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        fetchWorkspaceFeed()
        // Fetch posts every 2 minutes
        const interval = setInterval(() => {
            fetchWorkspaceFeed()
        }, 120000)
        return() => clearInterval(interval)
        // eslint-disable-next-line
    }, [workspace]);
    useEffect(() => {
        updateFilteredStories()
        // eslint-disable-next-line
    }, [stories, feedFilter])
    useEffect(() => {
        if (!pid) return
        if (pid && postRefs.current[pid]) {
            const element = postRefs.current[pid];
            requestAnimationFrame(() => {
                const topPos = element.getBoundingClientRect().top + window.pageYOffset - 48;
                window.scrollTo({ top: topPos });
            });
        }
    }, [pid, feedFetchComplete, location, filteredStories]);
    return (
        <div>
            {showCreateNewProjectModal && <ModalCreateNewProject workspaceId={workspaceId} hideModal={() => setShowCreateNewProjectModal(false)} />}
            {workspace.banner_image_url ?
            <div className="workspace-home-feed-banner">
                <img src={workspace.banner_image_url} alt="" />
            </div>
            :
            <div className="workspace-home-feed-banner-blank">

            </div>
            }
            <div className="workspace-home-feed-upper-header-container">
                <div className="workspace-home-feed-main-title">
                    <h1>{workspace.name}</h1>
                    <p>{getWorkspaceDescription()}</p>
                    <TeamProfPicPreviews team={team} count={20} />
                </div>
                <FeedToolbar feedFilter={feedFilter} setFeedFilter={setFeedFilter}/>
            </div>
            <div className="workspace-home-feed-container">
                {/* New Post Modal */}
                {isShowingNewPostForm && <ModalCreateMultiMediaWorkspacePost workspaceId={workspaceId} hideModal={hideCreateNewPostForm} createPost={createWorkspacePost} team={team} />}

                <div className="workspace-home-feed-main">
                    {feedFetchComplete && 
                    <div className="workspace-home-feed-main-inner">
                        
                        <div className="workspace-home-feed-create-post-container">
                            <ProfPicRoundedSquare src={getUserProfPicUrl()} userId={localStorage.getItem("gId")} userName={localStorage.getItem("gFullName")} height={48}/>
                            <div className="workspace-home-feed-create-post" onClick={showCreateNewPostForm}>
                                <div>What's on your mind, {localStorage.getItem("gFirstName")}?</div>
                            </div>
                            <div className="workspace-home-feed-create-post-button" onClick={showCreateNewPostForm}>Post</div>
                        </div>


                        {stories.length > 0 ? (
                            <div className="workspace-home-feed-main-post-container workspace-home-feed-main-post-container-populated">
                                {filteredStories.map((story) => (
                                    <div ref={el => postRefs.current[story._id] = el} key={story._id}>
                                        <FeedInterface team={team} key={story._id} feedItem={story} fetchFeed={fetchWorkspaceFeed}/>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="workspace-home-feed-main-post-container">
                                <div className="workspace-home-feed-main-feed-empty-heading">Workspace Quick Start</div>
                                <div className="workspace-home-feed-main-feed-empty-options">
                                    <div onClick={() => navigateToPage(`/workspaces/${workspaceId}/settings?v=users`)}>Invite your team</div>
                                    <div onClick={() => setShowCreateNewProjectModal(true)}>Create a new workspace project</div>
                                    <div onClick={showCreateNewPostForm}>Post a welcome message for everyone to see</div>
                                </div>
                            </div>
                        )}


                    </div>
                    }
                </div>

                <div className="workspace-home-feed-side">
                    <div className="workspace-home-feed-side-inner">

                        {workspace.description &&
                        <div className="workspace-home-feed-side-section-container">
                            <ReactMarkdown className="feed-right-side-about-container">{workspace.description}</ReactMarkdown>
                        </div>
                        }                        

                        <div className="workspace-home-feed-side-section-container">
                            <div className="feed-right-side-heading-text">Your Deadlines</div>
                            {feedFetchComplete ? (
                                <div className="workspace-home-feed-side-urgent-items-container">
                                    {(sensitiveItems.length + dueSoon.length) > 0 ? (
                                        <div className="workspace-home-feed-side-urgent-items">
                                            {sensitiveItems.map((component) => (
                                                <SidebarFeedUrgentItem key={component._id} component={component} overdue={true}/>
                                            ))}
                                            {dueSoon.map((component) => (
                                                <SidebarFeedUrgentItem key={component._id} component={component}/>
                                            ))}

                                        </div>
                                    ) : (
                                        <div className="workspace-home-feed-side-urgent-items-caught-up">You have no overdue assignments or urgent items to attend to.</div>
                                    )}
                                </div>
                            ) : (
                                <LoadingSymbolFacebook/>
                            )}
                            
                        </div>


                        {getComponentPosts().length > 0 && 
                        <div className="workspace-home-feed-side-section-container">
                            <div className="feed-right-side-heading-text">Workspace Activity</div>
                            <div className="workspace-home-feed-side-urgent-items-container">
                                {getComponentPosts(componentPostsLength).map((post) => (
                                    <div key={post._id} className={`workspace-feed-side-workspace-update ${getAdditionalUpdateClassName(post)}`} onClick={() => navigateFromUpdatesBar(post)}>
                                        {getRelatedUpdateSymbol(post.action) && <div>{getRelatedUpdateSymbol(post.action)}</div>}
                                        
                                        <div>
                                            <div className="workspace-feed-side-update-title">{post.author} {post.action} {post.post_heading}</div>
                                            <div className="workspace-home-feed-side-urgent-subtitle">
                                                <span>{post.post_special}</span> &middot; {getPostTimeStampText(post)}
                                            </div>
                                        </div>
                                        
                                    </div>
                                ))}
                                {getComponentPosts().length > componentPostsLength &&
                                    <div style={{marginTop: "8px", textAlign: "center", cursor: "pointer", textDecoration: "underline", color: "rgb(41,115,175"}} onClick={() => setComponentPostsLength(componentPostsLength + 10)}>Show more</div>
                                }
                            </div>
                        </div>
                        }        
                    </div>
                    
                    

                </div>


            </div>
        </div>
    )
}
