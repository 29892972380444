import React from 'react'
import { MdClose } from 'react-icons/md';
import { useState, useEffect } from "react";

export default function ModalKpiSettings({setIsShowingKpiSettings, deleteKpi, updateKpi, component}) {
    const [customUnits, setCustomUnits] = useState(() => { return "" })
    const [page, setPage] = useState(() => { return "main" })
    const [initialized, setInitialized] = useState(() => { return false })
    const [proposedUnitSelection, setProposedUnitSelection] = useState(() => { return "" })
    const metricUnits = [
        {
            id: 0,
            name: 'Raw Number',
            value: ''
        },
        {
            id: 1,
            name: '$',
            value: '$'
        },
        {
            id: 2,
            name: '%',
            value: '%'
        },
        {
            id: 3,
            name: 'Custom',
            value: 'custom'
        }
    ]
    const getUnits = () => {
        if (proposedUnitSelection === 'custom') return customUnits
        const metric = metricUnits.find((a) => a.value === proposedUnitSelection)
        if (!metric) return ""
        return metric.value
    }
    const updateMeasurement = () => {
        setIsShowingKpiSettings(false);
        updateKpi({
            attributes: {
                units: getUnits()
            }
        })
    }
    const permanentlyDeleteKpi = () => {
        setIsShowingKpiSettings(false)
        deleteKpi()
    }
    const initializeComponentSettings = () => {
        try {
            if (initialized) return
            if (!component) return
            if (!component.attributes) return
            const existingUnitValue = component.attributes.units
            setProposedUnitSelection(existingUnitValue)
            if (!["%", "$", ""].includes(existingUnitValue)) {
                setProposedUnitSelection("custom")
                setCustomUnits(component.attributes.units)
            }
            setInitialized(true)
        } catch (error) {
            return
        }
    }
    useEffect(() => {
        initializeComponentSettings()
    // eslint-disable-next-line
    }, [component])
    return (
        <div className="alignment-modal-background">
            <div className="modal-task-create-container">

                {/* Header */}
                <div className="modal-task-create-header-row">
                    <div className="workcomp-modal-title">{component.display_name} Settings</div>
                    <div>
                        <MdClose className="component-template-edit-modal-header-close" onClick={() => setIsShowingKpiSettings(false)}/>
                    </div>
                </div>

                {page === 'main' &&
                <div>                
                    <div className="modal-workspace-component-edit-section modal-workspace-component-edit-section-doublewide">
                        <div className="modal-workspace-component-edit-subsection">
                            <h4>Units</h4>
                            <select value={proposedUnitSelection} onChange={(e) => setProposedUnitSelection(e.target.value)} name="metricUnitSelection">
                            {metricUnits.map((metricUnit) => (
                                <option key={metricUnit.id} value={metricUnit.value}>{metricUnit.name}</option>
                            ))}
                            </select>
                        </div>
                        {proposedUnitSelection === "custom" ? (
                            <div className="modal-workspace-component-edit-subsection">
                            <h4>Measurement</h4>
                            <input type="text" value={customUnits} onChange={(e) => setCustomUnits(e.target.value)}/>
                            </div>
                        ) : (
                            <div className="modal-workspace-component-edit-subsection"></div>
                        )}
                    </div>


                    <div className="modal-edit-big-button-container">
                        <div className="modal-edit-big-button-main" onClick={updateMeasurement}>Save</div>
                        <div className="modal-edit-big-button-cancel" onClick={() => setIsShowingKpiSettings(false)}>Cancel</div>
                        <div className="modal-edit-big-button-delete" onClick={() => setPage('delete')}>Delete KPI</div>
                    </div>
                </div>
                }

                {page === 'delete' && 
                <div className="modal-edit-action-delete-container">
                    <div className="modal-edit-action-text-bold">Are you sure you want to remove this KPI?</div>
                    <div className="modal-edit-action-text-underline">This action is permanent.</div>

                    <div className="modal-edit-big-button-container">
                        <div className="modal-edit-big-button-main" onClick={permanentlyDeleteKpi} style={{backgroundColor: "#be3b41"}}>Delete</div>
                        <div className="modal-edit-big-button-cancel" onClick={() => setPage('main')}>Nevermind</div>
                    </div>
                </div>
                }

            </div>
        </div>
    )
}
