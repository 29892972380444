import { Link } from 'react-router-dom'
import Api from "../common/APIUtils"
import { useState } from 'react'
import '../styles/forms.css';

export default function ForgotPassword() {
    const api = new Api();
    const appName = process.env.REACT_APP_APP_NAME;
    const [emailAddress, setEmail] = useState(() => { return "" });
    const [passwordResetRequested, setPasswordResetRequested] = useState(() => { return false })
    const [errorOccurred, setErrorOccurred] = useState(() => { return false })
    const requestPasswordReset = (e) => {
        e.preventDefault();
        const payload = {
            email: emailAddress.toLowerCase()
        }
        api.passwordRequestPasswordReset(payload)
        .then( (res) => {
            console.log(res)
            setPasswordResetRequested(true)
        })
        .catch((err) => {
            console.log(err);
            setErrorOccurred(true)
        });;
    }

    return (
        <div className="form-container-centered">
            <h4 className="login-application-name">{appName}</h4>

            {passwordResetRequested && <div>Request sent to {emailAddress}</div>}
            {!passwordResetRequested && !errorOccurred && 
                <form onSubmit={ requestPasswordReset }>
                    <input type="emailAddress" htmlFor="emailAddress" placeholder="Email Address" required onChange={ (e) => setEmail(e.target.value) } />
                    <input type="submit" value="Reset Password"/>
                </form>
            }
            {errorOccurred && <div>Something went wrong. Please wait a few minutes and try again.</div> }

            <div className="form-text-link">
                <Link className="unlink" to='/login'>Log In</Link>
            </div>
        </div>
    )
}
