import React, { useEffect, useState, useRef, useReducer } from 'react'
import { useHistory } from 'react-router-dom';
import Api from '../../common/APIUtils';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { sortByProperty, hasPermission, addComponentToUncollapsedLocalStorageList, removeComponentFromUncollapsedLocalStorageList } from '../../common/Helpers'

import { BiMessageRounded } from 'react-icons/bi'
import { AiOutlineSetting, AiOutlineDeploymentUnit, AiOutlineDoubleRight } from 'react-icons/ai'
import { IoSearchOutline, IoLayers, IoChevronForwardSharp } from 'react-icons/io5'
import { MdLibraryBooks } from 'react-icons/md'
import { RiListCheck2, RiDashboardLine, RiMistFill, RiFocus2Line } from 'react-icons/ri'
import { IoMdAdd } from 'react-icons/io'

import ModalCreateNewProject from '../modals/ModalCreateNewProject'
import ModalCreateWorkspaceBucket from './ModalCreateWorkspaceBucket';
import ConfirmAction from '../modals/ConfirmAction';

import WorkspaceSearch from '../workspaces/WorkspaceSearch';
import LoadingSymbolInline from '../gadgets/LoadingSymbolInline';
import SidebarWorkspaceNavDropdown from './nav/SidebarWorkspaceNavDropdown'

export default function InteractiveSidebar({page, permissions, workspace, fetchWorkspace, workspacesAndNetworks, networkWorkspaces, privateWorkspaceCount, workspaceId, components, workspaceName, componentId, sidebarCollapsed, setSidebarCollapsed, network, userWorkspaces}) {
    const history = useHistory()
    const api = new Api()
    const defaultBucketName = "_default_bucket029382348090"
    const [showWorkspaceSearch, setShowWorkspaceSearch] = useState(() => { return false })
    const [bonds, setBonds] = useState(() => { return [] })
    const [contextMenu, setContextMenu] = useState(() => { return { visible: false, x: 0, y: 0 } });
    const [workspaceSidebar, setWorkspaceSidebar] = useState(() => { return [] })
    const [isLoadingSidebar, setIsLoadingSidebar] = useState(() => { return true })
    const [proposedBucketNameForDeletion, setProposedBucketNameForDeletion] = useState(() => { return false })
    const sidebarRef = useRef(null);
    const [showCreateNewProjectModal, setShowCreateNewProjectModal] = useState(() => { return false })
    const [showRemoveBucketConfirmation, setShowRemoveBucketConfirmation] = useState(() => { return false })
    const [showCreateNewBucketModal, setShowCreateNewBucketModal] = useState(() => { return false })
    const [searchResults, searchResultsDispatch] = useReducer((state, action) => {
        if (action.type === "update_results") {
          return { ...state, results: action.results }
        }
        return state
    }, { results: [] })
    const updateBucketsDatabaseAsync = async (newWorkspaceSidebar) => {
        try {
            let proposedBucketOrder = newWorkspaceSidebar.map(x => x.bucket)
            proposedBucketOrder = proposedBucketOrder.filter(b => b !== false)
            api.updateWorkspaceBuckets(workspace._id, { buckets: proposedBucketOrder, workspace_id: workspace._id })
            .then((res) => { return })
            .catch((err) => { return })
        } catch (error) {
            return
        }
    }
    const removeBucketByName = () => {
        try {
            if (!proposedBucketNameForDeletion) return
            let proposedBucketOrder = workspaceSidebar.map(x => x.bucket)
            proposedBucketOrder = proposedBucketOrder.filter((bucket) => {
                if (bucket === false) return false
                if (bucket === proposedBucketNameForDeletion) return false
                return true
            })
            api.updateWorkspaceBuckets(workspace._id, { buckets: proposedBucketOrder, workspace_id: workspace._id })
            .then((res) => {
                fetchWorkspace()
                setProposedBucketNameForDeletion(false)
            })
            .catch((error) => { return setProposedBucketNameForDeletion(false) })
        } catch (error) {
            setProposedBucketNameForDeletion(false)
        }
    }
    const updateComponentBucketAsync = async (componentId, proposedBucketName) => {
        let b = ""
        if (proposedBucketName && proposedBucketName !== defaultBucketName) b = proposedBucketName
        const payload = {
            workspace_bucket: b
        }
        const path = `/workspace/components/${componentId}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => { return })
        .catch((err) => { return })
    }
    const addWorkspaceBucket = (bucketName) => {
        setShowCreateNewBucketModal(false)
        if (!workspace) return
        if (!workspace._id) return
        api.updateWorkspaceBuckets(workspace._id, { bucket_add: bucketName, workspace_id: workspace._id })
        .then((res) => {
            fetchWorkspace()
        })
        .catch((err) => {
            return
        })
    }
    const onDragEnd = (result) => {
        try {
            const { source, destination, type } = result;
            if (type === "buckets") {

                // Handle bucket reordering
                const newWorkspaceSidebar = Array.from(workspaceSidebar);
                const [reorderedItem] = newWorkspaceSidebar.splice(source.index, 1);
                let destinationIndex = destination.index
                if (destinationIndex === 0) destinationIndex = 1
                newWorkspaceSidebar.splice(destinationIndex, 0, reorderedItem);
                setWorkspaceSidebar(newWorkspaceSidebar);

                // Fire and forget server update
                updateBucketsDatabaseAsync(newWorkspaceSidebar)

            }
            if (type === "components") {
                const sourceBucketName = source.droppableId;
                const destBucketName = destination.droppableId;
                if (sourceBucketName === destBucketName && destination.index === source.index) return
                const newWorkspaceSidebar = Array.from(workspaceSidebar);
                // Find the index of source and destination buckets
                const sourceBucketIndex = newWorkspaceSidebar.findIndex(bucket => 
                    (bucket.bucket || defaultBucketName) === sourceBucketName
                );
                const destBucketIndex = newWorkspaceSidebar.findIndex(bucket => 
                    (bucket.bucket || defaultBucketName) === destBucketName
                );
                if (sourceBucketIndex === -1 || destBucketIndex === -1) {
                    console.error("Source or destination bucket not found");
                    return;
                }
                // Remove the component from the source bucket
                const [removed] = newWorkspaceSidebar[sourceBucketIndex].components.splice(source.index, 1);
                // Ensure the destination bucket has a components array
                if (!newWorkspaceSidebar[destBucketIndex].components) {
                    newWorkspaceSidebar[destBucketIndex].components = [];
                }
                // Add the component to the destination bucket
                newWorkspaceSidebar[destBucketIndex].components.splice(destination.index, 0, removed);
                // Update the component's bucket
                removed.workspace_bucket = newWorkspaceSidebar[destBucketIndex].bucket || defaultBucketName;
                // Update the state with the new sidebar structure
                setWorkspaceSidebar(newWorkspaceSidebar);

                // Update the component bucket
                if (sourceBucketName !== destBucketName && result.draggableId) {
                    updateComponentBucketAsync(result.draggableId, destBucketName)
                }

                // Update positions on the target bucket
                const destinationBucket = newWorkspaceSidebar[destBucketIndex]
                if (destinationBucket.components.length === 1) return
                const positionUpdatePayload = []
                for (let j = 0; j < destinationBucket.components.length; j++) {
                    const componentInfo = destinationBucket.components[j]
                    positionUpdatePayload.push({
                        id: componentInfo._id,
                        update: { position: j}
                    })                    
                }
                api.updateMultipleComponents({
                    bulk_updates: [positionUpdatePayload],
                    workspace_id: workspace._id
                })
            }
        } catch (error) {
            return
        }
    }
    const goToLibraryPage = () => {
        history.push(`/workspaces/${workspaceId}/library`)
    }
    const goToWorkspacePage = (pageName) => {
        history.push(`/workspaces/${workspaceId}/${pageName}`)
    }
    const handleContextMenu = (event) => {
        event.preventDefault();
        const target = event.target;
        const isExcludedElement = target.classList.contains('sidebar-left-section-workspace-navigation-item') || target.closest('.sidebar-left-workspace-nav-dropdown-container') !== null;
        if (!isExcludedElement) {
          const rect = sidebarRef.current.getBoundingClientRect();
          setContextMenu({
            visible: true,
            x: event.clientX - rect.left,
            y: event.clientY
          });
        }
    };
    const goToWorkspaceComponent = (selComponent) => {
        history.push(`/workspaces/${selComponent.workspace_id}/c/${selComponent._id}`)
    }
    const askForNewProject = () => {
        setShowCreateNewProjectModal(true)
        setContextMenu({ ...contextMenu, visible: false })
    }
    const askForNewCategory = () => {
        setShowCreateNewBucketModal(true)
        setContextMenu({ ...contextMenu, visible: false })
    }
    const askForNewWorkstream = () => {
        history.push(`/workspaces/${workspaceId}/catalog`)
    }
    const requestBucketDeletion = (name) => {
        try {
            setProposedBucketNameForDeletion(name)
            setShowRemoveBucketConfirmation(true)
        } catch (error) {
            return
        }
    }
    const handleCloseContextMenu = () => {
        setContextMenu({ ...contextMenu, visible: false });
    }
    const rebuildWorkspaceSidebar = () => {
        try {
            if (!workspace) return
            let proposedSidebar = []

            // Identify the workspace buckets - assumes workspace is loaded
            let buckets = []
            if(Array.isArray(workspace.buckets)) buckets = workspace.buckets

            // Get the components
            let proposedComponentOrdering = []
            let workspaceBonds = []
            let workspaceComponents = []
            if (Array.isArray(workspace.bonds)) workspaceBonds = workspace.bonds
            if (Array.isArray(workspace.components)) workspaceComponents = workspace.components
            const childrenComponentIds = workspaceBonds.map(c => c.child_id) // have a parent
            const componentsCopy = Array.from(workspaceComponents)
            if (componentsCopy.length === 0 && buckets.length === 0) {
                setWorkspaceSidebar(proposedSidebar)
                setIsLoadingSidebar(false)
                return
            }
            const approvedComponents = componentsCopy.filter((c) => {
                if (c.status !== "active") return false
                if (["initiative", "board", "list", "timeline"].includes(c.name)) return true
                return false
            })
            const sortedComponentsDisplayName = sortByProperty(approvedComponents, "display_name")
            const topLevelComponents = sortedComponentsDisplayName.filter(c => !childrenComponentIds.includes(c._id))
            const sortedTopLevelComponents = sortByProperty(topLevelComponents, "position")
            proposedComponentOrdering = sortedTopLevelComponents.map((component, index) => ({
                ...component,
                position: (index + 1) * 1000
            }))

            // Split components into buckets and non-buckets
            const componentsWithBucket = proposedComponentOrdering.filter(c => buckets.includes(c.workspace_bucket))
            const componentsNoBucket = proposedComponentOrdering.filter(c => !buckets.includes(c.workspace_bucket))

            // Identify all components that belong in each bucket
            for (let i = 0; i < buckets.length; i++) {
                const bucketName = buckets[i];
                const bucketComponents = componentsWithBucket.filter(c => c.workspace_bucket === bucketName)
                proposedSidebar.push({bucket: bucketName, components: bucketComponents, is_default: false })
            }

            // Add the non-bucketed components to the sidebar
            proposedSidebar.unshift({ bucket: false, components: componentsNoBucket, is_default: true })

            // Establish the sidebar configuration
            setWorkspaceSidebar(proposedSidebar)

            // Signal loading is done
            setIsLoadingSidebar(false)
        } catch (error) {
            console.log(error)
            return setIsLoadingSidebar(false)
        }
    }
    useEffect(() => {
        document.addEventListener('click', handleCloseContextMenu);
        return () => {
            document.removeEventListener('click', handleCloseContextMenu);
        };
    // eslint-disable-next-line
    }, []);
    const triggerCreateNewAction = (actionName) => {
        if (actionName === "project") return askForNewProject()
        return askForNewWorkstream()
    }
    useEffect(() => {
        if (!workspace) return
        if (!workspace.bonds) return
        setBonds(workspace.bonds)
    // eslint-disable-next-line
    }, [workspace])
    useEffect(() => {
        rebuildWorkspaceSidebar()
    // eslint-disable-next-line
    }, [workspace])
    return (
        <div>
            {showWorkspaceSearch && <WorkspaceSearch workspace={workspace} hideWorkspaceSearch={() => setShowWorkspaceSearch(false)} searchResults={searchResults} searchResultsDispatch={searchResultsDispatch} />}
            {showCreateNewProjectModal && <ModalCreateNewProject workspaceId={workspace._id} hideModal={() => setShowCreateNewProjectModal(false)} />}
            {showCreateNewBucketModal && <ModalCreateWorkspaceBucket createBucket={addWorkspaceBucket} hideModal={() => setShowCreateNewBucketModal(false)} /> }
            {showRemoveBucketConfirmation && proposedBucketNameForDeletion && <ConfirmAction mainText={`Remove ${proposedBucketNameForDeletion}? \n\nOnly the bucket will be deleted. Existing items will be placed above any remaining buckets.`} onconfirm={() => removeBucketByName()} hideMe={() => setShowRemoveBucketConfirmation(false)} /> }
            {!sidebarCollapsed &&
            <div id="sidebar-left" className="interactive-sidebar" onContextMenu={handleContextMenu} ref={sidebarRef}>
                <div className="interactive-sidebar-border-bottom">
                    <SidebarWorkspaceNavDropdown currentWorkspace={workspace} workspaces={userWorkspaces} />
                </div>
                <div className="sidebar-left-section-workspace-navigation-container interactive-sidebar-border-bottom" style={{paddingBottom: "8px", borderTop: "none"}}>
                    <div className="sidebar-left-section-workspace-navigation-item" onClick={() => goToWorkspacePage('')}><BiMessageRounded/> Feed</div>
                    <div className="sidebar-left-section-workspace-navigation-item" onClick={() => goToWorkspacePage('dashboards')}><AiOutlineDeploymentUnit/> Dashboard</div>
                    <div className="sidebar-left-section-workspace-navigation-item" onClick={() => setShowWorkspaceSearch(true)}><IoSearchOutline/> Search</div>
                    <div className="sidebar-left-section-workspace-navigation-item" onClick={goToLibraryPage}><MdLibraryBooks/> Library</div>
                    <div className="sidebar-left-section-workspace-navigation-item" onClick={() => goToWorkspacePage('settings')}><AiOutlineSetting/>Settings</div>
                </div>

                {isLoadingSidebar && <div style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}><LoadingSymbolInline /></div> }

                {!isLoadingSidebar &&
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="sidebar-buckets" type="buckets" direction="vertical">
                        {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className="interactive-sidebar-sections">
                            {workspaceSidebar.map((sidebarBucket, bucketIndex) => (
                            <Draggable 
                                key={sidebarBucket.bucket || defaultBucketName}
                                draggableId={sidebarBucket.bucket || defaultBucketName}
                                index={bucketIndex}
                                isDragDisabled={!sidebarBucket.bucket || !hasPermission(permissions, "component_update")}
                            >
                                {(provided, snapshot) => (
                                <div ref={provided.innerRef} {...provided.draggableProps} className={`interactive-sidebar-section ${snapshot.isDragging ? 'is-dragging' : ''}`}>
                                    <SidebarBucket provided={provided} bucketIndex={bucketIndex} sidebarBucket={sidebarBucket} bonds={bonds} workspace={workspace} triggerCreateNewAction={triggerCreateNewAction} goToWorkspaceComponent={goToWorkspaceComponent} permissions={permissions} componentId={componentId} defaultBucketName={defaultBucketName} requestBucketDeletion={requestBucketDeletion} />
                                </div>
                                )}
                            </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                        )}
                    </Droppable>
                </DragDropContext>
                }

                {hasPermission(permissions, 'component_create') && !isLoadingSidebar && components.length === 0 &&
                <>
                    <div style={{fontSize: "14px", color: "grey", marginLeft: "8px", marginTop: "8px", cursor: "pointer"}} onClick={askForNewProject}>+  Create project</div>
                    <div style={{fontSize: "14px", color: "grey", marginLeft: "8px", marginTop: "8px", cursor: "pointer"}} onClick={askForNewWorkstream}>+  Create workstream</div>
                </>
                }
                {!hasPermission(permissions, 'component_create') && !isLoadingSidebar && components.length === 0 &&
                    <div style={{fontSize: "14px", color: "grey", marginLeft: "8px", marginTop: "8px"}}>Nothing here...</div>
                }

                {contextMenu.visible && (
                <div className="interactive-sidebar-menu-floating interactive-sidebar-menu-floating" style={{ top: `${contextMenu.y}px`, left: `${contextMenu.x}px` }}>
                    <span onClick={askForNewCategory}>New bucket</span>
                    <span onClick={askForNewProject}>Create project</span>
                    <span onClick={askForNewWorkstream}>Create workstream</span>
                </div>
                )}
            </div>
            }

            {sidebarCollapsed &&
                <div className="sidebar-hamburger-show-container">
                    <div className="sidebar-hamburger" onClick={() => setSidebarCollapsed(false)}>
                        <AiOutlineDoubleRight />
                    </div>
                </div>
            }
        </div>

    )
}

function SidebarBucket({ provided, bucketIndex, sidebarBucket, bonds, workspace, triggerCreateNewAction, goToWorkspaceComponent, permissions, componentId, defaultBucketName, requestBucketDeletion }) {
    const [showBucketMenu, setShowBucketMenu] = useState(() => { return false });
    const [isCollapsed, setIsCollapsed] = useState(() => {
        if (!sidebarBucket) return false
        if (!sidebarBucket.bucket) return false
        if (!workspace) return false
        const expandedIds = JSON.parse(localStorage.getItem('gExpandedIds')) || [];
        if (expandedIds.includes(`${workspace._id}-${sidebarBucket.bucket}`)) return true
        return false
    })
    const bucketMenuRef = useRef(null)
    const toggleCollapse = () => {
        setShowBucketMenu(false)
        const newCollapseState = !isCollapsed
        setIsCollapsed(!isCollapsed)
        if (!workspace) return
        if (!sidebarBucket) return
        if (!sidebarBucket.bucket) return
        if (newCollapseState) addComponentToUncollapsedLocalStorageList(`${workspace._id}-${sidebarBucket.bucket}`)
        if (!newCollapseState) removeComponentFromUncollapsedLocalStorageList(`${workspace._id}-${sidebarBucket.bucket}`)
    }
    const handleContextMenu = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setShowBucketMenu(true)
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (bucketMenuRef.current && !bucketMenuRef.current.contains(event.target)) {
                setShowBucketMenu(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [bucketMenuRef])
    return (
        <div>
            <div {...provided.dragHandleProps} className={`interactive-sidebar-section-bucket-name ${bucketIndex === 0 ? "interactive-sidebar-section-bucket-first" : ""}`} onContextMenu={handleContextMenu} ref={bucketMenuRef}>
                <div className={`interactive-sidebar-section-bucket-toggle ${isCollapsed ? "open" : ""}`} onClick={toggleCollapse}><IoChevronForwardSharp/></div>
                {sidebarBucket.bucket || false}
                {showBucketMenu && 
                <div className="interactive-sidebar-section-bucket-menu-floating interactive-sidebar-menu-floating">
                    {/* <span>Rename bucket</span> */}
                    <span onClick={() => requestBucketDeletion(sidebarBucket.bucket)}>Remove bucket</span>
                </div>
                }
            </div>
            <Droppable droppableId={sidebarBucket.bucket ? sidebarBucket.bucket : defaultBucketName} type="components">
            {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef} className={`interactive-sidebar-components ${snapshot.isDraggingOver ? 'is-dragging-over' : ''}`}>
                <SidebarSectionSlim 
                    componentId={componentId} 
                    permissions={permissions} 
                    goToWorkspaceComponent={goToWorkspaceComponent} 
                    createNew={triggerCreateNewAction} 
                    title={false} 
                    components={sidebarBucket.components || []} 
                    allComponents={workspace.components} 
                    bonds={bonds}
                    isCollapsed={isCollapsed}
                />
                {provided.placeholder}
                </div>
            )}
            </Droppable>

        </div>
    )
}

function SidebarSectionSlim({title, components, allComponents, bonds, goToWorkspaceComponent, createNew, permissions, componentId, isCollapsed }) {
    return (
        <div className="sidebar-slim-section">
            {title &&
            <div className="sidebar-slim-section-title">
                <p>{title}</p>
                {hasPermission(permissions, 'component_create') && <IoMdAdd onClick={createNew}/>}
            </div>
            }

            <div className="sidebar-slim-components-list">
                {components.length > 0 && !isCollapsed && (
                    components.map((c, index) => (
                        <Draggable key={c._id} draggableId={c._id} index={index} isDragDisabled={!hasPermission(permissions, "component_update")}>
                        {(provided, snapshot) => (
                            <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`interactive-sidebar-draggable-item ${snapshot.isDragging ? 'is-dragging' : ''}`}
                            >
                            <SidebarSectionSlimItem 
                                componentId={componentId}
                                goToWorkspaceComponent={goToWorkspaceComponent} 
                                component={c} 
                                allComponents={allComponents} 
                                bonds={bonds}
                            />
                            </div>
                        )}
                        </Draggable>
                    ))
                )}
                {(components.length === 0 || isCollapsed) && <div className="interactive-sidebar-empty-bucket"></div>}
            </div>
        </div>
    )
}

function SidebarSectionSlimItem({component, allComponents, bonds, goToWorkspaceComponent, componentId}) {
    const [isCollapsed, setIsCollapsed] = useState(() => {
        if (!component) return true
        if (!component._id) return true
        const expandedIds = JSON.parse(localStorage.getItem('gExpandedIds')) || [];
        if (expandedIds.includes(component._id)) return false
        return true
    })
    const [children, setChildren] = useState(() => { return false })
    const [componentBondChildrenIds, setComponentBondChildrenIds] = useState(() => { return false })
    const getComponentBonds = () => {
        try {
            if (!bonds) return false
            if (!allComponents) return false
            if (!component) return false
            if (component.name !== "initiative") return false
            const bondsMasterList = Array.from(bonds)
            let bondsWhereComponentIsParent = bondsMasterList.filter((b) => {
                if (b.parent_id === component._id) return true
                return false
            })
            if (!bondsWhereComponentIsParent) return false
            return bondsWhereComponentIsParent.map(b => b.child_id)
        } catch (error) {
            return false
        }
    }
    const getAdditionalStyle = (id) => {
        if (id === componentId) return {
            backgroundColor: "rgb(223, 223, 223)",
            fontWeight: 700
        }
    }
    const parseChildren = () => {
        const childrenIds = Array.from(componentBondChildrenIds)
        let childrenComponents = allComponents.filter((c) => {
            if (!['list', 'board'].includes(c.name)) return false
            if (c.status !== 'active') return false
            if (childrenIds.includes(c._id)) return true
            return false
        })
        childrenComponents = childrenComponents.sort((a,b) => {
            return a.display_name.localeCompare(b.display_name)
        })
        return childrenComponents
    }
    const getRepresentativeSymbol = (componentType) => {
        if (componentType === "initiative") return <IoLayers/>
        if (componentType === "list") return <RiListCheck2/>
        if (componentType === "board") return <RiDashboardLine/>
        if (componentType === "timeline") return <RiMistFill/>
        if (componentType === "kresult") return <RiFocus2Line/>
    }
    const toggleCollapse = () => {
        const newCollapseState = !isCollapsed
        setIsCollapsed(!isCollapsed)
        if (!newCollapseState) addComponentToUncollapsedLocalStorageList(component._id)
        if (newCollapseState) removeComponentFromUncollapsedLocalStorageList(component._id)
    }
    const handleContextMenu = (event) => {
        event.stopPropagation()
    }
    useEffect(() => {
        if (!component) return
        if (!allComponents) return
        setComponentBondChildrenIds(getComponentBonds())
    // eslint-disable-next-line
    }, [component])
    useEffect(() => {
        if (!componentBondChildrenIds) return
        setChildren(parseChildren())
    // eslint-disable-next-line
    }, [componentBondChildrenIds])
    return (
        <div className="sidebar-slim-component-item-container" onContextMenu={handleContextMenu}>
            <div className="sidebar-slim-component-item-title-row" style={getAdditionalStyle(component._id)}>
                
                {/* No children */}
                {(!componentBondChildrenIds || componentBondChildrenIds.length === 0) && <div className="sidebar-slim-component-item-symbol">{getRepresentativeSymbol(component.name)}</div>}

                {/* Children - supports hovering */}
                {componentBondChildrenIds.length > 0 &&
                <div className="sidebar-slim-component-item-symbol sidebar-slim-component-item-symbol-with-children">
                    <IoChevronForwardSharp className={`sidebar-slim-component-item-symbol-arrow ${!isCollapsed ? "sidebar-slim-component-item-symbol-rotated" : ""}`} onClick={toggleCollapse}/>
                    <IoLayers className="sidebar-slim-component-item-symbol-folder" />
                </div>
                }
                
                <div className="sidebar-slim-component-item-title-text" onClick={() => goToWorkspaceComponent(component)}>
                    {component.display_name}
                </div>
            </div>
            {!isCollapsed && children &&
            <div className="sidebar-slim-component-item-children">
                {children.map((childComponent) => (
                    <div key={childComponent._id} onClick={() => goToWorkspaceComponent(childComponent)} className="sidebar-slim-component-item-title-row" style={{paddingLeft: "14px"}}>
                        <div className="sidebar-slim-component-item-title-text sidebar-slim-component-item-child" style={getAdditionalStyle(childComponent._id)}>
                            {getRepresentativeSymbol(childComponent.name)}
                            {childComponent.display_name}
                        </div>
                    </div>
                ))}
                {children.length === 0 && <div style={{fontSize: "14px", color: "grey", marginLeft: "14px", marginTop: "8px"}}>No active workstreams</div> }
            </div>
            }
            
        </div>
    )
}