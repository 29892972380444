import React from 'react'
import { useEffect, useState, useReducer, useRef } from 'react';
import { hasPermission } from '../../common/Helpers';
import LexicalTextEditor from '../gadgets/LexicalTextEditor';

import { FaImage } from "react-icons/fa6";
import { IoCloseCircleSharp } from 'react-icons/io5';
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from "react-icons/md";

import PostImagePreview from '../posts/PostImagePreview';
import PostMediaFullView from '../posts/PostMediaFullView';

import { getWorkspaceComponentMultimedia, removeWorkspaceComponentMedia } from '../../common/Api';

export default function ComponentMainDescription({ component, permissions, updateDetails, team, saveChangesWithMultimedia }) {
    const [componentId, setComponentId] = useState(() => { return "" })
    const [imageFiles, setImageFiles] = useState(() => { return [] });
    const [isEditing, setIsEditing] = useState(() => { return false })
    const [managableImageFiles, setManagableImageFiles] = useState(() => { return [] })
    const [componentImageUrls, setComponentImageUrls] = useState(() => { return [] })
    const [postImages, setPostImages] = useState(() => { return [] })
    const [isShowingFullView, setIsShowingFullView] = useState(() => { return false })
    const [multiMediaFocusUrl, setMultimediaFocusUrl] = useState(() => { return false })
    const [multiMediaFocusIndex, setMultimediaFocusIndex] = useState(() => { return 0 })
    const [imagesLoaded, setImagesLoaded] = useState(() => { return false })
    const fileInputRef = useRef(null);
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB
    const [mainDescription, mainDescriptionDispatch] = useReducer((state, action) => {
        if (action.type === "init_legacy") return { ...state, description: action.value }
        if (action.type === "init") return { ...state, description_html: action.value.description_html, description_editor: action.value.description_editor }
        // if (action.type === "update") return { ...state, description_editor: action.value }       
        if (action.type === "save") {
            const safeHtml = action.value.description_html
            const editorState = action.value.description_editor
            return { ...state, description_editor: editorState, description_html: safeHtml }
        }       
        return state
    }, { description: "", description_editor: false, description_html: false })
    const getHandleNoSymbol = (handle) => {
        if (handle.startsWith("@")) {
            return handle.slice(1);
        }
        return handle;
    }
    const adjustAnchorTags = (htmlString) => {
        const regex = /<a\s+([^>]*?)(href="[^"]+")([^>]*?)>/gi;
        return htmlString.replace(regex, (match, preHref, href, postHref) => {
            const relExists = /rel=["'][^"']*["']/i.test(match);
            const targetExists = /target=["'][^"']*["']/i.test(match);
            const relString = relExists ? '' : ` rel="noopener noreferrer"`;
            const targetString = targetExists ? '' : ` target="_blank"`;
            return `<a ${preHref} ${href}${relString}${targetString} ${postHref}>`;
        })
    }
    const wrapHashtagsInSpan = (htmlString) => {
        const regex = /(^|\s|>)(#{1,})(\w+)/g;
        const regexMention = /<span([^>]*)data-lexical-beautiful-mention-trigger="@"([^>]*)>(.*?)<\/span>/g;
        htmlString = adjustAnchorTags(htmlString)
        htmlString = htmlString.replace(regex, (match, space, hashes, hashtag) => {
            const span = document.createElement('a');
            span.className = 'gadget-hashtag unlink';
            span.href = `/workspaces/${component.workspace_id}/t/${hashtag}`
            span.textContent = `${hashes}${hashtag}`;
            const wrapper = document.createElement('div');
            wrapper.appendChild(span);
            return space + wrapper.innerHTML;
        });

        htmlString = htmlString.replace(regexMention, (match, before, after, innerContent) => {
            const mentionSpan = document.createElement('a');
            mentionSpan.className = 'gadget-hashtag unlink';
            mentionSpan.href = `/u/${getHandleNoSymbol(innerContent)}`;
            mentionSpan.innerHTML = innerContent;
            mentionSpan.target = '_blank';
            mentionSpan.rel = 'noopener noreferrer';
            const wrapper = document.createElement('div');
            wrapper.appendChild(mentionSpan);
            return `<span${before}${after}>${wrapper.innerHTML}</span>`;
        });
        return htmlString
    }
    const getDescription = () => {
        if (mainDescription.description_html) {
            return wrapHashtagsInSpan(mainDescription.description_html)
        }
        return mainDescription.description
    }
    const getDisplayDescription = () => {
        if (mainDescription.description_html) {
            const parsedHtml = mainDescription.description_html.replace(/<\/?[^>]+(>|$)/g, '')
            if (parsedHtml === "") return ""
            return mainDescription.description_html
        }
        return mainDescription.description
    }
    const getInitialState = () => {
        if (!mainDescription.description_editor) return false
        return mainDescription.description_editor
    }
    const fetchMultimedia = () => {
        if (!component) return
        if (!component.workspace_id) return
        getWorkspaceComponentMultimedia(component.workspace_id, component._id)
        .then((res) => {
            if (res.data) {
                if (res.data.signed_urls) {
                    setPostImages(res.data.signed_urls)
                    setImagesLoaded(true)
                }
                if (res.data.component) {
                    if (Array.isArray(res.data.component.image_urls)) setComponentImageUrls(res.data.component.image_urls)
                }
            }
        }).catch((err) => {
            setImagesLoaded(false)
            setPostImages([])
        })
    }
    const selectImageToView = (imageUrl, imageIndex) => {
        try {
            let proposedIndex = 0
            if (imageIndex) proposedIndex = imageIndex
            setMultimediaFocusIndex(proposedIndex)
            setMultimediaFocusUrl(imageUrl)
            setIsShowingFullView(true)
        } catch (error) {
            return
        }
    }
    const submitMultimediaChange = () => {
        try {
            const formData = new FormData()
            imageFiles.forEach(file => formData.append('images', file))
            formData.append('description_editor', mainDescription.description_editor)
            formData.append('description_html', mainDescription.description_html)
            formData.append('workspace_id', component.workspace_id)
            saveChangesWithMultimedia(formData)
            clearMultimedia()
            setTimeout(() => { fetchMultimedia() }, 1500); // Non-deterministic - this stinks
            return
        } catch (error) {
            return
        }
    }
    const submitBasicDescriptionChange = () => {
        try {
            const newDescription = {
                description_editor: mainDescription.description_editor,
                description_html: mainDescription.description_html
            }
            updateDetails(newDescription)
        } catch (error) {
            return false
        }
    }
    const clearMultimedia = () => {
        try {
            imageFiles.forEach(file => URL.revokeObjectURL(file));
            setImageFiles([])
        } catch (error) {
            return
        }
    }
    const saveChanges = () => {
        setIsEditing(false)
        if (saveChangesWithMultimedia) return submitMultimediaChange()
        submitBasicDescriptionChange()
    }
    const isLexicalDescription = () => {
        try {
            if (mainDescription.description_editor && mainDescription.description_html) return true
            return false  
        } catch (error) {
            return false
        }
    }
    const getLegacyDescription = () => {
        if (mainDescription.description) return mainDescription.description
        return false
    }
    const mobilizeDescription = () => {
        try {
            let d = component.attributes.description
            if (component.name === "task") d = component.attributes.details
            if (!d) return ""
            if (typeof d === "string") {
                if (component.name === "task") d = component.attributes.details
                mainDescriptionDispatch({type: "init_legacy", value: d})
                return
            }
            mainDescriptionDispatch({
                type: "init",
                value: {
                    description_editor: d.description_editor,
                    description_html: d.description_html
                }
            })
            
        } catch (error) {
            return ""
        }
    }
    const cancelEdits = () => {
        setIsEditing(false)
        mobilizeDescription()
        imageFiles.forEach(file => URL.revokeObjectURL(file));
        setImageFiles([])
    }
    const promptToSelectPhotos = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }
    const handleFileSelect = (event) => {
        const files = Array.from(event.target.files).slice(0, 5)
        const imageFiles = files.filter(file => file.type === 'image/jpeg' || file.type === 'image/png');
        handleMultimediaPasteAction(imageFiles)
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    }
    const updateProposedImagesSet = () => {
        try {
            // Combine existing and proposed images into the set show while editing
            let images = []
            for (let i = 0; i < imageFiles.length; i++) {
                const newFile = imageFiles[i];
                images.push({
                    source: URL.createObjectURL(newFile),
                    id: `${newFile.name}_${newFile.lastModified}`,
                    isExisting: false
                })
            }
            for (let j = 0; j < postImages.length; j++) {
                const existingFileUrl = postImages[j];
                images.push({
                    source: existingFileUrl,
                    isExisting: true
                })
            }
            setManagableImageFiles(images)
            return
        } catch (error) {
            return
        }
    }
    const handleMultimediaPasteAction = (newImages) => {
        setImageFiles((currentImages) => {
            const availableSlots = 5 - currentImages.length
            if (availableSlots > 0) {
                const allowedImages = newImages.filter((image) => {
                    if (!image.size) return false
                    if (image.size < MAX_FILE_SIZE) return true
                    return false
                })
                const imagesToAdd = allowedImages.slice(0, availableSlots)
                return [...currentImages, ...imagesToAdd]
            }
            return currentImages
        })
    }
    const removeImage = (file) => {
        if (!file.isExisting) {
            setImageFiles(currentFiles => currentFiles.filter((f) => {
                const id = `${f.name}_${f.lastModified}`
                if (id === file.id) return false
                return true
            }))
            return
        }
        detachMultimedia(file.source)
    }
    const detachMultimedia = (imageUrl) => {
        try {
            // The index should match the index of the image_urls stored
            const imageUrlIndex = postImages.indexOf(imageUrl)
            const removeUrl = componentImageUrls[imageUrlIndex]

            // Update the local arrays to keep them in sync
            const currentPostImages = Array.from(postImages)
            const filteredPostImages = currentPostImages.filter((im) => im !== imageUrl)
            const currentComponentImageUrls = Array.from(componentImageUrls)
            const removeComponentImageUrlName = currentComponentImageUrls[imageUrlIndex]
            const filteredComponentImageUrls = currentComponentImageUrls.filter((url) => url !== removeComponentImageUrlName)
            setPostImages(filteredPostImages)
            setComponentImageUrls(filteredComponentImageUrls)

            // Send the delete request to the server
            removeWorkspaceComponentMedia({ component_id: component._id, image_url: removeUrl })
            .then((res) => {
                if (res.data) {
                    if (res.data.urls || Array.isArray(res.data.urls)) setComponentImageUrls(res.data.urls)
                }
            })
            .catch((err) => { return })
        } catch (error) {
            return
        }
    }
    useEffect(() => {
        if (!component) return
        fetchMultimedia()
        setComponentId(component._id)
        if (Array.isArray(component.image_urls)) setComponentImageUrls(component.image_urls)
    // eslint-disable-next-line
    }, [component])
    useEffect(() => {
        if (!component) return
        if (!component.attributes) return
        mobilizeDescription()
    // eslint-disable-next-line
    }, [componentId])
    useEffect(() => {
        return () => {
            imageFiles.forEach(file => URL.revokeObjectURL(file));
        };
    }, [imageFiles]);
    useEffect(() => {
        updateProposedImagesSet()
    // eslint-disable-next-line
    }, [postImages, imageFiles])
    return (
        <div>
            {isShowingFullView && <PostMediaFullView hideView={() => setIsShowingFullView(false)} imageUrls={postImages} focusIndex={multiMediaFocusIndex} focusUrl={multiMediaFocusUrl} post={false}  /> }
            {isEditing && 
                <div style={{position: "relative"}}>


                    <LexicalTextEditor handleMultimediaPasteAction={handleMultimediaPasteAction} team={team} isUpgrade={!isLexicalDescription()} getInitialState={getInitialState} mainDescriptionDispatch={mainDescriptionDispatch} legacyDescription={getLegacyDescription()} />

                    {managableImageFiles.length > 0 && saveChangesWithMultimedia &&
                    <div className="modal-mm-image-preview-container">
                        {managableImageFiles.map((file, index) => (
                            <div style={{position: "relative"}} onClick={() => removeImage(file)} key={index}>
                                <img src={file.source} alt={`preview ${index}`} className="modal-mm-image-preview" />
                                <IoCloseCircleSharp className="modal-mm-image-preview-remove" />
                            </div>
                        ))}
                    </div>
                    }

                    {saveChangesWithMultimedia &&
                    <div className="modal-mm-uploads">
                        <span style={{cursor: "default"}}>Add attachments</span>
                        <input type="file" style={{ display: 'none' }} ref={fileInputRef} multiple accept="image/jpeg, image/png" onChange={handleFileSelect}/>
                        <div className="modal-mm-upload-buttons">
                            <div className="modal-mm-upload-button" onClick={promptToSelectPhotos} title="Add image"><FaImage style={{color: "rgb(61, 114, 170)"}}/></div>
                        </div>
                    
                    </div>
                    }
                </div>
            }
            {!isEditing && getDisplayDescription() && isLexicalDescription() &&
            <div className="gadget-lexical-displayed-text-container">
                <div dangerouslySetInnerHTML={{ __html: getDescription() }}></div>
                {hasPermission(permissions, 'component_update') &&
                <div className="gadget-lexical-edit-button">
                    <span onClick={() => setIsEditing(true)}>Edit</span>
                </div>
                }
            </div>
            }
            {!isEditing && getDescription() && !isLexicalDescription() &&
            <div className="gadget-lexical-displayed-text-container">
                <div>{getDescription()}</div>
                {hasPermission(permissions, 'component_update') &&
                <div className="gadget-lexical-edit-button">
                    <span onClick={() => setIsEditing(true)}>Edit</span>
                </div>
                }
            </div>
            }

            {isEditing &&
            <div className="gadget-lexical-save-buttons">
                <span style={{backgroundColor: "grey"}} onClick={cancelEdits}>Cancel</span>
                <span onClick={saveChanges}>Save</span>
            </div>
            }

            {/* Click here to add a description! */}
            {!isEditing && !getDisplayDescription() && hasPermission(permissions, 'component_update') &&
            <div className="gadget-lexical-displayed-text-container gadget-lexical-displayed-text-empty" onClick={() => setIsEditing(true)}>
                Click here to add a description
            </div>
            }

            {!isEditing && !getDisplayDescription() && !hasPermission(permissions, 'component_update') &&
            <div className="gadget-lexical-displayed-text-container gadget-lexical-displayed-text-empty" style={{cursor: "auto"}}>
                No description
            </div>
            }

            {!isEditing && isLexicalDescription() && postImages.length > 0 && imagesLoaded &&
            <MediaCarouselDisplay postImages={postImages} selectImageToView={selectImageToView} />
            }
            
        </div>
    )
}

function MediaCarouselDisplay({ postImages, selectImageToView }) {
    const [isOverflowing, setIsOverflowing] = useState(() => { return false })
    const containerRef = useRef(null);
    const handleScroll = (direction) => {
        const container = containerRef.current;
        const scrollAmount = container.clientWidth;
        let newPosition;

        if (direction === 'left') {
            newPosition = Math.max(0, container.scrollLeft - scrollAmount);
        } else {
            newPosition = Math.min(container.scrollWidth - container.clientWidth, container.scrollLeft + scrollAmount);
        }

        container.scrollTo({ left: newPosition, behavior: 'smooth' });
    };
    useEffect(() => {
        const container = containerRef.current;
        if (container.scrollWidth > container.clientWidth) {
            setIsOverflowing(true);
        } else {
            setIsOverflowing(false);
        }
    // eslint-disable-next-line
    }, [postImages]);
    return (
        <div className="component-main-description-relative-wrapper">
            <div className="component-main-description-carousel-wrapper">
                {isOverflowing && <div onClick={() => handleScroll('left')} className="component-main-description-carousel-button"><MdOutlineArrowBackIosNew/></div>}
                <div className="component-main-description-carousel-container" ref={containerRef}>
                    {postImages.map((url, index) => (
                        <div key={url} className="component-main-description-carousel-item">
                            <PostImagePreview images={[postImages[index]]} selectImageToView={() => selectImageToView(url, index)} />
                        </div>
                    ))}
                </div>
                {isOverflowing && <div onClick={() => handleScroll('right')} className="component-main-description-carousel-button"><MdOutlineArrowForwardIos/></div>}
            </div>
        </div>
    )
}