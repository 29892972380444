import React from 'react'
import { useState, useEffect, useRef } from "react";
import ModalEditComponentTemplate from '../modals/ModalEditComponentTemplate';
import { sortByProperty } from '../../common/Helpers';
import { IoCaretDownSharp, IoCloseSharp, IoTrophy, IoCheckmarkCircle, IoSquareSharp, IoColorFilterSharp } from "react-icons/io5";
import LoadingSymbolFacebook from '../gadgets/LoadingSymbolFacebook'
import { RiListCheck2, RiDashboardLine } from 'react-icons/ri'
import TemplatePreviewSmall from '../templates/TemplatePreviewSmall';

export default function TemplateLibrary({ team, workspace, templates, isLoadingTemplates, fetchTemplates, network, permissions }) {
    const typeMenuRef = useRef(null)
    const [displayedTemplates, setDisplayedTemplates] = useState(() => { return [] })
    const [filterByType, setFilterByType] = useState(() => { return false })
    const [typeMenuShowing, setTypeMenuShowing] = useState(() => { return false })
    const [isShowingTemplateEdit, setIsShowingTemplateEdit] = useState(() => { return false })
    const [selectedTemplate, setSelectedTemplate] = useState(() => { return "" })
    const openEditTemplateModal = (t) => {
        setSelectedTemplate(t);
        setIsShowingTemplateEdit(true)
    }
    const hideTypeMenu = () => {
        setTypeMenuShowing(false)
    }
    const toggleTypeMenuShowing = () => {
        setTypeMenuShowing(!typeMenuShowing)
    }
    const getSymbol = (templateType) => {
        if (templateType === "initiative") return <IoColorFilterSharp style={{color: "rgb(181, 101, 118)"}}/>
        if (templateType === "list") return <RiListCheck2 style={{color: "#2A73AE"}}/>
        if (templateType === "kresult") return <IoTrophy style={{color: "#E56B6F"}}/>
        if (templateType === "task") return <IoCheckmarkCircle  style={{color: "#2A73AE"}}/>
        if (templateType === "column") return <IoSquareSharp    style={{color: "#FFB901"}}/>
        if (templateType === "board") return <RiDashboardLine style={{color: "#9F6ADB"}}/>
        return false
    }
    const getFilterByTypeName = () => {
        if (!filterByType) return
        if (filterByType === "list") return "Lists"
        if (filterByType === "initiative") return "Projects"
        if (filterByType === "board") return "Boards"
        return "Type"
    }
    const updateDisplayedTemplates = () => {
        try {
            const copyOfTemplates = Array.from(templates)
            const sortedByCreationDate = sortByProperty(copyOfTemplates, "createdAt")
            if (!filterByType) return setDisplayedTemplates(sortedByCreationDate)
            const filteredTemplates = sortedByCreationDate.filter(t => t.component_type === filterByType)
            setDisplayedTemplates(filteredTemplates)
        } catch (error) {
            return setDisplayedTemplates(templates)
        }
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (typeMenuRef.current && !typeMenuRef.current.contains(event.target)) {
                hideTypeMenu()
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    // eslint-disable-next-line
    }, [typeMenuRef])
    useEffect(() => {
        updateDisplayedTemplates()
        // eslint-disable-next-line
    }, [templates, filterByType])
    return (
        <>
            {isShowingTemplateEdit && <ModalEditComponentTemplate network={network} team={team} getSymbol={getSymbol} workspace={workspace} fetchTemplates={fetchTemplates} template={selectedTemplate} showEditTemplate={setIsShowingTemplateEdit} permissions={permissions}/>}

            {templates.length === 0 && !isLoadingTemplates &&
                <div className="workspace-page-templates-empty-container">
                    <div className="workspace-page-templates-empty-main">No templates yet</div>
                    <div className="workspace-page-templates-empty-sub">Templates created from existing lists, boards, and projects will be shown here</div>
                </div>
            }

            {isLoadingTemplates &&
                <div className="workspace-page-templates-empty-container" style={{backgroundColor: "white"}}>
                    <LoadingSymbolFacebook/>
                </div>
            }

            {templates.length > 0 &&
            <div className="workspace-page-templates-filters">
                {!filterByType ? (
                    <div className="workspace-page-templates-filter" ref={typeMenuRef} onClick={toggleTypeMenuShowing}>
                        <div className="workspace-page-templates-filter-display">
                            Type
                            <IoCaretDownSharp/>
                        </div>
                        {typeMenuShowing &&
                        <div className="workspace-page-templates-filter-menu-container">
                            <div className="workspace-page-templates-filter-menu-dropdown-item" onClick={() => setFilterByType("list")}>{getSymbol("list")}Lists</div>
                            <div className="workspace-page-templates-filter-menu-dropdown-item" onClick={() => setFilterByType("board")}>{getSymbol("board")}Boards</div>
                            <div className="workspace-page-templates-filter-menu-dropdown-item" onClick={() => setFilterByType("initiative")}>{getSymbol("initiative")}Projects</div>
                        </div>
                        }
                    </div>
                   ) : (
                    <div className="workspace-page-templates-filter-display-selected">
                        <div className="workspace-page-templates-filter-display">
                            <IoCloseSharp onClick={() => setFilterByType(false)} />
                            <div>{getFilterByTypeName()}</div>
                        </div>
                    </div>
                    )
                }
                {/* <div className="workspace-page-templates-filter">
                    <div className="workspace-page-templates-filter-display">
                        Scope
                        <IoCaretDownSharp/>
                    </div>
                </div> */}
            </div>
            }

            {templates.length > 0 &&
            <div className="workspace-page-templates-container">
                {displayedTemplates.map((template) => (
                    <TemplatePreviewSmall getTemplateTypeSymbol={getSymbol} key={template._id} template={template} openEditTemplateModal={openEditTemplateModal} />
                ))}
                {displayedTemplates.length === 0 &&
                <div className="workspace-page-templates-empty-container">
                    <div className="workspace-page-templates-empty-main">No matching templates</div>
                    <div className="workspace-page-templates-empty-sub">Update your search terms or filters</div>
                </div>
                }
            </div>
            }
        </>
    )
}
