import React from 'react'
import { IoRadioButtonOnSharp, IoRadioButtonOffSharp } from "react-icons/io5";

export default function ModalSimpleMultiRadioSelection({ options, selectedOption, setSelectedOption }) {
    return (
        <div className="modal-creator-complex-radio-selections">
            {options.map((type) => (
            <div className={`modal-creator-complex-radio-selection ${type._id === selectedOption ? "modal-creator-complex-radio-selection-selected" : ""}`} key={type._id} onClick={() => setSelectedOption(type._id)}>

                <div className="util-row util-align-center">
                    <div className="modal-creator-complex-radio-selection-identifier">{type.symbol}</div>
                    <div className="modal-creator-complex-radio-selection-names">
                        <h5>{type.display_name}</h5>
                        <p>{type.description}</p>
                    </div>
                </div>

                <div className="modal-creator-complex-radio-selection-radio-button">
                    {selectedOption === type._id && <IoRadioButtonOnSharp/>}
                    {selectedOption !== type._id && <IoRadioButtonOffSharp/>}
                </div>
            </div>
            ))}
        </div>
    )
}
