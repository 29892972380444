import React from 'react'
import '../../styles/app.css';
import { useState, useEffect } from 'react'
import Api from '../../common/APIUtils';
import {  useHistory } from 'react-router-dom';
import { FaLongArrowAltLeft} from 'react-icons/fa';

export default function Feedback() {
    let api = new Api()
    let history = useHistory();
    const [feeling, setFeeling] = useState(() => {
        return ""
    })
    const [experience, setExperience] = useState(() => {
        return ""
    })
    const [feedbackText, setFeedbackText] = useState(() => {
        return ""
    })
    const [canSubmit, setCanSubmit] = useState(() => {
        return false;
    })
    const [questionThree, setQuestionThree] = useState(() => {
        return "What do you want to share today?"
    })
    const [instructionsDismissed, setInstructionsDismissed] = useState(() => {
        if (localStorage.getItem("gFeedbackInstructionsDismissed")) {
            return localStorage.getItem("gFeedbackInstructionsDismissed")
        } else {
            return false
        }
    })
    const [isSubmitted, setIsSubmitted] = useState(() => {
        return false;
    })

    const submitFeedback = () => {
        setCanSubmit(false);
        const payload = {
            feedback: feedbackText
        }
        if (feeling !== "") { payload['feeling'] = feeling }
        if (experience !== "") { payload['experience'] = experience }
        api.createFeedback(payload)
        .then((res) => {
            if (res) {
                setIsSubmitted(true)
                console.log(res)
            } else {
                setCanSubmit(true);
                console.log('Problem creating feedback')
            }
        })
        .catch((err) => {
            console.log(err);
        })

    }

    useEffect(() => {
        if (feedbackText !== "") {
            setCanSubmit(true)
        } else {
            setCanSubmit(false)
        }
    }, [feedbackText])

    useEffect(() => {
        if (instructionsDismissed) {
            localStorage.setItem("gFeedbackInstructionsDismissed", true)
        }
    }, [instructionsDismissed])

    useEffect(() => {
        if (feeling === 'optimistic') {
            setQuestionThree("What's got you feeling so good today?");
        } else if (feeling === 'confused') {
            setQuestionThree("What's bothering you?");
        } else if (feeling === 'angry') {
            setQuestionThree("What's making you so unhappy?");
        } else if (feeling === 'inspired') {
            setQuestionThree("What ideas do you want to share today?");
        } else if (feeling === 'special') {
            setQuestionThree("Why do you feel so special???");       
        } else {
            setQuestionThree("What do you want to share today?");
        }
    }, [feeling])

    const noFeelingsChecked = () => {
        if (feeling === "none") {
            return true;
        } else {
            return false;
        }
    }

    const noExperienceChecked = () => {
        if (experience === "none") {
            return true;
        } else {
            return false;
        }
    }

    const removeFeelings = (hidesFeelings) => {
        if (hidesFeelings) {
            setFeeling("none")
        } else {
            setFeeling("");
        }
    }

    const removeExperience = (hidesExperience) => {
        if (hidesExperience) {
            setExperience("none")
        } else {
            setExperience("")
        }
    }

    const getSelectedFeelingClass = (f) => {
        if (f === feeling) {
            return "application-feedback-pill-selected"
        } else {
            return ""
        }
    }

    const getSelectedExperienceClass = (e) => {
        if (e === experience) {
            return "application-feedback-pill-selected"
        } else {
            return ""
        }
    }

    const goBack = () => {
        // Return one page
        history.goBack();
    }

    return (
        <div className="application-feedback-container">
            {/* <div className="application-feedback-banner">
                Thank you for helping us out!  
            </div> */}
            {!instructionsDismissed && 
            <div className="application-feedback-instructions-modal" onClick={(e) => setInstructionsDismissed(true)}>
                <div className="application-feedback-instructions-message-container">
                    <div className="application-feedback-instructions-message">

                        <div className="application-feedback-instructions-message-width-control">
                            <h2>Hey, {localStorage.getItem("gFirstName")} </h2>
                            <p>Our mission at <strong>Tetheros</strong> is to bring clarity to your team's work and strategy.</p>

                            <p>Tell us your:</p>
                            <ul>
                                <li>Frustrations</li>
                                <li>Confusions</li>
                                <li>Desires</li>
                                <li>Experiences</li>
                            </ul>

                            <p>Thank you for making Tetheros a better place!</p>
                            <br />
                            <small><em>Click anywhere to dismiss this message</em></small>
                        </div>
                    </div>
                </div>
            </div>
            }

            <div className="application-feedback-width-control" style={{marginTop: "16px"}}>

                <div style={{marginLeft: "0px"}} className="component-text-link user-assignment-go-back-link" onClick={goBack}>
                    <FaLongArrowAltLeft/> Return to work
                </div>

                {/* Headers */}
                <div className="application-feedback-headers">
                    <h1>Simple Feedback Form</h1>
                    <p>Here to help you help us... help you.</p>
                </div>

                {/* Show this after feedback is submitted */}
                {isSubmitted ? (
                    <div className="application-feedback-gratitude">Thank you!</div>
                ) : (
                    <>
                    {/* Who are you */}
                    <div className="application-feedback-section">
                        <h3>1. How are you feeling today?</h3>
                        <div className="application-feedback-pills">
                            <div className={`application-feedback-pill-optimistic ${getSelectedFeelingClass('optimistic')}`} onClick={(e) => setFeeling('optimistic')}>Optimistic</div>
                            <div className={`application-feedback-pill-confused ${getSelectedFeelingClass('confused')}`} onClick={(e) => setFeeling('confused')}>Confused</div>
                            <div className={`application-feedback-pill-angry ${getSelectedFeelingClass('angry')}`} onClick={(e) => setFeeling('angry')}>Angry</div>
                            <div className={`application-feedback-pill-inspired ${getSelectedFeelingClass('inspired')}`} onClick={(e) => setFeeling('inspired')}>Inspired</div>
                            <div className={`application-feedback-pill-special ${getSelectedFeelingClass('special')}`} onClick={(e) => setFeeling('special')}>Special</div>
                        </div>
                        <input type="checkbox" id="feeling" name="feeling" value="none" checked={noFeelingsChecked()} onChange={(e) => removeFeelings(e.target.checked)}/>
                        <label htmlFor="feeling">I don't share my feelings</label>
                    </div>

                    {/* What level of experience do you have with the application? */}
                    <div className="application-feedback-section">
                        <h3>2. How much experience do you have with Tetheros?</h3>
                        <div className="application-feedback-pills">
                            <div className={`application-feedback-pill-special ${getSelectedExperienceClass('zero')}`} onClick={(e) => setExperience('zero')}>Zero</div>
                            <div className={`application-feedback-pill-inspired ${getSelectedExperienceClass('low')}`} onClick={(e) => setExperience('low')}>Some</div>
                            <div className={`application-feedback-pill-optimistic ${getSelectedExperienceClass('medium')}`} onClick={(e) => setExperience('medium')}>Average</div>
                            <div className={`application-feedback-pill-confused ${getSelectedExperienceClass('high')}`} onClick={(e) => setExperience('high')}>Power User</div>
                        </div>
                        <input type="checkbox" id="experience" name="experience" value="none" checked={noExperienceChecked()} onChange={(e) => removeExperience(e.target.checked)}/>
                        <label htmlFor="experience">I'm keeping that a secret</label>
                    </div>

                    {/* What do you have to say? */}
                    <div className="application-feedback-section">
                        <h3>{`3. ${questionThree}`}</h3>

                        <textarea value={feedbackText} onChange={(e) => setFeedbackText(e.target.value)} placeholder="Tell us what you have to tell us."></textarea>
                        <p className="application-feedback-subtitle">Feedback is completely anonymous. If you want us to reply to you directly, include your name and email in the feedback.</p>
                    </div>

                    <div className="application-feedback-submissions">
                        {canSubmit ? (
                            <div className="application-feedback-submission-button application-feedback-submission-button-ready" onClick={(e) => submitFeedback()}>Submit Feedback</div>
                        ) : (
                            <div className="application-feedback-submission-button application-feedback-submission-button-nope">Submit Feedback</div>
                        )}
                    </div>
                    </>
                )}
            </div>
            
        </div>
    )
}
