import React from 'react'
// eslint-disable-next-line
import { AiFillLock, AiOutlineHome, AiOutlineTeam, AiOutlineSetting, AiOutlineDeploymentUnit, AiOutlineCopy } from 'react-icons/ai'
// eslint-disable-next-line
import { BiTrendingUp, BiBriefcaseAlt, BiCalendar, BiUser } from 'react-icons/bi'
import { Link, useHistory } from 'react-router-dom';
import { hasPermission } from '../../common/Helpers';

export default function SidebarNetworkInfo({setSidebarCollapsed, network, permissions}) {
    const history = useHistory()
    const goToNetworkUrl = () => {
        return `/n/${network.slug}`
    }
    const goToNetworkPage = (pageName) => {
        // follows url convention of /n/network-slug/pageName
        history.push(`/n/${network.slug}/${pageName}`)
    }
    return (
        <div className="sidebar-left-section-workspace-container">
            <div className="sidebar-left-section-workspace-subtitle">
                <AiFillLock/>
                <div>Private Network</div>
            </div>
            {/* Collapse button */}
            {/* <AiOutlineDoubleLeft className="sidebar-show-button" onClick={() => setSidebarCollapsed(true)}/> */}

            {/* Workspace Info Name and Handle */}
            <div className="sidebar-left-section-workspace-name">
                <Link className="unlink" to={goToNetworkUrl()}>
                    <h2>{network.name ? network.name : "."}</h2>
                </Link>
            </div>

            {/* Workspace Navigation */}
            <div className="sidebar-left-section-workspace-navigation-container">
                <div className="sidebar-left-section-workspace-navigation-item" onClick={() => goToNetworkPage('')}><AiOutlineHome/> Home</div>
                <div className="sidebar-left-section-workspace-navigation-item" onClick={() => goToNetworkPage('users')}><BiUser/>Users & Teams</div>
                <div className="sidebar-left-section-workspace-navigation-item" onClick={() => goToNetworkPage('dashboards')}><AiOutlineDeploymentUnit/> Dashboard</div>
                <div className="sidebar-left-section-workspace-navigation-item" onClick={() => goToNetworkPage('templates')}><AiOutlineCopy/> Templates</div>
                {/* <div className="sidebar-left-section-workspace-navigation-item" onClick={() => goToNetworkPage('metrics')}><BiTrendingUp/> Metrics</div> */}
                <div className="sidebar-left-section-workspace-navigation-item" onClick={() => goToNetworkPage('workspaces')}><BiBriefcaseAlt/> Workspaces</div>
                {/* <div className="sidebar-left-section-workspace-navigation-item" onClick={() => goToNetworkPage('calendar')}><BiCalendar/> Calendar</div> */}
                {/* <div className="sidebar-left-section-workspace-navigation-item" onClick={() => goToNetworkPage('team')}><AiOutlineTeam/>Teams</div> */}
                {hasPermission(permissions, 'network_admin') && <div className="sidebar-left-section-workspace-navigation-item" onClick={() => goToNetworkPage('settings')}><AiOutlineSetting/>Network Settings</div>}
            </div>
        </div> 
    )
}
