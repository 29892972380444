import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { sortByProperty, getColorStylesFromObjectId } from '../../common/Helpers'

export default function TaskStateSelector({ task, updateTask }) {
    const [isBoardTask, setIsBoardTask] = useState(() => { return false })
    const [taskStateName, setTaskStateName] = useState(() => { return "" })
    const [taskStateStyle, setTaskStateStyle] = useState(() => { return {} })
    const [availableStates, setAvailableStates] = useState(() => { return [] })
    const [isChangingState, setIsChangingState] = useState(() => { return false })
    const stateChangeMenuRef = useRef(null)
    const updateBoardTaskStatus = () => {
        try {
            if (task.parent.name !== "board") return setIsBoardTask(false)
            if (!task.states) return setIsBoardTask(false)
            const taskStateId = task.attributes.state
            const taskState = task.states.find(t => t._id === taskStateId)
            const colorStyles = getColorStylesFromObjectId(taskState.name, true)
            const backgroundColor = colorStyles.backgroundColor
            const textColor = colorStyles.color
            setAvailableStates(sortByProperty(task.states, "order"))
            setTaskStateStyle({ backgroundColor: backgroundColor, color: textColor })
            setTaskStateName(taskState.name)
            setIsBoardTask(true)
        } catch (error) {
            return setIsBoardTask(false)
        }
    }
    const selectNewState = (stateId) => {
        try {
            const columnIdDone = task.parent.attributes.column_id_done
            const taskState = availableStates.find(t => t._id === stateId)
            if (stateId === columnIdDone) {
                const payload = {
                    attributes: {
                        active: false,
                        date_completed: new Date(),
                        progress: 1,
                        state: stateId
                    },
                    status: "completed",
                    completed: new Date(),
                }
                setTaskStateName(taskState.name)
                updateTask(payload, task)
                return
            }
            setTaskStateName(taskState.name)
            const colorStyles = getColorStylesFromObjectId(taskState.name, true)
            setTaskStateStyle({ backgroundColor: colorStyles.backgroundColor, color: colorStyles.color })
            updateTask({ attributes: { state: stateId }}, task, true)
            setIsChangingState(false)
        } catch (error) {
            setIsChangingState(false)
        }
    }
    const attemptStateChange = () => {
        setIsChangingState(!isChangingState)
    }
    useEffect(() => {
        updateBoardTaskStatus()
    // eslint-disable-next-line
    }, [task])
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (stateChangeMenuRef.current && !stateChangeMenuRef.current.contains(event.target)) {
                setIsChangingState(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [stateChangeMenuRef])
    if (isBoardTask) {
        return (
            <div className="user-mydesk-task-summary-subtext-state-decorator-container" ref={stateChangeMenuRef}>
                <span className="user-mydesk-task-summary-subtext-state-decorator" style={taskStateStyle} onClick={attemptStateChange}>{taskStateName}</span>

                {isChangingState && availableStates.length > 0 &&
                <div className="task-state-selector-menu">
                    <div className="task-state-selector-header">Status</div>
                    <div className="task-state-selector-menu-items">
                        {availableStates.map((taskState) => (
                            <span key={taskState._id} onClick={() => { selectNewState(taskState._id) }}>{taskState.name}</span>
                        ))}
                    </div>
                    
                </div>
                }
            </div>
            
        )
    } else {
      return (
        <span></span>
      )  
    }
}
