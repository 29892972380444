import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from "react-router-dom";
import Api from '../../../common/APIUtils';
import { AiOutlineDoubleRight, AiOutlineRight, AiOutlineCheck } from 'react-icons/ai'
import LoadingSymbolGrid from '../../gadgets/LoadingSymbolGrid';
import DataManager from '../../../common/DataManager';

export default function UserInvitationJoin({refreshAuthStatus, authenticated}) {
    let history = useHistory();
    let dataManager = DataManager.getInstance();
    const api = new Api();
    const query = new URLSearchParams(useLocation().search);
    const [operational, setOperational] = useState(() => { return false })
    const [activationFetched, setActivationFetched] = useState(() => { return false })
    const [invitationData, setInvitationData] = useState(() => { return false })
    const [password, setPassword] = useState(() => { return "" })
    const [redirectLink, setRedirectLink] = useState(() => { return false })
    const [passwordCheck, setPasswordCheck] = useState(() => { return "" })
    const [firstName, setFirstName] = useState(() => { return "" })
    const [lastName, setLastName] = useState(() => { return "" })
    const [page, setPage] = useState(() => { return 1 })
    const urlIsProperlyFormatted = () => {
        const iQuery = query.get("i")
        const dQuery = query.get("d")
        if (!iQuery || !dQuery) return false
        return true
    }
    const fetchInvitationData = () => {
        if (!urlIsProperlyFormatted()) return
        api.getInvitationInformation({
            i: query.get("i"),
            d: query.get("d")
        })
        .then((res) => {
            if (res.data.response) {
                if (res.data.response.invited_by) {
                    setInvitationData(res.data.response)
                }
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }
    const finishBuildingProfile = () => {
        if (firstName.length < 3 || firstName === "   ") return setPage(6)
        if (lastName.length < 2 || lastName === "  ") return setPage(6)
        if (password !== passwordCheck || password.length < 8) return setPage(6)
        let payload = {
            first_name: firstName,
            last_name: lastName,
            invitation_id: query.get("i"),
            user_id: query.get("d"),
            password: password
        }
        setOperational(true)
        if (query.get("m")) payload['membership_token'] = query.get("m")
        api.acceptInvitation(payload)
        .then((res) => {
            refreshAuthStatus()
            dataManager.setAuth(true);
        })
        .catch((err) => {
            console.log(err)
        })
    }
    const fetchActivationSuccessData = () => {
        if (activationFetched) return
        api.getActivationSuccessMessage()
        .then((res) => {
            if (res.data) {
                if (res.data.response) {
                    setRedirectLink(`/desk?v=workspaces`)
                    setActivationFetched(true)
                }
            }
        })
    }
    const handleFirstNameFieldPress = (code) => {
        if (code === 'Enter' && firstName.length > 2 && firstName !== "   ") setPage(3)
    }
    const handleLastNameFieldPress = (code) => {
        if (code === 'Enter' && lastName.length > 1 && lastName !== "  ") setPage(4)
    }
    const handlePasswordCheck = (code) => {
        if (code === 'Enter' && password.length > 7 && password === passwordCheck) setPage(5)
    }
    const goToSignup = () => {
        history.push('/register')
    }
    useEffect(() => {
        if (authenticated && !operational) {
            history.push('/home');
        }
    // eslint-disable-next-line
    }, [authenticated])
    useEffect(() => {
        fetchInvitationData()
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (operational && authenticated) {
            fetchActivationSuccessData()
        }
    // eslint-disable-next-line
    },[authenticated, operational])
    useEffect(() => {
        if (operational && authenticated && redirectLink) {
            history.push(redirectLink)
        }
    // eslint-disable-next-line
    }, [redirectLink])
    useEffect(() => {
        if (page === 5) finishBuildingProfile()
    // eslint-disable-next-line
    }, [page])
    return (
        <div className="network-page" style={{marginTop: "48px"}}>
            <div className="network-page-header-row" style={{marginBottom: "48px"}}>
                <div className="network-page-header-text">
                    <h1>Welcome to Tetheros!</h1>
                    <p>Improve your collaboration by aligning your effort and impact with us</p>
                </div>
            </div>
            {invitationData && (
                <div className="public-invitation-invitation-banner">
                    You've been invited by {invitationData.invited_by} to join {invitationData.invited_to}
                </div>
            )}

            {(page === 1 && urlIsProperlyFormatted()) && 
            <div className="public-invitation-inputs">
                <div className="public-invitation-input-label">Before connecting with your team, we need to finish setting up your account</div>
                <div className="public-invitation-input-sublabel">This will only take a minute</div>
                <div className="public-invitation-input-advance-buttons">
                    <div className="public-invitation-input-advance-button" onClick={() => setPage(2)}>
                        Get Started
                        <AiOutlineDoubleRight/>
                    </div>
                </div>
            </div>
            }

            {(page === 1 && !urlIsProperlyFormatted()) && 
            <div className="public-invitation-inputs">
                <div className="public-invitation-input-label">Align your progress and purpose with Tetheros</div>
                <div className="public-invitation-input-advance-buttons">
                    <div className="public-invitation-input-advance-button" onClick={goToSignup}>
                        Get Started
                    </div>
                </div>
            </div>
            }


            {page === 2 &&
            <div className="public-invitation-inputs">
                <div className="public-invitation-input-label">What is your first name?</div>
                <input type="text" onKeyDown={(e) => handleFirstNameFieldPress(e.code)} autoFocus={true} value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder='Enter your first name' />
                {(firstName.length > 2 && firstName !== "   ") &&
                <div className="public-invitation-input-advance-buttons">
                    <div className="public-invitation-input-advance-button" onClick={() => setPage(3)}>
                        Next
                        <AiOutlineRight/>
                    </div>
                </div>
                }
            </div>
            }

            {page === 3 &&
            <div className="public-invitation-inputs">
                <div className="public-invitation-input-label">What is your last name?</div>
                <input type="text" onKeyDown={(e) => handleLastNameFieldPress(e.code)} autoFocus={true} value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder='Enter your last name' />
                {(lastName.length > 1 && lastName !== " ") &&
                <div className="public-invitation-input-advance-buttons">
                    <div className="public-invitation-input-advance-button" onClick={() => setPage(4)}>
                        Next
                        <AiOutlineRight/>
                    </div>
                </div>
                }
            </div>
            }

            {page === 4 &&
            <div className="public-invitation-inputs">
                <div className="public-invitation-input-label">Choose a password</div>
                <div className="public-invitation-input-sublabel">They need to match and be at least 8 characters long</div>
                <input type="password" autoFocus={true} value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Create a password' />
                <input type="password" onKeyDown={(e) => handlePasswordCheck(e.code)} value={passwordCheck} onChange={(e) => setPasswordCheck(e.target.value)} placeholder='Enter the password again' />
                {(password.length > 7 && password === passwordCheck) &&
                <div className="public-invitation-input-advance-buttons">
                    <div className="public-invitation-input-advance-button" style={{backgroundColor: "#019815"}} onClick={() => setPage(5)}>
                        Start Collaborating
                        <AiOutlineCheck/>
                    </div>
                </div>
                }
            </div>
            }

            {page === 5 &&
            <div className="public-invitation-inputs">
                <div style={{marginTop: "120px", width: "100%", display: "flex", justifyContent: "center"}}>
                    <div style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "1em"}}>
                        <LoadingSymbolGrid/>
                        <div style={{textAlign: "center", fontWeight: "600"}}>Building your profile...</div>
                    </div>
                </div>
            </div>
            }

            {page === 6 &&
            <div className="public-invitation-inputs">
                <div className="public-invitation-input-label">Oh no!</div>
                <div className="public-invitation-input-sublabel">Something went wrong.</div>
                <div className="public-invitation-input-advance-buttons">
                    <div className="public-invitation-input-advance-button" onClick={() => history.push('/register')}>
                        Fix It
                    </div>
                </div>
            </div>
            }


        </div>
    )
    
}
