import React from 'react'

export default function InternalMenuSelections({ updateView, view, viewOptions }) {
    return (
        <div className="gadget-internal-menu-selections">

            <div className="gadget-internal-menu-options">
                {viewOptions.map((option) => (
                    <span key={option.id} className={view === option.id ? "selected" : ""} onClick={() => updateView(option.id)}>{option.display_name}</span>
                ))}
            </div>

        </div>
    )
}
