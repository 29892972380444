import React from 'react'
import { useState, useEffect } from 'react';
import Api from '../../common/APIUtils'
import { useHistory } from 'react-router-dom';
import { BiPlus } from 'react-icons/bi'
import { BsThreeDots } from 'react-icons/bs'
import ConfirmAction from '../modals/ConfirmAction';
import DataTableRow from '../tables/DataTableRow';
// eslint-disable-next-line
import { toCapitalize, hasPermission } from '../../common/Helpers';
import { parseISO, format, isThisMonth } from 'date-fns'

import ModalManageWorkspaceUsers from '../modals/ModalManageWorkspaceUsers';
import ModalAddNetworkWorkspaceUser from '../modals/ModalAddNetworkWorkspaceUser';
import ModalManageWorkspaceUserMembership from '../modals/ModalManageWorkspaceUserMembership';
import ProfPicCircle from '../profiles/ProfPicCircle';
import UserActivityDot from '../gadgets/UserActivityDot';

export default function WorkspaceTeam({team, permissions, fetchWorkspace, workspaceId, workspace, networkTeams, workspaceUserLimit}) {
    const api = new Api();
    const history = useHistory();
    // eslint-disable-next-line
    const [view, setView] = useState(() => { return 'users' })
    const [allUsers, setAllUsers] = useState(() => {return []})
    const [showManageNetworkWorkspaceUserModal, setShowManageNetworkWorkspaceUserModal] = useState(() => {return false})
    const [showManageUsersModal, setShowManageUsersModal] = useState(() => {return false})
    const [showManageUserMembershipModal, setShowManageUserMembershipModal] = useState(() => {return false})
    const [selectedUser, setSelectedUser] = useState(() => {return ""})
    const [isShowingConfirmAction, setIsShowingConfirmAction] = useState(() => { return false })
    const getUsers = () => {
        api.getAllUsers()
        .then((res) => {
            setAllUsers(res.data.users);
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const addNewMember = (proposedNewMember, roleId) => {
        const payload = {
            userId: proposedNewMember.value,
            workspaceId: workspaceId,
            roleId: roleId
        }
        api.addUserToWorkspace(payload)
        .then((res) => {
            fetchWorkspace();
        })
        .catch((e) => {
            console.log('oops')
        })
    }
    const goToUserProfile = (userId) => {
        if (!userId) return
        history.push(`/user/${userId}`)
    }
    const inviteNewMemberViaEmail = (emailAddress) => {
        if (!emailAddress) return
        api.inviteUserToWorkspace({
            workspace_id: workspaceId,
            email_address: emailAddress
        })
        .then((res) => {
            fetchWorkspace()
        })
        .catch((e) => {
            console.log(e)
        })
    }
    const hideModal = () => {
        setShowManageUsersModal(false)
        setShowManageUserMembershipModal(false)
        setShowManageNetworkWorkspaceUserModal(false)
    }
    const isCreatorOfWorkspace = () => {
        if (!localStorage.getItem("gId")) return false;
        if (localStorage.getItem("gId") === workspace.creator_id) {
            return true
        } else {
            return false
        }
    }
    const hideConfirmActionText = () => {
        setIsShowingConfirmAction(false)
    }
    const leaveWorkspace = () => {
        hideConfirmActionText();
        const id = localStorage.getItem("gId");
        const payload = {
            userId: id,
            workspaceId: workspaceId
        }
        api.leaveWorkspace(payload)
        .then((res) => {
            if (res) {
                history.push(`/home`)
            } else {
                console.log('oops')
            }
        })
        .catch((e) => {
            console.log('oops')
        })
    }
    const canShowManageRoleAction = (id) => {
        if (!isPrivateWorkspace()) return false
        if (id === localStorage.getItem("gId")) return false
        if (workspace.creator_id === id) return false // Cannot manage the workspace owner's roles
        return true
    }
    const hasUsers = () => {
        if (!team) return false
        if (team.length < 1) return false
        return true
    }
    const canShowAddUserButtonHeader = () => {
        if (workspace.workspace_type === 'network'  && hasPermission(permissions, 'network_admin')) return true // Temporarily commented for now
        // if (!hasUsers()) return false // Temporarily commented for now
        if (!hasPermission(permissions, 'member_add')) return false
        // if (!workspaceUserLimit) return false
        if ((team.length >= workspaceUserLimit) && (workspace.workspace_type === 'private')) return false
        return true
    }
    const openAddUserModal = () => {
        if (workspace.workspace_type === "private") return setShowManageUsersModal(true)
        if (workspace.workspace_type === "network") return setShowManageNetworkWorkspaceUserModal(true)
    }
    const getLegacyRoleNameForUser = (user) => {
        if (workspace.creator_id === user._id) return "Owner"
        return "User"
    }
    const getRoleNameForUser = (user) => {
        try {
            if (!workspace) return getLegacyRoleNameForUser(user)
            if (!workspace.workspace_roles) return getLegacyRoleNameForUser(user)
            if (!user) return getLegacyRoleNameForUser(user)
            if (!user.user_membership) return getLegacyRoleNameForUser(user)
            if (!user.user_membership.role_id) return getLegacyRoleNameForUser(user)
            const filteredRoleList = workspace.workspace_roles.filter((role) => {
                if (role._id === user.user_membership.role_id) return true
                return false
            })
            if (filteredRoleList.length === 1) return filteredRoleList[0].name
        } catch (error) {
            return ""
        }
        return getLegacyRoleNameForUser(user)
    }
    const openManageUserMembershipModal = (user) => {
        setSelectedUser(user)
        setShowManageUserMembershipModal(true)
    }
    const getUserActionMenu = (user) => {
        if (!hasPermission(permissions, 'roles_update')) return ""
        if (!canShowManageRoleAction(user._id)) return ""
        return (
            <BsThreeDots className="network-workspace-manage-user-symbol" onClick={() => openManageUserMembershipModal(user)}/>
        )
    }
    const getLastRelevantActivity = (user) => {
        try {
            // Reads from network.memberships to determine if user has been active recently or not
            if (!user) return "Inactive"
            if (!user.user_membership) return "Inactive"
            if (!user.user_membership.last_approved_action_time) return "Inactive"
            const lastActivity = format(parseISO(user.user_membership.last_approved_action_time), 'PP')
            const isActive = isThisMonth(parseISO(user.user_membership.last_approved_action_time)) // had to occur within the current month
            return (
                <div>
                    <div style={{fontWeight: "600"}}>{isActive ? 'Active' : 'Inactive'}</div>
                    <div>{lastActivity}</div>
                </div>
            )  
        } catch (error) {
            return (
                <div>
                    <div style={{fontWeight: "600"}}>Inactive</div>
                </div>
            )   
        }

    }
    const formatUserDataForRow = (user) => {
        let rowData = [
            {
                width: "lg",
                content: (
                    <div className="workspace-team-table-user-container">
                        <ProfPicCircle src={user.profile_picture_url} height={56} userId={user._id} userName={`${user.firstName} ${user.lastName}`} />
                        <div className="workspace-team-table-user-names">
                            <div className="workspace-team-table-user-name-first">
                                <span onClick={() => goToUserProfile(user._id)}>{user.firstName} {user.lastName}</span>
                                <span><UserActivityDot height={8} lastLogin={user.last_login} /></span>
                            </div>
                            <div style={{fontWeight: "400", fontSize: "14px", marginTop: "0px"}}>@{user.handle}</div>
                            {user.status && <div className="workspace-team-table-user-status">{user.status}</div> }
                        </div>
                    </div>
                ),
                _id: `${user._id}-name`
            }
        ]
        if (isPrivateWorkspace()) {
                rowData.push({
                width: "md",
                content: getRoleNameForUser(user),
                _id: `${user._id}-role`
            })
        }
        rowData.push(
            {
                width: "md",
                content: getLastRelevantActivity(user),
                _id: `${user._id}-activity`
            },
            {
                width: "sm",
                content: getUserActionMenu(user),
                _id: `${user._id}-remove`
            }
        )
        return rowData
    }
    const getValidTeamUsers = () => {
        return team.filter((teammate) => {
            if (!teammate.firstName) return false
            if (!teammate.lastName) return false
            return true
        })
    }
    const updatePrivateWorkspaceUserMembership = (payload) => {
        hideModal()
        if (hasPermission(permissions, 'roles_update')) {
            api.updateUserWorkspaceMembership(payload)
            .then((res) => {
                console.log(res.data)
                fetchWorkspace()
            })
            .catch((err) => {
                console.log('Toast')
                console.log(err)
            })
        }
    }
    const canShowLeaveWorkspaceOption = () => {
        if (workspace.workspace_type === 'network') return false
        if (isCreatorOfWorkspace()) return false
        return true
    }
    const isPrivateWorkspace = () => {
        if (workspace.workspace_type !== 'private') return false
        return true
    }
    // const hasNetworkTeams = () => {
    //     if (isPrivateWorkspace()) return false
    //     if (!networkTeams) return false
    //     return true
    // }
    useEffect(() => {
        getUsers()
        // eslint-disable-next-line
    }, [team]);
    return (
        <>
            <div className="network-page" style={{width: "100%"}}>
                {showManageNetworkWorkspaceUserModal && <ModalAddNetworkWorkspaceUser networkId={workspace.network_id} hideModal={hideModal} prePopulatedWorkspaceIds={[workspaceId]} /> }
                {showManageUsersModal && <ModalManageWorkspaceUsers allUsers={allUsers} inviteNewMember={inviteNewMemberViaEmail} addNewMember={addNewMember} team={team} hideModal={hideModal} workspace={workspace}/>}
                {showManageUserMembershipModal && <ModalManageWorkspaceUserMembership updatePrivateWorkspaceUserMembership={updatePrivateWorkspaceUserMembership} team={team} user={selectedUser} workspace={workspace} hideModal={hideModal}/>}

                {isShowingConfirmAction && <ConfirmAction mainText="Are you sure you want to leave this workspace?  You will be automatically unassigned from anything you were assigned to." onconfirm={leaveWorkspace} hideMe={hideConfirmActionText}/>}

                <div className="workspace-library-section-header">
                    <h1>Users</h1>
                    <p>Invite, promote, and manage user membership and access to the workspace</p>
                </div>

                {canShowAddUserButtonHeader() && (
                    <div className="workspace-page-resources-actions-bar" style={{borderBottom: "none", flexDirection: "row"}}>
                        <div className="network-page-add-workspace" onClick={openAddUserModal}>
                            <BiPlus/>
                            <span>Add new users</span>
                        </div>
                    </div>
                )}
            
                <div className="workspace-team-section">
                    {view === 'users' &&
                        <div className="workspace-team-users-outer">
                            {hasUsers() && 
                            <div>
                                <div className="data-table-header-container" style={{marginTop: "1em"}}>
                                    <span className="data-table-col-lg">User</span>
                                    {isPrivateWorkspace() && <span className="data-table-col-md">Role</span> }
                                    <span className="data-table-col-md">Status</span>
                                    <span className="data-table-col-sm"> </span>
                                </div>
                                {getValidTeamUsers().map((user) => (
                                    <DataTableRow key={user._id} rowData={formatUserDataForRow(user)} />
                                ))}
                            </div>
                            }
                            
                        </div>
                    }
                </div>

                {/* Leave workspace if you are not creator */}
                {canShowLeaveWorkspaceOption() && (
                    <div className="workspace-team-member-leave" onClick={() => setIsShowingConfirmAction(true)}>Leave Workspace</div>
                )}

                {/* Show User Count if Private Workspace and owner */}
                {(isPrivateWorkspace() && workspaceUserLimit) &&
                <div style={{textAlign: "center"}}>
                    {team.length} of {workspaceUserLimit} membership seats used
                </div>
                }
                
            </div>

        </>
    )
}
