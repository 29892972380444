import React from 'react'
import { useState, useEffect, useRef } from "react";
import { parseISO, formatDistanceToNow } from 'date-fns';
import { IoAddSharp } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { BiSearch } from 'react-icons/bi'
import { hasPermission, getMetricDisplayValue, sortByPropertyReverse, getColorFromIndex } from '../../common/Helpers';
import ComponentMainDescription from '../components/ComponentMainDescription';
import NewMeasurement from '../forms/proj_components/NewMeasurement';
import Api from '../../common/APIUtils';
import Chart from 'react-google-charts';

export default function WorkspaceMeasurements({fetchWorkspace, components, team, workspaceId, subview, permissions}) {
    const api = new Api();
    const history = useHistory();
    const [page, setPage] = useState(() => { return "main"})
    const [query, setQuery] = useState(() => { return ""})
    const [kpis, setKpis] = useState(() => { return [] })
    const goToCreateMeasurementForm = () => {
        setPage("create")
    }
    const goToKpiPage = (measurement) => {
        if (!measurement) return
        if (!measurement._id) return
        history.push(`/workspaces/${workspaceId}/c/${measurement._id}`)
    }
    const getDescription = (measurement) => {
        let mainDescription = measurement.attributes.description
        if (mainDescription.description_html) {
            const parsedHtml = mainDescription.description_html.replace(/<\/?[^>]+(>|$)/g, '')
            if (parsedHtml === "") return ""
            return parsedHtml
        }
        return mainDescription.description

    }
    const resetPage = () => {
        fetchWorkspace()
        setPage("main")
    }
    const createNewKpi = (payload) => {
        payload['workspace_id'] = workspaceId;
        const path = `/components/metric/kpi`;
        try {
            api.createWorkspaceComponent(payload, path)
            .then( (res) => {
                if (res.status) fetchWorkspace()
            })
            .catch((err) => {
                console.log(err);
                alert(err);
            })
        } catch (error) {
            console.log(error)
        }
    }
    const getKpiComponents = () => {
        let kpiComponents = []
        if (!components) return setKpis(kpiComponents)
        if (!components.kpi) return setKpis(kpiComponents)
        kpiComponents = components.kpi
        // Sort by updatedAt
        kpiComponents = sortByPropertyReverse(kpiComponents, "updatedAt")
        // Deal with query
        if (!query) return setKpis(kpiComponents)
        kpiComponents = kpiComponents.filter((measurement) => {
            let searchableText = `${getDescription(measurement)}${measurement.display_name}`.toLowerCase()
            if (searchableText.indexOf(query.toLowerCase()) !== -1) return true
            return false
        })
        setKpis(kpiComponents)
    }
    useEffect(() => {
        if (subview) setPage(subview)
    }, [subview])
    useEffect(() => {
        getKpiComponents()
    // eslint-disable-next-line
    }, [components, query])
    return (
        <div className="network-page" style={{width: "98%"}}>
            {page === "main" && (
                <div>
                    <div className="network-users-header-row" style={{marginBottom: "16px", marginTop: "16px"}}>
                        <div className="network-page-header-text">
                            <h1>Measurements</h1>
                            <p>Key Performance Indicators (KPIs) identify a specific measurement at a point in time</p>
                        </div>
                    </div>
                    <div className="workspace-page-resources-actions-bar">
                        <div className="workspace-page-resources-action-search-container">
                            <BiSearch/>
                            <input type="text" value={query} onChange={(e) => setQuery(e.target.value)} placeholder="Search measurements" />
                        </div>
                        {hasPermission(permissions, 'component_create') && <div className="workspace-page-resources-create-button" onClick={goToCreateMeasurementForm}>+ Add KPI</div>}
                    </div>

                    <div className="workspace-page-kpis">
                        {kpis.map((kpi) => (
                            <KpiDisplay kpi={kpi} key={kpi._id} goToKpiPage={goToKpiPage} team={team} />
                        ))}
                        {kpis.length === 0 &&
                            <div className="workspace-page-kpi-container" onClick={goToCreateMeasurementForm}>
                                <div className="workspace-page-kpi-empty-new">
                                    <IoAddSharp/>
                                    <p>Create KPI</p>
                                </div>
                            </div>
                        }
                    </div>                    
                </div>
            )}

            {page === "create" && <NewMeasurement createKpi={createNewKpi} goToAllMeasurements={resetPage} />}
        </div>
    )
}

function KpiDisplay({ kpi, goToKpiPage, team }) {
    const [fontSize, setFontSize] =useState(() => { return 28 });
    const [text, setText] = useState(() => { return "" });
    const [showPopup, setShowPopup] = useState(() => { return false })
    const hoverTimer = useRef();
    const leaveTimer = useRef();
    const textRef = useRef();
    const adjustFontSize = () => {
        if (text.length > 10) setFontSize(22)
        if (text.length > 15) setFontSize(20)
        if (text.length > 20) setFontSize(16)
    };
    const handleMouseEnter = () => {
        // Clear the timer if it was previously set
        clearTimeout(hoverTimer.current);
        // Set the timer to show the popup after 500ms
        hoverTimer.current = setTimeout(() => {
            setShowPopup(true);
        }, 500);
    };
    const hasDescription = () => {
        try {
            if (kpi.attributes.description) return true
        } catch (error) {
            return false
        }
    }
    const getCustomUnits = () => {
        try {
            const NON_CUSTOM_UNITS = ["$", "%"]
            if (!kpi) return false
            if (!kpi.attributes.units || kpi.attributes.units === "") return false
            if (NON_CUSTOM_UNITS.includes(kpi.attributes.units)) return false 
            return <span>{kpi.attributes.units}</span>
        } catch (error) {
            return false
        }
    }
    const handleMouseLeave = () => {
    // Clear the hover timer to prevent showing the popup after leaving
    clearTimeout(hoverTimer.current);
    // Set the timer to hide the popup after 200ms
    leaveTimer.current = setTimeout(() => {
        setShowPopup(false);
    }, 200);
    };
    useEffect(() => {
        adjustFontSize();
    // eslint-disable-next-line
    }, [text]);
    useEffect(() => {
        if (!kpi) return
        setText(getMetricDisplayValue(kpi))
    // eslint-disable-next-line
    }, [kpi])
    return (
        <div className="workspace-page-kpi-container" onClick={() => goToKpiPage(kpi)} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div>
                <h4>{kpi.display_name}</h4>
                <div className="workspace-page-kpi-value" style={{ fontSize: `${fontSize}px` }} ref={textRef}>{getMetricDisplayValue(kpi)}{getCustomUnits()}</div> 
            </div>
            <div>
                <div className="workspace-page-kpi-descriptor">Updated {formatDistanceToNow(parseISO(kpi.updatedAt))} ago</div>
            </div>
            <SparklinePreview kpi={kpi} />
            {hasDescription() && showPopup && (
                <div className="workspace-page-kpi-description-popup">
                    <h4>{kpi.display_name}</h4>
                    <ComponentMainDescription component={kpi} permissions={false} updateDetails={() => { return false}} team={team} />
                </div>
            )}
        </div>
    )
}

function SparklinePreview({ kpi }) {
    const api = new Api()
    const [kpiData, setKpiData] = useState(() => { return false })
    const [formattedKpiData, setFormattedKpiData] = useState(() => { return [] })
    const [dataLoaded, setDataLoaded] = useState(() => { return false })
    const fetchKpiData = () => {
        if (!kpi) return
        if (!kpi._id) return
        try {
            api.getWorkspaceComponent({componentId: kpi._id, workspaceId: kpi.workspace_id})
            .then((res) => {
                if (res.data.response.component.attribute_updates) return setKpiData(res.data.response.component.attribute_updates)
                return false
            })
            .catch((err) => { return false })
        } catch (error) {
            return false
        }
    }
    const getColor = () => {
        return getColorFromIndex(kpi.display_name.length)
    }
    const options = {
        colors: [getColor()],
        backgroundColor: 'transparent',
        hAxis: {
          textPosition: 'none',
          baselineColor: 'transparent', 
          gridlines: {
            color: 'transparent'
          }
        },
        vAxis: {
          textPosition: 'none',
          baselineColor: 'transparent',
          gridlines: {
            color: 'transparent'
          }
        },
        lineWidth: 2,
        pointSize: 0,
        chartArea: {
          width: '100%',
          height: '80%'
        },
        legend: 'none',
        enableInteractivity: false,
        areaOpacity: 0.2
    };
    const formatKpiData = () => {
        try {
            let d = []
            const attributeUpdates = Array.from(kpiData)
            if (!attributeUpdates) setFormattedKpiData([])    
            const justValCurrentUpdates = attributeUpdates.filter(update => { return update.attribute_name === 'val_current' })
    
            const startingDataPoint = [parseISO(kpi.created), Number(kpi.attributes.val_start) ]
            d.push(startingDataPoint)
            
            for (let i = 0; i < justValCurrentUpdates.length; i++) {
                const entry = justValCurrentUpdates[i];
                d.push([parseISO(entry.time_stamp), Number(entry.attribute_value)])
            }
            if (d.length < 3) return
            const header = ["Date", kpi.display_name]
            const lastThreePoints = d.slice(-4)
            const sparklineData = [header, ...lastThreePoints]
            setFormattedKpiData(sparklineData)
            setDataLoaded(true)
        } catch (error) {
            setKpiData(false)
            setDataLoaded(false)
        }
    }
    useEffect(() => {
        fetchKpiData()
    // eslint-disable-next-line
    }, [kpi])
    useEffect(() => {
        formatKpiData()
    // eslint-disable-next-line
    }, [kpiData])
    return (
        <div className="workspace-page-kpi-sparkline">
            {kpiData && dataLoaded &&
            <Chart
                width={'80px'}
                height={'50px'}
                chartType="AreaChart"
                loader={<div></div>}
                data={formattedKpiData}
                options={options}
            />}
        </div>
    )
}