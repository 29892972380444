import React, { useState, useEffect } from 'react'
import '../../styles/blog.css';
import contentfulClient from '../../common/ContentfulApi'
import { useHistory } from 'react-router-dom';

import UnauthenticatedHeader from '../header/UnauthenticatedHeader'
import LandingFooter from '../landing/LandingFooter';
import { updateHeadTags, updateSchemaOrgJSONLD } from '../../common/Helpers';

export default function BlogLanding({authenticated}) {
    let history = useHistory()
    const [posts, setPosts] = useState(() => { return []})
    const updatePosts = () => {
        try {
            contentfulClient.getEntries({
                content_type: "blogPost",
                order: "-sys.createdAt",
                limit: 10
            }).then((response) => {
                if (response.items.length > 0) {
                    setPosts(response.items.map(item => item.fields))
                }
            })
            
        } catch (error) {
            
        }
    }
    const updateMeta = () => {
        try {
            if (!posts) return
            if (posts.length === 0) return

            // JSON-LD Data
            const itemListElement = posts.map((post, index) => ({
                "@type": "ListItem",
                "position": index + 1,
                "url": `https://www.tetheros.com/blog/${post.urlSlug}`
            }));
            updateSchemaOrgJSONLD({
                "@context": "http://schema.org",
                "@type": "ItemList",
                "itemListElement": itemListElement
            })

            // Meta
            let title = "Blog | Tetheros"
            let metaTags = [
                { name: 'description', content: 'Tactical teamwork improvement guides for leaders, readers, & collaboration wonks' },
                { name: 'robots', content: 'index, follow' },
                { name: 'og:title', content: title },
                { name: 'og:description', content: 'Tactical teamwork improvement guides for leaders, readers, & collaboration wonks' },
                { name: 'og:url', content: 'https://tetheros.com/blog' },
                { name: 'og:type', content: 'website' },
                { name: 'og:image', content: 'https://storage.googleapis.com/tetheros-public/logo/Tetheros%20OpenGraph%20Image.png' },
                { name: 'twitter:card', content: 'summary_large_image' },
                { name: 'twitter:title', content: title },
                { name: 'twitter:image', content: 'https://storage.googleapis.com/tetheros-public/logo/Tetheros%20OpenGraph%20Image.png' },
                { name: 'twitter:description', content: 'Tactical teamwork improvement guides for leaders, readers, & collaboration wonks' },
            ]
            updateHeadTags(title, metaTags)

        } catch (error) {
            return
        }
    }
    useEffect(() => {
        updatePosts()
        updateHeadTags("Blog | Tetheros")
        window.scrollTo(0, 0)
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        updateMeta()
    // eslint-disable-next-line
    }, [posts])
    return (
        <div>
            <UnauthenticatedHeader />
            <div className="blog-landing-heading">
                <div className="blog-landing-heading-inner">
                    <div className="blog-landing-heading-badge">Blog</div>
                    <h1>Tactical Teamwork<br/>Improvement Guides</h1>
                    <p>For leaders, readers, & collaboration wonks</p>
                </div>
            </div>
            <div className="blog-landing-list">
                {posts.map((post) => (
                    <div key={post.urlSlug} className="blog-landing-list-content" onClick={() => history.push(`/blog/${post.urlSlug}`)}>
                        <div className="blog-landing-list-content-titles">
                            <h2>{post.contentTitle}</h2>
                            <div className="blog-landing-list-content-tagline">{post.tagline}</div>
                            <div className="blog-landing-list-content-categories">
                                {post.contentCategories.map((categoryName) => (
                                    <span key={categoryName}>{categoryName}</span>
                                ))}
                            </div>
                        </div>
                        <div className="blog-landing-list-content-image">
                            <img src={post.heroImage.fields.file.url} alt={post.heroImage.fields.description} />
                        </div>
                        
                    </div>
                ))}
            </div>
            <LandingFooter/>
        </div>
    )
}