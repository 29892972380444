import React from 'react'
import { useState, useEffect } from 'react';
import '../../../styles/modals.css';
import { FaTimes, FaTasks, FaRunning, FaThLarge } from 'react-icons/fa'
import { BiCustomize, BiTrendingUp, BiSearch } from 'react-icons/bi'
import HProgressBar from '../../HProgressBar';
import LoadingSymbolGrid from '../../gadgets/LoadingSymbolGrid';
import Api from '../../../common/APIUtils';

export default function WorkspaceCompGoalAddMarker({setIsAddingNewGoalMarker, parentId, fetchComponent, allComponents, potentialBondIds, createNewBond}) {
    const api = new Api();
    const [page, setPage] = useState(() => { return 1 })
    const [query, setQuery] = useState(() => { return ""})
    const [selectedChild, setSelectedChild] = useState(() => { return "" })
    const [targetValue, setTargetValue] = useState(() => { return "" })
    const [isSubmitting, setIsSubmitting] = useState(() => { return false })
    const [isDoneSubmitting, setIsDoneSubmitting] = useState(() => { return false })
    const getComponentSymbol = (name) => {
        const symbolMap = {
            list: <FaTasks className="gomano-card-component-symbol"/>,
            sprint: <FaRunning className="gomano-card-component-symbol"/>,
            kanban: <FaThLarge className="gomano-card-component-symbol"/>,
            board: <BiCustomize className="gomano-card-component-symbol"/>,
            kpi: <BiTrendingUp className="gomano-card-component-symbol"/>
        }
        return symbolMap[name]
    }
    const getFilteredPotentialBonds = () => {
        if (!potentialBondIds) return []
        if (!query) return potentialBondIds
        const filteredBondIds = potentialBondIds.filter((potentialBondId) => {
            if (query) {
                const displayName = allComponents[potentialBondId]['display_name'].toLowerCase()
                if (displayName.indexOf(query.toLowerCase()) !== -1) return true
                return false
            }
            return true
        })
        return filteredBondIds
    }
    const getExtraComponentNameStyles = (name) => {
        if (name === 'kpi') {
            return {
                textTransform: "uppercase"
            }
        }
    }
    const getMetricDisplayValue = (measurement) => {
        if (measurement.name === 'kpi') {
            const currentValue = measurement.attributes.val_current;
            // if no units, display raw count
            if (!measurement.attributes.units) {
                return currentValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
    
            const unit = measurement.attributes.units;
    
            // if a dollar sign, return as a currency
            if (unit === "$") {
                // Create our number formatter.
                var formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    // minimumFractionDigits: 0,
                    // maximumFractionDigits: 0
                });
                
                return formatter.format(currentValue);
            } else if (unit === "%") {
                return `${(Math.round(currentValue * 100) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${unit}`
            } else {
                return currentValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        } else {
           return `${getProgressMetricForComponent(measurement)}%`
        }

    }
    const getProgressMetricForComponent = (component) => {
        if (component.progress) {
            return Math.round(component.progress*100);
        } else {
            return 0
        }
    }
    const getSelectedChildCurrentValue = () => {
        if (selectedChild.name === 'kpi') {
            return selectedChild.attributes.val_current
        } else {
            return getProgressMetricForComponent(selectedChild)
        }
    }
    const getRawSelectedChildCurrentValue = () => {
        if (selectedChild.name === 'kpi') {
            return selectedChild.attributes.val_current
        } else {
            return selectedChild.progress
        }
    }
    const getSelectedChildStyle = (id) => {
        if (id === selectedChild._id) {
            return {
                border: "4px solid rgb(40, 105, 143)"
            }
        }
    }
    const getGomanoCardBottomRow = (component) => {
        if (component.name === 'kpi') {
            return <div className="gomano-card-progress-label">{getMetricDisplayValue(component)}</div>
        } else {
            return (
                <>
                <span className="gomano-card-progress-label">{getProgressMetricForComponent(component)}%</span>
                <div className="gomano-card-progress-bar"><HProgressBar percentage={getProgressMetricForComponent(component)}/></div>
                </>
            )
        }
    }
    const addNewGoalMarker = () => {
        setIsSubmitting(true)
        // Create bond
        createNewBond(selectedChild._id)

        // Update child component parent_data property
        let payload = {}
        let parentData = {}
        parentData[parentId] = {
            initial: getRawSelectedChildCurrentValue(),
            target: (selectedChild.name === 'kpi') ? targetValue : (targetValue/100)
        }
        payload['parent_data'] = parentData
        const path = `/workspace/components/${selectedChild._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
            setIsDoneSubmitting(true)
        })
        .catch((err) => {
            console.log(err);
        })
    }
    useEffect(() => {
        setTargetValue(getSelectedChildCurrentValue())
    // eslint-disable-next-line
    }, [selectedChild])
    useEffect(() => {
        if (isDoneSubmitting) {
            setIsAddingNewGoalMarker(false)
        }
    // eslint-disable-next-line
    }, [isDoneSubmitting])
    return (
        <div className="alignment-modal-background">
            <div className="goal-marker-modal-form-container">
                <div className="goal-marker-modal-form-header">
                    <h3>Add new goal marker</h3>
                    <FaTimes className="goal-marker-modal-form-cancel" onClick={(e) => setIsAddingNewGoalMarker(false)}/>
                </div>

                {isSubmitting ? (
                    <>
                    <div>Creating new goal marker...</div>
                    <LoadingSymbolGrid/>
                    </>
                ) : (
                    <>
                    {/* Check if there are any potential bonds */}
                    {potentialBondIds.length > 0 ? (
                        <>
                            {page === 1 &&
                                <>
                                <div className="goal-marker-modal-form-page-description">Select a marker you would like to track with this goal.  You can track items in more than one place.</div>
                                <div className="gomano-search-container">
                                    <BiSearch/>
                                    <input type="text" autoFocus={true} value={query} onChange={(e) => setQuery(e.target.value)} placeholder="Find marker" />
                                </div>
                                <div className="goal-marker-new-option-card-container">
                                {/* Create a card for every possible BOND */}
                                {getFilteredPotentialBonds().map((cId) => (
                                    <div className="goal-marker-new-option-card" key={cId} style={getSelectedChildStyle(cId)} onClick={() => setSelectedChild(allComponents[cId])}>

                                        <div className="gomano-card-top">
                                            <div className="gomano-card-row">
                                                {getComponentSymbol(allComponents[cId]['name'])}
                                                <div className="gomano-card-component-type" style={getExtraComponentNameStyles(allComponents[cId]['name'])}>{allComponents[cId]['name']}</div>
                                            </div>
                                            
                                        </div>

                                        <div className="gomano-card-middle">
                                            <div className="gomano-card-component-name">{allComponents[cId]['display_name']}</div>
                                            {/* Show archived badge if the component is archived */}
                                            {allComponents[cId]['status'] === 'archived' &&
                                            <div>
                                                <span className="gomano-card-component-status-archived">{allComponents[cId]['status']}</span>
                                            </div>}
                                        </div>
                                        

                                        <div className="gomano-card-bottom">
                                            <div className="gomano-card-row">
                                                {getGomanoCardBottomRow(allComponents[cId])}
                                            </div>
                                            
                                        </div>
                                    </div>
                                ))}
                                {getFilteredPotentialBonds().length === 0 && <div>No matches found</div>}
                                </div>
                                <div className="modal-task-edit-submission-section">
                                    <div></div>
                                    <div className="modal-task-edit-submission-section">
                                        <div className="modal-task-create-cancel" onClick={(e) => setIsAddingNewGoalMarker(false)}>Cancel</div>
                                        {selectedChild && <div className="modal-task-create-submit" onClick={() => setPage(2)}>Continue</div>}
                                    </div>
                                </div> 
                                </>
                            }

                            {/* Page 2 - configure component */}
                            {page === 2 && (
                                <>
                                {/* Component Name */}
                                <div className="goal-marker-modal-form-page-description-type">
                                    {getComponentSymbol(selectedChild.name)}
                                    {selectedChild.name}
                                </div>
                                <div className="goal-marker-modal-form-page-description-names">
                                    <div className="goal-marker-modal-form-page-description-text">Set a goal for 
                                    <span className="goal-marker-modal-form-page-description-name"> {selectedChild.display_name}</span>
                                    :</div>
                                </div>

                                <div className="modal-workspace-component-edit-section modal-workspace-component-edit-section-doublewide">
                                    <div className="modal-workspace-component-edit-subsection">
                                        <h4>Current</h4>
                                        <div className="gomano-card-component-metric">{getMetricDisplayValue(selectedChild)}</div>
                                    </div>
                                    <div className="modal-workspace-component-edit-subsection">
                                        <h4>Target</h4>
                                        {selectedChild.name === 'kpi' ? (
                                            <input className="gomano-card-component-metric-target-input" value={targetValue} onChange={(e) => setTargetValue(e.target.value)} autoFocus={true} type="number" placeholder={getSelectedChildCurrentValue()}/>
                                        ) : (
                                            <input className="gomano-card-component-metric-target-input" value={targetValue} onChange={(e) => setTargetValue(e.target.value)} autoFocus={true} max={100} min={0} type="number" placeholder={getSelectedChildCurrentValue()}/>
                                        )}
                                    </div>
                                </div>

                                {/* Submission Section */}
                                <div className="modal-task-edit-submission-section">
                                    <div></div>
                                    <div className="modal-task-edit-submission-section">
                                        <div className="modal-task-create-cancel" onClick={() => setPage(1)}>Back</div>
                                        <div className="modal-task-create-submit" onClick={addNewGoalMarker}>Add New Marker</div>
                                    </div>
                                </div> 
                                </>
                            )}
                        {/* </div> */}
                        </>
                    ) : (
                        <div>
                            <div className="gomano-message-text">Great job, you're already tracking everything!</div>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                                <span className="gomano-message-button" onClick={(e) => setIsAddingNewGoalMarker(false)}>Return</span>
                            </div>
                        </div>
                    )}
                    </>
                )}

            </div>
        </div>
    )
}
