import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { Calendar } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { format, isValid, addYears, isPast, addDays } from 'date-fns';
import { IoCaretDownSharp, IoCalendarClearSharp } from 'react-icons/io5'
import { MdClose } from 'react-icons/md'

export default function DateBoxEditable({date, onupdate, label, editable, minDate, maxDate, side, clearDate, altEmptyText, component}) {
    const [isShowingDatePicker, setIsShowingDatePicker] = useState(() => { return false })
    const [displayedDate, setDisplayedDate] = useState(() => { return date })
    const [calendarFriendlyDate, setCalendarFriendlyDate] = useState(() => { return "" })
    const datePickerRef = useRef(null)
    const getMin = () => {
        if (!minDate) return new Date("01-01-2020")
        if (isValid(minDate)) return minDate
        return new Date("01-01-2020")
    }
    const hasDate = () => {
        if (displayedDate === "") return false
        if (!displayedDate) return false
        if (isValid(displayedDate)) return true
        return false
    }
    const getMax = () => {
        if (!maxDate) return addYears(new Date(), 20)
        if (isValid(maxDate)) return maxDate
        return addYears(new Date(), 20)
    }
    const hide = () => {
        setIsShowingDatePicker(false)
    }
    const getFancyDateString = () => {
        if (!editable && !isValid(displayedDate)) return <div>None</div>
        if (!isValid(displayedDate)) return (
            <div className="date-box-no-date-text">
                <div>{altEmptyText ? altEmptyText : "Choose a date"}</div>
                <IoCaretDownSharp/>
            </div>
        ) 
        return format(displayedDate, 'M/d/yyyy')
    }
    const handleRemoveDueDateAttempt = () => {
        if (!editable) return
        setDisplayedDate("")
        clearDate()
    }
    const handleClickOnDatePicker = () => {
        if (!editable) return
        if (isValid(displayedDate)) setCalendarFriendlyDate(displayedDate)
        if (!isValid(displayedDate)) setCalendarFriendlyDate(new Date())
        setIsShowingDatePicker(true)
    }
    const getAdditionalStyles = () => {
        if (side === "right") return {
            left: 0,
            right: "unset"
        }
        return {}
    }
    const getAdditionalDisplayStyles = () => {
        if (!isShowingDatePicker) return
        return {
            border: "1px solid rgb(25, 60, 126)"
        }
    }
    const getDateStyles = () => {
        if (!editable && !isValid(displayedDate)) return {}
        if (!isValid(displayedDate)) return {}
        let styles = {}
        // if component is complete return without updating styles
        if (component) {
            if (component.status === "completed") return styles
        }
        const overdueColor = "#E60053"
        if (isPast(addDays(displayedDate, 1))) styles['color'] = overdueColor
        return styles
    }
    const handleDateChange = (d) => {
        setDisplayedDate(d)
        hide()
        onupdate(d)
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
                hide()
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [datePickerRef])
    useEffect(() => {
        setDisplayedDate(date)
    }, [date])
    return (
        <div ref={datePickerRef}>
            <div className="date-box-editable-selection-container">
                <div className="date-box-editable-selection-clickable-display" style={getAdditionalDisplayStyles()}>
                    <div onClick={handleClickOnDatePicker}>
                        {label && <div className="date-box-editable-section-label">{label}</div>}
                        <div className="date-box-editable-inner-container">
                            <IoCalendarClearSharp style={{color: "#2973af"}}/>
                            <div>
                                <div className="date-box-editable-description" style={getDateStyles()}>{getFancyDateString()}</div>
                            </div>
                        </div>  
                    </div>
                    {(hasDate() && editable && clearDate) && (
                        <div className="gadget-owner-selector-clickable-container-remove-owner-button" onClick={handleRemoveDueDateAttempt}>
                            <MdClose/>
                        </div>
                    )}
                </div>

                {isShowingDatePicker && (
                    <div className="date-box-editable-calendar-picker" style={getAdditionalStyles()}>
                        <Calendar date={calendarFriendlyDate} onChange={handleDateChange} maxDate={getMax()} minDate={getMin()} />
                    </div>
                )}
            </div>
        </div>
    )
}
