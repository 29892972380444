import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Api from "../common/APIUtils"
import DataManager from "../common/DataManager";
import { useHistory, useParams } from 'react-router-dom';
import '../styles/workspace.css';
import '../styles/components.css';
import '../styles/posts.css';

// Component Imports
import WorkspaceCompList from './components/WorkspaceCompList';
import WorkspaceCompInitiative from './components/WorkspaceCompInitiative';
import WorkspaceCompGoal from './components/WorkspaceCompGoal';
import WorkspaceCompTaskMain from './components/lists/WorkspaceCompTaskMain';
import WorkspaceCompBoard from './components/WorkspaceCompBoard';
import WorkspaceCompKpi from './components/WorkspaceCompKpi';
import WorkspaceCompKResult from './components/WorkspaceCompKResult';
import WorkspaceCompTimeline from './components/WorkspaceCompTimeline';

// Non-component Imports
import WorkspaceHomeFeed from './workspaces/WorkspaceHomeFeed';
import WorkspaceTeam from './workspaces/WorkspaceTeam';
import WorkspaceSettings from './workspaces/WorkspaceSettings';
import WorkspaceDashboards from './workspaces/WorkspaceDashboards';
import WorkspaceMeasurements from './workspaces/WorkspaceMeasurements';
import WorkspaceTags from './workspaces/WorkspaceTags';
import dataMaps from '../common/DataMaps'
import LoadingSymbolRipple from './gadgets/LoadingSymbolRipple';
import ModalWorkspaceOnboardingMessage from './modals/ModalWorkspaceOnboardingMessage';
import WorkspaceLibrary from './workspaces/WorkspaceLibrary';
import PostPage from './posts/PostPage';
import InteractiveSidebar from './sidebars/InteractiveSidebar';

// eslint-disable-next-line
import Chat from './chat/Chat';

export default function Workspace({view, sidebarCollapsed, setSidebarCollapsed, subview}) {
    // const query = new URLSearchParams(useLocation().search);
    const api = new Api();
    const activeComponentId = useRef(false)
    const workspaceLastFetchedTimestamp = useRef(false)
    const [workspace, setWorkspace] = useState({});
    const [userWorkspaces, setUserWorkspaces] = useState(() => { return [] })
    const [isShowingOnboardingMessage, setIsShowingOnboardingMessage] = useState(() => { return true });
    const [workspaceUserLimit, setWorkspaceUserLimit] = useState(() => { return false });
    const [workspaceComponents, setWorkspaceComponents] = useState({});
    const [workspacePermissions, setWorkspacePermissions] = useState([]);
    const [team, setTeam] = useState(() => { return [] })
    const [networkTeams, setNetworkTeams] = useState(() => { return [] })
    // eslint-disable-next-line
    const [chatsLastSeen, setChatsLastSeen] = useState(() => { return false })
    const [activeComponent, setActiveComponent] = useState({});
    const [componentData, setComponentData] = useState({});
    const [componentIsLoaded, setComponentIsLoaded] = useState(false);
    // Validate user is logged in
    let history = useHistory();
    let dataManager = DataManager.getInstance();
    if (!dataManager.getAuth()) {
        history.push('/login');
    }
    const { workspaceId, componentId, tagName } = useParams();
    const fetchComponent = () => {
        if (!componentId) return
        const payload = {
            componentId: componentId,
            workspaceId: workspaceId
        }
        api.getWorkspaceComponent(payload)
        .then((res) => {
            if (res.data) {
                if (res.data.response) {
                    if (res.data.response.status === "error") {
                        history.push(`/workspaces/${workspaceId}`)
                        return
                    }
                    if (res.data.response.component._id !== activeComponentId.current) return
                    // if (fetchedComponentId !== componentId) return
                    if (res.data.response.component) {
                        if (res.data.response.component._id !== componentId) return
                        setActiveComponent(res.data.response.component)
                        setComponentData(res.data.response);
                        setComponentIsLoaded(true)
                        fetchWorkspace()
                    }
                }
            }
        })
        .catch((err) => {
            console.log(err)
            if (err.response.status === 400) {
                history.push(`/workspaces/${workspaceId}`)
            }
            // TODO: Add toast that component is not accessible or does not exist
        })
        
    }
    const fetchWorkspace = () => {
        if (!workspaceId) return
        if (workspaceLastFetchedTimestamp.current) {
            if (Date.now() - workspaceLastFetchedTimestamp.current < 900) return
        }
        const payload = {
            id: workspaceId
        }
        api.getWorkspace(payload)
        .then((res) => {
            setWorkspacePermissions(res.data['permissions']['permissions'])
            setWorkspace(res.data.workspace);
            setWorkspaceComponents(res.data['components']);
            setTeam(res.data['team']);
            setNetworkTeams(res.data.network_teams)
            if (res.data.workspace_user_limit) setWorkspaceUserLimit(res.data.workspace_user_limit)
            if (res.data.chats_last_seen) setChatsLastSeen(res.data.chats_last_seen)
            workspaceLastFetchedTimestamp.current = Date.now()
        })
        .catch((err) => {
            if (err.response) {
                if (err.response.status === 400) {
                    history.push(`/desk?v=workspaces`)
                    // TODO: Add toast that user does not have access to the workspace
                } else {
                    history.push(`/desk?v=workspaces`)
                }
            }
            history.push(`/desk?v=workspaces`)
        })  
    }
    const fetchUserWorkspaces = () => {
        api.getWorkspaces()
        .then((res) => {
            if (!res.data) return
            if (!res.data.workspaces) return
            setUserWorkspaces(res.data.workspaces)
        })
        .catch((err) => {
            console.log(err)
        })        
    }
    const onboardUser = () => {
        setIsShowingOnboardingMessage(false)
        const payload = {
            onboarded_user_id: localStorage.getItem("gId"),
            workspaceId: workspaceId
        }
        api.updateWorkspace(payload)
        .then((res) => {
            fetchWorkspace();
        })
        .catch((e) => {
            console.log(e)
        })         
    }
    const userNeedsToOnboard = () => {
        if (!workspace.onboarding_enabled) return false
        if (!workspace.onboarding_message || workspace.onboarding_message === "") return false
        if (!workspace.onboarded_user_ids) return true
        if (workspace.onboarded_user_ids.includes(localStorage.getItem("gId"))) return false
        return true
    }
    const getActiveComponentName = () => {
        if (!componentIsLoaded) return false
        if (!activeComponent) return false
        if (!activeComponent.name) return false
        return activeComponent.name
    }
    useEffect(() => {
        fetchWorkspace()
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        activeComponentId.current = componentId
        if (!componentId) return
        fetchComponent()
        setComponentIsLoaded(false)
    // eslint-disable-next-line
    }, [componentId])
    const getProjectContainerClass = () => {
        if (sidebarCollapsed) return "project-container-sidebar-hidden"
        return ""
    }
    const goToResourcesPage = () => {
        history.push(`/workspaces/${workspaceId}/library?v=resources`)
    }
    const getSubview = () => {
        if (!subview) return false
        return subview
    }
    useEffect(() => {
        if (!activeComponent) {
            document.title = workspace.name
            return
        }
        if (!activeComponent.display_name) {
            document.title = workspace.name
            return
        }
        document.title = `${activeComponent.display_name} | ${workspace.name}`
    // eslint-disable-next-line
    },[activeComponent, workspace])
    useEffect(() => {
        fetchUserWorkspaces()
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        fetchWorkspace()
    // eslint-disable-next-line
    }, [workspaceId])
    return (
        <div id="project-container" className={getProjectContainerClass()}>
            {/* TEMPORARILY DEPRECATED */}
            {/* {workspace._id && <Chat workspaceId={workspace._id} workspace={workspace} team={team} chatsLastSeen={chatsLastSeen} permissions={workspacePermissions} />} */}
            {/* Lower Panels */}
            <div id="project-lower-container">
                <InteractiveSidebar fetchWorkspace={fetchWorkspace} userWorkspaces={userWorkspaces} permissions={workspacePermissions} sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} id="project-lower-left" page="workspace" workspace={workspace} workspaceName={workspace.name} workspaceId={workspaceId} components={workspaceComponents} componentId={activeComponentId.current}/>

                <div id="project-lower-right">
                    {isShowingOnboardingMessage && userNeedsToOnboard() && <ModalWorkspaceOnboardingMessage mainText={workspace.onboarding_message} onconfirm={onboardUser} />}
                    {/* WORKSPACE VIEW - COMPONENTS */}
                    {view === 'workspace' &&
                        <>
                        {getActiveComponentName() === 'list' && <WorkspaceCompList workspaceName={workspace.name} permissions={workspacePermissions} dataMaps={dataMaps} team={team} componentData={componentData} component={activeComponent} fetchComponent={fetchComponent}/>}
                        {getActiveComponentName() === 'initiative' && <WorkspaceCompInitiative sidebarCollapsed={sidebarCollapsed} workspace={workspace} activeComponentId={componentId} workspaceName={workspace.name} permissions={workspacePermissions} dataMaps={dataMaps} team={team} componentData={componentData} component={activeComponent} fetchComponent={fetchComponent}/>}
                        {getActiveComponentName() === 'kresult' && <WorkspaceCompKResult activeComponentId={componentId} workspaceName={workspace.name} permissions={workspacePermissions} dataMaps={dataMaps} team={team} componentData={componentData} component={activeComponent} fetchComponent={fetchComponent}/>}
                        {getActiveComponentName() === 'timeline' && <WorkspaceCompTimeline activeComponentId={componentId} workspaceName={workspace.name} permissions={workspacePermissions} dataMaps={dataMaps} team={team} componentData={componentData} component={activeComponent} fetchComponent={fetchComponent}/>}
                        {getActiveComponentName() === 'goal' && <WorkspaceCompGoal workspaceName={workspace.name} dataMaps={dataMaps} permissions={workspacePermissions} team={team} componentData={componentData} component={activeComponent} fetchComponent={fetchComponent}/>}
                        {getActiveComponentName() === 'task' && <WorkspaceCompTaskMain workspaceName={workspace.name} permissions={workspacePermissions} dataMaps={dataMaps} workspaceComponents={workspaceComponents} team={team} componentData={componentData} component={activeComponent} fetchComponent={fetchComponent}/>}
                        {getActiveComponentName() === 'board' && <WorkspaceCompBoard workspaceName={workspace.name} permissions={workspacePermissions} dataMaps={dataMaps} team={team} componentData={componentData} component={activeComponent} fetchComponent={fetchComponent}/>}
                        {getActiveComponentName() === 'kpi' && <WorkspaceCompKpi workspace={workspace} workspaceName={workspace.name} permissions={workspacePermissions} dataMaps={dataMaps} team={team} componentData={componentData} component={activeComponent} fetchComponent={fetchComponent}/>}
                        {getActiveComponentName() === 'link' && goToResourcesPage()}
                        {!componentIsLoaded && <div style={{display: "flex", justifyContent: "center", marginTop: "200px", width: "100%"}}><LoadingSymbolRipple/></div> }
                        </>
                    }

                    {/* HOME VIEW - WORKSPACE FEED */}
                    {view === 'home' &&
                        <WorkspaceHomeFeed team={team} workspaceId={workspaceId} workspace={workspace}/>
                    }

                    {/* TEAM MANAGEMENT */}
                    {view === 'team' && <WorkspaceTeam workspaceUserLimit={workspaceUserLimit} permissions={workspacePermissions} networkTeams={networkTeams} team={team} fetchWorkspace={fetchWorkspace} workspaceId={workspaceId} workspace={workspace}/>}

                    {/* MEASUREMENTS */}
                    {view === 'measurements' && <WorkspaceMeasurements permissions={workspacePermissions} fetchWorkspace={fetchWorkspace} components={workspaceComponents} team={team} workspaceId={workspaceId} subview={getSubview()}/>}

                    {/* TAGS */}
                    {view === 'tags' && <WorkspaceTags permissions={workspacePermissions} workspace={workspace} fetchWorkspace={fetchWorkspace} workspaceId={workspaceId} team={team} tagName={tagName} />}

                    {/* RESOURCES */}
                    {view === 'library' && <WorkspaceLibrary workspace={workspace} permissions={workspacePermissions} fetchWorkspace={fetchWorkspace} components={workspaceComponents} team={team} workspaceId={workspaceId} subview={getSubview()}/>}
                    
                    {/* RESOURCES */}
                    {view === 'post' && <PostPage workspace={workspace} sidebarCollapsed={sidebarCollapsed} permissions={workspacePermissions} fetchWorkspace={fetchWorkspace} components={workspaceComponents} team={team} workspaceId={workspaceId} subview={getSubview()}/>}

                    {/* WORKSPACE SETTINGS */}
                    {view === 'settings' && <WorkspaceSettings workspaceUserLimit={workspaceUserLimit} networkTeams={networkTeams} permissions={workspacePermissions} workspace={workspace} workspaceName={workspace.name} fetchWorkspace={fetchWorkspace} workspaceId={workspaceId} team={team} />}

                    {/* WORKSPACE DASHBOARDS */}
                    {view === 'dashboards' && <WorkspaceDashboards workspace={workspace} permissions={workspacePermissions} sidebarCollapsed={sidebarCollapsed} team={team} workspaceName={workspace.name} fetchWorkspace={fetchWorkspace} workspaceId={workspaceId} />}
                </div>

            </div>

        </div>
    )
}
