import React from 'react'
import { FaTimes } from 'react-icons/fa';
import { MdWorkOutline } from 'react-icons/md'
import { useState } from 'react';
import Api from '../../common/APIUtils';

import DataTableRow from '../tables/DataTableRow';
import ToggleSwitch from '../gadgets/ToggleSwitch';
import WorkspaceSettingsDetails from '../workspaces/WorkspaceSettingsDetails';

export default function ModalManageNetworkWorkspace({network, workspace, hideModal, fetchWorkspaces, permissions, deleteNetworkWorkspace}) {
    const api = new Api()
    const [view, setView] = useState(() => {return 'teams'})
    const [addUserFilter, setAddUserFilter] = useState(() => { return ""})
    const getNavClassName = (name) => {
        if (name === view) {
            return "network-panel-nav-selected"
        } else {
            return ""
        }
    }
    const teamMeetsFilter = (name) => {
        if (addUserFilter === "") return true
        if (name.toUpperCase().indexOf(addUserFilter.toUpperCase()) > -1) return true
        return false
    }
    const getAllTeams = () => {
        if (!network) return []
        if (!network.all_teams) return []
        const teams = network.all_teams.filter((team) => {
            return teamMeetsFilter(team.name)
        })
        return teams
    }
    const getTeamButton = (team) => {
        const activatedTeamIds = workspace.teams.map(a => a._id);
        return <div style={{padding: "0.5em"}} onClick={() => changeTeamActivation(activatedTeamIds.includes(team._id), team._id)}><ToggleSwitch isChecked={activatedTeamIds.includes(team._id)} /></div>
    }
    const formatTeamRowData = (team) => {
        return [
            {
                width: "lg",
                content: team.name,
                _id: `${team._id}-name`
            },
            {
                width: "sm",
                content: getTeamButton(team),
                _id: `${team._id}-manage`
            }
        ]
    }
    const changeTeamActivation = (previous, teamId) => {
        if (previous) return deactivateTeam(teamId)
        return activateTeam(teamId)
    }
    const activateTeam = (teamId) => {
        if (!network.slug) return
        const payload = {
            team_id: teamId,
            workspace_id: workspace._id
        }
        api.addWorkspaceToTeam(payload, network.slug)
        .then((res) => {
            if (res.data) {
                fetchWorkspaces()
            }
        })
        .catch((err) => {
            console.log('Toast')
        })
    }
    const deactivateTeam = (teamId) => {
        if (!network.slug) return
        const payload = {
            team_id: teamId,
            workspace_id: workspace._id
        }
        api.removeWorkspaceFromTeam(payload, network.slug)
        .then((res) => {
            if (res.data) {
                fetchWorkspaces()
            }
        })
        .catch((err) => {
            console.log('Toast')
        })
    }
    return (
        <div className="alignment-modal-background">
            <div className="modal-task-create-container" style={{minHeight: "30em"}}>

                {/* Header */}
                <div className="modal-task-create-header-row" style={{borderBottom: "none"}}>
                    <div className="modal-task-create-header-title">{workspace.name}</div>
                    <div className="modal-side-edit-header-row" style={{borderBottom: "none"}}>
                        <FaTimes className="project-post-form-cancel" onClick={hideModal}/>
                    </div>
                </div>

                <div className="network-panel-controls-container">
                    {/* Navigation Items */}
                    <div className="network-panel-nav-container">
                        <MdWorkOutline/>
                        <span className={getNavClassName('teams')} onClick={(e) => setView('teams')}>Teams</span>
                        <span className={getNavClassName('settings')} onClick={(e) => setView('settings')}>Settings</span>
                    </div>
                    <div className="network-panel-nav-container-actions">
                    </div>
                </div>

                {view === 'teams' &&
                <div className="modal-network-workspace-view-container">
                    <input className="modal-team-add-search-box" value={addUserFilter} onChange={(e) => setAddUserFilter(e.target.value)} type="text" placeholder="Find a team"/>
                    <div className="data-table-header-container" style={{marginTop: "1em"}}>
                        <span className="data-table-col-lg">Team</span>
                        <span className="data-table-col-sm">Workspace Access</span>
                    </div>
                    {getAllTeams().map((team) => (
                        <DataTableRow key={team._id} rowData={formatTeamRowData(team)} />
                    ))
                    }
                </div>
                }

                {view === 'settings' &&
                    <WorkspaceSettingsDetails workspace={workspace} workspaceId={workspace._id} fetchWorkspace={fetchWorkspaces} workspaceName={workspace.name} fromNetwork={true} permissions={permissions} deleteNetworkWorkspace={deleteNetworkWorkspace}/>
                }

            </div>
        </div>
    )
}
