import React from 'react'
import '../../styles/gadgets.css'
import { useEffect } from 'react'

export default function ToastText({setShowToast, content, timeout, actionText, actionOnClick}) {
    const hideToastText = () => setShowToast(false)
    const takeAnAction = () => {
        try {
            actionOnClick()
            setShowToast(false)
        } catch (error) {
            setShowToast(false)
        }
    }
    useEffect(() => {
        if (!timeout) return
        const timer = setTimeout(() => {
            setShowToast(false)
        }, timeout)
        return () => clearTimeout(timer)
    // eslint-disable-next-line
    }, [])
    return (
        <div className="toast-outer-container">
            {content && (
                <div className="toast-inner-container">
                    <div className="toast-content">{content}</div>
                    {!actionOnClick && <div className="toast-action" onClick={hideToastText}>Dismiss</div>}
                    {actionOnClick && <div className="toast-action" onClick={takeAnAction}>{actionText}</div>}
                </div>
            )}
        </div>
    )
}
