import React from 'react'
import { formatDistanceToNow } from 'date-fns'

export default function NewComponentTemplateChoice({template, setAsTemplate, chosenTemplate}) {
    const getAdditionalClass = () => {
        if (chosenTemplate === template._id) {
            return "form-template-selected"
        } else {
            return ""
        }
    }
    const getTemplateDescription = () => {
        if (template.template_description) {
            return template.template_description

        } else if (template.config && template.config.display_name) {
            return <>Created from <strong>{template.config.display_name}</strong></>

        } else if (template.component_type) {
            return <>A <strong>{template.component_type}</strong> template</>

        } else {
            return "A template"
        }
    }
    return (
        <div className={`form-template-container-outer ${getAdditionalClass()}`} onClick={() => setAsTemplate(template._id)}>
            <div>
                <div className="form-template-name">{template.template_name}</div>
                <div className="form-template-description">{getTemplateDescription()}</div>
            </div>
            {template.createdAt && <div className="form-template-age">Template created {formatDistanceToNow(new Date(template.createdAt))} ago</div>}
        </div>
    )
}
