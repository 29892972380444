import React from 'react'
import Api from '../../common/APIUtils';
import { useState, useReducer, useEffect } from 'react';
import LoadingSymbolRipple from '../gadgets/LoadingSymbolRipple'
import { AiFillCheckSquare, AiOutlineBorder } from 'react-icons/ai'
import { translatePermissionString } from '../../common/Helpers';

import ModalSimpleHeader from './simple/ModalSimpleHeader'
import ModalSimpleSingleButton from './simple/ModalSimpleSingleButton'
import ProfPicCircle from '../profiles/ProfPicCircle';

export default function ModalManageNetworkUser({userId, network, fetchNetwork, hideModal, networkUserData}) {
    const api = new Api()
    const [nav, setNav] = useState(() => { return "teams"})
    const [userMembershipData, userMembershipDataDispatch] = useReducer((state, action) => {
        try {
            if (action.type === "init") {
                // teams
                let currentTeamIdsUserIsMemberOf = []
                const userTeamMemberships = network.user_team_memberships.filter((teamMembership) => {
                    if (teamMembership.member_id === userId) return true
                    return false
                })
                if (userTeamMemberships) currentTeamIdsUserIsMemberOf = userTeamMemberships.map(t => t.membership_to_id)
                // workspaces
                let currentWorkspaceIdsUserIsMemberOf = []
                const userWorkspaceMemberships = networkUserData.users.find(u => u._id === userId)
                if (userWorkspaceMemberships) currentWorkspaceIdsUserIsMemberOf = userWorkspaceMemberships.workspace_memberships.map(w => w.membership_to_id)
                // roles
                let roleData = {}
                for (let i = 0; i < userWorkspaceMemberships.workspace_memberships.length; i++) {
                    let roleId = ""
                    if (userWorkspaceMemberships.workspace_memberships[i].role_id) roleId = userWorkspaceMemberships.workspace_memberships[i].role_id
                    roleData[userWorkspaceMemberships.workspace_memberships[i].membership_to_id] = roleId
                }
                // together now
                let membershipData = {
                    teams: currentTeamIdsUserIsMemberOf,
                    workspaces: currentWorkspaceIdsUserIsMemberOf,
                    roles: roleData
                }
                return { loaded: true, ...action.data, ...membershipData }
            }
            if (action.type === "toggle_team") {
                let selectedTeamIds = Array.from(state.teams)
                // Add team if it isn't selected
                if (!selectedTeamIds.includes(action.team_id)) {
                    selectedTeamIds.push(action.team_id)
                    return { ...state, teams: selectedTeamIds }
                }
                // Remove it - it is already selected
                const index = selectedTeamIds.indexOf(action.team_id)
                if (index < 0) return state
                selectedTeamIds.splice(index, 1)
                return { ...state, teams: selectedTeamIds }
            }
            if (action.type === "toggle_workspace") {
                let selectedWorkspaceIds = Array.from(state.workspaces)
                // Add workspace if it isn't selected
                if (!selectedWorkspaceIds.includes(action.workspace_id)) {
                    selectedWorkspaceIds.push(action.workspace_id)
                    return { ...state, workspaces: selectedWorkspaceIds }
                }
                // Remove it - it is already selected
                const index = selectedWorkspaceIds.indexOf(action.workspace_id)
                if (index < 0) return state
                selectedWorkspaceIds.splice(index, 1)
                // Remove the proposed role change //// { workspace_id: role_id, workspace_id: role_id }
                let proposedRoles = {}
                if (state.roles) proposedRoles = state.roles
                proposedRoles[action.workspace_id] = ""
                return { ...state, workspaces: selectedWorkspaceIds, roles: proposedRoles }
            }
            if (action.type === "change_workspace_role") {
                let proposedRoles = {}
                if (state.roles) proposedRoles = state.roles
                proposedRoles[action.workspace_id] = action.role_id
                return { ...state, roles: proposedRoles }
            }
            return state
        } catch (error) {
            console.log(error)
            return state
        }
    }, {})
    const getUserData = () => {
        const allUsers = Array.from(networkUserData.users)
        const userData = allUsers.find(u => u._id === userId)
        if (!userData) return false
        return userData
    }
    const getListOfWorkspacesUserCanAccess = () => {
        try {
            let workspaceIds = userMembershipData.workspaces
            const currentlySelectedTeams = userMembershipData.teams
            for (let i = 0; i < currentlySelectedTeams.length; i++) {
                const teamWorkspaceIdAccess = networkUserData.teams.find(t => t._id === currentlySelectedTeams[i]).workspace_ids
                workspaceIds = [...new Set(workspaceIds.concat(teamWorkspaceIdAccess))]
            }
            return workspaceIds.filter((id) => {
                const wrkspc = networkUserData.workspaces.find(w => w._id === id)
                if (!wrkspc) return false
                if (!wrkspc.name) return false
                return true
            })
            // return workspaceIds
        } catch (error) {
            console.log(error)
            return []
        }
    }
    const getUserCurrentTeamIds = () => {
        try {
            let currentTeamIdsUserIsMemberOf = []
            const userTeamMemberships = network.user_team_memberships.filter((teamMembership) => {
                if (teamMembership.member_id === userId) return true
                return false
            })
            if (userTeamMemberships) currentTeamIdsUserIsMemberOf = userTeamMemberships.map(t => t.membership_to_id)
            return currentTeamIdsUserIsMemberOf
        } catch (error) {
            return []
        }
    }
    const getUserCurrentWorkspaceIds = () => {
        try {
            let currentWorkspaceIdsUserIsMemberOf = []
            const userWorkspaceMemberships = networkUserData.users.find(u => u._id === userId)
            if (userWorkspaceMemberships) currentWorkspaceIdsUserIsMemberOf = userWorkspaceMemberships.workspace_memberships.map(w => w.membership_to_id)
            return currentWorkspaceIdsUserIsMemberOf
        } catch (error) {
            return []
        }
    }
    const getNavClass = (navName) => {
        if (navName === nav) return "modal-mgmt-user-membership-nav-selected"
        return ""
    }
    const updateUserData = () => {
        const userData = getUserData()
        let updatedData = {
            user: userData
        }
        userMembershipDataDispatch( {type: "init", data: updatedData } )
    }
    const getTeamOptions = () => {
        if (!network.all_teams) return <div>No teams available...</div>
        return (
            <>
            {network.all_teams.map((team) => (
                <div className="modal-mgmt-user-membership-team" key={team._id}>
                    {userMembershipData.teams.includes(team._id) && <AiFillCheckSquare style={{color: "#3D72AA"}} onClick={() => userMembershipDataDispatch({ type: "toggle_team", team_id: team._id })}/>}
                    {!userMembershipData.teams.includes(team._id) && <AiOutlineBorder onClick={() => userMembershipDataDispatch({ type: "toggle_team", team_id: team._id })}/>}
                    <span>{team.name}</span>
                </div>
            ))}
            </>
        )
    }
    const getRoleOptions = () => {
        if (!networkUserData.roles) return <option value="">--- None Found ---</option>
        return (
            <>
            {networkUserData.roles.map((role) => (
                <option value={role._id} key={role._id}>{role.name}</option>
            ))}
            </>
        )
    }
    const getWorkspaceOptions = () => {
        if (!networkUserData.workspaces) return <div>No workspaces available...</div>
        return (
            <>
            {networkUserData.workspaces.map((workspace) => (
                <div className="modal-mgmt-user-membership-workspace" key={workspace._id}>
                    <div className="modal-mgmt-user-membership-team">
                        {userMembershipData.workspaces.includes(workspace._id) && <AiFillCheckSquare style={{color: "#3D72AA"}} onClick={() => userMembershipDataDispatch({ type: "toggle_workspace", workspace_id: workspace._id })}/>}
                        {!userMembershipData.workspaces.includes(workspace._id) && <AiOutlineBorder onClick={() => userMembershipDataDispatch({ type: "toggle_workspace", workspace_id: workspace._id })}/>}
                        <span>{workspace.name}</span>
                    </div>
                    {userMembershipData.workspaces.includes(workspace._id) && 
                    <select value={userMembershipData.roles[workspace._id]} onChange={(e) => userMembershipDataDispatch({ type: "change_workspace_role", role_id: e.target.value, workspace_id: workspace._id })}>
                        <option value="">No permissions</option>
                        {getRoleOptions()}
                    </select>
                    }
                </div>
            ))}
            </>
        )
    }
    const buildPayload = () => {
        let payload
        try {
            // team removals
            let teamIdsToRemove = []
            const currentTeamIds = getUserCurrentTeamIds()
            const proposedTeamIds = userMembershipData.teams
            for (let i = 0; i < currentTeamIds.length; i++) {
                if (!proposedTeamIds.includes(currentTeamIds[i])) teamIdsToRemove.push(currentTeamIds[i])
            }

            // teams to add
            let teamIdsToAdd = []
            for (let i = 0; i < proposedTeamIds.length; i++) {
                if (!currentTeamIds.includes(proposedTeamIds[i])) teamIdsToAdd.push(proposedTeamIds[i])         
            }

            // workspaces to remove
            let workspaceIdsToRemove = []
            const currentWorkspaceIds = getUserCurrentWorkspaceIds()
            const proposedWorkspaceIds = userMembershipData.workspaces
            for (let i = 0; i < currentWorkspaceIds.length; i++) {
                if (!proposedWorkspaceIds.includes(currentWorkspaceIds[i])) workspaceIdsToRemove.push(currentWorkspaceIds[i])               
            }

            // workspaces to add
            let workspacesToAdd = []
            for (let i = 0; i < proposedWorkspaceIds.length; i++) {
                // if it has a role associated with it, add it
                if (userMembershipData.roles[proposedWorkspaceIds[i]]) {
                    workspacesToAdd.push({
                            workspace_id: proposedWorkspaceIds[i],
                            role_id: userMembershipData.roles[proposedWorkspaceIds[i]]
                        })
                }
                // Selected but no role exists - add to workspaceIdsToRemove
                if (!userMembershipData.roles[proposedWorkspaceIds[i]]) workspaceIdsToRemove.push(proposedWorkspaceIds[i])
            }
            payload = {
                network_id: network._id,
                user_id: userId,
                team_ids_remove: teamIdsToRemove,
                team_ids_add: teamIdsToAdd,
                workspace_ids_remove: workspaceIdsToRemove,
                workspaces_add: workspacesToAdd
            }
        } catch (error) {
            return hideModal()
        }

        if (!payload.network_id) return hideModal()
        api.updateNetworkUserPermissions(payload)
        .then((res) => {
            fetchNetwork()
            hideModal()
        })
        .catch((err) => {
            console.log(err)
            fetchNetwork()
            hideModal()
        })
    }
    useEffect(() => {
        if (!userId) return
        updateUserData()
    // eslint-disable-next-line
    }, [])
    return (
        <div className="modal-mgmt-background-overlay">

            {!userMembershipData.loaded &&
            <div className="modal-mgmt-container">
                <ModalSimpleHeader title="Edit User Settings" hideModal={hideModal} />
                <div style={{padding: "48px", display: "flex", justifyContent: "center"}}>
                    <LoadingSymbolRipple/>
                </div>
            </div>
            }

            {userMembershipData.loaded &&
            <div className="modal-mgmt-container">
                <ModalSimpleHeader title="Edit User Settings" hideModal={hideModal} />

                <div className="modal-mgmt-section-cols">

                    <div className="modal-mgmt-sections" style={{minWidth: "240px"}}>
                        <div className="modal-mgmt-section">
                            <ProfPicCircle src={userMembershipData.user.profile_picture_url} userId={userId} userName={`${userMembershipData.user.firstName} ${userMembershipData.user.lastName}`} height={80} />
                            <h2 style={{margin: "0", marginTop: "20px"}}>{userMembershipData.user.firstName} {userMembershipData.user.lastName}</h2>
                            <p style={{margin: "0"}}>@{userMembershipData.user.handle}</p>
                        </div>
                    </div>
            
                    <div className="modal-mgmt-sections" style={{flexGrow: 1}}>

                        <div className="modal-mgmt-section">
                            <h3>User Access</h3>
                            <div className="modal-mgmt-user-membership-navs">
                                <span className={getNavClass("teams")} onClick={() => setNav("teams")}>Teams</span>
                                <span className={getNavClass("workspaces")} onClick={() => setNav("workspaces")}>Workspaces</span>
                            </div>
                        </div>

                        {nav === "teams" &&
                        <>
                        <div className="modal-mgmt-section">
                            <h4>What teams would you like this user to be on?</h4>
                            <div className="modal-mgmt-user-membership-teams">
                                {getTeamOptions()}
                            </div>
                        </div>
                        </>
                        }

                        {nav === "workspaces" &&
                        <>
                        <div className="modal-mgmt-section">
                            <h4>What workspaces would you like this user to be a direct member of?</h4>
                            <div className="modal-mgmt-user-membership-teams">
                                {getWorkspaceOptions()}
                            </div>
                        </div>
                        </>
                        }
                    </div>
                </div>
                <div className="modal-mgmt-sections" style={{marginTop: "24px"}}>
                    <div className="modal-mgmt-section">
                        <h3>Based on your selections, this user will have the following permissions:</h3>
                        <div className="modal-mgmt-user-workspace-permission-sets">
                            {getListOfWorkspacesUserCanAccess().map((workspaceId) => (
                                <NetworkWorkspacePermissionSet key={workspaceId} networkUserData={networkUserData} workspaceId={workspaceId} userMembershipData={userMembershipData}  />
                            ))}
                        </div>

                    </div>
                </div>
                <div className="modal-mgmt-sections" style={{display: "flex", flexDirection: "column-reverse", marginTop: "40px"}}>
                    <ModalSimpleSingleButton buttonText="Save" isValid={true} onclick={buildPayload} />
                </div>
            </div>
            }
        </div>
    )
}

function NetworkWorkspacePermissionSet({workspaceId, networkUserData, userMembershipData}) {
    const getWorkspaceName = () => {
        try {
            return networkUserData.workspaces.find(w => w._id === workspaceId).name
        } catch (error) {
            return "Unknown workspace"
        }
    }
    const getPermissions = () => {
        try {
            let permissions = []
            // From teams
            const teamsUserIsOn = userMembershipData.teams
            for (let i = 0; i < teamsUserIsOn.length; i++) {
                const teamId = teamsUserIsOn[i]
                const teamData = networkUserData.teams.find(t => t._id === teamId)
                const workspacesTeamCanAccess = teamData.workspace_ids
                if (!workspacesTeamCanAccess) continue
                if (!workspacesTeamCanAccess.includes(workspaceId)) continue // team cannot access workspace
                const teamRoleId = teamData.role_id
                if (!teamRoleId) continue
                const rolePermissions = networkUserData.roles.find(r => r._id === teamRoleId).permissions
                permissions = [...new Set(permissions.concat(rolePermissions))]
            }
            // From direct memberships
            const userWorkspaceMemberships = userMembershipData.workspaces
            if (!userWorkspaceMemberships.includes(workspaceId)) return permissions
            if (!userMembershipData.roles[workspaceId]) return permissions
            const workspaceRoleId = userMembershipData.roles[workspaceId]
            if (!workspaceRoleId) return permissions
            const workspaceRoleData = networkUserData.roles.find(r => r._id === workspaceRoleId)
            if (!workspaceRoleData) return permissions
            const workspaceRolePermissions = workspaceRoleData.permissions
            if (!workspaceRolePermissions) return permissions
            permissions = [...new Set(permissions.concat(workspaceRolePermissions))]
            return permissions
        } catch (error) {
            console.log(error)
            return []
        }
    }
    return (
        <div className="modal-mgmt-user-workspace-permission-set">
            <div className="modal-mgmt-user-workspace-name">
                <div>{getWorkspaceName()}</div>
            </div>
            <div className="modal-manage-user-membership-role-permissions-box" style={{flexGrow: 1}}>
                {getPermissions().map((permission) => (
                    <span key={permission}>{translatePermissionString(permission)}</span>
                ))}
                {getPermissions().length === 0 && <div style={{fontSize: "14px"}}>No permissions</div> }
            </div>
        </div>
    )
}