import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';

import WorkspaceSettingsDetails from './WorkspaceSettingsDetails'
import WorkspaceTeam from './WorkspaceTeam';
import InternalMenuSelections from '../gadgets/InternalMenuSelections'

export default function WorkspaceSettings({ workspaceName, team, workspaceId, fetchWorkspace, fromNetwork, workspace, permissions, deleteNetworkWorkspace, networkTeams, workspaceUserLimit }) {
    const history = useHistory()
    const [hubView, setHubView] = useState(() => { return "overview" })
    const updateView = (newView) => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('v', newView);
        history.push(`${window.location.pathname}?${searchParams.toString()}`);
        setHubView(newView);
    };
    const viewOptions = [
        {
            display_name: "Overview",
            id: "overview"
        },
        {
            display_name: "Users",
            id: "users"
        }
    ]
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const initialView = searchParams.get('v') || 'overview'
        setHubView(initialView);
    }, []);
    return (
        <div className="network-page">
            <div className="network-users-header-row" style={{marginBottom: "16px", marginTop: "16px"}}>
                <div className="network-page-header-text">
                    <h1>Settings</h1>
                    <p>Customize your workspace experience</p>
                </div>
            </div>
            <div className="workspace-library">
                <div className="workspace-library-menu-selectors">
                    <InternalMenuSelections updateView={updateView} view={hubView} viewOptions={viewOptions} />
                </div>
                <div className="workspace-library-display-container">

                    {hubView === "overview" && <WorkspaceSettingsDetails permissions={permissions} workspace={workspace} workspaceName={workspaceName} fetchWorkspace={fetchWorkspace} workspaceId={workspaceId} team={team} />}

                    {hubView === "users" && <WorkspaceTeam workspaceUserLimit={workspaceUserLimit} permissions={permissions} networkTeams={networkTeams} team={team} fetchWorkspace={fetchWorkspace} workspaceId={workspaceId} workspace={workspace}/>}
                </div>
            </div>
        </div>
    )
}
