import React from 'react'
import { useState, useEffect, useRef } from 'react';
import Api from '../../common/APIUtils';
import { hasPermission } from '../../common/Helpers';
import EditableComponentTitle from '../gadgets/EditableComponentTitle';
import { format, addDays, subDays, parseISO, isSaturday, isSunday } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { IoRepeat } from 'react-icons/io5'
import { MdAdd } from 'react-icons/md'

import ComponentTimelineAddWorkstream from './timeline/ComponentTimelineAddWorkstream';
import ComponentTimelineDateSelector from './timeline/ComponentTimelineDateSelector';
import ComponentTimelineHeader from './timeline/ComponentTimelineHeader';
import ComponentTimelineSwimlane from './timeline/ComponentTimelineSwimlane';

import ModalTimelineNewTask from '../modals/ModalTimelineNewTask';
import ModalTimelineEditTask from '../modals/ModalTimelineEditTask';

import SimpleListActionMenu from './SimpleListActionMenu';
import ConfirmAction from '../modals/ConfirmAction';

export default function WorkspaceCompTimeline({component, permissions, team, componentData, fetchComponent, workspaceName, activeComponentId}) {
    let history = useHistory();
    const projectedDays = 60;
    const api = new Api()
    const mainContainerRef = useRef(false)
    const [dateRange, setDateRange] = useState(() => { return [] }) // range of date strings formatted as 'yyyy-MM-dd'
    const [keyDate, setKeyDate] = useState(() => { return subDays(new Date(), 2) })
    const [endDate, setEndDate] = useState(() => { return new Date() })
    const [swimlanes, setSwimlanes] = useState(() => { return [] })
    const [isAddingNewMarker, setIsAddingNewMarker] = useState(() => { return false })
    const [isShowingConfirmMenu, setIsShowingConfirmMenu] = useState(() => { return false })
    const [confirmMenuContent, setConfirmMenuContent] = useState(() => { return "" })
    const [confirmMenuAction, setConfirmMenuAction] = useState(() => { return false })
    const [isEditingTask, setIsEditingTask] = useState(() => { return false })
    const [isAddingTask, setIsAddingTask] = useState(() => { return false })
    const [isSubmittingUpdates, setIsSubmittingUpdates] = useState(() => { return false })
    const [componentToEdit, setComponentToEdit] = useState(() => { return false })
    const [componentToEditComments, setComponentToEditComments] = useState(() => { return [] })
    const [parentOfNewTask, setParentOfNewTask] = useState(() => { return false })
    const [parentOfEditTask, setParentOfEditTask] = useState(() => { return false })
    // eslint-disable-next-line
    const [showCompletedTasks, setShowCompletedTasks] = useState(() => { return false })
    const [lastSwimlaneGenerationTime, setLastSwimlaneGenerationTime] = useState(() => { return false })
    const [unsortedAlignments, setUnsortedAlignments] = useState(() => { return [] })
    const [pendingComponentUpdates, setPendingComponentUpdates] = useState(() => { return false }) // used to async update a component
    const updateComponent = (payload, componentId) => {
        const path = `/workspace/components/${componentId}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const getColumnStyle = (dateString) => {
        let columnStyle = {}
        const asDateObject = parseISO(dateString)
        const isSat = isSaturday(asDateObject)
        const isSun = isSunday(asDateObject)
        // if weekend - background color
        if (!isSat && !isSun) return {}
        if (isSat || isSun) columnStyle['backgroundColor'] = "var(--weekend-background)"
        return columnStyle
    }
    const regenerateDateRange = () => {
        let headingsData = []
        let iDate = keyDate // iteration date
        while (iDate < endDate) {
            const formattedIDate = format(iDate, 'yyyy-MM-dd')
            headingsData.push(formattedIDate)
            // increment
            iDate = addDays(iDate, 1)
        }
        setDateRange(headingsData)
    }
    const rebuildCalendarMap = () => {
        setEndDate(addDays(keyDate, projectedDays))
        return true
    }
    const fetchFullAlignmentInformation = async () => {
        if (!component) return
        if (!component._id) return
        api.getComponentAlignments({componentId: component._id})
        .then((res) => {
            if (res.data) {
                if (res.data.response) {
                    setUnsortedAlignments(res.data.response.alignments)
                }
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }
    const popFromSwimlanes = (swimlaneId) => {
        let copyOfSwimlanes = Array.from(swimlanes)
        let index = copyOfSwimlanes.findIndex((swimlane) => swimlane._id === swimlaneId)
        copyOfSwimlanes.splice(index, 1)
        setSwimlanes(copyOfSwimlanes)
    }
    const changeKeyDate = (d) => {
        setKeyDate(subDays(d, 2))
    }
    const updateSwimlanes = () => {
        try {
            if (!component.childcomponents) setSwimlanes([])
            if (component.childcomponents.length === 0) setSwimlanes([])
            const directChildrenComponents = component.childcomponents;
            let updatedSwimlane = Array.from(swimlanes)
            for (let i = 0; i < directChildrenComponents.length; i++) {
                let swimlaneBaseComponent = directChildrenComponents[i];
                const exIndex = updatedSwimlane.findIndex(a => a._id === swimlaneBaseComponent._id)

                let tasksArray = unsortedAlignments.filter((alignedComponent) => {
                    if (!alignedComponent.attributes) return false
                    if (alignedComponent.attributes.parent === swimlaneBaseComponent._id) return true
                    return false
                })

                // If > -1 it is already in there - add new tasks idempotently
                if (exIndex > -1) {
                    updatedSwimlane[exIndex]['tasks'] = tasksArray
                    continue
                }

                // Build new entry and add to updatedSwimlane
                swimlaneBaseComponent['tasks'] = tasksArray
                updatedSwimlane.push(swimlaneBaseComponent)
            }
            setSwimlanes(updatedSwimlane)
        } catch (error) {
            console.log(error)
            setSwimlanes([])
        }
        setLastSwimlaneGenerationTime(new Date())
    }
    const addPendingComponentUpdate = (payload, componentId) => {
        let updatedPendingComponentUpdates = Array.from(pendingComponentUpdates)
        updatedPendingComponentUpdates.push({
            component_id: componentId,
            payload: payload,
            stamp: Date.now()
        })
        setPendingComponentUpdates(updatedPendingComponentUpdates)
    }
    const removePendingComponentUpdate = (stamp) => {
        let pendingComponentUpdatesCopy = Array.from(pendingComponentUpdates)
        const updatedUpdates = pendingComponentUpdatesCopy.filter((update) => {
            if (update.stamp === stamp) return false
            return true
        })
        setPendingComponentUpdates(updatedUpdates)
    }
    const handlePendingComponentUpdates = () => {
        if (!pendingComponentUpdates) return
        if (pendingComponentUpdates.length === 0) return
        if (isSubmittingUpdates) return
        setIsSubmittingUpdates(true)
        const currentPendingUpdates = Array.from(pendingComponentUpdates)
        for (let i = 0; i < currentPendingUpdates.length; i++) {
            const update = currentPendingUpdates[i];
            updateComponent(update.payload, update.component_id)
            removePendingComponentUpdate(update.stamp)
        }
        setIsSubmittingUpdates(false)
    }
    const reorderByDate = () => {
        setLastSwimlaneGenerationTime(new Date())
    }
    const createNewTask = (payload, closeModal) => {
        if (closeModal) setIsAddingTask(false)
        let formalPayload = payload
        formalPayload['workspace_id'] = component.workspace_id
        api.createWorkspaceComponent(formalPayload, '/components/work/task')
        .then((res) => {
            fetchFullAlignmentInformation()
            updateSwimlanes()
            reorderByDate()
        })
        .catch((err) => console.log(err))
    }
    const hideConfirmPopup = () => {
        setIsShowingConfirmMenu(false)
    }
    const archiveInitiative = () => {
        hideConfirmPopup()
        const payload = {
            status: 'archived'
        };
        const path = `/workspace/components/${componentData.component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const restoreInitiative = () => {
        hideConfirmPopup()
        const payload = {
            status: 'active'
        };
        const path = `/workspace/components/${componentData.component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const deleteInitiative = () => {
        hideConfirmPopup()
        const payload = {
            componentId: componentData.component._id
        }
        const workspaceId = componentData.component.workspace_id;

        api.deleteWorkspaceComponent(payload)
        .then((res) => {
            history.push(`/workspaces/${workspaceId}`);
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const askForArchiveConfirmation = () => {
        setConfirmMenuContent("Archive timeline?  It will be hidden in the sidebar and only shown in relevant locations.  You can restore it later.")
        setConfirmMenuAction(() => archiveInitiative)
        setIsShowingConfirmMenu(true)
    }
    const askForReactivateConfirmation = () => {
        setConfirmMenuContent("Reactivate timeline?")
        setConfirmMenuAction(() => restoreInitiative)
        setIsShowingConfirmMenu(true)
    }
    const askForDeleteConfirmation = () => {
        setConfirmMenuContent("Are you sure you want to permanently delete this timeline?")
        setConfirmMenuAction(() => deleteInitiative)
        setIsShowingConfirmMenu(true)
    }
    const resetScroll = () => {
        mainContainerRef.current.scrollLeft = 0;
    }
    const getActionList = () => {
        let actions = [];
        if (hasPermission(permissions, 'component_delete')) {
            actions = actions.concat([
                {
                    text: 'Delete',
                    action: askForDeleteConfirmation,
                    id: 'delete'
                }
            ])
        }
        if (component.status === 'active' && hasPermission(permissions, 'component_update')) {
            actions = [
                {
                    text: 'Archive',
                    action: askForArchiveConfirmation,
                    id: 'archive'
                }
            ].concat(actions)
        }
        if (component.status !== 'active' && hasPermission(permissions, 'component_update')) {
            actions = [
                {
                    text: 'Reactivate',
                    action: askForReactivateConfirmation,
                    id: 'reactivate'
                }
            ].concat(actions)
        }
        return actions
    }
    const addMarker = (cId) => {
        // Goal marker is always just a child bond
        setIsAddingNewMarker(false)
        let payload = {};
        payload['workspace_id'] = componentData.component.workspace_id;
        payload['child_id'] = cId;
        payload['parent_id'] = componentData.component['_id'];
        api.createBond(payload)
        .then((res) => {
            fetchComponent()
            fetchFullAlignmentInformation()
            updateSwimlanes()
            reorderByDate()
        });
    }
    const removeSwimlane = (childId) => {
        if (!hasPermission(permissions, 'component_update')) return
        popFromSwimlanes(childId)
        const payload = {
            workspaceId: component.workspace_id,
            child_id: childId,
            parent_id: component._id
        }
        api.deleteBond(payload)
        .then((res) => {
            fetchComponent()
            // fetchFullAlignmentInformation()
            // updateSwimlanes()
            // reorderByDate()
        })
        .catch((err) => {
            console.log(err);
        });
    }
    const getStates = () => {
        if (!component) return []
        if (!component.states) return []
        return component.states
    }
    const addNewTask = (parentComponent) => {
        if (!parentComponent._id) return
        setParentOfNewTask(parentComponent)
        setIsAddingTask(true)
    }
    const editTask = (payload, componentId) => {
        updateComponent(payload, componentId)
        fetchFullAlignmentInformation()
        updateSwimlanes()
        reorderByDate()
        // addPendingComponentUpdate(payload, componentId)
    }
    const updateComponentToEditComments = () => {
        try {
            let foundComments = component.aligned_comments.filter((comment) => {
                if (comment.target_id === componentToEdit._id) return true
                return false
            })
            setComponentToEditComments(foundComments)
        } catch (error) {
            setComponentToEditComments([])
        }
    }
    const selectTaskToEdit = (taskComponent, parentComponent) => {
        setComponentToEdit(taskComponent)
        setParentOfEditTask(parentComponent)
        setIsEditingTask(true)
    }
    const closeNewTaskModal = () => {
        setIsAddingTask(false)
    }
    useEffect(() => {
        rebuildCalendarMap()
        resetScroll()
    // eslint-disable-next-line
    }, [keyDate])
    useEffect(() => {
        regenerateDateRange()
    // eslint-disable-next-line
    }, [endDate])
    useEffect(() => {
        fetchFullAlignmentInformation()
        updateSwimlanes()
    // eslint-disable-next-line
    },[])
    useEffect(() => {
        updateSwimlanes()
    // eslint-disable-next-line
    }, [unsortedAlignments])
    useEffect(() => {
        if (isSubmittingUpdates) return
        handlePendingComponentUpdates()
    // eslint-disable-next-line
    }, [pendingComponentUpdates])
    useEffect(() => {
        reorderByDate()
    }, [showCompletedTasks])
    useEffect(() => {
        if (isAddingTask) setIsEditingTask(false)
        if (isEditingTask) setIsAddingTask(false)
    }, [isAddingTask, isEditingTask])
    useEffect(() => {
        updateComponentToEditComments()
    // eslint-disable-next-line 
    }, [componentToEdit])
    return (
        <div className="component-timeline-page-container">
            {isAddingNewMarker && <ComponentTimelineAddWorkstream types={["board", "list"]} component={component} setIsAddingNewMarker={setIsAddingNewMarker} potentialBondIds={componentData.potential_bonds.as_parent} createNewBond={addMarker}/>}
            {isShowingConfirmMenu && <ConfirmAction mainText={confirmMenuContent} onconfirm={confirmMenuAction} hideMe={hideConfirmPopup} />}
            {isAddingTask && parentOfNewTask && <ModalTimelineNewTask createNewTask={createNewTask} keyDate={keyDate} swimlanes={swimlanes} team={team} parent={parentOfNewTask} closeRequest={closeNewTaskModal} /> }
            {isEditingTask && parentOfEditTask && <ModalTimelineEditTask comments={componentToEditComments} permissions={permissions} states={getStates()} component={componentToEdit} componentId={componentToEdit._id} editTask={editTask} keyDate={keyDate} swimlanes={swimlanes} team={team} parent={parentOfEditTask} closeRequest={() => setIsEditingTask(false)} /> }
            <div className="component-page-row" style={{margin: "12px"}}>
                <EditableComponentTitle permissions={permissions} component={component} activeComponentId={activeComponentId} fetchComponent={fetchComponent}/>
                <ComponentTimelineDateSelector range={projectedDays} keyDate={keyDate} setKeyDate={setKeyDate} endDate={endDate}/>
                {hasPermission(permissions, 'component_update') &&
                <div className="workcomp-board-control-panel-symbol-button component-timeline-options-container">
                    {/* <IoSettingsOutline/> */}
                    <SimpleListActionMenu actionsList={getActionList()} symbol="gear"/>
                </div>
                }
            </div>
            <div className="component-timeline-container" ref={mainContainerRef}>
                <div className="component-timeline-row-workstream-date-headers-sticky">
                    <div className="component-timeline-row">
                        <div className="component-timeline-side-title" style={{backgroundColor: "white", zIndex: "7"}}>
                            <div className="component-timeline-side-title-heading component-timeline-side-title-timeline-groups">
                                Workstream
                                <div style={{display: "flex", alignItems: "center", gap: "16px"}}>
                                    {hasPermission(permissions, 'component_update') && <MdAdd onClick={() => setIsAddingNewMarker(true)}/>}
                                    <IoRepeat onClick={reorderByDate}/>
                                </div>
                                
                            </div>
                            
                        </div>

                        <div className="component-timeline-side-content" style={{backgroundColor: "white", zIndex: "6", borderBottom: "1px solid var(--border-color)"}}>
                            <ComponentTimelineHeader keyDate={keyDate} dateRange={dateRange} setKeyDate={changeKeyDate} getColumnStyle={getColumnStyle}/>
                        </div>
                    </div>
                </div>
                <div className="component-timeline-swimlanes">
                    {swimlanes.map((swimlane) => (
                        <ComponentTimelineSwimlane removeSwimlane={removeSwimlane} editTask={selectTaskToEdit} addNewTask={addNewTask} showCompletedTasks={showCompletedTasks} lastSwimlaneGenerationTime={lastSwimlaneGenerationTime} keyDate={keyDate} dateRange={dateRange} key={swimlane._id} updateComponentAndFetchTimeline={addPendingComponentUpdate} swimlane={swimlane} permissions={permissions}/>
                    ))}
                </div>

            </div>
            
        </div>
    )
}
