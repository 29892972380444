import React from 'react'
import { IoSearchOutline } from 'react-icons/io5';
import { BiPlus } from 'react-icons/bi'
import { RiDeleteBinLine } from 'react-icons/ri'
import { MdOutlineEdit } from 'react-icons/md'
import { useState, useEffect, useReducer } from 'react';
import { hasPermission, translatePermissionString, sortByProperty } from '../../common/Helpers';
import Api from '../../common/APIUtils';

import ModalNetworkEditRole from '../modals/ModalNetworkEditRole';
import ModalNetworkRoleCreate from '../modals/ModalNetworkRoleCreate';
import ModalNetworkRoleDeleteConfirm from '../modals/ModalNetworkRoleDeleteConfirm';

export default function NetworkRoles({ network, fetchNetwork, permissions }) {
    const api = new Api()
    const [networkUserData, networkUserDataDispatch] = useReducer((state, action) => {
        if (action.type === "init") return { loaded: true, ...action.data }
        // if (action.type === "init") return { ...state, loaded: true, ...action.data }
        return state
    }, {
        loaded: false,
        teams: [],
        roles: []
    })
    const [queryText, setQueryText] = useState(() => {return ""})
    const [showCreateRoleModal, setShowCreateRoleModal] = useState(() => {return false})
    const [showConfirmDeleteRoleModal, setShowConfirmDeleteRoleModal] = useState(() => {return false})
    const [showEditRoleModal, setShowEditRoleModal] = useState(() => {return false})
    const [selectedRoleId, setSelectedRoleId] = useState(() => { return false })
    const [sortedRoles, setSortedRoles] = useState(() => {return []})

    const hideModal = () => {
        fetchNetwork()
        setShowCreateRoleModal(false)
        setShowEditRoleModal(false)
        setSelectedRoleId(false)
        setShowConfirmDeleteRoleModal(false)
    }
    const fetchNetworkUserData = () => {
        if (!network._id) return
        api.fetchNetworkUserData(network._id)
        .then((res) => {
            networkUserDataDispatch({type: "init", data: res.data })
        })
        .catch((err) => { console.log(err) })
    }
    const selectRoleToEdit = (roleId) => {
        setSelectedRoleId(roleId)
        setShowEditRoleModal(true)
    }
    const updateSortedRoles = () => {
        if (!networkUserData.roles) return setSortedRoles([])
        try {
            let filteredRoles = networkUserData.roles.filter((role) => {
                if (role.is_custom) return true
                return false
            })
            let proposedSortedRoles = sortByProperty(filteredRoles, "name")
            if (!queryText || queryText.trim() === "") return setSortedRoles(proposedSortedRoles)
            proposedSortedRoles = proposedSortedRoles.filter((role) => {
                const lowercaseQuery = queryText.toLowerCase()
                const aggregateRoleName = role.name
                if (aggregateRoleName.toLowerCase().indexOf(lowercaseQuery.toLowerCase()) > -1) return true
                return false
            })
            setSortedRoles(proposedSortedRoles)
        } catch (error) {
            setSortedRoles([])
        }
    }
    const selectRoleToDelete = (roleId) => {
        setSelectedRoleId(roleId)
        setShowConfirmDeleteRoleModal(true)
    }
    const deleteCustomRole = () => {
        if (!selectedRoleId) return
        let payload = {
            role_id: selectedRoleId,
            network_id: network._id
        }
        api.deleteCustomRole(payload)
        .then((res) => {
            hideModal()
        })
        .catch((err) => {
            hideModal()
        })
    }
    useEffect(() => {
        if (!network) return 
        if (!network._id) return
        fetchNetworkUserData()
    // eslint-disable-next-line
    }, [network])
    useEffect(() => {
        updateSortedRoles()
    // eslint-disable-next-line
    }, [networkUserData, queryText])
    return (
        <div className="network-hub-page">
            {showEditRoleModal && selectedRoleId && <ModalNetworkEditRole hideModal={hideModal} roleId={selectedRoleId} network={network} networkUserData={networkUserData} /> }
            {showConfirmDeleteRoleModal && selectedRoleId && <ModalNetworkRoleDeleteConfirm hideModal={hideModal} roleId={selectedRoleId} network={network} deleteRole={deleteCustomRole} networkUserData={networkUserData} />}
            {showCreateRoleModal && <ModalNetworkRoleCreate hideModal={hideModal} network={network} /> }

            <div className="network-hub-page-header-row">
                <div className="modal-mgmt-select-dropdown-search" style={{maxWidth: "300px"}}>
                    <IoSearchOutline/>
                    <input type="text" placeholder="Search roles..." value={queryText} onChange={(e) => setQueryText(e.target.value)} />
                </div>
                <div className="util-row util-row-reverse util-align-stretch" style={{flexBasis: "50%", gap: "20px"}}>
                    {hasPermission(permissions, 'network_admin') &&
                    <div className="network-page-add-workspace" onClick={() => setShowCreateRoleModal(true)}>
                        <BiPlus/>
                        <span>Create role</span>
                    </div>
                    }
                </div>
            </div>

            <div className="network-users-container">

                {hasPermission(permissions, 'network_admin') &&
                <div className="network-users-view-outer">
                    <div className="network-users-team-outer">
                        <div className="network-page-teams-team-sections">
                            {sortedRoles.map((role) => (
                                <div className="network-page-roles-role-section" key={role._id}>
                                    <div className="network-page-roles-attributes">
                                        <div className="network-page-roles-attribute">
                                            <div className="network-page-roles-attribute-header">
                                                <h3>Role Name</h3>
                                                {hasPermission(permissions, "network_admin") &&
                                                <div style={{display: "flex", alignItems: "center", gap: "20px", justifyContent: "flex-end"}}>
                                                    <div className="network-users-col-action-edit" onClick={() => selectRoleToEdit(role._id)}><MdOutlineEdit/></div>
                                                    <div className="network-users-col-action-edit" onClick={() => selectRoleToDelete(role._id)}><RiDeleteBinLine/></div>
                                                </div>
                                                }
                                                
                                            </div>
                                            <p>{role.name}</p>
                                        </div>
                                        <div className="network-page-roles-attribute">
                                            <h3>Access Granted</h3>
                                            <div className="modal-manage-user-membership-role-permissions-box">
                                                {role.permissions.map((permission) => (
                                                    <span key={permission}>{translatePermissionString(permission)}</span>
                                                ))}
                                                {role.permissions.length === 0 && <div style={{fontSize: "14px"}}>No permissions assigned</div> }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {sortedRoles.length === 0 && !queryText && <div style={{fontSize: "16px", textAlign: "center"}}>Create custom roles for teams or users in your network</div> }
                            {sortedRoles.length === 0 && queryText && <div style={{fontSize: "16px", textAlign: "center"}}>No roles match your search term</div> }
                        </div>
                    </div>
                </div>
                }

            </div>

        </div>
    )
}
