import React from 'react'
import { FaPen, FaTrash } from 'react-icons/fa'
import { useState } from 'react';
import { hasPermission } from '../../../common/Helpers';

export default function ComponentCriteria({data, updateCriteriaStatus, permissions, deleteCriteria, updateCriteriaDescription}) {
    const [isEditingCriteria, setIsEditingCriteria] = useState(() => {
        return false;
    })
    const [proposedDescription, setProposedDescription] = useState(() => {
        return data.description;
    })
    const status2ClassMap = {
        active: "criteria-item-active",
        completed: "criteria-item-completed"
    }
    const getClassFromStatus = () => {
        if (status2ClassMap[data.status]) {
            return status2ClassMap[data.status]
        } else {
            return status2ClassMap['active']
        }
    }
    const getCriteriaItemCircle = () => {
        if (data.status === 'active') {
            return "criteria-item-circle"
        } else {
            return "criteria-item-circle criteria-item-circle-completed"
        }
    }
    const toggleCompletionStatus = () => {
        if (data.status === 'active') {
            updateCriteriaStatus(data._id, 'completed')
        } else {
            updateCriteriaStatus(data._id, 'active')
        }
    }
    const submitNewDescription = () => {
        setIsEditingCriteria(false);
        if (data.description !== proposedDescription) {
            updateCriteriaDescription(data._id, proposedDescription)
        }
    }
    const handleEditKeyPress = (e) => {
        if (e.key === 'Enter') {
            submitNewDescription();
        }
    }
    return (
        <div className="criteria-item-row">
            <div className="criteria-item">
                {isEditingCriteria ? (
                    <>
                        <div className={getCriteriaItemCircle()}></div>
                        <input onKeyPress={(e) => handleEditKeyPress(e)} className="criteria-item-editing" autoFocus={true} onBlur={() => submitNewDescription()} type="text" value={proposedDescription} onChange={(e) => setProposedDescription(e.target.value)} />
                    </>
                ) : (
                    <>
                        <div className={getCriteriaItemCircle()} onClick={() => toggleCompletionStatus()}></div>
                        <div className={getClassFromStatus()}>{data.description}</div>
                    </>
                )}

            </div>
            <div className="criteria-item-options-button">
                {(data.status === 'active') && (!isEditingCriteria) && (hasPermission(permissions, 'component_update')) && 
                    <div className="criteria-item-options-container">
                        <span className="criteria-item-options-option" onClick={() => setIsEditingCriteria(true)}><FaPen/></span>
                        <span className="criteria-item-options-option" onClick={() => deleteCriteria(data._id)}><FaTrash/></span>
                    </div>
                }

            </div>
        </div>
    )
}
