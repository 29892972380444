import React from 'react'
import { MdCancel } from 'react-icons/md'
import { useHistory } from 'react-router-dom';

import { getFormattedDisplayWithUnits, hasPermission } from '../../../common/Helpers';
import CProgressBar from '../CProgressBar';

export default function WorkspaceGoalMarker({component, permissions, parentId, removeMarker, bondId, beginGoalMarkerEdit}) {
    const history = useHistory();
    const isCompatibleMarker = () => {
        if (component.parent_data) {
            if (component.parent_data[parentId]) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }
    const goToComponentPage = () => {
        if (!component) return
        history.push(`/workspaces/${component.workspace_id}/c/${component._id}`)
    }
    const getCurrentValue = () => {
        if (isCompatibleMarker()) {
            if (component.name === 'kpi') {
                return getFormattedDisplayWithUnits(component.attributes.units, component.attributes.val_current)
            } else {
                return component.progress ? getFormattedDisplayWithUnits("%", Math.round(component.progress*100)) : "0%"
            }
        } else {
            return component.progress ? getFormattedDisplayWithUnits("%", Math.round(component.progress*100)) : "0%"
        }
        
    }
    const getTargetProgress = () => {
        if (isCompatibleMarker()) {
            if (component.name === 'kpi') {
                return getFormattedDisplayWithUnits(component.attributes.units, component.parent_data[parentId].target)
            } else {
                return getFormattedDisplayWithUnits("%", Math.round(component.parent_data[parentId].target*100))
            } 
        } else {
            return component.progress ? getFormattedDisplayWithUnits("%", Math.round(component.progress*100)) : "0%"
        }
        
    }
    const getPercentage = () => {
        if (isCompatibleMarker()) {
            if (component.parent_data) {
                return component.parent_data[parentId].progress*100
            } else {
                return 0
            } 
        } else {
            return Math.round(component.progress*100)
        }
        
    }
    const getInitialValue = () => {
        if (isCompatibleMarker()) {
            if (component.parent_data) {
                if (component.name === 'kpi') {
                    return getFormattedDisplayWithUnits(component.attributes.units, component.parent_data[parentId].initial)
                } else {
                    return getFormattedDisplayWithUnits("%", Math.round(component.parent_data[parentId].initial*100))
                } 
            } else {
                return "..."
            }
        } else {
            return "..."
        }
        
        
    }
    const allowCompatibleEdits = () => {
        if (isCompatibleMarker()) {
            beginGoalMarkerEdit(component)
        }        
    }
    const canRemove = () => {
        if (hasPermission(permissions, 'component_update')) return true
        return false
    }
    return (
            <div className="workcomp-alignment-marker-container-goal">
                <CProgressBar height={80} percentage={getPercentage(component)}/>
                <div className="workcomp-alignment-marker-information-container">
                    <div onClick={goToComponentPage}>
                        <div className="workcomp-alignment-marker-displayname">{component.display_name}</div>
                        <div className="workcomp-alignment-marker-type">{component.name}</div>
                    </div>

                    <div>                   
                        <div className="workcomp-page-row-centered" style={{gap: "8px"}} onClick={() => allowCompatibleEdits()}>
                            <span style={{fontSize: "14px"}}>{getCurrentValue()} / {getTargetProgress()}</span>
                            <span style={{fontSize: "16px"}}>&middot;</span>
                            <span style={{textTransform: "uppercase", fontSize: "12px"}}>{component.name}</span>
                        </div>

                        <div className="component-view-goal-marker-metric-information-data-row" onClick={() => allowCompatibleEdits()}>
                            <span>Initially {getInitialValue()}</span>
                        </div>
                    </div>
                </div>
                {canRemove && 
                <div className="workcomp-alignment-marker-actions">
                    <MdCancel onClick={() => removeMarker(bondId)}/>
                </div>
                }
            </div>


    )
}
