import React from 'react'
import { IoList, IoClose, IoEllipse, IoColorFilterSharp, IoTrophy, IoSearchOutline, IoCheckbox, IoChevronDownOutline, IoChevronUpOutline, IoEllipsisVertical, IoCheckmarkDone, IoWarning } from 'react-icons/io5'
import { BiPlus } from 'react-icons/bi'
import { useState, useEffect, useRef } from 'react'
import { toCapitalize, getUserNameFromId, getHandleFromId, hasPermission, getUserProfileSrcFromId } from '../../common/Helpers'
import { parseISO, format, isValid, isBefore, endOfYesterday, addDays, isAfter  } from 'date-fns'
import { useHistory } from 'react-router-dom'
import ProfPicCircle from '../profiles/ProfPicCircle'

export default function AssignmentTable({components, workspaces, sortBy, setSortBy, team, updateWorkspaceComponent, permissions, context}) {
    const history = useHistory()
    const [query, setQuery] = useState(() => { return "" })
    const [rawComponentCopy, setRawComponentCopy] = useState(() => { return [] }) // pulled away from passed state for performance
    const [orderedComponents, setOrderedComponents] = useState(() => { return [] })
    const [displayedComponents, setDisplayedComponents] = useState(() => { return [] })
    const [ownerFilters, setOwnerFilters] = useState(() => { return [] })
    const [componentFilters, setComponentFilters] = useState(() => { return ["task"] })
    const [statusFilters, setStatusFilters] = useState(() => { return ["active"] })
    const [workspaceOppositeFilter, setWorkspaceOppositeFilter] = useState(() => { return [] }) // items here are NOT shown
    const goToWorkspace = (workspaceId) => {
        if (!workspaceId) return
        history.push(`/workspaces/${workspaceId}`)
    }
    const goToComponent = (component) => {
        if (!component) return
        if (!component.workspace_id) return
        if (!component._id) return
        history.push(`/workspaces/${component.workspace_id}/c/${component._id}`)
    }
    const transformComponentDisplayName = (componentName) => {
        if (componentName === "initiative") return "Project"
        if (componentName === "kresult") return "Goal"
        return toCapitalize(componentName)
    }
    const goToPage = (endpoint) => {
        if (!endpoint) return
        history.push(endpoint)
    }
    const addWorkspaceFilter = (workspaceId) => {
        // Use this option to HIDE a workspace
        if (workspaceOppositeFilter.includes(workspaceId)) return
        let copyOfWorkspaceFilters = Array.from(workspaceOppositeFilter)
        copyOfWorkspaceFilters.push(workspaceId)
        setWorkspaceOppositeFilter(copyOfWorkspaceFilters)
    }
    const removeWorkspaceFilter = (workspaceId) => {
        // Use this option to SHOW a workspace
        if (!workspaceOppositeFilter.includes(workspaceId)) return
        let copyOfWorkspaceFilters = Array.from(workspaceOppositeFilter)
        const index = copyOfWorkspaceFilters.indexOf(workspaceId)
        if (index < 0) return
        copyOfWorkspaceFilters.splice(index, 1)
        setWorkspaceOppositeFilter(copyOfWorkspaceFilters)
    }
    const addComponentFilter = (componentName) => {
        if (componentFilters.includes(componentName)) return
        let copyOfComponentFilters = Array.from(componentFilters)
        copyOfComponentFilters.push(componentName)
        setComponentFilters(copyOfComponentFilters)
    }
    const addStatusFilter = (statusName) => {
        if (statusFilters.includes(statusName)) return
        let copyOfStatusFilters = Array.from(statusFilters)
        copyOfStatusFilters.push(statusName)
        setStatusFilters(copyOfStatusFilters)
    }
    const addOwnerFilter = (ownerId) => {
        if (ownerFilters.includes(ownerId)) return
        let copyOfOwnerFilters = Array.from(ownerFilters)
        copyOfOwnerFilters.push(ownerId)
        setOwnerFilters(copyOfOwnerFilters)
    }
    const removeComponentFilter = (componentName) => {
        if (!componentFilters.includes(componentName)) return
        let copyOfComponentFilters = Array.from(componentFilters)
        const index = copyOfComponentFilters.indexOf(componentName)
        if (index < 0) return
        copyOfComponentFilters.splice(index, 1)
        setComponentFilters(copyOfComponentFilters)
    }
    const removeStatusFilter = (statusName) => {
        if (!statusFilters.includes(statusName)) return
        let copyOfStatusFilters = Array.from(statusFilters)
        const index = copyOfStatusFilters.indexOf(statusName)
        if (index < 0) return
        copyOfStatusFilters.splice(index, 1)
        setStatusFilters(copyOfStatusFilters)
    }
    const removeOwnerFilter = (ownerId) => {
        if (!ownerFilters.includes(ownerId)) return
        let copyOfOwnerFilters = Array.from(ownerFilters)
        const index = copyOfOwnerFilters.indexOf(ownerId)
        if (index < 0) return
        copyOfOwnerFilters.splice(index, 1)
        setOwnerFilters(copyOfOwnerFilters)
    }
    const getStatusColor = (statusName) => {
        let styleColor = "rgb(53, 53, 53)"
        const statusColorMap = {
            active: "rgb(41, 115, 175)",
            archived: "#fc636b",
            completed: "#049921"
        }
        if (statusColorMap[statusName]) styleColor=statusColorMap[statusName]
        return styleColor
    }
    const getStatusTypeFilterContent = (statusName) => {
        const styleColor = getStatusColor(statusName)
        return (
            <div className="assignment-table-filter-pill-decoration">
                <IoEllipse style={{color: styleColor}} />
                <span>{toCapitalize(statusName)}</span>
            </div>
        )
    }
    const getStatusFilterOptions = () => {
        return {
            name: "Status",
            options: [
                { option_name: "active", option_content: <div className="assignment-table-filter-dropdown-option"><IoEllipse style={{color: getStatusColor("active")}} /><span>Active</span></div> },
                { option_name: "archived", option_content: <div className="assignment-table-filter-dropdown-option"><IoEllipse style={{color: getStatusColor("archived")}} /><span>Archived</span></div> },
                { option_name: "completed", option_content: <div className="assignment-table-filter-dropdown-option"><IoEllipse style={{color: getStatusColor("completed")}} /><span>Completed</span></div> }
            ]
        }
    }
    const getComponentFilterOptions = () => {
        return {
            name: "Component types",
            options: [
                { option_name: "task", option_content: <div className="assignment-table-filter-dropdown-option">{getComponentFilterSymbol("task")}<span>Task</span></div> },
                { option_name: "initiative", option_content: <div className="assignment-table-filter-dropdown-option">{getComponentFilterSymbol("initiative")}<span>Project</span></div> },
                { option_name: "kresult", option_content: <div className="assignment-table-filter-dropdown-option">{getComponentFilterSymbol("kresult")}<span>Goal</span></div> },
            ]
        }
    }
    const getOwnerFilterOptions = () => {
        try {
            if (!team) return false
            if (team.length === 0) return false
            let filterOptions = { name: "Owner" }
            let optionsArray = []
            for (let i = 0; i < team.length; i++) {
                const teammate = team[i];
                optionsArray.push( {
                    option_name: teammate._id,
                    option_content: <div className="util-row util-align-center" style={{gap: "12px"}}>
                        <ProfPicCircle height={24} src={getUserProfileSrcFromId(team, teammate._id)} userId={teammate._id} userName={getUserNameFromId(team, teammate._id)} />
                        {`${teammate.firstName} ${teammate.lastName}`}
                        </div>
                })
            }
            filterOptions["options"] = optionsArray
            return filterOptions
        } catch (error) {
            return false
        }

    }
    const getWorkspaceFilterOptions = () => {
        let options = []
        for (let i = 0; i < workspaces.length; i++) {
            options.push({
                option_name: workspaces[i]._id,
                option_content: <div className="assignment-table-filter-dropdown-option">{workspaces[i].name}</div>
            })
        }
        return {
            name: "Workspaces",
            options: options
        }
    }
    const getComponentFilterSymbol = (componentName) => {
        const colorStyles = {
            color: getComponentFilterColor(componentName)
        }
        const componentFilterSymbolMap = {
            task: <IoList style={colorStyles}/>,
            goal: <IoTrophy style={colorStyles}/>,
            initiative: <IoColorFilterSharp style={colorStyles}/>,
            kresult: <IoTrophy style={colorStyles}/>,
        }
        if (componentFilterSymbolMap[componentName]) return componentFilterSymbolMap[componentName]
        return <IoList style={colorStyles}/>
    }
    const getComponentFilterColor = (componentName) => {
        const componentFilterColorMap = {
            task: "#355070",
            goal: "#6D597A",
            initiative: "#B56576",
            kresult: "#E56B6F",
        }
        if (componentFilterColorMap[componentName]) return componentFilterColorMap[componentName]
        return "#EAAC8B"
    }
    const getComponentTypeFilterContent = (componentName) => {
        return (
            <div className="assignment-table-filter-pill-decoration">
                {getComponentFilterSymbol(componentName)}
                <span>{transformComponentDisplayName(componentName)}</span>
            </div>
        )
    }
    const updateComponentSorting = () => {
        let preSortedComponents = Array.from(rawComponentCopy)
        // apply sorting
        if (sortBy === "date") {
            // first gather only items that have due dates
            let componentsWithDueDates = preSortedComponents.filter((component) => {
                if (component.attributes.date_due && component.attributes.date_due !== "") return true
                return false
            })
            componentsWithDueDates = componentsWithDueDates.sort( (a, b) => { 
                return parseISO(a.attributes.date_due) - parseISO(b.attributes.date_due)
            })
            // then order items without due dates
            let componentsSansDueDates = preSortedComponents.filter((component) => {
                if (component.attributes.date_due && component.attributes.date_due !== "") return false
                return true
            })
            componentsSansDueDates = componentsSansDueDates.sort((a, b) => {
                return parseISO(a.updatedAt) - parseISO(b.updatedAt)
            })
            preSortedComponents = componentsWithDueDates.concat(componentsSansDueDates)
        }
        if (sortBy === "item") {
            preSortedComponents = preSortedComponents.sort((a,b) => {
                return a.display_name.localeCompare(b.display_name)
            })
        }
        if (sortBy === "type") {
            preSortedComponents = preSortedComponents.sort((a,b) => {
                return a.name.localeCompare(b.name)
            })
        }
        if (sortBy === "status") {
            preSortedComponents = preSortedComponents.sort((a,b) => {
                return a.status.localeCompare(b.status)
            })
        }
        if (sortBy === "workspace") {
            preSortedComponents = preSortedComponents.sort((a,b) => {
                return a.workspace.name.localeCompare(b.workspace.name)
            })
        }
        if (sortBy === "owner") {

            // first gather only items that have owners
            let componentsWithOwners = preSortedComponents.filter((component) => {
                if (component.owner_id && component.owner_id !== "") return true
                return false
            })
            componentsWithOwners = componentsWithOwners.sort( (a, b) => { 
                return getUserNameFromId(team, a.owner_id).localeCompare(getUserNameFromId(team, b.owner_id))
            })
            // then order items without owners
            let componentsSansOwners = preSortedComponents.filter((component) => {
                if (component.owner_id && component.owner_id !== "") return false
                return true
            })
            componentsSansOwners = componentsSansOwners.sort((a, b) => {
                return parseISO(a.updatedAt) - parseISO(b.updatedAt)
            })
            preSortedComponents = componentsWithOwners.concat(componentsSansOwners)
        }
        return setOrderedComponents(preSortedComponents)
        
    }
    const workspaceIdMatchesWorkspacesPassed = (workspaceId) => {
        let workspacesList = Array.from(workspaces)
        const filteredToMatch = workspacesList.find(a => a._id === workspaceId)
        if (!filteredToMatch) return false
        return true
    }
    const updateDisplayedComponents = () => {
        let preFilteredComponents = Array.from(orderedComponents)
        const FORBIDDEN_COMPONENTS = ["list", "kpi", "board", "kresult", "link"]
        preFilteredComponents = preFilteredComponents.filter((component) => {
            if (FORBIDDEN_COMPONENTS.includes(component.name)) return false
            // Filter out if parent is archived
            let parentStatus = "active"
            if (component.parent) {
                if (component.parent.status) parentStatus = component.parent.status
            }
            if (parentStatus === "archived") return false
            // Filter network workspaces for now
            if(!workspaceIdMatchesWorkspacesPassed(component.workspace_id)) return false
            // status filter
            if (!statusFilters.includes(component.status) && statusFilters.length > 0) return false
            // component type filter
            if (componentFilters.length > 0 && !componentFilters.includes(component.name)) return false
            // workspaces filter
            if (!workspaceOppositeFilter.includes(component.workspace_id) && workspaceOppositeFilter.length > 0) return false
            // owner filter
            if (!ownerFilters.includes(component.owner_id) && ownerFilters.length > 0) return false
            if (!query.trim() || query.trim() === "") return true
            // has query
            let detailsOrDescription = ""
            let searchableDescriptionString = ""
            if (component.attributes.details) detailsOrDescription = component.attributes.details
            if (component.attributes.description && !detailsOrDescription) detailsOrDescription = component.attributes.description
            // Legacy description
            if (typeof detailsOrDescription === "string") {
                searchableDescriptionString = detailsOrDescription
            }
            // lexical description
            if (detailsOrDescription.description_html) searchableDescriptionString = detailsOrDescription.description_html.replace(/<\/?[^>]+(>|$)/g, '')            

            const textToSearch = `${searchableDescriptionString}${component.display_name}${getUserNameFromId(team, component.owner_id)}`
            if (textToSearch.toLowerCase().indexOf(query.trim().toLowerCase()) !== -1) return true
            return false
        })
        setDisplayedComponents(preFilteredComponents)
    }
    useEffect(() => {
        updateComponentSorting()
    // eslint-disable-next-line
    }, [sortBy, rawComponentCopy])
    useEffect(() => {
        updateDisplayedComponents()
    // eslint-disable-next-line
    }, [statusFilters, workspaceOppositeFilter, componentFilters, orderedComponents, query, workspaces, ownerFilters])
    useEffect(() => {
        if (!components) return
        const copyOfComponents = Array.from(components)
        setRawComponentCopy(copyOfComponents)
    // eslint-disable-next-line
    }, [components])
    return (
        <div className="assignment-table">
            {(rawComponentCopy.length > 0 || displayedComponents.length > 0) &&
            <>
            <div className="assignment-table-filter-pills">
                {componentFilters.map((componentName) => (
                    <AssignmentTableFilterPill key={componentName} filterContent={getComponentTypeFilterContent(componentName)} removeFilter={() => removeComponentFilter(componentName)} />
                ))}
                {statusFilters.map((statusName) => (
                    <AssignmentTableFilterPill key={statusName} filterContent={getStatusTypeFilterContent(statusName)} removeFilter={() => removeStatusFilter(statusName)} />
                ))}
            </div>

            <div className="assignment-table-outer-table">
                <div className="assignment-table-control-panel">
                    <div className="assignment-table-controls-left">
                        <div className="assignment-table-controls-search">
                            <div className="assignment-table-controls-search-symbol"><IoSearchOutline/></div>
                            <input  placeholder="Search..." value={query} onChange={(e) => setQuery(e.target.value)}/>
                        </div>
                    </div>

                    <div className="assignment-table-controls-right">
                        <AssignmentTableFilterDropdownMenu config={getComponentFilterOptions()} activateFilter={addComponentFilter} deactivateFilter={removeComponentFilter} currentFilter={componentFilters} />
                        <AssignmentTableFilterDropdownMenu config={getStatusFilterOptions()} activateFilter={addStatusFilter} deactivateFilter={removeStatusFilter} currentFilter={statusFilters} />
                        <AssignmentTableFilterDropdownMenu config={getWorkspaceFilterOptions()} activateFilter={addWorkspaceFilter} deactivateFilter={removeWorkspaceFilter} currentFilter={workspaceOppositeFilter} isInverted={false}/>
                        {getOwnerFilterOptions() && <AssignmentTableFilterDropdownMenu config={getOwnerFilterOptions()} activateFilter={addOwnerFilter} deactivateFilter={removeOwnerFilter} currentFilter={ownerFilters} />}
                    </div>
                </div>

                <div className="assignment-table-header-and-row-container">
                    <div className="assignment-table-header-row">
                        <div className="assignment-table-col-selection-box"></div>
                        <div className="assignment-table-col-item"><span onClick={() => setSortBy("item")}>Item</span></div>
                        <div className="assignment-table-col-type"><span onClick={() => setSortBy("type")}>Type</span></div>
                        <div className="assignment-table-col-status"><span onClick={() => setSortBy("status")}>Status</span></div>
                        <div className="assignment-table-col-owner"><span onClick={() => setSortBy("owner")}>Owner</span></div>
                        <div className="assignment-table-col-workspace"><span onClick={() => setSortBy("workspace")}>Workspace</span></div>
                        <div className="assignment-table-col-date"><span onClick={() => setSortBy("date")}>Date</span></div>
                        <div className="assignment-table-col-options"></div>
                    </div>
                    {displayedComponents.map((component) => (
                        <AssignmentTableComponentRow workspaces={workspaces} context={context} permissions={permissions} goToPage={goToPage} updateWorkspaceComponent={updateWorkspaceComponent} key={component._id} component={component} transformComponentDisplayName={transformComponentDisplayName} team={team} getStatusColor={getStatusColor} goToWorkspace={goToWorkspace} goToComponent={goToComponent} />
                    ))}
                </div>
                
                
            </div>
            </>}
            {rawComponentCopy.length === 0 &&
            <div style={{display: "flex", justifyContent: "center", marginTop: "30vh"}}>
                <div style={{display: "flex", flexDirection: "column", gap: "20px", alignItems: "center"}}>
                    {context === "workspace" && <div>There's nothing in this workspace yet!</div>}
                    {!context && <div>There's nothing here.</div>}
                    {context === "workspace" &&
                        <div className="network-page-add-workspace" onClick={() => history.push(`/workspaces/${workspaces[0]._id}/catalog`)}>
                            <BiPlus/>
                            <span>Create new workstream</span>
                        </div>
                    }
                </div>
            </div>
            }

            {displayedComponents.length === 0 && !query &&
            <div style={{display: "flex", justifyContent: "center", marginTop: "30vh"}}>
                <div style={{display: "flex", flexDirection: "column", gap: "20px", alignItems: "center"}}>
                    <div>No assignments found.  Created tasks, goals, and projects will appear here.</div>
                </div>
            </div>
            }

            {displayedComponents.length === 0 && query && rawComponentCopy.length > 0 &&
            <div style={{display: "flex", justifyContent: "center", marginTop: "30vh"}}>
                <div style={{display: "flex", flexDirection: "column", gap: "20px", alignItems: "center"}}>
                    <div>Nothing found for: <span style={{fontWeight: 600}}>{query}</span></div>
                    <div style={{color: "grey", textDecoration: "underline", cursor: "pointer"}} onClick={() => setQuery("")}>Clear search</div>
                </div>
            </div>
            }
        </div>
    )
}

function AssignmentTableFilterPill({filterContent, removeFilter}) {
    return (
        <div className="assignment-table-filter-pill">
            {filterContent}
            <IoClose onClick={removeFilter}/>
        </div>
    )
}

function AssignmentTableFilterDropdownMenu({config, activateFilter, deactivateFilter, currentFilter, isInverted}) {
    const [isShowingDropdown, setIsShowingDropdown] = useState(() => { return false })
    const filterMenuDropdownRef = useRef(false);
    const handleClick = (itemName) => {
        if (currentFilter.includes(itemName)) return deactivateFilter(itemName)
        return activateFilter(itemName)
    }
    const toggleDropdownDisplay = () => {
        if (isShowingDropdown) return setIsShowingDropdown(false)
        setIsShowingDropdown(true)
    }
    const itemIsFiltered = (itemName) => {
        if (isInverted) return !currentFilter.includes(itemName)
        return currentFilter.includes(itemName)
    }
    const getAdditionalStyles = () => {
        if (!isShowingDropdown && currentFilter.length === 0) return {}
        if (isShowingDropdown) return { backgroundColor: "rgb(243, 243, 243)" }
        if (currentFilter.length > 0) return { backgroundColor: "rgba(1, 120, 255, 0.07)" }
        return {
            backgroundColor: "rgb(243, 243, 243)"
        }
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterMenuDropdownRef.current && !filterMenuDropdownRef.current.contains(event.target)) {
                setIsShowingDropdown(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [filterMenuDropdownRef])
    return (
        <div className="assignment-table-controls-filter-container" style={getAdditionalStyles()} ref={filterMenuDropdownRef}>
            <div className="assignment-table-controls-filter-button" onClick={toggleDropdownDisplay}>
                {config.name}
                {isShowingDropdown && <IoChevronUpOutline/>}
                {!isShowingDropdown && <IoChevronDownOutline/>}
            </div>
            {isShowingDropdown &&
            <div className="assignment-table-controls-filter-dropdown-outer">
                {config.options.map((filterItem) => (
                    <div key={filterItem.option_name} className="assignment-table-controls-filter-dropdown-inner" onClick={() => handleClick(filterItem.option_name)}>
                        {itemIsFiltered(filterItem.option_name) && <IoCheckbox style={{color: "rgb(31, 116, 196)"}} /> }
                        {!itemIsFiltered(filterItem.option_name) && <IoCheckbox/>}
                        {filterItem.option_content}
                    </div>
                ))}
            </div>
            }
        </div>
    )
}

function AssignmentTableComponentRow({component, context, transformComponentDisplayName, workspaces, team, getStatusColor, goToWorkspace, goToPage, goToComponent, updateWorkspaceComponent, permissions}) {
    const [isShowingActionMenu, setIsShowingActionMenu] = useState(() => { return false })
    const [localComponent, setLocalComponent] = useState(() => { return component })
    const filterMenuDropdownRef = useRef(false);
    const getComponentDate = () => {
        if (!component) return
        if (!component.attributes) return
        if (!component.attributes.date_due) return ""
        if (!isValid(parseISO(component.attributes.date_due))) return ""
        return format(parseISO(component.attributes.date_due), 'PP')
    }
    const getStatusStyle = () => {
        return { color: getStatusColor(localComponent.status)}
    }
    const getOwnerName = () => {
        if (component.status === "completed" && component.completor_id) return getUserNameFromId(team, component.completor_id)
        return getUserNameFromId(team, component.owner_id)
    }
    const getOwnerHandle = () => {
        if (component.status === "completed" && component.completor_id) return getHandleFromId(team, component.completor_id)
        return getHandleFromId(team, component.owner_id)
    }
    const updateComponentStatus = (statusName) => {
        let attributes = {}
        if (statusName === "completed") attributes = { active: false }
        if (statusName === "active") attributes = { active: true }
        updateWorkspaceComponent({status: statusName, attributes: attributes}, component._id) // empty attributes trigger logic to move board tasks into the right column automatically
        let updatedComponent = localComponent
        updatedComponent.status = statusName
        setLocalComponent(updatedComponent)
        setIsShowingActionMenu(false)
    }
    const getDueDateAdditionalStyles = () => {
        if (component.status === "completed") return {
            textDecoration: "line-through",
            color: "grey"
        }
        return {}
    }
    const toggleMenu = () => {
        if (isShowingActionMenu) return setIsShowingActionMenu(false)
        return setIsShowingActionMenu(true)
    }
    const getComponentDateSymbol = () => {
        if (!component) return
        if (!component.attributes) return
        if (!component.attributes.date_due) return
        if (component.status === "completed") return <IoCheckmarkDone style={{color: "#049921"}} />
        if (!isValid(parseISO(component.attributes.date_due))) return
        const dueDate = parseISO(component.attributes.date_due)
        if (isBefore(dueDate, endOfYesterday())) return <IoWarning style={{color: "rgb(168, 36, 36)"}} />
        if (!isAfter(dueDate, addDays(new Date(), 7))) return <IoEllipse style={{color: "rgb(147, 90, 170)", fontSize: "12px"}} />
        return
    }
    const getComponentDisplayName = () => {
        try {
            const parent = component.parent
            if (component.name === "task" && parent) return (
                <span>
                    <p onClick={() => goToComponent(component)}>{component.display_name}</p>
                    <div onClick={() => goToComponent(component.parent)}>{component.parent.display_name} &middot; {transformComponentDisplayName(component.parent.name)}</div>
                </span>
            )
            if (component.name === "initiative" && component.attributes.date_start && component.attributes.date_end) return (
                <span>
                    <p onClick={() => goToComponent(component)}>{component.display_name}</p>
                    <div onClick={() => goToComponent(component)}>{format(parseISO(component.attributes.date_start), 'PP')} - {format(parseISO(component.attributes.date_end), 'PP')}</div>
                </span>
            )
            return <span><p onClick={() => goToComponent(component)}>{component.display_name}</p></span>
        } catch (error) {
            return <span><p onClick={() => goToComponent(component)}>{component.display_name}</p></span>
        }
    }
    const getOwnerInformation = () => {
        if (!component) return
        if (!component.owner_id || component.owner_id === "") return
        return (
            <>
                <p>{getOwnerName()}</p>
                <span>@{getOwnerHandle()}</span>
            </>
        )
    }
    const canShowOptionsMenu = () => {
        try {
            if (!context) return true
            if (context === "workspace" && hasPermission(permissions, 'component_update')) return true
            return false
        } catch (error) {
            return true
        }

    }
    const getWorkspace = () => {
        try {
            if (component.workspace.name) return component.workspace
            if (context === "workspace") return workspaces[0]
            return ""
        } catch (error) {
            return ""
        }
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterMenuDropdownRef.current && !filterMenuDropdownRef.current.contains(event.target)) {
                setIsShowingActionMenu(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [filterMenuDropdownRef])
    return (
        <div className="assignment-table-component-row">
            <div className="assignment-table-col-selection-box"></div>
            <div className="assignment-table-col-item">{getComponentDisplayName()}</div>
            <div className="assignment-table-col-type">{transformComponentDisplayName(component.name)}</div>
            <div className="assignment-table-col-status" style={getStatusStyle()}> <span>{localComponent.status}</span></div>
            <div className="assignment-table-col-owner"> {getOwnerInformation()}</div>
            <div className="assignment-table-col-workspace"><span onClick={() => goToWorkspace(component.workspace_id)}>{getWorkspace().name}</span></div>
            <div className="assignment-table-col-date" style={getDueDateAdditionalStyles()}>{getComponentDateSymbol()}{getComponentDate()}</div>
            <div className="assignment-table-col-options" ref={filterMenuDropdownRef}>
                {canShowOptionsMenu() && <IoEllipsisVertical onClick={toggleMenu}/>}
                {isShowingActionMenu &&
                <div className="assignment-table-col-options-menu">
                    <span onClick={() => goToPage(`/workspaces/${component.workspace_id}/c/${component._id}`)}>Go To {transformComponentDisplayName(component.name)}</span>
                    {/* <span>Edit properties</span> */}
                    {localComponent.status === "active" && <span onClick={() => updateComponentStatus("completed")}>Mark {transformComponentDisplayName(component.name)} complete</span>}
                    {localComponent.status === "completed" && <span onClick={() => updateComponentStatus("active")}>Reactivate {transformComponentDisplayName(component.name)}</span>}
                    {/* <span>Go to parent</span> */}
                </div>
                }
            </div>
        </div>
    )
}