import React from 'react'
import { useState, useEffect } from 'react'
import { getUserProfileSrcFromId, getUserNameFromId, getUserDataFromId, hasPermission, sortByProperty } from '../../common/Helpers'
import Api from '../../common/APIUtils'
import { useHistory } from 'react-router-dom';

import ProfPicCircle from '../profiles/ProfPicCircle'
import { Calendar } from 'react-date-range';
import { addDays, format, isValid, parseISO } from 'date-fns';

import ModalSimpleHeader from './simple/ModalSimpleHeader'
import ModalSimpleSingleButton from './simple/ModalSimpleSingleButton'
import ModalSimpleNavigationBar from './simple/ModalSimpleNavigationBar'
import LoadingSymbolInline from '../gadgets/LoadingSymbolInline'
import ToggleSwitch from '../gadgets/ToggleSwitch'

import { IoChevronDown, IoChevronUp, IoLink, IoMap, IoTrashSharp } from "react-icons/io5";
import { BsArrowRight, BsCalendarEvent, BsFillCalendar3EventFill } from 'react-icons/bs';

export default function ModalInitiativeSettings({ component, hideModal, permissions, team, fetchComponent }) {
    const api = new Api()
    const history = useHistory()
    const [nav, setNav] = useState(() => { return "general" })
    const [proposedTemplateName, setProposedTemplateName] = useState(() => { return "" })
    const [proposedOwnerId, setProposedOwnerId] = useState(() => { return component.owner_id })
    const [isChangingOwners, setIsChangingOwners] = useState(() => { return false })
    const [isPublic, setIsPublic] = useState(() => { return component.is_public })
    const [initiativeId, setInitiativeId] = useState(() => { return "" })
    const [teammateQuery, setTeammateQuery] = useState(() => { return "" })
    const [filteredTeam, setFilteredTeam] = useState(() => { return [] })
    const [attributesLoaded, setAttributesLoaded] = useState(() => { return false })
    const [timelineInitialized, setTimelineInitialized] = useState(() => { return false })
    const [proposedOwnerInformation, setProposedOwnerInformation] = useState(() => { return false })
    const [isShowingDateStartPicker, setIsShowingDateStartPicker] = useState(() => { return false })
    const [copyLinkText, setCopyLinkText] = useState(() => { return "Copy Link" })
    const [isShowingDateEndPicker, setIsShowingDateEndPicker] = useState(() => { return false })
    const [dateStart, setDateStart] = useState(() => { return false })
    const [dateEnd, setDateEnd] = useState(() => { return false })
    const navStates = [
        {name: "general", display: "General"},
        {name: "timeline", display: "Timeline"},
    ]
    const saveAndClose = () => {
        // for the attributes that don't save automatically, save here
        let payload = {
            attributes: {
                initiative_id: initiativeId
            }
        }
        updateInitiative(payload)
        hideModal()
    }
    const updateInitiative = (payload) => {
        if (!hasPermission(permissions, 'component_update')) return
        if (!component) return
        const path = `/workspace/components/${component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const deleteInitiative = () => {
        hideModal()
        const payload = {
            componentId: component._id
        }
        const workspaceId = component.workspace_id;

        api.deleteWorkspaceComponent(payload)
        .then((res) => {
            history.push(`/workspaces/${workspaceId}`);
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const updateUserData = () => {
        try {
            const userData = getUserDataFromId(team, proposedOwnerId)
            setProposedOwnerInformation(userData)
        } catch (error) {
            return
        }
    }
    const initializeTimeline = () => {
        try {
            if (!component) return
            if (timelineInitialized) return
            setDateStart(parseISO(component.attributes.date_start))
            setDateEnd(parseISO(component.attributes.date_end))
            setTimelineInitialized(true)
        } catch (error) {
            return
        }
    }
    const archiveInitiative = () => {
        try {
            hideModal()
            updateInitiative({status: "archived"})
        } catch (error) {
            return
        }
    }
    const initializeAttributes = () => {
        // Init the initiative_id if not already set
        try {
            if (!component) return
            if (attributesLoaded) return
            if (!component.attributes) return
            if (component.attributes.initiative_id) setInitiativeId(component.attributes.initiative_id)
            if (component.is_public) {
                setIsPublic(true)
            } else {
                setIsPublic(false)
            }
            setAttributesLoaded(true)
        } catch (error) {
            return
        }
    }
    const copyPublicLinkToClipboard = () => {
        try {
            navigator.clipboard.writeText(`https://tetheros.com/public/c/${component._id}`)
            setCopyLinkText("Copied!")
        } catch (error) {
            return
        }
    }
    const updateInitiativeOwner = () => {
        if (proposedOwnerId !== component.owner_id) {
            updateInitiative({
                owner_id: proposedOwnerId
            })
        }
    }
    const updateInitiativeTimeline = (dateType, value) => {
        if (!component) return
        if (!component.attributes) return
        let property = "date_end"
        if (dateType === "date_start") property = "date_start"
        if (!isValid(value)) return
        const formattedDateValue = format(value, 'yyyy-MM-dd')
        if (property === "date_end" && formattedDateValue === component.attributes.date_end) return
        if (property === "date_start" && formattedDateValue === component.attributes.date_start) return
        let attributes = {}
        attributes[property] = formattedDateValue
        let payload = { attributes: attributes }
        updateInitiative(payload)
    }
    const getFancyDateString = (dateString) => {
        try {
            return format(dateString, 'MMM do, yyyy')
        } catch (error) {
            return "loading..."
        }
        
    }
    const updateStartDate = (newStartDate) => {
        setDateStart(newStartDate)
        setIsShowingDateStartPicker(false)
    }
    const updateEndDate = (newEndDate) => {
        setDateEnd(newEndDate)
        setIsShowingDateEndPicker(false)
    }
    const showStartDatePicker = () => {
        setIsShowingDateEndPicker(false)
        setIsShowingDateStartPicker(true)
    }
    const showEndDatePicker = () => {
        setIsShowingDateStartPicker(false)
        setIsShowingDateEndPicker(true)
    }
    const updateFilteredTeam = () => {
        try {
            const copyOfTeam = Array.from(team)
            let proposedTeam = copyOfTeam.filter((teammate) => {
                if (teammate._id === proposedOwnerId) return false
                if (teammateQuery.trim().length < 2) return true
                const nameQueryBase = `${teammate.firstName}${teammate.lastName}@${teammate.handle}`.toLowerCase()
                if (nameQueryBase.toLowerCase().indexOf(teammateQuery.trim().toLowerCase()) !== -1) return true
                return false
            })
            const ordered = sortByProperty(proposedTeam, "firstName")
            setFilteredTeam(ordered)
        } catch (error) {
            return setFilteredTeam(team)
        }
    }
    const updateOwner = (teammate) => {
        setProposedOwnerId(teammate._id)
        setIsChangingOwners(false)
    }
    const toggleProjectPublicness = () => {
        if (!attributesLoaded) return
        const payload = {
            is_public: isPublic
        }
        const path = `/workspace/components/${component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const templatizeProject = () => {
        try {
            if (!proposedTemplateName) return
            if (proposedTemplateName.trim() === "") return
            hideModal()
            const payload = {
                template_name: proposedTemplateName,
                base_component_id: component._id,
                scope: 'workspace',
                component_type: 'initiative',
                workspace_id: component.workspace_id
            };
            api.createTemplateFromComponent(payload)
            .then((res) => {
                fetchComponent();
            })
            .catch((err) => {
                return
            })
        } catch (error) {
            return setNav("general")
        }

    }
    useEffect(() => {
        updateUserData()
        initializeTimeline()
        initializeAttributes()
    // eslint-disable-next-line
    }, [component])
    useEffect(() => {
        updateFilteredTeam()
    // eslint-disable-next-line
    }, [team, proposedOwnerId, teammateQuery])
    useEffect(() => {
        updateInitiativeOwner()
    // eslint-disable-next-line
    }, [proposedOwnerId])
    useEffect(() => {
        updateInitiativeTimeline("date_end", dateEnd)
    // eslint-disable-next-line
    }, [dateEnd])
    useEffect(() => {
        updateInitiativeTimeline("date_start", dateStart)
    // eslint-disable-next-line
    }, [dateStart])
    useEffect(() => {
        if (!attributesLoaded) return
        toggleProjectPublicness()
    // eslint-disable-next-line
    }, [isPublic])
    return (
        <div className="modal-mgmt-background-overlay" style={{zIndex: 200}}>
            <div className="modal-mgmt-container modal-mgmt-container-initiative-settings">
                <div className="modal-initiative-settings-header">
                    <ModalSimpleHeader hideModal={hideModal} title="Project Settings" />
                    <ModalSimpleNavigationBar view={nav} setView={setNav} allViews={navStates} />
                </div>

                <div className="modal-initiative-settings-scrollable-container app-thin-scrollbar">
                    {nav === "general" &&
                    <div>

                        {/* PROJECT OWNER */}
                        <div className="modal-workstream-settings-setting-single">
                            <div className="modal-workstream-settings-setting-names">
                                <div className="modal-workstream-settings-setting-name">Project Lead</div>
                                <div className="modal-workstream-settings-setting-name-description">This person is the point of contact for the project. Leave blank if you do not wish to have a project owner.</div>
                            </div>
                            <div className="modal-workstream-settings-setting-value">
                                <div className="modal-initiative-settings-project-owner-area">
                                    <div className="modal-initiative-settings-project-owner-display">
                                        {proposedOwnerId && <ProfPicCircle height={120} src={getUserProfileSrcFromId(team, proposedOwnerId)} userId={proposedOwnerId} userName={getUserNameFromId(team, proposedOwnerId)} disableClick={true}/> }
                                        <div className="modal-initiative-settings-project-owner-menu">
                                            <div className="modal-initiative-settings-project-owner-name" onClick={() => setIsChangingOwners(!isChangingOwners)}>
                                                {proposedOwnerId && <span>{proposedOwnerInformation.firstName} {proposedOwnerInformation.lastName}</span>}
                                                {!proposedOwnerId && <span style={{fontWeight: 500, fontSize: "14px"}}>Select a project lead</span>}
                                                {!isChangingOwners && <IoChevronDown/>}
                                                {isChangingOwners && <IoChevronUp/>}
                                            </div>

                                            {proposedOwnerId &&
                                            <div className="util-row util-row-reverse">
                                                <span style={{fontSize: "13px", textDecoration: "underline", marginTop: "8px", cursor: "pointer"}} onClick={() => setProposedOwnerId("")}>Remove Owner</span>
                                            </div>
                                            }
                                            
                                            {isChangingOwners && team && hasPermission(permissions, "component_update") && 
                                            <div className="modal-initiative-settings-owner-change-menu app-thin-scrollbar">
                                                <input type="text" placeholder="Search" autoFocus={true} value={teammateQuery} onChange={(e) => setTeammateQuery(e.target.value)} />
                                                {filteredTeam.map((teammate) => (
                                                    <span key={teammate._id} onClick={() => updateOwner(teammate)}>{teammate.firstName} {teammate.lastName}</span>
                                                ))}
                                            </div>
                                            }

                                        </div>
                                    </div>
                                    
                                </div>
                                
                            </div>
                        </div>

                        {/* PROJECT ID */}
                        <div className="modal-workstream-settings-setting-single modal-workstream-settings-setting-single-vertical">
                            <div className="modal-workstream-settings-setting-names">
                                <div className="modal-workstream-settings-setting-name">Project ID</div>
                                <div className="modal-workstream-settings-setting-name-description">Assign a project number. Leave blank if you don't have one.</div>
                            </div>
                            <div className="modal-workstream-settings-setting-value">
                                <div className="modal-workstream-settings-text-input-container">
                                    {attributesLoaded && <input type="text" value={initiativeId} onChange={(e) => setInitiativeId(e.target.value)} placeholder="Provide a project number or internal reference ID" />}
                                    {!attributesLoaded && <div><LoadingSymbolInline /></div>}
                                </div>
                            </div>
                        </div>

                        {/* PUBLIC PROJECT */}
                        <div className="modal-workstream-settings-setting-single">
                            <div className="modal-workstream-settings-setting-names">
                                <div className="modal-workstream-settings-setting-name">Public Project</div>
                                <div className="modal-workstream-settings-setting-name-description">Let anyone with the link see a non-interactive version of this project.</div>
                                {isPublic && <div className="modal-workstream-settings-setting-name-button" onClick={copyPublicLinkToClipboard}><IoLink/>{copyLinkText}</div> }
                            </div>
                            <div className="modal-workstream-settings-setting-value">
                                <div style={{display: "flex", flexDirection: "row-reverse"}}><ToggleSwitch isChecked={isPublic} setIsChecked={setIsPublic} /></div>
                            </div>
                        </div>

                        {/* TEMPLATE */}
                        <div className="modal-workstream-settings-setting-single">
                            <div className="modal-workstream-settings-setting-names">
                                <div className="modal-workstream-settings-setting-name">Create Project Template</div>
                                <div className="modal-workstream-settings-setting-name-description">A template lets you recreate all workstreams in a future project.</div>
                            </div>
                            <div className="modal-workstream-settings-setting-value" style={{display: "flex", flexDirection: "row-reverse"}}>
                                <div className="modal-workstream-settings-setting-name-button modal-workstream-settings-setting-name-button-blue" style={{justifySelf: "flex-end", alignSelf: "flex-end"}} onClick={() => setNav("template")}><IoMap/>Create Template</div>
                            </div>
                        </div>


                    </div>
                    }

                    {nav === "timeline" &&
                    <div>
                        {/* PROJECT TIMELINE */}
                        <div className="modal-workstream-settings-setting-single modal-workstream-settings-setting-single-vertical">
                            <div className="modal-workstream-settings-setting-names">
                                <div className="modal-workstream-settings-setting-name">Project Timeline</div>
                                <div className="modal-workstream-settings-setting-name-description">Declare when this project starts and ends. Serves as an anchor for analytics, goals, and context.</div>
                            </div>
                            <div className="modal-workstream-settings-setting-value">
                                {timelineInitialized &&
                                <div className="modal-workstream-settings-text-input-container">
                                
                                    <div className="form-network-create-form-section-row" style={{gap: "1em", boxSizing: "border-box"}}>
                                        {/* Start Date */}
                                        <div className="form-create-date-selection-container" onClick={showStartDatePicker}>
                                            <div className="form-create-date-selection-clickable-display">
                                                <BsCalendarEvent/>
                                                <div>
                                                    <div className="form-network-create-form-section-label">Start Date</div>
                                                    <div className="form-create-date-description">{getFancyDateString(dateStart)}</div>
                                                </div>
                                            </div>

                                            {isShowingDateStartPicker && (
                                                <div className="form-create-date-calendar-picker">
                                                    <Calendar date={dateStart} onChange={updateStartDate} maxDate={addDays(dateEnd, -1)} />
                                                </div>
                                            )}
                                        </div>

                                        <BsArrowRight/>

                                        {/* End Date */}
                                        <div className="form-create-date-selection-container" onClick={showEndDatePicker}>
                                                <div className="form-create-date-selection-clickable-display" >
                                                    <BsFillCalendar3EventFill/>
                                                    <div>
                                                        <div className="form-network-create-form-section-label">End Date</div>
                                                        <div className="form-create-date-description">{getFancyDateString(dateEnd)}</div>
                                                    </div>
                                                </div>

                                                {isShowingDateEndPicker && (
                                                    <div className="form-create-date-calendar-picker">
                                                        <Calendar date={dateEnd} onChange={updateEndDate} minDate={addDays(dateStart, 1)}  />
                                                    </div>
                                                )}
                                        </div>

                                    </div>
                                </div>
                                }
                                {!timelineInitialized &&
                                <div><LoadingSymbolInline /></div>
                                }
                            </div>
                        </div>
                    </div>
                    }

                    {nav === "delete" &&
                        <div className="initiative-settings-prompt-container">
                            <div className="initiative-settings-delete-title">Are you sure you want to delete this project?</div>
                            <div className="initiative-settings-delete-subtitle"> <span style={{fontWeight: 700, color: "rgb(181, 40, 40)", textDecoration: "underline"}}>This action is permanent.</span><br/><br/>All workstreams and goals associated with this project will also be deleted. <br/><br/>Resources will remain in the workspace and will not be deleted.</div>
                            <div className="util-row util-align-center util-align-stretch" style={{gap: "14px"}}>
                                <div className="initiative-settings-delete-button" onClick={deleteInitiative}>Permanently delete</div>
                                {component.status !== "archived" &&<div className="initiative-settings-delete-button initiative-settings-delete-button-archive" onClick={archiveInitiative}>Archive instead</div>}
                            </div>
                            <div className="initiative-settings-back-button" onClick={() => setNav("general")}>Go back</div>
                        </div>
                    }

                    {nav === "template" &&
                    <div>
                        <div className="modal-workstream-settings-setting-single modal-workstream-settings-setting-single-vertical">
                            <div className="modal-workstream-settings-setting-names">
                                <div className="modal-workstream-settings-setting-name">Template Name</div>
                                <div className="modal-workstream-settings-setting-name-description">What would you like to call this template?</div>
                            </div>
                            <div className="modal-workstream-settings-setting-value">
                                <div className="modal-workstream-settings-text-input-container">
                                    <input autoFocus={true} type="text" value={proposedTemplateName} onChange={(e) => setProposedTemplateName(e.target.value)} placeholder="Provide an awesome project template name" />
                                </div>
                            </div>
                        </div>
                        <div class="util-row util-align-center util-justify-center util-col" style={{marginTop: "36px", gap: "20px"}}>
                            <div className={`initiative-settings-delete-button initiative-settings-delete-button-create ${proposedTemplateName.trim() === "" && "initiative-settings-delete-button-create-invalid"}`} onClick={templatizeProject}>Add template</div>
                            <div className="initiative-settings-back-button" onClick={() => setNav("general")}>Go back</div>
                        </div>
                        
                    </div>
                    }
                </div>

                <div className="modal-initiative-settings-footer">
                    <div className="util-row util-space-between util-align-end">
                        {nav !== "delete" && nav !== "template" && hasPermission(permissions, "component_delete") && <div className="initiative-setting-delete-text" onClick={() => setNav("delete")}><IoTrashSharp/>Delete Project</div>}
                        {/* {nav === "delete" && <span></span> } */}
                        {!["delete", "template"].includes(nav) && <ModalSimpleSingleButton isValid={true} buttonText={"Done"} onclick={saveAndClose} />}
                    </div>
                </div>

            </div>
        </div>
    )
}
