import React from 'react'
import { BsThreeDots } from 'react-icons/bs'
import { MdOutlineContentCopy } from 'react-icons/md'
import { getUserNameFromId, hasPermission, isValidHttpUrl } from '../../../common/Helpers'
import { useState, useEffect, useRef } from 'react';
import Api from '../../../common/APIUtils';
import ResourceSymbol from '../../gadgets/ResourceSymbol';

import { getWorkspaceFileUrl } from '../../../common/Api';

export default function WorkspaceCompInitiativeAlignmentResource({team, permissions, resource, fetchComponent, removeMarker, altRemovalText}) {
    const [isShowingManagePropertiesModal, setIsShowingManagePropertiesModal] = useState(() => { return false })
    const [isShowingManageMenu, setIsShowingManageMenu] = useState(() => { return false })
    const [isUpdatingDestination, setIsUpdatingDestination] = useState(() => { return false })
    const [isDownloading, setIsDownloading] = useState(() => { return false })
    const [resourceUrl, setResourceUrl] = useState(() => { return false })
    const [displayDestination, setDisplayDestination] = useState(() => { return false })
    const [destinationEditModalCopyButtonText, setDestinationEditModalCopyButtonText] = useState(() => { return "Copy link" })
    const [propertiesMenuCopyButtonText, setPropertiesMenuCopyButtonText] = useState(() => { return "Copy link" })
    const destinationUpdateRef = useRef(null)
    const propertiesMenuRef = useRef(null)
    const api = new Api()
    const downloadFile = () => {
        setIsShowingManageMenu(false)
        if (resource.name !== "file") return
        if (isDownloading) return
        if (resourceUrl) return openFile(resourceUrl)
        setIsDownloading(true)
        getWorkspaceFileUrl(resource.workspace_id, resource._id)
        .then((res) => {
            if (res.data) {
                if (res.data.url) {
                    setResourceUrl(res.data.url)
                    openFile(res.data.url)
                }
            }
            setIsDownloading(false)
        })
        .catch((err) => { return setIsDownloading(false) })
    }
    const getResourceDestination = () => {
        try {
            if (resource.name === "link") return resource.attributes.destination
            return false
        } catch (error) {
            return false
        }
    }
    const openFile = (url) => {
        // Create a hidden iframe
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        try {
            // Set the iframe's source to the file URL
            iframe.src = url;
            // Remove the iframe after a short delay
            setTimeout(() => {
            document.body.removeChild(iframe);
            }, 2000); // Adjust this delay if needed
        } catch (error) {
            console.error('Error initiating download:', error);
            // Optionally, provide user feedback here
        }
    }
    const manageProperties = () => {
        setIsShowingManageMenu(false)
        setIsShowingManagePropertiesModal(true)
    }
    const hideUpdateDestinationContainer = () => {
        setIsUpdatingDestination(false)
    }
    const hideManagePropertiesModal = () => {
        setIsShowingManagePropertiesModal(false)
    }
    const updateResource = (payload) => {
        hideManagePropertiesModal()
        if (!resource) return
        if (!resource._id) return
        const path = `/workspace/components/${resource._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const getPlaceholder = () => {
        try {
            return resource.attributes.destination
        } catch (error) {
            return "Enter a URL"
        }
    }
    const getRemovalMenuLanguage = () => {
        if (altRemovalText) return altRemovalText
        return "Unlink resource"
    }
    const copyDestinationLinkDestinationMenu = () => {
        if (!getResourceDestination()) return
        navigator.clipboard.writeText(getResourceDestination())
        setDestinationEditModalCopyButtonText("Copied!")
    }
    const copyDestination = () => {
        if (!getResourceDestination()) return 
        navigator.clipboard.writeText(getResourceDestination()) 
        setPropertiesMenuCopyButtonText("Copied!")
    }
    const removeAlignment = () => {
        if (!resource._id) return
        removeMarker(resource._id)
        setIsShowingManageMenu(false)
    }
    const updateResourceDestination = () => {
        if (!displayDestination) return setIsUpdatingDestination(false)
        if (!isValidHttpUrl(displayDestination)) return setIsUpdatingDestination(false)
        // Update resource
        updateResource({attributes: {destination: displayDestination}})
        setIsUpdatingDestination(false)
    }
    const handleKeyPress = (code) => {
        if (code === 'Enter') return updateResourceDestination()
    }
    const getDescription = () => {
        try {
            if (resource.attributes.description === "") return <div style={{color: "grey"}}>No description provided</div>
            return resource.attributes.description
        } catch (error) {
            return "No description provided"
        }
    }
    useState(() => {
        if (!resource) return
        if (!resource.attributes) return
        if (!resource.attributes.destination) return
        setDisplayDestination(resource.attributes.destination)
    }, [])
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (destinationUpdateRef.current && !destinationUpdateRef.current.contains(event.target)) {
                hideUpdateDestinationContainer()
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [destinationUpdateRef])
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (propertiesMenuRef.current && !propertiesMenuRef.current.contains(event.target)) {
                setIsShowingManageMenu(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [propertiesMenuRef])
    useEffect(() => {
        if (isUpdatingDestination) setDestinationEditModalCopyButtonText("Copy link")
        if (isShowingManageMenu) setPropertiesMenuCopyButtonText("Copy link")
    }, [isUpdatingDestination, isShowingManageMenu])
    return (
        <div className="component-page-initiative-searchable-resources-table-row">
            {isShowingManagePropertiesModal && <ModalManageResourceProperties resource={resource} hideModal={hideManagePropertiesModal} updateResource={updateResource} />}
            <div className="component-page-initiative-searchable-resources-table-item comp-pg-init-srchble-rsrcs-tbl-itm-lg component-page-initiative-searchable-resources-table-item-resource-name">

                <div style={{marginRight: "8px"}}><ResourceSymbol resource={resource} /></div>

                {resource.name === "link" && <a target="_blank" rel="noreferrer" href={getResourceDestination()} className="unlink">{resource.display_name}</a>}
                {resource.name === "file" && <div className="file" onClick={downloadFile}>{resource.display_name}</div>}
                {(hasPermission(permissions, 'component_update') && !isUpdatingDestination && resource.name === "link") &&
                <div className="component-page-initiative-searchable-resources-table-item-resource-edit-button" onClick={() => setIsUpdatingDestination(true)}>
                    Edit
                </div>
                }
                {(hasPermission(permissions, 'component_update') && resource.name === "file") &&
                <div className="component-page-initiative-searchable-resources-table-item-resource-edit-button" onClick={downloadFile}>
                    Download
                </div>
                }
                {(hasPermission(permissions, 'component_update') && isUpdatingDestination && resource.name === "link") &&
                <div className="comp-pg-alignment-resource-destination-update-container" ref={destinationUpdateRef}>
                    <p>Edit destination</p>
                    <input onKeyPress={(e) => handleKeyPress(e.code)} placeholder={getPlaceholder()} value={displayDestination} onChange={(e) => setDisplayDestination(e.target.value)} type="text" autoFocus={true} />
                    <div className="comp-pg-alignment-resource-destination-update-copy-link-button" onClick={copyDestinationLinkDestinationMenu}>
                        <MdOutlineContentCopy/>
                        {destinationEditModalCopyButtonText}
                    </div>
                </div>
                }
            </div>
            <div className="component-page-initiative-searchable-resources-table-item comp-pg-init-srchble-rsrcs-tbl-itm-lg">{getDescription()}</div>
            <div className="component-page-initiative-searchable-resources-table-item comp-pg-init-srchble-rsrcs-tbl-itm-sm">
                {getUserNameFromId(team, resource.creator_id)}
            </div>
            <div className="component-page-initiative-searchable-resources-table-item comp-pg-init-srchble-rsrcs-tbl-itm-sm component-page-initiative-searchable-resources-table-item-resource-manage-button">
                {hasPermission(permissions, 'component_update') && <BsThreeDots onClick={() => setIsShowingManageMenu(!isShowingManageMenu)}/>}
                {(hasPermission(permissions, 'component_update') && isShowingManageMenu) && 
                <div className="component-page-initiative-searchable-resources-table-item-resource-manage-menu" ref={propertiesMenuRef}>
                    {resource.name === "link" && <div onClick={copyDestination}>{propertiesMenuCopyButtonText}</div>}
                    {resource.name === "file" && <div onClick={downloadFile}>Download</div>}
                    {resource.name === "link" && <div onClick={manageProperties}>Edit properties</div>}
                    <div onClick={removeAlignment}>{getRemovalMenuLanguage()}</div>                   
                </div>
                }
            </div>                                        
        </div>
    )
}


function ModalManageResourceProperties({resource, hideModal, updateResource}) {
    const [proposedName, setProposedName] = useState(() => { return "" })
    const [proposedDescription, setProposedDescription] = useState(() => { return "" })
    const [proposedDestination, setProposedDestination] = useState(() => { return "" })
    const validateUpdate = () => {
        if (!proposedDestination) return hideModal()
        if (!proposedName) return hideModal()
        updateResource({
            display_name: proposedName,
            attributes: {
                destination: proposedDestination,
                description: proposedDescription
            }
        })
        hideModal()
    }
    useEffect(() => {
        setProposedName(resource.display_name)
        if (!resource.attributes) return
        if (resource.attributes.description) setProposedDescription(resource.attributes.description)
        if (resource.attributes.destination) setProposedDestination(resource.attributes.destination)
    // eslint-disable-next-line
    },[])
    return (
        <div className="alignment-modal-background">
            <div className="modal-project-create-child-container" style={{width: "600px"}}>
                <h1>Properties</h1>

                <div>
                    <div className="modal-project-create-child-form-options">
                        <div className="modal-project-create-child-form-option">
                            <h2>Name</h2>
                            <input value={proposedName} onChange={(e) => setProposedName(e.target.value)} autoFocus={true} type="text" placeholder="Resource name" />
                        </div>
                        <div className="modal-project-create-child-form-option">
                            <h2>Link</h2>
                            <input value={proposedDestination} onChange={(e) => setProposedDestination(e.target.value)} type="text" placeholder="https://..." />
                        </div>
                        <div className="modal-project-create-child-form-option">
                            <h2>Description</h2>
                            <input value={proposedDescription} onChange={(e) => setProposedDescription(e.target.value)}  type="text" placeholder="Add a description" />
                        </div>
                    </div>
                    <div className="modal-project-create-child-form-buttons">
                        <div className="modal-project-create-child-form-button modal-project-create-child-form-button-create" onClick={validateUpdate}>Update</div>
                        <div className="modal-project-create-child-form-button" onClick={hideModal}>Cancel</div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}