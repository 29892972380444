import React from 'react'
import { useState, useEffect, useRef } from "react";
import { format, parseISO } from 'date-fns'
import { IoCubeSharp, IoExtensionPuzzleSharp, IoAnalyticsSharp, IoGridSharp } from "react-icons/io5";

export default function TemplatePreviewSmall({ template, openEditTemplateModal, getTemplateTypeSymbol }) {
    const [totalTasks, setTotalTasks] = useState(() => { return 0 })
    const [totalColumns, setTotalColumns] = useState(() => { return 0 })
    const [totalGoals, setTotalGoals] = useState(() => { return 0 })
    const [totalWorkstreams, setTotalWorkstreams] = useState(() => { return 0 })
    const [showPopup, setShowPopup] = useState(() => { return false })
    const hoverTimer = useRef();
    const leaveTimer = useRef();
    const getSymbol = () => {
        return getTemplateTypeSymbol(template.component_type)
    }
    const updateTotals = () => {
        if (!template) return
        // Tasks
        if (["list", "board"].includes(template.component_type)) {
            setTotalTasks(template.configs.length)
        } else if (template.component_type === "initiative") {
            setTotalTasks(getTaskCountForProject())
        }
        // Columns
        if (template.component_type === "board") {
            setTotalColumns(template.config.columns.length)
        }
        // Workstreams
        if (template.component_type !== "initiative") return
        setTotalWorkstreams(getWorkstreamCountForProject())
        setTotalGoals(getGoalCountForProject())
    }
    const getTaskCountForProject = () => {
        try {
            let totalTasks = 0
            const configs = template.configs
            for (let i = 0; i < configs.length; i++) {
                const config = configs[i];
                if (config.tasks) totalTasks += config.tasks.length
            }
            return totalTasks
        } catch (error) {
            return 0
        }
    }
    const getWorkstreamCountForProject = () => {
        try {
            let totalBoards = 0
            const configs = template.configs
            totalBoards = configs.filter(c => ["board", "list"].includes(c.component_type))
            return totalBoards.length
        } catch (error) {
            return 0
        }
    }
    const getGoalCountForProject = () => {
        try {
            let totalGoals = 0
            const configs = template.configs
            totalGoals = configs.filter(c => c.component_type === "kresult")
            return totalGoals.length
        } catch (error) {
            return 0
        }
    }
    const handleMouseEnter = () => {
        // Clear the timer if it was previously set
        clearTimeout(hoverTimer.current);
        // Set the timer to show the popup after 500ms
        hoverTimer.current = setTimeout(() => {
            setShowPopup(true);
        }, 500);
    };
    const handleMouseLeave = () => {
        // Clear the hover timer to prevent showing the popup after leaving
        clearTimeout(hoverTimer.current);
        // Set the timer to hide the popup after 200ms
        leaveTimer.current = setTimeout(() => {
            setShowPopup(false);
        }, 200);
    };
    useEffect(() => {
        updateTotals()
    // eslint-disable-next-line
    }, [template])
    return (
        <div style={{position: "relative"}} className="workspace-page-template-display" onClick={() => openEditTemplateModal(template)} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className="workspace-page-template-display-header">
                {getSymbol()}
                <div>
                    <h4>{template.template_name}</h4>
                    <div className="workspace-page-template-display-heading-subtitle">Created {format(parseISO(template.createdAt), 'MM/dd/yyyy')}</div>
                </div>
            </div>
            {template.scope === "network" && <div className="workspace-page-template-display-network-badge">
                <span>Network</span>
            </div>}
            {showPopup && (
                <div className="workspace-page-template-description-popup">
                    <h4>{template.template_name}</h4>
                    <div className="workspace-page-template-display-features">
                        {["list", "board"].includes(template.component_type) &&
                        <div className="workspace-page-template-display-feature">
                            <IoCubeSharp/>
                            {totalTasks} tasks
                        </div>
                        }
                        {template.component_type === "board" &&
                        <div className="workspace-page-template-display-feature">
                            <IoGridSharp />
                            {totalColumns} columns
                        </div>
                        }
                        {template.component_type === "initiative" &&
                        <>
                        <div className="workspace-page-template-display-feature">
                            <IoExtensionPuzzleSharp  />
                            {totalWorkstreams} workstreams
                        </div>
                        <div className="workspace-page-template-display-feature">
                            <IoAnalyticsSharp />
                            {totalGoals} goals
                        </div>
                        <div className="workspace-page-template-display-feature">
                            <IoCubeSharp/>
                            {totalTasks} tasks
                        </div>
                        </>
                        }
                    </div>
                    {template.template_description &&
                    <div className="workspace-page-template-display-description">{template.template_description}</div>
                    }
                </div>
            )}
        </div>
    )
}