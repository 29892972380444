import React from 'react'
import { useState, useRef, useEffect } from 'react'
import { BsCalendarEvent } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import Api from "../../../common/APIUtils"
import '../../../styles/forms.css';
import '../../../styles/components.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar } from 'react-date-range';
import { format, isValid, addDays } from 'date-fns';

export default function NewGoal({workspaceId}) {
    const api = new Api();
    let history = useHistory();
    const dateSelector = useRef()
    const [name, setName] = useState(() => { return "" })
    const [canSubmit, setCanSubmit] = useState(() => { return false })
    const [isShowingdueDatePicker, setIsShowingdueDatePicker] = useState(() => { return false })
    const [dueDate, setdueDate] = useState(() => { return addDays(new Date(), 30) })
    const [goalDescription, setGoalDescription] = useState(() => { return "" })
    const publishGoal = () => {
        if (!canSubmit) return
        const attributes = {};
        // Add the default attributes and the defined attributes to the sub object
        attributes['active'] = true;
        if (goalDescription !== "") { attributes['description'] = goalDescription } else { attributes['description'] = "" }
        if (dueDate) { attributes['date_due'] = format(new Date(dueDate), "yyyy-MM-dd") } else { attributes['date_due'] = "" }
        const payload = {
            workspace_id: workspaceId,
            is_published: true,
            display_name: name,
            attributes: attributes,
            owner: ""
        }
        const path = `/components/plan/goal`;
        api.createWorkspaceComponent(payload, path)
        .then( (res) => {
            console.log(res)
            if (res.status === 200) {
                history.push(`/workspaces/${workspaceId}/c/${res.data.response.component._id}`);
            }
        })
        .catch((err) => {
            console.log(err);
            alert(err);
        })

    }
    const getDescriptionRowCount = () => {
        const MIN_ROW_COUNT = 4;
        // assume roughly 50 characters per row
        const breaks = goalDescription.split(/\r\n|\r|\n/).length + 1
        const rowsFromCharacters = Math.round(goalDescription.length/50) + 1
        return Math.max(breaks, rowsFromCharacters, MIN_ROW_COUNT);
    }
    const updateDueDate = (newStartDate) => {
        setdueDate(newStartDate)
    }
    const showStartDatePicker = () => {
        setIsShowingdueDatePicker(true)
    }
    const getFancyDateString = (dateString) => {
        if (!isValid(dateString)) return 'Choose a date'
        return format(dateString, 'MMM do, yyyy')
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dateSelector.current && !dateSelector.current.contains(event.target)) {
                setIsShowingdueDatePicker(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [dateSelector])
    useEffect(() => {
        if (!isValid(dueDate) || name === "") {
            setCanSubmit(false)
            return
        }
        setCanSubmit(true)
    // eslint-disable-next-line
    }, [dueDate, name])
    return (
        <div className="network-page">
            <div className="network-page-header-row">
                <div className="network-page-header-text">
                    <h1>New Goal</h1>
                    <p>Use goals to set progress targets for KPIs and work</p>
                </div>
            </div>

            <div className="form-network-create-form-container">

                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-section-label">Name</div>
                    <div className="form-network-create-form-section-input">
                        <input style={{fontSize: "1.1em"}} autoFocus={true} value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder='Name this goal' />
                    </div>
                </div>

                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-subsection">
                        <div className="form-network-create-form-subsection-title">Timeline</div>
                        <div className="form-network-create-form-subsection-divider-container">
                            <div className="form-network-create-form-subsection-divider"></div>
                            <div></div>
                        </div>
                    </div>

                    <div className="form-network-create-form-section-row" style={{gap: "1em", boxSizing: "border-box"}}>

                        <div className="form-create-date-selection-container" onClick={showStartDatePicker}>
                            <div className="form-create-date-selection-clickable-display">
                                <BsCalendarEvent/>
                                <div>
                                    <div className="form-network-create-form-section-label">Due Date</div>
                                    <div className="form-create-date-description">{getFancyDateString(dueDate)}</div>
                                </div>
                            </div>

                            {isShowingdueDatePicker && (
                                <div className="form-create-date-calendar-picker" ref={dateSelector}>
                                    <Calendar date={dueDate} onChange={updateDueDate} />
                                </div>
                            )}
                        </div>

                    </div>
                </div>

                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-subsection">
                        <div className="form-network-create-form-subsection-title">Details</div>
                        <div className="form-network-create-form-subsection-divider-container">
                            <div className="form-network-create-form-subsection-divider"></div>
                            <div></div>
                        </div>
                    </div>
                    <div className="form-network-create-form-section-input">
                        <div className="form-network-create-form-section-label" style={{marginBottom: "0.5em"}}>Description</div>
                        <textarea rows={getDescriptionRowCount()} placeholder="Add more information about this goal." value={goalDescription} onChange={(e) => setGoalDescription(e.target.value)}></textarea>
                    </div>
                </div>

                {canSubmit ? <div className="form-network-create-form-create" onClick={publishGoal}>Create Goal</div> : <div className="form-network-create-form-create" style={{backgroundColor: "grey"}}>Create Goal</div>}


            </div>
        </div>

    )
}
