import React from 'react'
import { useHistory } from 'react-router-dom';

export default function WorkspaceCompHashtags({tags, workspaceId, asButtons}) {
  const history = useHistory()
  const isValidTag = (tag) => {
    if (!workspaceId) return false
    if (!tag) return false
    if (!tag.tag_name) return false
    return true
  }
  const goToTagsPage = (tag) => {
    if (isValidTag(tag)) return history.push(`/workspaces/${workspaceId}/t/${tag.tag_name}`)
  }
  const additionalStyles = (tag) => {
    if (!isValidTag(tag)) return {
      color: "black",
      cursor: "default"
    }
    if (asButtons) return { padding: "4px 12px", marginRight: "12px", backgroundColor: "#2A73AE", borderRadius: "12px", color: "white", fontWeight: 500, textDecoration: "none" }
    return {}
  }
  return (
    <div className="gadget-hashtags-container">
      {tags.map((tag) => (
          <span style={additionalStyles(tag)} onClick={() => goToTagsPage(tag)} key={tag._id} className="gadget-hashtag">#{tag.tag_name}</span>
      ))}
    </div>
  )
}
