import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { Calendar } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { format, isValid, addYears } from 'date-fns';
import { IoCaretDownSharp } from 'react-icons/io5'

export default function DateRangeValueEditable({date, onupdate, label, editable, minDate, maxDate, side, clearDate}) {
    const [isShowingDatePicker, setIsShowingDatePicker] = useState(() => { return false })
    const [calendarFriendlyDate, setCalendarFriendlyDate] = useState(() => { return "" })
    const datePickerRef = useRef(null)
    const getMin = () => {
        if (!minDate) return new Date("01-01-2020")
        if (isValid(minDate)) return minDate
        return new Date("01-01-2020")
    }
    const handleNewChange = (propDate) => {
        hide()
        onupdate(propDate)
    }
    const getMax = () => {
        if (!maxDate) return addYears(new Date(), 20)
        if (isValid(maxDate)) return maxDate
        return addYears(new Date(), 20)
    }
    const hide = () => {
        setIsShowingDatePicker(false)
    }
    const getFancyDateString = () => {
        if (!editable && !isValid(date)) return <div>None</div>
        if (!isValid(date)) return (
            <div className="date-box-no-date-text">
                <div>Choose a date</div>
                <IoCaretDownSharp/>
            </div>
        ) 
        return format(date, 'PP')
    }
    const handleClickOnDatePicker = () => {
        if (!editable) return
        if (isValid(date)) setCalendarFriendlyDate(date)
        if (!isValid(date)) setCalendarFriendlyDate(new Date())
        setIsShowingDatePicker(true)
    }
    const getAdditionalStyles = () => {
        if (side === "right") return {
            left: 0,
            right: "unset"
        }
        return { }
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
                hide()
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [datePickerRef])
    // <div className="component-page-initiative-prog-overview-date-value">{format(parseISO(proposedDateStart), 'PP')}</div>
    return (
        <div className="component-page-initiative-prog-overview-date-value" ref={datePickerRef}>
                <div onClick={handleClickOnDatePicker}>
                    <div>{getFancyDateString()}</div>
                </div>

                {isShowingDatePicker && (
                    <div className="date-box-editable-calendar-picker" style={getAdditionalStyles()}>
                        <Calendar date={calendarFriendlyDate} onChange={handleNewChange} maxDate={getMax()} minDate={getMin()} />
                    </div>
                )}
        </div>
    )
}
