import React from 'react'
import { useRef } from 'react'
import { BiCalendar } from 'react-icons/bi';
import { MdSchema } from 'react-icons/md'
import { GiCheckMark } from 'react-icons/gi'
import { format, parseISO, isPast, addDays } from 'date-fns'
import ProfPicCircle from '../../profiles/ProfPicCircle';
import { getUserProfileSrcFromId, getUserNameFromId, getColorHexMapFromName } from '../../../common/Helpers';
import { Draggable } from 'react-beautiful-dnd'

export default function WorkspaceCompListTask({task, isInCompleted, team, selectTaskToEdit, updateTask, index}) {
    const actionCircleRef = useRef(null)
    const getDateSection = () => {
        try {
            if (isInCompleted) {
                return (
                    <div className="component-list-task-section-meta">
                        <BiCalendar/>
                        {task.completed && <span>Completed {format(parseISO(task.completed), 'LLLL do')}</span>}
                        {!task.completed && <span>{format(new Date(), 'LLLL do')}</span>}
                    </div>
                )
            }
            if (!task.attributes.date_due) return
            const overDueColor = "#E60053"
            let styles = {}
            if (isPast(addDays(parseISO(task.attributes.date_due), 1))) styles['color'] = overDueColor
            return (
                <div className="component-list-task-section-meta">
                    <BiCalendar style={styles}/>
                    <span style={styles}>{format(parseISO(task.attributes.date_due), 'LLLL do')}</span>
                </div>
            ) 
             
        } catch (error) {
            console.log(error)
        }
    }
    const getActionCircle = () => {
        if (!isInCompleted) {
            return (
                <span className="component-list-task-action-circle" ref={actionCircleRef} onClick={completeTask}></span>
            )
        }
        return (
            <span className="component-list-task-action-circle component-list-task-action-circle-completed" ref={actionCircleRef} onClick={restoreTask}>
                <GiCheckMark style={{color: "#9F6ADB"}}/>
            </span>
        )
    }
    const getOwnerSection = () => {
        if (isInCompleted) return
        if (!task.owner_id) return
        return (
            <div className="component-list-task-section-meta">
                <ProfPicCircle src={getUserProfileSrcFromId(team, task.owner_id)} userId={task.owner_id} userName={getUserNameFromId(team, task.owner_id)} height="28px" disableClick={true}/>
            </div>
        )
    }
    const getDetails = () => {
        try {
            if (typeof task.attributes.details === "string") return task.attributes.details
            if (!task.attributes.details.description_html) return ""
            return task.attributes.details.description_html.replace(/<\/?[^>]+(>|$)/g, ' ')
        } catch (error) {
            return ""
        }
    }
    const getSubtasksSection = () => {
        try {
            if (isInCompleted) return
            if (!task.criteria) return
            if (task.criteria.length === 0) return
            let completed = 0
            const copyOfCriteria = Array.from(task.criteria)
            const completedCriteria = copyOfCriteria.filter((c) => c.status === "completed")
            if (completedCriteria.length > 0) completed = completedCriteria.length
            return (
                <div className="component-list-task-section-meta">
                    <MdSchema/>
                    <span>{completed}/{task.criteria.length} completed</span>
                </div>
            )
        } catch (error) {
            return
        }
    }
    const completeTask = () => {
        const payload = {
            attributes: {
                active: false,
                date_completed: new Date(),
                progress: 1,
            },
            status: "completed",
            completed: new Date(),
        }
        updateTask(payload, task._id)
    }
    const restoreTask = () => {
        const payload = {
            attributes: {
                active: true,
                date_completed: "",
                progress: 0,
            },
            status: "active",
            completed: ""
        }
        updateTask(payload, task._id) 
    }
    const getCardColorStyle = (provided) => {
        let addedStyles = {}
        try {
            let cardColor = task.attributes.color_name
            if (!cardColor || cardColor === "none") return {...provided.draggableProps.style}
            const col = getColorHexMapFromName(cardColor).hex
            addedStyles['borderLeft'] = `3px solid ${col}`
            addedStyles['borderTopLeftRadius'] = "0px"
            addedStyles['borderBottomLeftRadius'] = "0px"
            addedStyles['borderRight'] = `3px solid transparent`
        } catch (error) {
            addedStyles = {}   
        }
        return {...provided.draggableProps.style, ...addedStyles}
    }
    const handleGeneralClicks = (event) => {
        if (actionCircleRef.current && actionCircleRef.current.contains(event.target)) return
        return selectTaskToEdit(task._id)
    }
    return (
        <Draggable draggableId={task._id} index={index} isDragDisabled={isInCompleted}>
            {(provided) => (

            <div className="component-list-task-container" onClick={(event) => handleGeneralClicks(event)} { ...provided.draggableProps } ref={provided.innerRef} {...provided.dragHandleProps} style={getCardColorStyle(provided)}>
                <div className="component-list-task-container-section-summary">
                    {getActionCircle()}
                    <div className="component-list-task-summary-titles">
                        <h3>{task.display_name}</h3>
                        <p>{getDetails()}</p>
                    </div>
                </div>
                <div className="component-list-task-container-section-meta">
                    {getSubtasksSection()}
                    {getDateSection()}
                    {getOwnerSection()}
                </div>
            </div>
            )}



        </Draggable>
    )
}
