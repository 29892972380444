import React from 'react'
import { FaLongArrowAltLeft} from 'react-icons/fa';
import { useState, useEffect } from "react";

export default function NewMeasurement({createKpi, goToAllMeasurements}) {
    const [canSubmit, setCanSubmit] = useState(() => { return false })
    const [proposedName, setProposedName] = useState(() => { return "" })
    const [description, setDescription] = useState(() => { return "" })
    const [customUnits, setCustomUnits] = useState(() => { return "" })
    const [proposedUnitSelection, setProposedUnitSelection] = useState(() => { return "" })
    const [proposedStartingValue, setProposedStartingValue] = useState(() => { return 0 })
    const metricUnits = [
        {
            id: 0,
            name: 'Raw Number',
            value: ''
        },
        {
            id: 1,
            name: '$',
            value: '$'
        },
        {
            id: 2,
            name: '%',
            value: '%'
        },
        {
            id: 3,
            name: 'Custom',
            value: 'custom'
        }
    ]
    const getDescriptionRowCount = () => {
        const MIN_ROW_COUNT = 2;
        // assume roughly 50 characters per row
        const breaks = description.split(/\r\n|\r|\n/).length + 1
        const rowsFromCharacters = Math.round(description.length/50) + 1
        return Math.max(breaks, rowsFromCharacters, MIN_ROW_COUNT);
    }
    const getStartingValueStyle = () => {
        return {
            // border: "none",
            // borderRadius: "0",
            border: "1px solid rgb(156,156,156)",
            fontSize: "48px",
            // fontSize: "1.1em",
            paddingBottom: "4px",
            paddingLeft: "4px",
            paddingTop: "4px",
            height: "unset",
            fontWeight: "400",
            textAlign: "center",
            fontFamily: "-apple-system, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
        }
    }
    const getUnits = () => {
        if (proposedUnitSelection === 'custom') return customUnits
        const metric = metricUnits.find((a) => a.value === proposedUnitSelection)
        if (!metric) return ""
        return metric.value
    }
    const submitNewKpi = () => {
        createKpi({
            display_name: proposedName,
            is_published: true,
            attributes: {
                val_start: proposedStartingValue,
                val_current: proposedStartingValue,
                units: getUnits(),
                is_ascending: true,
                description: description
            }
        })
        goToAllMeasurements()
    }
    useEffect(() => {
        if (proposedName && (proposedStartingValue || proposedStartingValue === 0)) {
            setCanSubmit(true)
            return
        }
        setCanSubmit(false)
    }, [proposedName, proposedStartingValue])
    return (
        <div className="network-page">
            <div className="network-page-header-row">
                <div onClick={goToAllMeasurements} className="network-page-header-back-navigation">
                    <FaLongArrowAltLeft />
                    <span>All Measurements</span>
                </div>
            </div>

            <div className="network-page-header-row">
                <div className="network-page-header-text">
                    <h1>New Key Performance Indicator</h1>
                    <p>Key Performance Indicators (KPIs) identify a specific measurement at a point in time.</p>
                </div>
            </div>

            <div className="form-network-create-form-container">

                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-section-label">Name</div>
                    <div className="form-network-create-form-section-input">
                        <input style={{fontSize: "1.1em"}} autoFocus={true} value={proposedName} onChange={(e) => setProposedName(e.target.value)} type="text" placeholder='What are you measuring with this KPI?' />
                    </div>
                </div>

                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-section-label">Starting Value</div>
                    <div className="form-network-create-form-section-input">
                        <input value={proposedStartingValue} onChange={(e) => setProposedStartingValue(e.target.value)} style={getStartingValueStyle()} type="number" placeholder="0" />
                    </div>
                </div>

                <div className="modal-workspace-component-edit-section modal-workspace-component-edit-section-doublewide form-network-create-form-section" style={{marginTop: "2em"}}>
                    <div className="modal-workspace-component-edit-subsection">
                        <div className="form-network-create-form-section-label" style={{marginBottom: "0.5em"}}>Units</div>
                        <select style={{fontSize: "1.1em"}} value={proposedUnitSelection} onChange={(e) => setProposedUnitSelection(e.target.value)} name="metricUnitSelection">
                        {metricUnits.map((metricUnit) => (
                            <option key={metricUnit.id} value={metricUnit.value}>{metricUnit.name}</option>
                        ))}
                        </select>
                    </div>
                    {proposedUnitSelection === "custom" ? (
                        <div className="modal-workspace-component-edit-subsection">
                            <div className="form-network-create-form-section-label" style={{marginBottom: "0.5em"}}>Measurement</div>
                            <div className="form-network-create-form-section-input">
                                <input style={{fontSize: "1.1em"}} autoFocus={true} value={customUnits} onChange={(e) => setCustomUnits(e.target.value)} type="text" />
                            </div>
                        </div>
                    ) : (
                        <div className="modal-workspace-component-edit-subsection"></div>
                    )}
                </div>
            
                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-section-input">
                        <div className="form-network-create-form-section-label" style={{marginBottom: "0.5em"}}>Description</div>
                        <textarea rows={getDescriptionRowCount()} placeholder="Add a description for the measurement" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                    </div>
                </div>

                {canSubmit ? <div className="form-network-create-form-create" onClick={submitNewKpi}>Add KPI to Workspace</div> : <div className="form-network-create-form-create" style={{backgroundColor: "grey"}}>Add KPI to Workspace</div>}

            </div>

        </div>
    )
}
