import React from 'react'
import { useRef } from 'react';
import { format, addDays, parseISO } from 'date-fns';
import { getColorFromIndex, getColorStylesFromObjectId } from '../../common/Helpers';
import CalendarDayModal from './CalendarDayModal';
import { RiMedal2Fill  } from 'react-icons/ri'

function CalendarCell({number, events, date, colorByEventProperty, colorCodex, showDayModal, setShowDayModal, selectedModalDay, setSelectedModalDay, activateItemModal, activateNewComponentModal}) {
    const cellContainer = useRef();
    const showInModal = (item, e) => {
        if (e) e.stopPropagation()
        activateItemModal(item)
    }
    const getColorKeyProp = (component) => {
        // the item the color styles should hash from
        let colorKeyProp = component._id
        if (colorByEventProperty) colorKeyProp = component[colorByEventProperty]
        if (colorByEventProperty === "parent") {
            if (component.parent) {
                if (component.parent._id) colorKeyProp = component.parent._id
            } else if (component.attributes.parent) {
                if (component.attributes.parent) colorKeyProp = component.attributes.parent
            }
        }
        return colorKeyProp
    }
    const getBackgroundColor = (component) => {
        try {
            const colorKeyProp = getColorKeyProp(component)
            const colorStyles = getColorStylesFromObjectId(colorKeyProp)
            return colorStyles.backgroundColor
        } catch (error) {
            return getColorFromIndex(99)
        }
    }
    const getEventStyles = (component) => {
        const colorKeyProp = getColorKeyProp(component)
        const colorStyles = getColorStylesFromObjectId(colorKeyProp)
        if (component.status !== 'active') return {
            textDecoration: 'line-through',
            opacity: "60%",
            backgroundColor: colorStyles.backgroundColor,
            color: colorStyles.color
        }
        return {
            backgroundColor: colorStyles.backgroundColor,
            color: colorStyles.color
        }
    }
    const getCalendarCellBoxStyles = () => {
        if (format(date, 'P') === format(new Date(), 'P')) return {
            backgroundColor: 'rgb(230, 230, 230)',
        }
        return {}
    }
    const getNumberStyles = () => {
        if (format(date, 'P') === format(new Date(), 'P')) return {
            fontWeight: '600'
        }
        return {}
    }
    const canShowModal = () => {
        if (!showDayModal) return false
        if (format(date, 'P') === format(selectedModalDay, 'P')) return true
        return false
    }
    const closeDownModal = () => {
        setShowDayModal(false)
    }
    const handleCellClick = () => {
        activateNewComponentModal(date)
    }
    const calculateAllowedDisplayItems = () => {
        if (!cellContainer.current) return 3
        if (!cellContainer.current.clientHeight) return 3
        const currentHeight = cellContainer.current.clientHeight;
        const HEADER_HEIGHT = 24; // px
        const EVENT_HEIGHT = 28; // actual height is 24px
        const MORE_MESSAGE_HEIGHT = 30; // px
        const availableHeight = currentHeight - (HEADER_HEIGHT + MORE_MESSAGE_HEIGHT)
        const maxFit = Math.floor(availableHeight/EVENT_HEIGHT)
        if (maxFit < 0) return 0
        return maxFit
    }
    return (
        <div className="calendar-cell-width-control calendar-cell-box" style={getCalendarCellBoxStyles()} ref={cellContainer} onClick={handleCellClick}>
            <div className="calendar-cell-content-header" style={getNumberStyles()} onClick={() => calculateAllowedDisplayItems()}>{number}</div>
            {events.map((component) => (
                <div style={getEventStyles(component)} className="calendar-cell-content-event" onClick={(e) => showInModal(component, e)} key={component._id}>
                    {component.name === "kresult" && <RiMedal2Fill  style={{marginRight: "2px"}}/> }
                    <span>{component.display_name}</span>
                </div>
            ) )}
            {canShowModal() && <CalendarDayModal getBackgroundColor={getBackgroundColor} getEventStyles={getEventStyles} goToItem={showInModal} events={events} closeModal={closeDownModal} date={date}/>}
        </div>
    )
}

export default function CalendarRow({dateStart, events, colorByEventProperty, colorCodex, showDayModal, setShowDayModal, selectedModalDay, setSelectedModalDay, activateItemModal, activateNewComponentModal}) {
    // colorByEventProperty searches the property name identified here and figures out what index it is in the colorCodex for adding a style to the individual event
    // colorCodex is an array of items that the colorByEventProperty *values* should have an entry inside of
    // Example:
    // colorByEventProperty="workspace_id" would read a value from an event of "123"
    // the colorCodex would have be an array of workspace IDs, ["123", "456"] --- resultant index is 0
    const getEventsForDate = (importantDate) => {
        const filteredComponents = events.filter((component) => {
            var date = false
            if (component.status === 'completed' && component.completed) {
                date = component.completed
                if (!component.attributes) date = component.completed
                if (component.attributes.date_due) date = component.attributes.date_due
                if (component.attributes.date_due === "") date = component.completed
            }

            if (component.status !== 'completed') {
                if (!component.attributes) return false
                if (!component.attributes.date_due) return false
                if (component.attributes.date_due === "") return false
                date = component.attributes.date_due
            }
            if (!date) return false
            
            if (format(parseISO(date), 'P') === format(importantDate, 'P')) {
                return true
            }
            return false
        })
        return filteredComponents
    }
    const getRange = () => {
        let range = []
        for (let i = 0; i < 7; i++) {
            const dateInQuestion = addDays(dateStart, i)
            let dateData = {}
            const dateNumber = format(dateInQuestion, 'd')
            dateData['key'] = format(dateInQuestion, 'PPPP')
            dateData['date'] = dateInQuestion
            dateData['number'] = dateNumber
            dateData['events'] = getEventsForDate(dateInQuestion)
            range.push(dateData)
        }
        return range
    }
    return (
        <div className="calendar-row calendar-row-content">
            {getRange().map((dateData) => (
                <CalendarCell activateItemModal={activateItemModal} activateNewComponentModal={activateNewComponentModal} selectedModalDay={selectedModalDay} setSelectedModalDay={setSelectedModalDay} setShowDayModal={setShowDayModal} showDayModal={showDayModal} key={dateData.key} number={dateData.number} events={dateData.events} date={dateData.date} colorByEventProperty={colorByEventProperty} colorCodex={colorCodex}/>
            ))}
        </div>
    )
}
