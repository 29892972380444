import React from 'react';
import { useState, useEffect, useRef } from 'react';
import EditableComponentTitle from '../gadgets/EditableComponentTitle';
import ConfirmAction from '../modals/ConfirmAction';
import Api from '../../common/APIUtils';
import { useHistory } from 'react-router-dom';
import { hasPermission, sortByDateProperty, sortByPropertyReverse, sortByDateStringAttribute, sortByProperty } from '../../common/Helpers';
import { IoSettingsOutline, IoSearchOutline, IoAdd } from 'react-icons/io5'
import ContextBreadcrumb from '../gadgets/ContextBreadcrumb';
import CProgressBar from './CProgressBar';
import { Player } from '@lottiefiles/react-lottie-player'
import ComponentUserFilterButton from '../gadgets/ComponentUserFilterButton';
import ModalCreateWorkstreamTemplate from '../modals/ModalCreateWorkstreamTemplate';
import ModalWorkstreamSettings from '../modals/ModalWorkstreamSettings';
import SimpleListActionMenu from './SimpleListActionMenu';
import CreateNewTaskMegabar from '../gadgets/CreateNewTaskMegabar';

import WorkspaceCompBoardColumn from './boards/WorkspaceCompBoardColumn';
import NewComponentTask from '../modals/NewComponentTask';
import ModalFullScreenEditTask from '../modals/ModalFullScreenEditTask';
import ModalMoveTaskToNewParent from '../modals/ModalMoveTaskToNewParent';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import LoadingSymbolGrid from '../gadgets/LoadingSymbolGrid';

export default function WorkspaceCompBoard({ team, component, fetchComponent, permissions, componentData, workspaceName }) {
    const api = new Api();
    const isUpdatingLocalCopy  = useRef(false)
    const lottieCelebrationFile = require('../../static/assets/lottie_confetti.json')
    let history = useHistory();
    const [boardId, setBoardId] = useState(() => { return component._id ? component._id : false})
    const [celebrate, setCelebrate] = useState(() => { return false })
    const [isEditingCards, setIsEditingCards] = useState(() => { return false })
    const [isTemplatizing, setIsTemplatizing] = useState(() => { return false })
    const [isShowingConfirmMenu, setIsShowingConfirmMenu] = useState(() => { return false })
    const [realtimeProgress, setRealtimeProgress] = useState(() => { return component.progress ? Math.round(component.progress * 100, 2) : 0 })
    const [confirmMenuContent, setConfirmMenuContent] = useState(() => { return "" })
    const [confirmMenuAction, setConfirmMenuAction] = useState(() => { return false })
    const [showCreateTaskForm, setShowCreateTaskForm] = useState(() => { return false })
    const [isShowingManageSettingsMenu, setIsShowingManageSettingsMenu] = useState(() => { return false })
    const [isShowingCreateWorkstreamTemplateModal, setIsShowingCreateWorkstreamTemplateModal] = useState(() => { return false })
    const [filteredOutTeammates, setFilteredOutTeammates] = useState(() => { return [] })
    const [searchText, setSearchText] = useState(() => { return "" })
    const [columnData, setColumnData] = useState(() => { return [] })
    const [boardFilter, setBoardFilter] = useState(() => { return {} })

    const lastCardUpdate  = useRef(false)
    const lastPendingUpdateTime  = useRef(false)
    const [isLoadingBoardData, setIsLoadingBoardData] = useState(() => { return true })
    const [boardData, setBoardData] = useState(() => { return {} })
    const [isUpdatingColumnOrders, setIsUpdatingColumnOrders] = useState(() => { return false })
    const [isEditingModal, setIsEditingModal] = useState(() => { return false })
    const [isMovingModal, setIsMovingModal] = useState(() => { return false })
    const [selectedTask, setSelectedTask] = useState(() => { return "" })
    const [cardLimit, setCardLimit] = useState(() => { return 50 })
    const [pendingColumnUpdates, setPendingColumnUpdates] = useState(() => { return false })
    const [isSubmittingColumnUpdates, setIsSubmittingColumnUpdates] = useState(() => { return false })
    const [lastUpdateTime, setLastUpdateTime] = useState(() => { return false })
    const [isSubmittingCardUpdates, setIsSubmittingCardUpdates] = useState(() => { return false })
    const [activeCardUpdateSetIsSpinning, setActiveCardUpdateSetIsSpinning] = useState(() => { return false })
    const [pendingCardUpdateQueue, setPendingCardUpdateQueue] = useState(() => { return [] })
    const [activeCardUpdateSet, setActiveCardUpdateSet] = useState(() => { return [] })

    const hideConfirmPopup = () => {
        setIsShowingConfirmMenu(false)
    }
    const fetchBoardData = () => {
        if (isUpdatingLocalCopy.current) return
        if (isEditingCards) return
        if (isSubmittingCardUpdates) return
        try {
          if (!isUpdatingColumnOrders) {
            const payload = {
              componentId: componentData.component._id
            }
            api.getBoardCards(payload, cardLimit)
            .then((res) => {
              if (!isUpdatingLocalCopy.current) {
                if (res.data.response.board) {
                  if (!isEditingCards && !isSubmittingCardUpdates) setBoardData(res.data.response.board); // replacement for the tasks - includes positions
                  setIsLoadingBoardData(false)
                }
                if (res.data.response.columns) {
                  if (res.data.response.columns.length > 0) setColumnData(res.data.response.columns) // current bug - this returns as a zero length array at times
                  setIsLoadingBoardData(false)
                }
              }
              // calculateRealtimeProgress()
            })
            .catch((err) => {
                console.log(err)
            })      
          }
        } catch (error) {
          console.log(error)
        }
      }
    const deleteBoard = async () => {
        hideConfirmPopup()
        setIsShowingManageSettingsMenu(false)
        const payload = {
            componentId: componentData.component._id
        }
        const workspaceId = componentData.component.workspace_id;

        api.deleteWorkspaceComponent(payload)
        .then((res) => {
            history.push(`/workspaces/${workspaceId}`);
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const restoreBoard = () => {
        hideConfirmPopup()
        setIsShowingManageSettingsMenu(false)
        const payload = {
            status: 'active'
        };
        const path = `/workspace/components/${componentData.component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const templatizeBoard = (templateName) => {
        hideConfirmPopup()
        setIsTemplatizing(true)
        setIsShowingManageSettingsMenu(false)
        const payload = {
            template_name: templateName,
            base_component_id: componentData.component._id,
            scope: 'workspace',
            component_type: 'board',
            workspace_id: componentData.component.workspace_id
        };
        api.createTemplateFromComponent(payload)
        .then((res) => {
            setIsTemplatizing(false)
            fetchComponent();
        })
        .catch((err) => {
            setIsTemplatizing(false)
        })
    }
    const archiveBoard = () => {
        hideConfirmPopup()
        setIsShowingManageSettingsMenu(false)
        const payload = {
            status: 'archived'
        };
        const path = `/workspace/components/${componentData.component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const completeBoard = () => {
        hideConfirmPopup()
        setIsShowingManageSettingsMenu(false)
        const payload = {
            status: 'completed',
            progress: 1
        };
        const path = `/workspace/components/${componentData.component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const transformToList = () => {
        hideConfirmPopup()
        const payload = {
            component_id: boardId,
            workspace_id: component.workspace_id,
            proposed_name: "list"
        }
        api.transformWorkspaceComponent(payload)
        .then((res) => {
            fetchComponent()
        })
        .catch((err) => { return })
    }
    const getActionList = () => {
        let actions = [];
        if (hasPermission(permissions, 'component_delete')) {
            actions = actions.concat([
                {
                    text: 'Delete board',
                    action: askForDeleteConfirmation,
                    id: 'delete'
                }
            ])
        }
        if (hasPermission(permissions, 'component_template_create')) {
            actions = [
                {
                    text: 'Create template from board',
                    action: askForTemplateConfirmation,
                    id: 'template'
                }
            ].concat(actions)
        }
        if (component.status === "archived") {
            actions = [
                {
                    text: 'Reactivate board',
                    action: askForUnarchiveConfirmation,
                    id: 'unarchive'
                }
            ].concat(actions)
        }
        if (component.status === "completed") {
            actions = [
                {
                    text: 'Reactivate board',
                    action: askForReactivateConfirmation,
                    id: 'reactivate'
                }
            ].concat(actions)
        }
        if (component.status === "active") {
            actions = [
                {
                    text: 'Mark board complete',
                    action: askForCompleteConfirmation,
                    id: 'complete'
                }
            ].concat(actions)
        }
        if (component.status === "active") {
            actions = [
                {
                    text: 'Archive board',
                    action: askForArchiveConfirmation,
                    id: 'archive'
                }
            ].concat(actions)
        }
        return actions
    }
    const askForArchiveConfirmation = () => {
        setConfirmMenuContent("Archive board?  Board will be hidden in the sidebar and only shown in relevant locations.  You can restore it later.")
        setConfirmMenuAction(() => archiveBoard)
        setIsShowingConfirmMenu(true)
    }
    const askForUnarchiveConfirmation = () => {
        setConfirmMenuContent("Restore board from archive?")
        setConfirmMenuAction(() => restoreBoard)
        setIsShowingConfirmMenu(true)
    }
    const askForReactivateConfirmation = () => {
        setConfirmMenuContent("Reactivate board?")
        setConfirmMenuAction(() => restoreBoard)
        setIsShowingConfirmMenu(true)
    }
    const askForCompleteConfirmation = () => {
        setConfirmMenuContent("Mark board as complete?")
        setConfirmMenuAction(() => completeBoard)
        setIsShowingConfirmMenu(true)
    }
    const askForListConfirmation = () => {
        setConfirmMenuContent("Transform this board into a list?")
        setConfirmMenuAction(() => transformToList)
        setIsShowingManageSettingsMenu(false)
        setIsShowingConfirmMenu(true)
    }
    const askForDeleteConfirmation = () => {
        setConfirmMenuContent("Are you sure you want to permanently delete this board?\n\nAll tasks, assignments, and comments will be deleted with it.\n\nThis action cannot be reversed. It is permanent.")
        setConfirmMenuAction(() => deleteBoard)
        setIsShowingConfirmMenu(true)
    }
    const askForTemplateConfirmation = () => {
        setIsShowingManageSettingsMenu(false)
        setIsShowingCreateWorkstreamTemplateModal(true)
    }
    const canCreateNewTask = () => {
        if (!componentData.component) return false
        if (!hasPermission(permissions, 'component_create')) return false
        if (componentData.component.status === 'active') return true
        return false
    }
    const updateBoard = (payload) => {
        const path = `/workspace/components/${component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const hideProgressBar = () => {
        try {
            if (component.attributes.hide_progress_bar) return true
            return false
        } catch (error) {
            return false
        }
    }
    const displayCreateTaskForm = () => {
        setShowCreateTaskForm(true)
    }
    const handleLottiePlayer = (event) => {
        if (event === "complete") setCelebrate(false)
    }

    const calculateRealtimeProgress = () => {
        try {
          if (!columnData || columnData.length === 0) return
          const copyOfColumns = Array.from(columnData)
          let totalTasks = 0
          let completed = 0
          for (let i = 0; i < copyOfColumns.length; i++) {
            const col = copyOfColumns[i];
            if (col._id === componentData.component.attributes.column_id_done) {
              completed = col.tasks.length
            }
            totalTasks += col.tasks.length
          }
          if (totalTasks === 0) return setRealtimeProgress(0)
          return setRealtimeProgress(Math.round(completed/totalTasks*100, 2))
        } catch (error) {
          return
        }
    }
    const addNewColumnToBoard = (columnOrderOrigin) => {
        const payload = {
            order_origin: columnOrderOrigin,
            name: "New Column",
            component_id: componentData.component._id
        }
        api.createBoardColumn(payload)
        .then((res) => {
        fetchBoardData();
        })
        .catch((err) => {
            console.log(err)
        })
    }
    const removeBoardColumn = (columnId) => {
        const payload = {
            column_id: columnId
        }
        api.removeBoardColumn(payload)
        .then((res) => {
            fetchBoardData();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const removePendingColumnUpdate = (stamp) => {
        let pendingColumnUpdatesCopy = Array.from(pendingColumnUpdates)
        const updatedUpdates = pendingColumnUpdatesCopy.filter((update) => {
            if (update.stamp === stamp) return false
            return true
        })
        setPendingColumnUpdates(updatedUpdates)
    }
    const handlePendingColumnUpdates = () => {
        if (!pendingColumnUpdates) return
        if (pendingColumnUpdates.length === 0) return
        if (isSubmittingColumnUpdates) return
        setIsSubmittingColumnUpdates(true)
        const currentPendingUpdates = Array.from(pendingColumnUpdates)
        for (let i = 0; i < currentPendingUpdates.length; i++) {
            const update = currentPendingUpdates[i];
            updateColumn(update.payload)
            removePendingColumnUpdate(update.stamp)
        }
        setIsSubmittingColumnUpdates(false)
    }
    const sendQueuedTaskUpdates = () => {
        setIsSubmittingCardUpdates(true)
        const activeCardUpdateSetCopy = Array.from(activeCardUpdateSet)
        api.updateMultipleComponents({
            bulk_updates: activeCardUpdateSetCopy,
            workspace_id: componentData.component.workspace_id
        })
        .then((res) => {
            setActiveCardUpdateSet([])
            setLastUpdateTime(Date.now())
            setIsSubmittingCardUpdates(false)
            setActiveCardUpdateSetIsSpinning(false)
        })
        .catch((err) => {
            console.log(err)
            setActiveCardUpdateSet([])
            setLastUpdateTime(Date.now())
            setIsSubmittingCardUpdates(false)
            setActiveCardUpdateSetIsSpinning(false)
        })
    }
    const updateColumn = (payload) => {
        api.updateBoardColumn(payload)
        .then((res) => {
            if (!isSubmittingColumnUpdates) fetchBoardData()
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const updateTask = (payload, taskId) => {
        const path = `/workspace/components/${taskId}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchBoardData();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const deleteTask = (taskId) => {
        setIsEditingModal(false)
        const payload = {
            componentId: taskId
        }
        api.deleteWorkspaceComponent(payload)
        .then((res) => {
            fetchBoardData();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const getColumnById = (columnId) => {
    return columnData.find(x => x._id === columnId)
    }
    const replaceColumnById = (columnId, replacementData) => {
        const copyOfColumnData = Array.from(columnData);
        const colIndex = copyOfColumnData.findIndex(x => x._id === columnId);
        copyOfColumnData[colIndex] = replacementData
        setColumnData(copyOfColumnData)
    }
    const updateColumnTasks = (oldParentColumnId, newParentColumnId, sourceIndex, destinationIndex, taskId) => {
        // Deterministically get the EFFECTIVE INDEX based on the taskId because if there is a search term it isn't clear what is being dragged
        setIsEditingCards(!isEditingCards)
        // Rearrange and update the single column
        if (oldParentColumnId === newParentColumnId) {
            // build effective version of the array
            const items = Array.from(getColumnById(newParentColumnId).tasks)
            const [reorderedItem] = items.splice(sourceIndex, 1);
            items.splice(destinationIndex, 0, reorderedItem);

            // temp update the array in the state variable
            const copyOfSpecificColumn = getColumnById(newParentColumnId)
            copyOfSpecificColumn['tasks'] = items;
            replaceColumnById(newParentColumnId, copyOfSpecificColumn);

            // update task positions
            const positionUpdatePayload = []
            for (let i=0; i<items.length; i++) {
            const taskInfo = items[i];
            positionUpdatePayload.push({
                id: taskInfo._id,
                update: {position: i}
            })
            }
            setPendingCardUpdateQueue([...pendingCardUpdateQueue, positionUpdatePayload ])
        // Remove from the existing column, add to new column, and update both columns
        } else {
            // Remove the oldParentColumn
            const oldColumnTasks = Array.from(getColumnById(oldParentColumnId).tasks)
            let effectiveSourceIndex = sourceIndex // this might be different if a filter is hiding some tasks
            if (taskId && boardFilter) {
            if (boardFilter.query) {
                if (boardFilter.query.trim() !== "") {
                const existingTaskItem = oldColumnTasks.filter(t => t._id === taskId)
                if (existingTaskItem.length === 1) {
                    const foundIndex = oldColumnTasks.indexOf(existingTaskItem[0])
                    if (foundIndex > -1) effectiveSourceIndex = foundIndex
                }
                }

            }

            }
            const [movedTask] = oldColumnTasks.splice(effectiveSourceIndex, 1)
            // Add to the newParentColumn
            const newColumnTasks = Array.from(getColumnById(newParentColumnId).tasks)
            newColumnTasks.splice(destinationIndex, 0, movedTask);

            // Temp update the arrays in the state variable IF an update isn't already happening
            // this is cosmetic only
            const copyOfOldColumn = getColumnById(oldParentColumnId)
            copyOfOldColumn['tasks'] = oldColumnTasks;
            const copyOfNewColumn = getColumnById(newParentColumnId)
            copyOfNewColumn['tasks'] = newColumnTasks;
            replaceColumnById(oldParentColumnId, copyOfOldColumn);
            replaceColumnById(newParentColumnId, copyOfNewColumn);

            // Update the task positions
            const positionUpdatePayload = [];
            for (let j = 0; j < oldColumnTasks.length; j++) {
            const taskInfo = oldColumnTasks[j]
            positionUpdatePayload.push({
                id: taskInfo._id,
                update: {position: j}
            })
            }
            for (let k = 0; k < newColumnTasks.length; k++) {
            const taskInfo = newColumnTasks[k];
            positionUpdatePayload.push({
                id: taskInfo._id,
                update: {position: k, attributes: {...taskInfo.attributes, state: newParentColumnId}}
            })
            }
            setPendingCardUpdateQueue([...pendingCardUpdateQueue, positionUpdatePayload ])
        }
    }
    const allowDragEvents = (start) => {
    if (start.type === "cards") setIsEditingCards(true)
    setIsUpdatingColumnOrders(true)
    }
    const handleDragEvents = (result) => {
    if (!hasPermission(permissions, 'component_update')) return
    // if the dropped location doesn't exist - AKA outside the bounds
    if (!result.destination) return;
    // Responsible for reassigning a lot of different things
    setIsUpdatingColumnOrders(true) // prevents fetch-interrupts
    try {
        // Update database entries
        if (result.type === 'columns') {
        // if the dragged item is one of the protected columns don't do anything
        if ([boardData.attributes.column_id_new, boardData.attributes.column_id_done].includes(result.draggableId)) return;

        // if the destination is the very beginning or very end don't do anything
        if (result.destination.index === 0 || result.destination.index >= columnData.length-1) return;

        // if the destination is the same as the source don't do anything
        if (result.destination.index === result.source.index) return;

        // Submit an update
        const items = Array.from(columnData)
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setColumnData(items)
        let payloadSet = []
        for (let i=0; i<items.length; i++) {
            payloadSet.push({
            payload: {
                column_id: items[i]._id,
                order: i
            },
            stamp: Date.now()
            })
        }
        setPendingColumnUpdates(payloadSet)
        setIsUpdatingColumnOrders(false) // prevents fetch-interrupts
        }
        
        if (result.type === 'cards') {
        setIsEditingCards(true)
        const taskId = result.draggableId;
        const newParentColumnId = result.destination.droppableId;
        const prevParentColumnId = result.source.droppableId;
        const newIndex = result.destination.index;
        const prevIndex = result.source.index;
        const isDoneColumnId = boardData.attributes.column_id_done;

        // if the dragged column is the same as the original column rearrange index
        if (newParentColumnId === prevParentColumnId) {
            if (newIndex === prevIndex) {
            return;
            } else {
            updateColumnTasks(prevParentColumnId, newParentColumnId, prevIndex, newIndex, taskId)
            }
            setIsEditingCards(false)
            return;
        }

        // if the card is moved out of the isDoneColumnId update location and status
        if (isDoneColumnId === prevParentColumnId) {
            updateColumnTasks(prevParentColumnId, newParentColumnId, prevIndex, newIndex, taskId)
            updateTask({status: 'active', completed: "", attributes: {active: true, date_completed: "", progress: 0, state: newParentColumnId} }, taskId)
            setIsEditingCards(false)
            return;
        }

        // if the card is moved into the isDoneColumnId update the location and status
        if (isDoneColumnId === newParentColumnId) {
            setCelebrate(true)
            updateColumnTasks(prevParentColumnId, newParentColumnId, prevIndex, newIndex, taskId)
            updateTask({status: 'completed', completed: new Date(), attributes: {active: false, date_completed: new Date(), progress: 1, state: newParentColumnId} }, taskId)
            setIsEditingCards(false)
            return;
        }
        // assume all other cases are moving from one non-done column to another non-done column
        updateColumnTasks(prevParentColumnId, newParentColumnId, prevIndex, newIndex, taskId)
        setIsEditingCards(false)
        }

    } catch (error) {
        console.log(error)
        return
    } finally {
        setIsUpdatingColumnOrders(false)
        setIsEditingCards(false)
    }
    }
    const toggleEditPanel = (isEditing) => {
    if (isEditing) {
        setIsEditingModal(true);
    } else {
        setIsEditingModal(false)
        fetchBoardData()
    }
    }
    const publishTask = (payload) => {
        if (!payload.attributes.state) payload['attributes']['state'] = componentData.component.attributes.column_id_new;
        // payload['attributes']['state'] = componentData.component.attributes.column_id_new;
        payload['attributes']['position'] = 0;
        payload['workspace_id'] = componentData.component.workspace_id;
        const path = `/components/work/task`;
        api.createWorkspaceComponent(payload, path)
        .then( (res) => {
            fetchBoardData();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const hideCreateTaskForm = () => {
        setShowCreateTaskForm(false)
    }
    const finishMoving = () => {
        setIsMovingModal(false)
    }
    const triggerMoveAction = () => {
        setIsEditingModal(false)
        setIsMovingModal(true)
    }
    const getExistingPositions = () => {
        try {
            const newColumnData = columnData.find(c => c._id === componentData.component.attributes.column_id_new)
            const tasksInColumn = newColumnData.tasks
            const positions = tasksInColumn.map(a => a.position)
            return positions
        } catch (error) {
            return false
        }
    }
    const getHighestOrder = () => {
        try {
            let columnsWithoutDone = columnData.filter((c) => {
            if (c._id === componentData.component.attributes.column_id_done) return false
            return true
            })
            const columnOrders = columnsWithoutDone.map(c => c.order)
            const highestRemainingOrder = Math.max(...columnOrders)
            return highestRemainingOrder
        } catch (error) {
            return 0
        }
    }
    const sortColumnByParameters = (column, params) => {
        try {
            if (!hasPermission(permissions, 'component_update')) return
            let ascending = params.is_ascending
            let sortMethod = params.sort_by
            const copyOfTasks = Array.from(column.tasks)
            let items = copyOfTasks
            // Rearrange
            if (sortMethod === "date") {
                items = sortByDateStringAttribute(copyOfTasks, ascending, "display_name")
            }
            if (sortMethod === "name") {
                if (ascending) items = sortByProperty(copyOfTasks, "display_name")
                if (!ascending) items = sortByPropertyReverse(copyOfTasks, "display_name")
            }
            if (sortMethod === "age") {
                items = sortByDateProperty(copyOfTasks, "createdAt", ascending)
            }
    
            // temp update the array in the state variable
            const copyOfSpecificColumn = getColumnById(column._id)
            copyOfSpecificColumn['tasks'] = items;
            replaceColumnById(column._id, copyOfSpecificColumn);

            // update task positions
            const positionUpdatePayload = []
            for (let i=0; i<items.length; i++) {
            const taskInfo = items[i];
            positionUpdatePayload.push({
                id: taskInfo._id,
                update: { position: i }
            })
            }
            setPendingCardUpdateQueue([...pendingCardUpdateQueue, positionUpdatePayload ])
        } catch (error) {
            return
        }




    }
    useEffect(() => {
        fetchBoardData()
    // eslint-disable-next-line
    }, [cardLimit])
    useEffect(() => {
        setIsLoadingBoardData(true)
        fetchBoardData()
    // eslint-disable-next-line
    }, [boardId])
    useEffect(() => {
        if (!componentData.component) return
        if (componentData.component._id !== boardId) setBoardId(componentData.component._id)
    // eslint-disable-next-line
    }, [componentData])
    useEffect(() => {
        if (isSubmittingColumnUpdates) return
        handlePendingColumnUpdates()
    // eslint-disable-next-line
    }, [pendingColumnUpdates])
    useEffect(() => {
        // Delay calling until more updates are stuffed
        if (pendingCardUpdateQueue.length === 0) return
        lastPendingUpdateTime.current = Date.now()
        const timer = setTimeout(() => {
            if (Date.now() - lastPendingUpdateTime.current < 900 || activeCardUpdateSetIsSpinning) return
            if (!activeCardUpdateSetIsSpinning) {
            // transfer the queued updates to the active updates to spin (if no spinning is happening)
            setActiveCardUpdateSetIsSpinning(true) 
            setActiveCardUpdateSet(pendingCardUpdateQueue)
            setPendingCardUpdateQueue([])
            }
        }, 1000)
        return () => clearTimeout(timer)
    // eslint-disable-next-line
    }, [pendingCardUpdateQueue, activeCardUpdateSetIsSpinning])
    useEffect(() => {
        if (activeCardUpdateSet.length === 0) return
        sendQueuedTaskUpdates()
    // eslint-disable-next-line
    }, [activeCardUpdateSet])
    useEffect(() => {
        if (!lastUpdateTime) return
        lastCardUpdate.current = Date.now()
        const timer = setTimeout(() => {
            if (Date.now() - lastCardUpdate.current < 9000) return
            fetchBoardData()
        }, 10000)
        return () => clearTimeout(timer)
    // eslint-disable-next-line
    }, [lastUpdateTime])
    useEffect(() => {
        calculateRealtimeProgress()
    // eslint-disable-next-line
    }, [columnData])

    useEffect(() => {
        if (!component) return
        if (component._id === boardId) return
        setBoardId(component._id)
        // if (Date.now() - titleLastUpdatedTimestamp.current > 900) setDisplayName(component.display_name ? component.display_name : "")
    // eslint-disable-next-line
    }, [componentData])
    useEffect(() => {
        fetchComponent()
    // eslint-disable-next-line
    }, [component._id])
    useEffect(() => {
        let peopleFilter = []
        let termFilter = false
        if (filteredOutTeammates) peopleFilter = filteredOutTeammates
        if (searchText.trim() || searchText.trim() !== "") termFilter = searchText.trim()
        setBoardFilter({people: peopleFilter, query: termFilter})
    }, [filteredOutTeammates, searchText])
    useEffect(() => {
        if (isShowingConfirmMenu) setIsShowingManageSettingsMenu(false)
    }, [isShowingConfirmMenu])
    return (
        <div className="comp-view-flex-container-board">
            <div className="comp-view-main">
                {isShowingCreateWorkstreamTemplateModal && <ModalCreateWorkstreamTemplate name="board" isTemplatizing={isTemplatizing} hideModal={() => setIsShowingCreateWorkstreamTemplateModal(false)} createTemplate={templatizeBoard} /> }
                {isShowingConfirmMenu && <ConfirmAction mainText={confirmMenuContent} onconfirm={confirmMenuAction} hideMe={hideConfirmPopup} />}
                {isShowingManageSettingsMenu && hasPermission(permissions, 'component_update') && <ModalWorkstreamSettings permissions={permissions} team={team} componentData={componentData} updateWorkstream={updateBoard} hideModal={() => setIsShowingManageSettingsMenu(false)} type="board" transformToList={askForListConfirmation}/>}


                <div className="component-page-row" style={{alignItems: "center", justifyContent: "space-between", paddingLeft: "2px", marginTop: "12px", marginBottom: "14px"}}>
                    <ContextBreadcrumb permissions={permissions} component={component} componentData={componentData} workspaceName={workspaceName} />
                    <div className="util-row util-align-center" style={{gap: "12px"}}>
                        <div className={`component-view-title-meta-status-${component.status}`}>{component.status}</div>
                        {hasPermission(permissions, "component_update") &&
                            <SimpleListActionMenu actionsList={getActionList()}/>
                        }
                    </div>
                    
                </div>
                <div className="component-page-row" style={{alignItems: "center", justifyContent: "space-between", marginBottom: "18px", gap: "20px"}}>
                    <div className="component-page-row" style={{alignItems: "center", gap: "8px", flexGrow: 1, position: "relative"}}>
                        {!hideProgressBar() &&
                        <div style={{minWidth: "52px", minHeight: "52px", cursor: "pointer"}} onClick={() => setCelebrate(true)}>
                            {!celebrate && <CProgressBar extraStyles={{fontSize: "12px"}} height={48} percentage={realtimeProgress} />}
                            {celebrate && <Player autoplay loop={false} src={lottieCelebrationFile} style={{height: "48px", width: "48px"}} onEvent={handleLottiePlayer} speed={1.5}/>}
                        </div>
                        }
                        <EditableComponentTitle permissions={permissions} component={component} activeComponentId={boardId} fetchComponent={fetchComponent}/>
                    </div>
                    <div className="component-page-row" style={{alignItems: "stretch", gap: "16px"}}>
                        {/* New Task */}
                        {canCreateNewTask() && <div className="workcomp-board-control-panel-create-button" onClick={displayCreateTaskForm}><IoAdd/> <span className="component-workstream-responsive">New Task</span></div>}
                        {/* Search */}
                        <div className="workcomp-board-control-panel-search-container">
                            <IoSearchOutline/>
                            <input type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder="Search" />
                        </div>
                        {/* Filter */}
                        <ComponentUserFilterButton filteredOutTeammates={filteredOutTeammates} setFilteredOutTeammates={setFilteredOutTeammates} team={team} />
                        {/* Settings */}
                        {hasPermission(permissions, "component_update") && <div className="workcomp-board-control-panel-symbol-button" onClick={() => setIsShowingManageSettingsMenu(true)}><IoSettingsOutline/></div>}
                    </div>            
                </div>

                {canCreateNewTask() && 
                    <div>
                        <CreateNewTaskMegabar fetchParent={fetchBoardData} existingPositions={getExistingPositions()} parentComponent={componentData.component}/>
                    </div>
                }

                <div className="workspace-panel-view-holder">
                    <div>
                        {isLoadingBoardData ? (
                            <div style={{display: "flex", width: "100%", justifyContent: "center", marginTop: "15%"}}>
                            <LoadingSymbolGrid/>
                            </div>
                        ) : (
                            <DragDropContext onDragStart={allowDragEvents} onDragEnd={handleDragEvents}>
                    
                            <Droppable droppableId="columns" direction='horizontal' type='columns'>
                                {(provided) => (
                    
                                <div className="workspace-board-container" {...provided.droppableProps} ref={provided.innerRef}>
                    
                                    {isEditingModal && <ModalFullScreenEditTask componentData={componentData} permissions={permissions} task={selectedTask} fetchBoard={fetchBoardData} setIsEditingCard={toggleEditPanel} team={team} updateTask={updateTask} deleteTask={deleteTask} openMoveModal={triggerMoveAction}/>}
                                    {isMovingModal && <ModalMoveTaskToNewParent task={selectedTask} finishMoving={finishMoving} updateTask={updateTask} />}
                                    {showCreateTaskForm && <NewComponentTask parentId={componentData.component._id} team={team} hideCreateTaskForm={hideCreateTaskForm} createTask={publishTask} parentComponent={componentData.component} states={columnData}/>}
                    
                                    {columnData.map((column, index) => (
                                        <WorkspaceCompBoardColumn setCardLimit={setCardLimit} boardFilter={boardFilter} permissions={permissions} board={boardData} index={index} columnTitle={column.name} key={column._id} data={column} team={team} fetchBoard={fetchBoardData} updateTask={updateTask} addColumn={addNewColumnToBoard} removeBoardColumn={removeBoardColumn} updateColumn={updateColumn} setSelectedTask={setSelectedTask} setIsEditingModal={toggleEditPanel} getHighestOrder={getHighestOrder} sortColumnByParameters={sortColumnByParameters} />
                                    ))}
                                    {provided.placeholder}
                    
                                </div>
                            )}
                            </Droppable>
                            </DragDropContext>
                        )}
                    </div>
                </div>

            </div>

        </div>
    );
}