import React from 'react'
import { format, startOfMonth, parseISO, addDays } from 'date-fns'

export default function ComponentTimelineHeader({keyDate, dateRange, setKeyDate, getColumnStyle}) {
    const getMonthDecoration = (formattedDateString) => {
        const startOfMonthFromDate = startOfMonth(addDays(parseISO(formattedDateString),2));
        if (formattedDateString === format(startOfMonthFromDate, 'yyyy-MM-dd')) return format(addDays(startOfMonthFromDate,2), 'LLL yyyy')
        if (formattedDateString === format(addDays(keyDate,2), 'yyyy-MM-dd')) return format(addDays(keyDate,2), 'LLL yyyy')
        return ""
    }
    const getAdditionalDateStyle = (formattedDateString) => {
        if (formattedDateString === format(addDays(keyDate,2), 'yyyy-MM-dd')) return {backgroundColor: "rgb(37, 77, 151)", color: "white", fontWeight: "500"}
    }
    return (
        <div className="component-timeline-content-heading-container">
            {dateRange.map((dateString) => (
                <div className="component-timeline-content-heading-col component-timeline-content-col-width" key={dateString} style={getColumnStyle(dateString)}>
                    <div className="component-timeline-content-heading-month-slot"><span>{getMonthDecoration(dateString)}</span></div>
                    <div className="component-timeline-content-heading-day-number-slot" onClick={() => setKeyDate(parseISO(dateString))}>
                        <div>{format(parseISO(dateString), 'EEEEE')}</div>
                        <span style={getAdditionalDateStyle(dateString)}>{format(parseISO(dateString), 'd')}</span>
                    </div>
                </div>
            ))}            
        </div>
    )
}
