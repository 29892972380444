import React from 'react';
import { useState, useEffect } from 'react'
import ProfPicCircle from '../../profiles/ProfPicCircle';
import { getHandleFromId, getUserNameFromId } from '../../../common/Helpers';
import { isAfter, parseISO, isBefore, startOfToday, endOfToday, isValid, addDays } from 'date-fns'

export default function DashboardMain({data, team, goToUserPanel}) {
    const [rawDataCopy, setRawDataCopy] = useState(() => { return [] })
    const [rawComponentsCopy, setRawComponentsCopy] = useState(() => { return [] })
    const [organizedData, setOrganizedData] = useState(() => { return [] })
    const [unassignedData, setUnassignedData] = useState(() => { return [] })
    const organizeData = () => {
        try {
            if (rawDataCopy.length === 0) return
            let updatedOrganizedData = []
            const copyOfRawData = Array.from(rawDataCopy)
            for (let i = 0; i < copyOfRawData.length; i++) {
                const userAssignments = copyOfRawData[i];
                const filteredUserAssignments = userAssignments.assignments.filter((task) => {
                    if (task.parent) {
                        if (task.parent.status === "archived") return false
                    }
                    return true
                })
                const activeUserAssignmentsWithDate = filteredUserAssignments.filter((task) => {
                    if (task.status !== "active") return false
                    if (!task.attributes) return false
                    if (!task.attributes.date_due) return false
                    if (task.attributes.date_due === "") return false
                    if (isValid(parseISO(task.attributes.date_due))) return true
                    return false
                })
                const activeUserAssignmentsWithoutDate = filteredUserAssignments.filter((task) => {
                    if (task.status !== "active") return false
                    if (!task.attributes) return true
                    if (!task.attributes.date_due) return true
                    if (task.attributes.date_due === "") return true
                    if (isValid(parseISO(task.attributes.date_due))) return false
                    return true
                })
                const overdue = activeUserAssignmentsWithDate.filter((task) => {
                    if (isBefore(parseISO(task.attributes.date_due), startOfToday())) return true
                    return false
                })
                const dueToday = activeUserAssignmentsWithDate.filter((task) => {
                    if (isAfter(parseISO(task.attributes.date_due), endOfToday())) return false
                    if (isBefore(parseISO(task.attributes.date_due), startOfToday())) return false
                    return true
                })
                const thisWeek = activeUserAssignmentsWithDate.filter((task) => {
                    if (isBefore(parseISO(task.attributes.date_due), startOfToday())) return false
                    if (isBefore(parseISO(task.attributes.date_due), endOfToday())) return false
                    if (isAfter(parseISO(task.attributes.date_due), addDays(endOfToday(), 7))) return false
                    return true
                })
                updatedOrganizedData.push({
                    _id: userAssignments._id,
                    profile_picture_url: userAssignments.profile_picture_url,
                    overdue: overdue.length,
                    dueToday: dueToday.length,
                    thisWeek: thisWeek.length,
                    noDueDate: activeUserAssignmentsWithoutDate.length
                })
            }
            setOrganizedData(updatedOrganizedData)
        } catch (error) {
            return
        }
    }
    const getAdditionalOverdueStyle = (totalOverdue) => {
        try {
            if (totalOverdue > 0) return { backgroundColor: "rgba(255, 0, 0, 0.094)"}
        } catch (error) {
            return {}
        }
    }
    const getAdditionalNextDaysStyle = (totalNextDays) => {
        try {
            if (totalNextDays > 7) return { backgroundColor: "rgba(255, 255, 0, 0.178)"}
        } catch (error) {
            return {}
        }
    }
    const updateUnassigned = () => {
        try {
            if (rawComponentsCopy.length === 0) return
            const copyOfAllComponents = Array.from(rawComponentsCopy)
            let updatedUnassignedData = []
            const activeComponentsNoOwner = copyOfAllComponents.filter((component) => {
                if (component.status !== "active") return false
                if (component.name !== "task") return false
                if (component.owner_id === "") return true
                if (!component.owner_id) return true
                return false
            })
            const activeUserAssignmentsWithDate = activeComponentsNoOwner.filter((task) => {
                if (!task.attributes) return false
                if (!task.attributes.date_due) return false
                if (task.attributes.date_due === "") return false
                if (isValid(parseISO(task.attributes.date_due))) return true
                return false
            })
            const activeUserAssignmentsWithoutDate = activeComponentsNoOwner.filter((task) => {
                if (task.status !== "active") return false
                if (!task.attributes) return true
                if (!task.attributes.date_due) return true
                if (task.attributes.date_due === "") return true
                if (isValid(parseISO(task.attributes.date_due))) return false
                return true
            })
            const overdue = activeUserAssignmentsWithDate.filter((task) => {
                if (isBefore(parseISO(task.attributes.date_due), startOfToday())) return true
                return false
            })
            const dueToday = activeUserAssignmentsWithDate.filter((task) => {
                if (isAfter(parseISO(task.attributes.date_due), endOfToday())) return false
                if (isBefore(parseISO(task.attributes.date_due), startOfToday())) return false
                return true
            })
            const thisWeek = activeUserAssignmentsWithDate.filter((task) => {
                if (isBefore(parseISO(task.attributes.date_due), startOfToday())) return false
                if (isBefore(parseISO(task.attributes.date_due), endOfToday())) return false
                if (isAfter(parseISO(task.attributes.date_due), addDays(endOfToday(), 7))) return false
                return true
            })
            updatedUnassignedData.push({
                _id: false,
                profile_picture_url: false,
                overdue: overdue.length,
                dueToday: dueToday.length,
                thisWeek: thisWeek.length,
                noDueDate: activeUserAssignmentsWithoutDate.length
            })

            setUnassignedData(updatedUnassignedData)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if (!data) return
        if (data.assignments) setRawDataCopy(Array.from(data.assignments))
        if (data.all) setRawComponentsCopy(Array.from(data.all))
    // eslint-disable-next-line
    }, [data])
    useEffect(() => {
        organizeData()
    // eslint-disable-next-line
    }, [rawDataCopy])
    useEffect(() => {
        updateUnassigned()
    // eslint-disable-next-line
    }, [rawComponentsCopy])
    return (
    <div>
        <div className="table-main-row table-main-row-header">
            <span className="table-main-col table-main-col-5"></span>
            <span className="table-main-col table-main-col-main">User</span>
            <span className="table-main-col table-main-col-15">Overdue</span>
            <span className="table-main-col table-main-col-15">Due Today</span>
            <span className="table-main-col table-main-col-15">Next 7 Days</span>
            <span className="table-main-col table-main-col-15">No Due Date</span>
        </div>
        {organizedData.map((teammate) => (
        <div className="table-main-row table-main-row-content table-main-row-content-clickable" key={teammate._id} onClick={() => goToUserPanel(teammate._id)}>
            <span className="table-main-col table-main-col-5">
                <ProfPicCircle height={40} src={teammate.profile_picture_url} userId={teammate._id} userName={getUserNameFromId(team, teammate._id)}/>
            </span>
            <span className="table-main-col table-main-col-main">
                <div>
                    <div style={{fontSize: "16px", fontWeight: 700}}>{getUserNameFromId(team, teammate._id)}</div>
                    <div>@{getHandleFromId(team, teammate._id)}</div>
                </div>
            </span>
            <span className="table-main-col table-main-col-15" style={getAdditionalOverdueStyle(teammate.overdue)}>{teammate.overdue}</span>
            <span className="table-main-col table-main-col-15">{teammate.dueToday}</span>
            <span className="table-main-col table-main-col-15" style={getAdditionalNextDaysStyle(teammate.thisWeek)}>{teammate.thisWeek}</span>
            <span className="table-main-col table-main-col-15">{teammate.noDueDate}</span>
        </div>
        ))}
        {unassignedData.map((teammate) => (
        <div className="table-main-row table-main-row-content table-main-row-content-clickable" key={teammate._id} onClick={() => goToUserPanel(teammate._id)}>
            <span className="table-main-col table-main-col-5">
                
            </span>
            <span className="table-main-col table-main-col-main">
                <div>
                    <div style={{fontSize: "16px", fontWeight: 700}}>Unassigned</div>
                    <div>@nobody</div>
                </div>
            </span>
            <span className="table-main-col table-main-col-15" style={getAdditionalOverdueStyle(teammate.overdue)}>{teammate.overdue}</span>
            <span className="table-main-col table-main-col-15">{teammate.dueToday}</span>
            <span className="table-main-col table-main-col-15" style={getAdditionalNextDaysStyle(teammate.thisWeek)}>{teammate.thisWeek}</span>
            <span className="table-main-col table-main-col-15">{teammate.noDueDate}</span>
        </div>
        ))}
    </div>
    );
}
