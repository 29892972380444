import React from 'react'
import { useState, useEffect } from "react";
import Api from '../../common/APIUtils';
import TemplateLibrary from '../templates/TemplateLibrary';

export default function NetworkTemplates({ network, fetchNetwork, permissions }) {
    let api = new Api()
    const [templates, setTemplates] = useState(() => { return [] })
    const [isLoadingTemplates, setIsLoadingTemplates] = useState(() => { return true })
    const fetchNetworkTemplates = () => {
        if (!network._id) return
        const payload = {network_id: network._id}
        api.getNetworkComponentTemplates(payload)
        .then((res) => {
            if (res.data) {
                setTemplates(res.data.response.templates)
                setIsLoadingTemplates(false)
            }
        })
        .catch((err) => {
            if (err.response) {
                if (err.response.status === 400) {
                    // Toast
                    console.log('Have a toast')
                } else {
                    // Toast
                    console.log('Have a toast')
                }
            }
            // Toast
            console.log('Have a toast')
        })
    }
    useEffect(() => {
        fetchNetworkTemplates()
    // eslint-disable-next-line
    }, [network])
    return (
        <div className="network-hub-page">

            <div className="network-hub-page-header-row">
                <div className="network-hub-page-header-text">Templates</div>
                <div className="network-page-header-actions">
                </div>
            </div>

            <TemplateLibrary isLoadingTemplates={isLoadingTemplates} templates={templates} team={[]} workspace={false} network={network} fetchTemplates={fetchNetworkTemplates} permissions={permissions} />
        </div>
    )
}
