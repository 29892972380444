import Header from "./components/Header";
import Login from "./components/Login";
import Home from "./components/Home";
import Register from "./components/Register";
import ForgotPassword from "./components/ForgotPassword";
import PasswordReset from "./components/PasswordReset";
import NewNetwork from "./components/networks/NewNetwork";
import NewNetworkItem from "./components/NewNetworkItem";
import Network from "./components/networks/Network";
import Workspace from "./components/Workspace";
import NewWorkspaceItem from "./components/NewWorkspaceItem";
import PublicComponent from "./components/public/PublicComponent";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Api from "./common/APIUtils";
import { useState, useEffect, useReducer } from 'react';
import DataManager from "./common/DataManager";
import ComponentMenu from "./components/ComponentMenu";
import UserProfile from "./components/UserProfile";
import MyDesk from "./components/mydesk/MyDesk";
import Feedback from "./components/app/Feedback";
import UserSettings from "./components/app/UserSettings";
import Redirect from "./components/Redirect";
import UserInvitationJoin from "./components/components/public/UserInvitationJoin";
import ProApplication from "./components/forms/public/ProApplication";
import PrivacyPolicy from "./components/PrivacyPolicy";
import LandingBase from "./components/landing/LandingBase";
// import Messages from "./components/messages/Messages";
import BlogLanding from "./components/blog/BlogLanding";
import BlogPost from "./components/blog/BlogPost";
import { authenticatedHeaderPaths } from "./common/AppConfigs";
import RegisterConfirmation from "./components/accounts/RegisterConfirmation";
import AccountPlans from "./components/accounts/AccountPlans";
import WizardOnboarding from "./components/wizards/WizardOnboarding";

import PromotionCalendarWizard from "./components/tools/PromotionCalendarWizard";
import PublicToolBase from "./components/tools/PublicToolBase";

function App() {
  const [authenticated, setAuthentication] = useState(null);
  const [userData, setUserData] = useState({});
  const [sidebarCollapsed, setSidebarCollapsed] = useState(() => { return false })
  const api = new Api(); 
  let dataManager = DataManager.getInstance();
  const initialUserAssignments = {
    loaded: false,
    is_loading: true,
    tasks: [],
    workspaces: [],
    notifications: []
  }
  const initialUserSettings = {
    tasks_auto_assign_owner: false,
    email_new_assignments: false,
    settings_initialized: false
  }
  const [userAssignments, userAssignmentsDispatch] = useReducer((state, action) => {
    if (action.type === "begin_fetch") return { ...state, is_loading: true }
    if (action.type === "update") {
      return { ...state,  tasks: action.value.tasks, workspaces: action.value.workspaces, notifications: action.value.notifications, loaded: true, is_loading: false }
    }
    if (action.type === "remove_task") {
      const updatedTasks = state.tasks.filter(t => t._id !== action.taskId)
      return { ...state, tasks: updatedTasks }
    }
    if (action.type === "update_due_date") {
      if (!state.tasks) return state
      const existingTasks = Array.from(state.tasks)
      const taskToUpdate = existingTasks.find(t => t._id === action.task_id)
      if (taskToUpdate) taskToUpdate.attributes.date_due = action.date_due
      return { ...state, tasks: existingTasks }
    }
    return state
  }, initialUserAssignments)
  const [userSettings, userSettingsDispatch] = useReducer((state, action) => {
    if (action.type === "init") {
      if (state.settings_initialized) return state
      if (!action.data) return state
      let newSettings = {
        tasks_auto_assign_owner: action.data.tasks_auto_assign_owner,
        email_new_assignments: action.data.email_new_assignments,
        email_new_mentions: action.data.email_new_mentions,
        settings_initialized: true
      }
      return { ...state, ...newSettings }
    }
    if (action.type === "update") {
      let update = {}
      update[action.name] = action.value
      return { ...state, ...update }
    }
  }, initialUserSettings)
  const fetchAuthStatus = () => {
    api.isAuthenticated()
    .then( (res) => {
        if (res.data.authenticated) {
          setUserData(res.data);
          setAuthentication(true);
          dataManager.setAuth(true);
        } else {
          setAuthentication(false);
          dataManager.setAuth(false);
          setUserData({});
          clearLocalStorage();
        }
    })
    .catch((err) => {
      setAuthentication(false);
      dataManager.setAuth(false);
    });
  }
  const clearLocalStorage = () => {
    localStorage.removeItem("gFirstName");
    localStorage.removeItem("gLastName");
    localStorage.removeItem("gFullName");
    localStorage.removeItem("gId");
    localStorage.removeItem("gHandle");
    localStorage.removeItem("auth");
    localStorage.removeItem("gHomeSidebar");
    localStorage.removeItem("gFeedbackInstructionsDismissed");
    localStorage.removeItem("gSidebarCollapsed");
    localStorage.removeItem("gProfPicUrl");
    localStorage.removeItem("gMyDeskView");
    localStorage.removeItem("gMyDeskAssignmentView");
    localStorage.removeItem("gLicenseTier");
    localStorage.removeItem("gLicenseStatus");
    localStorage.removeItem("gTrelloApiKey");
    localStorage.removeItem("gPrivWorkspaceCount");
    localStorage.removeItem("gPrivWorkspaceUserCount");
    localStorage.removeItem("gExpandedIds");
    localStorage.removeItem("gFeedFilterName");
    localStorage.removeItem("gHideChat");
    localStorage.removeItem("gChatSize");
    localStorage.removeItem("gChatDarkMode")
    localStorage.removeItem("gPinnedWorkspaceIds")
    localStorage.removeItem("gExpandedSidebar")
    localStorage.removeItem("gPlanInterest")
    localStorage.removeItem("gPlanAnnualInterest")
    localStorage.removeItem("gDeskView")
  }
  const logout = () => {
    setAuthentication(false);
    dataManager.setAuth(false);
    clearLocalStorage();
    api.logout()
    .then( (res) => {
      fetchAuthStatus();
    })
    .catch((err) => {
        console.log(err);
    });;
  }
  const updateUserStatus = (statusText) => {
    try {
      api.updateUserStatus({status: statusText})
      .then((res) => { 
        fetchAuthStatus()
      })
      .catch((err) => { return })
    } catch (error) {
      return
    }
  }
  const getUserAssignments = () => {
    if (!authenticated) return
    userAssignmentsDispatch({type: "begin_fetch"})
    api.getUserAssignments(localStorage.getItem("gId"))
    .then((res) => {
        if (res.data.response) {
          userAssignmentsDispatch({
            type: "update",
            value: {
              tasks: res.data.response.tasks,
              workspaces: res.data.response.workspaces,
              notifications: res.data.response.notifications
            }
          })
        }
    })
    .catch((err) => {
        console.log(err);
    })  
  }
  useEffect(() => {
    fetchAuthStatus();
    const interval = setInterval(() => {
      fetchAuthStatus();
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (!authenticated) return
    getUserAssignments()
  // eslint-disable-next-line
  },[])
  useEffect(() => {
    localStorage.setItem("gFirstName", userData.firstName);
    localStorage.setItem("gLastName", userData.lastName);
    localStorage.setItem("gFullName", `${userData.firstName} ${userData.lastName}`);
    localStorage.setItem("gHandle", userData.handle);
    localStorage.setItem("gId", userData.id);
    localStorage.setItem("gProfPicUrl", (userData.profile_picture_url ? userData.profile_picture_url : "") )
    localStorage.setItem("gLicenseTier", userData.license_tier)
    localStorage.setItem("gLicenseStatus", userData.license_status)
    localStorage.setItem("gPinnedWorkspaceIds", userData.pinned_workspace_ids)
    localStorage.setItem("gPrivWorkspaceCount", userData.private_workspace_limit)
    localStorage.setItem("gPrivWorkspaceUserCount", userData.private_workspace_user_limit)
    if (userData.settings && !userSettings.settings_initialized) userSettingsDispatch({ type: "init", data: userData.settings })
  // eslint-disable-next-line
  }, [userData])
  useEffect(() => {
    localStorage.setItem("gSidebarCollapsed", sidebarCollapsed)
  }, [sidebarCollapsed])
  return (
    <Router>
      <div className="container">

        <Route path={authenticatedHeaderPaths}>
          {authenticated && <Header authenticated={authenticated} logout={logout} userData={userData} updateStatus={updateUserStatus}/>}
        </Route>

        <Switch>

          <Route exact path="/">
            <Redirect authenticated={authenticated}/>
          </Route>

          <Route exact path="/tools/event-plan-generator">
            <PromotionCalendarWizard authenticated={authenticated}/>
          </Route>

          <Route exact path="/t/:toolId">
            <PublicToolBase authenticated={authenticated} />
          </Route>

          <Route exact path={["/welcome", "/pricing", "/use-cases", "/about"]}>
            <LandingBase authenticated={authenticated}/>
          </Route>

          <Route exact path="/accounts/register/confirm">
            <RegisterConfirmation />
          </Route>

          <Route exact path="/blog">
            <BlogLanding authenticated={authenticated}/>
          </Route>

          <Route exact path="/blog/:slug">
            <BlogPost />
          </Route>

          <Route exact path="/forms/licenses/:tier">
            <ProApplication authenticated={authenticated}/>
          </Route>

          <Route exact path="/login">
            <Login refreshAuthStatus={fetchAuthStatus} authenticated={authenticated}/>
          </Route>

          <Route exact path="/register">
            <Register refreshAuthStatus={fetchAuthStatus} />
          </Route>

          <Route exact path="/p/join">
            <UserInvitationJoin refreshAuthStatus={fetchAuthStatus} authenticated={authenticated}/>
          </Route>

          <Route path="/privacy-policy" exact component={PrivacyPolicy}/>

          <Route path="/forgot-password" exact component={ForgotPassword}/>

          <Route path="/reset-password" exact component={PasswordReset} />

          <Route exact path="/user/:userId">
            <UserProfile authenticated={authenticated} />
          </Route>

          <Route exact path="/u/:userHandle">
            <UserProfile authenticated={authenticated} />
          </Route>

          <Route exact path="/public/c/:componentId">
            <PublicComponent authenticated={authenticated} />
          </Route>

          {authenticated !== null && authenticated && [
            <Route key="network-create" exact path="/network/">
              <NewNetwork sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} userData={userData}/>
            </Route>,

            <Route key="account-plans" exact path="/account/plans">
              <AccountPlans userData={userData} />
            </Route>,

            <Route key="home-main" exact path="/home">
              {userData.onboarding_complete ? <Home sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} userData={userData} fetchAuthStatus={fetchAuthStatus} authenticated={authenticated} /> : <Redirect authenticated={authenticated} specificPath="/flows/setup" />}
            </Route>,

            <Route key="home-onboarding" exact path="/flows/setup">
              {!userData.onboarding_complete ? <WizardOnboarding logout={logout} refreshAuthStatus={fetchAuthStatus} /> : <Redirect authenticated={authenticated} specificPath="/home" />}
            </Route>,

            <Route key="network-item-create" exact path="/n/:networkSlug/:item/create">
              <NewNetworkItem sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed}/>
            </Route>,

            <Route key="network-home" exact path="/n/:networkSlug">
              <Network home={true} sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} userData={userData} refreshUserData={fetchAuthStatus}/>
            </Route>,

            <Route key="network-workspaces" exact path="/n/:networkSlug/:view">
              <Network sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} userData={userData} refreshUserData={fetchAuthStatus}/>
            </Route>,

            <Route key="network-workspace-component" exact path="/n/:networkSlug/c/:componentId">
              <Network component={true} sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} userData={userData} refreshUserData={fetchAuthStatus}/>
            </Route>,

            <Route key="workspace-home" exact path="/workspaces/:workspaceId">
              <Workspace sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} view='home'/>
            </Route>,

            <Route key="workspace-measurements" exact path="/workspaces/:workspaceId/measurements">
              <Workspace sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} view='measurements'/>
            </Route>,

            <Route key="workspace-library" exact path="/workspaces/:workspaceId/library">
              <Workspace sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} view='library'/>
            </Route>,

            <Route key="workspace-dashboards" exact path="/workspaces/:workspaceId/dashboards">
              <Workspace sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} view='dashboards'/>
            </Route>,

            <Route key="workspace-settings" exact path="/workspaces/:workspaceId/settings">
              <Workspace sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} view='settings'/>
            </Route>,

            <Route key="workspace-main" exact path="/workspaces/:workspaceId/c/:componentId">
              <Workspace sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} view='workspace'/>
            </Route>,

            <Route key="workspace-post-page" exact path="/workspaces/:workspaceId/p/:postId">
              <Workspace sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} view='post'/>
            </Route>,

            <Route key="workspace-tags" exact path="/workspaces/:workspaceId/t/:tagName">
              <Workspace sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} view='tags'/>
            </Route>,
            
            <Route key="workspace-catalog" exact path="/workspaces/:workspaceId/catalog">
              <ComponentMenu/>
            </Route>,

            <Route key="user-assignments" exact path="/desk">
              <MyDesk userAssignments={userAssignments} userAssignmentsDispatch={userAssignmentsDispatch} getUserAssignments={getUserAssignments} userData={userData}/>
            </Route>,

            <Route key="user-settings" exact path="/settings">
              <UserSettings userData={userData} refreshUserData={fetchAuthStatus} userSettings={userSettings} userSettingsDispatch={userSettingsDispatch}/>
            </Route>,

            // <Route key="user-messages" exact path="/messages">
            //   <Messages userData={userData} userAssignments={userAssignments} getUserAssignments={getUserAssignments} />
            // </Route>,

            <Route key="workspace-new" exact path="/workspaces/:workspaceId/new">
              <NewWorkspaceItem/>
            </Route>,

            <Route key="workspace-new-measurement" exact path="/workspaces/:workspaceId/measurements/new">
              <Workspace sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} view='measurements' subview='create'/>
            </Route>,

            <Route key="tetheros-feedback" exact path="/app/feedback">
              <Feedback/>
            </Route>,

            <Route key="authenticated-catch" path="*">
              <Redirect authenticated={authenticated}/>
            </Route>
          ]}
          <Route path="*">
            <Redirect authenticated={authenticated}/>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
