import React from 'react'
import { useParams, useHistory } from 'react-router-dom';
import { useState, useEffect, useReducer } from 'react';
import Api from '../../common/APIUtils'
import DataManager from '../../common/DataManager';
import '../../styles/network.css'
import { toCapitalize } from '../../common/Helpers';
import { MdVerified } from 'react-icons/md'

import NetworkWorkspaces from './NetworkWorkspaces';
import NetworkUsers from './NetworkUsers';
import NetworkTeams from './NetworkTeams';
import NetworkSettings from './NetworkSettings';
import NetworkTemplates from './NetworkTemplates';
import NetworkCatalog from './NetworkCatalog';
import NetworkBilling from './NetworkBilling';
import NetworkRoles from './NetworkRoles';

export default function Network({ sidebarCollapsed, setSidebarCollapsed, home, component, userData, fetchAuthStatus }) {
    const api = new Api();
    let history = useHistory();
    let dataManager = DataManager.getInstance();
    const { networkSlug, view } = useParams();
    const [networkUserData, networkUserDataDispatch] = useReducer((state, action) => {
        if (action.type === "init") return { loaded: true, ...action.data }
        // if (action.type === "init") return { ...state, loaded: true, ...action.data }
        return state
    }, {
        loaded: false,
        teams: [],
        roles: []
    })
    const [network, setNetwork] = useState(() => { return false })
    const [networkCapacity, setNetworkCapacity] = useState(() => { return 5 })
    const [permissions, setPermissions] = useState(() => { return [] })
    const [workspaces, setWorkspaces] = useState(() => { return {} })
    const [hubView, setHubView] = useState(() => { return "workspaces" })
    const getNavClassName = (existingNavClass) => {
        if (existingNavClass === hubView) return "network-hub-header-nav-sel"
        return ""
    }
    const fetchNetwork = () => {
        const payload = {
            networkSlug: networkSlug
        }
        api.getNetwork(payload)
        .then((res) => {
            if (res.data) {
                setNetwork(res.data.response.network)
                if (res.data.response.network_capacity) setNetworkCapacity(res.data.response.network_capacity)
                setWorkspaces(res.data.response.workspaces)
                if (res.data.response.permissions) setPermissions(res.data.response.permissions)
            }
        })
        .catch((err) => {
            if (err.response) {
                if (err.response.status === 400) {
                    history.push(`/home`)
                } else {
                    history.push(`/home`)
                }
            }
            history.push(`/home`)
        })  
    }
    const fetchNetworkUserData = () => {
        if (!network._id) return
        api.fetchNetworkUserData(network._id)
        .then((res) => {
            networkUserDataDispatch({type: "init", data: res.data })
        })
        .catch((err) => { console.log(err) })
    }
    const updateView = (newView) => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('v', newView);
        history.push(`${window.location.pathname}?${searchParams.toString()}`);
        setHubView(newView);
    };
    useEffect(() => {
        if (!dataManager.getAuth()) {
            history.push('/login');
            return
        }
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        fetchNetworkUserData()
    // eslint-disable-next-line
    }, [network])
    useEffect(() => {
        if (!network) {
            document.title = "Tetheros"
            return
        }
        if (!network.name) {
            document.title = "Tetheros"
            return
        }
        if (!view) {
            document.title = "Tetheros"
            return
        }
        document.title = `${toCapitalize(view)} | ${network.name}`
    // eslint-disable-next-line
    }, [view, network])
    useEffect(() => {
        const ALLOWED_VIEWS = ['workspaces', 'team', 'settings', 'users', 'templates', 'catalog', 'build', 'components', 'dashboards']
        if (!home && !component) {
            if (!ALLOWED_VIEWS.includes(view)) history.push(`/n/${networkSlug}`)
        }
        fetchNetwork()
    // eslint-disable-next-line
    }, [networkSlug, view, home, component])
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const initialView = searchParams.get('v') || 'workspaces'; // Use a default view if 'v' is not specified
        setHubView(initialView);
    }, []);
    return (
        <div className="network-hub">
            <div className="network-hub-header">
                <div className="network-hub-header-left">
                    {network.name}
                    <MdVerified />
                </div>
                <div style={{fontSize: "13px", fontWeight: 400, color: "grey" }}>Professional Network {network && `• ${network.memberships.length} members`}</div>
                <div className="network-hub-header-nav">
                    <span onClick={() => updateView("workspaces")} className={getNavClassName("workspaces")}>Workspaces</span>
                    <span onClick={() => updateView("users")} className={getNavClassName("users")}>Users</span>
                    <span onClick={() => updateView("team")} className={getNavClassName("team")}>Teams</span>
                    <span onClick={() => updateView("roles")} className={getNavClassName("roles")}>Roles</span>
                    <span onClick={() => updateView("templates")} className={getNavClassName("templates")}>Templates</span>
                    <span onClick={() => updateView("billing")} className={getNavClassName("billing")}>Billing</span>
                    <span onClick={() => updateView("settings")} className={getNavClassName("settings")}>Settings</span>
                </div>
            </div>

            {hubView === 'workspaces' && <NetworkWorkspaces network={network} permissions={permissions}/>}
            {hubView === 'users' && <NetworkUsers networkCapacity={networkCapacity} networkUserData={networkUserData} fetchNetworkUserData={fetchNetworkUserData} network={network} fetchNetwork={fetchNetwork} permissions={permissions}/>}
            {hubView === 'roles' && <NetworkRoles network={network} fetchNetwork={fetchNetwork} permissions={permissions}/>}
            {hubView === 'team' && <NetworkTeams networkUserData={networkUserData} networkWorkspaces={workspaces} network={network} fetchNetwork={fetchNetwork} permissions={permissions}/>}
            {hubView === 'templates' && <NetworkTemplates network={network} fetchNetwork={fetchNetwork} permissions={permissions}/>}
            {hubView === 'settings' && <NetworkSettings fetchNetwork={fetchNetwork} network={network} permissions={permissions}/>}
            {hubView === 'catalog' && <NetworkCatalog fetchNetwork={fetchNetwork} network={network} permissions={permissions}/>}
            {hubView === 'billing' && <NetworkBilling userData={userData} refreshUserData={fetchAuthStatus} network={network} /> }
            
        </div>
    )
}