import React from 'react'
import Api from '../../common/APIUtils';
import { useState } from 'react';
import ProfPicCircle from '../profiles/ProfPicCircle';
import ComponentComment from './sidepanes/ComponentComment';
import SimpleMentionInput from '../gadgets/SimpleMentionInput'

export default function ComponentMainComments({comments, componentId, fetchComponent, team}) {
    const [newComment, setNewComment] = useState(() => { return "" })
    const api = new Api();
    const handleKeyPress = (e) => {
        const code = e.code
        if (code === "Enter" || code === "NumpadEnter") createComment()
    }
    const createComment = () => {
        if (newComment) {
            const payload = {
                content: newComment,
                target_id: componentId,
                type: "component"
            }
            setNewComment("")
            api.createComponentComment(payload)
            .then((res) => {
                fetchComponent();
            })
            .catch((err) => {
                console.log(err);
            })
        }
    }
    const deleteComment = (commentId) => {
        const payload = {
            comment_id: commentId
        }
        api.deleteComponentComment(payload)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const updateComment = (commentId, content) => {
        const payload = {
            comment_id: commentId,
            content: content
        }
        api.updateComponentComment(payload)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err)
        })
    }
    const getUserProfPicUrl = () => {
        if (localStorage.getItem("gProfPicUrl")) {
            return localStorage.getItem("gProfPicUrl")
        } else {
            return false
        }
    }
    return (
        <div className="workcomp-main-comments-container">

            {/* Comment Input */}
            <div className="workcomp-main-comments-input workcomp-page-row-centered" style={{gap: "24px", marginTop: "12px"}}>
                <ProfPicCircle src={getUserProfPicUrl()} userId={localStorage.getItem("gId")} userName={localStorage.getItem("gFullName")} height={48}/>

                <div className="modal-full-screen-task-edit-comments-lower">
                    <SimpleMentionInput inputClassName="modal-full-screen-task-edit-comment-new" autoFocus={true} type="text" placeholderText="What would you like to say?" inputValue={newComment} setInputValue={setNewComment} handleInputPress={handleKeyPress} team={team}/>
                    <div className="modal-full-screen-task-edit-comment-post" onClick={createComment}>Post</div>
                </div>
            </div>


            {/* Comment Display */}
            <div className="workcomp-main-comments">
                {comments.reverse().map((comment) => (
                    <ComponentComment updateComment={updateComment} deleteComment={deleteComment} key={comment._id} comment={comment} team={team} />
                ))}
                {comments.length === 0 && <div style={{textAlign: "center"}}>Be the first to comment</div>}
            </div>
        </div>
    )
}
