import { useEffect } from 'react'
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { COMMAND_PRIORITY_NORMAL, PASTE_COMMAND } from 'lexical';

export default function LexicalImagePastePlugin({ handleMultimediaPasteAction }) {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        return editor.registerCommand(
            PASTE_COMMAND,
            (event) => {
                if (!handleMultimediaPasteAction) return false
                const data = event.dataTransfer
                if (!data) return false
                if (!data.files) return false
                const files = data.files
                if (files.length === 0) return false
                let imageFiles = []
                for (let i = 0; i < files.length; i++) {
                    if (files[i].type.startsWith("image")) imageFiles.push(files[i])
                }
                if (imageFiles.length === 0) return false
                handleMultimediaPasteAction(imageFiles)
                event.preventDefault()
                return true
            },
            COMMAND_PRIORITY_NORMAL
        )  
        // eslint-disable-next-line
        }, [editor])
    return null
}