import React from 'react'
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useState, useEffect } from 'react'
import Api from '../../../common/APIUtils';
import { IoIosCheckmarkCircle, IoIosCloseCircle } from 'react-icons/io'

export default function NewBoardImport({workspaceId}) {
    const history = useHistory()
    const api = new Api();
    const { hash } = useLocation()
    const [trelloBoards, setTrelloBoards] = useState(() => { return []})
    const [disableSubmission, setDisableSubmission] = useState(() => { return false })
    const [selectedBoardId, setSelectedBoardId] = useState(() => { return "" })
    const getAdditionalClass = (id) => {
        if (id === selectedBoardId) {
            return "form-template-selected"
        } else {
            return ""
        }
    }
    const getTokenFromUrl = () => {
        if (!hash) return history.push(`/${workspaceId}/new?layer=work&name=board`)
        return hash.replace('#token=','')
    }
    const getTrelloApiKey = () => {
        if (!localStorage.getItem("gTrelloApiKey")) return false
        return localStorage.getItem("gTrelloApiKey")
    }
    const fetchTrelloBoards = () => {
        const params = {
            api_key: getTrelloApiKey(),
            api_token: getTokenFromUrl()
        }
        api.getTrelloBoards(params)
        .then((res) => {
            setTrelloBoards(res.data.boards)
        })
        .catch((err) => {
            console.log(err)
        })
    }
    const getBoardDesc = (board) => {
        if (!board) return "Existing Trello board"
        if (!board.desc) return "Existing Trello board"
        return board.desc
    }
    const canSubmit = () => {
        if (disableSubmission) return false
        if (selectedBoardId) return true
        return false
    }
    const importFromTrello = () => {
        if (!selectedBoardId) return
        setDisableSubmission(true)
        const payload = {
            workspace_id: workspaceId,
            trello_board_id: selectedBoardId,
            api_key: getTrelloApiKey(),
            api_token: getTokenFromUrl()
        }
        api.importTrelloBoard(payload)
        .then((res) => {
            if (res.data) return history.push(`/workspaces/${res.data.board.board.workspace_id}/c/${res.data.board.board._id}`)
            console.log('Something may have gone wrong')
        })
        .catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        fetchTrelloBoards()
    // eslint-disable-next-line
    },[])
    return (
        <div className="form-new-component-container">

            <div className="form-new-component-width-control">
                {/* Heading */}
                <div className="form-new-component-heading">New Board</div>

                {/* Sub-heading */}
                <div className="form-new-component-subheading">
                    Move tasks around in 2D, across columns, until they're finished.
                </div>

                <div className="form-new-component-section">
                    <div className="form-new-component-section-title">Select a Trello Board</div>

                    {/* Grid of trello boards */}
                    <div className="form-templates-grid">
                        {trelloBoards.map((board) => (
                            <div key={board.id} className={`form-template-container-outer ${getAdditionalClass(board.id)}`} onClick={() => setSelectedBoardId(board.id)}>
                                <div>
                                    <div className="form-template-name">{board.name}</div>
                                    <div className="form-template-description">{getBoardDesc(board)}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Disclaimer area */}
                {canSubmit() && (
                    <div className="form-new-component-section">
                        <div className="form-new-component-section-title">Compatibility</div>
                        <div className="form-trello-disclaimers">
                            <div className="form-trello-disclaimers-inner">
                                <div className="form-trello-disclaimer form-trello-disclaimer-in"><IoIosCheckmarkCircle/> Card names</div>
                                <div className="form-trello-disclaimer form-trello-disclaimer-in"><IoIosCheckmarkCircle/> Card descriptions</div>
                                <div className="form-trello-disclaimer form-trello-disclaimer-in"><IoIosCheckmarkCircle/> Statuses</div>
                                <div className="form-trello-disclaimer form-trello-disclaimer-in"><IoIosCheckmarkCircle/> Due dates</div>
                            </div>
                            <div className="form-trello-disclaimers-inner">
                                <div className="form-trello-disclaimer form-trello-disclaimer-out"><IoIosCloseCircle/> Assignees</div>
                                <div className="form-trello-disclaimer form-trello-disclaimer-out"><IoIosCloseCircle/> Attachments</div>
                                <div className="form-trello-disclaimer form-trello-disclaimer-out"><IoIosCloseCircle/> Archived cards</div>
                            </div>
                        </div>
                    </div>
                )}

                {/* Submission Area */}
                {canSubmit() && (
                    <>
                    <div className="form-new-component-submission-section">
                        <div className="form-new-component-submit" onClick={importFromTrello}>Import</div>
                        <Link to={`/workspaces/${workspaceId}`} className="unlink form-new-component-cancel">Cancel</Link>
                    </div>
                    </>
                )}
                
            </div>
            
        </div>
    )
}
