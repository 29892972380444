const colorMap = {
    "none": {
        hex: "#ffffff",
        text: "#000000"
    },
    "armatage": {
        hex: "#FFBF42",
        text: "#000000"
    },
    "grape": {
        hex: "#A599B5",
        text: "#ffffff"
    },
    "orange": {
        hex: "#f07167",
        text: "#ffffff"
    },
    "blue": {
        hex: "#3F88C5",
        text: "#ffffff"
    },
    "pine": {
        hex: "#136F63",
        text: "#ffffff"
    },
    "pink": {
        hex: "#ffafcc",
        text: "ffffff"
    },
    "red": {
        hex: "#D00000",
        text: "#ffffff"
    },
    "onyx": {
        hex: "#393e41",
        text: "#ffffff"
    },
}

const calendarColorsHsl = [
    { h: 220, s: 30, l: 30 },  // #384A6B
    { h: 210, s: 60, l: 40 },  // #2A73AE
    { h: 260, s: 45, l: 65 },  // #9F6ADB
    { h: 355, s: 85, l: 70 },  // #FC636B
    { h: 45, s: 100, l: 50 },  // #FFB901
    { h: 198, s: 100, l: 46 }, // #00A7E9
    { h: 227, s: 60, l: 40 },  // #303f9f
    { h: 155, s: 50, l: 60 },  // #65cc6f
    { h: 0, s: 60, l: 70 },    // #e57373
    { h: 300, s: 50, l: 70 },  // #ce93d8
    { h: 330, s: 100, l: 30 }, // #c51162
    { h: 165, s: 100, l: 20 }, // #004d40
    { h: 28, s: 100, l: 50 },  // #bc5100
    { h: 200, s: 100, l: 40 }, // #007ac1
];

const getHashCodeFromObjectId = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const character = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + character;
        hash = hash & hash; // Convert to 32bit integer
    }
    return Math.abs(hash);
};

module.exports.convertHslToRgb = (h, s, l) => {
    h /= 360;
    s /= 100;
    l /= 100;
    let r, g, b;
  
    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1/6) return p + (q - p) * 6 * t;
        if (t < 1/2) return q;
        if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
        return p;
      };
  
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1/3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1/3);
    }
    return { r: Math.round(r * 255), g: Math.round(g * 255), b: Math.round(b * 255) };
}

module.exports.getContrastColor = (bgColor) => {
    let r = parseInt(bgColor.substr(1,2), 16);
    let g = parseInt(bgColor.substr(3,2), 16);
    let b = parseInt(bgColor.substr(5,2), 16);
    let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 160) ? "#000000" : "#ffffff";
}

module.exports.getTransparentColor = (hexColor, alphaDecimal) => {
    let r = parseInt(hexColor.substr(1, 2), 16);
    let g = parseInt(hexColor.substr(3, 2), 16);
    let b = parseInt(hexColor.substr(5, 2), 16);
  
    let result = 'rgba(' + r + ',' + g + ',' + b + ',' + alphaDecimal + ')';
    return result;
}

module.exports.getColorFromIndex = (index) => {
    try {
        const colors = [
            "#384A6B",
            "#2A73AE",
            "#9F6ADB",
            "#FC636B",
            "#FFB901",
            "rgb(0, 167, 233)",
            "#303f9f",
            "#9ccc65",
            "#e57373",
            "#ce93d8",
            "#c51162",
            "#004d40",
            "#bc5100",
            "#007ac1"
            // "rgb(6, 54, 116)",
            // "#b20000",
            // "#227b30",
            // "#7e57c2",
            // "#f6bf26",
            // "rgb(0, 167, 233)",
            // "#303f9f",
            // "#9ccc65",
            // "#e57373",
            // "#ce93d8",
            // "#c51162",
            // "#004d40",
            // "#bc5100",
            // "#007ac1"
        ]
        if (colors[index]) return colors[index]
        if (index > colors.length) {
            return colors[index % colors.length]
        }
        return "#174061"
    } catch (error) {
        return "#174061"
    }

}

module.exports.sortByProperty = (arr, propName) => {
    try {
        return arr.sort((a, b) => {
            let valA = a[propName]
            let valB = b[propName]
            if (typeof valA === "string") valA = valA.toLowerCase()
            if (typeof valB === "string") valB = valB.toLowerCase()
            if (valA < valB) return -1;
            if (valA > valB) return 1;
            return 0;
        });
    } catch (error) {
      return arr
    }
}

module.exports.sortByDateStringAttribute = (items, ascending, secondarySortingAttribute) => {
    try {
        let secondaryAttribute = "updatedAt"
        if (secondarySortingAttribute) secondaryAttribute = secondarySortingAttribute
        items.sort((a, b) => {
            const dateA = a.attributes && a.attributes.date_due ? new Date(a.attributes.date_due) : null;
            const dateB = b.attributes && b.attributes.date_due ? new Date(b.attributes.date_due) : null;
            const secondaryA = a[secondaryAttribute].toLowerCase()
            const secondaryB = b[secondaryAttribute].toLowerCase()
            if (dateA && dateB) {
                return ascending ? dateA - dateB : dateB - dateA;
            } else if (dateA) {
                return -1;
            } else if (dateB) {
                return 1;
            } else if (ascending) {
                if (secondaryA < secondaryB) return -1
                if (secondaryA > secondaryB) return 1
                return 0
            } else {
                if (secondaryA < secondaryB) return 1
                if (secondaryA > secondaryB) return -1
                return 0
            }
        });
        return items
    } catch (error) {
        return items
    }
}

module.exports.sortByDateProperty = (items, propertyName, ascending) => {
    try {
        let secondaryAttribute = "display_name"
        items.sort((a, b) => {
            const dateA = a[propertyName] ? new Date(a[propertyName]) : null;
            const dateB = b[propertyName] ? new Date(b[propertyName]) : null;
            const secondaryA = a[secondaryAttribute].toLowerCase()
            const secondaryB = b[secondaryAttribute].toLowerCase()
            if (dateA && dateB) {
                return ascending ? dateA - dateB : dateB - dateA;
            } else if (dateA) {
                return -1;
            } else if (dateB) {
                return 1;
            } else if (ascending) {
                if (secondaryA < secondaryB) return -1
                if (secondaryA > secondaryB) return 1
                return 0
            } else {
                if (secondaryA < secondaryB) return 1
                if (secondaryA > secondaryB) return -1
                return 0
            }
        });
        return items
    } catch (error) {
        return items
    }
}

module.exports.getColorStylesFromObjectId = (objectId, useRawColors) => {
    try {
        const hash = getHashCodeFromObjectId(objectId);
        const index = hash % calendarColorsHsl.length;
        const hsl = calendarColorsHsl[index];
        let effectiveL = hsl.l
        let effectiveS = hsl.s
        let effectiveHue = hsl.h
        if (!useRawColors) {
            effectiveHue = (hsl.h + hash - 20) % 360;
            effectiveL = Math.max(0, Math.min(100, hsl.l + hash % 10));
            effectiveS = Math.max(0, Math.min(100, hsl.s + hash % 10));
        }
        const backgroundColorHsl = `hsl(${effectiveHue}, ${effectiveS}%, ${effectiveL}%)`
        const backgroundColorRgb = this.convertHslToRgb(effectiveHue, effectiveS, effectiveL)
        let textColor = "white"
        let yiq = ((backgroundColorRgb.r * 299) + (backgroundColorRgb.g * 587) + (backgroundColorRgb.b * 114)) / 1000;
        if (yiq >= 160) textColor = "black"
        return {
            backgroundColor: backgroundColorHsl,
            color: textColor
        }
    } catch (error) {
        return {
            backgroundColor: "#384A6B",
            color: "white"
        }
    }
}

module.exports.sortByMostRecentMessage = (arr) => {
    // Fairly specific to the Messages.jsx component
    try {
        return arr.sort((a, b) => {
        if (a["messages"][0]["createdAt"] < b["messages"][0]["createdAt"]) return 1;
        if (a["messages"][0]["createdAt"] > b["messages"][0]["createdAt"]) return -1;
        return 0;
        });
    } catch (error) {
      return arr
    }
}

module.exports.sortByPropertyReverse = (arr, propName) => {
    try {
        return arr.sort((a, b) => {
            let valA = a[propName]
            let valB = b[propName]
            if (typeof valA === "string") valA = valA.toLowerCase()
            if (typeof valB === "string") valB = valB.toLowerCase()
            if (valA < valB) return 1;
            if (valA > valB) return -1;
            return 0;
        });
    } catch (error) {
      return arr
    }
}

module.exports.getColorFromPaletteBasedOnFirstName = (firstName) => {
    if (!firstName || firstName === "None") return "#ffffff"
    const colors = [
        "#384A6B",
        "#2A73AE",
        "#9F6ADB",
        "#FC636B",
        "#FFB901"
    ]
    const firstNameLength = firstName.length
    const leftOvers = firstNameLength % colors.length
    if (leftOvers > colors.length - 1) return colors[0]
    return colors[leftOvers]
}

module.exports.validateUrl = (url) => {
    try {
        // eslint-disable-next-line
        var urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-z0-9\-]+(?:\.[a-z]{2,})(?:[\/\?#][^\s]*)?$/i;
        return urlRegex.test(url);
    } catch (error) {
        return false
    }
}

module.exports.getWordsOfPhraseByCount = (phrase, count, maxChars) => {
    try {
        var wordArray = phrase.split(" ");
        var choppedPhrase = "";
        for (let index = 0; index < count; index++) {
            if (wordArray[index]) {
                choppedPhrase += `${wordArray[index]} `
            }
        }
        if (maxChars) {
            if (maxChars >= choppedPhrase) {
                return choppedPhrase
            } else {
                return choppedPhrase.substring(0,maxChars)
            }
        } else {
            return choppedPhrase;
        }   
    } catch (error) {
        console.log(error)
        return "no"
    }

}

module.exports.getDisplayDescriptionHtml = (component) => {
    let mainDescription = component.attributes.description
    if (component.attributes.details) {
        if (component.attributes.details.description_html) mainDescription = component.attributes.details
    }
    try {
        if (mainDescription.description_html) {
            const parsedHtml = mainDescription.description_html.replace(/<\/?[^>]+(>|$)/g, '')
            if (parsedHtml === "") return ""
            return mainDescription.description_html
        }
        return mainDescription
    } catch (error) {
        return ""
    }
}

module.exports.addComponentToUncollapsedLocalStorageList = (componentId) => {
    try {
        let expandedIds = JSON.parse(localStorage.getItem('gExpandedIds')) || [];
        const index = expandedIds.indexOf(componentId);
        if (index < 0) {
            expandedIds.push(componentId);
            localStorage.setItem('gExpandedIds', JSON.stringify(expandedIds));
        }
    } catch (error) {
        return
    }
}

module.exports.removeComponentFromUncollapsedLocalStorageList = (componentId) => {
    try {
        let expandedIds = JSON.parse(localStorage.getItem('gExpandedIds')) || [];
        const index = expandedIds.indexOf(componentId);
        if (index > -1) {
            expandedIds.splice(index, 1)
            localStorage.setItem('gExpandedIds', JSON.stringify(expandedIds));
        }
    } catch (error) {
        return   
    }
}

module.exports.parseHashtagsFromString = (str) => {
    // Does not include the actual pound symbol
    var re = /#(\w+)/g;
    var matches = str.match(re);
    if (matches) {
        return matches.map(function(match) {
            return match.substring(1);
        });
    }
    return []
}

module.exports.getFormattedDisplayWithUnits = (unit, value) => {
    const currentValue = value;
    // if no units, display raw count
    if (!unit) {
        return currentValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    // if a dollar sign, return as a currency
    if (unit === "$") {
        // Create our number formatter.
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            // minimumFractionDigits: 0,
            // maximumFractionDigits: 0
        });
        
        return formatter.format(currentValue);
    } else if (unit === "%") {
        return `${(Math.round(currentValue * 100) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${unit}`
    } else {
        return currentValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }    
}

module.exports.translatePermissionString = (permissionString) => {
    const translations = {
        component_create: "Create new items",
        component_read: "View items",
        component_update: "Update items",
        component_delete: "Delete assignable items",
        member_add: "Add new workspace members",
        member_delete: "Remove workspace members",
        component_template_create: "Create templates",
        component_template_update: "Update templates",
        component_template_read: "View components",
        component_template_delete: "Delete templates",
        workspace_delete: "Delete workspaces",
        workspace_name_update: "Update workspace settings",
        roles_update: "Update user roles",
        network_admin: "Unrestricted network permissions",
        network_user: "Basic network operations"
    }
    if (translations[permissionString]) return translations[permissionString]
    return permissionString
}

module.exports.getUserDataFromId = (team, id) => {
    if (id === "" || !id) return "None"
    if (!team) return "None"
    var result = team.filter(obj => {
        return obj._id === id
    })
    if (result.length > 0) {
        return result[0]
    } else {
        return false
    }
    
}

module.exports.getMetricDisplayValue = (measurement) => {
    const currentValue = measurement.attributes.val_current;
    // if no units, display raw count
    if (!measurement.attributes.units) {
        return currentValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const unit = measurement.attributes.units;

    // if a dollar sign, return as a currency
    if (unit === "$") {
        // Create our number formatter.
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            // minimumFractionDigits: 0,
            // maximumFractionDigits: 0
        });
        
        return formatter.format(currentValue);
    } else if (unit === "%") {
        return `${(Math.round(currentValue * 100) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${unit}`
    } else {
        return currentValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}

module.exports.getPageTabClassName = (name, view) => {
    if (name === view) return "component-page-tab-selected"
}

module.exports.formatHtmlStringWithHyperlinks = (htmlString) => {
    // Regular expression to match URLs
    // eslint-disable-next-line
    const urlRegex = /\b(?:https?:\/\/)?(?:www\.)?[-A-Z0-9+&@#\/%=~_|!:,.;]*\.[A-Z]{2,3}(?:\/[-A-Z0-9+&@#\/%=~_|!:,.;]*)*(?:\?[A-Z0-9+&@#\/%=~_|!:,.;]*)?(?![^<]*>|[^<>]*<\/a>)/gi;
  
    // Function to remove punctuation from the end of URLs
    function cleanUrl(match) {
      // Remove punctuation at the end of the URL if it's there
      const punctuationRegex = /[.,;!?]*$/;
      const cleanMatch = match.replace(punctuationRegex, '');
      // Return the cleaned URL
      return cleanMatch;
    }
  
    // Replace URLs in the HTML string with <a> tags
    return htmlString.replace(urlRegex, function(match) {
      const cleanMatch = cleanUrl(match);
      const urlWithProtocol = /^(http:\/\/|https:\/\/)/i.test(cleanMatch) ? cleanMatch : `http://${cleanMatch}`;
      return `<a href='${urlWithProtocol}' target='_blank' rel='noopener noreferrer'>${cleanMatch}</a>`;
    //   return `<a href='${cleanMatch}' target='_blank' rel='noopener noreferrer'>${cleanMatch}</a>`;
    });
}

module.exports.getPageTabInitiativeClassName = (name, view) => {
    if (name === view) return "component-page-tab-initiative-selected"
}

module.exports.wrapHashtagsInSpan = (htmlString, workspaceId) => {
    const getHandleNoSymbol = (handle) => {
        if (handle.startsWith("@")) {
            return handle.slice(1);
        }
        return handle;
    }
    const regex = /(^|\s|>)(#{1,})(\w+)/g;
    const regexMention = /<span([^>]*)data-lexical-beautiful-mention-trigger="@"([^>]*)>(.*?)<\/span>/g;
  
    htmlString = htmlString.replace(regex, (match, space, hashes, hashtag) => {
        const span = document.createElement('a');
        span.className = 'gadget-hashtag unlink';
        span.href = `/workspaces/${workspaceId}/t/${hashtag}`
        span.textContent = `${hashes}${hashtag}`;
        const wrapper = document.createElement('div');
        wrapper.appendChild(span);
        return space + wrapper.innerHTML;
    });

    htmlString = htmlString.replace(regexMention, (match, before, after, innerContent) => {
        const mentionSpan = document.createElement('a');
        mentionSpan.className = 'gadget-hashtag unlink';
        mentionSpan.href = `/u/${getHandleNoSymbol(innerContent)}`;
        mentionSpan.innerHTML = innerContent;
        const wrapper = document.createElement('div');
        wrapper.appendChild(mentionSpan);
        return `<span${before}${after}>${wrapper.innerHTML}</span>`;
    });
    return htmlString
}

module.exports.getSubviewTabInitiativeClassName = (name, view) => {
    if (name === view) return "component-subview-tab-initiative-selected"
}

module.exports.getUserDataFromEmail = (team, emailAddress) => {
    if (emailAddress === "" || !emailAddress) {
        return "None"
    } else {
        var result = team.filter(obj => {
            return obj.emailAddress === emailAddress
        })
        if (result.length > 0) {
            return result[0]
        } else {
            return false
        }
    }
}

module.exports.getUserNameFromId = (team, id) => {
    if (id === "" || !id) return "None"
    if (!team) return "None"
    var result = team.filter(obj => {
        return obj._id === id
    })
    if (result.length > 0) {
        return `${result[0].firstName} ${result[0].lastName}`
    } else {
        return false
    }
}

module.exports.getUserProfileSrcFromId = (team, id) => {
    if (id === "" || !id) return false
    if (!team) return false
    var result = team.filter(obj => {
        return obj._id === id
    })
    if (result.length > 0) {
        return result[0].profile_picture_url
    } else {
        return false
    }
    
}

module.exports.getFirstNameFromId = (team, id) => {
    if (id === "" || !id) {
        return false
    } else {
        var result = team.filter(obj => {
            return obj._id === id
        })
        if (result.length > 0) {
            return `${result[0].firstName}`
        } else {
            return false
        }
    }
}

module.exports.getHandleFromId = (team, id) => {
    if (id === "" || !id) {
        return ""
    } else {
        var result = team.filter(obj => {
            return obj._id === id
        })
        if (result.length > 0) {
            return `${result[0].handle}`
        } else {
            return ""
        }
    }
}

module.exports.toCapitalize = (word) => {
    if (word) {
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
    } else {
        return ""
    }
}

module.exports.capitalizeFirstLetter = (word) => {
    if (word) {
        return word[0].toUpperCase() + word.slice(1);
    } else {
        return word
    }
}

module.exports.getColorHexMapFromName = (colorName) => {
    // #ffffff is white
    // #000000 is black
    const defaultColor = {
        hex: "#ffffff",
        text: "#000000"
    }
    if (colorMap[colorName]) {
        return colorMap[colorName]
    } else {
        return defaultColor
    }
}

module.exports.getAvailableCardColors = () => {
    // Returns list of object keys
    return Object.keys(colorMap)
}

module.exports.getAcronym = (content, maxChars) => {
    try {
        var matches = content.match(/\b(\w)/g);
        var choppedPhrase = matches.join('');
        if (maxChars) {
            if (maxChars >= matches) {
                return choppedPhrase
            } else {
                return choppedPhrase.substring(0,maxChars)
            }
        } else {
            return choppedPhrase;
        }
    } catch (error) {
        return " "
    }
    
}

module.exports.getWorkspaceNameFromMap = (workspaceMap, workspaceId) => {
    if (workspaceMap[workspaceId]) {
        return workspaceMap[workspaceId]
    } else {
        return "Workspace"
    }
}

module.exports.hasPermission = (permissionsList, permissionString) => {
    if (!permissionsList) return false
    if (permissionsList.includes(permissionString)) return true
    return false
}

module.exports.getHyperLinkRegex = () => {
    // eslint-disable-next-line
    return /((http|ftp|https):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/
        // return /@([\w_]+)/; // Matches @.... 
}

module.exports.getEmailRegex = () => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
}

module.exports.isValidEmailAddress = (emailAddress) => {
    if (!emailAddress) return false
    const emailRegex = this.getEmailRegex();
    return String(emailAddress)
        .toLowerCase()
        .match(emailRegex);
}

module.exports.isValidBusinessEmailAddress = (emailAddress) => {
    try {
        if (!emailAddress) return false;
  
        const emailRegex = this.getEmailRegex();
      
        if (!emailRegex.test(emailAddress)) return false
      
        const blacklistedDomainPrefixes = [
            'gmail',
            'yahoo',
            'hotmail',
            'outlook',
            'aol',
            'icloud',
            'me',
            'mac',
            'live',
            'protonmail',
            'tutanota',
            'pm.me',
            'zoho',
            'yandex',
            'mail.ru',
            'rambler.ru',
            'fastmail',
            'hush',
            'hushmail',
            'guerrillamail',
            'guerrillamailblock',
            'mailinator',
            'mailin8r',
            'sofimail',
            'fromru',
            'drdrb',
          ];
      
          const emailDomain = emailAddress.split('@')[1];
          const domainPrefix = emailDomain.split('.')[0].toLowerCase();
      
          if (blacklistedDomainPrefixes.includes(domainPrefix)) return false;
        return true;
    } catch (error) {
        return false
    }
}

module.exports.cleanSlug = (proposedSlug) => {
    return proposedSlug.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, "") //remove diacritics
    .toLowerCase()
    .replace(/\s+/g, '-') //spaces to dashes
    .replace(/&/g, '-and-') //ampersand to and
    // eslint-disable-next-line
    .replace(/[^\w\-]+/g, '') //remove non-words
    // eslint-disable-next-line
    .replace(/\-\-+/g, '-') //collapse multiple dashes
    .replace(/^-+/, '') //trim starting dash
    .replace(/_/, '-')
    // .replace(/-+$/, '') //trim ending dash
}

module.exports.getPercentageComplete = (component) => {
    if (!component) return 0
    if (!component.progress) return 0
    return component.progress*100
}

module.exports.isCompletable = (component) => {
    if (!component) return false
    if (!component.status) return false
    if (component.status !== 'active') return false
    if (component.name === 'task') return true
    if (!component.progress) return false
    if (component.progress < 1) return false
    return true
}

// module.exports.isValidHttpUrl = (s) => {
//     let url;
//     try {
//         url = new URL(s);
//     } catch (e) { return false; }
//     return /https?/.test(url.protocol);
// }

module.exports.isValidHttpUrl = (string) => {
    try {
        const pattern = new RegExp('^(https?:\\/\\/)' + // protocol is mandatory
        '(([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+' + // domain name and subdomains
        '[a-zA-Z]{2,}' + // TLD
        '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-zA-Z\\d_]*)?$', 'i'); // fragment locator
    
      return !!pattern.test(string);
    } catch (error) {
        return false
    }
}

module.exports.updateHeadTags = (title, metaTags) => {
    try {
        if (title) document.title = title
        let providedMetaTags = []
        if (metaTags) providedMetaTags = metaTags
        if (providedMetaTags.length === 0) {
            const keepMetaTags = ['charset', 'viewport', 'X-UA-Compatible', 'theme-color'];
            const existingMetaTags = document.querySelectorAll('meta');
            existingMetaTags.forEach(meta => {
                const name = meta.getAttribute('name') || meta.getAttribute('http-equiv');
                if (!keepMetaTags.includes(name)) meta.parentNode.removeChild(meta)
            });
            this.removeSchemaOrgJSONLD()
            return
        }
        const head = document.getElementsByTagName('head')[0];
        metaTags.forEach(tag => {
        let metaElement = document.querySelector(`meta[name="${tag.name}"]`);
        if (!metaElement) {
            metaElement = document.createElement('meta');
            metaElement.setAttribute('name', tag.name);
            head.insertBefore(metaElement, head.firstChild)
        }
        metaElement.setAttribute('content', tag.content);
        });
    } catch (error) {
        return
    }

}

module.exports.updateSchemaOrgJSONLD = (data) => {
    try {
        const scriptId = 'schema-org-json-ld'
        let script = document.getElementById(scriptId);
        if (!script) {
          script = document.createElement('script');
          script.type = 'application/ld+json';
          script.id = scriptId;
          document.getElementsByTagName('head')[0].appendChild(script);
        }
        script.textContent = JSON.stringify(data);
    } catch (error) {
        return
    }
}

module.exports.removeSchemaOrgJSONLD = () => {
    try {
        const scriptId = 'schema-org-json-ld'
        const script = document.getElementById(scriptId)
        if (script) script.parentNode.removeChild(script)
    } catch (error) {
        return
    }

}

module.exports.transformUrlIntoDisplayName = (url) => {
    try {
        // Extract hostname from URL
        const hostname = new URL(url).hostname;
        // Split hostname into parts
        const domainParts = hostname.split('.');

        // Remove TLD (last part) if it's commonly recognized (e.g., .com, .net, .org, etc.)
        const tlds = ['com', 'net', 'org', 'co', 'uk', 'info', 'biz', 'io', 'gov', 'edu', 'us', 'mil', 'int'];
        if (tlds.includes(domainParts[domainParts.length - 1])) {
        domainParts.pop();
        }

        // Capitalize each part and reverse the order for subdomains
        const capitalizedParts = domainParts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).reverse();

        // Join parts with space
        const primaryDomain = capitalizedParts.join(' ');

        return primaryDomain;
    } catch (error) {
        return url
    }
}