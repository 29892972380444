import React from 'react'
import { useState, useEffect, useReducer } from 'react'
import { useHistory } from 'react-router-dom';
import Api from "../../../common/APIUtils"
import '../../../styles/forms.css';
import '../../../styles/components.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import LoadingSymbolGrid from '../../gadgets/LoadingSymbolGrid';
import { Calendar } from 'react-date-range';
import { addDays, format, isValid } from 'date-fns';
import { BsArrowRight, BsCalendarEvent, BsFillCalendar3EventFill } from 'react-icons/bs';
import LexicalTextEditor from '../../gadgets/LexicalTextEditor';
import NewComponentTemplateChoice from './NewComponentTemplateChoice';

export default function NewInitiative({ workspaceId, proposedTemplate }) {
    const api = new Api();
    let history = useHistory();
    const [dateErrorText, setDateErrorText] = useState(() => { return "" })
    const [page, setPage] = useState(() => { return 1 })
    const [canSubmit, setCanSubmit] = useState(() => { return false })
    const [isShowingDateStartPicker, setIsShowingDateStartPicker] = useState(() => { return false })
    const [isShowingDateEndPicker, setIsShowingDateEndPicker] = useState(() => { return false })
    const [templatesLoaded, setTemplatesLoaded] = useState(() => { return false })
    const [dateStart, setDateStart] = useState(() => { return new Date() })
    const [initialDescriptionState, setInitialDescriptionState] = useState(() => { return false })
    const [isSubmitting, setIsSubmitting] = useState(() => { return false })
    const [dateEnd, setDateEnd] = useState(() => { return addDays(new Date(), 30) })
    const [templates, setTemplates] = useState(() => { return [] })
    const [chosenTemplate, setChosenTemplate] = useState(() => {
        if (proposedTemplate) {
            return proposedTemplate
        } else {
            return "123"
        }
    })
    const [proposedInitiative, proposedInitiativeDispatch] = useReducer((state, action) => { 
        if (action.type === "update_name") return { ...state, name: action.value }
        if (action.type === "save") {
            const safeHtml = action.value.description_html
            const editorState = action.value.description_editor
            return { ...state, description_editor: editorState, description_html: safeHtml }
        }       
        return state
    }, { description_editor: false, description_html: false, name: "" })
    const getFancyDateString = (dateString) => {
        if (!isValid(dateString)) return 'Choose a date'
        return format(dateString, 'MMM do, yyyy')
    }
    const blankBoardTemplate = {
        template_name: 'Blank',
        _id: "123",
        template_description: "Start from scratch"
    }
    const updateStartDate = (newStartDate) => {
        setDateStart(newStartDate)
        showEndDatePicker()
    }
    const updateEndDate = (newEndDate) => {
        setDateEnd(newEndDate)
        setIsShowingDateEndPicker(false)
    }
    const showStartDatePicker = () => {
        setIsShowingDateEndPicker(false)
        setIsShowingDateStartPicker(true)
    }
    const isValidTemplateId = (id) => {
        if (id === "123") {
            return true;
        }
        const foundTemplatesWithId = templates.filter(t => {
            return t._id === id
        })
        if (foundTemplatesWithId.length > 0) {
            return true
        } else {
            return false;
        }
    }
    const showEndDatePicker = () => {
        setIsShowingDateStartPicker(false)
        setIsShowingDateEndPicker(true)
    }
    const ALLOWED_CODES = ["Enter", "NumpadEnter"]
    const handleKeyBoardPress = (code) => {
        if (ALLOWED_CODES.includes(code)) {
            if (canSubmit) return setPage(2)
        }
        if (code === "Escape") history.goBack()
    }
    const publishInitiative = () => {
        if (!canSubmit) return
        setIsSubmitting(true)
        const attributes = {};
        attributes['description'] = {
            description_editor: proposedInitiative.description_editor,
            description_html: proposedInitiative.description_html
        };
        attributes['name'] = proposedInitiative.name;
        attributes['active'] = true;
        attributes['date_start'] = format(dateStart, 'yyyy-MM-dd')
        attributes['date_end'] = format(dateEnd, 'yyyy-MM-dd')

        let payload = {
            workspace_id: workspaceId,
            is_published: true,
            display_name: proposedInitiative.name,
            attributes: attributes
        }
        if (chosenTemplate !== "123" && isValidTemplateId(chosenTemplate)) payload['template_id'] = chosenTemplate
        const path = `/components/strategy/initiative`;
        api.createWorkspaceComponent(payload, path)
        .then( (res) => {
            if (res.status === 200) {
                history.push(`/workspaces/${workspaceId}/c/${res.data.response.component._id}`);
            }
        })
        .catch((err) => {
            return 
        })
        
    }
    const updateDescriptionFromTemplate = () => {
        try {
            if (!chosenTemplate || chosenTemplate === "123") return setInitialDescriptionState(false)
            if (templates.length === 0) return setInitialDescriptionState(false)
            let t = false
            for (let i = 0; i < templates.length; i++) {
                const foundTemplate = templates[i];
                if (foundTemplate._id === chosenTemplate) t = foundTemplate
            }
            if (!t) return setInitialDescriptionState(false)
            setInitialDescriptionState(t.config.description.description_editor)
            proposedInitiativeDispatch({
                type: "save",
                value: {
                    description_html: t.config.description.description_html,
                    description_editor: t.config.description.description_editor
                }
            })
        } catch (error) {
            return setInitialDescriptionState(false)
        }
    }
    const getComponentTemplates = () => {
        const payload = {
            workspaceId: workspaceId,
            type: 'initiative'
        }
        try {
            api.getComponentTemplates(payload)
            .then((res) => {
                if (res.data.response.templates) {
                    setTemplatesLoaded(true)
                    setTemplates(res.data.response.templates)
                } else {
                    setTemplatesLoaded(false)
                    setTemplates([])
                }
            }) 
        } catch (error) {
            console.log(error)
        }
    }
    const getInitialState = () => {
        if (!initialDescriptionState) return false
        return initialDescriptionState
    }
    useEffect(() => {
        if (!isValid(dateStart) || !isValid(dateEnd)) {
            setCanSubmit(false)
            return
        }
        if (dateStart >= dateEnd) {
            setDateErrorText("End date needs to be after start date")
            setCanSubmit(false)
            return
        }
        setDateErrorText("")
        if (proposedInitiative.name === "") {
            setCanSubmit(false)
            return
        }
        setCanSubmit(true)
    // eslint-disable-next-line
    }, [dateStart, dateEnd, proposedInitiative])
    useEffect(() => {
        if (dateStart > dateEnd) {
            const e = dateEnd;
            const s = dateStart;
            setDateEnd(s)
            setDateStart(e)
        }
    // eslint-disable-next-line
    }, [dateEnd])
    useEffect(() => {
        getComponentTemplates()
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (!chosenTemplate || chosenTemplate === "123") {
            setInitialDescriptionState(false)
            return
        }
        updateDescriptionFromTemplate()
    // eslint-disable-next-line
    }, [chosenTemplate, templates])
    return (
        <div className="network-page" style={{maxWidth: "1000px"}}>
            <div className="network-page-header-row">
                <div className="network-page-header-text">
                    {page === 1 && <h1>New Project</h1>}
                    {page === 2 && <h1>{proposedInitiative.name}</h1>}
                    {page === 1 && <p>A central area for planning, doing, and measuring your work</p>}
                    {page === 2 && <p>What is this project about?</p>}
                </div>
            </div>

            {page === 1 &&
            <div className="form-network-create-form-container">
                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-section-label">Name</div>
                    <div className="form-network-create-form-section-input">
                        <input style={{fontSize: "14px"}} type="text" value={proposedInitiative.name} onChange={(e) => proposedInitiativeDispatch({type: "update_name", value: e.target.value })} placeholder='Name this project' autoFocus onKeyDown={(e) => handleKeyBoardPress(e.code)} />
                    </div>
                </div>

                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-subsection">
                        <div className="form-network-create-form-subsection-title">Timeline</div>
                        <div className="form-network-create-form-subsection-divider-container">
                            <div className="form-network-create-form-subsection-divider"></div>
                            <div></div>
                        </div>
                    </div>

                    <div className="form-network-create-form-section-row" style={{gap: "1em", boxSizing: "border-box"}}>

                        {/* Start Date */}
                        <div className="form-create-date-selection-container" onClick={showStartDatePicker}>
                            <div className="form-create-date-selection-clickable-display">
                                <BsCalendarEvent/>
                                <div>
                                    <div className="form-network-create-form-section-label">Start Date</div>
                                    <div className="form-create-date-description">{getFancyDateString(dateStart)}</div>
                                </div>
                            </div>

                            {isShowingDateStartPicker && (
                                <div className="form-create-date-calendar-picker">
                                    <Calendar date={dateStart} onChange={updateStartDate} />
                                </div>
                            )}
                        </div>

                        <BsArrowRight/>

                        {/* End Date */}
                        <div className="form-create-date-selection-container" onClick={showEndDatePicker}>
                                <div className="form-create-date-selection-clickable-display" >
                                    <BsFillCalendar3EventFill/>
                                    <div>
                                        <div className="form-network-create-form-section-label">End Date</div>
                                        <div className="form-create-date-description">{getFancyDateString(dateEnd)}</div>
                                    </div>
                                </div>

                                {isShowingDateEndPicker && (
                                    <div className="form-create-date-calendar-picker">
                                        <Calendar date={dateEnd} onChange={updateEndDate} />
                                    </div>
                                )}
                        </div>

                    </div>
                    {dateErrorText && <div>{dateErrorText}</div>}
                </div>

                <div className="form-network-create-form-section">
                    <div className="form-network-create-form-section-label">Choose a template</div>
                    {templatesLoaded ? (
                        <div className="form-templates-grid">
                            <NewComponentTemplateChoice chosenTemplate={chosenTemplate} template={blankBoardTemplate} setAsTemplate={setChosenTemplate} />

                            {templates.map((template) => (
                                <NewComponentTemplateChoice key={template._id} chosenTemplate={chosenTemplate} template={template} setAsTemplate={setChosenTemplate}/>
                            ))}
                        </div>
                    ) : (
                        <LoadingSymbolGrid/>
                    )}
                </div>

                {canSubmit ? <div className="form-network-create-form-create" onClick={() => setPage(2)}>Next</div> : <div className="form-network-create-form-create" style={{backgroundColor: "grey"}}>Next</div>}
            </div>
            }

            {page === 2 &&
            <div className="form-network-create-form-container">

                <div className="form-network-create-form-section">

                    <div>
                        <div className="form-network-create-form-section-label" style={{marginBottom: "0.5em"}}>Description</div>
                        <div style={{position: "relative"}}>
                            <LexicalTextEditor isUpgrade={false} getInitialState={getInitialState} mainDescriptionDispatch={proposedInitiativeDispatch} legacyDescription={false} />
                        </div>
                    </div>
                </div>

                {!isSubmitting &&
                <>
                    {canSubmit ? <div className="form-network-create-form-create" onClick={publishInitiative}>Create Project</div> : <div className="form-network-create-form-create" style={{backgroundColor: "grey"}}>Create Project</div>}
                    <div style={{display: "flex", justifyContent: "center", marginTop: "12px"}}>
                        <span style={{cursor: "pointer", fontSize: "14px", color: "grey"}} onClick={() => setPage(1)}>Go Back</span>
                    </div>
                </>
                }
                {isSubmitting && 
                <div className="util-row util-justify-center util-align-center"><LoadingSymbolGrid/></div>
                }

            </div>
            }

        </div>


    )
}
