import React from 'react'
import { useState, useEffect } from "react";
import Api from '../../common/APIUtils';
import {  useHistory } from 'react-router-dom';
import ModalSimpleHeader from './simple/ModalSimpleHeader';
import ModalSimpleSingleButton from './simple/ModalSimpleSingleButton';
import { getUserNameFromId, hasPermission } from '../../common/Helpers';

export default function ModalEditComponentTemplate({ showEditTemplate, template, fetchTemplates, workspace, getSymbol, team, network, permissions }) {
    const api = new Api();
    const history = useHistory();
    const [view, setView] = useState(() => {return 'overview'})
    const [tasks, setTasks] = useState(() => { return [] })
    const [columns, setColumns] = useState(() => { return [] })
    const [workstreams, setWorkstreams] = useState(() => { return [] })
    const [proposedTemplateName, setProposedTemplateName] = useState(() => { return template.template_name })
    const [proposedTemplateDescription, setProposedTemplateDescription] = useState(() => { return template.template_description })
    const [isSubmittable, setIsSubmittable] = useState(() => { return true })
    const deleteTemplate = () => {
        const payload = {
            templateId: template._id
        }
        api.deleteComponentTemplate(payload)
        .then((res) => {
            showEditTemplate(false)
            fetchTemplates();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const getNetworkUserNavClassName = (navName) => {
        if (navName === view) return "network-users-view-navigation-selected"
        return ""
    }
    const canPromote = () => {
        if (template.scope === 'network') return false
        if (!workspace) return false
        if (workspace.workspace_type === 'private') return false
        if (workspace.workspace_type === 'network') return true
        return false
    }
    const updateTemplate = () => {
        const payload = {
            template_name: proposedTemplateName,
            template_description: proposedTemplateDescription
        }
        api.updateComponentTemplate(payload, template._id)
        .then((res) => {
            console.log(res)
            showEditTemplate(false)
            fetchTemplates();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const createItemFromTemplate = () => {
        try {
            history.push(`/workspaces/${workspace._id}/new?name=${template.component_type}&template-id=${template._id}`)
        
        } catch (error) {
            history.push(`/workspaces/${template.workspace_id}/new?name=${template.component_type}&template-id=${template._id}`)
        }
    }
    const attemptSubmission = () => {
        if (isSubmittable) {
            updateTemplate()
        }
    }
    const promoteTemplate = (asCopy) => {
        let payload = {
            template_id: template._id,
            network_id: workspace?.network_id
        }
        if (asCopy) payload['copy'] = true
        api.promoteComponentTemplate(payload)
        .then((res) => {
            if (res.data) {
                fetchTemplates()
                showEditTemplate(false)
                return
            }
        })
        .catch((err) => {
            fetchTemplates()
            showEditTemplate(false)
        })
    }
    const centerInContainerStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    }
    const canBeDeleted = () => {
        if (template.scope === 'workspace') return true
        return false
    }
    const getTaskDetails = (task) => {
        try {
            if (typeof task.details === "string") return task.details
            if (!task.details.description_html) return ""
            return task.details.description_html.replace(/<\/?[^>]+(>|$)/g, '')
        } catch (error) {
            return ""
        }
    }
    const hideModal = () => {
        showEditTemplate(false)
    }
    const updateTotals = () => {
        if (!template) return
        // Tasks
        if (["list", "board"].includes(template.component_type)) {
            setTasks(template.configs)
        }
        // Columns
        if (template.component_type === "board") {
            setColumns(template.config.columns)
        }
        // Workstreams
        if (template.component_type !== "initiative") return
        setWorkstreams(getWorkstreamCountForProject())
    }
    const getWorkstreamCountForProject = () => {
        try {
            let totalWorkstreams = 0
            const configs = template.configs
            totalWorkstreams = configs.filter(c => ["board", "list"].includes(c.component_type))
            return totalWorkstreams
        } catch (error) {
            return []
        }
    }
    useEffect(() => {
        if (proposedTemplateName) {
            setIsSubmittable(true)
        } else {
            setIsSubmittable(false)
        }
    }, [proposedTemplateName])
    useEffect(() => {
        updateTotals()
    // eslint-disable-next-line
    }, [template])
    return (
        <div className="modal-mgmt-background-overlay">
            <div className="modal-mgmt-container" style={{width: "60%"}}>
                <ModalSimpleHeader title={template.template_name} hideModal={hideModal} />

                <div className="network-users-view-navigation network-users-view-navigation-small">
                    <span onClick={() => setView("overview")} className={getNetworkUserNavClassName("overview")}>Overview</span>
                    <span onClick={() => setView("settings")} className={getNetworkUserNavClassName("settings")}>Template Settings</span>
                </div>

                {view === "overview" && template.component_type === "initiative" &&
                <div className="modal-mgmt-sections" style={{gap: "20px"}}>
                    <div className="modal-mgmt-section">
                        <h3>Project Template</h3>
                        {template.template_description && <div>{template.template_description}</div>}
                        {!template.template_description && <div>This template creates a project with {workstreams.length} workstream{workstreams.length > 1 && "s"}.</div>}
                    </div>

                    <div className="modal-mgmt-section">
                        <h3>Workstreams <span>{workstreams.length}</span></h3>
                        <div className="modal-component-template-create-content-containers">
                            {workstreams.map((workstream) => (
                                <div className="modal-component-template-create-content-container" key={workstream.display_name}>
                                    <div className="modal-component-template-create-content-summary">
                                        {getSymbol(workstream.component_type)}
                                        <h5>{workstream.display_name}</h5>
                                    </div>
                                    <div className="modal-component-template-create-content-descriptor">{workstream.tasks.length} tasks</div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {getUserNameFromId(team, template.creator_id) &&
                    <div className="modal-mgmt-section">
                        <div className="modal-component-template-create-creator">Template created by {getUserNameFromId(team, template.creator_id)}</div>
                    </div>
                    }

                    {!network && <ModalSimpleSingleButton isValid={true} buttonText="Use Template" onclick={createItemFromTemplate} />}

                </div>
                }

                {view === "overview" && template.component_type === "list" &&
                <div className="modal-mgmt-sections" style={{gap: "20px"}}>
                    <div className="modal-mgmt-section">
                        <h3>{getSymbol("list")}List</h3>
                        {template.template_description && <div>{template.template_description}</div>}
                        {!template.template_description && <div>This template creates a list with {tasks.length} task{tasks.length > 1 && "s"}</div>}
                    </div>

                    <div className="modal-mgmt-section">
                        <h3>Tasks <span>{tasks.length}</span></h3>
                        <div className="modal-component-template-create-content-containers">
                            {tasks.map((task) => (
                                <div className="modal-component-template-create-content-container" key={task.display_name}>
                                    <div className="modal-component-template-create-content-summary">
                                        {getSymbol("task")}
                                        <h5>{task.display_name}</h5>
                                    </div>
                                    <div className="modal-component-template-create-content-descriptor">{getTaskDetails(task)}</div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {getUserNameFromId(team, template.creator_id) &&
                    <div className="modal-mgmt-section">
                        <div className="modal-component-template-create-creator">Template created by {getUserNameFromId(team, template.creator_id)}</div>
                    </div>
                    }

                    {!network && <ModalSimpleSingleButton isValid={true} buttonText="Use Template" onclick={createItemFromTemplate} />}
                </div>
                }

                {view === "overview" && template.component_type === "board" &&
                <div className="modal-mgmt-sections" style={{gap: "20px"}}>
                    <div className="modal-mgmt-section">
                        <h3>{getSymbol("board")}Board</h3>
                        {template.template_description && <div>{template.template_description}</div>}
                        {!template.template_description && <div>This template creates a board with {columns.length} columns and {tasks.length} task{tasks.length > 1 && "s"}</div>}
                    </div>

                    <div className="modal-mgmt-section">
                        <h3>Columns <span>{columns.length}</span></h3>
                        <div className="modal-component-template-create-content-containers">
                            {columns.map((column) => (
                                <div className="modal-component-template-create-content-container" key={column.name}>
                                    <div className="modal-component-template-create-content-summary">
                                        {getSymbol("column")}
                                        <h5>{column.name}</h5>
                                    </div>
                                    <div className="modal-component-template-create-content-descriptor"></div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="modal-mgmt-section">
                        <h3>Tasks <span>{tasks.length}</span></h3>
                        <div className="modal-component-template-create-content-containers">
                            {tasks.map((task, index) => (
                                <div className="modal-component-template-create-content-container" key={`${task.display_name}-${index}`}>
                                    <div className="modal-component-template-create-content-summary">
                                        {getSymbol("task")}
                                        <h5>{task.display_name}</h5>
                                    </div>
                                    <div className="modal-component-template-create-content-descriptor">{getTaskDetails(task)}</div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {getUserNameFromId(team, template.creator_id) &&
                    <div className="modal-mgmt-section">
                        <div className="modal-component-template-create-creator">Template created by {getUserNameFromId(team, template.creator_id)}</div>
                    </div>
                    }

                    {!network && <ModalSimpleSingleButton isValid={true} buttonText="Use Template" onclick={createItemFromTemplate} />}
                </div>
                }

                {view === 'settings' && 
                <div className="modal-mgmt-sections">
                    <div className="modal-mgmt-section">
                        <h3>Template Name</h3>
                        <input type="text" placeholder="What do you want to rename this template?" value={proposedTemplateName} onChange={(e) => setProposedTemplateName(e.target.value)} maxLength={44}/>
                    </div>

                    <div className="modal-mgmt-section">
                        <h3>Description</h3>
                        <input type="text" placeholder="Enter a short description for this template" value={proposedTemplateDescription} onChange={(e) => setProposedTemplateDescription(e.target.value)} maxLength={150}/>
                    </div>

                    {(canBeDeleted() || canPromote()) &&
                    <div className="modal-mgmt-section">
                        <h3>Actions</h3>
                        {canBeDeleted() &&
                        <div>
                             <span className="modal-component-template-create-section-action-link" onClick={() => setView('delete')}>Delete template</span>
                        </div>
                        }
                        {canPromote() &&
                        <div>
                             <span className="modal-component-template-create-section-action-link" onClick={() => setView('promote')}>Promote to network</span>
                        </div>
                        }
                    </div>
                    }

                    {template.scope === 'network' && !network && 
                    <div className="modal-mgmt-section">
                        <div>This template is managed by the network</div>
                    </div>
                    }

                    {template.scope === 'network' && network && hasPermission(permissions, "component_template_delete") &&
                    <div className="modal-mgmt-section">
                        <h3>Actions</h3>
                        <div>
                             <span className="modal-component-template-create-section-action-link" onClick={() => setView('delete')}>Delete template</span>
                        </div>
                    </div>
                    }
                    
                    <ModalSimpleSingleButton isValid={isSubmittable} buttonText="Save Template" onclick={attemptSubmission} />
                </div>
                }

                {view === 'promote' && (
                    <div className="modal-component-template-create-section-container" style={centerInContainerStyle}>
                        <div style={{fontWeight: "600"}}>Promote this template to the network?</div>
                        <div>This will make it available to all workspaces in the network.</div>
                        <div className="modal-component-template-create-promotion-buttons">
                            <div className="modal-component-template-create-promotion-button" onClick={() => promoteTemplate(false)}>Promote <div style={{fontSize: "0.8em"}}>Move entire template to the network</div></div>
                            <div className="modal-component-template-create-promotion-button" onClick={() => promoteTemplate(true)}>Copy <div style={{fontSize: "0.8em"}}>Keep a local copy in workspace</div></div>
                        </div>
                        <div style={{marginTop: "1em", marginBottom: "1em"}}>
                            <span style={{color: "grey"}} className="modal-component-template-information-section-delete-button" onClick={() => setView('settings')}>Go Back</span>
                        </div>
                    </div>
                )}

                {view === 'delete' && (
                    <div className="modal-component-template-create-section-container" style={centerInContainerStyle}>
                        <div style={{fontWeight: "600"}}>Are you sure you want to delete this template?</div>
                        <div style={{marginTop: "1em"}}>
                            <span className="modal-component-template-information-section-delete-button" onClick={deleteTemplate}>Permanently Delete</span>
                        </div>
                        <div style={{marginTop: "1em", marginBottom: "1em"}}>
                            <span style={{color: "grey"}} className="modal-component-template-information-section-delete-button" onClick={() => setView('settings')}>Go Back</span>
                        </div>
                    </div>
                )}
            
            </div> 
        </div>
    )
}
