import React from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { useState } from 'react';
import Api from '../../../common/APIUtils';
import { isValidEmailAddress } from '../../../common/Helpers';

export default function ProApplication({authenticated}) {
    let history = useHistory();
    const api = new Api()
    const { tier } = useParams();
    const [page, setPage] = useState(() => { return 1 })
    const [teamSize, setTeamSize] = useState(() => { return "1" })
    const [firstName, setFirstName] = useState(() => { return "" })
    const [lastName, setLastName] = useState(() => { return "" })
    const [business, setBusiness] = useState(() => { return "" })
    const [tetherosEmail, setTetherosEmail] = useState(() => { return "" })
    const finishForm = () => {
        history.push('/home')
    }
    const advancePage = () => {
        setPage(page + 1)
    }
    const backPage = () => {
        setPage(page - 1)
    }
    const submit = () => {
        let payload = {
            tier: tier,
            name: `${firstName} ${lastName}`,
            emailAddress: tetherosEmail,
            business: business,
            teamSize: getTeamSize()
        }
        api.notifySalesTeamOfLead(payload)
        .then((res) => {
            
        })
        .catch((err) => {
            console.log(err)
        })
        advancePage()
    }
    const handleKeyPress = (e) => {
        const code = e.code;
        if (!code) return
        if (code !== 'Enter') return
        if (page === 1 && !validateTextString(firstName)) return
        if (page === 2 && !validateTextString(lastName)) return
        if (page === 3 && !validateTextString(business)) return
        if (page === 5 && !isValidEmailAddress(tetherosEmail)) return
        advancePage()
    }
    const getTeamSize = () => {
        if (teamSize === "1") return "1"
        if (teamSize === "10") return "2-10"
        if (teamSize === "20") return "11-20"
        if (teamSize === "50") return "21-50"
        if (teamSize === "999") return "50+"
    }
    const validateTextString = (str) => {
        if (str === "") return false
        if (!str) return false
        return true
    }
    return (
        <div className="form-application-container">
            {page === 1 &&
            <div className="form-application-container-question-outer">
                <div className="form-application-container-question-inner">
                    <h2>Welcome to Tetheros!  What is your first name?</h2>
                    <input autoFocus={true} type="text" placeholder="Answer here..." value={firstName} onChange={(e) => setFirstName(e.target.value)} onKeyDown={(e) => handleKeyPress(e)}/>
                </div>
                <div className="form-application-container-nav-buttons form-application-container-question-inner">
                    <div></div>
                    {validateTextString(firstName) ? (
                        <div className="form-application-nav-button form-application-nav-button-next" onClick={advancePage} >Next</div>
                    ) : (
                        <div className="form-application-nav-waiting-text">Enter your first name to continue</div>
                    )}
                </div>
            </div>
            }

            {page === 2 &&
            <div className="form-application-container-question-outer">
                <div className="form-application-container-question-inner">
                    <h2>What about your last name?</h2>
                    <input autoFocus={true} type="text" placeholder="Answer here..." value={lastName} onChange={(e) => setLastName(e.target.value)} onKeyDown={(e) => handleKeyPress(e)}/>
                </div>
                <div className="form-application-container-nav-buttons form-application-container-question-inner">
                    <div className="form-application-nav-button" onClick={backPage}>Back</div>
                    {validateTextString(lastName) ? (
                        <div className="form-application-nav-button form-application-nav-button-next" onClick={advancePage} >Next</div>
                    ) : (
                        <div className="form-application-nav-waiting-text">Enter your last name to continue</div>
                    )}
                </div>
            </div>
            }

            {page === 3 &&
            <div className="form-application-container-question-outer">
                <div className="form-application-container-question-inner">
                    <h2>What is the name of your business?</h2>
                    <input autoFocus={true} type="text" placeholder="Answer here..." value={business} onChange={(e) => setBusiness(e.target.value)} onKeyDown={(e) => handleKeyPress(e)}/>
                </div>
                <div className="form-application-container-nav-buttons form-application-container-question-inner">
                    <div className="form-application-nav-button" onClick={backPage}>Back</div>
                    {validateTextString(business) ? (
                        <div className="form-application-nav-button form-application-nav-button-next" onClick={advancePage} >Next</div>
                    ) : (
                        <div className="form-application-nav-waiting-text">Enter your business name to continue</div>
                    )}
                </div>
            </div>
            }

            {page === 4 &&
            <div className="form-application-container-question-outer">
                <div className="form-application-container-question-inner">
                    <h2>What is your team size?</h2>
                    <select value={teamSize} onChange={(e) => setTeamSize(e.target.value)}>
                        <option value="1">1</option>
                        <option value="10">2-10</option>
                        <option value="20">11-20</option>
                        <option value="50">20-50</option>
                        <option value="999">50+</option>
                    </select>
                </div>
                <div className="form-application-container-nav-buttons form-application-container-question-inner">
                    <div className="form-application-nav-button" onClick={backPage}>Back</div>
                    <div className="form-application-nav-button form-application-nav-button-next" onClick={advancePage}>Next</div>
                </div>
            </div>
            }

            {page === 5 &&
            <div className="form-application-container-question-outer">
                <div className="form-application-container-question-inner">
                    <h2>What is your email?</h2>
                    <input autoFocus={true} type="text" placeholder="Answer here..." value={tetherosEmail} onChange={(e) => setTetherosEmail(e.target.value)} onKeyDown={(e) => handleKeyPress(e)}/>
                </div>
                <div className="form-application-container-nav-buttons form-application-container-question-inner">
                    <div className="form-application-nav-button" onClick={backPage}>Back</div>
                    {isValidEmailAddress(tetherosEmail) ? (
                        <div className="form-application-nav-button form-application-nav-button-next" onClick={advancePage} >Next</div>
                    ) : (
                        <div className="form-application-nav-waiting-text">Enter your email to continue</div>
                    )}
                </div>
            </div>
            }

            {page === 6 &&
            <div className="form-application-container-question-outer">
                <div className="form-application-container-question-inner">
                    <h2>Does everything look right to you, {firstName}?</h2>
                    <div className="form-application-container-review-answers">

                        <div className="form-application-container-review-answer-outer">
                            <div className="form-application-container-review-answer-label">Name</div>
                            <div className="form-application-container-review-answer-value">{firstName} {lastName}</div>
                        </div>

                        <div className="form-application-container-review-answer-outer">
                            <div className="form-application-container-review-answer-label">Business</div>
                            <div className="form-application-container-review-answer-value">{business}</div>
                        </div>

                        <div className="form-application-container-review-answer-outer">
                            <div className="form-application-container-review-answer-label">Team Size</div>
                            <div className="form-application-container-review-answer-value">{getTeamSize()}</div>
                        </div>

                        <div className="form-application-container-review-answer-outer">
                            <div className="form-application-container-review-answer-label">Email Address</div>
                            <div className="form-application-container-review-answer-value">{tetherosEmail}</div>
                        </div>
                    </div>
                </div>
                <div className="form-application-container-nav-buttons form-application-container-question-inner">
                    <div className="form-application-nav-button form-application-nav-button-next" onClick={submit}>Yes, submit</div>
                    <div className="form-application-nav-button" onClick={backPage}>No, go back</div>
                </div>
                <div className="form-application-container-question-inner form-application-container-review-consent">
                    By submitting this form, you consent to our sales team reaching out to your email to discuss your use of Tetheros.
                </div>
            </div>
            }

            {page === 7 &&
            <div className="form-application-container-question-outer">
                <div className="form-application-container-question-inner">
                    <h2>Thanks!</h2>
                    <div>Our team will email you as soon as possible - we can't wait to talk!</div>
                    <div className="form-application-container-review-complete-button" onClick={finishForm}>Back to the action</div>
                </div>
            </div>
            }

        </div>
    )
}
