import React from 'react'
import { getUserNameFromId, getUserProfileSrcFromId, getHyperLinkRegex } from '../../../common/Helpers'
import { formatDistanceToNow, parseISO } from 'date-fns';
import { FaPen, FaTrash } from 'react-icons/fa'
import { useState } from 'react';
import ProfPicCircle from '../../profiles/ProfPicCircle';
import { LinkIt } from 'react-linkify-it';
import { useHistory } from 'react-router-dom';

export default function ComponentComment({comment, team, deleteComment, updateComment, styles, height, hideProfilePicture}) {
    const history = useHistory()
    const [proposedComment, setProposedComment] = useState(() => { return comment.content })
    const [isEditingComment, setIsEditingComment] = useState(() => { return false })
    const [newCommentRows, setNewCommentRows] = useState(() => { return 4 })
    const finishChangingComment = () => {
        setIsEditingComment(false);
        if (proposedComment && (proposedComment !== comment.content)) {
            updateComment(comment._id, proposedComment)
        }
    }
    const transformStringWithMentions = (text) => {
        try {
            const mentionRegex = /@\w+/g;
            // Function to navigate to the user's page
            const goToUserPage = (username) => {
              history.push(`/u/${username}`);
            };
            return (
              <span>
                {text.split(mentionRegex).reduce((acc, part, index) => {
                  if (index === 0) return [part];
                  const mention = text.match(mentionRegex)[index - 1];
                  return [...acc, <span className="gadget-hashtag" key={index} onClick={() => goToUserPage(mention.slice(1))}>{mention}</span>, part];
                }, [])}
              </span>
            );
        } catch (error) {
            return text
        }

      }
    const updateDescriptionRows = (e) => {
        const MINIMUM_ROW_COUNT = 4;
        const breaks = e.target.value.split(/\r\n|\r|\n/).length + 1
        setNewCommentRows(Math.max(breaks, MINIMUM_ROW_COUNT))
    }
    const getContentStyles = () => {
        if (styles) {
            return styles;
        } else {
            return {}
        }
    }
    const buildAppropriateHyperLink = (match, key) => {
        const getClickableLink = link => {
            return link.startsWith("http://") || link.startsWith("https://") ? link : `https://${link}`;
        };
        return (
            <a href={getClickableLink(match)} target="_blank" rel="noreferrer" key={key}>{match}</a>
        )
    }
    return (
        <div className="component-comment-item">
            {!hideProfilePicture &&
            <ProfPicCircle src={getUserProfileSrcFromId(team, comment.author_id)} userId={comment.author_id} userName={getUserNameFromId(team, comment.author_id)} height={height ? height : 48} />}
            <div className="component-comment-item-container">
                <div className="component-comment-item-header-row">
                    <div className="component-comment-item-header">
                        <div style={getContentStyles()}>{getUserNameFromId(team, comment.author_id)}</div>
                        <span>{formatDistanceToNow(parseISO(comment.createdAt))} ago</span>
                    </div>

                    <div className="component-comment-item-options-container">
                        {((comment.author_id === localStorage.getItem("gId")) && !isEditingComment) && (
                            <>
                            {updateComment && <span className="component-comment-item-option" onClick={() => setIsEditingComment(true)}><FaPen/></span>}
                            {deleteComment && <span className="component-comment-item-option" onClick={() => deleteComment(comment._id)}><FaTrash/></span>}
                            </>
                        )}
                    </div>
                </div>
                {isEditingComment ? (
                    <div className="component-comment-item-content">
                        <textarea autoFocus={true} className="component-comment-item-content-edit" rows={newCommentRows} onKeyDown={(e) => updateDescriptionRows(e)}  placeholder="Have you thought about just deleting it instead?" value={proposedComment} onChange={(e) => setProposedComment(e.target.value)}></textarea>
                        <div style={{display: "flex", flexDirection: "row-reverse"}}>
                            <span onClick={() => finishChangingComment()} className="component-comment-item-content-edit-update">Update</span>
                        </div>
                    </div>
                ) : (
                    <div className="component-comment-item-content" style={getContentStyles()}>
                        <LinkIt component={(match, key) => { return buildAppropriateHyperLink(match, key)} } regex={getHyperLinkRegex() }>
                            {transformStringWithMentions(comment.content)}
                        </LinkIt>
                    </div>
                )}
                
            </div>

        </div>
  )
}
