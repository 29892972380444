import React, { useEffect, useState } from 'react'
import { useHistory  } from 'react-router-dom'
import { fetchAuth } from '../../common/Api'

import LoadingSymbolFacebook from '../gadgets/LoadingSymbolFacebook';

import UnauthenticatedHeader from '../header/UnauthenticatedHeader'

export default function RegisterConfirmation() {
    const history = useHistory()
    const [isError, setIsError] = useState(() => { return false })
    const updateAuthentication = () => {
        fetchAuth()
        .then((res) => {
            if (res.data.authenticated) {
                history.push("/home")
            } else {
                return setIsError(true)
            }
        })
        .catch((err) => {
            console.log(err)
            return setIsError(true)
        })
    }
    useEffect(() => {
        updateAuthentication()
    }, [])
    return (
        <div>
            <UnauthenticatedHeader />
            {isError &&
            <div className="register-confirmation">
                <h2>Oops</h2>
                <div>Something went wrong. Usually, this can be fixed by trying again.</div>
                <div onClick={() => history.push("/")} className="register-confirmation-error-button">Back to home</div>
            </div>
            }
            {!isError &&
            <div className="register-confirmation">
                <LoadingSymbolFacebook/>
                <div style={{color: "rgb(47, 47, 47)"}}>Loading...</div>
            </div>
            }
        </div>
    )
}
