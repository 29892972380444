import React from 'react'
import DataTableEntry from './DataTableEntry'

export default function DataTableRow({rowData, clickFunction}) {
    const getClassNameFromWidth = (width) => {
        const width2ClassMap = {
            lg: "data-table-col-lg",
            md: "data-table-col-md",
            sm: "data-table-col-sm"
        }

        if (width2ClassMap[width]) {
            return width2ClassMap[width]
        } else {
            return width2ClassMap["md"]
        }
    }
    const getAdditionalStyles = () => {
        let addedStyle = {}
        if (clickFunction) addedStyle = {
            cursor: "pointer"
        }
        return addedStyle
    }

    return (
        <div className="data-table-row-container unlink data-table-row-special-hover" onClick={clickFunction} style={getAdditionalStyles()}>
            {rowData.map((item) => (
                <span key={item._id} className={getClassNameFromWidth(item.width)}>
                    <DataTableEntry content={item.content} special={item.special}/>
                </span> 
            ))}
        </div>
    )
}
