import React from 'react'
import { Link } from 'react-router-dom';

export default function SidebarUserInfo({setSidebarCollapsed}) {
    const getCurrentUserInitials = () => {
        const firstName = localStorage.getItem("gFirstName");
        const lastName = localStorage.getItem("gLastName");
        return `${firstName.charAt(0)}${lastName.charAt(0)}`
    }
    const getCurrentUserName = () => {
        return localStorage.getItem("gFullName")
    }
    const getCurrentUserHandle = () => {
        if (localStorage.getItem("gHandle")) {
            return `@${localStorage.getItem("gHandle")}`
        } else {
            return ""
        }
    }
    const getUserProfileUrl = () => {
        return `/user/${localStorage.getItem("gId")}`
    }
    return (
        <div className="sidebar-left-section-user">
            {/* User Info Circle */}
            <Link className="unlink" to={getUserProfileUrl()}>
                <div className="u-profile-circle-grey-left-side">
                    {localStorage.getItem("gProfPicUrl") ? (
                        <img src={localStorage.getItem("gProfPicUrl")} alt="profile" />
                    ) : (
                        <>
                        {getCurrentUserInitials()}
                        </>
                    )}
                </div>
            </Link>

            {/* User Info Name and Handle */}
            <div>
                <Link className="unlink" to={getUserProfileUrl()}>
                    <div className="sidebar-left-section-user-name">{getCurrentUserName()}</div>
                    <div className="sidebar-left-section-user-handle">{getCurrentUserHandle()}</div>
                </Link>
            </div>

            {/* Collapse button */}
            {/* <AiOutlineDoubleLeft className="sidebar-show-button" onClick={() => setSidebarCollapsed(true)}/> */}
        </div> 
    )
}
