import React from "react";
import { useState } from 'react'

import { MdClose } from "react-icons/md";
import { IoDownloadOutline, IoCopyOutline, IoAddOutline } from "react-icons/io5";

export default function PromotionCalendarSettingsModal({ hideModal, eventData, eventDataDispatch, toolId }) {
    const [proposedName, setProposedName] = useState(() => { return eventData.display_name })
    const [animate, setAnimate] = useState(() => { return false });
    const saveAndClose = () => {
        hideModal()
        if (!proposedName) return
        if (proposedName.trim() === "") return
        eventDataDispatch({ type: "update_name", value: proposedName })
    }
    const downloadCSVFromJson = () => {
        const csvRows = [];
        const headers = ['Task', 'Due Date', 'Description', 'Status', 'Color'];
        csvRows.push(headers.join(','));
        const eventsWithDates = eventData.events.filter((event) => {
            if (!event.attributes) return false
            if (!event.attributes.date_due) return false
            return true
        })
        const sortedEvents = eventsWithDates.sort((a, b) => new Date(a.attributes.date_due) - new Date(b.attributes.date_due));
    
        sortedEvents.forEach(event => {
            const flattenedEvent = {
                display_name: event.display_name,
                date_due: event.attributes.date_due,
                description: event.attributes.description,
                status: event.status,
                color_hex: event.color_hex
            };
            const row = headers.map(field => JSON.stringify(flattenedEvent[field])).join(',');
            csvRows.push(row);
        });
        const csvContent = csvRows.join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', `${eventData.display_name}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    const copyLink = () => {
        navigator.clipboard.writeText(`https://tetheros.com/t/${toolId}`).then(() => {
            // Trigger the animation
            setAnimate(true);
            setTimeout(() => setAnimate(false), 500); // Reset animation state after 500ms
          }).catch(err => {
            return
          })
    }
    return (
        <div className="public-tool-promotion-calendar-modal-overlay">
            <div className="public-tool-promotion-calendar-modal-container public-tool-promotion-calendar-modal-container-settings">
                <div className="public-tool-promotion-calendar-modal-header">
                    <h2>Update calendar</h2>
                    <div className="util-row util-align-center" style={{ gap: "20px" }}>
                        <div onClick={hideModal} className="public-tool-promotion-calendar-modal-header-action"><MdClose /></div>
                    </div>
                </div>

                <div className="public-tool-promotion-calendar-modal-titles public-tool-promotion-calendar-modal-edits">
                    <div className="public-tool-promotion-calendar-modal-edits-label">Event Name</div>
                    <input placeholder="Enter an event title" value={proposedName} onChange={(e) => setProposedName(e.target.value)} />
                </div>

                <div className="public-tool-promotion-calendar-modal-text-and-button">
                    <div>
                        <div className="public-tool-promotion-calendar-modal-edits-label" style={{marginBottom: "4px"}}>Export</div>
                        <div style={{fontSize: "14px"}}>Download your content calendar as a spreadsheet-friendly CSV format</div>
                    </div>
                    <div className="public-tool-promotion-calendar-modal-text-button" onClick={downloadCSVFromJson}><IoDownloadOutline/></div>
                </div>

                <div className="public-tool-promotion-calendar-modal-text-and-button">
                    <div style={{overflow: "hidden", textOverflow: "ellipsis" }}>
                        <div className="public-tool-promotion-calendar-modal-edits-label" style={{marginBottom: "4px"}}>Copy Link</div>
                        <div style={{fontSize: "14px", textOverflow: "ellipsis", overflow: "hidden"}}>https://tetheros.com/t/{toolId}</div>
                    </div>
                    <div className={`public-tool-promotion-calendar-modal-text-button ${animate ? 'button-animate' : ''}`} onClick={copyLink}><IoCopyOutline/></div>
                </div>

                <div className="public-tool-promotion-calendar-modal-text-and-button">
                    <div style={{overflow: "hidden", textOverflow: "ellipsis" }}>
                        <div className="public-tool-promotion-calendar-modal-edits-label" style={{marginBottom: "4px"}}>Add to Tetheros</div>
                        <div style={{fontSize: "14px", textOverflow: "ellipsis", overflow: "hidden", color: "rgb(164, 164, 164)"}}>Coming soon</div>
                    </div>
                    <div className={`public-tool-promotion-calendar-modal-text-button`} style={{cursor: "default"}}><IoAddOutline style={{color: "rrgb(164, 164, 164)"}}/></div>
                </div>

                <div className="public-tool-promotion-calendar-modal-footer">
                    <div></div>
                    <div className="util-row util-align-stretch" style={{gap: "14px"}}>
                        <div className="public-tool-promotion-calendar-modal-footer-save public-tool-promotion-calendar-modal-footer-save-inverted" onClick={hideModal}>Cancel</div>
                        <div className="public-tool-promotion-calendar-modal-footer-save" onClick={saveAndClose}>Update calendar</div>
                    </div>
                </div>

            </div>
        </div>
  );
}
