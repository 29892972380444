import React from 'react'
import { useState, useEffect } from 'react'
import Api from '../../common/APIUtils'
import LoadingSymbolRipple from '../gadgets/LoadingSymbolRipple';
import { getFormattedDisplayWithUnits } from '../../common/Helpers';
import { format, parseISO } from 'date-fns';

import ModalSimpleHeader from './simple/ModalSimpleHeader'
import ModalToolSingleSelectRoleDropdownWithSearch from './tools/ModalToolSingleSelectRoleDropdownWithSearch'
import ModalSimpleSingleButton from './simple/ModalSimpleSingleButton'
import ToggleSwitch from '../gadgets/ToggleSwitch';

import DateBoxEditable from '../components/misc/DateBoxEditable';

export default function ModalCreateNewGoalInProject({ kpis, hideModal, parentProjectComponent, fetchProject }) {
    const api = new Api() 
    const [name, setName] = useState(() => { return "" })
    const [isKpiBased, setIsKpiBased] = useState(() => { return false })
    const [page, setPage] = useState(() => { return 1 })
    const [isValid, setIsValid] = useState(() => { return false })
    const [isSubmitting, setIsSubmitting] = useState(() => { return false })
    const [dueDate, setDueDate] = useState(() => { return "" })
    const [chosenKpi, setChosenKpi] = useState(() => { return false })
    const [kpiTarget, setKpiTarget] = useState(() => { return "" })
    const [kpiData, setKpiData] = useState(() => { return false })
    const handleEnterKeyPress = (e) => {
        if (e.code.toLowerCase() === 'enter') {
            if (page === 1 && isValid) return navigateFromPageOne()
        }
    }
    const hasKpis = () => {
        if (!Array.isArray(kpis)) return false
        if (kpis.length > 0) return true
        return false
    }
    const publishGoal = () => {
        // Create the key result
        if (!parentProjectComponent) return hideModal()
        setIsSubmitting(true)
        let attributes = {}
        if (isKpiBased) {
            let currentValue = 0;
            if (kpiData.attributes.val_current) currentValue = kpiData.attributes.val_current
            attributes = {
                type: 'kpi',
                val_start: currentValue,
                val_target: kpiTarget
            }
        }
        if (!isKpiBased) {
            attributes = {
                type: 'manual',
                val_start: 0,
                val_target: 100
            }
        }
        let payload = {
            workspace_id: parentProjectComponent.workspace_id,
            is_published: true,
            display_name: name,
            proposed_parent_ids: [parentProjectComponent._id],
        }
        if (name === "") payload['display_name'] = "Untitled Goal"
        if (dueDate === "" && parentProjectComponent.attributes.date_end) {
            attributes["date_due"] = parentProjectComponent.attributes.date_end
        } else {
            attributes["date_due"] = dueDate
        }
        payload['attributes'] = attributes
        api.createWorkspaceComponent(payload, `/components/plan/kresult`)
        .then( (res) => {
            if (res.status === 200) {
                const newGoalId = res.data.response.component._id
                updateKpiDataToReflectNewGoal(newGoalId)
            }
            hideModal()
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const getCurrentValue = () => {
        try {
            if (!kpiData) return 0
            const currentValue = kpiData.attributes.val_current;
            const units = kpiData.attributes.units;
            return getFormattedDisplayWithUnits(units, currentValue)
        } catch (error) {
            return 0
        }
    }
    const createGoalKpiBond = (newGoalId) => {
        const payload = {};
        payload['workspace_id'] = parentProjectComponent.workspace_id;
        payload['child_id'] = chosenKpi;
        payload['parent_id'] = newGoalId;
        api.createBond(payload)
        .then((res) => {
        });
    }
    const updateKpiDataToReflectNewGoal = (newGoalId) => {
        if (!hasKpis() || !kpiData || chosenKpi === "123") return fetchProject()
        // Create bond
        createGoalKpiBond(newGoalId)

        // Update child component parent_data property
        let payload = {}
        let parentData = {}
        let currentValue = 0;
        if (kpiData.attributes.val_current) currentValue = kpiData.attributes.val_current
        parentData[newGoalId] = {
            initial: currentValue,
            target: kpiTarget
        }
        payload['parent_data'] = parentData
        const path = `/workspace/components/${chosenKpi}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchProject()
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const getKpiInformation = () => {
        if (chosenKpi === "123") return false
        let filteredList = kpis.filter(a => a._id === chosenKpi)
        return filteredList[0]
    }
    const navigateFromPageOne = () => {
        if (isKpiBased) return setPage(2)
        setPage(3)
    }
    const navigateFromPageThree = () => {
        if (isKpiBased) return setPage(2)
        setPage(1)
    }
    const updateDueDate = (d) => {
        setDueDate(format(d, 'yyyy-MM-dd'))
    }
    const updateValidState = () => {
        try {
            if (!name) return setIsValid(false)
            if (name.trim() === "") return setIsValid(false)
            if (page === 1) return setIsValid(true)
            if (isKpiBased && !chosenKpi) return setIsValid(false)
            if (isKpiBased && !kpiData) return setIsValid(false)
            if (isKpiBased && !kpiTarget) return setIsValid(false)
            if (isKpiBased) {
                if (kpiTarget.toString() === kpiData.attributes.val_current.toString()) return setIsValid(false)
            }
            return setIsValid(true)
        } catch (error) {
            return setIsValid(false)
        }
    }
    useEffect(() => {
        setKpiData(getKpiInformation())
    // eslint-disable-next-line
    },[chosenKpi])
    useEffect(() => {
        if (!kpiData) return
        if (kpiData.attributes.val_current || kpiData.attributes.val_current === 0) setKpiTarget(kpiData.attributes.val_current)
    // eslint-disable-next-line
    }, [kpiData])
    useEffect(() => {
        updateValidState()
    // eslint-disable-next-line
    }, [isKpiBased, chosenKpi, kpiTarget, name, page])
    return (
        <div className="modal-mgmt-background-overlay" style={{zIndex: 200}}>
            {page === 1 &&
            <div className="modal-mgmt-container modal-mgmt-container-workstream-settings" style={{minHeight: "40vh"}}>
                <div>
                    <ModalSimpleHeader hideModal={hideModal} title="New Project Goal" />

                    <div className="modal-workstream-settings-setting-single modal-workstream-settings-setting-single-vertical">
                        <div className="modal-workstream-settings-setting-names">
                            <div className="modal-workstream-settings-setting-name">Goal name</div>
                        </div>
                        <div className="modal-workstream-settings-setting-value">
                            <div className="modal-workstream-settings-text-input-container">
                                <input onKeyUp={(e) => handleEnterKeyPress(e)} autoFocus={true} type="text" placeholder="e.g. Boost conversion rates, Encourage innovation, etc" value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    {kpis.length > 400 &&
                    <div className="modal-workstream-settings-setting-single">
                        <div className="modal-workstream-settings-setting-names">
                            <div className="modal-workstream-settings-setting-name">Track progress with a KPI</div>
                            <div className="modal-workstream-settings-setting-name-description">If you don't select a KPI to use, your goal progress will have to be manually tracked from 0 - 100%</div>
                        </div>
                        <div className="modal-workstream-settings-setting-value">
                            <div style={{display: "flex", flexDirection: "row-reverse"}}><ToggleSwitch isChecked={isKpiBased} setIsChecked={setIsKpiBased} /></div>
                        </div>
                    </div>
                    }
                </div>
                {!isSubmitting ? (
                    <div>
                        <div className="modal-workstream-settings-buttons util-row util-align-center util-row-reverse" style={{gap: "24px"}}>
                            <ModalSimpleSingleButton isValid={isValid} buttonText="Next" onclick={navigateFromPageOne} />
                            <ModalSimpleSingleButton isValid={true} invertStyle={true} buttonText="Cancel" onclick={hideModal} />
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="modal-project-create-child-form-buttons"><LoadingSymbolRipple/></div>
                        <div className="modal-project-create-child-form-buttons">
                            <div className="modal-project-create-child-form-button" onClick={hideModal}>Cancel</div>
                        </div>
                    </div>
                )}
                
            </div>
            }
            {page === 2 &&
            <div className="modal-mgmt-container modal-mgmt-container-workstream-settings" style={{minHeight: "40vh"}}>
                <div>
                    <ModalSimpleHeader hideModal={hideModal} title="Goal Settings" />

                    {isKpiBased && kpis.length > 0 &&
                    <div className="modal-workstream-settings-setting-single modal-workstream-settings-setting-single-vertical">
                        <div className="modal-workstream-settings-setting-names">
                            <div className="modal-workstream-settings-setting-name">Which metric do you want to track for this goal?</div>
                        </div>
                        <div className="modal-workstream-settings-setting-value">
                            <div className="modal-workstream-settings-text-input-container">
                                <ModalToolSingleSelectRoleDropdownWithSearch displayAttributeName="display_name" searchText="Search KPIs..." setSelectedId={setChosenKpi} allOptions={kpis} selectedId={chosenKpi} positionTop={false}/>
                            </div>
                        </div>
                    </div>
                    }


                    {chosenKpi && isKpiBased && kpiData &&
                    <>
                    <div className="modal-workstream-settings-setting-single modal-workstream-settings-setting-single-vertical">
                        <div className="modal-workstream-settings-setting-names">
                            <div className="modal-workstream-settings-setting-name">Current value</div>
                        </div>
                        <div className="modal-workstream-settings-setting-value">
                            <div className="modal-workstream-settings-text-input-container">
                                <div>{getCurrentValue()}</div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-workstream-settings-setting-single modal-workstream-settings-setting-single-vertical">
                        <div className="modal-workstream-settings-setting-names">
                            <div className="modal-workstream-settings-setting-name">Set a target value for the project</div>
                        </div>
                        <div className="modal-workstream-settings-setting-value">
                        <div className="modal-workstream-settings-text-input-container">
                                <input autoFocus={true} type="number" value={kpiTarget} onChange={(e) => setKpiTarget(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    </>
                    }
                </div>
                {!isSubmitting ? (
                    <div>
                        <div className="modal-workstream-settings-buttons util-row util-align-center util-row-reverse" style={{gap: "24px"}}>
                            <ModalSimpleSingleButton isValid={isValid} buttonText="Next" onclick={() => setPage(3)} />
                            <ModalSimpleSingleButton isValid={true} invertStyle={true} buttonText="Back" onclick={() => setPage(1)} />
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="modal-project-create-child-form-buttons"><LoadingSymbolRipple/></div>
                        <div className="modal-project-create-child-form-buttons">
                            <div className="modal-project-create-child-form-button" onClick={hideModal}>Cancel</div>
                        </div>
                    </div>
                )}
                
            </div>
            }
            {page === 3 &&
            <div className="modal-mgmt-container modal-mgmt-container-workstream-settings" style={{minHeight: "50vh"}}>
                <div>
                    <ModalSimpleHeader hideModal={hideModal} title="Goal Settings" />
                    <div className="modal-workstream-settings-setting-single">
                        <div className="modal-workstream-settings-setting-names">
                            <div className="modal-workstream-settings-setting-name">Due date</div>
                            <div className="modal-workstream-settings-setting-name-description">When do you want to accomplish this goal? Leave blank to automatically match the end of the project ({format(parseISO(parentProjectComponent.attributes.date_end), "PPP")}).</div>
                        </div>
                        <div className="modal-workstream-settings-setting-value">
                            <div style={{display: "flex", flexDirection: "row-reverse"}}>
                                <div style={{position: "absolute"}}>
                                    <DateBoxEditable component={false} side="right" editable={true} date={parseISO(dueDate)} label="" onupdate={updateDueDate} clearDate={() => setDueDate("")} /> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {!isSubmitting ? (
                    <div>

                        <div className="modal-workstream-settings-buttons util-row util-align-center util-row-reverse" style={{gap: "24px"}}>
                            <ModalSimpleSingleButton isValid={isValid} buttonText="Create project goal" onclick={publishGoal} />
                            <ModalSimpleSingleButton isValid={true} invertStyle={true} buttonText="Back" onclick={navigateFromPageThree} />
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="modal-project-create-child-form-buttons"><LoadingSymbolRipple/></div>
                        <div className="modal-project-create-child-form-buttons">
                            <div className="modal-project-create-child-form-button" onClick={hideModal}>Cancel</div>
                        </div>
                    </div>
                )}
                
            </div>
            }
        </div>
    )
}
