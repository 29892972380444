import React from 'react'
import { FaTimes } from 'react-icons/fa';
import { useState, useEffect } from 'react';

export default function ModalManageWorkspaceUserMembership({user, team, updatePrivateWorkspaceUserMembership, workspace, hideModal}) {
    const [proposedRole, setProposedRole] = useState(() => { return ""})
    const getModalTitle = () => {
        if (!user) return "Manage User"
        if (!user.firstName) return "Manage User"
        return `${user.firstName} ${user.lastName}`
    }
    const getUserRole = () => {
        if (!user || !team || !workspace) return ""
        if (!user.user_membership) return ""
        if (!user.user_membership.role_id) return ""
        if (!workspace.workspace_roles) return ""
        const userRoleId = user.user_membership.role_id
        const role = workspace.workspace_roles.find(r => r._id === userRoleId)
        if (role._id) return role._id
        return ""
    }
    const hasBeenUpdated = () => {
        if (getUserRole() !== proposedRole) return true
        return false
    }
    const getRoles = () => {
        if (!workspace) return []
        if (!workspace.workspace_roles) return []
        return workspace.workspace_roles
    }
    const getSaveButtonStyles = () => {
        if (hasBeenUpdated()) return {}
        return {
            cursor: "not-allowed",
            backgroundColor: "grey"
        }
    }
    const removeUserFromWorkspace = () => {
        const payload = {
            workspace_id: workspace._id,
            user_id: user._id,
            role_id: ""
        }
        updatePrivateWorkspaceUserMembership(payload)
        hideModal()
    }
    const attemptSaving = () => {
        if (hasBeenUpdated()) {
            const payload = {
                workspace_id: workspace._id,
                user_id: user._id,
                role_id: proposedRole
            }
            return updatePrivateWorkspaceUserMembership(payload)
        }
    }
    useEffect(() => {
        setProposedRole(getUserRole())
    // eslint-disable-next-line
    }, [])
    return (
        <div className="alignment-modal-background">
            <div className="modal-manage-user-membership-container">

                <div className="modal-task-create-header-row" style={{paddingBottom: "0px"}}>
                    <div className="modal-task-create-header-title">{getModalTitle()}</div>
                    <div className="modal-side-edit-header-row" style={{borderBottom: "none"}}>
                        <FaTimes className="project-post-form-cancel" onClick={hideModal}/>
                    </div>
                </div>

                {/* Search And Find */}
                <div className="modal-network-manage-users-section">
                    <div className="modal-manage-user-membership-section">
                        <div className="modal-manage-user-membership-section-title">Role</div>

                        <form className="modal-network-manage-users-form">
                            <select className="modal-manage-user-membership-dropdown" value={proposedRole} onChange={(e) => setProposedRole(e.target.value)}>
                                <option value="">--None--</option>
                                {getRoles().map((r) => (
                                    <option value={r._id} key={r._id}>{r.name}</option>
                                ))}
                            </select>
                        </form>
                    </div>

                    {proposedRole === "" && (
                        <div className="modal-manage-user-membership-section">
                            This will remove all user privileges and deactivate their workspace membership.
                        </div>
                    )}

                    {/* Instructional Box */}
                    <div className="modal-network-manage-users-section modal-manage-user-membership-role-container">
                        <div className="modal-manage-user-membership-role">
                            <p>User</p>
                            <div>- Create, view, and update components and templates.</div>
                        </div>

                        <div className="modal-manage-user-membership-role">
                            <p>Leader</p>
                            <div>- Create, view, and update components and templates.</div>
                            <div>- Delete components and templates.</div>
                            <div>- Add and remove members</div>
                        </div>

                        <div className="modal-manage-user-membership-role">
                            <p>Owner</p>
                            <div>- Create, view, and update components and templates.</div>
                            <div>- Delete components and templates.</div>
                            <div>- Add and remove members</div>
                            <div>- Update workspace settings</div>
                            <div>- Manage user roles</div>
                        </div>
                    </div>
                    

                    <div className="modal-manage-user-membership-section modal-manage-user-membership-buttons" style={{marginBottom: "1em"}}>
                        <div className="modal-manage-user-membership-save-button" onClick={attemptSaving} style={getSaveButtonStyles()}>Save</div>
                        <div className="modal-manage-user-membership-cancel-button" onClick={hideModal}>Cancel</div>
                    </div>

                    <div className="modal-manage-user-membership-section modal-manage-user-membership-buttons">
                        <span className="modal-manage-user-membership-revoke-button" onClick={removeUserFromWorkspace}>Remove User From Workspace</span>
                    </div>
                </div>

            </div>
        </div>
    )
}
