import React from 'react'
import { useState, useEffect } from 'react';
import Api from '../../common/APIUtils';
import LoadingSymbolRipple from '../gadgets/LoadingSymbolRipple'
import LoadingSymbolGrid from '../gadgets/LoadingSymbolGrid'
import { ReactComponent as ConfirmInvitationSent } from '../../static/assets/invitation-sent-confirm.svg';
import ModalSimpleHeader from './simple/ModalSimpleHeader'
import ModalSimpleSingleButton from './simple/ModalSimpleSingleButton'
import ModalToolSingleSelectRoleDropdownWithSearch from './tools/ModalToolSingleSelectRoleDropdownWithSearch';
import { getUserNameFromId } from '../../common/Helpers';

export default function ModalImportPrivateWorkspace({network, hideModal}) {
    const api = new Api()
    const [workspaces, setWorkspaces] = useState(() => { return [] })
    const [isImportable, setIsImportable] = useState(() => { return false })
    const [isLoadingModal, setIsLoadingModal] = useState(() => { return true })
    const [page, setPage] = useState(() => { return 1 })
    const [disconnectedUsers, setDisconnectedUsers] = useState(() => { return [] })
    const [selectedTeamId, setSelectedTeamId] = useState(() => { return false })
    const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(() => { return false })
    const [anErrorOccurred, setAnErrorOccurred] = useState(() => { return false })
    const [proposedWorkspace, setProposedWorkspaceData] = useState(() => { return false })
    const fetchWorkspaces = () => {
        const privateWorkspaceFilter = (wrkspce) => {
            if (wrkspce.workspace_type !== 'private') return false
            if (wrkspce.creator_id === localStorage.getItem("gId")) return true
            return false
        }
        api.getWorkspaces()
        .then((res) => {
            if (res.data) {
                const totalWorkspaces = res.data.workspaces;
                const privateWorkspaces = totalWorkspaces.filter((workspace) => {
                    return privateWorkspaceFilter(workspace)
                })
                setWorkspaces(privateWorkspaces)
                setIsLoadingModal(false)
            }
        })
        .catch((err) => {
            console.log(err);
        })  
        
    }
    const triggerWorkspaceImport = (withUsers) => {
        setPage(11)
        if (!selectedWorkspaceId || !network.slug) return setPage(20)
        try {
            let payload = {
                workspace_id: selectedWorkspaceId
            }
            if (withUsers) payload['proposed_user_ids'] = disconnectedUsers
            if (selectedTeamId) payload['proposed_team_id'] = selectedTeamId
            api.importPrivateWorkspaceToNetwork(payload, network.slug)
            .then((res) => {
                setPage(3)
            })
            .catch((err) => { setPage(20) })
        } catch (error) {
            setPage(20)
        }
    }
    const fetchProposedWorkspace = () => {
        if (!selectedWorkspaceId) return
        api.getWorkspace({ id: selectedWorkspaceId })
        .then((res) => {
            setProposedWorkspaceData(res.data)
        })
        .catch((err) => {
            return
        })
    }
    const getTeamOptions = () => {
        if (!network) return []
        if (!network.all_teams) return []
        return [{ _id: false, name: "None"}, ...network.all_teams]
    }
    const getListOfTeammatesNotInNetwork = () => {
        // Reviews the proposedWorkspace to see which users are not already in the network
        if (!proposedWorkspace || !network) return
        try {
            const networkMemberships = network.memberships
            const networkMemberUserIds = networkMemberships.map(u => u.member_id)
            const workspaceTeammates = proposedWorkspace.team
            const workspaceTeammateIds = workspaceTeammates.map(t => t._id)
            let userIdsOnTeamProposedToHaveAccess = []
            if (selectedTeamId) {
                const userTeamMemberships = network.user_team_memberships
                const userTeamMembershipsOnlyProposed = userTeamMemberships.filter((membership) => {
                    if (membership.membership_to_id === selectedTeamId) return true
                    return false
                })
                userIdsOnTeamProposedToHaveAccess = userTeamMembershipsOnlyProposed.map(u => u.member_id)
            }
            
            let usersNotInNetwork = []
            for (let i = 0; i < workspaceTeammateIds.length; i++) {
                const userId = workspaceTeammateIds[i];
                if (networkMemberUserIds.includes(userId)) continue
                if (userIdsOnTeamProposedToHaveAccess.includes(userId)) continue
                usersNotInNetwork.push(userId)
            }
            setDisconnectedUsers(usersNotInNetwork)
            if (usersNotInNetwork.length === 0) {
                // setPage(3)
                triggerWorkspaceImport(true)
            } else {
                setPage(2)
            }
        } catch (error) {
            return setAnErrorOccurred(true)
        }
    }
    useEffect(() => {
        fetchWorkspaces()
    // eslint-disable-next-line
    }, [network])
    useEffect(() => {
        if (!selectedWorkspaceId) {
            setIsImportable(false)
            return
        }
        setIsImportable(true)
    // eslint-disable-next-line
    }, [selectedWorkspaceId])
    useEffect(() => {
        if (page === 10) getListOfTeammatesNotInNetwork()
    // eslint-disable-next-line
    }, [proposedWorkspace, page])
    useEffect(() => {
        if (page === 10) fetchProposedWorkspace()
    // eslint-disable-next-line
    }, [page])
    useEffect(() => {
        if (anErrorOccurred) setPage(20)
    }, [anErrorOccurred])
    return (
        <div className="modal-mgmt-background-overlay">

            {isLoadingModal &&
            <div className="modal-mgmt-container">
                <ModalSimpleHeader title="Import private workspace to network" hideModal={hideModal} />
                <div style={{padding: "48px", display: "flex", justifyContent: "center"}}>
                    <LoadingSymbolRipple/>
                </div>
                
            </div>
            }
            {page === 20 &&
            <div className="modal-mgmt-container">
                <ModalSimpleHeader title=" " hideModal={hideModal} />
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                    <h3>Something went wrong.</h3>
                    <p>Try again in a few minutes</p>
                </div>
                <div className="util-row util-justify-center" style={{marginTop: "24px"}}>
                    <ModalSimpleSingleButton isValid={true} invertStyle={true} buttonText="Go Back" onclick={hideModal} />
                </div>
                
            </div>
            }
            {page === 10 &&
            <div className="modal-mgmt-container">
                <ModalSimpleHeader title="Import private workspace to network" hideModal={hideModal} />
                <div style={{padding: "48px", display: "flex", justifyContent: "center"}}>
                    <div className="util-row util-col util-justify-center util-align-center" style={{gap: "20px"}}>
                        <LoadingSymbolRipple/>
                        <p>Calculating if anyone might get left behind...</p>
                    </div>
                </div>
                
            </div>
            }
            {page === 11 &&
            <div className="modal-mgmt-container">
                <ModalSimpleHeader title=" " hideModal={hideModal} />
                <div style={{padding: "48px", display: "flex", justifyContent: "center"}}>
                    <div className="util-row util-col util-justify-center util-align-center" style={{gap: "20px"}}>
                        <LoadingSymbolGrid/>
                        <p>Importing <strong>{proposedWorkspace.workspace.name}</strong> into the <strong>{network.name}</strong> network...</p>
                    </div>
                </div>
                
            </div>
            }
            {!isLoadingModal && page === 1 &&
            <div className="modal-mgmt-container">

                <ModalSimpleHeader title="Import private workspace to network" hideModal={hideModal} />

                <div className="modal-mgmt-sections">
                    {workspaces.length > 0 &&
                    <div className="modal-mgmt-section">
                        <h3>Select a Workspace</h3>
                        <ModalToolSingleSelectRoleDropdownWithSearch searchText="Search workspaces..." setSelectedId={setSelectedWorkspaceId} allOptions={workspaces} selectedId={selectedWorkspaceId}/>
                    </div>
                    }

                    {selectedWorkspaceId &&
                    <div className="modal-mgmt-section">
                        <h3>Select a network team to grant initial access</h3>
                        <ModalToolSingleSelectRoleDropdownWithSearch searchText="Search teams..." setSelectedId={setSelectedTeamId} allOptions={getTeamOptions()} selectedId={selectedTeamId}/>
                    </div>
                    }

                    {workspaces.length === 0 &&
                    <div className="modal-mgmt-section">
                        <p>You don't have any private workspaces to import.</p>
                    </div>
                    }
                    <ModalSimpleSingleButton isValid={isImportable} buttonText="Next" onclick={() => setPage(10)} />
                </div>
            </div>
            }
            {!isLoadingModal && page === 2 &&
            <div className="modal-mgmt-container">
                <ModalSimpleHeader title="Confirm workspace import" hideModal={hideModal} />
                <div className="modal-mgmt-sections">
                    <div className="modal-mgmt-section">
                        <h3>The following users will lose access to the workspace if they are not added to the network:</h3>
                        {disconnectedUsers.map((user) => (
                            <p key={user}>{getUserNameFromId(proposedWorkspace.team, user)}</p>
                        ))}
                        <div className="modal-mgmt-info-box">
                            Imported users will be granted <strong>Workspace User</strong> access to the workspace. You can change this later.
                        </div>
                    </div>

                    <div className="util-row util-space-between">
                        <div>
                            <ModalSimpleSingleButton invertStyle={true} isValid={isImportable} buttonText="Go Back" onclick={() => setPage(1)} />
                        </div>
                        <div className="util-row util-justify-end" style={{gap: "24px"}}>
                            <ModalSimpleSingleButton isValid={isImportable} buttonText="Import with users" onclick={() => triggerWorkspaceImport(true)} />
                            <ModalSimpleSingleButton isValid={isImportable} invertStyle={true} buttonText="Import without users" onclick={() => triggerWorkspaceImport(false)} />
                        </div>
                    </div>


                    
                </div>
            </div>
            }

            {!isLoadingModal && page === 3 &&
            <div className="modal-mgmt-container">

                <ModalSimpleHeader title=" " hideModal={hideModal} />
                <div className="modal-mgmt-confirm-container">
                    <ConfirmInvitationSent/>
                    <h3>Import Successful!</h3>
                    <div className="util-row util-justify-center" style={{marginTop: "24px"}}>
                        <ModalSimpleSingleButton isValid={true} buttonText="To Workspaces" onclick={hideModal} />
                    </div>
                </div>
            </div>
            }

        </div>
    )
}
