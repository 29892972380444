import React from 'react'
import { AiOutlineTeam, AiOutlineSetting, AiOutlineCopy } from 'react-icons/ai';
import { BiBriefcaseAlt, BiBoltCircle, BiUser, BiTargetLock, BiAnchor } from 'react-icons/bi'
import { BsCurrencyDollar } from 'react-icons/bs'
import { GoCalendar } from 'react-icons/go'
import { useHistory } from 'react-router-dom';

export default function NetworkCatalogItem({symbol, title, subtitle, link}) {
    const history = useHistory();
    const navigate = () => {
        if (!link) return
        history.push(link);
    }
    const symbolMap = {
        workspaces: <BiBriefcaseAlt style={{ color: "rgb(86, 86, 218)" }}/>,
        teams: <AiOutlineTeam style={{ color: "rgb(253, 105, 130)" }}/>,
        billing: <BsCurrencyDollar style={{ color: "rgb(44, 124, 44)" }}/>,
        settings: <AiOutlineSetting style={{ color: "rgb(44, 124, 44)" }}/>,
        users: <BiUser style={{color: "goldenrod"}} />,
        templates: <AiOutlineCopy style={{ color: "#48BEFF" }} />,
        goal: <BiTargetLock style={{ color: "rgb(44, 124, 44)" }} />,
        initiative: <BiAnchor style={{ color: "#7c2c41" }} />,
        calendar: <GoCalendar style={{ color: "#FC636B" }} />,
    }
    const getSymbol = () => {
        if (!symbolMap[symbol]) return <BiBoltCircle style={{ color: "grey" }}/>
        return symbolMap[symbol]
    }
    return (
        <div className="network-home-action-grid-single-container" onClick={navigate}>
            <div className="network-home-action-grid-single-symbol">{getSymbol()}</div>
            <div className="network-home-action-grid-single-text-container">
                <h2>{title}</h2>
                <p>{subtitle}</p>
            </div>
        </div>
    )
}
