import React, { useState, useEffect, useRef } from 'react'

import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from "react-icons/md";

export default function PostImagePreview({ images, selectImageToView }) {
    const [totalImages, setTotalImages] = useState(() => { return 0 })
    const [containerStyle, setContainerStyle] = useState(() => { return {} })
    const singleImageRef = useRef(null);
    const updateTotalImages = () => {
        try {
            if (!images) return
            setTotalImages(images.length)
        } catch (error) {
            return setTotalImages(0)
        }
    }
    useEffect(() => {
        updateTotalImages()
    // eslint-disable-next-line
    }, [images])
    useEffect(() => {
        if (totalImages === 0) return
        if (totalImages > 1) return
        const handleResize = () => {
            if (singleImageRef.current) {
                const width = singleImageRef.current.naturalWidth;
                const height = singleImageRef.current.naturalHeight;
                if (width > height) {
                    // Set container's padding-bottom based on the image's height to width ratio
                    setContainerStyle({
                        paddingBottom: `${(height / width) * 100}%`,
                        height: 0
                    });
                } else {
                    // Reset to default square behavior
                    setContainerStyle({
                        paddingBottom: '100%',
                        height: 0
                    });
                }
            }
        };

        const imgEl = singleImageRef.current;
        if (imgEl.complete) {
            handleResize(); // Image is already loaded
        } else {
            imgEl.addEventListener('load', handleResize);
        }

        return () => {
            imgEl.removeEventListener('load', handleResize);
        };
    // eslint-disable-next-line
    }, [images, totalImages]);
    return (
        <div className="post-image-preview-container">
            {totalImages === 1 &&
            <div className="post-image-single" style={containerStyle} onClick={(e) => selectImageToView(e, images[0], 0)}>
                <img src={images[0]} alt="Feed post" ref={singleImageRef} />
            </div>
            }
            {totalImages > 1 && <ImageCarousel images={images} selectImageToView={selectImageToView} />}
        </div>
    );
}

function ImageCarousel({ images, selectImageToView }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [containerStyle, setContainerStyle] = useState(() => { return {} })
    const imageRef = useRef(null);
    useEffect(() => {
        const handleResize = () => {
            if (imageRef.current) {
                const width = imageRef.current.naturalWidth;
                const height = imageRef.current.naturalHeight;
                if (width > height) {
                    // Set container's padding-bottom based on the image's height to width ratio
                    setContainerStyle({
                        paddingBottom: `${(height / width) * 100}%`,
                        height: 0
                    });
                } else {
                    // Reset to default square behavior
                    setContainerStyle({
                        paddingBottom: '100%',
                        height: 0
                    });
                }
            }
        };

        const imgEl = imageRef.current;
        if (imgEl.complete) {
            handleResize(); // Image is already loaded
        } else {
            imgEl.addEventListener('load', handleResize);
        }

        return () => {
            imgEl.removeEventListener('load', handleResize);
        };
    // eslint-disable-next-line
    }, [images, currentIndex]);
    const goToPrevious = (event) => {
        event.stopPropagation()
        const isFirstImage = currentIndex === 0;
        const newIndex = isFirstImage ? images.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const goToNext = (event) => {
        event.stopPropagation()
        const isLastImage = currentIndex === images.length - 1;
        const newIndex = isLastImage ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    return (
        <div className="post-image-single post-image-carousel-container" style={containerStyle} onClick={(e) => selectImageToView(e, images[currentIndex], currentIndex)}>
            {currentIndex > 0 && <div onClick={goToPrevious} className="post-image-carousel-arrow post-image-carousel-left-arrow"><MdOutlineArrowBackIosNew/></div>}
            <img src={images[currentIndex]} alt="Displayed content" ref={imageRef} />
            {currentIndex < (images.length - 1) && <div onClick={goToNext} className="post-image-carousel-arrow post-image-carousel-right-arrow"><MdOutlineArrowForwardIos/></div>}
        </div>
    );
}