import React from 'react'
import { getMetricDisplayValue } from '../../common/Helpers'
import { BsArrowRight } from 'react-icons/bs'
import { useState, useEffect } from 'react'
import LoadingSymbolRipple from '../gadgets/LoadingSymbolRipple'

export default function ModalUpdateKeyResultSettings({hideModal, kpiComponent, keyResultComponent, updateComponent}) {
    const [valTarget, setValTarget] = useState(() => { return 0 })
    const [krComponentId, setKrComponentId] = useState(() => { return false })
    const getFormattedChosenMetric = () => {
        if (!kpiComponent) return
        return getMetricDisplayValue(kpiComponent)
    }
    const updateProgress = () => {
        if (!updateComponent) return hideModal()
        updateComponent({attributes: {val_target: valTarget}})
        hideModal()
    }
    const getKeyResultComponent = () => {
        if (!keyResultComponent) return false
        if (!keyResultComponent.attributes) return false
        return keyResultComponent
    }
    const getMetricUnits = () => {
        if (!kpiComponent) return false
        if (!kpiComponent.attributes) return false
        if (!kpiComponent.attributes.units || kpiComponent.attributes.units === "") return false
        return kpiComponent.attributes.units
    }
    useEffect(() => {
        if (!keyResultComponent) return
        if (!keyResultComponent._id) return
        setKrComponentId(keyResultComponent._id)
    // eslint-disable-next-line
    }, [keyResultComponent])
    useEffect(() => {
        if (keyResultComponent._id === krComponentId) return
        if (!keyResultComponent) return
        if (!keyResultComponent.attributes) return
        if (!keyResultComponent.attributes.val_target) return
        setValTarget(keyResultComponent.attributes.val_target)
    // eslint-disable-next-line
    }, [krComponentId])
    return (
        <div className="alignment-modal-background">
            <div className="modal-key-result-update-container">
                <h1>Update Key Result</h1>
                {getKeyResultComponent() ? (
                    <>
                    <div className="modal-project-create-child-form-options modal-key-result-update-form-options">
                        <h2>Current Target</h2>
                        <div className="form-network-create-form-section-row" style={{gap: "1em", boxSizing: "border-box"}}>
                            <div style={{fontSize: "20px"}}>{keyResultComponent.attributes.val_start}</div>
                            <BsArrowRight/>
                            <div style={{fontSize: "20px", fontWeight: "600"}}>{keyResultComponent.attributes.val_target}</div>
                        </div>
                        <p style={{textAlign: "left"}}>Metric is currently {getFormattedChosenMetric()}</p>
                    </div>
                    <div className="modal-project-create-child-form-options modal-key-result-update-form-options">
                        <h2>Change target?</h2>
                        <div className="form-network-create-form-section-row" style={{gap: "1em", boxSizing: "border-box"}}>
                            <div style={{fontSize: "20px"}}>{keyResultComponent.attributes.val_start}</div>
                            <BsArrowRight/>
                            <div className="form-network-create-form-metric-input-container">
                            {getMetricUnits() === "$" && <span style={{fontSize: "20px"}}>$</span> }
                            <input style={{fontSize: "20px"}} type="number" value={valTarget} onChange={(e) => setValTarget(e.target.value)} />
                            {getMetricUnits() !== "$" && <span style={{fontSize: "20px"}}>{getMetricUnits()}</span> }
                            </div>
                        </div>
                    </div>
                    </>
                    ) : (
                    <div style={{marginLeft: "auto", marginRight: "auto"}}><LoadingSymbolRipple/></div>
                    )}
                <div className="modal-project-create-child-form-buttons">
                    <div className="modal-project-create-child-form-button modal-project-create-child-form-button-create" onClick={updateProgress}>Update Progress</div>
                    <div className="modal-project-create-child-form-button" onClick={hideModal}>Cancel</div>
                </div>                
            </div>
        </div>
    )
}
