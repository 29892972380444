import React from 'react'
import { useState } from 'react';
import Api from '../../../common/APIUtils';
import '../../../styles/modals.css';
import { FaTimes, FaTasks, FaRunning, FaThLarge } from 'react-icons/fa'
import { BiTargetLock, BiCustomize, BiTrendingUp, BiSearch, BiKey, BiLinkAlt } from 'react-icons/bi'
import HProgressBar from '../../HProgressBar';
import LoadingSymbolRipple from '../../gadgets/LoadingSymbolRipple';

export default function ComponentTimelineAddWorkstream({component, setIsAddingNewMarker, potentialBondIds, createNewBond, type, types}) {
    const api = new Api()
    const [query, setQuery] = useState(() => { return ""})
    const [allComponents, setAllComponents] = useState(() => { return []})
    const [finishedLoading, setFinishedLoading] = useState(() => { return false})
    const getComponentSymbol = (name) => {
        const symbolMap = {
            list: <FaTasks className="gomano-card-component-symbol"/>,
            sprint: <FaRunning className="gomano-card-component-symbol"/>,
            kanban: <FaThLarge className="gomano-card-component-symbol"/>,
            goal: <BiTargetLock className="gomano-card-component-symbol"/>,
            board: <BiCustomize className="gomano-card-component-symbol"/>,
            kpi: <BiTrendingUp className="gomano-card-component-symbol"/>,
            kresult: <BiKey className="gomano-card-component-symbol"/>,
            link: <BiLinkAlt className="gomano-card-component-symbol" />
        }
        return symbolMap[name]
    }
    const getAllComponents = async () => {
        if (!component) return setFinishedLoading(true)
        if (component.workspace_id) {
            var payload = { workspaceId: component.workspace_id }
        }
        if (component.network_id) {
            payload = { networkId: component.network_id }
        }

        api.getAllWorkspaceComponents(payload)
        .then((res) => {
            if (res.data) {
                setAllComponents(res.data.response.components);
                setFinishedLoading(true)
            } else {
                console.log('Problem fetching components')
            }
        })
        .catch((err) => {
            console.log(err);
        });        
    }
    const getProgressMetricForComponent = (component) => {
        if (component.progress) {
            return Math.round(component.progress*100);
        } else {
            return 0
        }
    }
    const getValidBondsForType = () => {
        let allowedComponentBondIds = []
        for (let i = 0; i < potentialBondIds.length; i++) {
            const componentId = potentialBondIds[i];
            if (!allComponents[componentId]) continue
            if (types) {
                if (!types.includes(allComponents[componentId]['name'])) continue
            }
            if (!type && allComponents[componentId]['status'] !== "archived" && allComponents[componentId]['status'] !== "completed") {
                allowedComponentBondIds.push(componentId)
                continue
            }
            if (allComponents[componentId]['name'] === type) allowedComponentBondIds.push(componentId)
        }
        if (!query) return allowedComponentBondIds
        const filteredAllowedComponentBondIds = allowedComponentBondIds.filter((componentBondId) => {
            const displayName = allComponents[componentBondId]['display_name'].toLowerCase()
            if (displayName.indexOf(query.toLowerCase()) !== -1) return true
            return false
        })
        return filteredAllowedComponentBondIds
    }
    const transformName = (currentName) => {
        if (currentName === "kresult") return "Goal"
        return currentName
    }
    useState(() => {
        getAllComponents()
    },[])
    return (
        <div className="alignment-modal-background">
            <div className="goal-marker-modal-form-container">
                <div className="goal-marker-modal-form-header">
                    <h3>Add workstream</h3>
                    <FaTimes className="goal-marker-modal-form-cancel" onClick={(e) => setIsAddingNewMarker(false)}/>
                </div>
                <div className="gomano-search-container">
                    <BiSearch/>
                    <input type="text" autoFocus={true} value={query} onChange={(e) => setQuery(e.target.value)} placeholder="Find something" />
                </div>


                {getValidBondsForType().length > 0 && finishedLoading && (
                    <div className="goal-marker-new-option-card-container">
                        {/* Create a card for every possible BOND */}
                        {getValidBondsForType().map((cId) => (
                            <div className="goal-marker-new-option-card" key={cId}>

                                <div className="gomano-card-top">
                                    <div className="gomano-card-row">
                                        {getComponentSymbol(allComponents[cId]['name'])}
                                        <div className="gomano-card-component-type">{transformName(allComponents[cId]['name'])}</div>
                                    </div>
                                    
                                </div>

                                <div className="gomano-card-middle">
                                    <div className="gomano-card-component-name">{allComponents[cId]['display_name']}</div>
                                    {/* Show archived badge if the component is archived */}
                                    {allComponents[cId]['status'] === 'archived' &&
                                        <div>
                                            <span className="gomano-card-component-status-archived">{allComponents[cId]['status']}</span>
                                        </div>
                                    }
                                </div>
                                

                                <div className="gomano-card-bottom">
                                    <div className="gomano-card-row">
                                        <div className="gomano-card-progress-bar"><HProgressBar percentage={getProgressMetricForComponent(allComponents[cId])}/></div>
                                        <span className="gomano-card-progress-label">{getProgressMetricForComponent(allComponents[cId])}%</span>
                                    </div>
                                    
                                </div>
                                <div className="gomano-card-add-button" onClick={(e) => createNewBond(cId)}>+ Add</div>
                            </div>
                        ))} 
                    </div>
                )}
                {!getValidBondsForType().length > 0 && finishedLoading && (
                    <div>
                        <div className="gomano-message-text">You've already added everything!</div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                            <span className="gomano-message-button" onClick={(e) => setIsAddingNewMarker(false)}>Return</span>
                        </div>
                    </div>
                )}

                {!finishedLoading && 
                <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <LoadingSymbolRipple/>
                </div>
                }
            </div>
        </div>
    )
}
