import React from 'react'
import { hasPermission } from '../../common/Helpers'
import { useState, useEffect, useRef } from 'react';
import Api from '../../common/APIUtils'

export default function EditableComponentTitle({ permissions, component, activeComponentId, fetchComponent, canWrap }) {
    const api = new Api()
    const [displayName, setDisplayName] = useState(() => { return component.display_name ? component.display_name : ""})
    const [isEditing, setIsEditing] = useState(() => { return false })
    const titleLastUpdatedTimestamp = useRef(false);
    const editingInputRef = useRef(null);
    const getDisplayNamePlaceholder = () => {
        if (!component) return "Enter a timeline name"
        if (!component.display_name) return "Enter a timeline name"
        return component.display_name
    }
    const updateTimeline = (payload) => {
        const path = `/workspace/components/${component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            if (fetchComponent) fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const displayNameFieldHandler = (event) => {
        if (["Enter", "NumpadEnter"].includes(event.code)) {
            event.preventDefault()
            event.target.blur()
        }
    }
    const updateDisplayName = () => {
        if (!component) return
        if (!component.display_name) return
        if (displayName === "" | displayName === component.display_name) return
        const payload = {
            display_name: displayName
        }
        updateTimeline(payload)
    }
    const updateInputSelectionState = () => {
        // auto focuses the input when isEditing is turned on
        if (!isEditing) return
        if (!editingInputRef.current) return
        editingInputRef.current.focus()
    }
    useEffect(() => {
        titleLastUpdatedTimestamp.current = Date.now()
        const timer = setTimeout(() => {
            if (Date.now() - titleLastUpdatedTimestamp.current < 900) return
            updateDisplayName()
        }, 1000)
        return () => clearTimeout(timer)
    // eslint-disable-next-line
    }, [displayName])
    useEffect(() => {
        if (!component) return
        if (!component.display_name) return
        setDisplayName(component.display_name)
    // eslint-disable-next-line
    }, [activeComponentId])
    useEffect(() => {
        updateInputSelectionState()
    // eslint-disable-next-line
    }, [isEditing])
    return (
        <div className="component-page-title-stacked-info" style={{flexGrow: "1", width: "100%", display: "flex", flexDirection: "column", gap: "4px"}} title={displayName}>
            {hasPermission(permissions, 'component_update') ? (
                <>
                {(!canWrap || (canWrap && isEditing)) && <input ref={editingInputRef} onBlur={() => setIsEditing(false)} type="text" maxLength={180} onKeyUp={(e) => displayNameFieldHandler(e)} className="workcomp-page-task-title-editable" placeholder={getDisplayNamePlaceholder()} value={displayName} onChange={(e) => setDisplayName(e.target.value)} /> }
                {canWrap && !isEditing && <div className="workcomp-page-task-title-editable" style={{border: "2px solid transparent", backgroundColor: "transparent"}} onClick={() => setIsEditing(true)}>{displayName}</div> }
                </>
            ) : (
                <div className="workcomp-page-task-title-editable" style={{border: "2px solid transparent", backgroundColor: "transparent"}}>{displayName}</div>
            )}
        </div>
    )
}
