import React from 'react'
import { useState, useRef, useEffect } from 'react';
import { BsFillBellFill, BsCheck2All } from 'react-icons/bs';
import { formatRelative  } from 'date-fns'
import { useHistory } from 'react-router-dom';
import Api from '../../common/APIUtils';

export default function NotificationBell() {
    const api = new Api();
    const history = useHistory();
    const [collapsed, setCollapsed] = useState(() => { return true })
    const [notifications, setNotifications] = useState(() => { return [] })
    const [unreadNotifications, setUnreadNotifications] = useState(() => { return [] })
    const notificationsRef = useRef(null)
    const hide = () => {
        setCollapsed(true)
    }
    const goToInterestingUrl = (notification) => {
        if (notification.url_endpoint) {
            hide()
            deleteNotification(notification._id)
            history.push(notification.url_endpoint)
        }
    }
    const markAllAsRead = () => {
        hide()
        clearAllNotifications()
    }
    const fetchNotifications = () => {
        try {
            api.getNotifications()
            .then((res) => {
                if (res) {
                    setNotifications(res.data.response.notifications)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
    const deleteNotification = (notificationId) => {
        const payload = {
            notification_id: notificationId
        }
        try {
            api.deleteNotification(payload)
            .then((res) => {
                fetchNotifications()
            })
        } catch (error) {
            console.log(error)
        }
    }
    const getAdditionalNotificationMessageClass = (notification) => {
        try {
            if (notification.status === "read") return "header-notification-message-read"
            return ""
        } catch (error) {
            return ""
        }
    }
    const clearAllNotifications = () => {
        try {
            api.deleteAllNotifications()
            .then((res) => {
                fetchNotifications()
            })
            .catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
        }
    }
    const updateUnreads = () => {
        if (!notifications) return setUnreadNotifications([])
        if (notifications.length === 0) return setUnreadNotifications([])
        const unreads = notifications.filter(n => n.status !== "read")
        setUnreadNotifications(unreads)
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (notificationsRef.current && !notificationsRef.current.contains(event.target)) {
                hide()
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [notificationsRef])
    useEffect(() => {
        fetchNotifications()
        // Fetch notifications every 60 seconds
        const interval = setInterval(() => {
            fetchNotifications()
        }, 60000)
        return() => clearInterval(interval)
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        updateUnreads()
    // eslint-disable-next-line
    }, [notifications])
    return (
        <div className="header-notifications-container" ref={notificationsRef}>
            <div className="header-notification-bell-container" onClick={() => setCollapsed(!collapsed)}>
                <BsFillBellFill className="header-notifications-button" />
            </div>
            {unreadNotifications.length > 0 && <div className="header-notification-red-dot"></div>}
            <div className="header-notifications-dropdown">
            {!collapsed && (
                <div className="header-notifications-primary-display">
                    <div className="header-notifications-primary-header-row">
                        <h2>Notifications</h2>
                        {notifications.length > 0 &&
                            <div className="header-notifications-primary-clear-text">
                                <BsCheck2All/>
                                <span onClick={markAllAsRead}>Mark all as read</span>
                            </div>
                        }
                    </div>
                    
                    <div className="header-notifications-notifications-container">
                        {notifications.map((notification) => (
                            <div key={notification._id} className={`header-notification-single-container-outer ${getAdditionalNotificationMessageClass(notification)}`}>
                                <div className="header-notification-single-container-inner" onClick={() => goToInterestingUrl(notification)}>
                                    <span className="notification-color" style={{backgroundColor: notification.color}}></span>
                                    <div>
                                        <div className={`header-notification-message`}>
                                            {notification.message}
                                        </div>
                                        <div className="header-notification-timestamp">
                                            {formatRelative(new Date(notification.createdAt), new Date())}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {notifications.length < 1 && <div className="header-notification-caught-up">All caught up.</div> }
                    </div>
                </div>
                
            )}
            </div>
            
        </div>
    )
}
