import React from 'react'
import { FaFilePdf, FaFileCsv, FaFileExcel, FaFileImage, FaFileLines, FaFile, FaPaperclip } from "react-icons/fa6";

export default function ResourceSymbol({resource}) {
    const getFileSymbol = () => {
        try {
            if (!resource) return <div></div>
            if (resource.name === "link") return <FaPaperclip style={{ color: "#2A73AE" }} />
            const fileName = resource.attributes.cloud_file_url
            const extension = fileName.split('.').pop().toLowerCase();
            if (extension === "pdf") return <FaFilePdf style={{color: "#FF3B30"}}/>
            if (extension === "csv") return <FaFileCsv style={{color: "#1C6C3F"}}/>
            if (extension === "xls" || extension === "xlsx") return <FaFileExcel style={{color: "#1C6C3F"}}/>
            if (["png","jpg", "jpeg"].includes(extension)) return <FaFileImage style={{color: "#FC636B"}}/>
            if (["txt","docx", "doc"].includes(extension)) return <FaFileLines style={{color: "#2A73AE"}}/>
            return <FaFile style={{color: "#9F6ADB"}}/>
        } catch (error) {
            return <FaFile />
        }
    }
    return getFileSymbol()
}
