import React from 'react'
import { useState, useEffect } from 'react';
import { IoCaretDownOutline, IoCaretUpOutline, IoSearchOutline, IoAdd, IoRemove } from 'react-icons/io5';

export default function ModalToolMultiSelectWorkspaceDropdownWithSearch({selectedWorkspaceIds, allWorkspaces, addWorkspace, removeWorkspace, type, positionTop}) {
    const [isSelecting, setIsSelecting] = useState(() => { return false })
    const [searchFilterText, setSearchFilterText] = useState(() => { return "" })
    const [selectedObjects, setSelectedObjects] = useState(() => { return []})
    const getFilteredWorkspaces = () => {
        if (!searchFilterText || searchFilterText === "") return allWorkspaces
        if (type === "user") {
            return allWorkspaces.filter((user) => {
                const searchableName = `@${user.handle}${user.firstName}${user.lastName}`
                if (searchableName.toLowerCase().indexOf(searchFilterText.toLowerCase()) !== -1) return true
                return false
            })
        }
        return allWorkspaces.filter((workspaceOpt) => {
            if (workspaceOpt.name.toLowerCase().indexOf(searchFilterText.toLowerCase()) !== -1) return true
            return false
        })
    }
    const updatedSelectedObjects = () => {
        try {
            const currentIds = Array.from(selectedWorkspaceIds)
            const allObjects = Array.from(allWorkspaces)
            let onlySelectedObjects = allObjects.filter((object) => {
                if (currentIds.includes(object._id)) return true
                return false
            })
            setSelectedObjects(onlySelectedObjects)
        } catch (error) {
            return
        }
    }
    const getPreviewText = () => {
        try {
            if (selectedObjects.length === 0) return "Select from dropdown"
            if (type === "user") {
                const objectNames = selectedObjects.map(obj => obj["firstName"]);
                if (objectNames.length === 1) return objectNames[0]
                const lastValue = objectNames.pop();
                return `${objectNames.join(', ')}, and ${lastValue}`;
            }
            const objectNames = selectedObjects.map(obj => obj["name"]);
            if (objectNames.length === 1) return objectNames[0]
            const lastValue = objectNames.pop();
            return `${objectNames.join(', ')}, and ${lastValue}`;
        } catch (error) {
            return "Select from dropdown"
        }
    }
    const getAdditionalStyles = (workspaceId) => {
        if (workspaceIsSelected(workspaceId)) return { fontWeight: 700, color: "black" }
        return {}
    }
    const toggleWorkspaceSelection = (workspaceId) => {
        if (workspaceIsSelected(workspaceId)) return removeWorkspace(workspaceId)
        addWorkspace(workspaceId)
    }
    const additionalSelectionContainerStyles = () => {
        if (!positionTop) return {}
        return { maxHeight: "480px" }
    }
    const getAdditionalModalStyles = () => {
        try {
            if (positionTop) {
                return {
                    bottom: "100%",
                    left: 0,
                    right: 0,
                    overflowY: "auto",
                    overflowX: "hidden"
                }
            }
            return {}
        } catch (error) {
            return {}
        }
    }
    const workspaceIsSelected = (workspaceId) => {
        return selectedWorkspaceIds.includes(workspaceId)
    }
    const getDisplayName = (item) => {
        if (type === "user") return `${item.firstName} ${item.lastName} @${item.handle}`
        return item.name
    }
    useEffect(() => {
        updatedSelectedObjects()
    // eslint-disable-next-line
    }, [selectedWorkspaceIds])
    return (
        <div style={{overflow: "visible", position: "relative"}}>
            {!isSelecting &&
            <div className="modal-mgmt-select-dropdown-mode-display" onClick={() => setIsSelecting(true)}>
                <p>{getPreviewText()}</p>
                <IoCaretDownOutline/>
            </div>
            }
            {isSelecting &&
            <div className="modal-mgmt-select-dropdown-mode-display" onClick={() => setIsSelecting(false)}>
                <p> </p>
                <IoCaretUpOutline/>
            </div>
            }
            {isSelecting &&
            <div className="modal-mgmt-select-dropdown-options" style={getAdditionalModalStyles()}>
                <div className="modal-mgmt-select-dropdown-search">
                    <IoSearchOutline/>
                    <input autoFocus={true} type="text" placeholder="Search..." value={searchFilterText} onChange={(e) => setSearchFilterText(e.target.value)}/>
                </div>
                
                <div className="modal-mgmt-select-dropdown-options-container" style={additionalSelectionContainerStyles()}>
                    {getFilteredWorkspaces().map((workspaceOption) => (
                        <div className="modal-mgmt-select-dropdown-option" onClick={() => toggleWorkspaceSelection(workspaceOption._id)} style={getAdditionalStyles(workspaceOption._id)} key={workspaceOption._id}>
                            {getDisplayName(workspaceOption)}
                            {!workspaceIsSelected(workspaceOption._id) && <IoAdd/>}
                            {workspaceIsSelected(workspaceOption._id) && <IoRemove/>}
                        </div>
                    ))}
                </div>

            </div>
            }
        </div>
    )
}