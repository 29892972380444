import React from 'react'
import { getColorHexMapFromName, getContrastColor } from '../../../common/Helpers';
import { format, parseISO, isAfter, addDays } from 'date-fns';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

export default function ComponentTimelineTaskRow({task, keyDate, dateRange, handleTaskDragging, allowDragEvents, taskIndex, editTask}) {
    const taskIsOnDay = (dateString) => {
        try {
            if (task.status === "completed" && dateString === format(parseISO(task.completed), 'yyyy-MM-dd')) return true
            if (task.status === "active" && dateString === format(parseISO(task.attributes.date_due), 'yyyy-MM-dd')) return true
            return false
        } catch (error) {
            return false
        }
    }
    const getAdditionalNameStyle = () => {
        let s = {}
        if (task.status === "completed") s['textDecoration'] = "line-through"
        if (task.status === "active" && (isAfter(new Date(), addDays(parseISO(task.attributes.date_due), 1)))) s['color'] = '#b91d1d'
        return s
    }
    const getAdditionalDotStyle = () => {
        try {
            if (!task.attributes.color_name) return {}
            const colorInfo = getColorHexMapFromName(task.attributes.color_name)
            const backgroundColor = colorInfo.hex;
            const textColor = getContrastColor(backgroundColor)
            return {
                backgroundColor: backgroundColor,
                color: textColor
            }
        } catch (error) {
            return {}
        }
    }
    const getAdditionalTaskRowStyles = () => {
        let s = {}
        if (taskIndex % 2 === 0) s['backgroundColor'] = "var(--row-background)"
        return s
    }
    return (
        <div className="component-timeline-row">
            <div className="component-timeline-side-title">
                <div className="component-timeline-side-title-task" style={getAdditionalNameStyle()} onClick={() => editTask(task)}>{task.display_name}</div>
            </div>
            
            <div className="component-timeline-side-content" style={getAdditionalTaskRowStyles()}>
            <DragDropContext onDragEnd={handleTaskDragging} onDragStart={allowDragEvents}>
            <Droppable droppableId='tasks' direction='horizontal' type='tasks'>
                {(provided) => (
                <div className="component-timeline-content-heading-container" {...provided.droppableProps} ref={provided.innerRef}>
                    {dateRange.map((dateString, index) => (
                        <ComponentTimelineTaskContainer editTask={editTask} getAdditionalNameStyle={getAdditionalNameStyle} dateString={dateString} index={index} taskIsOnDay={taskIsOnDay} getAdditionalDotStyle={getAdditionalDotStyle} task={task} key={dateString}/>
                    ))}
                    {provided.placeholder}
                </div>  
                )}
                </Droppable>
                </DragDropContext>
            </div>
            
        </div>
    )
}

function ComponentTimelineTaskContainer({task, index, dateString, taskIsOnDay, getAdditionalDotStyle, getAdditionalNameStyle, editTask}) {
    return (
        <Draggable key={dateString} draggableId={`${task._id}_${dateString}`} index={index}>
            {(provided) => (
            <div className={`component-timeline-content-heading-col component-timeline-content-col-width`} {...provided.draggableProps} ref={provided.innerRef} >
                {taskIsOnDay(dateString) && 
                <div className="component-timeline-content-task-name">
                    <span style={getAdditionalDotStyle()} {...provided.dragHandleProps}>{format(parseISO(dateString), 'L/d')}</span>
                    <p style={getAdditionalNameStyle()} onClick={() => editTask(task)}>{task.display_name}</p>
                </div>
                }
                {!taskIsOnDay(dateString) && <div {...provided.dragHandleProps}></div>}
            </div>
            )}
        </Draggable>
    )
}