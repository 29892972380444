import React from 'react'
import componentMenu from '../static/component-menu';
import { Link, useParams, useHistory } from 'react-router-dom';

import { FaLongArrowAltLeft} from 'react-icons/fa';

export default function ComponentMenu() {
    let history = useHistory();
    const { workspaceId } = useParams();

    const goBack = () => {
        // Return one page
        history.push(`/workspaces/${workspaceId}`);
    }

    return (
        <div id="component-menu-container">

            {/* Top navigation bar */}
            <div id="component-menu-top-bar">
                <div className="component-text-link" onClick={goBack}>
                    <FaLongArrowAltLeft/> Go Back
                </div>
            </div>

            <div className="component-menu-width-control">

                {/* Pageview Title */}
                <div className="component-menu-title">Create New Item</div>

                {/* Component Menu Items */}
                <div id="component-menu-new-items-container">
                    {componentMenu.map((componentSection) => (
                        <div className="component-menu-new-item-section" key={componentSection.title}>
                            <h4>{componentSection.title}</h4>
                            <div className="component-menu-new-item-section-subtitle">{componentSection.subtitle}</div>
                            <div className="component-menu-grid-desktop">
                                {componentSection.items.map((componentMenuItem) => (
                                    <div className="component-menu-item" key={componentMenuItem.name}>
                                        <h5>{componentMenuItem.name}</h5>
                                        <p>{componentMenuItem.description}</p>
                                        <Link to={ `/workspaces/${workspaceId}${componentMenuItem.to}` } className="component-menu-item-add">Add</Link>
                                    </div>
                                ))}

                            </div>
                        </div>
                    ))}
                </div>
            </div>



        </div>
    )
}
