import React from 'react'

export default function SimpleLoadingPage({title}) {
    return (
        <div className="gadget-simple-loading-page">
            <div className="gadget-simple-loading-page-header">
                <div>{title}</div>
            </div>

            <div className="util-row util-justify-center">
                <div className="gadget-simple-loading-page-loader-hypno"></div>
            </div>

            <div className="util-row util-justify-center" style={{marginTop: "80px", fontSize: "14px", color: "rgb(165, 165, 165", fontWeight: 300}}>
                loading...
            </div>
            
        </div>
    )
}
