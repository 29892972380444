import React from 'react'
import { useState, useRef } from 'react'
import BlurProofInput from './BlurProofInput'
import ProfPicCircle from '../profiles/ProfPicCircle'

export default function SimpleMentionInput({ inputClassName, autoFocus, placeholderText, inputValue, setInputValue, handleInputPress, team, mentionsStyles }) {
    const [mentionSuggestions, setMentionSuggestions] = useState(() => { return [] })
    const mainInputRef = useRef(null)
    const getSuggestions = (typedWord) => {
        if (!team) return []
        return team.filter((d) => {
            const searchedDataText = `${d.firstName}${d.lastName}${d.handle}`
            if (searchedDataText.indexOf(typedWord) !== -1) return true
            return false
        })
    }
    const handleSuggestionClick = (suggestion) => {
        setMentionSuggestions([])
        let updatedInputValue = inputValue
        const words = updatedInputValue.split(' ')
        words.pop()
        words.push(`@${suggestion.handle} `)
        updatedInputValue = words.join(' ')
        setInputValue(updatedInputValue)
        mainInputRef.current.focus()
    }
    const getAdditionalMentionsStyles = () => {
        try {
            let styles = {}
            if (mentionsStyles) styles = mentionsStyles
            return styles
        } catch (error) {
            return {}
        }
    }
    const handleChange = (e) => {
        const value = e.target.value;
        setInputValue(value)

        // Check if the user is working on adding a mention
        if (value.trim() === "" || value.endsWith(' ')) return setMentionSuggestions([])
        const wordsInInput = value.trim().split(" ")
        const lastTypedWord = wordsInInput[wordsInInput.length - 1];
        if (lastTypedWord === "") return setMentionSuggestions([])
    
        if (lastTypedWord.includes('@')) {
            const match = lastTypedWord.slice(1);
            setMentionSuggestions(getSuggestions(match).slice(0, 5));
        } else {
            setMentionSuggestions([]);
        }
    };
    const handleKeyDown = (e) => {
        if (["Escape", "Enter", "NumpadEnter"].includes(e.code)) setMentionSuggestions([])
        handleInputPress(e)
    }
    return (
        <div className="gadget-simple-mention-input">
            <BlurProofInput inputRef={mainInputRef}>
                <input className={inputClassName} ref={mainInputRef} autoFocus={autoFocus} type="text" placeholder={placeholderText} value={inputValue} onChange={(e) => handleChange(e)} onKeyDown={(e) => handleKeyDown(e)}/>
            </BlurProofInput>

            {mentionSuggestions.length > 0 && (
                <div className="gadget-simple-mention-input-suggestions-list-container" style={getAdditionalMentionsStyles()}>
                    <div className="gadget-simple-mention-input-suggestions-list">
                    {mentionSuggestions.map((suggestion) => (
                        <div className="gadget-simple-mention-input-suggestion" key={suggestion._id} onClick={() => handleSuggestionClick(suggestion)}>
                            <ProfPicCircle height={32} src={suggestion.profile_picture_url} userId={suggestion._id} userName={`${suggestion.firstName} ${suggestion.lastName}`}  />
                            <div className="gadget-beautiful-mentions-menu-item-names">
                                <p>{`${suggestion.firstName} ${suggestion.lastName}`}</p>
                                <span>@{suggestion.handle}</span>
                            </div>
                        </div>
                    ))}
                    </div>

                </div>
            )}
        </div>
    )
}
