import React from 'react'
import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Api from '../../common/APIUtils';
import { getFormattedDisplayWithUnits } from '../../common/Helpers';
import { ReactComponent as Logo } from './resources/tetheros-t-blue-sm.svg'
import { format } from 'date-fns'
import { AiOutlineFundProjectionScreen } from 'react-icons/ai'
import { RiQuillPenFill } from 'react-icons/ri'
import { MdMiscellaneousServices, MdAttachMoney, MdThumbUpAlt, MdSchema, MdCelebration, MdDirectionsRun, MdOutlineCheck } from 'react-icons/md'
import { Calendar } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import LoadingSymbolRipple from '../gadgets/LoadingSymbolRipple'
// DEPRECATED FOR WizardOnboarding.jsx

export default function WizardUserOnboarding({ fetchAuthStatus }) {
    const history = useHistory()
    const api = new Api()
    const [page, setPage] = useState(() => { return 1 })
    const [theme, setTheme] = useState(() => { return false })
    const [workspaceResult, setWorkspaceResult] = useState(() => { return false })
    const [projectResult, setProjectResult] = useState(() => { return false })
    const [workspaceName, setWorkspaceName] = useState(() => { return `${localStorage.getItem("gFirstName")}'s Workspace` })
    const [initiativeName, setInitiativeName] = useState(() => { return `${workspaceName}'s Team` })
    const [initiativeEndDate, setInitiativeEndDate] = useState(() => {
        let d = new Date()
        d.setMonth(d.getMonth() + 3)
        // return format(d, 'yyyy-MM-dd')
        return d
    })
    const [goalTarget, setGoalTarget] = useState(() => { return "" })
    const [formattedGoalTarget, setFormattedGoalTarget] = useState(() => { return "" })
    const [tasks, setTasks] = useState(() => { return [] })
    const [proposedTask, setProposedTask] = useState(() => { return "" })
    const attemptWorkspaceBuild = () => {
        let payload = {
            template_name: theme,
            workspace_name: workspaceName,
            project_name: initiativeName,
            tasks: tasks,
            date_end: format(initiativeEndDate, 'yyyy-MM-dd'),
            date_start: format(new Date(), 'yyyy-MM-dd')
        }
        api.triggerUserOnboardingWorkflow(payload)
        .then((res) => {
            if (res.data.workspace) {
                fetchAuthStatus()
                if (res.data.project) setProjectResult(res.data.project)
                setWorkspaceResult(res.data.workspace)
            }
        })
    }
    const addPage = () => {
        setPage(page + 1)
    }
    const subPage = () => {
        setPage(page - 1)
    }
    const updateProjectEndDate = (date) => {
        setInitiativeEndDate(date)
    }
    const addProposedTaskToTasks = (code) => {
        if (code !== 'Enter') return
        if (!proposedTask || proposedTask === "") return
        if (tasks.length === 5) return
        if (tasks.includes(proposedTask.trim())) return setProposedTask("")
        let currentTasks = Array.from(tasks)
        currentTasks.push(proposedTask.trim())
        setTasks(currentTasks)
        setProposedTask("")
    }
    const removeTaskFromTasks = (taskName) => {
        if (!tasks.includes(taskName.trim())) return
        let currentTasks = Array.from(tasks)
        const index = currentTasks.indexOf(taskName.trim())
        if (index < 0) return
        currentTasks.splice(index, 1); // 2nd parameter means remove one item only
        setTasks(currentTasks)
    }
    const logKeyDown = (e) => {
        const ALLOWED_CODES = ['Backspace', 'Numpad0', 'Numpad1', 'Numpad2', 'Numpad3', 'Numpad4', 'Numpad5', 'Numpad6', 'Numpad7', 'Numpad8', 'Numpad9', 'Digit0', 'Digit1', 'Digit2', 'Digit3', 'Digit4', 'Digit5', 'Digit6', 'Digit7', 'Digit8', 'Digit9']
        if (!ALLOWED_CODES.includes(e.code)) return

        // Remove the last digit
        if (e.code === 'Backspace' && goalTarget === "") return
        if (e.code === 'Backspace' && goalTarget !== "") {
            setGoalTarget(goalTarget.slice(0,-1))
            return
        }
        
        // Add the digit to the end
        let value = e.key
        if ((value === "0" || value === 0) && goalTarget === "") return
        if (goalTarget === "") return setGoalTarget(value)
        let newTarget = goalTarget + value
        setGoalTarget(newTarget)
    }
    const updateFormattedGoalTarget = () => {
        let formatted = getFormattedDisplayWithUnits(false, goalTarget)
        setFormattedGoalTarget(formatted)
    }
    const getCurrentMonthName = () => {
        return format(new Date(), 'LLLL')
    }
    const goToNewWorkspace = () => {
        if (projectResult) return history.push(`/workspaces/${workspaceResult._id}/c/${projectResult._id}`)
        history.push(`/workspaces/${workspaceResult._id}`)
    }
    useEffect(() => {
        updateFormattedGoalTarget()
    // eslint-disable-next-line
    },[goalTarget])
    useEffect(() => {
        document.title = "New Workspace"
    }, [])
    useEffect(() => {
        if (page === 7) attemptWorkspaceBuild()
    // eslint-disable-next-line
    }, [page])
    useEffect(() => {
        if (workspaceName) document.title = workspaceName
        if (!theme) {
            setInitiativeName(`${workspaceName} Project`)
            return
        }
        if (theme === 'projects') {
            setInitiativeName(`${localStorage.getItem("gFirstName")}'s Project`)
            return
        }
        if (theme === 'fundraising') {
            setInitiativeName(`${getCurrentMonthName()} Fundraising Work`)
            return
        }
        if (theme === 'events') {
            setInitiativeName(`${workspaceName}'s Event`)
            return
        }
    // eslint-disable-next-line
    }, [theme, workspaceName])
    return (
        <div className="wizard-page-container">
            {page === 1 && (
                <div className="wizard-page">
                    <Logo className="wizard-logo"/>
                    <div className="wizard-page-heading">Welcome, {localStorage.getItem("gFirstName")}!</div>
                    <div className="wizard-page-subheading">You have an important mission.  Tetheros makes it easier.  Let's set things up so you and your team can get back to work.</div>
                    <div className="wizard-navigation-section wizard-navigation-section-1">
                        <div className="wizard-navigation-button-big" onClick={addPage}>Get Started</div>
                    </div>
                </div>
            )}

            {page === 2 && (
                <div className="wizard-page">

                    <div className="wizard-page-heading">Let's start by naming your workspace</div>
                    <div className="wizard-page-subheading">You can change this later if you need to</div>

                    <div className="wizard-input-container">
                        <input autoFocus={true} type="text" placeholder={`${localStorage.getItem("gFirstName")}'s Workspace`} value={workspaceName} onChange={(e) => setWorkspaceName(e.target.value)} />
                    </div>

                    <div className="wizard-page-subtitle">"The way to get started is to quit talking and begin doing." -Walt Disney</div>
                    <div className="wizard-navigation-section wizard-navigation-section-2">
                        <div className="wizard-navigation-button wizard-navigation-button-secondary" onClick={subPage}>Back</div>
                        <div className="wizard-navigation-button wizard-navigation-button-primary" onClick={addPage}>Next</div>
                    </div>
                </div>
            )}

            {page === 3 && (
                <div className="wizard-page">

                    <div className="wizard-page-heading">Choose a workspace focus</div>
                    <div className="wizard-page-subheading">We'll automatically load your workspace with helpful templates and other goodies</div>

                    <div className="wizard-grid-outer-container">

                        {/* <div className="wizard-grid-row">
                            <WizardGridItem setIsSelected={setTheme} selected={theme} icon="projects" title="Basic Projects"/>
                            <WizardGridItem setIsSelected={setTheme} selected={theme} icon="marketing" title="Digital Marketing"/>
                            <WizardGridItem setIsSelected={setTheme} selected={theme} icon="design" title="Graphic Design"/>
                        </div>
                        <div className="wizard-grid-row">
                            <WizardGridItem setIsSelected={setTheme} selected={theme} icon="operations" title="Operations"/>
                            <WizardGridItem setIsSelected={setTheme} selected={theme} icon="fundraising" title="Fundraising"/>
                            <WizardGridItem setIsSelected={setTheme} selected={theme} icon="events" title="Event Planning"/>
                        </div> */}
                        <div className="wizard-grid-row">
                            <WizardGridItem setIsSelected={setTheme} selected={theme} icon="projects" title="Basic Projects"/>
                            <WizardGridItem setIsSelected={setTheme} selected={theme} icon="design" title="Design Projects"/>
                            <WizardGridItem setIsSelected={setTheme} selected={theme} icon="events" title="Event Planning"/>
                        </div>
                    </div>

                    <div className="wizard-page-subtitle">"The secret of success is to do the common thing uncommonly well." -John D. Rockefeller Jr.</div>
                    <div className="wizard-navigation-section wizard-navigation-section-2">
                        <div className="wizard-navigation-button wizard-navigation-button-secondary" onClick={subPage}>Back</div>
                        {theme && <div className="wizard-navigation-button wizard-navigation-button-primary" onClick={addPage}>Next</div>}
                        {!theme && <div className="wizard-navigation-button wizard-navigation-button-primary" onClick={() => setPage(7)}>Skip</div>}
                    </div>
                </div>
            )}

            {theme === 'projects' && (
                <div className="wizard-page">
                    {page === 4 &&
                    <div>
                        <div className="wizard-page-heading">What project are you working on?</div>
                        <div className="wizard-page-subheading">You can rename this later if you need to.</div>

                        <div className="wizard-input-container">
                            <input autoFocus={true} type="text" placeholder={initiativeName} value={initiativeName} onChange={(e) => setInitiativeName(e.target.value)} />
                        </div>

                        <div className="wizard-page-subtitle">"Success usually comes to those who are too busy to be looking for it." -Henry David Thoreau</div>
                        <div className="wizard-navigation-section wizard-navigation-section-2">
                            <div className="wizard-navigation-button wizard-navigation-button-secondary" onClick={subPage}>Back</div>
                            <div className="wizard-navigation-button wizard-navigation-button-primary" onClick={addPage}>Next</div>
                        </div>
                    </div>
                    }
                    {page === 5 &&
                    <div>
                        <div className="wizard-page-heading">When do you want to be done by?</div>
                        <div className="wizard-page-subheading">If it takes longer you can always add time.</div>

                        <div className="wizard-input-container" style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                            <Calendar date={initiativeEndDate} minDate={new Date()} onChange={updateProjectEndDate} />
                        </div>

                        <div className="wizard-page-subtitle">"It does not matter how slowly you go as long as you do not stop." -Confucius</div>
                        <div className="wizard-navigation-section wizard-navigation-section-2">
                            <div className="wizard-navigation-button wizard-navigation-button-secondary" onClick={subPage}>Back</div>
                            <div className="wizard-navigation-button wizard-navigation-button-primary" onClick={addPage}>Next</div>
                        </div>
                    </div>
                    }
                    {page === 6 &&
                    <div>
                        <div className="wizard-page-heading">What tasks have to get done this week?</div>
                        <div className="wizard-page-subheading">Add up to five items right now - you can always add more later</div>

                        {tasks.length < 5 &&
                        <div className="wizard-input-container">
                            <input autoFocus={true} type="text" onKeyDown={(e) => addProposedTaskToTasks(e.code)} placeholder="Add a task name" value={proposedTask} onChange={(e) => setProposedTask(e.target.value)} />
                            <div className="wizard-input-instructions">Click any task to remove it from the list</div>
                        </div>
                        }

                        {tasks.length >= 5 && 
                        <div className="wizard-input-container">
                            <div className="wizard-input-good-job">
                                <MdDirectionsRun/>
                                You're off to a running start!
                            </div>
                        </div>
                        }

                        <div className="wizard-proposed-tasks">
                            {tasks.map((task) => (
                                <div className="wizard-proposed-task-container" key={task}>
                                    <MdOutlineCheck/>
                                    <div className="wizard-proposed-task-text" onClick={() => removeTaskFromTasks(task)}>{task}</div>
                                </div>
                            ))}
                        </div>

                        {tasks.length < 5 && <div className="wizard-page-subtitle">Add a task and press Enter when you're ready.  We're almost done!</div>}
                        {tasks.length >= 5 && <div className="wizard-page-subtitle">You can add the rest of your tasks later.</div>}
                        
                        <div className="wizard-navigation-section wizard-navigation-section-2">
                            <div className="wizard-navigation-button wizard-navigation-button-secondary" onClick={subPage}>Back</div>
                            <div className="wizard-navigation-button wizard-navigation-button-primary" onClick={addPage}>Next</div>
                        </div>
                    </div>
                    }
                </div>
            )}
            {theme === 'fundraising' && (
                <div className="wizard-page">
                    {page === 4 &&
                    <div>
                        <div className="wizard-page-heading">What are you raising money for?</div>
                        <div className="wizard-page-subheading">You can rename this later if you need to.</div>

                        <div className="wizard-input-container">
                            <input autoFocus={true} type="text" placeholder={initiativeName} value={initiativeName} onChange={(e) => setInitiativeName(e.target.value)} />
                        </div>

                        <div className="wizard-page-subtitle">You can create as many efforts as you want later if you have any.</div>
                        <div className="wizard-navigation-section wizard-navigation-section-2">
                            <div className="wizard-navigation-button wizard-navigation-button-secondary" onClick={subPage}>Back</div>
                            <div className="wizard-navigation-button wizard-navigation-button-primary" onClick={() => setPage(50)}>Next</div>
                        </div>
                    </div>
                    }
                    {page === 50 &&
                    <div>
                        <div className="wizard-page-heading">What is your ideal fundraising deadline?</div>
                        <div className="wizard-page-subheading">If it takes longer you can always add time.</div>

                        <div className="wizard-input-container">
                            <input autoFocus={true} type="date" value={initiativeEndDate} onChange={(e) => setInitiativeEndDate(e.target.value)} />
                        </div>

                        <div className="wizard-navigation-section wizard-navigation-section-2">
                            <div className="wizard-navigation-button wizard-navigation-button-secondary" onClick={() => setPage(4)}>Back</div>
                            <div className="wizard-navigation-button wizard-navigation-button-primary" onClick={addPage}>Next</div>
                        </div>
                    </div>
                    }
                    {page === 51 &&
                    <div>
                        <div className="wizard-page-heading">How much money do you want to raise?</div>
                        {/* <div className="wizard-page-subheading">We won't hold you to this if it has to change later.</div> */}

                        <div className="wizard-input-container wizard-input-currency-container">
                            <div className="wizard-input-currency-symbol">$</div>
                            <input className="wizard-input-currency" onChange={() => {return false}} onKeyDown={(e) => logKeyDown(e)} placeholder="Enter an amount" autoFocus={true} type="text" value={formattedGoalTarget} />
                        </div>

                        <div className="wizard-navigation-section wizard-navigation-section-2">
                            <div className="wizard-navigation-button wizard-navigation-button-secondary" onClick={subPage}>Back</div>
                            <div className="wizard-navigation-button wizard-navigation-button-primary" onClick={addPage}>Next</div>
                        </div>
                    </div>
                    }
                    {page === 52 &&
                    <div>
                        <div className="wizard-page-heading">What tasks need to get done?</div>
                        <div className="wizard-page-subheading">Add up to five items right now - you can always add more later</div>

                        {tasks.length < 5 &&
                        <div className="wizard-input-container">
                            <input autoFocus={true} type="text" onKeyDown={(e) => addProposedTaskToTasks(e.code)} placeholder="Add a task name" value={proposedTask} onChange={(e) => setProposedTask(e.target.value)} />
                            <div className="wizard-input-instructions">Click any task to remove it from the list</div>
                        </div>
                        }

                        {tasks.length >= 5 && 
                        <div className="wizard-input-container">
                            <div className="wizard-input-good-job">
                                <MdDirectionsRun/>
                                You're off to a running start!
                            </div>
                        </div>
                        }

                        <div className="wizard-proposed-tasks">
                            {tasks.map((task) => (
                                <div className="wizard-proposed-task-container" key={task}>
                                    <MdOutlineCheck/>
                                    <div className="wizard-proposed-task-text" onClick={() => removeTaskFromTasks(task)}>{task}</div>
                                </div>
                            ))}
                        </div>

                        {tasks.length < 5 && <div className="wizard-page-subtitle">Add a task and press Enter when you're ready.  We're almost done!</div>}
                        {tasks.length >= 5 && <div className="wizard-page-subtitle">You can add the rest of your tasks later.</div>}
                        
                        <div className="wizard-navigation-section wizard-navigation-section-2">
                            <div className="wizard-navigation-button wizard-navigation-button-secondary" onClick={subPage}>Back</div>
                            <div className="wizard-navigation-button wizard-navigation-button-primary" onClick={() => setPage(7)}>Next</div>
                        </div>
                    </div>
                    }
                </div>
            )}
            {theme === 'events' && (
                <div className="wizard-page">
                    {page === 4 &&
                    <div>
                        <div className="wizard-page-heading">What's the event name?</div>
                        <div className="wizard-page-subheading">You can rename this later if you need to.</div>

                        <div className="wizard-input-container">
                            <input autoFocus={true} type="text" placeholder={initiativeName} value={initiativeName} onChange={(e) => setInitiativeName(e.target.value)} />
                        </div>

                        <div className="wizard-page-subtitle">You can add more details later if you have any.</div>
                        <div className="wizard-navigation-section wizard-navigation-section-2">
                            <div className="wizard-navigation-button wizard-navigation-button-secondary" onClick={subPage}>Back</div>
                            <div className="wizard-navigation-button wizard-navigation-button-primary" onClick={addPage}>Next</div>
                        </div>
                    </div>
                    }
                    {page === 5 &&
                    <div>
                        <div className="wizard-page-heading">When is the big day?</div>
                        <div className="wizard-page-subheading">If it changes you can always adjust this later.</div>

                        <div className="wizard-input-container" style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                            <Calendar date={initiativeEndDate} minDate={new Date()} onChange={updateProjectEndDate} />
                        </div>

                        <div className="wizard-navigation-section wizard-navigation-section-2">
                            <div className="wizard-navigation-button wizard-navigation-button-secondary" onClick={subPage}>Back</div>
                            <div className="wizard-navigation-button wizard-navigation-button-primary" onClick={addPage}>Next</div>
                        </div>
                    </div>
                    }
                    {page === 6 &&
                    <div>
                        <div className="wizard-page-heading">What tasks have to get done soon?</div>
                        <div className="wizard-page-subheading">Add up to five items right now - you can always add more later</div>

                        {tasks.length < 5 &&
                        <div className="wizard-input-container">
                            <input autoFocus={true} type="text" onKeyDown={(e) => addProposedTaskToTasks(e.code)} placeholder="Add a task name" value={proposedTask} onChange={(e) => setProposedTask(e.target.value)} />
                            <div className="wizard-input-instructions">Click any task to remove it from the list</div>
                        </div>
                        }

                        {tasks.length >= 5 && 
                        <div className="wizard-input-container">
                            <div className="wizard-input-good-job">
                                <MdDirectionsRun/>
                                You're off to a running start!
                            </div>
                        </div>
                        }

                        <div className="wizard-proposed-tasks">
                            {tasks.map((task) => (
                                <div className="wizard-proposed-task-container" key={task}>
                                    <MdOutlineCheck/>
                                    <div className="wizard-proposed-task-text" onClick={() => removeTaskFromTasks(task)}>{task}</div>
                                </div>
                            ))}
                        </div>

                        {tasks.length < 5 && <div className="wizard-page-subtitle">Add a task and press Enter when you're ready.  We're almost done!</div>}
                        {tasks.length >= 5 && <div className="wizard-page-subtitle">You can add the rest of your tasks later.</div>}
                        
                        <div className="wizard-navigation-section wizard-navigation-section-2">
                            <div className="wizard-navigation-button wizard-navigation-button-secondary" onClick={subPage}>Back</div>
                            <div className="wizard-navigation-button wizard-navigation-button-primary" onClick={addPage}>Next</div>
                        </div>
                    </div>
                    }
                </div>
            )}

            {theme === 'design' && (
                <div className="wizard-page">
                    {page === 4 &&
                    <div>
                        <div className="wizard-page-heading">What's the project name?</div>
                        <div className="wizard-page-subheading">You can rename this later if you need to.</div>

                        <div className="wizard-input-container">
                            <input autoFocus={true} type="text" placeholder={initiativeName} value={initiativeName} onChange={(e) => setInitiativeName(e.target.value)} />
                        </div>

                        <div className="wizard-page-subtitle">"Only a life lived for others is a life worthwhile." -Albert Einstein</div>
                        <div className="wizard-navigation-section wizard-navigation-section-2">
                            <div className="wizard-navigation-button wizard-navigation-button-secondary" onClick={subPage}>Back</div>
                            <div className="wizard-navigation-button wizard-navigation-button-primary" onClick={addPage}>Next</div>
                        </div>
                    </div>
                    }
                    {page === 5 &&
                    <div>
                        <div className="wizard-page-heading">What's the project deadline?</div>
                        <div className="wizard-page-subheading">You can change this later if you have to.</div>

                        <div className="wizard-input-container" style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                            <Calendar date={initiativeEndDate} minDate={new Date()} onChange={updateProjectEndDate} />
                        </div>

                        <div className="wizard-page-subtitle">"I love deadlines. I love the whooshing noise they make as they go by." -Douglas Adams</div>
                        <div className="wizard-navigation-section wizard-navigation-section-2">
                            <div className="wizard-navigation-button wizard-navigation-button-secondary" onClick={subPage}>Back</div>
                            <div className="wizard-navigation-button wizard-navigation-button-primary" onClick={addPage}>Next</div>
                        </div>
                    </div>
                    }
                    {page === 6 &&
                    <div>
                        <div className="wizard-page-heading">What tasks are due soon?</div>
                        <div className="wizard-page-subheading">Add up to five items right now - you can always add more later</div>

                        {tasks.length < 5 &&
                        <div className="wizard-input-container">
                            <input autoFocus={true} type="text" onKeyDown={(e) => addProposedTaskToTasks(e.code)} placeholder="Add a task name" value={proposedTask} onChange={(e) => setProposedTask(e.target.value)} />
                            <div className="wizard-input-instructions">Click any task to remove it from the list</div>
                        </div>
                        }

                        {tasks.length >= 5 && 
                        <div className="wizard-input-container">
                            <div className="wizard-input-good-job">
                                <MdDirectionsRun/>
                                You're off to a running start!
                            </div>
                        </div>
                        }

                        <div className="wizard-proposed-tasks">
                            {tasks.map((task) => (
                                <div className="wizard-proposed-task-container" key={task}>
                                    <MdOutlineCheck/>
                                    <div className="wizard-proposed-task-text" onClick={() => removeTaskFromTasks(task)}>{task}</div>
                                </div>
                            ))}
                        </div>

                        {tasks.length < 5 && <div className="wizard-page-subtitle">Add a task and press Enter when you're ready.  We're almost done!</div>}
                        {tasks.length >= 5 && <div className="wizard-page-subtitle">You can add the rest of your tasks later.</div>}
                        
                        <div className="wizard-navigation-section wizard-navigation-section-2">
                            <div className="wizard-navigation-button wizard-navigation-button-secondary" onClick={subPage}>Back</div>
                            <div className="wizard-navigation-button wizard-navigation-button-primary" onClick={addPage}>Next</div>
                        </div>
                    </div>
                    }
                </div>
            )}

            {page === 7 && (
                <div className="wizard-page">
                    <Logo className="wizard-logo"/>
                    {!workspaceResult ? (
                        <>
                            <div className="wizard-page-heading">Building {initiativeName}...</div>
                            <LoadingSymbolRipple/>
                        </>
                    ) : (
                        <>
                            <div className="wizard-page-heading">All done, {localStorage.getItem("gFirstName")}!</div>
                            <div className="wizard-page-subheading">Your workspace is setup and ready to use.</div>
                            
                            <div className="wizard-navigation-section wizard-navigation-section-1">
                                <div className="wizard-navigation-button-big" onClick={goToNewWorkspace}>Start Working</div>
                            </div>
                        </>
                    )}
                </div>
            )}
            
        </div>
    )
}

function WizardGridItem({title, icon, setIsSelected, selected}) {
    const iconMap = {
        projects: <AiOutlineFundProjectionScreen/>,
        operations: <MdMiscellaneousServices/>,
        design: <RiQuillPenFill/>,
        management: <MdSchema/>,
        fundraising: <MdAttachMoney/>,
        marketing: <MdThumbUpAlt/>,
        events: <MdCelebration/>
    }
    const additionalStyles = () => {
        if (icon !== selected) return {}
        return {
            color: "green",
            textDecoration: "underline"
        }
    }
    const updateSelected = () => {
        if (icon === selected) return setIsSelected(false)
        setIsSelected(icon)
    }
    const getAdditionalGridStyle = () => {
        if (icon !== selected) return {}
        return {
            backgroundColor: "rgba(0, 128, 0, 0.062)"
        }
    }
    return (
        <div style={getAdditionalGridStyle()} className="wizard-grid-item" onClick={() => updateSelected()}>
            <div style={additionalStyles()} className="wizard-grid-item-icon">{iconMap[icon]}</div>
            <div style={additionalStyles()} className="wizard-grid-item-title">{title}</div>
        </div>
    )
}