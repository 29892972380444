import React, { useState } from 'react'

import ModalSimpleHeader from '../modals/simple/ModalSimpleHeader'
import ModalSimpleSingleButton from '../modals/simple/ModalSimpleSingleButton'

export default function ModalCreateWorkspaceBucket({createBucket, hideModal}) {
    const [bucketName, setBucketName] = useState(() => { return "" })
    const handleEnterKeyPress = (e) => {
        if (['enter', 'numpadenter'].includes(e.code.toLowerCase())) {
            if (!bucketName) return
            if (bucketName.trim() === "") return
            return submitBucket()
        }
    }
    const submitBucket = () => {
        try {
            if (!bucketName) return
            if (bucketName.trim() === "") return
            createBucket(bucketName)
        } catch (error) {
            return
        }
    }
    return (
        <div className="modal-mgmt-background-overlay" style={{zIndex: 200}}>
            <div className="modal-mgmt-container modal-mgmt-container-creator">
                <div>
                    <ModalSimpleHeader hideModal={hideModal} title="Add Bucket" />
                    <div>
                        <div className="modal-workstream-settings-setting-single modal-workstream-settings-setting-single-vertical">
                            <div className="modal-workstream-settings-setting-names">
                                <div className="modal-workstream-settings-setting-name">Bucket Name</div>
                            </div>
                            <div className="modal-workstream-settings-setting-value">
                                <div className="modal-workstream-settings-text-input-container">
                                    <input onKeyUp={(e) => handleEnterKeyPress(e)} autoFocus={true} type="text" placeholder="Projects" value={bucketName} maxLength={28} onChange={(e) => setBucketName(e.target.value)} />
                                </div>
                            </div>
                        </div>

                        <div className="modal-workstream-settings-buttons util-row util-row-reverse util-align-center" style={{gap: "8px"}}>
                            <ModalSimpleSingleButton isValid={bucketName} buttonText="Add" onclick={submitBucket} />
                            <ModalSimpleSingleButton isValid={true} invertStyle={true} buttonText="Cancel" onclick={hideModal} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
