import React from 'react'
import HProgressBar from '../../HProgressBar'
import { MdClose } from 'react-icons/md'
import { hasPermission, getFormattedDisplayWithUnits } from '../../../common/Helpers'
import { useHistory } from 'react-router-dom';
import ComponentBearingBadge from '../misc/ComponentBearingBadge'
import Api from '../../../common/APIUtils'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi'

export default function WorkspaceCompInitiativeAlignmentItem({component, permissions, removeMarker, fetchComponent}) {
    const api = new Api()
    const history = useHistory()
    const updateComponent = (payload) => {
        if (!component) return
        const path = `/workspace/components/${component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            return fetchComponent()
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const getProgress = () => {
        if (component.status === "completed") return 100
        if (component.progress || component.progress === 0) return `${Math.floor(Math.round(component.progress*100))}`
        return "0"
    }
    const getName = () => {
        if (!component) return ""
        if (!component.name) return ""
        if (component.name === 'initiative') return "Project"
        if (component.name === 'goal') return "Goal"
        if (component.name === 'list') return "List"
        if (component.name === 'board') return "Board"
        if (component.name === 'kpi') return "KPI"
        // if (component.name === 'kresult') return "Goal"
        if (component.name === "kresult") {
            return (
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "6px"}}>
                    <span>{getFormattedDisplayWithUnits(false, component.attributes.val_start)}</span>
                    <HiOutlineArrowNarrowRight/>
                    <span>{getFormattedDisplayWithUnits(false, component.attributes.val_target)}</span>
                </div>
            )
        }
        return false
    }
    const getComponentBearingInformation = () => {
        try {
            let bearingInfo = {
                text: "Active",
                color: false,
                background: false
            }
            if (component.attributes.bearing) bearingInfo['text'] = component.attributes.bearing
            if (component.attributes.bearing_color_text) bearingInfo['color'] = component.attributes.bearing_color_text
            if (component.attributes.bearing_color_background) bearingInfo['background'] = component.attributes.bearing_color_background
            return bearingInfo
        } catch (error) {
            return false
        }
    }
    const navigateToAlignedComponent = () => {
        if (!component) return
        if (!component._id) return
        if (!component.workspace_id) return
        return history.push(`/workspaces/${component.workspace_id}/c/${component._id}`)
    }
    return (
        <div className="workspace-comp-initiative-alignment-item-t-container">
            <div className="workspace-comp-initiative-alignment-t-row workspace-comp-initiative-alignment-item-t-overflow-control">
                <div className="workspace-comp-initiative-alignment-item-t-display-name" onClick={navigateToAlignedComponent}>
                    {component.display_name}
                    {component.status === "active" && <span>{getName()}</span>}
                    {component.status === "completed" && <div className={`component-view-title-meta-status-${component.status}`}>Completed</div>}
                    {component.status === "archived" && <div className={`component-view-title-meta-status-${component.status}`}>Archived</div>}
                </div>
            </div>

            <div className="workspace-comp-initiative-alignment-t-row" style={{minWidth: "480px"}}>
                {component.status !== "archived" &&
                <>
                <HProgressBar percentage={getProgress()} />
                {getProgress()}%
                {(component.name === "kresult" && getComponentBearingInformation()) && <ComponentBearingBadge color={getComponentBearingInformation().color} text={getComponentBearingInformation().text} updateComponent={updateComponent} permissions={permissions} background={getComponentBearingInformation().background} /> }
                </>
                }
                {component.status === "archived" && <div style={{width: "100%", color: "grey"}}>Archived items do not report progress</div> }
                {hasPermission(permissions, 'component_update') && removeMarker &&
                <div className="workspace-comp-initiative-alignment-t-button-remove" onClick={() => removeMarker(component._id)}>
                    <MdClose/>
                </div>
                }
            </div>       
        </div>
    )
}
