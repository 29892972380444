import React from 'react'
import { useState, useEffect } from 'react';
import { hasPermission } from '../../../common/Helpers';
import { useHistory } from 'react-router-dom';
import { MdExpandMore, MdExpandLess, MdClose, MdAdd } from 'react-icons/md'
import { format, addDays, parseISO } from 'date-fns';

import ConfirmAction from '../../modals/ConfirmAction';

import ComponentTimelineTaskRow from './ComponentTimelineTaskRow';

export default function ComponentTimelineSwimlane({updateComponentAndFetchTimeline, editTask, removeSwimlane, addNewTask, showCompletedTasks, swimlane, keyDate, dateRange, permissions, lastSwimlaneGenerationTime}) {
    const history = useHistory()
    const [displayedTasks, setDisplayedTasks] = useState(() => { return [] })
    const [unscheduledTasks, setUnscheduledTasks] = useState(() => { return [] })
    const [isCollapsed, setIsCollapsed] = useState(() => { return false })
    const [isShowingConfirmMenu, setIsShowingConfirmMenu] = useState(() => { return false })
    const [isShowingUnscheduledTasks, setIsShowingUnscheduledTasks] = useState(() => { return false })
    const getCollapseSymbol = () => {
        if (isCollapsed) return <MdExpandLess/>
        return <MdExpandMore/>
    }
    const updateTaskDueDate = (taskId, dueDateString) => {
        let copyOfTasks = Array.from(displayedTasks)
        const newTasks = copyOfTasks.map((task) => {
            if (task._id === taskId) {
                let attributes = task.attributes
                attributes['date_due'] = dueDateString
                return { ...task, attributes: attributes }
            }
            return task
        })
        setDisplayedTasks(newTasks)
    }
    const allowDragEvents = (start) => {
        return true
    };
    const handleTaskDragging = (result) => {
        if (!hasPermission(permissions, 'component_update')) return
        try {
            if (!result.draggableId) return
            if (!result.destination) return
            if (!result.destination.index && result.destination.index !== 0) return
            const taskId = result.draggableId.split("_")[0]
            const updatedDate = format(addDays(keyDate, result.destination.index), 'yyyy-MM-dd')
            updateTaskDueDate(taskId, updatedDate)
            // Actually update the task in database
            updateComponentAndFetchTimeline({attributes: {date_due: updatedDate}}, taskId)
        } catch (error) {
            console.log(error);
        }
    };
    const organizeDisplayedTasks = () => {
        if (!swimlane) return
        if (!swimlane.tasks) return
        let filteredDisplayedTasks = swimlane.tasks.filter((component) => {
            if ((component.status === "completed" && !showCompletedTasks) || component.status === "archived") return false
            if (!component.attributes) return false
            if (component.status === "completed" && showCompletedTasks) return true
            if (component.status === "active" && component.attributes.date_due && component.attributes.date_due !== "") return true
            return false
        })
        filteredDisplayedTasks = filteredDisplayedTasks.sort( (a, b) => {
            let aDate = 0
            let bDate = 0
            if (a.status === "active") aDate = parseISO(a.attributes.date_due)
            if (b.status === "active") bDate = parseISO(b.attributes.date_due)
            if (a.status === "completed") aDate = parseISO(a.completed)
            if (b.status === "completed") bDate = parseISO(b.completed)
            return aDate - bDate
        })
        setDisplayedTasks(filteredDisplayedTasks)
        let filteredUnscheduledTasks = swimlane.tasks.filter((component) => {
            if (component.status === "completed" || component.status === "archived") return false
            if (!component.attributes) return true
            if (!component.attributes.date_due || component.attributes.date_due === "") return true
            return false
        })
        if (!filteredUnscheduledTasks) return setUnscheduledTasks([])
        filteredUnscheduledTasks = filteredUnscheduledTasks.sort((a,b) => {
            return a.display_name.localeCompare(b.display_name)
        })
        setUnscheduledTasks(filteredUnscheduledTasks)
    }
    const goToWorkstream = () => {
        history.push(`/workspaces/${swimlane.workspace_id}/c/${swimlane._id}`)
    }
    const selectTaskAndEdit = (taskComponent) => {
        editTask(taskComponent, swimlane)
    }
    const createNewTask = () => {
        addNewTask(swimlane)
    }
    const getRidOfSwimlane = () => {
        hideConfirmPopup()
        removeSwimlane(swimlane._id)
    }
    const hideConfirmPopup = () => {
        setIsShowingConfirmMenu(false)
    }
    useEffect(() => {
        organizeDisplayedTasks()
        // setDisplayedTasks(Array.from(swimlane.tasks))
    // eslint-disable-next-line
    },[lastSwimlaneGenerationTime])
    return (
        <div className="component-timeline-swimlane">
            {isShowingConfirmMenu && <ConfirmAction mainText={`Are you sure you want to remove ${swimlane.display_name} from this timeline?`} onconfirm={getRidOfSwimlane} hideMe={hideConfirmPopup} />}
            <div className="component-timeline-row">
                {/* Collapsible */}
                <div className="component-timeline-side-title">
                    <div className="component-timeline-side-title-heading component-timeline-side-title-swimlane">
                        <div className="component-timeline-swimlane-collapse-button" onClick={() => setIsCollapsed(!isCollapsed)}>{getCollapseSymbol()}</div>
                        <div className="component-timeline-swimlane-display-name" onClick={goToWorkstream}>{swimlane.display_name}</div>
                        <div className="component-timeline-swimlane-settings-button">
                            {hasPermission(permissions, 'component_update') && <MdClose onClick={() => setIsShowingConfirmMenu(true)}/>}
                        </div>
                    </div>
                </div>

                <div className="component-timeline-side-content">
                    <div className="component-timeline-content-heading-container">
                        {dateRange.map((dateString) => (
                            <div className="component-timeline-content-heading-col component-timeline-content-col-width" key={dateString}> <span style={{color: "transparent"}}></span> </div>
                        ))}            
                    </div>
                </div>
            </div>

            {/* Tasks */}
            {displayedTasks && displayedTasks.length > 0 && !isCollapsed &&
            <>
                {displayedTasks.map((task, index) => (
                    <ComponentTimelineTaskRow editTask={selectTaskAndEdit} updateTaskDueDate={updateTaskDueDate} taskIndex={index} keyDate={keyDate} dateRange={dateRange} key={task._id} task={task} allowDragEvents={allowDragEvents} handleTaskDragging={handleTaskDragging}/>
                ))}
            </>
            }
            {displayedTasks && displayedTasks.length === 0 && !isCollapsed &&
            <div className="component-timeline-row">
                <div className="component-timeline-side-title">
                    <div className="component-timeline-side-title-empty-tasks">No scheduled tasks yet...</div>
                </div>
                <div className="component-timeline-side-content"></div>
            </div>
            }

            {/* Unscheduled Tasks */}
            {unscheduledTasks && unscheduledTasks.length > 0 && !isCollapsed &&
            <div className="component-timeline-row">
                <div className="component-timeline-side-title">
                    <div className="component-timeline-side-title-task-unscheduled" onClick={() => setIsShowingUnscheduledTasks(!isShowingUnscheduledTasks)}>
                        {isShowingUnscheduledTasks ? <MdExpandMore/> : <MdExpandLess/> }
                        Unscheduled tasks
                    </div>
                </div>
            </div>
            }

            {isShowingUnscheduledTasks && unscheduledTasks.length > 0 && !isCollapsed &&
            <>
            {unscheduledTasks.map((task) => (
                <div className="component-timeline-row" key={task._id}>
                    <div className="component-timeline-side-title">
                        <div className="component-timeline-side-title-task" style={{paddingLeft: "48px"}} onClick={() => selectTaskAndEdit(task)}>{task.display_name}</div>
                    </div>
                    <div className="component-timeline-side-content"></div>
                </div>
            ))}
            </>
            }
            {!isCollapsed && hasPermission(permissions, 'component_create') &&
            <div className="component-timeline-row">
                <div className="component-timeline-side-title">
                <div className="component-timeline-swimlane-add-task-row">
                    <div className="component-timeline-swimlane-add-task" onClick={createNewTask}><MdAdd/><span>Task</span></div>
                </div>
                </div>
                <div className="component-timeline-side-content"></div>
            </div>
            }

        </div>

    )
}
