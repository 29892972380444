import React from 'react'
import { IoClose } from 'react-icons/io5';

export default function ModalSimpleHeader({hideModal, title}) {
    return (
        <div className="modal-mgmt-header-row">
            <h2>{title}</h2>
            <IoClose onClick={hideModal}/>
        </div>
    )
}
