import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { Calendar } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { MdFirstPage, MdLastPage, MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { format, addDays, subDays } from 'date-fns';

export default function ComponentTimelineDateSelector({setKeyDate, keyDate, range}) {
    const [isShowingDatePicker, setIsShowingDatePicker] = useState(() => { return false })
    const [shownDate, setShownDate] = useState(() => { return addDays(keyDate, 2) })
    const datePickerRef = useRef(null)
    const advanceOneDay = () => setKeyDate(addDays(keyDate, 1))
    const retreatOneDay = () => setKeyDate(subDays(keyDate, 1))
    const advanceToNextWeek = () => setKeyDate(addDays(keyDate, 7))
    const retreatOneWeek = () => setKeyDate(subDays(keyDate, 7))
    const setToday = () => setKeyDate(subDays(new Date(), 2))
    const hide = () => {
        setIsShowingDatePicker(false)
    }
    const handleDateChange = (d) => {
        setShownDate(d)
        hide()
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
                hide()
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [datePickerRef])
    useEffect(() => {
        setShownDate(addDays(keyDate, 2))
    // eslint-disable-next-line
    },[])
    useEffect(() => {
        setKeyDate(subDays(shownDate,2))
    // eslint-disable-next-line
    }, [shownDate])
    return (
        <div className="component-timeline-date-selector-container" ref={datePickerRef}>
            <div className="component-timeline-date-selector-date-button" onClick={setToday}>Today</div>
            <div className="component-timeline-date-selector-symbol-button" onClick={retreatOneWeek}><MdFirstPage/></div>
            <div className="component-timeline-date-selector-symbol-button" onClick={retreatOneDay}><MdChevronLeft/></div>
            <div className="component-timeline-date-selector-date-button" onClick={() => setIsShowingDatePicker(!isShowingDatePicker)}>{format(addDays(keyDate,2), 'PP')}</div>
            <div className="component-timeline-date-selector-symbol-button" onClick={advanceOneDay}><MdChevronRight/></div>
            <div className="component-timeline-date-selector-symbol-button" onClick={advanceToNextWeek}><MdLastPage/></div>
            {isShowingDatePicker && (
                <div className="date-box-editable-calendar-picker" style={{top: "100%"}}>
                    <Calendar date={shownDate} onChange={handleDateChange} />
                </div>
            )}
        </div>
    )
}
