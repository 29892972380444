import React from 'react'
import '../styles/sidebar.css';
import SidebarHome from './sidebars/SidebarHome';
import SidebarWorkspaceSlim from './sidebars/SidebarWorkspaceSlim';
import SidebarNetwork from './sidebars/SidebarNetwork';
import { AiOutlineDoubleLeft, AiOutlineBook, AiOutlineDoubleRight } from 'react-icons/ai'
import { useHistory } from 'react-router-dom';

export default function ResponsiveSidebar({page, permissions, workspace, workspacesAndNetworks, networkWorkspaces, privateWorkspaceCount, workspaceId, components, workspaceName, componentId, sidebarCollapsed, setSidebarCollapsed, network, userWorkspaces}) {
    const history = useHistory()
    const getClass = () => {
        if (sidebarCollapsed) {
            return "sidebar-move-left"
        } else {
            return ""
        }
    }
    const getBackgroundColor = () => {
        if (sidebarCollapsed) return { backgroundColor: "transparent", transform: "translateX(-240px)"}
        return {}
    }
    const openDocs = () => {
        const newWindow = window.open('https://docs.tetheros.com', '_blank', 'noopener,noreferrer') 
        if (newWindow) newWindow.opener = null
    }
    const openPrivacyPolicy = () => {
        return history.push('/privacy-policy')
    }
    return (
        <>
        {sidebarCollapsed ? ( 
        <div className="sidebar-hamburger-show-container">
            <div className="sidebar-hamburger" onClick={() => setSidebarCollapsed(false)}>
                <AiOutlineDoubleRight />
            </div>
        </div>
        ) : (
        <div id="sidebar-left" style={getBackgroundColor()}>
            <div className={getClass()}>
                {/* Render HOME sidebar */}
                {page === 'home' && (
                    <SidebarHome workspacesAndNetworks={workspacesAndNetworks} setSidebarCollapsed={setSidebarCollapsed} privateWorkspaceCount={privateWorkspaceCount} />
                )}

                {/* Render WORKSPACE sidebar */}
                {page === 'workspace' && <SidebarWorkspaceSlim userWorkspaces={userWorkspaces} workspace={workspace} permissions={permissions} setSidebarCollapsed={setSidebarCollapsed} workspaceName={workspaceName} components={components} workspaceId={workspaceId} componentId={componentId}/>}
                {/* {page === 'workspace' && <SidebarWorkspace userWorkspaces={userWorkspaces} workspace={workspace} permissions={permissions} setSidebarCollapsed={setSidebarCollapsed} workspaceName={workspaceName} components={components} workspaceId={workspaceId} componentId={componentId}/>} */}

                {/* Render NETWORK sidebar */}
                {page === 'network' && (
                    <>
                    <SidebarNetwork permissions={permissions} networkWorkspaces={networkWorkspaces} setSidebarCollapsed={setSidebarCollapsed} network={network} components={components}/>
                    </>
                )}
                <div className="sidebar-documentation-button">
                    <div>
                        <div className="sidebar-documentation" onClick={openDocs}><AiOutlineBook/>Documentation</div>
                        <div className="sidebar-documentation" onClick={openPrivacyPolicy}>Privacy Policy</div>
                        <div className="sidebar-documentation" onClick={() => setSidebarCollapsed(true)} style={{paddingTop: "12px", paddingBottom: "12px", marginTop: "8px", borderTop: "1px solid rgb(212, 212, 212)", borderRadius: "0px"}}><AiOutlineDoubleLeft/>Collapse</div>
                    </div>
                </div>
            </div>
        </div>
        )}
        </>
        

    )
}
