import React from 'react'
import { useState, useEffect, useRef } from "react"
import { useHistory } from 'react-router-dom';

import WorkspaceCompHashtags from '../gadgets/WorkspaceCompHashtags';
import ComponentComment from '../components/sidepanes/ComponentComment';
import ComponentMainDescription from '../components/ComponentMainDescription';

import { getUserNameFromId, getAvailableCardColors, getColorHexMapFromName, hasPermission, parseHashtagsFromString } from '../../common/Helpers'

import { MdClear, MdDateRange, MdOutlineOpenInNew } from 'react-icons/md'
import { format, parseISO } from 'date-fns';
import { Calendar } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

export default function ModalTimelineEditTask({parent, closeRequest, swimlanes, team, keyDate, editTask, component, componentId, states, permissions, comments, keepOpenOnSave}) {
    const history = useHistory()
    const [displayedComponent, setDisplayedComponent] = useState(() => { return component })
    const [proposedOwner, setProposedOwner] = useState(() => { return component.owner_id })
    const [proposedParentId, setProposedParentId] = useState(() => {
        if (!parent) {
            return false
        } else {
            return parent._id
        }
    })
    const [proposedName, setProposedName] = useState(() => { return component.display_name })
    const [proposedDescription, setProposedDescription] = useState(() => { return component.attributes.description })
    const [proposedDueDate, setProposedDueDate] = useState(() => { return component.attributes.date_due })
    const [proposedStatus, setProposedStatus] = useState(() => { return component.status })
    const [proposedColorName, setProposedColorName] = useState(() => { return component.attributes.color_name })
    const [isShowingDatePicker, setIsShowingDatePicker] = useState(() => { return false })
    const [apparentTags, setApparentTags] = useState(() => { return [] })
    const [hasChanges, setHasChanges] = useState(() => { return false })
    const dueDateRef = useRef(null)
    const hide = () => {
        setIsShowingDatePicker(false)
    }
    const goToTaskPage = () => {
        if (!component) return
        history.push(`/workspaces/${component.workspace_id}/c/${component._id}`)
    }
    const handleDueDateChange = (d) => {
        setProposedDueDate(format(d, 'yyyy-MM-dd'))
        hide()
    }
    const clearDueDate = () => {
        setProposedDueDate("")
        hide()
    }
    const getPresentableDueDate = () => {
        try {
            return format(parseISO(proposedDueDate), 'PP')
        } catch (error) {
            return "None"
        }        
    }
    const submitTaskUpdate = () => {
        if (!proposedName) return // todo: display an error!
        if (!proposedParentId) return
        let payload = {
            display_name: proposedName,
            owner_id: proposedOwner,
            status: proposedStatus,
            is_published: true,
            attributes: {
                details: proposedDescription,
                date_due: proposedDueDate,
                active: true,
                parent: proposedParentId,
                color_name: proposedColorName
            }
        }
        editTask(payload, component._id)
        if (!keepOpenOnSave) return closeRequest()
        setHasChanges(false)
    }
    const hasComments = () => {
        if (!comments) return false
        if (comments.length === 0) return true
        return true
    }
    const getDueDateStart = () => {
        if (proposedDueDate === "" || !proposedDueDate) return new Date()
        return parseISO(proposedDueDate)
    }
    const updateDescription = (newDescription) => {
        setProposedDescription(newDescription)
    }
    const getColorChangeOptionStyle = (colorName) => {
        const colorInfo = getColorHexMapFromName(colorName)
        let s = {
            backgroundColor: colorInfo.hex,
            cursor: "pointer",
            border: "1.5px solid rgb(221, 221, 221)"
        }
        if (colorName === proposedColorName) s['border'] = "1.5px solid black"
        return s
    }
    const getState = () => {
        if (!states) return false
        if (states.length === 0) return false
        if (!component.attributes.state) return false
        const state = states.find((stateInfo) => {
            if (stateInfo._id === component.attributes.state) return true
            return false
        })
        if (!state) return false
        return state.name
    }
    const updatesExist = () => {
        if (proposedStatus !== component.status) return true
        if (proposedName !== component.display_name) return true
        if (proposedDescription !== component.attributes.details) return true
        let currentDueDate = component.attributes.date_due ? component.attributes.date_due : ""
        let altProposedDueDate = proposedDueDate ? proposedDueDate : ""
        if (currentDueDate !== altProposedDueDate) return true
        if (proposedColorName !== component.attributes.color_name) return true
        let currentComponentOwner = component.owner_id ? component.owner_id : ""
        let altProposedOwner = proposedOwner ? proposedOwner : ""
        if (altProposedOwner !== currentComponentOwner) return true
        return false
    }
    const resetValues = () => {
        setProposedOwner(displayedComponent.owner_id)
        setProposedParentId(parent._id)
        setProposedName(displayedComponent.display_name)
        setProposedDescription(displayedComponent.attributes.details)
        setProposedDueDate(displayedComponent.attributes.date_due)
        setProposedColorName(displayedComponent.attributes.color_name)
        setProposedStatus(displayedComponent.status)
    }
    const updateApparentTags = () => {
        try {
            let tagArray = []
            let addedTags = []
            const stringTagArray = parseHashtagsFromString(proposedDescription)
            for (let i = 0; i < stringTagArray.length; i++) {
                if (addedTags.includes(stringTagArray[i])) continue
                tagArray.push({
                    _id: i,
                    tag_name: stringTagArray[i]
                })
                addedTags.push(stringTagArray[i])
            }
            setApparentTags(tagArray)
        } catch (error) {
            setApparentTags([])
        }
    }
    useEffect(() => {
        updateApparentTags()
    // eslint-disable-next-line
    }, [proposedDescription])
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dueDateRef.current && !dueDateRef.current.contains(event.target)) {
                hide()
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [dueDateRef])
    useEffect(() => {
        setDisplayedComponent(component)
    // eslint-disable-next-line
    },[componentId])
    useEffect(() => {
        resetValues()
    // eslint-disable-next-line
    }, [displayedComponent])
    useEffect(() => {
        // check if changes are ready to save
        if (!component) return
        if (!proposedName) return
        if (updatesExist()) {
            setHasChanges(true)
            return
        }
        setHasChanges(false)
    // eslint-disable-next-line
    }, [proposedName, proposedDescription, proposedDueDate, proposedOwner, proposedColorName, proposedStatus])
    return (
        <div className="modal-timeline-container-outer-relative">
            {/* <div className="modal-timeline-container-backdrop"></div> */}
            <div className="modal-timeline-container">
                <div className="modal-timeline-header-row">
                    <div className="modal-timeline-header-row-edit-title">
                        {hasPermission(permissions, 'component_update') && <h3>Edit task</h3>}
                        {!hasPermission(permissions, 'component_update') && <h3>Task details</h3>}
                        <MdOutlineOpenInNew onClick={goToTaskPage}/>
                    </div>
                    <MdClear onClick={closeRequest}/>
                </div>

                <div className="modal-timeline-new-task-inputs-scrollable">
                    {hasPermission(permissions, 'component_update') &&
                    <div className="modal-timeline-new-task-inputs">
                        <div className="modal-timeline-new-task-input">
                            <div className="modal-timeline-new-task-input-title">Name</div>
                            <input value={proposedName} onChange={(e) => setProposedName(e.target.value)} type="text" placeholder="Enter a task name" />
                        </div>
                        <div className="modal-timeline-new-task-display">
                            <div className="modal-timeline-new-task-input-title">Workstream</div>
                            <div className="modal-timeline-new-task-display-title" onClick={() => history.push(`/workspaces/${parent.workspace_id}/c/${parent._id}`)}>{parent.display_name}</div>
                            {getState() && <div className="modal-timeline-new-task-display-subtitle-badge">{getState()}</div>}
                        </div>
                        <div className="modal-timeline-new-task-input">
                            <div className="modal-timeline-new-task-input-title">Description</div>
                            <ComponentMainDescription permissions={permissions} component={displayedComponent} updateDetails={updateDescription} team={team}/>  
                            <WorkspaceCompHashtags tags={apparentTags} workspaceId={component.workspace_id}/>
                        </div>

                        <div className="modal-timeline-new-task-input-row-2">

                        <div className="modal-timeline-new-task-input">
                            <div className="modal-timeline-new-task-input-title">Due Date</div>
                            <div className="modal-timeline-new-task-date-selector-container" ref={dueDateRef}>
                                <div className="modal-timeline-new-task-date-selector-date-button" onClick={() => setIsShowingDatePicker(!isShowingDatePicker)}><MdDateRange/>{getPresentableDueDate()}</div>
                                {isShowingDatePicker && (
                                <div className="date-box-editable-calendar-picker" style={{left: "0px", top: "100%", zIndex: "12", backgroundColor: "white"}}>
                                    <Calendar date={getDueDateStart()} onChange={handleDueDateChange} />
                                    <div className="modal-timeline-new-task-clear-date-button" onClick={clearDueDate}>Clear Due Date</div>
                                </div>
                                )}
                            </div>
                        </div>
                        <div className="modal-timeline-new-task-input">
                            <div className="modal-timeline-new-task-input-title">Status</div>
                            <select value={proposedStatus} onChange={(e) => setProposedStatus(e.target.value)}>
                                <option value="active">Active</option>
                                <option value="completed">Complete</option>
                            </select>
                        </div>

                        </div>
                        {team &&
                        <div className="modal-timeline-new-task-input">
                            <div className="modal-timeline-new-task-input-title">Owner</div>
                            <select value={proposedOwner} onChange={(e) => setProposedOwner(e.target.value)}>
                                <option value="">Nobody</option>
                                {team.map((teammate) => (
                                    <option key={teammate._id} value={teammate._id}>{getUserNameFromId(team, teammate._id)}</option>
                                ))}
                            </select>
                        </div>}
                        <div className="modal-timeline-new-task-input">
                            <div className="modal-timeline-new-task-input-title">Task Color</div>

                            <div className="modal-full-screen-edit-task-color-change-colors">
                                {getAvailableCardColors().map((colorName) => (
                                    <div style={getColorChangeOptionStyle(colorName)} className="modal-full-screen-edit-task-color-change-color-circle" key={colorName} onClick={() => setProposedColorName(colorName)}></div>
                                ))}
                            </div>

                        </div>
                        {hasComments() &&
                        <div className="modal-timeline-new-task-input modal-timeline-new-task-comments">
                            <div className="modal-timeline-new-task-input-title">Comments</div>
                            {comments.length === 0 && <span>No comments</span> }
                            <div className="modal-timeline-comments-list">
                            {comments.map((comment) => (
                                <ComponentComment updateComment={false} deleteComment={false} key={comment._id} comment={comment} team={team} hideProfilePicture={true}/>
                            ))}
                            </div>
                        </div>
                        }
                    </div>
                    }
                    {!hasPermission(permissions, 'component_update') &&
                    <div className="modal-timeline-new-task-inputs" style={{gap: "32px"}}>
                        <div className="modal-timeline-new-task-display-name">{proposedName}</div>
                        <div className="modal-timeline-new-task-display">
                            <div className="modal-timeline-new-task-input-title">Workstream</div>
                            <div className="modal-timeline-new-task-display-title" onClick={() => history.push(`/workspaces/${parent.workspace_id}/c/${parent._id}`)}>{parent.display_name}</div>
                            {getState() && <div className="modal-timeline-new-task-display-subtitle-badge">{getState()}</div>}
                        </div>
                        <div className="modal-timeline-new-task-display-description">
                            <ComponentMainDescription permissions={permissions} component={displayedComponent} updateDetails={updateDescription}/>
                        </div>
                        <div className="modal-timeline-new-task-display-key-attributes">
                            <div className="modal-timeline-new-task-display-key-attribute">
                                <span>Due Date:</span>
                                {getPresentableDueDate()}
                            </div>
                            <div className="modal-timeline-new-task-display-key-attribute">
                                <span>Owner:</span>
                                <div className="modal-timeline-new-task-display-owner">{getUserNameFromId(team, proposedOwner)}</div>
                            </div>
                            <div className="modal-timeline-new-task-display-key-attribute">
                                <span>Created</span>
                                {format(parseISO(component.createdAt), 'PP')}
                            </div>
                        </div>
                    </div>
                    }
                </div>

                {hasPermission(permissions, 'component_update') &&
                <div className="modal-timeline-new-task-actions modal-timeline-new-task-actions-alignment">
                    <div className="modal-timeline-new-task-actions-buttons">
                        {hasChanges && <div onClick={submitTaskUpdate}>Save Changes</div>}
                        {!hasChanges && <div style={{backgroundColor: "grey", cursor: "not-allowed"}}>Save</div>}
                        <div onClick={closeRequest}>Cancel</div>
                    </div>
                </div>
                }
            </div>
            
        </div>
    )
}