import React from 'react'
import '../../styles/public.css';
import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom';

import { format, parseISO } from 'date-fns';

import Api from '../../common/APIUtils';
import { getPercentageComplete } from '../../common/Helpers';
import HProgressBar from '../HProgressBar';
import PublicInitiative from './PublicInitiative';
import PublicList from './PublicList';

import { RiListCheck2, RiDashboardLine, RiMistFill, RiFocus2Line } from 'react-icons/ri'
import { MdPersonOutline, MdCalendarToday, MdDiamond } from "react-icons/md";

export default function PublicComponent({ authenticated }) {
    const { componentId } = useParams();
    const [isLoading, setIsLoading] = useState(() => { return false })
    const [component, setComponent] = useState(() => { return false })
    const [errorMessage, setErrorMessage] = useState(() => { return false })
    const api = new Api()
    const history = useHistory()
    const fetchPublicComponent = () => {
        setIsLoading(true)
        setErrorMessage(false)
        api.getPublicComponent(componentId)
        .then((res) => {
            if (res.data.component) setComponent(res.data.component)
            setIsLoading(false)
            setErrorMessage(false)
        })
        .catch((err) => {
            setErrorMessage("You don't have permission to see this")
            // TODO: Redirect or show error message
            setIsLoading(false)
            setComponent(false)
        })
    }
    const getRepresentativeSymbol = () => {
        if (component.name === "list") return <RiListCheck2/>
        if (component.name === "board") return <RiDashboardLine/>
        if (component.name === "timeline") return <RiMistFill/>
        if (component.name === "kresult") return <RiFocus2Line/>
        if (component.name === "initiative") return <MdDiamond/>
        return <RiMistFill/>
    }
    const getComponentNameDecoration = () => {
        try {
            let displayName = component.name
            if (component.name === "initiative") displayName = "Project"
            if (component.name === "kresult") displayName = "Goal"
            if (component.name === "kpi") displayName = "KPI"
            return (
                <div className="public-component-heading-decoration">
                    {getRepresentativeSymbol()}
                    <span>{displayName}</span>
                </div>
            )
        } catch (error) {
            return false
        }
    }
    const getStatusDecoration = () => {
        try {
            if (component.name === "kpi") return false
            if (["board", "list", "initiative", "kresult"].includes(component.name)) {
                return (
                    <div className="public-component-heading-decoration" style={{width: "120px"}}>
                        <HProgressBar percentage={getPercentageComplete(component)} height={8} />
                        <span>{Math.round(getPercentageComplete(component))}%</span>
                    </div>
                )
            }
            // if task in a board, get the column name here and use a color based on the column name
            return <span className={`component-view-title-meta-status-${component.status}`}>{component.status}</span>
        } catch (error) {
            console.log(error)
            return false
        }
    }
    const getOwnerDecoration = () => {
        try {
            if (!component.owner_name || component.owner_name === "" || component.owner_id === "") return false
            return (
                <div className="public-component-heading-decoration">
                    <MdPersonOutline/>
                    <span>{component.owner_name}</span>
                </div>
            )
        } catch (error) {
            return false
        }
    }
    const getDateDecoration = () => {
        try {
            if (component.name === "initiative") {
                let dateText = `${format(parseISO(component.attributes.date_start), "M/d/Y")} → ${format(parseISO(component.attributes.date_end), "M/d/Y")}`
                if (component.status === "completed") dateText = `Completed ${format(parseISO(component.completed), "PPP")}`
                return (
                    <div className="public-component-heading-decoration">
                        <MdCalendarToday/>
                        <span>{dateText}</span>
                    </div>
                )
            }
            if (component.name === "task") {
                let dateText = false
                if (component.attributes.date_due && component.attributes.date_due !== "") dateText = format(parseISO(component.attributes.date_due), "PPP") 
                if (component.status === "completed" && component.completed) dateText = `Completed ${format(parseISO(component.completed), "PPP")}`
                if (!dateText) return false
                return (
                    <div className="public-component-heading-decoration">
                        <MdCalendarToday/>
                        <span>{dateText}</span>
                    </div>
                )
            }
            return false
        } catch (error) {
            return false
        }
    }
    const getStickyStyles = () => {
        try {
            if (authenticated) return { top: "48px" }
            return { top: "0px" }
        } catch (error) {
            return {}
        }
    }
    useEffect(() => {
        fetchPublicComponent()
    // eslint-disable-next-line
    }, [componentId])
    return (
        <div className="public-component-page">
            {component && !errorMessage && !isLoading &&
            <div className="public-component-container-main">
                <div className="public-component-heading" style={getStickyStyles()}>
                    <h2>{component.display_name}</h2>
                    {component.workspace && <div className="public-component-subheading-workspace">{component.workspace.name}</div>}
                    <div className="util-row util-align-center util-space-between">
                        <div className="public-component-heading-decorators">
                            {getComponentNameDecoration()}
                            {getOwnerDecoration()}
                            {getDateDecoration()}
                        </div>
                        <div className="public-component-heading-decorators">
                            {getStatusDecoration()}
                        </div>
                    </div>
                </div>
                {component.name === "initiative" && <PublicInitiative component={component} /> }
                {component.name === "list" && <PublicList component={component} /> }
            </div>
            }
            {isLoading && <div>Loading...</div>}
            {!isLoading && errorMessage &&
            <div className="public-component-redirect-container">
                <div className="public-component-redirect-message">{errorMessage}</div>
                {authenticated && <div className="public-component-redirect-action" onClick={() => history.push('/home')}>Home</div>}
                {!authenticated && <div className="public-component-redirect-action" onClick={() => history.push('/login')}>Sign Up</div>}
            </div>
            }
        </div>
    )
}
