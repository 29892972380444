import { IoEllipsisVertical } from 'react-icons/io5'
import React, { useState, useEffect, useRef } from 'react';

export default function PostOptions({actionsList}) {
    const [collapsed, setCollapsed] = useState(() => { return true })
    const menuRef = useRef(null)
    const hide = () => {
        setCollapsed(true)
    }
    const doAction = (actionItem) => {
        if (actionItem.action) {
            hide()
            actionItem.action()
        }
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                hide()
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [menuRef])
    return (
        <div className="post-options-container-outer" ref={menuRef}>
            <div className="post-options-button" onClick={() => setCollapsed(!collapsed)}>
                <IoEllipsisVertical/>
            </div>

            {actionsList && 
            <div className="simple-list-action-menu-dropdown">
                {!collapsed && (
                <div className="simple-list-action-menu-primary-display">
                    <div className="simple-list-action-menu-items">
                        {actionsList.map((actionItem) => (
                            <div className="simple-list-action-menu-item" key={actionItem.id} onClick={() => doAction(actionItem)}>{actionItem.text}</div>
                        ))}
                    </div>
                </div>
                )}
            </div>
            }
        </div>
    )
}
