import React from 'react'
import '../../styles/posts.css';
import { formatDistanceToNow, formatDistanceToNowStrict, parseISO } from 'date-fns';
import { Link, useHistory } from 'react-router-dom';
import { useState, useEffect, useRef, useCallback } from 'react';
import NewPostComment from './NewPostComment';
import Api from '../../common/APIUtils';
import ProfPicRoundedSquare from '../profiles/ProfPicRoundedSquare';
import ProfPicCircle from '../profiles/ProfPicCircle';
import { FaRegCommentDots, FaRegComment } from 'react-icons/fa'
import { AiOutlineLike } from 'react-icons/ai'
import { getUserProfileSrcFromId, formatHtmlStringWithHyperlinks, wrapHashtagsInSpan } from '../../common/Helpers';
import { convertFromRaw } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import PostOptions from './PostOptions';
import ModalEditPost from '../modals/ModalEditPost';
import { IoTrashOutline } from 'react-icons/io5'
import { getPostMultimedia } from '../../common/Api';
import PostImagePreview from './PostImagePreview';
import PostMediaFullView from './PostMediaFullView';

export default function Post({ post, fetchPosts, feedDisplay, workspaceMap, team }) {
    const api = new Api();
    const history = useHistory();
    const [isShowingFullView, setIsShowingFullView] = useState(() => { return false })
    const [multiMediaFocusUrl, setMultimediaFocusUrl] = useState(() => { return false })
    const [multiMediaFocusIndex, setMultimediaFocusIndex] = useState(() => { return 0 })
    const [likeReactions, setLikeReactions] = useState(() => { return 0 })
    const isLikedByUser = useRef(false)
    const [isShowingEditPost, setIsShowingEditPost] = useState(() => { return false })
    const [commentsVisible, setCommentsVisible] = useState(() => { return false })
    const [postImages, setPostImages] = useState(() => { return [] })
    const postRef = useRef(null);
    // const [hasVideo, setHasVideo] = useState(() => { return false })
    const [imagesLoaded, setImagesLoaded] = useState(() => { return false })
    const [hasMultimedia, setHasMultimedia] = useState(() => { return false })
    const [postComments, setPostComments] = useState(() => { return [] })
    const [youtubeVideoId, setYoutubeVideoId] = useState(() => { return null });
    const [comment, setComment] = useState(() => { return "" })
    const toggleShowComments = () => {
        setCommentsVisible(!commentsVisible);
    }
    const isLexical = (content) => {
        try {
            if (content.description_editor && content.description_html) return true
            return false
        } catch (error) {
            return false
        }
    }
    const renderTextContent = () => {
        try {
            if (!post) return <div className="project-post-content"></div>
            if (post.type === 'component') return renderComponentPost()
            if (post.text_content) return <div className="project-post-content">{post.text_content}</div>
    
            // Determine if it is a legacy rich_text_content post (draft.js) or current (lexical)
            if (!post.rich_text_content) return <div className="project-post-content"></div>
            let postContent = JSON.parse(post.rich_text_content)
            if (isLexical(postContent)) {
                return (
                    <div className="project-post-content gadget-lexical-displayed-text-container" style={{cursor: "pointer"}}>
                        <div dangerouslySetInnerHTML={{ __html: wrapHashtagsInSpan(postContent.description_html, post.workspace_id) }}></div>
                    </div>
                )
            }
            // Draft.js (legacy, deprecated)
            const formattedRichText = formatHtmlStringWithHyperlinks(convertToHTML(convertFromRaw(postContent)))
            return <div className="project-post-content" dangerouslySetInnerHTML={{ __html: formattedRichText }} />   
        } catch (error) {
            return <div className="project-post-content"></div>
        }
    }
    const renderComponentPost = () => {
        // for type = 'component'
        if (post.type !== 'component') return false
        try {
            return (
                <div className="project-post-content">
                    <span className="project-post-content-clickable" onClick={() => history.push(`/user/${post.creator}`)}>{post.author}</span> {post.action} <span className="project-post-content-clickable" onClick={() => history.push(`/workspaces/${post.workspace_id}/c/${post.component_id}`)}>{post.post_heading}</span> {feedDisplay === "home" && <span>in the workspace <span className="project-post-content-clickable" onClick={() => history.push(`/workspaces/${post.workspace_id}`)}>{post.post_special}</span>.</span>}
                </div>
            )
        } catch (error) {
            return false
        }

    }
    const deletePost = () => {
        api.deletePost({
            post_id: post._id
        })
        .then((res) => {
            fetchPosts()
        })
        .catch((err) => {
            console.log(err)
        })
    }
    const deletePostComment = (commentId) => {
        api.deletePost({
            post_id: commentId
        })
        .then((res) => {
            fetchPosts()
        })
        .catch((err) => {
            console.log(err)
        })
    }
    const isEdited = () => {
        if (post.is_edited) return true
        return false
    }
    const editPost = () => {
        setIsShowingEditPost(true)
    }
    const hideEditPostForm = () => {
        setIsShowingEditPost(false)
    }
    const updateWorkspacePost = (payload) => {
        if (!payload.post_id) return
        if (payload) {
            api.updatePost(payload)
            .then((res) => {
                fetchPosts();
            })
            .catch((err) => {
                console.log(err);
            });   
        }
    }
    const postComment = () => {
        setComment("");
        const payload = {
            parent_id: post._id,
            text_content: comment
        }
        api.createPostComment(payload)
        .then((res) => {
            fetchPosts();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const getAuthorPictureSrc = () => {
        if (post.author_profile_pic_src) {
            return post.author_profile_pic_src
        } else {
            return false
        }
    }
    const goToWorkspace = () => {
        if (!post.workspace_id) return history.push(`/workspaces/${localStorage.getItem("gId")}`)
        history.push(`/workspaces/${post.workspace_id}`)
    }
    const getWorkspaceNameFromPost = (post) => {
        if (!post) return "Workspace"
        if (!post.workspace_name) return "Workspace"
        return post.workspace_name
    }
    const updateReactions = () => {
        try {
            if (!post) return
            if (!post.reactions) return 
            if (post.reactions.length === 0) return
            const filteredLikeReactions = post.reactions.filter(reaction => reaction.reaction_name_legacy === "like")
            setLikeReactions(filteredLikeReactions.length)
            const userHasLiked = filteredLikeReactions.find(r => r.user_id === localStorage.getItem("gId"))
            if (userHasLiked) isLikedByUser.current = true
            if (!userHasLiked) isLikedByUser.current = false
        } catch (error) {
            return
        }
    }
    const likePost = () => {
        if (isLikedByUser.current) return unlikePost()
        // optimistically update local
        setLikeReactions(likeReactions + 1)
        isLikedByUser.current = true
        api.createPostReaction({post_id: post._id, legacy_reaction_string: "like"})
        .then((res) => {
            fetchPosts()
        })
        .catch((err) => fetchPosts())
    }
    const goToPostPage = () => {
        if (!post) return
        if (!post._id) return
        if (!post.workspace_id) return
        if (post.type === "component") return
        history.push(`/workspaces/${post.workspace_id}/p/${post._id}`)
    }
    const unlikePost = () => {
        setLikeReactions(likeReactions - 1)
        isLikedByUser.current = false
        api.removePostReaction({post_id: post._id, legacy_reaction_string: "like"})
        .then((res) => {
            fetchPosts()
        })
        .catch((err) => fetchPosts())
    }
    const additionalReactionStyle = () => {
        if (!isLikedByUser.current) return {backgroundColor: "#ecedf3a2"}
        return {}
    }
    const getPostHeader = () => {
        return post.author
    }
    const emptyFunction = () => {
        return
    }
    const getPostSummary = () => {
        let onclick = emptyFunction
        let classname = ""
        let summaryText = `${formatDistanceToNowStrict(parseISO(post.created))} ago`
        if (feedDisplay === 'home') {
            summaryText += ` in ${getWorkspaceNameFromPost(post)}`
            onclick = goToWorkspace
            classname = "post-subheading-clickable-workspace"
        }
        if (isEdited()) summaryText += " · Edited"
        return <div className={classname} onClick={onclick}>{summaryText}</div>
    }
    const isOwnerOfComment = (comment) => {
        if (comment.creator === localStorage.getItem("gId")) return true
        return false 
    }
    const updateComments = () => {
        try {
            if (!post) return
            if (!post.comments) return
            setPostComments(post.comments)
        } catch (error) {
            return
        }
    }
    const isValidHttpUrl = (s) => {
        let url;
        try {
            url = new URL(s);
        } catch (e) { return false; }
        return /https?/.test(url.protocol);
    }
    const getCommentWithUrls = (sourceString) => {
        if (!sourceString) return ""
        let description = []
        // First split line breaks
        const lines = sourceString.split('\n')
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            const words = line.split(" ")
            for (let d = 0; d < words.length; d++) {
                const word = words[d];
                if (isValidHttpUrl(word)) {
                    description.push(<a rel="noreferrer" target="_blank" href={word}>{word}</a>)
                } else {
                    description.push(<>{word}</>)
                }
                description.push(<>{" "}</>)
            }
            description.push(<>{"\n"}</>)
        }
        return description
    }
    const isActiveUsersPost = () => {
        if (post.creator === localStorage.getItem("gId")) return true
        return false
    }
    const getPostOptionsForPostOwner = () => {
        let postOptions = [{
            id: 'delete-post',
            action: deletePost,
            text: 'Delete post'
        }]
        if (post.text_content && !post.rich_text_content) return postOptions
        return [
            {
                id: 'edit-post',
                action: editPost,
                text: 'Edit post'
            },
            {
                id: 'delete-post',
                action: deletePost,
                text: 'Delete post'
            }
        ]
    }
    const getCommentsLength = () => {
        try {
            if (!post) return 0
            return post.comments.length
        } catch (error) {
            return 0
        }
    }
    const isValidComponentPost = () => {
        try {
            if (post.type !== 'component') return false
            return true
        } catch (error) {
            return false
        }
    }
    const fetchMultimedia = () => {
        try {
            getPostMultimedia(post.workspace_id, post._id)
            .then((res) => {
                if (res.data) {
                    if (res.data.signed_urls) {
                        setPostImages(res.data.signed_urls)
                        setImagesLoaded(true)
                        setHasMultimedia(true)
                    }
                }
            })
        } catch (error) {
            return
        }
    }
    const observeImage = (entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                fetchMultimedia()
                observer.unobserve(entry.target);
            }
        });
    }
    const updateMultimediaSettings = () => {
        try {
            if (!post) {
                return setHasMultimedia(false)
            }
            if (!post.image_urls) {
                return setHasMultimedia(false)
            }
            if (post.image_urls.length === 0) {
                return setHasMultimedia(false)
            }
            setHasMultimedia(true)
            const observer = new IntersectionObserver(observeImage, {
                root: null,
                rootMargin: "0px",
                threshold: 0.1
            })
            if (postRef.current) {
                observer.observe(postRef.current);
            }
    
            return () => {
                if (postRef.current) {
                    observer.disconnect();
                }
            };
        } catch (error) {
            return
        }
    }
    const selectImageToView = (e, imageUrl, imageIndex) => {
        try {
            e.stopPropagation()
            let proposedIndex = 0
            if (imageIndex) proposedIndex = imageIndex
            setMultimediaFocusIndex(proposedIndex)
            setMultimediaFocusUrl(imageUrl)
            setIsShowingFullView(true)
        } catch (error) {
            return
        }
    }
    const extractYouTubeVideoId = useCallback((html) => {
        try {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = html;
            const links = tempDiv.querySelectorAll('a');
            for (let link of links) {
                const href = link.getAttribute('href');
                if (href) {
                    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=|embed\/|v\/|shorts\/|live\/\?v=)?([a-zA-Z0-9_-]{11})/;
                    const match = href.match(regex);
                    if (match && match[1]) {
                        return match[1];
                    }
                }
            }
            const text = tempDiv.textContent || tempDiv.innerText;
            const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=|embed\/|v\/|shorts\/|live\/\?v=)?([a-zA-Z0-9_-]{11})/;
            const match = text.match(regex);
            return match && match[1] ? match[1] : null;
        } catch (error) {
            return null
        }
    }, []);
    const updateYoutubeIdentification = () => {
        try {
            if (hasMultimedia) return
            if (!post.rich_text_content) return
            if (postImages.length > 0) return
            const content = JSON.parse(post.rich_text_content);
            const videoId = extractYouTubeVideoId(content.description_html || '');
            setYoutubeVideoId(videoId);
        } catch (error) {
            return setYoutubeVideoId(null)
        }
    }
    useEffect(() => {
        updateReactions()
        updateComments()
        updateMultimediaSettings()
        updateYoutubeIdentification()
    // eslint-disable-next-line
    }, [post])
    useEffect(() => {
        if (isShowingFullView) {
          document.body.classList.add('app-scroll-prevention');
        } else {
          document.body.classList.remove('app-scroll-prevention');
        }
        return () => {
          document.body.classList.remove('app-scroll-prevention');
        };
    }, [isShowingFullView]);
    return (
        <div className="project-single-post-container" ref={postRef} onClick={() => goToPostPage()}>
            {isShowingFullView && <PostMediaFullView hideView={() => setIsShowingFullView(false)} imageUrls={postImages} focusIndex={multiMediaFocusIndex} focusUrl={multiMediaFocusUrl} post={post}  /> }
            <div>
                {isValidComponentPost() && (
                    <div className="project-single-post-header-container" onClick={(e) => e.stopPropagation()}>
                        <ProfPicCircle height={42} src={getAuthorPictureSrc()} userId={post.creator} userName={post.author}  />
                        <div>
                            <div className="project-single-post-header" onClick={() => history.push(`/user/${post.creator}`)}>{post.author}</div>

                            <div className="project-single-post-summary">
                                <div className="project-post-time">
                                {getPostSummary()}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {post.type === 'workspace' && (
                    <div className="project-single-post-header-container" onClick={(e) => e.stopPropagation()}>
                        {isShowingEditPost && <ModalEditPost hideEditPostForm={hideEditPostForm} updateWorkspacePost={updateWorkspacePost} post={post} team={team} />}
                        <ProfPicCircle height={42} src={getAuthorPictureSrc()} userId={post.creator} userName={post.author}  />
                        <div>
                            <div className="project-single-post-header" onClick={() => history.push(`/user/${post.creator}`)}>
                                {getPostHeader()}
                            </div>
                            <div className="project-single-post-summary">
                                <div className="project-post-time">
                                    {getPostSummary()}
                                </div>
                            </div>
                        </div>
                        {isActiveUsersPost() && (
                            <PostOptions actionsList={getPostOptionsForPostOwner()}/>
                        )}
                    </div>
                )}

                {post.type === 'user' && (
                    <div className="project-single-post-header-container" onClick={(e) => e.stopPropagation()}>
                        <ProfPicRoundedSquare src={getAuthorPictureSrc()} userId={post.creator} userName={post.author} height={48}/>
                        <div>
                            <div className="project-single-post-header">
                                <Link className="unlink" to={`/user/${post.creator}`}>{post.post_heading}</Link>
                            </div>
                            <div className="project-single-post-summary">
                                <div className="project-post-time">
                                    {formatDistanceToNow (new Date(post.created))} ago
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {post.type === 'system' && (
                    <div className="project-single-post-header-container">
                        <div>
                            <div className="project-single-post-header">
                                <Link className="unlink" to={`/user/${post.creator}`}>{post.post_heading}</Link>
                            </div>
                            <div className="project-single-post-summary">
                                <div className="project-post-time">
                                    {formatDistanceToNow (new Date(post.created))} ago
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {post.post_title &&
                <div className="project-post-title" style={{cursor: "pointer"}}>{post.post_title}</div>
                }
                {youtubeVideoId && postImages.length === 0 && <EmbeddedYouTubeVideo youtubeVideoId={youtubeVideoId} /> }
                {renderTextContent()}
                {/* IMAGES */}
                {hasMultimedia && imagesLoaded && <PostImagePreview images={postImages} selectImageToView={selectImageToView} />}
            </div>

            <div className="project-post-discussion" onClick={(e) => e.stopPropagation()}>
                <div className="project-post-reactions">
                    <div className="project-post-reaction" onClick={likePost} style={additionalReactionStyle()}>
                        {isLikedByUser.current ? <>👍</> : <AiOutlineLike style={{fontSize: "15px"}}/>} <span>{likeReactions}</span>
                    </div>
                </div>
                <div className="feed-post-discussion-item" onClick={(e) => toggleShowComments()}>
                    {getCommentsLength() > 0 && <FaRegCommentDots/>}
                    {getCommentsLength() === 0 && <FaRegComment/>}
                    {post.comments && <span>{getCommentsLength()} comments</span>}
                </div>
            </div>

            {commentsVisible &&
                <div onClick={(e) => e.stopPropagation()}>
                    <div className="project-post-comments">
                        {postComments.map((comment) => (
                            <div className="project-post-comment-container" key={comment._id}>
                                {isOwnerOfComment(comment) && <div className="project-post-floating-tools" onClick={() => deletePostComment(comment._id)}><IoTrashOutline/></div> }
                                <ProfPicCircle height={42} src={getUserProfileSrcFromId(team, comment.creator)} userId={comment.creator} userName={comment.author}  />
                                <div className="project-post-comment-content-enclosure">
                                    <div className="project-post-comment-header-titles">
                                        <p className="project-post-comment-header-author">{comment.author}</p>
                                        <p className="project-post-comment-header-created">{formatDistanceToNowStrict (new Date(comment.created))} ago</p>
                                    </div>

                                    <div className="project-post-comment-content">
                                        {getCommentWithUrls(comment.text_content)}
                                    </div>
                                </div>
                            </div>
                        ))}
                        {postComments.length < 1 && <div style={{color: "grey", fontSize: "0.9em", textAlign: "center"}}>Be the first to comment</div>}
                    </div>
                    <NewPostComment postComment={postComment} comment={comment} setComment={setComment}/>
                </div>
            }
        </div>
    )
}

function EmbeddedYouTubeVideo({ youtubeVideoId }) {
    return (
        <div className="youtube-embed-container" onClick={(e) => e.stopPropagation()}>
            <iframe
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${youtubeVideoId}?controls=1&showinfo=0&rel=0`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded YouTube video"
            ></iframe>
        </div>
    )
}