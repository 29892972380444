import React from 'react'

export default function DataTableEntry({content, special}) {

    const specialClasses = {
        pill: "data-table-basic-pill",
        bluepill: "data-table-blue-pill",
        greenpill: "data-table-green-pill",
        redpill: "data-table-red-pill",
        yellowpill: "data-table-yellow-pill",
        purplepill: "data-table-purple-pill",
        capitalize: "data-table-capitalize"
    }

    return (
        <div className={`data-table-row-cell ${specialClasses[special] || ''}`}>
                {content}
        </div>
    )
}
