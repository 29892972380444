import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom';
import { ReactComponent as Logo } from '../../static/assets/logov1-basicblue.svg';
import ProfPicCircle from '../profiles/ProfPicCircle';
import { Player } from '@lottiefiles/react-lottie-player'
import { validateUrl } from '../../common/Helpers';

import { initializeAccount, proposeInitialPlan, getAccountInitializationStatus, activateTrial, completeOnboarding } from '../../common/Api';

export default function WizardOnboarding({ logout, refreshAuthStatus }) {
    const history = useHistory()
    const [page, setPage] = useState(() => { return "loading" })
    const [pageTitle, setPageTitle] = useState(() => { return "Welcome to Tetheros" })
    const [pageInstructions, setPageInstructions] = useState(() => { return "Align your effort and impact" })
    const [companyName, setCompanyName] = useState(() => { return "" })
    const [companyWebsite, setCompanyWebsite] = useState(() => { return "" })
    const [industry, setIndustry] = useState(() => { return "" })
    const [projectDescription, setProjectDescription] = useState(() => { return "" })
    const [onboardBlueprint, setOnboardBlueprint] = useState(() => { return {} })
    const lottieCelebrationFile = require('../../static/assets/lottie_confetti.json')
    const updatePageTitles = () => {
        if (page === "introduction") {
            setPageTitle(`Welcome to Tetheros!`)
            setPageInstructions("Tell us a little about your work. We'll use this to setup your workspace")
            return
        }
        if (page === "project") {
            setPageTitle("Let's start by adding your first project")
            setPageInstructions("Describe your project in a sentence or two and provide as much detail as possible.")
            return
        }
        if (page === "generating") {
            setPageTitle("Building your workspace...")
            setPageInstructions(false)
            return
        }
        if (page === "builderror") {
            setPageTitle("Something went wrong")
            setPageInstructions("Please try again later")
            return
        }
        if (page === "confirmation") {
            return
        }
        setPageTitle(false)
        setPageInstructions(false)
    }
    const submitWorkspaceBuildSpecifications = () => {
        let effectiveDescription = projectDescription
        if (projectDescription.length > 1600) effectiveDescription = projectDescription.substring(0, 1600)
        setPage("generating")
        initializeAccount({
            company_name: companyName,
            company_website: companyWebsite,
            industry: industry,
            project_description: effectiveDescription
        })
        .then((res) => {
            if (!res) return setPage("builderror")
        })
        .catch((err) => {
            return setPage("builderror")
        })
    }
    const updateProposedPlanInterest = () => {
        if (localStorage.getItem("gPlanInterest")) {
            proposeInitialPlan({proposed_plan: localStorage.getItem("gPlanInterest")})
            .then((res) => {
                localStorage.removeItem("gPlanInterest")
            })
            .catch((err) => { return })
        }
    }
    const checkOnboardingStatus = () => {
        try {
            if (page !== "loading") return
            refreshAuthStatus()
            getAccountInitializationStatus()
            .then((res) => {
                if (!res.data) return setPage("introduction")
                if (res.data.completed) {
                    if (res.data.workspace) {
                        setOnboardBlueprint(res.data)
                        setPageTitle(`The ${res.data.workspace.name} workspace is ready!`)
                        if (res.data.project) setPageInstructions("Your project waits for you inside")
                    } else {
                        setPageInstructions(false)
                    }
                    return setPage("confirmation")
                } return setPage("introduction")
            })
            .catch((err) => { return setPage("introduction")})
        } catch (error) {
            return setPage("introduction")
        }
    }
    const continueToWorkspace = () => {
        // Mark the onboarding complete
        completeOnboarding()
        .then((res) => {
            // Add a flag to localStorage to indicate tutorial_mode is active
            localStorage.setItem("gTutorialMode", "yes")
            // Go to new workspace
            attemptWorkspaceNav()
        }).catch((err) => { setPage("builderror")})
    }
    const upgradeToTeamTrialPlan = () => {
        // Mark the onboarding complete
        activateTrial()
        .then((res) => {
            completeOnboarding()
            .then((res) => {
                // Add a flag to localStorage to indicate tutorial_mode is active
                localStorage.setItem("gTutorialMode", "yes")
    
                // Go to new workspace
                attemptWorkspaceNav()
            }).catch((err) => { setPage("builderror")})
        })
        .catch((err) => {
            setPage("builderror")
        })

    }
    // const continueWithSocial = () => {
    //     // Mark the onboarding complete
    //     completeOnboarding()
    //     .then((res) => {
    //         // Add a flag to localStorage to indicate tutorial_mode is active
    //         localStorage.setItem("gTutorialMode", "yes")

    //         // Go to user profile page
    //         if (localStorage.getItem("gId")) return history.push(`/user/${localStorage.getItem("gId")}`)
    //         return history.push("/home")
    //     }).catch((err) => { setPage("builderror")})
    // }
    const handleEnterKeyPress = (e) => {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
            if (page === "introduction") {
                if (companyName === "") return
                return setPage("project")
            }
        }
        return
    }
    const getAdvanceButtonStyle = (dependencyVars) => {
        try {
            // If any are blank, return grey styles
            const invalidStyles = { backgroundColor: "grey", cursor: "default"}
            if (dependencyVars.some(value => value === "")) return invalidStyles
            if (!validateUrl(companyWebsite)) return invalidStyles
            return {}
        } catch (error) {
            return {}
        }
    }
    const handleButtonClick = (proposedPage, dependencyVars) => {
        try {
            if (dependencyVars.some(value => value === "")) return
            if (!validateUrl(companyWebsite)) return
            return setPage(proposedPage)
        } catch (error) {
            return 
        }
    }
    const attemptWorkspaceNav = () => {
        if (onboardBlueprint.workspace._id) {
            if (onboardBlueprint.project) {
                history.push(`/workspaces/${onboardBlueprint.workspace._id}/c/${onboardBlueprint.project._id}`)
            }
        }
    }
    const isSocialOnly = () => {
        try {
            if (onboardBlueprint.plan_name === "social") return true
            if (["startup", "team", "business"].includes(onboardBlueprint.plan_name)) return false
            return true
        } catch (error) {
            return true
        }
    }
    const updateBuildStatus = () => {
        if (page !== "generating") return
        getAccountInitializationStatus()
        .then((res) => {
            if (!res.data) return setPage("builderror")
            if (res.data.completed) {
                if (res.data.workspace) {
                    setOnboardBlueprint(res.data)
                    setPage("confirmation")
                    setPageTitle(`The ${res.data.workspace.name} workspace is ready!`)
                }
                return
            }
        })
        .catch((err) => { return setPage("builderror")})
    }
    useEffect(() => {
        updatePageTitles()
    // eslint-disable-next-line
    }, [page])
    useEffect(() => {
        updateProposedPlanInterest()
        checkOnboardingStatus()
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (page !== "generating") return
        updateBuildStatus();
        const interval = setInterval(() => {
            updateBuildStatus();
        }, 5000);
        return () => clearInterval(interval);
    // eslint-disable-next-line
    }, [page]);
    return (
        <div className="wizard-onboarding-container">
            <SpecialHeader logout={logout} />
            <div className="wizard-onboarding-backdrop"></div>
            <div className="wizard-onboarding-form">
                <h1>{pageTitle}</h1>
                <h3>{pageInstructions}</h3>
                {page === "loading" &&
                <div className="util-row util-col util-align-center" style={{paddingTop: "40px", paddingBottom: "40px"}}>
                    <div className="wizard-onboarding-loader"></div>
                </div>
                }
                {page === "introduction" &&
                <div>
                    <div className="wizard-inputs">
                        <div className="wizard-input-basic">
                            <p>Company Name</p>
                            <input type="text" autoFocus={true} onKeyDown={(e) => handleEnterKeyPress(e)} placeholder="Acme, Inc" value={companyName} onChange={(e) => setCompanyName(e.target.value)} maxLength={140} />
                        </div>
                        <div className="wizard-input-basic">
                            <p>Company Website</p>
                            <input type="text" placeholder="www.acme.com" onKeyDown={(e) => handleEnterKeyPress(e)} value={companyWebsite} onChange={(e) => setCompanyWebsite(e.target.value)} maxLength={140} />
                        </div>
                        <div className="wizard-input-basic">
                            <p>Industry</p>
                            <input type="text" onKeyDown={(e) => handleEnterKeyPress(e)} placeholder="Design, Marketing, Content, Sales, Software, Financial Services, Legal Services, etc..." value={industry} onChange={(e) => setIndustry(e.target.value)} maxLength={140} />
                        </div>
                    </div>
                    <div className="wizard-navigation-bottom">
                        <div className="wizard-navigation-bottom-button-primary" onClick={() => handleButtonClick("project", [companyName, industry])} style={getAdvanceButtonStyle([companyName, industry])}>Continue</div>
                    </div>
                </div>
                }
                {page === "project" &&
                <div>
                    <div className="wizard-inputs">
                        <div className="wizard-input-basic">
                            <textarea maxLength={1600} rows={8} type="text" placeholder="Example: Brand redesign and strategic development for a local tech startup called Acme. The project includes digital review, asset development, and strategic roadmapping." value={projectDescription} onChange={(e) => setProjectDescription(e.target.value)}></textarea>
                        </div>
                    </div>
                    <div className="wizard-navigation-bottom">
                        <div className="wizard-navigation-bottom-button-primary" onClick={submitWorkspaceBuildSpecifications}>Generate My Workspace</div>
                        <div className="wizard-navigation-bottom-button-secondary" onClick={() => setPage("introduction")}>Go back</div>
                    </div>
                </div>
                }

                {page === "generating" && 
                <div className="util-row util-col util-align-center" style={{paddingTop: "40px", paddingBottom: "40px"}}>
                    <div className="wizard-onboarding-loader"></div>
                    
                    <div style={{marginTop: "40px"}}><LoadingScreen/></div>
                </div>
                }

                {page === "confirmation" && 
                <div className="util-row util-col util-align-center" style={{paddingTop: "40px", paddingBottom: "40px", position: "relative"}}>
                    <div style={{position: "absolute", top: 0}}>
                        <Player autoplay loop={true} src={lottieCelebrationFile} style={{height: "120px", width: "120px"}} speed={1}/>
                    </div>
                    
                    <div className="wizard-cta-section">
                        <p>Ready for high-definition alignment?<br/><br/><strong>This is a 7-day trial of Tetheros</strong><br/>You can upgrade your experience at any time.</p>
                    </div>
                    <div className="wizard-navigation-bottom">
                        {!isSocialOnly() && <div className="wizard-navigation-bottom-button-primary" onClick={continueToWorkspace}>Go to workspace</div>}
                        {isSocialOnly() && <div className="wizard-navigation-bottom-button-primary" onClick={upgradeToTeamTrialPlan}>Continue to workspace</div>}
                    </div>
                </div>
                }

            </div>
        </div>
    )
}

function SpecialHeader({logout}) {
    const [profileMenuShowing, setProfileMenuShowing] = useState(() => { return false })
    const profileMenuRef = useRef(null)
    const toggleProfileMenuShowing = () => {
        setProfileMenuShowing(!profileMenuShowing)
    }
    const clickLogout = () => {
        hideProfileMenu()
        logout();
        // history.push("/login");
    }
    const hideProfileMenu = () => {
        setProfileMenuShowing(false)
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) {
                hideProfileMenu()
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [profileMenuRef])
    return (
        <div className="wizard-special-header">
            <div className="wizard-special-header-inner">
                <Logo/>

                <div className="header-right-side-auth-items-inner" ref={profileMenuRef}>

                    <div className="header-profile-name-row" onClick={toggleProfileMenuShowing}>
                        <ProfPicCircle height={40} disableClick={true} src={localStorage.getItem("gProfPicUrl")} userId={localStorage.getItem("gId")} userName={localStorage.getItem("gFullName")} />
                     </div>

                    {profileMenuShowing &&
                    <div className="header-profile-dropdown-container">
                        <div className="header-profile-dropdown-item" onClick={clickLogout}>Log Out</div>
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}

function LoadingScreen() {
    const tips = [
      'What if we collaborated as effectively as we watch, react, share and shop?',
      'The future belongs to teams who leverage technology and social capital to rapidly solve important problems.',
      'So we\'re excited to welcome you to Tetheros',
      'At the moment, we\'re still building your workspace',
      'Workspaces contain all your projects',
      'Projects contain workstreams and goals',
      'Workstreams track all your tasks',
      'Most teams only need one workspace',
      'Which they can use for many projects',
      'Tetheros is a social workspace platform',
      'We believe in the power of community'
    ];
    const [currentTip, setCurrentTip] = useState(0);
    const [fadeIn, setFadeIn] = useState(true);
  
    useEffect(() => {
        const interval = setInterval(() => {
            setFadeIn((prevFadeIn) => !prevFadeIn);
        }, 2500);
  
        const cycleInterval = setInterval(() => {
            setCurrentTip((prevTip) => (prevTip + 1) % tips.length);
        }, 5000);
  
    return () => {
        clearInterval(interval);
        clearInterval(cycleInterval);
    };
    // eslint-disable-next-line
    }, []);
  
    const fadeStyle = {
      opacity: fadeIn ? 1 : 0,
      transition: 'opacity 1s ease-in-out',
    };
  
    return (
        <div style={{ textAlign: "center", height: "44px", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div style={fadeStyle}>
                <span>{tips[currentTip]}</span>
            </div>
      </div>
    );
  };