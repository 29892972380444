import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Api from "../../common/APIUtils"
import ConfirmAction from '../modals/ConfirmAction';
import ComponentMainComments from './ComponentMainComments';
import ComponentMainDescription from './ComponentMainDescription';
import { hasPermission, getPercentageComplete, getMetricDisplayValue, getFormattedDisplayWithUnits } from '../../common/Helpers';
import SimpleListActionMenu from './SimpleListActionMenu';
import { BsBookmarkFill, BsCheck2 } from 'react-icons/bs';
import { ImFolderOpen } from 'react-icons/im'
import { FaRocket } from 'react-icons/fa';
import { HiOutlineArrowNarrowRight, HiOutlineRefresh } from 'react-icons/hi'
import { parseISO, format, isValid, formatDistanceStrict, isAfter, addDays, isBefore, endOfYesterday } from 'date-fns'
import DateRangeValueEditable from './misc/DateRangeValueEditable';
import OwnerSelectorDropdownSmall from '../gadgets/OwnerSelectorDropdownSmall';
import ContextBreadcrumb from '../gadgets/ContextBreadcrumb';
import ComponentBearingBadge from './misc/ComponentBearingBadge';
import CProgressBar from './CProgressBar';
import ModalUpdateKeyResultProgress from '../modals/ModalUpdateKeyResultProgress';
import ModalUpdateKeyResultSettings from '../modals/ModalUpdateKeyResultSettings';
import WorkspaceCompHashtags from '../gadgets/WorkspaceCompHashtags';

export default function WorkspaceCompKResult({component, permissions, team, componentData, fetchComponent, workspaceName, activeComponentId}) {
    let history = useHistory();
    const api = new Api();
    const titleLastUpdatedTimestamp = useRef(false);
    const [alignmentsLoaded, setAlignmentsLoaded] = useState(() => { return false })
    const [isShowingConfirmMenu, setIsShowingConfirmMenu] = useState(() => { return false })
    const [isShowingUpdateKeyResultProgressModal, setIsShowingUpdateKeyResultProgressModal] = useState(() => { return false })
    const [isShowingUpdateKeyResultSettingsModal, setIsShowingUpdateKeyResultSettingsModal] = useState(() => { return false })
    const [displayName, setDisplayName] = useState(() => { return component.display_name ? component.display_name : ""})
    const [proposedOwnerId, setProposedOwnerId] = useState(() => { return component.owner_id ? component.owner_id : "" })
    const [proposedDueDate, setProposedDueDate] = useState(() => { return component.attributes.date_due ? component.attributes.date_due : "" })
    const [confirmMenuContent, setConfirmMenuContent] = useState(() => { return "" })
    const [confirmMenuAction, setConfirmMenuAction] = useState(() => { return false })
    const [comments, setComments] = useState(() => { return [] })
    const [alignedComponentIds, setAlignedComponentIds] = useState(() => { return [] })
    const [keyResultId, setKeyResultId] = useState(() => { return false })
    const [tags, setTags] = useState(() => { return [] })
    const updateKeyResult = (payload) => {
        if (!component) return
        const path = `/workspace/components/${component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const hideKeyResultProgressUpdateModal = () => {
        setIsShowingUpdateKeyResultProgressModal(false)
        setIsShowingUpdateKeyResultSettingsModal(false)
    }
    const getDisplayNamePlaceholder = () => {
        if (!component) return "Enter a task name"
        if (!component.display_name) return "Enter a task name"
        return component.display_name
    }
    const updateDisplayName = () => {
        if (!component) return
        if (!component.display_name) return
        if (displayName === "" | displayName === component.display_name) return
        const payload = {
            display_name: displayName
        }
        const path = `/workspace/components/${component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const taskTitleFieldHandler = (event) => {
        if (["Enter", "NumpadEnter"].includes(event.code)) {
            event.preventDefault()
            event.target.blur()
        }
    }
    const updateDescription = (newDescription) => {
        updateKeyResult({
            attributes: {
                description: newDescription
            }
        })
    }
    const updateInitiativeOwner = () => {
        if (proposedOwnerId !== component.owner_id) {
            updateKeyResult({
                owner_id: proposedOwnerId
            })
        }
    }
    const isAKpiBasedKeyResult = () => {
        if (!component) return false
        if (!component.attributes) return false
        if (!component.attributes.type) return false
        if (component.attributes.type === 'kpi') return true
        return false
    }
    const additionalSettingsStyle = () => {
        if (!isAKpiBasedKeyResult()) return {}
        return { cursor: "pointer" }
    }
    const handleClickingOnSettings = () => {
        if (component.status === 'completed' || !isAKpiBasedKeyResult()) return
        setIsShowingUpdateKeyResultSettingsModal(true)
    }
    const hideConfirmPopup = () => {
        setIsShowingConfirmMenu(false)
    }
    const archiveInitiative = () => {
        hideConfirmPopup()
        const payload = {
            status: 'archived'
        };
        const path = `/workspace/components/${componentData.component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const fetchFullAlignmentInformation = async () => {
        if (!component) return
        if (!component._id) return
        api.getComponentAlignments({componentId: component._id})
        .then((res) => {
            if (res.data) {
                if (res.data.response) {
                    if (res.data.response.status === 'successful') setAlignedComponentIds(res.data.response.alignments)
                    setAlignmentsLoaded(true)
                }
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }
    const restoreInitiative = () => {
        hideConfirmPopup()
        const payload = {
            status: 'active',
            attributes: {
                bearing: 'On Track',
                bearing_color_background: "#dce7f7",
                bearing_color_text: "#4170a5"
            }
        };
        const path = `/workspace/components/${componentData.component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const deleteInitiative = () => {
        hideConfirmPopup()
        const payload = {
            componentId: componentData.component._id
        }
        const workspaceId = componentData.component.workspace_id;

        api.deleteWorkspaceComponent(payload)
        .then((res) => {
            console.log(res);
            history.push(`/workspaces/${workspaceId}`);
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const completeInitiative = () => {
        hideConfirmPopup()
        const payload = {
            status: 'completed',
            progress: 1,
            attributes: {
                bearing: 'Completed',
                bearing_color_background: "#dcf7e3",
                bearing_color_text: "#41a55c"
            }
        };
        const path = `/workspace/components/${componentData.component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const goToKpiPage = () => {
        const kpi = getAlignedKpi()
        if (kpi) return history.push(`/workspaces/${kpi.workspace_id}/c/${kpi._id}`)
        return false
    }
    const askForArchiveConfirmation = () => {
        setConfirmMenuContent("Archive project?  Project will be hidden in the sidebar and only shown in relevant locations.  You can restore it later.")
        setConfirmMenuAction(() => archiveInitiative)
        setIsShowingConfirmMenu(true)
    }
    const askForCompleteConfirmation = () => {
        setConfirmMenuContent("Is this Key Result complete?")
        setConfirmMenuAction(() => completeInitiative)
        setIsShowingConfirmMenu(true)
    }
    const askForReactivateConfirmation = () => {
        setConfirmMenuContent("Reactivate project?")
        setConfirmMenuAction(() => restoreInitiative)
        setIsShowingConfirmMenu(true)
    }
    const askForDeleteConfirmation = () => {
        setConfirmMenuContent("Are you sure you want to permanently delete this project?  All markers, alignments, and posts will be deleted with it.")
        setConfirmMenuAction(() => deleteInitiative)
        setIsShowingConfirmMenu(true)
    }
    const getActionList = () => {
        let actions = [];
        if (hasPermission(permissions, 'component_delete')) {
            actions = actions.concat([
                {
                    text: 'Delete',
                    action: askForDeleteConfirmation,
                    id: 'delete'
                }
            ])
        }
        if (component.status === 'active' && hasPermission(permissions, 'component_update')) {
            actions = [
                        {
                            text: 'Archive',
                            action: askForArchiveConfirmation,
                            id: 'archive'
                        }
                    ].concat(actions)
        }
        if (component.status !== 'active' && hasPermission(permissions, 'component_update')) {
            actions = [
                {
                    text: 'Reactivate',
                    action: askForReactivateConfirmation,
                    id: 'reactivate'
                }
            ].concat(actions)
        }
        return actions
    }
    const updateStartDate = (d) => {
        return
    }
    const updateEndDate = (d) => {
        setProposedDueDate(format(d, 'yyyy-MM-dd'))
    }
    const canEditDates = () => {
        if (!hasPermission(permissions, 'component_update')) return false
        if (!component) return false
        if (!component.attributes) return false
        return true;
    }
    const getMinimumEndDate = () => {
        return addDays(new Date(), 1)
    }
    const getCreationDate = () => {
        if (!component) return ""
        if (!component.createdAt) return ""
        if (!isValid(parseISO(component.createdAt))) return ""
        return format(parseISO(component.createdAt), 'PP')
    }
    const isManualKeyResult = () => {
        if (!component) return false
        if (!component.attributes) return false
        if (!component.attributes.type) return false
        if (component.attributes.type === 'manual') return true
        return false
    }
    const getCreationDateRaw = () => {
        if (!component) return false
        if (!component.createdAt) return false
        if (!isValid(parseISO(component.createdAt))) return false
        return parseISO(component.createdAt)
    }
    const isReadyToComplete = () => {
        if (!component) return false
        if (component.status !== 'active') return false
        if (component.progress >= 1) return true
        if (!component.attributes) return false
        if (!component.attributes.date_end) return false
        if (!isValid(parseISO(component.attributes.date_end))) return false
        if (isBefore(parseISO(component.attributes.date_end), endOfYesterday())) return true
        return false
    }
    const isComplete = () => {
        if (!component) return false
        if (!component.status) return false
        if (component.status === 'completed') return true
        return false
    }
    const getRemainingDayRawNumber = () => {
        if (isComplete()) return 0
        const currentEndDate = parseISO(proposedDueDate)
        if (!isAfter(currentEndDate, new Date())) return 0
        return Math.ceil(Math.abs((new Date() - currentEndDate) / (24*60*60*1000)))
    }
    const calculatePercentageOfTimePassedAsString = () => {
        const remainingDays = getRemainingDayRawNumber()
        if (remainingDays === 0) return "100"
        if (!getCreationDateRaw()) return "100"
        const currentStartDate = getCreationDateRaw()
        const currentEndDate = parseISO(proposedDueDate)
        const calculatedPercentage = Math.floor((new Date() - currentStartDate)/(currentEndDate - currentStartDate)*100)
        if (calculatedPercentage < 5) return "0"
        return calculatedPercentage.toString()
    }
    const getRemainingDays = () => {
        if (isComplete()) return "0 days"
        const currentEndDate = parseISO(proposedDueDate)
        if (isAfter(currentEndDate, new Date())) return formatDistanceStrict(currentEndDate, new Date(), { roundingMethod: "ceil"})
        return "0 days"
    }
    const getAlignedKpi = () => {
        if (!alignmentsLoaded) return false
        let bondedKpi = alignedComponentIds.find(a => a.name === 'kpi')
        if (!bondedKpi) return false
        return bondedKpi
    }
    const getFormattedValueJourney = () => {
        if (!component) return
        if (!component.attributes) return
        if (component.attributes.type === 'kpi') {
            let units = false
            const alignedKpi = getAlignedKpi()
            if (alignedKpi.attributes) units = alignedKpi.attributes.units
            return (
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "6px"}}>
                    <span>{getFormattedDisplayWithUnits(units, component.attributes.val_start)}</span>
                    <HiOutlineArrowNarrowRight/>
                    <span>{getFormattedDisplayWithUnits(units, component.attributes.val_target)}</span>
                </div>
            )
        }
        if (component.attributes.type === 'manual') return (
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "6px"}}>
                <span>0%</span>
                <HiOutlineArrowNarrowRight/>
                <span>100%</span>
            </div>
        )
    }
    const getKpiName = () => {
        const bondedKpi = getAlignedKpi()
        if (!bondedKpi) return false
        return bondedKpi.display_name
    }
    const getComponentBearingInformation = () => {
        let bearingInfo = {
            text: "Active",
            color: false,
            background: false
        }
        try {
            if (component.attributes.bearing) bearingInfo['text'] = component.attributes.bearing
            if (component.attributes.bearing_color_text) bearingInfo['color'] = component.attributes.bearing_color_text
            if (component.attributes.bearing_color_background) bearingInfo['background'] = component.attributes.bearing_color_background
            return bearingInfo
        } catch (error) {
            return bearingInfo
        }
    }
    const getProgressBarReplacementText = () => {
        if (!getAlignedKpi()) return false
        return getMetricDisplayValue(getAlignedKpi())
    }
    const getExtraStyles = () => {
        try {
            const displayValueString = String(getProgressBarReplacementText())
            if (displayValueString.length > 14) return { fontSize: "16px", fontWeight: "700" }
            if (displayValueString.length > 12) return { fontSize: "18px", fontWeight: "700" }
            if (displayValueString.length > 9) return { fontSize: "20px", fontWeight: "700" }
            return { fontSize: "28px", fontWeight: "700" }
        } catch (error) {
            return {fontSize: "28px", fontWeight: "700"}
        }
    }
    useEffect(() => {
        if (!componentData) return
        if (!componentData.component) return
        if (!componentData.component._id) return
        setKeyResultId(componentData.component._id)
        setComments(componentData.component.comments)
    // eslint-disable-next-line
    }, [componentData])
    useEffect(() => {
        updateInitiativeOwner()
    // eslint-disable-next-line
    }, [proposedOwnerId])
    useEffect(() => {
        titleLastUpdatedTimestamp.current = Date.now()
        const timer = setTimeout(() => {
            if (Date.now() - titleLastUpdatedTimestamp.current < 900) return
            updateDisplayName()
        }, 1000)
        return () => clearTimeout(timer)
    // eslint-disable-next-line
    }, [displayName])
    useEffect(() => {
        let payload = {}
        if (!isValid(parseISO(proposedDueDate))) return
        payload['attributes'] = {date_due: proposedDueDate}
        updateKeyResult(payload)
    // eslint-disable-next-line
    }, [proposedDueDate])
    useEffect(() => {
        if (!keyResultId) return
        if (keyResultId !== activeComponentId) return // don't do anything if these don't match
        setDisplayName(component.display_name ? component.display_name : "")
        setAlignmentsLoaded(false)
        setProposedOwnerId(component.owner_id ? component.owner_id : "")
        setProposedDueDate(component.attributes.date_due ? component.attributes.date_due : "")
        fetchFullAlignmentInformation()
    // eslint-disable-next-line
    }, [keyResultId])
    useEffect(() => {
        if (!componentData) return
        if (!componentData.component) return
        if (!componentData.component.component_tags) {
            setTags([])
            return
        }
        setTags(componentData.component.component_tags)
    }, [componentData])
    return (
        <div className="workcomp-page-container workcomp-page-container-dark">
            {/* Modals */}
            {isShowingConfirmMenu && <ConfirmAction mainText={confirmMenuContent} onconfirm={confirmMenuAction} hideMe={hideConfirmPopup} />}
            {isShowingUpdateKeyResultProgressModal && <ModalUpdateKeyResultProgress keyResultComponent={component} updateComponent={updateKeyResult} hideModal={hideKeyResultProgressUpdateModal}/> }
            {isShowingUpdateKeyResultSettingsModal && <ModalUpdateKeyResultSettings kpiComponent={getAlignedKpi()} keyResultComponent={component} updateComponent={updateKeyResult} hideModal={hideKeyResultProgressUpdateModal}/> }
            <div className="component-page-outer" style={{marginTop: "0.4em"}}>
                <div className="component-page-row" style={{alignItems: "center", justifyContent: "space-between", marginTop: "0.4em"}}>
                    <div className="component-page-row" style={{alignItems: "center", justifyContent: "flex-start", gap: "2em"}}>
                        <div>
                            <ContextBreadcrumb permissions={permissions} component={component} componentData={componentData} workspaceName={workspaceName} />
                        </div>
                    </div>
                    <div>
                        {hasPermission(permissions, "component_update") && <SimpleListActionMenu actionsList={getActionList()} symbol="gear"/>}
                    </div>
                </div>

                <div className="component-page-title component-page-row" style={{alignItems: "center", gap: "0.5em"}}>
                    {hasPermission(permissions, 'component_update') ? (
                        <input type="text" onKeyUp={(e) => taskTitleFieldHandler(e)} className="workcomp-page-task-title-editable workcomp-page-task-title-editable-initiative" placeholder={getDisplayNamePlaceholder()} value={displayName} onChange={(e) => setDisplayName(e.target.value)} />
                    ) : (
                        <div className="workcomp-page-task-title-editable workcomp-page-task-title-editable-initiative" style={{border: "2px solid transparent"}}>{displayName}</div>
                    )}
                </div>
                <WorkspaceCompHashtags tags={tags} workspaceId={component.workspace_id}/>


                <div className="workcomp-page-main-display-split-container">
                    <div className="workcomp-page-main-display-split-primary">
                        <div className="workcomp-page-main-display-split-width-small">                        
                            <div className="component-page-initiative-summary-section-container">
                                <div className="component-page-initiative-summary-item-description-container">
                                    <ComponentMainDescription permissions={permissions} component={component} updateDetails={updateDescription} fontSize="16" team={team}/>
                                </div>
                            </div>

                            <div className="component-page-kresult-section-centered-column">
                                <ComponentBearingBadge permissions={permissions} text={getComponentBearingInformation().text} color={getComponentBearingInformation().color} background={getComponentBearingInformation().background} updateComponent={updateKeyResult}/>

                                <div className="component-page-kresult-section-progress">
                                    <div className="component-page-kresult-val-subtitles" style={additionalSettingsStyle()} onClick={handleClickingOnSettings}>{getFormattedValueJourney()}</div>
                                </div>
                                <CProgressBar replacementStrokeWidth="8px" percentage={getPercentageComplete(component)} height={180} replacementText={getProgressBarReplacementText()} extraStyles={getExtraStyles()}/> 
                                {getKpiName() && <div className="component-page-kresult-kpi-name" onClick={goToKpiPage}>{getKpiName()}</div>}
                                {getKpiName() && <div className="component-page-kresult-kpi-subtitle">Progress updates automatically</div> }
                                {(hasPermission(permissions, 'component_update') && isManualKeyResult()) && <div className="component-page-kresult-kpi-update-button" onClick={() => setIsShowingUpdateKeyResultProgressModal(true)}><HiOutlineRefresh/>Update Progress</div>}

                            </div>

                            <div className="component-page-initiative-alignments-section-container" style={{marginTop: "48px"}}>
                                <div className="component-page-initiative-section-heading">Discussion</div>
                                <ComponentMainComments team={team} fetchComponent={fetchComponent} comments={comments} componentId={keyResultId}/>
                            </div>
                        </div>
                    </div>
                    <div className="workcomp-page-main-display-split-sidebar">
                        <div className="workspace-panel-settings-section">
                            <div className="workspace-panel-setting workspace-panel-setting-mini workspace-panel-setting-borders" style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                                <div className="workspace-panel-settings-section-heading">Progress Overview</div>

                                {/* Status - percentage */}
                                <div className="component-page-initiative-prog-overview-subtitle">Status</div>
                                <div className="component-page-initiative-prog-overview-dates-container">
                                    <div className="component-page-initiative-prog-overview-dates-progress-bar" style={{width: `${getPercentageComplete(component)}%`}}></div>
                                    <div style={{zIndex: "4", color: "white"}}>{Math.round(getPercentageComplete(component))}% complete</div>
                                </div>

                                {/* Timeline */}
                                <div className="component-page-initiative-prog-overview-subtitle">Timeline</div>
                                <div className="component-page-initiative-prog-overview-subtitle-description">{getRemainingDays()} remaining</div>
                                <div className="component-page-initiative-prog-overview-dates-container">
                                    <div className="component-page-initiative-prog-overview-dates-progress-bar" style={{width: `${calculatePercentageOfTimePassedAsString()}%`}}></div>
                                    <DateRangeValueEditable editable={false} maxDate={addDays(parseISO(proposedDueDate), -1)} date={getCreationDateRaw()} label="" onupdate={updateStartDate}/>
                                    <DateRangeValueEditable editable={canEditDates()} minDate={getMinimumEndDate()} date={parseISO(proposedDueDate)} label="" onupdate={updateEndDate}/>
                                </div>

                                
                            </div>
                            <div className="workspace-panel-setting workspace-panel-setting-mini workspace-panel-setting-borders">
                                <div className="workspace-panel-settings-section-heading">About</div>

                                <div className="workspace-panel-settings-status-items">
                                    <div className="workspace-panel-setting-iconography-and-text">
                                        <ImFolderOpen/>
                                        <div>Key Result</div>
                                    </div>
                                    <div className="workspace-panel-setting-iconography-and-text">
                                        <BsBookmarkFill/>
                                        <div>Created {getCreationDate()}</div>
                                    </div>
                                    {isComplete() && (
                                    <div className="workspace-panel-setting-iconography-and-text">
                                        <BsCheck2 style={{color: "green"}} />
                                        <div>Completed {format(parseISO(component.completed), 'PP')}</div>
                                    </div>
                                    )}
                                    {isReadyToComplete() && (
                                        <div className="workspace-panel-setting-action-launch" style={{backgroundColor: "green", width: "100%"}} onClick={askForCompleteConfirmation}>
                                            <FaRocket/> Finalize Completion
                                        </div>
                                    )}
                                </div>
                                
                            </div>
                        </div>
                        <div className="workspace-panel-setting workspace-panel-setting-mini workspace-panel-setting-borders" style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                            <div className="workspace-panel-settings-section-heading">Owner</div>
                            <div style={{width: "100%"}}>
                                <OwnerSelectorDropdownSmall permissions={permissions} proposedOwnerId={proposedOwnerId} team={team} setProposedOwnerId={setProposedOwnerId}/>
                            </div>
                        </div>
                    </div>
                </div> 
                

            </div>
        </div>
    )
}
