import React from 'react'
import ModalSimpleHeader from './simple/ModalSimpleHeader'

export default function ModalCreateNewTaskMegabarMultiple({hideModal, proposedTaskList, createMultipleTasks}) {
    return (
        <div className="modal-mgmt-background-overlay">
            <div className="modal-mgmt-container">
                <ModalSimpleHeader title={`Do you want to create ${proposedTaskList.length} tasks?`} hideModal={hideModal} />
                <div className="modal-mgmt-sections" style={{display: "flex", flexDirection: "column", gap: "14px"}}>
                    <p style={{margin: 0, fontSize: "22px", fontWeight: 400, lineHeight: "32px"}}>The following items will be added:</p>
                    <div style={{maxHeight: "300px", overflowY: "auto", display: "flex", flexDirection: "column", gap: "6px"}}>
                        {proposedTaskList.map((task) => (
                            <div style={{marginLeft: "40px", whiteSpace: "pre-wrap", wordBreak: "break-word"}} key={task}>{task}</div>
                        ))}
                    </div>
                    <div className="modal-task-create-submission-section" style={{alignItems: "center", flexDirection: "row", gap: "20px"}}>
                        <div className="modal-task-create-button-create" onClick={createMultipleTasks}>Create {proposedTaskList.length} Tasks</div>
                        <div className="modal-task-create-button-create-save-and-next" onClick={hideModal}>No thanks</div>
                    </div>  
                </div>
            </div>
        </div>
    )
}
