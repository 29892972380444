import React, { useState, useEffect, useReducer, useRef } from 'react'

import LexicalTextEditor from '../gadgets/LexicalTextEditor';
import BlurProofInput from '../gadgets/BlurProofInput';
import ConfirmAction from '../modals/ConfirmAction'
import LoadingSymbolInline from '../gadgets/LoadingSymbolInline'

import { IoAddSharp, IoCreateOutline } from "react-icons/io5";
import { FaTrash } from "react-icons/fa6";


import { createUserNote, updateUserNote, deleteUserNote } from '../../common/Api';

export default function UserNotebook({ notes, fetchNotes, isLoading, isError }) {
    const [editorKey, setEditorKey] = useState(() => { return 0 });
    const [isAttemptingDelete, setIsAttemptingDelete] = useState(() => { return false });
    const [isSaving, setIsSaving] = useState(() => { return false });
    const [selectedNote, setSelectedNote] = useState(() => { return null });
    const [noteTitle, setNoteTitle] = useState(() => { return "New Note" });
    const saveTimeoutRef = useRef(null);
    const titleInputRef = useRef(null)
    const [activeUserNote, activeUserNoteDispatch] = useReducer((state, action) => {
        if (action.type === "save") {
            const safeHtml = action.value.description_html
            const editorState = action.value.description_editor
            return { ...state, description_editor: editorState, description_html: safeHtml }
        } 
        if (action.type === "change_notes") {
            const safeHtml = action.description_html
            const editorState = action.description_editor
            return { description_editor: editorState, description_html: safeHtml }
        }
        if (action.type === "reset") {
            console.log('resetting')
            return { description_editor: false, description_html: false }
        }
        return state
      }, { description_editor: false, description_html: false })
    const addNote = () => {
        const newNote = {
            note_title: 'New Note',
        };
        setNoteTitle("New note")
        setSelectedNote(newNote);
        activeUserNoteDispatch({ type: "reset" })
        setEditorKey(prevKey => prevKey + 1)
    }
    
    const noteHasBeenModified = (noteId) => {
        try {
            const copyOfNotes = Array.from(notes)
            const exNote = copyOfNotes.find((n) => n._id === noteId)
            if (exNote.note_title !== noteTitle) return true
            const currentJson = JSON.stringify(activeUserNote)
            if (currentJson === exNote.rich_text_content) return false
            return true
        } catch (error) {
            return false
        }
    }
    const saveNote = () => {
        if (!selectedNote) return
        // Is a new note - create
        if (!selectedNote._id) {
            if (!activeUserNote.description_html) return
            setIsSaving(true)
            createUserNote({ rich_text_content: JSON.stringify(activeUserNote), note_title: noteTitle })
            .then((res) => {
                if (res.data) {
                    if (res.data.note) {
                        setSelectedNote(res.data.note)
                        setNoteTitle(res.data.note.note_title)
                        fetchNotes()
                        setIsSaving(false)
                    }
                }
            })
            return
        }
        // Check if any modifications have been made
        if (!noteHasBeenModified(selectedNote._id)) return
        setIsSaving(true)
        updateUserNote({note_id: selectedNote._id, rich_text_content: JSON.stringify(activeUserNote), note_title: noteTitle })
        .then((res) => {
            fetchNotes()
            setIsSaving(false)
            return
        })
        .catch((err) => { return setIsSaving(false) })
    }
    const deleteSelectedNote = () => {
        if (!selectedNote._id) return setIsAttemptingDelete(false)
        deleteUserNote(selectedNote._id)
        .then((res) => {
            fetchNotes()
        })
        setIsAttemptingDelete(false)
    }
    const reviewSelectedNote = () => {
        // Checks if the selected note needs to remain or be initialized
        try {
            if (!notes) return setSelectedNote(null)
            if (notes.length > 0 && !isLoading && !selectedNote) return updateSelectedNote(notes[0])
            // Check if note is even valid anymore
            if (selectedNote) {
                if (selectedNote._id) {
                    const selectedNoteId = selectedNote._id
                    const allNoteIds = notes.map(n => n._id)
                    if (!allNoteIds.includes(selectedNoteId)) {
                        return updateSelectedNote(notes[0])                        
                    }
                }
            }
        } catch (error) {
            return
        }
    }
    const updateSelectedNote = (note) => {
        // Save existing note if changing

        // Change note
        setNoteTitle(note.note_title)
        const richTextJson = JSON.parse(note.rich_text_content)
        let descriptionHtml = false
        let descriptionEditor = false
        if (richTextJson.description_html) descriptionHtml = richTextJson.description_html
        if (richTextJson.description_editor) descriptionEditor = richTextJson.description_editor
        activeUserNoteDispatch({type: "change_notes", description_html: descriptionHtml, description_editor: descriptionEditor })
        setSelectedNote(note)
        setEditorKey(prevKey => prevKey + 1);
    }
    const getInitialState = () => {
        if (!selectedNote || !activeUserNote.description_editor) return undefined;
        return activeUserNote.description_editor;
    };
    useEffect(() => {
        reviewSelectedNote()
    // eslint-disable-next-line
    }, [notes])
    useEffect(() => {
        if (activeUserNote) {
          if (saveTimeoutRef.current) {
            clearTimeout(saveTimeoutRef.current);
          }
          saveTimeoutRef.current = setTimeout(() => {
            saveNote();
          }, 1500);
        }
    
        return () => {
          if (saveTimeoutRef.current) {
            clearTimeout(saveTimeoutRef.current);
          }
        };
    // eslint-disable-next-line
    }, [activeUserNote, noteTitle]);
    return (
        <div className="user-notes-container">
            {isAttemptingDelete && <ConfirmAction hideMe={() => setIsAttemptingDelete(false)} onconfirm={deleteSelectedNote} title="Delete Note?" mainText="Are you sure you want to delete this note? This cannot be undone." /> }

            {/* NAVIGATION PANEL */}
            <div className="user-notes-navigation app-thin-scrollbar">
                <h4>Notes</h4>
                {!isLoading &&
                <div>
                    <div className="user-notes-add-button" title="Create a note" onClick={addNote}><IoCreateOutline/></div>
                    <div className="user-notes-navigation-notes">
                    {notes.map((note) => (
                        <div key={note._id} title={note.note_title} className={`user-notes-note ${selectedNote && selectedNote._id === note._id ? 'user-notes-selected' : ''}`} onClick={() => updateSelectedNote(note)}>{note.note_title}</div>
                    ))}
                    </div>
                    {notes.length === 0 && <span className="user-notes-navigation-empty-add" onClick={addNote}><IoAddSharp/>Add new note</span> }
                </div>
                }
                {isLoading && 
                <div className="util-row util-col" style={{width: "100%", gap: "32px"}}>
                    <LoadingSymbolInline />
                    <LoadingSymbolInline />
                    <LoadingSymbolInline />
                    <LoadingSymbolInline />
                </div>
                }
            </div>

            {/* DISPLAY PANEL */}
            <div className="user-notes-display-panel">

                {selectedNote && !isError && !isLoading &&
                <div>
                    <div className="user-notes-display-controls">
                        <div className="user-notes-action-remove" onClick={() => setIsAttemptingDelete(true)}><FaTrash/></div>
                        {isSaving && <div style={{fontSize: "14px", color: "grey", fontStyle: "italic"}}>Saving note...</div>}
                    </div>
                    <BlurProofInput inputRef={titleInputRef}>
                        <input type="text" value={noteTitle} onChange={(e) => setNoteTitle(e.target.value)} className="user-notes-title-input" ref={titleInputRef} maxLength={84} />
                    </BlurProofInput>
                    <div style={{position: "relative"}}>
                        <LexicalTextEditor key={editorKey} handleMultimediaPasteAction={false} team={false} isUpgrade={false} getInitialState={getInitialState} mainDescriptionDispatch={activeUserNoteDispatch} legacyDescription={false} />
                    </div>
                    
                </div>
                }

                {notes.length === 0 && !selectedNote && !isLoading && <p className="user-notes-empty-text">Use My Desk to store notes, meeting minutes, or anything else you find important</p>}

                {isLoading && !isError &&
                <div style={{width: "100%", padding: "80px", boxSizing: "border-box"}}>
                    <LoadingSymbolInline/>
                </div>
                }

                {isError &&
                <div style={{width: "100%", padding: "80px", boxSizing: "border-box"}}>
                    <p className="user-notes-empty-text">Something went wrong fetching your notes. Please try again later.</p>
                </div>
                }


            </div>
        </div>
    );
};