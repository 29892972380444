import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import GenericToolHeader from './GenericToolHeader';
import Api from '../../common/APIUtils';
import { Calendar } from 'react-date-range'
import { FaTools } from "react-icons/fa";
import { MdMail } from "react-icons/md";
import { format, isValid, isAfter, parseISO } from 'date-fns'
import { isValidEmailAddress, isValidBusinessEmailAddress, updateSchemaOrgJSONLD, updateHeadTags } from '../../common/Helpers';
import { ReactComponent as TetherosSmallLogo } from '../../static/assets/logov1-basicblue.svg';

import ModalToolSingleSelectRoleDropdownWithSearch from '../modals/tools/ModalToolSingleSelectRoleDropdownWithSearch'
import ModalToolMultiSelectWorkspaceDropdownWithSearch from '../modals/tools/ModalToolMultiSelectWorkspaceDropdownWithSearch'
import SimpleLoadingPage from '../gadgets/SimpleLoadingPage'

export default function PromotionCalendarWizard({ authenticated }) {
    const history = useHistory()
    const [emailAddress, setEmailAddress] = useState(() => { return "" })
    const [eventType, setEventType] = useState(() => { return false })
    const [eventLength, setEventLength] = useState(() => { return 1 })
    const [emailAddressFeedback, setEmailAddressFeedback] = useState(() => { return "" })
    const [teamSize, setTeamSize] = useState(() => { return false })
    const [isBuilding, setIsBuilding] = useState(() => { return false })
    const [isLoading, setIsLoading] = useState(() => { return true })
    const [requirements, setRequirements] = useState(() => { return "" })
    const [guests, setGuests] = useState(() => { return false })
    const [budget, setBudget] = useState(() => { return false })
    const [calendarFriendlyDate, setCalendarFriendlyDate] = useState(() => { return new Date() })
    const [responsiveEventDate, setResponsiveEventDate] = useState(() => { return format(new Date(), "yyyy-MM-dd") })
    const [page, setPage] = useState(() => { return 0 })
    const [submitted, setSubmitted] = useState(() => { return false})
    const [title, setTitle] = useState(() => { return ""})
    const [selectedTeamIds, setSelectedTeamIds] = useState(() => { return [] })
    const getRadioStyle = (clientVal) => {
        if (clientVal === eventLength) return { backgroundColor: "#2A73AE" }
        return {}
    }
    const api = new Api();
    const triggerNewBuild = () => {
        setSubmitted(true)
        localStorage.setItem("tlm1", emailAddress)
        let formattedDate = format(calendarFriendlyDate, 'yyyy-MM-dd')
        let payload = {
            title: title.slice(0, 120),
            type: eventType,
            event_length: eventLength,
            date_start: formattedDate,
            attendance: guests,
            team_size: teamSize,
            marketing_channels: [],
            budget: budget,
            special_requirements: requirements.slice(0, 1000),
            email_address: emailAddress
        }
        api.createEventPlanMagnet(payload)
        .then((res) => {
            if (res.data) {
                if (res.data.status === "complete") {
                    return history.push(`/t/${res.data.planner._id}`)
                }
            }
        })
        .catch((err) => {
            console.error(err);
        });
    };
    const handleDateChange = (d) => {
        setCalendarFriendlyDate(d)
    }
    const handleResponsiveDateChange = (d) => {
        setResponsiveEventDate(d)
        handleDateChange(parseISO(d))
    }
    const addTeamToSelected = (teamId) => {
        if (selectedTeamIds.includes(teamId)) return
        let updatedTeamIds = Array.from(selectedTeamIds)
        updatedTeamIds.push(teamId)
        setSelectedTeamIds(updatedTeamIds)
    }
    const removeTeamFromSelected = (teamId) => {
        if (!selectedTeamIds.includes(teamId)) return
        let updatedTeamIds = Array.from(selectedTeamIds)
        const index = updatedTeamIds.indexOf(teamId)
        if (index < 0) return
        updatedTeamIds.splice(index, 1)
        setSelectedTeamIds(updatedTeamIds)
    }
    const isValidToSubmit = () => {
        try {
            if (!title) return false
            if (title.trim() === "") return false
            if (!eventType || !teamSize || !guests || !budget || !calendarFriendlyDate) return false
            if (!isValid(calendarFriendlyDate)) return false
            if (!isAfter(calendarFriendlyDate, new Date())) return false
            if (page === 2 && !isValidEmailAddress(emailAddress)) return false
            if (page === 2 && !isValidBusinessEmailAddress(emailAddress) && isValidEmailAddress(emailAddress)) return false
            return true
        } catch (error) {
            return false
        }
    }
    const getAdditionalSubmitStyle = () => {
        if (isValidToSubmit()) return {}
        return { backgroundColor: "grey" }
    }
    const updateEmailAddressFeedback = () => {
        try {
            setEmailAddressFeedback("")
            if (!isValidEmailAddress(emailAddress)) return
            if (!isValidBusinessEmailAddress(emailAddress) && isValidEmailAddress(emailAddress)) return setEmailAddressFeedback("This tool requires a business email address")
        } catch (error) {
            return
        }
    }
    const attemptPageTurn = () => {
        // Validate
        if (page === 1) {
            if (isValidToSubmit()) {
                setPage(2)
            }
        }
    }
    const reviewEntitlements = () => {
        try {
            const leadMagnetStatus = localStorage.getItem("tlm1")
            if (leadMagnetStatus === null) return setIsLoading(false)
            api.checkForLeadMagnet({type: "event_plan", email: leadMagnetStatus})
            .then((res) => {
                console.log(res)
                if (res.data.exists) {
                    if (res.data.magnet.status === "active") history.push(`/t/${res.data.magnet._id}`)
                    if (res.data.magnet.status === "error") return setIsLoading(false)
                    if (res.data.magnet.status === "building") return setIsBuilding(true)
                } else {
                    return setIsLoading(false)
                }
            })
            .catch((err) => {
                return
            })
        } catch (error) {
            return
        }
    }
    const eventTypes = [
        { display_name: "Conference", _id: "conference" },
        { display_name: "Trade Show", _id: "trade_show" },
        { display_name: "Product Launch", _id: "product_launch" },
        { display_name: "Seminar/Workshop", _id: "seminar" },
        { display_name: "Sales Meeting/Summit", _id: "sales meeting" },
        { display_name: "User Conference", _id: "user conference" },
        { display_name: "Gala/Fundraiser", _id: "gala" },
        { display_name: "Ceremony/Award Show", _id: "ceremony or award show" },
        { display_name: "Professional Association Monthly Program", _id: "professional association monthly program" },
        { display_name: "Meetup/Networking Event", _id: "meetup" },
        { display_name: "Team-Building Event", _id: "team building" },
        { display_name: "Corporate Retreat", _id: "corporate retreat" },
        { display_name: "Career Fair", _id: "career fair" },
        { display_name: "Roadshow", _id: "roadshow" },
        { display_name: "Incentive Trip", _id: "incentive trip" },
        { display_name: "Client Appreciation Event", _id: "client appreciation" },
        { display_name: "Investor Relations Event", _id: "investor relations" },
        { display_name: "Media Event", _id: "media event" },
        { display_name: "Grand Opening", _id: "grand opening" },
        { display_name: "Anniversary Celebration", _id: "anniversary" },
        { display_name: "Holiday Party", _id: "holiday party" }
    ]
    const budgetRanges = [
        { display_name: "Limited (< $5,000)", _id: "$0 - $5,000" },
        { display_name: "Small ($5,000 - $20,000)", _id: "$5,000 - $20,000"},
        { display_name: "Moderate ($20,000 - $50,000)", _id: "$20,000 - $50,000"},
        { display_name: "Large ($50k - $100k)", _id: "$50,000 - $100,000"},
        { display_name: "Generous ($100k - $250k)", _id: "$100,000 - $250,000"},
        { display_name: "Extreme ($250k - $1M)", _id: "$250,000 - $1,000,000"},
        { display_name: "Massive (> $1,000,000)", _id: "more than $1,000,000"},
    ]
    const guestRanges = [
        { display_name: "Less than 100", _id: "< 100" },
        { display_name: "100 - 250", _id: "100 - 250" },
        { display_name: "250 - 500", _id: "250 - 500" },
        { display_name: "500 - 1,000", _id: "500 - 1000" },
        { display_name: "1,000 - 2,000", _id: "1000 - 2000" },
        { display_name: "2,000 - 5,000", _id: "2000 - 5000" },
        { display_name: "More than 5,000", _id: "> 5000" }
    ]
    const planTeamRanges = [
        { display_name: "1", _id: "1" },
        { display_name: "2 - 5", _id: "2 - 5" },
        { display_name: "6 - 10", _id: "6 - 10" },
        { display_name: "11 - 20", _id: "11 - 20" },
        { display_name: "20 - 35", _id: "20 - 35" },
        { display_name: "More than 35", _id: "> 35" },
    ]
    const channelOptions = [
        { _id: "Email Newsletter", name: "Email Newsletter"},
        { _id: "LinkedIn", name: "LinkedIn"},
        { _id: "Twitter", name: "Twitter"},
        { _id: "Facebook", name: "Facebook"},
        { _id: "Podcasts", name: "Podcasts"},
        { _id: "Blogging", name: "Blogging"},
        { _id: "Press Releases", name: "Press Releases"},
        { _id: "Printed Invitations", name: "Printed Invitations"},
        { _id: "Digital Advertising", name: "Digital Advertising"},
        { _id: "Printed Advertising", name: "Printed Advertising"},
        { _id: "Guerilla Advertising", name: "Guerilla Advertising"},
    ]
    const updateHomeMeta = () => {
        try {
            // JSON-LD Data
            const schema = {
                "@context": "http://schema.org",
                "@type": "WebSite",
                "name": "Tetheros",
                "url": "https://www.tetheros.com/tools/event-plan-generator",
                "logo": "https://storage.googleapis.com/tetheros-public/logo/Tetheros%20OpenGraph%20Image.png",
                "description": "Rapidly generate an action plan for your upcoming event.",
                "publisher": {
                    "@type": "Organization",
                    "name": "Tetheros",
                    "url": "https://www.tetheros.com",
                    "logo": {
                    "@type": "ImageObject",
                    "url": "https://storage.googleapis.com/tetheros-public/logo/Tetheros%20OpenGraph%20Image.png",
                    "width": 1200,
                    "height": 630
                    }
                },
                "sameAs": [
                    "https://www.facebook.com/profile.php?id=100093504467373",
                    "https://twitter.com/tetheros",
                    "https://www.linkedin.com/company/tetheros/"
                ]
            }
            updateSchemaOrgJSONLD(schema)

            // Meta
            let title = "Event Plan Generator | Tetheros"
            let metaTags = [
                { name: 'description', content: 'Rapidly generate an action plan for your upcoming event' },
                { name: 'robots', content: 'index, follow' },
                { name: 'og:title', content: title },
                { name: 'og:description', content: 'Rapidly generate an action plan for your upcoming event' },
                { name: 'og:url', content: 'https://tetheros.com/' },
                { name: 'og:type', content: 'website' },
                { name: 'og:image', content: 'https://storage.googleapis.com/tetheros-public/logo/Tetheros%20OpenGraph%20Image.png' },
                { name: 'twitter:card', content: 'summary_large_image' },
                { name: 'twitter:title', content: title },
                { name: 'twitter:image', content: 'https://storage.googleapis.com/tetheros-public/logo/Tetheros%20OpenGraph%20Image.png' },
                { name: 'twitter:description', content: 'Rapidly generate an action plan for your upcoming event' },
            ]
            updateHeadTags(title, metaTags)
        } catch (error) {
            updateHeadTags("Event Plan Generator | Tetheros", [])
        }
    }
    useEffect(() => {
        reviewEntitlements()
        updateHomeMeta()
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        updateEmailAddressFeedback()
    // eslint-disable-next-line
    }, [emailAddress])
    return ( 
        <div>
            {page > 0 &&
            <GenericToolHeader authenticated={authenticated} symbol="events" title="Interactive Event Plan Generator" subtitle="Effortlessly kickstart an interactive action plan for your next event" />
            }
            {isLoading && !isBuilding &&
            <SimpleLoadingPage title="Interactive Event Plan Generator"/>
            }
            {!submitted && page === 0 && !isLoading && !isBuilding &&
            <div className="public-tool-promotion-calendar-wizard" style={{maxWidth: "unset"}}>
                <div className="landing-base-primary-container-limited-width" style={{width: "95%", maxWidth: "2000px"}}>
                    <div style={{width: "40px", marginLeft: "auto", marginRight: "auto", marginTop: "40px", cursor: "pointer"}}><TetherosSmallLogo onClick={() => history.push("/welcome")}/></div>
                    
                    <div className="landing-base-home-feature-title landing-base-home-feature-title-centered" style={{marginTop: "40px"}}>Event Plan Generator</div>
                    <div style={{fontWeight: 500, textAlign: "center"}}>Powered by Tetheros</div>
                    <div className="landing-base-home-subtext" style={{textAlign: "center", marginTop: "40px"}}>Rapidly generate an <span>action plan</span> for your <span>upcoming event</span></div>
                    <div className="util-row util-justify-center" style={{gap: "20px", marginTop: "60px"}}>
                            <div style={{width: "120px", textAlign: "center"}} className="landing-base-home-cta" onClick={() => setPage(1)}>Start now</div>
                            <div style={{width: "120px", textAlign: "center"}} className="landing-base-home-cta landing-base-home-cta-alt" onClick={() => window.open("https://youtu.be/T4a64DDErlY", '_blank').focus()}>Watch demo</div>
                    </div>
                    <div className="landing-base-home-hero-image-container" style={{width: "100%", margin: "auto", marginTop: "40px", marginBottom: "40px"}}>
                        <img src="https://storage.googleapis.com/tetheros-public/landing/Tetheros%20Event%20Plan%20Generator.png" alt="Tetheros workstreams shown for a committee-based team" onClick={() => window.open("https://storage.googleapis.com/tetheros-public/landing/Tetheros%20Event%20Plan%20Generator.png", '_blank').focus()} />
                    </div>
                </div>
            </div>
            }
            {!submitted && page === 1 && !isLoading && !isBuilding &&
            <div className="public-tool-promotion-calendar-wizard">
                <div className="public-tool-promotion-calendar-responsive-heading">
                    <h2>Interactive Event Plan Generator</h2>
                    <p>Effortlessly kickstart an interactive action plan for your next event.</p>
                </div>

                <div className="public-tool-promotion-calendar-wizard-collection-row-col">

                    <div className="public-tool-promotion-calendar-wizard-section">
                        <h4>Basic Information</h4>
                        <div className="public-tool-promotion-calendar-wizard-subsection">
                            <div className="public-tool-promotion-calendar-wizard-question">What is the name of your event?</div>
                            <input type="text" maxLength={120} placeholder="Provide an event title" value={title} onChange={(e) => setTitle(e.target.value)} />
                        </div>
                        <div className="public-tool-promotion-calendar-wizard-subsection">
                            <div className="public-tool-promotion-calendar-wizard-question">Select an event type</div>
                            <ModalToolSingleSelectRoleDropdownWithSearch displayAttributeName="display_name" searchText="Search event types..." setSelectedId={setEventType} allOptions={eventTypes} selectedId={eventType}/>
                        </div>
                        <div className="public-tool-promotion-calendar-wizard-subsection">
                            <div className="public-tool-promotion-calendar-wizard-question">When does your event start?</div>
                            <div style={{width: "100%"}} className="util-row util-justify-start public-tool-promotion-calendar-wizard-calendar-desktop">
                                <Calendar date={calendarFriendlyDate} onChange={handleDateChange} minDate={new Date()} />
                            </div>
                            <input className="public-tool-promotion-calendar-wizard-calendar-mobile" type="date" value={responsiveEventDate} onChange={(e) => handleResponsiveDateChange(e.target.value)} />
                            
                        </div>
                    </div>

                    <div className="public-tool-promotion-calendar-wizard-section">
                        <h4>Event Scale</h4>
                        <div className="public-tool-promotion-calendar-wizard-subsection">
                            <div className="public-tool-promotion-calendar-wizard-question">What's your budget?</div>
                            <ModalToolSingleSelectRoleDropdownWithSearch displayAttributeName="display_name" searchText="Search" setSelectedId={setBudget} allOptions={budgetRanges} selectedId={budget}/>
                        </div>
                        <div className="public-tool-promotion-calendar-wizard-subsection">
                            <div className="public-tool-promotion-calendar-wizard-question">How many guests are you expecting?</div>
                            <ModalToolSingleSelectRoleDropdownWithSearch displayAttributeName="display_name" searchText="Search" setSelectedId={setGuests} allOptions={guestRanges} selectedId={guests}/>
                        </div>
                        <div className="public-tool-promotion-calendar-wizard-subsection">
                            <div className="public-tool-promotion-calendar-wizard-question">How large is your core planning team?</div>
                            <ModalToolSingleSelectRoleDropdownWithSearch displayAttributeName="display_name" searchText="Search" setSelectedId={setTeamSize} allOptions={planTeamRanges} selectedId={teamSize}/>
                        </div>
                        <div className="public-tool-promotion-calendar-wizard-subsection">
                            <div className="public-tool-promotion-calendar-wizard-question">How long is the event?</div>
                            <div className="public-tool-promotion-calendar-wizard-answer-radios">
                                <div className="public-tool-promotion-calendar-wizard-answer-radio">
                                    <span className="public-tool-promotion-calendar-wizard-answer-radio-button" onClick={() => setEventLength(1)} style={getRadioStyle(1)}></span>
                                    One day
                                </div>
                                <div className="public-tool-promotion-calendar-wizard-answer-radio">
                                    <span className="public-tool-promotion-calendar-wizard-answer-radio-button" onClick={() => setEventLength(2)} style={getRadioStyle(2)}></span>
                                    Multiple days
                                </div>
                            </div>
                        </div>
                        <div className="public-tool-promotion-calendar-wizard-subsection">
                            <div className="public-tool-promotion-calendar-wizard-question">Which marketing channels do you want to use?</div>
                            <ModalToolMultiSelectWorkspaceDropdownWithSearch addWorkspace={addTeamToSelected} removeWorkspace={removeTeamFromSelected} allWorkspaces={channelOptions} selectedWorkspaceIds={selectedTeamIds} positionTop={true}/>
                        </div>
                    </div>
                </div>

                <div className="public-tool-promotion-calendar-wizard-collection-row-col">
                    <div className="public-tool-promotion-calendar-wizard-section" style={{flexBasis: "100%"}}>
                        <h4>The Details</h4>
                        <div className="public-tool-promotion-calendar-wizard-subsection">
                            <div className="public-tool-promotion-calendar-wizard-question">What else can you tell us about your event? (optional)</div>
                            <textarea maxLength={1000} rows={6} placeholder="Describe your event with as much detail as possible. Provide any other details that could impact planning (goals, VIP guests, fixed dates, venue requirements, focus areas, etc.)" value={requirements} onChange={(e) => setRequirements(e.target.value)}></textarea>
                            {requirements.length > 600 ? <div style={{fontSize: "12px", color: "grey", height: "16px"}}>{requirements.length}/1,000</div> : <div style={{height: "16px"}}></div>}
                        </div>
                    </div>
                </div>

                <div style={{marginTop: "20px", marginBottom: "40px", width: "100%"}} className="util-row util-justify-center">
                    <div className="public-tool-promotion-calendar-wizard-submit-button" onClick={attemptPageTurn} style={getAdditionalSubmitStyle()}>
                        <FaTools/>Build Event Plan
                    </div>
                </div>

            </div>}
            {!submitted && page === 2 && !isLoading && !isBuilding &&
            <div className="public-tool-promotion-calendar-wizard">
                <div className="public-tool-promotion-calendar-wizard-final-confirmation-page">
                    <div className="public-tool-promotion-calendar-wizard-section" style={{width: "100%"}}>
                        <div className="public-tool-promotion-calendar-wizard-subsection util-row util-col" style={{gap: "20px"}}>
                            <div className="public-tool-promotion-calendar-wizard-question">Where should we send the {title} event plan when it's ready?</div>
                            <div className="util-row util-justify-center"> <div className="public-tool-promotion-calendar-wizard-loader"></div> </div>
                            <input type="email" placeholder="Enter your email address" value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
                            <span style={{textAlign: "center", fontSize: "12px", color: "darkred"}}>{emailAddressFeedback}</span>
                        </div>
                    </div>

                    <div style={{marginTop: "20px", marginBottom: "40px", width: "100%", gap: "20px"}} className="util-row util-justify-center util-col util-align-center">
                        <span className="public-tool-promotion-calendar-wizard-submit-button" onClick={triggerNewBuild} style={getAdditionalSubmitStyle()}>
                            <MdMail/> Send it to me
                        </span>
                        <div style={{color: "#174061", fontSize: "12px"}}>If you click this we'll assume you're okay with us sending you email.</div>
                        <div style={{fontSize: "14px", cursor: "pointer", textDecoration: "underline", color: "grey"}} onClick={() => setPage(1)}>Go Back</div>
                    </div>
                </div>
            </div>
            }
            {isBuilding &&
            <div className="tools-error-message">
                <div className="util-row util-col util-align-center util-justify-center" style={{gap: "40px"}}>
                    <div className="tools-loading-loader"></div>
                    Your event plan is still building. We will email you when it's ready!
                </div>
                
            </div>
            }
            {submitted &&
            <div className="public-tool-promotion-calendar-wizard util-row util-col util-align-center" style={{marginTop: "120px"}}>
                <div className="tools-loading-loader"></div>
                <div style={{textAlign: "center", fontSize: "18px"}}>We'll send you an email at <span style={{fontWeight: 600, color: "#174061"}}>{emailAddress}</span> when it's ready!</div>
                {!authenticated && <div className="public-tool-promotion-calendar-wizard-new-account" onClick={() => history.push('/register')}>Get started with Tetheros</div>}
                {authenticated && <div className="public-tool-promotion-calendar-wizard-new-account" onClick={() => history.push('/home')}>Return to Tetheros</div>}
            </div> }
            {!submitted && !isLoading && !isBuilding && page > 0 && <div className="public-tool-promotion-calendar-wizard-disclaimer">Enjoy this free tool provided by Tetheros! Disclaimer: we are not responsible for how it is used. While we do our absolute best to provide reliable, useful, and innovative tools, the automated event plan generator is an experimental product and may not function as expected at all times. And although we're extremely attentive to data security and handling, we do not provide any warranty or guarantee of data accuracy, backups, or services of any kind unless you are an active Tetheros Pro customer.</div>}
        </div>
    );
}