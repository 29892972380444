import React from 'react'
import Api from '../../common/APIUtils';
import { useState, useEffect } from 'react';
import NewResource from '../forms/proj_components/NewResource';
import { BiSearch } from 'react-icons/bi'
import { hasPermission } from '../../common/Helpers';
import ConfirmAction from '../modals/ConfirmAction';
import WorkspaceCompInitiativeAlignmentResource from '../components/initiatives/WorkspaceCompInitiativeAlignmentResource';
import ModalCreateNewResourceInProject from '../modals/ModalCreateNewResourceInProject';

export default function WorkspaceResources({fetchWorkspace, components, team, workspaceId, subview, permissions}) {
    const api = new Api()
    const [page, setPage] = useState(() => { return "main"})
    const [query, setQuery] = useState(() => { return ""})
    const [resources, setResources] = useState(() => { return [] })
    const [filteredResources, setFilteredResources] = useState(() => { return [] })
    const [isShowingCreateNewResource, setIsShowingCreateNewResource] = useState(() => { return false })
    const [showConfirmAction, setShowConfirmAction] = useState(() => { return false })
    const [targetResourceId, setTargetResourceId] = useState(() => { return false })
    const goToResourceCreateForm = () => {
        setPage("create")
    }
    const resetPage = () => {
        fetchWorkspace()
        setPage("main")
    }
    const getSortedComponents = () => {
        try {
            const sortedResources = resources.filter((resource) => {
                if (resource._id === targetResourceId) return false
                if (query) {
                    const queryLowerCase = query.toLowerCase()
                    // Check if display name matches
                    if (resource.display_name.toLowerCase().indexOf(queryLowerCase) !== -1) return true
    
                    // Check if any keyword match
                    const keywords = resource.attributes['keywords']
                    if (keywords) {
                        if (keywords.join(",").toLowerCase().indexOf(queryLowerCase) !== -1) return true
                    }
    
                    // Check if description matches
                    const description = resource.attributes['description']
                    if (description) {
                        if (description.toLowerCase().indexOf(queryLowerCase) !== -1) return true
                    }
                    return false
                }
                return true
            })
            return sortedResources
        } catch (error) {
            return []
        }

    }
    const deleteResource = () => {
        if (!targetResourceId) return
        const payload = {
            componentId: targetResourceId
        }
        api.deleteWorkspaceComponent(payload)
        .then((res) => {
            fetchWorkspace()
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const removeResource = () => {
        deleteResource()
        fetchWorkspace()
        setShowConfirmAction(false)
    }
    const attemptRemoval = (id) => {
        setTargetResourceId(id)
        setShowConfirmAction(true)
    }
    const cancelRemoval = () => {
        setTargetResourceId(false)
        setShowConfirmAction(false)
    }
    useEffect(() => {
        if (subview) setPage(subview)
    }, [subview])
    useEffect(() => {
        if (!components) {
            setResources([])
            return
        }
        if (!components.link && !components.file) {
            setResources([])
            return
        }
        let l = []
        let f = []
        if (components.link) l = components.link
        if (components.file) f = components.file
        let r = [...l, ...f]
        setResources(r)
    // eslint-disable-next-line
    }, [components])
    useEffect(() => {
        if (!resources) {
            setFilteredResources([])
            return
        }
        setFilteredResources(getSortedComponents())
    // eslint-disable-next-line
    },[resources, query, components])
    return (
        <div className="network-page" style={{width: "100%"}}>
            {isShowingCreateNewResource && <ModalCreateNewResourceInProject workspaceId={workspaceId} parentProjectComponent={false} hideModal={() => setIsShowingCreateNewResource(false)} fetchAlignments={fetchWorkspace}/> }
            {page === "main" && (
                <div>
                    {showConfirmAction && <ConfirmAction hideMe={cancelRemoval} mainText="Are you sure you want to remove this resource?" onconfirm={removeResource} />}
                    <div className="workspace-library-section-header">
                        <h1>Resources</h1>
                        <p>Workspace assets, like links and notes, to improve your efficiency</p>
                    </div>

                    {resources.length > 0 && (
                        <div className="workspace-page-resources-actions-bar">
                            <div className="workspace-page-resources-action-search-container">
                                <BiSearch/>
                                <input type="text" value={query} onChange={(e) => setQuery(e.target.value)} placeholder="Search resources" />
                            </div>
                            {hasPermission(permissions, 'component_create') && <div className="workspace-page-resources-create-button" onClick={() => setIsShowingCreateNewResource(true)}>+ Add Resource</div>}
                        </div>
                    )}

                    {resources.length === 0 && <div className="workspace-page-resources-actions-bar"></div>}
                    
                    <div className="workspace-page-resources-lower-container" style={{marginTop: "40px"}}>

                        {resources.length > 0 && (
                            <>
                            <div className="component-page-initiative-searchable-resources-table-row component-page-initiative-searchable-resources-table-row-header">
                                <div className="component-page-initiative-searchable-resources-table-item comp-pg-init-srchble-rsrcs-tbl-itm-lg">Resource</div>
                                <div className="component-page-initiative-searchable-resources-table-item comp-pg-init-srchble-rsrcs-tbl-itm-lg">Description</div>
                                <div className="component-page-initiative-searchable-resources-table-item comp-pg-init-srchble-rsrcs-tbl-itm-sm">Added by</div>
                                <div className="component-page-initiative-searchable-resources-table-item comp-pg-init-srchble-rsrcs-tbl-itm-sm"></div>
                            </div>
                            {filteredResources.map((resource) => (
                                <WorkspaceCompInitiativeAlignmentResource altRemovalText="Delete" key={resource._id} resource={resource} team={team} permissions={permissions} fetchComponent={fetchWorkspace} removeMarker={attemptRemoval} />
                            ))}
                            </>
                        )}

                        {resources.length === 0 &&
                            <div className="workspace-page-resources-empty-state">
                                <div>Create resources to keep track of digital assets like file locations, websites, and important links</div>
                                {hasPermission(permissions, 'component_create') && <div className="workspace-page-resources-create-button" onClick={goToResourceCreateForm}>Add Resource</div>}
                            </div>
                        }

                        {(resources.length > 0) && (getSortedComponents().length < 1) && <div>No resources found</div> }
                    </div>
                </div>
            )}

            {page === "create" && (
                <NewResource goToAllResources={resetPage} workspaceId={workspaceId}/>
            )}
            
        </div>
    )
}