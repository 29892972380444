import React from 'react'
import { FaTimes } from 'react-icons/fa';
import { BsArrowLeft } from 'react-icons/bs'
import { IoPersonAdd} from 'react-icons/io5'
import { MdEmail } from 'react-icons/md'
import { useState, useEffect } from 'react';
import Select from 'react-select';
import { isValidEmailAddress } from '../../common/Helpers';

export default function ModalManageWorkspaceUsers({allUsers, addNewMember, inviteNewMember, team, hideModal, workspace}) {
    const [newMember, setNewMember] = useState(() => {return ""});
    const [invitedEmailAddress, setInvitedEmailAddress] = useState(() => { return "" })
    const [role, setRole] = useState(() => { return "" })
    const [formattedUserList, setFormattedUserList] = useState(() => {return []})
    const [userSearchTerm, setUserSearchTerm] = useState(() => {return ""})
    const [page, setPage] = useState(() => {return "main"})
    const getRoles = () => {
        if (!workspace) return []
        if (!workspace.workspace_roles) return []
        return workspace.workspace_roles
    }
    const formatUsersForSelect = () => {
        try {
            const existingTeamMemberIds = [];
        for (let j = 0; j < team.length; j++) {
            existingTeamMemberIds.push(team[j]['_id']);
        }
        if (userSearchTerm.length < 4) {
            setFormattedUserList([])
        } else {
            let formattedUsers = [];
            let users = []
            if (allUsers.length) users = allUsers
            for (let i = 0; i < users.length; i++) {
                const user = users[i];
                if (!existingTeamMemberIds.includes(user._id)) {
                    formattedUsers.push({value: user._id, label: `${user.firstName} ${user.lastName} @${user.handle}`})
                }
            }
            setFormattedUserList(formattedUsers);
        }
        } catch (error) {
            console.log(error)
        }
        
    }
    const getAddButtonStyle = () => {
        if (role === "" || !newMember.value) return {
            cursor: "not-allowed",
            backgroundColor: "grey"
        }
    }
    const submitInvitation = () => {
        if (!isValidEmailAddress(invitedEmailAddress)) return
        setPage("invite-sent")
        inviteNewMember(invitedEmailAddress)
    }
    const getInviteButtonStyle = () => {
        if (!isValidEmailAddress(invitedEmailAddress)) return {
            cursor: "not-allowed",
            backgroundColor: "grey"
        }
        return {}
    }
    const addTeammate = (e) => {
        // e.preventDefault()
        if (!newMember.value) return
        if (role === "") return
        addNewMember(newMember, role)
        hideModal()
    }
    useEffect(() => {
        formatUsersForSelect()
        // eslint-disable-next-line
    }, [userSearchTerm, allUsers]);
    return (
        <div className="alignment-modal-background">
            <div className="modal-manage-user-membership-container">

                <div className="modal-task-create-header-row">
                    <div className="modal-task-create-header-title">Add to Workspace</div>
                    <div className="modal-side-edit-header-row" style={{borderBottom: "none"}}>
                        <FaTimes className="project-post-form-cancel" onClick={hideModal}/>
                    </div>
                </div>

                <div className="modal-network-manage-users-section">
                    {page === "main" && 
                    <div className="modal-workspace-manage-users-form">
                        <div className="modal-workspace-manage-users-form-giant-buttons">
                            <div className="modal-workspace-manage-users-form-giant-button" onClick={() => setPage("add")}>
                                <IoPersonAdd style={{color: "#8795e8"}} />
                                <div>Add</div>
                            </div>
                            <div className="modal-workspace-manage-users-form-giant-button" onClick={() => setPage("invite")}>
                                <MdEmail style={{ color: "#ccddbb"}} />
                                <div>Invite</div>
                            </div>
                        </div>
                        <div onClick={hideModal} className="modal-workspace-manage-users-form-button-nevermind">Nevermind</div>
                    </div>
                    }
                    {page === "add" &&
                    <form className="modal-workspace-manage-users-form">
                        <div onClick={() => setPage("main")} className="modal-workspace-manage-users-back-nav"><BsArrowLeft/> <span>Back</span></div>
                        <div className="modal-workspace-manage-users-form-section">
                            <label>Search for existing member</label>
                            <Select noOptionsMessage={() => null} value={newMember} onInputChange={(e) => setUserSearchTerm(e)} onChange={setNewMember} placeholder="Enter a name or handle" isSearchable className="network-users-form-dropdown" options={formattedUserList}/>
                        </div>

                        <div className="modal-workspace-manage-users-form-section">
                            <label>Choose a role</label>
                            <select className="modal-manage-user-membership-dropdown" value={role} onChange={(e) => setRole(e.target.value)}>
                                <option value="">--None--</option>
                                {getRoles().map((r) => (
                                    <option value={r._id} key={r._id}>{r.name}</option>
                                ))}
                            </select>
                        </div>

                        {/* Instructional Box */}
                        <div className="modal-network-manage-users-section modal-manage-user-membership-role-container">
                            <div className="modal-manage-user-membership-role">
                                <p>User</p>
                                <div>- Create, view, and update components and templates.</div>
                            </div>

                            <div className="modal-manage-user-membership-role">
                                <p>Leader</p>
                                <div>- Create, view, and update components and templates.</div>
                                <div>- Delete components and templates.</div>
                                <div>- Add and remove members</div>
                            </div>

                            <div className="modal-manage-user-membership-role">
                                <p>Owner</p>
                                <div>- Create, view, and update components and templates.</div>
                                <div>- Delete components and templates.</div>
                                <div>- Add and remove members</div>
                                <div>- Update workspace settings</div>
                                <div>- Manage user roles</div>
                            </div>
                        </div>

                        <div className="modal-manage-user-membership-buttons">
                            <div className="modal-manage-user-membership-save-button" value="Add" type="submit" style={getAddButtonStyle()} onClick={addTeammate}>Add</div>
                            <div className="modal-manage-user-membership-cancel-button" onClick={hideModal}>Cancel</div>
                        </div>
                    </form>
                    }
                    {page === "invite" &&
                    <form className="modal-workspace-manage-users-form" onSubmit={(e) => e.preventDefault()}>
                        <div onClick={() => setPage("main")} className="modal-workspace-manage-users-back-nav"><BsArrowLeft/> <span>Back</span></div>
                        <div className="modal-workspace-manage-users-form-section">
                            <label>Enter email address</label>
                            <input className="modal-workspace-manage-users-form-invite-input" placeholder="Enter a valid email address" type="text" value={invitedEmailAddress} onChange={(e) => setInvitedEmailAddress(e.target.value)}/>
                        </div>

                        <div className="modal-manage-user-membership-buttons">
                            <div className="modal-manage-user-membership-save-button" value="Add" type="submit" style={getInviteButtonStyle()} onClick={submitInvitation}>Invite</div>
                            <div className="modal-manage-user-membership-cancel-button" onClick={hideModal}>Cancel</div>
                        </div>
                    </form>
                    }
                    {page === "invite-sent" && 
                    <div className="modal-workspace-manage-users-form">
                        <div style={{textAlign: "center", fontSize: "16px", fontWeight: "600", marginTop: "12px"}}>Invite sent to {invitedEmailAddress}</div>
                        <div style={{textAlign: "center"}}>If there are enough seats in your workspace you can assign them a role after they join</div>
                        <div onClick={hideModal} className="modal-workspace-manage-users-form-button-nevermind">Back to work</div>
                    </div>
                    }
                </div>

            </div>
        </div>
    )
}
