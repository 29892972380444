import React from 'react'
import Api from '../../common/APIUtils';
import { useState, useEffect } from 'react';
import '../../styles/tables.css';
import { useHistory } from 'react-router-dom';
import { IoSearchOutline   } from 'react-icons/io5'
import { MdOutlineStar, MdOutlineStarBorder } from "react-icons/md";
import { getColorFromPaletteBasedOnFirstName, sortByProperty } from '../../common/Helpers';

export default function UserWorkspaces({userData}) {
    const history = useHistory();
    const api = new Api();
    const [searchText, setSearchText] = useState(() => { return "" });
    const [workspaces, setWorkspaces] = useState(() => { return [] });
    const [sortedWorkspaces, setSortedWorkspaces] = useState(() => { return [] });
    const [pinnedWorkspaces, setPinnedWorkspaces] = useState(() => { return [] });
    const [pinnedWorkspaceIds, setPinnedWorkspaceIds] = useState(() => {
        return localStorage.getItem("gPinnedWorkspaceIds").split(",")
    });
    const fetchWorkspaces = () => {
        api.getWorkspaces()
        .then((res) => {
            setWorkspaces(res.data.workspaces);
        })
        .catch((err) => {
            console.log(err);
        })  
    }
    const savePinnedWorkspace = (workspaceId) => {
        api.pinWorkspaceForUser({
            add: [workspaceId],
            remove: []
        })
        .then((res) => { return })
        .catch((err) => { return })
    }
    const removePinnedWorkspace = (workspaceId) => {
        api.pinWorkspaceForUser({
            remove: [workspaceId],
            add: []
        })
        .then((res) => { return })
        .catch((err) => { return })
    }
    const updateSortedWorkspaces = () => {
        try {
            const workspacesCopy = Array.from(workspaces)
            const organized = sortByProperty(workspacesCopy, "name")
            if (!searchText.trim() || searchText.trim() === "") return setSortedWorkspaces(organized)
            const filtered = organized.filter((w) => {
                if (w.name.toLowerCase().indexOf(searchText.trim().toLowerCase()) !== -1) return true
                return false
            })
            setSortedWorkspaces(filtered)
        } catch (error) {
            return setSortedWorkspaces(workspaces)
        }
    }
    const navToWorkspace = (workspaceId) => {
        if (!workspaceId) return
        return history.push(`/workspaces/${workspaceId}`)
    }
    const pinWorkspace = (workspaceId) => {
        let currentWorkspaceIds = Array.from(pinnedWorkspaceIds)
        if (currentWorkspaceIds.includes(workspaceId)) return 
        currentWorkspaceIds.push(workspaceId)
        setPinnedWorkspaceIds(currentWorkspaceIds)
        localStorage.setItem("gPinnedWorkspaceIds", currentWorkspaceIds)
        savePinnedWorkspace(workspaceId)
    }
    const unpinWorkspace = (workspaceId) => {
        let currentWorkspaceIds = Array.from(pinnedWorkspaceIds)
        if (!currentWorkspaceIds.includes(workspaceId)) return
        const index = currentWorkspaceIds.indexOf(workspaceId)
        currentWorkspaceIds.splice(index, 1)
        setPinnedWorkspaceIds(currentWorkspaceIds)
        localStorage.setItem("gPinnedWorkspaceIds", currentWorkspaceIds)
        removePinnedWorkspace(workspaceId)
    }
    const updatePinnedWorkspaceSection = () => {
        try {
            if (!sortedWorkspaces) return setPinnedWorkspaces([])
            if (sortedWorkspaces.length === 0) return setPinnedWorkspaces([])
            if (!pinnedWorkspaceIds) return setPinnedWorkspaces([])
            if (pinnedWorkspaceIds.length === 0) return setPinnedWorkspaces([])
            const proposedPinnedWorkspaces = sortedWorkspaces.filter((workspace) => {
                if (pinnedWorkspaceIds.includes(workspace._id)) return true
                return false
            })
            if (proposedPinnedWorkspaces.length > 0) return setPinnedWorkspaces(proposedPinnedWorkspaces)
            setPinnedWorkspaces([])
        } catch (error) {
            return setPinnedWorkspaces([])
        }
    }
    useEffect(() => {
        fetchWorkspaces();
        document.title = "Workspaces | Tetheros"
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        updatePinnedWorkspaceSection()
    // eslint-disable-next-line
    }, [sortedWorkspaces])
    useEffect(() => {
        updateSortedWorkspaces()
    // eslint-disable-next-line
    }, [workspaces, searchText])
    useEffect(() => {
        if (!userData) return
        if (!userData.pinned_workspace_ids) return
        setPinnedWorkspaceIds(userData.pinned_workspace_ids)
    },[userData])
    return (
        <div className="user-workspaces-main">
            <div className="user-workspaces-header">
                <div className="user-workspaces-search-container">
                    <IoSearchOutline/>
                    <input type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder="Search" autoFocus={true} />
                </div>
            </div>

            {pinnedWorkspaces.length > 0 && !searchText &&
            <div className="user-workspaces-section">
                <h2>Favorites</h2>
                {pinnedWorkspaces.length > 0 &&
                <div className="user-workspaces-workspace-grid">
                    {pinnedWorkspaces.map((workspace) => (
                        <WorkspaceNavigationItemGrid unpinWorkspace={unpinWorkspace} pinnedWorkspaceIds={pinnedWorkspaceIds} pinWorkspace={pinWorkspace} workspace={workspace} key={workspace._id} navToWorkspace={navToWorkspace} />
                    ))}
                </div>
                }
            </div>
            }

            <div className="user-workspaces-section">
                <h2>All Workspaces</h2>
                {sortedWorkspaces.length > 0 &&
                <div className="user-workspaces-workspace-grid">
                    {sortedWorkspaces.map((workspace) => (
                        <WorkspaceNavigationItemGrid unpinWorkspace={unpinWorkspace} pinnedWorkspaceIds={pinnedWorkspaceIds} pinWorkspace={pinWorkspace} workspace={workspace} key={workspace._id} navToWorkspace={navToWorkspace} />
                    ))}
                </div>
                }
                {sortedWorkspaces.length === 0 && !searchText.trim() &&
                <div className="user-workspaces-empty-action-text">You aren't a member of any workspaces yet</div>
                }
                {sortedWorkspaces.length === 0 && searchText.trim() &&
                <div className="user-workspaces-empty-action-text">No workspace names match your search terms</div>
                }
            </div>

        </div>
    )
}

function WorkspaceNavigationItemGrid({workspace, navToWorkspace, pinnedWorkspaceIds, pinWorkspace, unpinWorkspace}) {
    const isPinned = () => {
        try {
            if (!workspace) return false
            if (!workspace._id) return false
            if (!pinnedWorkspaceIds) return false
            if (pinnedWorkspaceIds.includes(workspace._id)) return true
            return false
        } catch (error) {
            return false 
        }
    }
    const togglePinned = (e) => {
        e.stopPropagation()
        if (isPinned()) return unpinWorkspace(workspace._id)
        pinWorkspace(workspace._id)
    }
    const getNetworkDecorator = () => {
        if (!workspace) return false
        if (workspace.network) return workspace.network.name
        return "Private Workspace"
    }
    return (
        <div className="user-workspaces-nav-item-grid" onClick={() => navToWorkspace(workspace._id)}>
            {!workspace.banner_image_url && <div style={{backgroundColor: `${getColorFromPaletteBasedOnFirstName(workspace.name)}20`, flexGrow: 1}}></div>}
            {workspace.banner_image_url && <div style={{backgroundImage: `url(${workspace.banner_image_url})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "left bottom", flexGrow: 1}}></div>}
            <div className="user-workspaces-nav-item-grid-workspace-information">
                <div>
                    <span className="user-workspace-nav-network-decorator">{getNetworkDecorator()}</span>
                    <div className="user-workspaces-nav-pinned" onClick={(e) => togglePinned(e)}>{isPinned() ? <MdOutlineStar/> : <MdOutlineStarBorder/>}</div>
                </div>
                <h3>{workspace.name}</h3>
                </div>
        </div>
    )
}