const componentMenu = [
    // {
    //     title: 'Strategies',
    //     subtitle: 'Create a purpose to align your efforts toward',
    //     items: [
    //         {
    //             name: 'Project',
    //             description: 'Align workstreams, goals, and resources for a specific purpose',
    //             url: '/strategy/initiative',
    //             to: '/new?layer=strategy&name=initiative',
    //             com_name: 'initiative',
    //             com_layer: 'strategy'
    //         }
    //     ]
    // },
    {
        title: 'Workstreams',
        subtitle: 'Create and organize tasks in different ways',
        items: [
            {
                name: 'List',
                description: 'Create and complete simple tasks',
                url: '/work/list',
                to: '/new?layer=work&name=list',
                com_name: 'list',
                com_layer: 'work'
            },
            {
                name: 'Board',
                description: 'Track work with cards and columns',
                url: '/work/board',
                to: '/new?layer=work&name=board',
                com_name: 'board',
                com_layer: 'work'
            }
        ]
    },
    {
        title: 'Views',
        subtitle: 'Group and visualize workstreams and goals',
        items: [
            {
                name: 'Timeline',
                description: 'Group workstreams, hashtags, and goals on an interactive calendar',
                url: '/strategy/timeline',
                to: '/new?layer=plan&name=timeline',
                com_name: 'timeline',
                com_layer: 'plan'
            }
        ]
    },
    // {
    //     title: 'Measurements & Goals',
    //     subtitle: 'Set meaningful goals with real data',
    //     items: [
    //         // {
    //         //     name: 'Goal',
    //         //     description: 'Measure the progress of anything over time',
    //         //     url: '/plan/goal',
    //         //     to: '/new?layer=plan&name=goal',
    //         //     com_name: 'goal',
    //         //     com_layer: 'plan'
    //         // },
    //         {
    //             name: 'KPI',
    //             description: 'Create key performance indicators for anything',
    //             url: '/plan/goal',
    //             to: '/measurements/new',
    //             com_name: 'goal',
    //             com_layer: 'plan'
    //         },
    //         {
    //             name: 'Goal',
    //             description: 'Set and measure the progress of a KPI over time',
    //             url: '/plan/kresult',
    //             to: '/new?layer=plan&name=kresult',
    //             com_name: 'kresult',
    //             com_layer: 'plan'
    //         }
    //     ]
    // },
    // {
    //     title: 'Organization',
    //     subtitle: 'Keep track of all the important pieces of your mission',
    //     items: [
    //         {
    //             name: 'Resource',
    //             description: 'Organize digital assets like file locations, websites, and important links.',
    //             url: '/work/list',
    //             to: '/resources/new',
    //             com_name: 'list',
    //             com_layer: 'work'
    //         }
    //     ]
    // }
]

export default componentMenu;