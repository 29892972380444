import React from 'react'
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Api from '../../common/APIUtils';
import { cleanSlug } from '../../common/Helpers';

export default function NetworkSettings({network, fetchNetwork}) {
    let history = useHistory()
    let api = new Api()
    const [errorText, setErrorText] = useState(() => { return "" })
    const [proposedName, setProposedName] = useState(() => { return "" })
    const [proposedSlug, setProposedSlug] = useState(() => { return "" })
    const [hasUpdates, setHasUpdates] = useState(() => { return false })
    const buildApprovedSlug = (typedSlug) => {
        setProposedSlug(cleanSlug(typedSlug))
    }
    const updateNetworkSettings = () => {
        if (!hasUpdates) return
        setErrorText("")
        let payload = {}
        if (proposedName !== network.name && proposedName) payload['name'] = proposedName
        if (proposedSlug !== network.slug && proposedSlug) payload['slug'] = proposedSlug
        api.updateNetwork(payload, network.slug)
        .then((res) => {
            if (res.data) {
                if (res.data.response.network.slug !== network.slug) {
                    history.push(`/n/${res.data.response.network.slug}/settings`)
                    return
                }
                fetchNetwork()
            }
        })
        .catch((err) => {
            if (err.response) {
                if (err.response.status === 400) {
                    setErrorText("Something went wrong with the request")
                } else {
                    setErrorText("Something went wrong with the request")
                }
            }
            setErrorText("Something went wrong with the request")
        }) 
    }
    const revertSettingChanges = () => {
        setProposedSlug(network.slug)
        setProposedName(network.name)
    }
    useEffect(() => {
        setProposedName(network.name)
        setProposedSlug(network.slug)
    }, [network])
    useEffect(() => {
        if (proposedName !== network.name && proposedName) {
            setHasUpdates(true)
            return
        }
        if (proposedSlug !== network.slug && proposedSlug) {
            setHasUpdates(true)
            return
        }
        setHasUpdates(false)
    }, [proposedName, proposedSlug, network])
    return (
        <div className="network-hub-page">
            
            <div className="network-page-header-row">
                <div className="network-hub-page-header-text">Settings{errorText && <p style={{color: "red"}}>{errorText}</p>}</div>
            </div>

            <div>
                <div className="network-settings-item">
                    <div className="network-settings-item-text">
                        <h2>Name</h2>
                        <p>Changing the network name will not affect anything else </p>
                    </div>
                    <div className="network-settings-item-input">
                        <input className="network-settings-item-input-text" value={proposedName} onChange={(e) => setProposedName(e.target.value)} placeholder={network.name} type="text" />
                    </div>
                </div>

                <div className="network-settings-item">
                    <div className="network-settings-item-text">
                        <h2>URL</h2>
                        <p>Update the URL of the network.  This will not impact any network workspace URLs.</p>
                    </div>
                    <div className="network-settings-item-input">
                        <input className="network-settings-item-input-text" value={proposedSlug} onChange={(e) => buildApprovedSlug(e.target.value)} placeholder={network.slug} type="text" />
                    </div>
                </div>

                {hasUpdates && 
                    <div className="network-settings-save-discard-section">
                        <div className="network-settings-button network-settings-save" onClick={updateNetworkSettings}>Save Changes</div>
                        <div className="network-settings-button network-settings-discard" onClick={revertSettingChanges}>Revert Settings</div>
                    </div>
                }
            </div>
            
        </div>
    )
}
