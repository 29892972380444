import React from 'react'
import { FaTimes } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import Api from '../../common/APIUtils';
import DataTableRow from '../tables/DataTableRow';

export default function ModalManageTeam({network, team, hideModal, fetchUsers, fetchNetwork, view, networkWorkspaces, fetchNetworkTeam}) {
    const api = new Api()
    const [addUserFilter, setAddUserFilter] = useState(() => { return ""})
    const [addWorkspaceFilter, setAddWorkspaceFilter] = useState(() => { return ""})
    const [potentialUsers, setPotentialUsers] = useState(() => { return []})
    const [potentialWorkspaces, setPotentialWorkspaces] = useState(() => { return []})
    const nameOrHandleMeetsUserFilter = (firstName, lastName, handle) => {
        if (addUserFilter === "") return true
        if (firstName.toUpperCase().indexOf(addUserFilter.toUpperCase()) > -1) return true
        if (lastName.toUpperCase().indexOf(addUserFilter.toUpperCase()) > -1) return true
        if (handle.toUpperCase().indexOf(addUserFilter.toUpperCase()) > -1) return true
        return false
    }
    const workspaceNameMeetsWorkspaceFilter = (name) => {
        if (addWorkspaceFilter === "") return true
        if (name.toUpperCase().indexOf(addWorkspaceFilter.toUpperCase()) > -1) return true
        return false
    }
    const getPotentialUsers = () => {
        const activeUsers = potentialUsers.filter((user) => {
            if (!user.firstName || !user.lastName) return false
            return true
        })
        return activeUsers
    }
    const getPotentialNewMembers = () => {
        let allUsersInNetwork = network.network_users;
        if (!team.user_ids) return setPotentialUsers(allUsersInNetwork)
        if (!team.user_ids.length === 0) return setPotentialUsers(allUsersInNetwork)
        const potentialNewMembers = allUsersInNetwork.filter((user) => {
            if (team.user_ids.includes(user._id)) return false
            return nameOrHandleMeetsUserFilter(user.firstName, user.lastName, user.handle)
        })
        if (!potentialNewMembers) return setPotentialUsers([])
        setPotentialUsers(potentialNewMembers)
    }
    const getPotentialNewWorkspaces = () => {
        if (!networkWorkspaces) return setPotentialWorkspaces([])
        const potentialNewWorkspaces = networkWorkspaces.filter((workspace) => {
            if (!team.workspace_ids) return true // workspace is not connected to any teams
            if (team.workspace_ids.includes(workspace._id)) return false
            return workspaceNameMeetsWorkspaceFilter(workspace.name)
        })
        if (!potentialNewWorkspaces) return setPotentialWorkspaces([])
        setPotentialWorkspaces(potentialNewWorkspaces)
    }
    const addUserToTeam = (user) => {
        if (!network || !team) return
        const payload = {
            team_id: team._id,
            user_email: user.emailAddress
        }
        api.addUserToTeam(payload, network.slug)
        .then((res) => {
            if (res.data) {    
                fetchNetwork()
                fetchUsers()
                getPotentialNewMembers()            
            }
        })
        .catch((err) => {
            console.log('Toast')
        })
    }
    const formatDataForRow = (user) => {
        return [
            {
                width: "lg",
                content: (
                    <div>
                        <div style={{fontWeight: "600"}}>{user.firstName} {user.lastName}</div>
                        <div style={{fontWeight: "400", color: "grey"}}>@{user.handle}</div>
                    </div>
                ),
                _id: `${user._id}-name`
            },
            {
                width: "sm",
                content: (
                    <button onClick={() => addUserToTeam(user)} className="network-team-open-manage-button">+ Add</button>
                ),
                _id: `${user._id}-manage`
            }
        ]
    }
    const formatWorkspaceDataForRow = (workspace) => {
        return [
            {
                width: "lg",
                content: <div style={{paddingTop: "0.5em", paddingBottom: "0.5em"}}>{workspace.name}</div>,
                _id: `${workspace._id}-name`
            },
            {
                width: "sm",
                content: (
                    <button className="network-team-open-manage-button" onClick={() => addWorkspaceToTeam(workspace._id)}>+ Add</button>
                ),
                _id: `${workspace._id}-manage`
            }
        ]
    }
    const addWorkspaceToTeam = (workspaceId) => {
        if (!network.slug) return
        const payload = {
            team_id: team._id,
            workspace_id: workspaceId
        }
        api.addWorkspaceToTeam(payload, network.slug)
        .then((res) => {
            if (res.data) {
                fetchNetwork()
                fetchUsers()
                getPotentialNewWorkspaces()
                fetchNetworkTeam()
            }
        })
        .catch((err) => {
            console.log('Toast')
        })
    }
    useEffect(() => {
        getPotentialNewMembers()
        getPotentialNewWorkspaces()
    // eslint-disable-next-line
    }, [network, team, addUserFilter, addWorkspaceFilter])
    useEffect(() => {
        if (fetchNetworkTeam) fetchNetworkTeam()
    // eslint-disable-next-line
    }, [])
    return (
        <div className="alignment-modal-background">
            <div className="modal-task-create-container">

                {/* Header */}
                <div className="modal-task-create-header-row" style={{borderBottom: "none"}}>
                    <div className="modal-task-create-header-title">{team.name}</div>
                    <div className="modal-side-edit-header-row" style={{borderBottom: "none"}}>
                        <FaTimes className="project-post-form-cancel" onClick={hideModal}/>
                    </div>
                </div>

                {view === 'add_users' && (
                    <div className="modal-team-view-container">
                        <input className="modal-team-add-search-box" value={addUserFilter} onChange={(e) => setAddUserFilter(e.target.value)} type="text" placeholder="Search for a teammate"/>
                        <div className="modal-team-add-network-users">
                            {getPotentialUsers().map((user) => (
                                <DataTableRow key={user._id} rowData={formatDataForRow(user)}/>
                            ))}

                            {getPotentialUsers().length === 0 && <div className="modal-team-add-network-users-subtext">No users found.  Invite more people to your network to add them to this team.</div> }
                        </div>
                        {network && <div className="modal-team-add-network-users-subtext">You can only add users that are already part of the {network.name} network</div>}
                    </div>
                )}

                {view === 'add_workspaces' && (
                    <div className="modal-team-view-container">
                        <input className="modal-team-add-search-box" value={addWorkspaceFilter} onChange={(e) => setAddWorkspaceFilter(e.target.value)} type="text" placeholder="Search for a workspace"/>
                        <div className="modal-team-add-network-users">
                            {potentialWorkspaces.map((workspace) => (
                                <DataTableRow key={workspace._id} rowData={formatWorkspaceDataForRow(workspace)}/>
                            ))}
                        </div>
                        {network && <div className="modal-team-add-network-users-subtext">You can only add workspaces from the {network.name} network</div>}
                    </div>
                )}

            </div>
        </div>
    )
}
