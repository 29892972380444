import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import { IoEllipsisVerticalSharp, IoCheckmarkOutline } from "react-icons/io5";
import { AiOutlineSortAscending, AiOutlineSortDescending } from "react-icons/ai";
import { MdKeyboardArrowLeft } from "react-icons/md";
import WorkspaceCompBoardCard from './WorkspaceCompBoardCard';
import ConfirmAction from '../../modals/ConfirmAction';
import ToolTip from '../../gadgets/ToolTip';
import Api from '../../../common/APIUtils';
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { differenceInDays } from 'date-fns'
import { hasPermission } from '../../../common/Helpers';

export default function WorkspaceCompBoardColumn({ setCardLimit, boardFilter, setIsEditingModal, permissions, setSelectedTask, columnTitle, updateTask, index, data, team, board, fetchBoard, addColumn, removeBoardColumn, updateColumn, getHighestOrder, sortColumnByParameters }) {
    const api = new Api();
    const [permaColumnIds, setPermaColumnIds] = useState(() => {
        try {
            return [board.attributes.column_id_done, board.attributes.column_id_new]
        } catch (error) {
            console.log(error)
            return []
        }
    })
    const [showDeleteColumnConfirm, setShowDeleteColumnConfirm] = useState(() => { return false })
    const [isEditingColumnName, setIsEditingColumnName] = useState(() => { return false })
    const [proposedColumnName, setProposedColumnName] = useState(() => { return columnTitle })
    const [autoHideSetting, setAutoHideSetting] = useState(() => { return -1 })
    const [showToolTip, setShowToolTip] = useState(() => { return false })
    const getColumnClassnames = (dragged) => {
        if (dragged) {
            return "workspace-board-column-cards workspace-board-column-dragged-over"
        } else {
            return "workspace-board-column-cards"
        }
        
    }
    const hideConfirmPopups = () => {
        setShowDeleteColumnConfirm(false)
    }
    const canFetchMoreTasks = () => {
        if (data.tasks.length < data.task_count) return true
        return false
    }
    const deleteColumn = () => {
        removeBoardColumn(data._id)
    }
    const isDoneColumn = () => {
        try {
            if (board.attributes.column_id_done === data._id) {
                return true
            } else {
                return false
            }
        } catch (error) {
            return false
        }
    }
    const isDoneOrNewColumn = () => {
        try {
            if ((board.attributes.column_id_done === data._id) || (board.attributes.column_id_new === data._id)) {
                return true
            } else {
                return false
            }
        } catch (error) {
            return false
        }
    }
    const fetchTasksToLimit = () => {
        setCardLimit(data.task_count)
    }
    const updateColumnName = () => {
        const payload = {
            name: proposedColumnName,
            column_id: data._id
        }
        updateColumn(payload)
        setIsEditingColumnName(false);
    }
    const handleColumnNameChangeKeyPress = (e) => {
        if (e.key === 'Enter') {
            updateColumnName()
        }
    }
    const deleteTask = (taskId) => {
        const payload = {
            componentId: taskId
        }
        api.deleteWorkspaceComponent(payload)
        .then((res) => {
            fetchBoard();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const completeTask = (task) => {
        const taskId = task._id;
        const payload = {
            attributes: {
                active: false,
                date_completed: new Date(),
                progress: 1,
                state: board.attributes.column_id_done
            },
            status: 'completed',
            completed: new Date()
        }
        const path = `/workspace/components/${taskId}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchBoard();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const restoreTask = (task) => {
        const taskId = task._id;
        const payload = {
            attributes: {
                active: true,
                date_completed: "",
                progress: 0,
                state: board.attributes.column_id_new
            },
            status: 'active',
            completed: ""
        }
        const path = `/workspace/components/${taskId}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchBoard();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const autoHideFilter = (item) => {
        try {           
            // Necessary to accommodate new items that haven't been updated in the DB yet
            if (!item.completed) {
                return true
            }
            const timePassedInDaysSinceCompleted = differenceInDays(new Date(), new Date(item.completed));
            // if the difference in TIME is less than the autoHideSetting, hide it
            if (timePassedInDaysSinceCompleted < autoHideSetting) {
                return true
            } else {
                return false
            }   
        } catch (error) {
            console.log(error)
            return false;
        }
    }
    const filterTasks = (taskList) => {
        if (!taskList) return []
        if (taskList.length === 0) return []
        if (!boardFilter) return taskList
        if (boardFilter.people.length === 0 && !boardFilter.query) return taskList
        let filteredTaskList = []
        try {
            filteredTaskList = taskList.filter((taskData) => {
                if (boardFilter.people.length > 0) {
                    if (!taskData.owner_id) return false
                    if (!boardFilter.people.includes(taskData.owner_id)) return false
                }
                if (!boardFilter.query) return true
                if (taskData.display_name.toLowerCase().indexOf(boardFilter.query.toLowerCase()) !== -1) return true
                if (!taskData.attributes.details) return false
                // Legacy description
                if (typeof taskData.attributes.details === "string") {
                    if (taskData.attributes.details.toLowerCase().indexOf(boardFilter.query.toLowerCase()) !== -1) return true
                }
                // lexical description
                if (!taskData.attributes.details.description_html) return false
                let descriptionString = taskData.attributes.details.description_html.replace(/<\/?[^>]+(>|$)/g, '')
                if (descriptionString.toLowerCase().indexOf(boardFilter.query.toLowerCase()) !== -1) return true
                return false
            })
        } catch (error) {
            filteredTaskList = taskList
        }
        return filteredTaskList
    }
    const getUnfinishedTasks = () => {
        if (!boardFilter) return data.tasks
        if (boardFilter.people.length === 0 && !boardFilter.query) return data.tasks
        return filterTasks(data.tasks)
    }
    const getAdditionalColumnClass = () => {
        if (!isDoneOrNewColumn()) return ""
        if (isDoneColumn()) return "workspace-board-column-done"
        return "workspace-board-column-new"
    }
    const autoHideTasks = () => {
        // If there is no defined board filter follow autoHideSettings
        if ((!boardFilter) || (boardFilter.people.length === 0 && !boardFilter.query)) {
            if (autoHideSetting < 0) return filterTasks(data.tasks)
            let copyOfTasks = Array.from(data.tasks);
            let autoHiddenFilterTasks = copyOfTasks.filter(autoHideFilter)
            return filterTasks(autoHiddenFilterTasks)
        }
        // If there is a board filter, ignore autoHideSettings
        if (boardFilter.people.length > 0 || boardFilter.query) {
            return filterTasks(data.tasks)
        }
        return data.tasks
    }
    const submitColumnSortRequest = (params) => {
        try {
            if (!data) return
            if (!data._id) return
            sortColumnByParameters(data, params)
        } catch (error) {
            return
        }
    }
    const getColumnDraggableProps = (provided) => {
        if (hasPermission(permissions, 'component_update')) return { ...provided.draggableProps } 
        return {}
    }
    useEffect(() => {
        try {
            setPermaColumnIds([board.attributes.column_id_done, board.attributes.column_id_new])
            if (board.attributes.auto_hide_days) {
                setAutoHideSetting(board.attributes.auto_hide_days)
            }
        } catch (error) {
            console.log(error)
            setPermaColumnIds([])
        }
    // eslint-disable-next-line
    }, [])
    return (
        <Draggable key={data._id} draggableId={data._id} index={index} isDragDisabled={isDoneOrNewColumn()}>
            {(provided) => (
            <div className={`workspace-board-column ${getAdditionalColumnClass()}`} { ...getColumnDraggableProps(provided) } ref={provided.innerRef}>
                {/* Confirmation Menus */}
                {showDeleteColumnConfirm && <ConfirmAction mainText="Are you sure you want to delete this column?  All items underneath will be permanently deleted with it." onconfirm={deleteColumn} hideMe={hideConfirmPopups}/> }

                            {/* Header */}
                            <div className="workspace-board-column-header">
                                <div className="workspace-board-column-headers" {...provided.dragHandleProps}>
                                    {isEditingColumnName ? (
                                        <input className="workspace-board-column-header-title workspace-board-column-header-title-edit" value={proposedColumnName} onChange={(e) => setProposedColumnName(e.target.value)} autoFocus={true} onBlur={updateColumnName} onKeyDown={(e) => handleColumnNameChangeKeyPress(e)}/>
                                    ) : (
                                        <>
                                        <div className="workspace-board-column-header-title" onClick={(e) => setIsEditingColumnName(true)}>
                                            <h2 title={columnTitle}>{columnTitle}</h2>
                                            {/* <span className="workspace-board-column-header-title-count">{getColumnTaskCount()}</span> */}
                                        </div>
                                        {permaColumnIds.includes(data._id) ? (
                                            <div className="workspace-board-column-header-options workspace-board-column-header-options-perma">
                                                <BiInfoCircle onMouseEnter={() => setShowToolTip(true)} onMouseLeave={() => setShowToolTip(false)}/>
                                                {showToolTip && <ToolTip position="bottom" text={isDoneColumn() ? "Tasks moved here will be marked complete" : "New tasks start here"}/>}
                                            </div>
                                        ) : (
                                            <div className="workspace-board-column-header-options">
                                                <ColumnSettingsActionMenu sortColumnByParameters={submitColumnSortRequest} deleteColumn={(e) => setShowDeleteColumnConfirm(true)} />
                                                {/* <span className="workspace-board-column-shift-control"><BiMoveHorizontal/></span>
                                                <BiTrash onClick={(e) => setShowDeleteColumnConfirm(true)}/> */}
                                            </div>
                                        )}
                                        </>
                                    )}
                                    
                                </div>
                            </div>

                            {/* Start Droppable area for cards */}
                            {isDoneColumn() ? (
                                // Done column cards
                                <Droppable droppableId={data._id} type="cards">
                                    {(provided, snapshot) => (
                                        <div className={getColumnClassnames(snapshot.isDraggingOver)} {...provided.droppableProps} ref={provided.innerRef}>
                                            {autoHideTasks().map((task, i) => (
                                                <WorkspaceCompBoardCard isInDoneColumn={true} setSelectedTask={setSelectedTask} setIsEditingModal={setIsEditingModal} fetchBoard={fetchBoard} key={task._id} index={i} data={task} team={team} completeTask={completeTask} updateTask={updateTask} deleteTask={deleteTask} restoreTask={restoreTask}/>
                                            ))}
                                            {provided.placeholder}
                                            {canFetchMoreTasks() && <div className='workspace-board-column-fetch-tasks-button' onClick={fetchTasksToLimit}>Fetch All Tasks</div>}
                                        </div>
                                    )}
                                </Droppable>
                            ) : (
                                // Not the done column cards
                                <Droppable droppableId={data._id} type="cards">
                                    {(provided, snapshot) => (
                                        <div className={getColumnClassnames(snapshot.isDraggingOver)} {...provided.droppableProps} ref={provided.innerRef}>
                                            {getUnfinishedTasks().map((task, i) => (
                                                <WorkspaceCompBoardCard isInDoneColumn={false} setSelectedTask={setSelectedTask} fetchBoard={fetchBoard} key={task._id} index={i} data={task} team={team} completeTask={completeTask} updateTask={updateTask} deleteTask={deleteTask} restoreTask={restoreTask} setIsEditingModal={setIsEditingModal}/>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            )}
                            {/* End droppable area for cards */}
                            
                {/* Mechanism for displaying the "new column" action */}
                <div className="workspace-board-column-new-column-button-tripwire"></div>
                {!isDoneColumn() && hasPermission(permissions, "component_update") && <div className="workspace-board-column-new-column-button" onClick={(e) => addColumn(data.order)}>+</div>}
                {/* {isDoneColumn() && hasPermission(permissions, "component_update") && <div className="workspace-board-column-new-column-button workspace-board-column-new-column-button-permanent" onClick={(e) => addColumn(getHighestOrder())}>+ New Column</div> } */}
            </div>
            )}
        </Draggable>
    );
}

function ColumnSettingsActionMenu({ sortColumnByParameters, deleteColumn }) {
    const [isShowingSortActionMenu, setIsShowingSortActionMenu] = useState(() => { return false })
    const [isAscending, setIsAscending] = useState(() => { return true })
    const [sortByMethod, setSortByMethod] = useState(() => { return "date" })
    const [view, setView] = useState(() => { return "main" })
    const stateChangeMenuRef = useRef(null)
    const getSortSymbol = (sortName) => {
        if (sortName === sortByMethod) return <IoCheckmarkOutline/>
        return <div style={{minWidth: "16px"}}></div>
    }
    const submitChange = () => {
        try {
            sortColumnByParameters({ is_ascending: isAscending, sort_by: sortByMethod })
            setIsShowingSortActionMenu(false)
            setView("main")
        } catch (error) {
            setIsShowingSortActionMenu(false)
        }
    }
    const submitDeleteRequest = () => {
        setIsShowingSortActionMenu(false)
        deleteColumn()
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (stateChangeMenuRef.current && !stateChangeMenuRef.current.contains(event.target)) {
                setIsShowingSortActionMenu(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [stateChangeMenuRef])
    return (
        <div className="component-list-controls-sort-container" ref={stateChangeMenuRef}>
            <div onClick={() => setIsShowingSortActionMenu(!isShowingSortActionMenu)} className={`component-board-column-options-symbol ${isShowingSortActionMenu ? "component-board-column-options-symbol-active" : ""}`}>
                <IoEllipsisVerticalSharp/>
            </div>
            {isShowingSortActionMenu &&
                <div className="task-state-selector-menu" style={{left: "unset", right: 0}}>
                    {view === "main" &&
                    <div>
                        <div className="task-state-selector-header">Actions</div>
                        <div className="task-state-selector-menu-items">
                            <span onClick={() => setView("sort")}>Sort tasks</span>
                            <span onClick={submitDeleteRequest}>Delete Column</span>
                        </div>
                    </div>
                    }
                    {view === "sort" &&
                    <div>
                        <div className="task-state-selector-header task-state-selector-header-nav">
                            <MdKeyboardArrowLeft onClick={() => setView("main")}/>
                            Sort tasks
                        </div>
                        <div className="task-state-selector-menu-items">
                            <span onClick={() => setSortByMethod("date")}>{getSortSymbol("date")}Sort by Due Date</span>
                            <span onClick={() => setSortByMethod("name")}>{getSortSymbol("name")}Sort Alphabetically</span>
                            <span onClick={() => setSortByMethod("age")}>{getSortSymbol("age")}Sort by Age</span>
                        </div>
                        <div className="task-state-selector-asc-desc">
                            <div onClick={() => setIsAscending(true)} className={`task-state-selector-option ${isAscending ? "task-state-selector-option-selected" : ""}`}><AiOutlineSortAscending/>Ascending</div>
                            <div onClick={() => setIsAscending(false)} className={`task-state-selector-option ${!isAscending ? "task-state-selector-option-selected" : ""}`}><AiOutlineSortDescending/>Descending</div>
                        </div>
                        <div className="task-state-selector-confirmation-buttons">
                            <div onClick={submitChange}>Update</div>
                            <div onClick={() => setIsShowingSortActionMenu(false)}>Cancel</div>
                        </div>
                    </div>
                    }
                </div>
            }
        </div>
    )
}