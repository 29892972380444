import React from 'react'

export default function UserActivityDot({lastLogin, height}) {
    const getSecondsPassed = () => {
        try {
            const currentDate = Date.now()
            const activityDate = new Date(lastLogin)
            return (currentDate - activityDate)/1000
        } catch (error) {
            return -1
        }
    }
    const getStyles = () => {
        if (!lastLogin) return { display: "none" }
        let backgroundColor
        let styledHeight = 14
        if (height) styledHeight = height
        const secondsPassed = getSecondsPassed()
        if (secondsPassed >= (60*5)) backgroundColor = "#FFB901" // inactive
        if (secondsPassed < (60*5)) backgroundColor = "#34a853" // active
        if (secondsPassed < 0) backgroundColor = "grey" // unclear
        return { backgroundColor: backgroundColor, width: styledHeight, minWidth: styledHeight, height: styledHeight }
    }
    return (
        <div className="user-activity-dot" style={getStyles()}></div>
    )
}
