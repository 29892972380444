import React from 'react'
import '../../styles/modals.css';
import ModalSimpleHeader from './simple/ModalSimpleHeader';

export default function ConfirmAction({mainText, onconfirm, hideMe, title}) {
    return (
        <div className="modal-mgmt-background-overlay" style={{zIndex: 200}}>
            <div className="modal-mgmt-container">
                <ModalSimpleHeader title={title ? title : "Confirm action"} hideModal={hideMe} />
                <div className="modal-interaction-confirm-text-main">{mainText}</div>

                <div className="modal-task-create-submission-section" style={{alignItems: "center", flexDirection: "row", gap: "20px"}}>
                    <div className="modal-task-create-button-create" onClick={(e) => onconfirm()}>Confirm</div>
                    <div className="modal-task-create-button-create-save-and-next" onClick={(e) => hideMe()}>Cancel</div>
                </div>  

            </div>
        </div>
    )
}
