import React from 'react'
import '../../styles/gadgets.css'

export default function ToolTip({text, position}) {
    const position2ClassMap = {
        bottom: "gadget-tooltip-text-bottom"
    }
    const getPositionClass = () => {
        if (position2ClassMap[position]) {
            return `gadget-tooltip-text ${position2ClassMap[position]}`
        } else {
            return "gadget-tooltip-text"
        }
    }

    return (
        <div className="gadget-tooltip-container-outer">
            <span className={getPositionClass()}>{text}</span>
        </div>
    )
}
