import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { hasPermission, getUserNameFromId, getUserProfileSrcFromId, getUserDataFromId } from '../../common/Helpers'
import ProfPicCircle from '../profiles/ProfPicCircle'
import { MdClose } from 'react-icons/md'

export default function OwnerSelectorDropdown({permissions, proposedOwnerId, setProposedOwnerId, team}) {
    const [isSelecting, setIsSelecting] = useState(() => { return false })
    const [displayedOwnerId, setDisplayedOwnerId] = useState(() => { return proposedOwnerId })
    const ownerSelectorTeamSelectionBox = useRef()
    const getAdditionalClickableStyles = () => {
        if (hasPermission(permissions, 'component_update')) return {}
        return {
            cursor: "default"
        }
    }
    const handleOwnerChangeAttempt = () => {
        if (!hasPermission(permissions, 'component_update')) return
        if (isSelecting) return
        setIsSelecting(true)
    }
    const handleRemoveOwnerChangeAttempt = () => {
        if (!hasPermission(permissions, 'component_update')) return
        setProposedOwnerId("")
        setDisplayedOwnerId("")
        setIsSelecting(false)
    }
    const getNameData = () => {
        if (!displayedOwnerId || displayedOwnerId === "") return (
            <div style={{color: "grey"}}>Unassigned</div>
        )
        const user = getUserDataFromId(team, displayedOwnerId)
        return (
            <div className="gadget-owner-selector-name-container">
                <div className="gadget-owner-selector-name-selected-name">{user.firstName} {user.lastName}</div>
                <div className="gadget-owner-selector-name-selected-handle">@{user.handle}</div>
            </div>
        )
    }
    const isShowingChangeOwnerSelection = () => {
        if (!hasPermission(permissions, 'component_update')) return false
        return isSelecting
    }
    const changeOwner = (newOwnerId) => {
        setProposedOwnerId(newOwnerId)
        setDisplayedOwnerId(newOwnerId)
        setIsSelecting(false)
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ownerSelectorTeamSelectionBox.current && !ownerSelectorTeamSelectionBox.current.contains(event.target)) {
                setIsSelecting(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [ownerSelectorTeamSelectionBox])
    return (
        <div className="gadget-owner-selector-dropdown-container">
            {!isSelecting &&
                <div className="gadget-owner-selector-clickable-container gadget-owner-selector-height-control" style={getAdditionalClickableStyles()}>
                    <div className="gadget-owner-selector-clickable-container-owner-info" onClick={handleOwnerChangeAttempt}>
                        <ProfPicCircle src={getUserProfileSrcFromId(team, displayedOwnerId)} userId={displayedOwnerId} userName={getUserNameFromId(team, displayedOwnerId)} height={48} disableClick={true}/>
                        {getNameData()}
                    </div>
                    <div className="gadget-owner-selector-clickable-container-remove-owner-button" onClick={handleRemoveOwnerChangeAttempt}>
                        {proposedOwnerId && <MdClose/>}
                    </div>
                </div>
            }

            {isSelecting && 
                <div className="gadget-owner-selector-search-bar gadget-owner-selector-height-control">
                    Select a new owner
                </div>
            }

            {isShowingChangeOwnerSelection() && 
            <div className="gadget-owner-selector-team-select" ref={ownerSelectorTeamSelectionBox}>
                {team.map((teammate) => (
                    <div key={teammate._id} className="gadget-owner-selector-selectable-teammate-container" onClick={() => changeOwner(teammate._id)}>
                        <ProfPicCircle src={getUserProfileSrcFromId(team, teammate._id)} userId={proposedOwnerId} userName={getUserNameFromId(team, teammate._id)} height={32} disableClick={true}/>
                        <div className="gadget-owner-selector-name-container">
                            <div className="gadget-owner-selector-name-selected-name">{teammate.firstName} {teammate.lastName}</div>
                            <div className="gadget-owner-selector-name-selected-handle">@{teammate.handle}</div>
                        </div>
                    </div>
                ))}
            </div>
            }
        </div>

        
    )
}
