import React from 'react'

export default function ModalSimpleNavigationBar({ view, setView, allViews }) {
    const getNavClassName = (existingNavClass) => {
        if (existingNavClass === view) return "modal-simple-nav-bar-nav-sel"
        return ""
    }
    return (
        <div className="modal-simple-nav-bar">
            <div className="modal-simple-nav-bar-nav">
                {allViews.map((subView) => (
                    <span key={subView.name} onClick={() => setView(subView.name)} className={getNavClassName(subView.name)}>{subView.display}</span>
                ))}
            </div>
        </div>
    )
}
