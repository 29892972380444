import React from 'react'

export default function SlideSelector({selectedSlideOption, setSlideOption, slideOptions}) {
    const getAdditionalClass = (slideOptionName) => {
        if (selectedSlideOption === slideOptionName) return "gadget-feed-toolbar-selected"
        return ""
    }
    return (
        <div className="gadget-feed-toolbar">
            {slideOptions.map((slideOption) => (
                <span key={slideOption._id} onClick={() => setSlideOption(slideOption._id)} className={getAdditionalClass(slideOption._id)}>{slideOption.display_name}</span>
            ))}
        </div>
    )
}
