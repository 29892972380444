import React from 'react'
import '../../styles/modals.css';
import { IoClose } from 'react-icons/io5';
import { BsListUl, BsListOl } from 'react-icons/bs'
import { useState, useEffect, useReducer, useRef } from 'react'
import { Editor, EditorState, RichUtils, convertToRaw } from 'draft-js';
import 'draft-js/dist/Draft.css'
import LexicalTextEditor from '../gadgets/LexicalTextEditor';

import BlurProofInput from '../gadgets/BlurProofInput';
import ModalSimpleSingleButton from './simple/ModalSimpleSingleButton';

export default function ModalEditPost({ hideEditPostForm, updateWorkspacePost, post, team }) {
    const [canSubmit, setCanSubmit] = useState(() => { return false })
    const [placeholder, setPlaceholder] = useState(() => { return "Create a new post..." })
    const [isLexical, setIsLexical] = useState(() => { return false })
    const [proposedPost, proposedPostDispatch] = useReducer((state, action) => {
        if (action.type === "init") return { ...state, description_html: action.value.description_html, description_editor: action.value.description_editor }
        if (action.type === "save") {
            const safeHtml = action.value.description_html
            const editorState = action.value.description_editor
            return { ...state, description_editor: editorState, description_html: safeHtml }
        }       
        return state
    }, { description: "", description_editor: false, description_html: false })
    const [postTitle, setPostTitle] = useState(() => {
        if (post) return post.post_title
        return ""
    })
    const [postTextContent, setPostTextContent] = useState(() => { return "" })
    const [editorState, setEditorState] = useState(() => { return EditorState.createEmpty()})
    const postTitleRef = useRef(null);
    const postContent = () => {
        let finalContent = postTextContent
        if (isLexical) finalContent = JSON.stringify(proposedPost)
        updateWorkspacePost({
            post_id: post._id,
            post_title: postTitle,
            rich_text_content: finalContent
        });
        hideEditPostForm();
    }
    const handleKeyCommand = (command) => {
        const newState = RichUtils.handleKeyCommand(editorState, command)
        if (!newState) return 'not-handled'
        setEditorState(newState)
        return 'handled'
    }
    const toggleEditingState = (styleName) => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, styleName))
    }
    const toggleBlockType = (blockType) => {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType))
    }
    const identifyPostGeneration = () => {
        // Determine if post is lexical or draft.js (deprecated)
        try {
            if (!post) return hideEditPostForm()
            let postContent = JSON.parse(post.rich_text_content)
            if (postContent.description_html && postContent.description_editor) {
                proposedPostDispatch({
                    type: "init",
                    value: {
                        description_editor: postContent.description_editor,
                        description_html: postContent.description_html
                    }
                })
                setIsLexical(true)
                return
            }
            proposedPostDispatch({
                type: "init",
                value: {
                    description_editor: false,
                    description_html: false
                }
            })
            setIsLexical(true)
            return
            
            // Draft.js - deprecated
            // setIsLexical(false)
            // const textContent = convertFromRaw(JSON.parse(post.rich_text_content))
            // setEditorState(EditorState.createWithContent(textContent))
            // if (post.post_title) setPostTitle(post.post_title)
        } catch (error) {
            console.log(error)
            return
        }
    }
    const getInitialState = () => {
        try {
            if (!proposedPost.description_editor) return false
            return proposedPost.description_editor
        } catch (error) {
            return false
        }

    }
    const updatePostSubmissionState = () => {
        try {
            let proposedPostContent = ""
            if (!proposedPost.description_html) return setCanSubmit(false)
            proposedPostContent = proposedPost.description_html.replace(/<\/?[^>]+(>|$)/g, '')
            if (proposedPostContent.trim() === "") return setCanSubmit(false)
            return setCanSubmit(true)
        } catch (error) {
            setCanSubmit(false)
        }
    }
    useEffect(() => {
        if (postTextContent !== "") {
            setCanSubmit(true)
        } else {
            setCanSubmit(false)
        }
    // eslint-disable-next-line
    }, [postTextContent])
    useEffect(() => {
        updatePostSubmissionState()
    // eslint-disable-next-line
    }, [proposedPost])
    useEffect(() => {
        if (!editorState) {
            setPostTextContent("")
            return
        }
        if (editorState.getCurrentContent().getPlainText() === "") {
            setPostTextContent("")
            return
        }
        setPostTextContent(JSON.stringify(convertToRaw(editorState.getCurrentContent())))
    // eslint-disable-next-line
    }, [editorState])
    useEffect(() => {
        const selection = editorState.getSelection();
        const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType()
        if (!blockType) {
            setPlaceholder("Create a new post...")
            return
        }
        if (postTextContent) {
            setPlaceholder("")
            return
        }
        if ((blockType === "unordered-list-item" || blockType === "ordered-list-item") && (!postTextContent)) {
            setPlaceholder("")
            return
        }
        setPlaceholder("Create a new post...")
    // eslint-disable-next-line
    }, [editorState])
    useEffect(() => {
        const selection = editorState.getSelection();
        const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey())
        if ((blockType === "unordered-list-item" || blockType === "ordered-list-item")) {
            setPlaceholder("")
            return
        }
        if (!postTextContent || postTextContent === "") {
            setPlaceholder("Create a new post...")
            return
        }
        setPlaceholder("")
    // eslint-disable-next-line
    }, [postTextContent])
    useEffect(() => {
        if (!post) return
        identifyPostGeneration()
    // eslint-disable-next-line
    }, [])
    return (
        <div className="modal-mm-post-outer">
            <div className="modal-mm-post">

                <div className="modal-mm-post-header-row">
                    <h2>Edit Post</h2>
                    <IoClose onClick={hideEditPostForm}/>
                </div>

                <div className="modal-mm-text-title-container">
                    <BlurProofInput inputRef={postTitleRef}>
                        <input type="text" ref={postTitleRef} placeholder="Subject (optional)" maxLength={300} value={postTitle} onChange={(e) => setPostTitle(e.target.value)} />
                    </BlurProofInput>
                </div>

                {!isLexical &&
                <>
                <div className="project-post-form-rich-text-controls">
                    <div className="project-post-form-rich-text-control" onClick={() => toggleEditingState('BOLD')}><strong>B</strong></div>
                    <div className="project-post-form-rich-text-control" onClick={() => toggleEditingState('ITALIC')}><em>i</em></div>
                    <div className="project-post-form-rich-text-control" onClick={() => toggleEditingState('UNDERLINE')} style={{textDecoration: "underline"}}>U</div>
                    <div className="project-post-form-rich-text-control" onClick={() => toggleBlockType('ordered-list-item')}><BsListOl/></div>
                    <div className="project-post-form-rich-text-control" onClick={() => toggleBlockType('unordered-list-item')}><BsListUl/></div>
                </div>

                <div className="project-post-form-rich-text-editor">
                    {placeholder !== "" && <div className="project-post-form-rich-text-editor-placeholder">{placeholder}</div> }
                    <Editor editorState={editorState} onChange={setEditorState} handleKeyCommand={handleKeyCommand}/>
                </div>
                </>
                }

                {isLexical &&
                <div style={{position: "relative"}}>
                    <LexicalTextEditor team={team} isUpgrade={false} getInitialState={getInitialState} mainDescriptionDispatch={proposedPostDispatch} legacyDescription={false} />
                </div>
                }

                <div className="modal-mm-buttons util-row util-align-center util-row-reverse" style={{gap: "14px"}}>
                    <ModalSimpleSingleButton isValid={canSubmit} buttonText="Save" onclick={postContent} />
                    <ModalSimpleSingleButton invertStyle={true} isValid={true} buttonText={"Cancel"} onclick={hideEditPostForm} />
                </div>                
                
            </div>
        </div>
    )
}
