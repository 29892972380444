import * as React from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useState } from "react";

import { $isRangeSelection, $getSelection } from 'lexical';
import { MdFormatListBulleted, MdFormatListNumbered, MdOutlineFormatBold, MdOutlineFormatItalic, MdOutlineFormatUnderlined } from 'react-icons/md'
import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND } from "@lexical/list";

export default function LexicalListToolbarPlugin() {
  const [editor] = useLexicalComposerContext();
  const [blockType, setBlockType] = useState(() => { return "paragraph" })
  const formatList = (listType) => {
    if (listType === "number" && blockType !== "number") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
      setBlockType("number");
    } else if (listType === "bullet" && blockType !== "bullet") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
      setBlockType("bullet");
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      setBlockType("paragraph");
    }
  };

  // GUIDE:
  // 0 - no formatting
  // 1 - bold
  // 2 - italics
  // 3 - bold and italic
  // 8 - underline
  // 11 - bold, italic, and underline

  const toggleBold = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const nodes = selection.getNodes();
        nodes.forEach(node => {
          if (node.getType() === 'text') {
            let proposedFormat = 1
            let currentFormat = node.getFormat();
            if (currentFormat === 1) proposedFormat = 0
            if (currentFormat === 2) proposedFormat = 3
            if (currentFormat === 3) proposedFormat = 2 
            if (currentFormat === 8) proposedFormat = 9
            if (currentFormat === 9) proposedFormat = 8
            if (currentFormat === 10) proposedFormat = 11
            if (currentFormat === 11) proposedFormat = 10
            node.setFormat(proposedFormat)
          }
        });
      }
    });
  };

  const toggleItalic = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const nodes = selection.getNodes();
        nodes.forEach(node => {
          if (node.getType() === 'text') {
            let proposedFormat = 2
            let currentFormat = node.getFormat();
            if (currentFormat === 1) proposedFormat = 3
            if (currentFormat === 2) proposedFormat = 0
            if (currentFormat === 3) proposedFormat = 1
            if (currentFormat === 8) proposedFormat = 10
            if (currentFormat === 9) proposedFormat = 11
            if (currentFormat === 10) proposedFormat = 8
            if (currentFormat === 11) proposedFormat = 9
            node.setFormat(proposedFormat)
          }
        });
      }
    });
  };

  const toggleUnderline = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const nodes = selection.getNodes();
        nodes.forEach(node => {
          if (node.getType() === 'text') {
            let proposedFormat = 8
            let currentFormat = node.getFormat();
            if (currentFormat === 1) proposedFormat = 9
            if (currentFormat === 2) proposedFormat = 10
            if (currentFormat === 3) proposedFormat = 11
            if (currentFormat === 11) proposedFormat = 3
            if (currentFormat === 10) proposedFormat = 2
            if (currentFormat === 9) proposedFormat = 1
            if (currentFormat === 8) proposedFormat = 0
            node.setFormat(proposedFormat)
          }
        });
      }
    });
  };

  return (
    <div className="gadget-lexical-toolbar">
      <div onClick={toggleBold}>
        <MdOutlineFormatBold/>
      </div>
      <div onClick={toggleItalic}>
        <MdOutlineFormatItalic/>
      </div>
      <div onClick={toggleUnderline}>
        <MdOutlineFormatUnderlined/>
      </div>
      <div onClick={() => formatList("bullet")}>
        <MdFormatListBulleted/>
      </div>
      <div onClick={() => formatList("number")}>
        <MdFormatListNumbered/>
      </div>
    </div>
  );
}
