import { Link, useHistory, useLocation } from "react-router-dom";
import Api from "../common/APIUtils"
import { useState, useEffect } from 'react'
import '../styles/forms.css';

export default function PasswordReset() {
    const api = new Api();
    const appName = process.env.REACT_APP_APP_NAME;
    let history = useHistory();

    const query = new URLSearchParams(useLocation().search);
    const email = query.get("email");
    const token = query.get("token");

    const [newPassword, setNewPassword] = useState(() => {
        return "";
    });

    const [newPasswordCheck, setNewPasswordCheck] = useState(() => {
        return "";
    });

    const [passwordSubmitted, setPasswordSubmitted] = useState(() => {
        return false;
    })

    const [passwordsMatch, setPasswordsMatch] = useState(() => {
        return false;
    })

    // Check if the passwords are good to go
    useEffect(() => {
        if (newPassword === newPasswordCheck) {
            setPasswordsMatch(true)
        } else {
            setPasswordsMatch(false)
        }
        // eslint-disable-next-line
    }, [newPassword, newPasswordCheck])

    // TODO: Check when this page loads if the email and token are valid from the get-go and don't make user jump through hoops if they aren't
    // TODO: Style the submit button to indicate the passwords match or don't

    const resetPassword = (e) => {
        e.preventDefault();
        if (passwordsMatch) {
            setPasswordSubmitted(true)
            const payload = {
                email: email.toLowerCase(),
                token: token,
                password: newPassword
            }
            api.passwordChange(payload)
            .then( (res) => {
                console.log(res)
                history.push('/login');
            })
            .catch((err) => {
                console.log(err);
                alert('Something went wrong');
            });;
        } else {
            alert('Passwords do not match')
        }
    }

    return (
        <div className="form-container-centered">
            <h4 className="login-application-name">{appName}</h4>
            {passwordSubmitted ? (
                <div>Success!</div>
            ) : (
                <form onSubmit={ resetPassword }>
                    <input type="password" htmlFor="newPassword" placeholder="Create a new password" onChange={ (e) => setNewPassword(e.target.value) } />
                    <input type="password" htmlFor="newPasswordCheck" placeholder="Type your password again" onChange={ (e) => setNewPasswordCheck(e.target.value) } />
                    <input type="submit" value="Update Password"/>
                </form>
            )}

            <div className="form-text-link">
                <Link className="unlink" to='/login'>Log In</Link>
            </div>
        </div>
    )
}
