import React from 'react'
import '../../styles/modals.css';
import { FaTimes, FaRegTrashAlt, FaReply, FaPlus } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import Api from '../../common/APIUtils';
import { useHistory } from 'react-router-dom';
import { MdOpenInNew, MdCheck } from 'react-icons/md';
import ComponentCriteria from '../components/sidepanes/ComponentCriteria';

export default function ModalUpdateListTask({task, setShowEditingModal, team, updateTask, completeTask, deleteTask, restoreTask}) {
    const api = new Api();
    const history = useHistory();
    // STATE VARIABLES
    const [proposedDescription, setProposedDescription] = useState(() => {
        return task.attributes.description;
    })
    const [proposedOwner, setProposedOwner] = useState(() => {
        return task.owner_id || '';
    })
    const [proposedDetails, setProposedDetails] = useState(() => {
        return task.attributes.details;
    })
    const [proposedDueDate, setProposedDueDate] = useState(() => {
        return task.attributes.date_due || '';
    })
    const [proposedParentId, setProposedParentId] = useState(() => {
        return task.attributes.parent || '';
    })
    const [canSave, setCanSave] = useState(() => {
        return true;
    })
    const [editTaskPayload, setEditTaskPayload] = useState(() => {
        return {};
    })
    const [moveToLists, setMoveToLists] = useState(() => {
        return []
    })
    const [criteria, setCriteria] = useState(() => {
        return []
    })
    const [proposedCriteria, setProposedCriteria] = useState(() => {
        return ""
    })
    const [tab, setTab] = useState(() => {
        return "details"
    });
    const directToTaskPage = () => {
        history.push(`/workspaces/${task.workspace_id}/c/${task._id}`)
    }
    const fetchPotentialParentLists = () => {
        try {
            const params = {
                name: 'list',
                workspaceId: task.workspace_id
            }
            api.getComponentsByType(params)
            .then((res) => {
                console.log(res);
                if (res.data.response.components) {
                    setMoveToLists(res.data.response.components); // replacement for the tasks - includes positions
                } else {
                    setMoveToLists([])
                }
            })
            .catch((err) => {
                console.log(err)
            }) 
        } catch (error) {
            console.log('Error fetching parent lists');
        }

    }
    const getTaskCriteria = () => {
        const payload = {
            componentId: task._id
        }
        api.getWorkspaceComponent(payload)
        .then((res) => {
            if (res.data.response) {
                setCriteria(res.data.response.component.criteria)
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }
    const updateCriteriaDescription = (criteriaId, description) => {
        const payload = {
            criteria_id: criteriaId,
            description: description
        }
        api.updateComponentCriteria(payload)
        .then((res) => {
            getTaskCriteria();
            updateTask();
        })
        .catch((err) => {
            console.log(err)
        })
    }
    const updateCriteriaStatus = (criteriaId, status) => {
        const payload = {
            criteria_id: criteriaId,
            status: status
        }
        api.updateComponentCriteria(payload)
        .then((res) => {
            getTaskCriteria();
            updateTask();
        })
        .catch((err) => {
            console.log(err)
        })
    }
    const deleteCriteria = (criteriaId) => {
        const payload = {
            criteria_id: criteriaId
        }
        api.deleteComponentCriteria(payload)
        .then((res) => {
            getTaskCriteria();
            updateTask();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const handleNewCriteriaPress = (e) => {
        if (e.key === 'Enter') {
            if (proposedCriteria) {
                createCriteria()
            }
        }
    }
    const createCriteria = () => {
        const payload = {
            description: proposedCriteria,
            component_id: task._id
        }
        setProposedCriteria("")
        api.addComponentCriteria(payload)
        .then((res) => {
            getTaskCriteria();
            updateTask();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const submitNewChanges = () => {
        if (canSave) {
            setShowEditingModal(false);
            updateTask(editTaskPayload);    
        }
    }
    const getSelectedTabClass = (tabName) => {
        if (tabName === tab) {
            return "modal-side-edit-tab-row-item modal-side-edit-tab-row-item-selected"
        } else {
            return "modal-side-edit-tab-row-item"
        }
    }
    // HELPER FUNCTIONS
    useEffect(() => {
        if (proposedDescription === "") {
            setCanSave(false);
        } else {
            setCanSave(true);
        }
    }, [proposedDescription]);

    useEffect(() => {
        fetchPotentialParentLists()
        getTaskCriteria()
    // eslint-disable-next-line
    }, [task])

    useEffect(() => {
        const updatedTaskInfo = {
            owner_id: proposedOwner,
            display_name: proposedDescription,
            attributes: {
                date_due: proposedDueDate,
                details: proposedDetails,
                description: proposedDescription,
                parent: proposedParentId
            }
        }
        setEditTaskPayload(updatedTaskInfo);
    // eslint-disable-next-line
    }, [proposedOwner, proposedDueDate, proposedDetails, proposedDescription, proposedParentId])

    return (
        <div className="alignment-modal-background">
                <div className="modal-task-create-container">
                    {/* Header */}
                    <div className="modal-task-create-header-row">
                        <div className="modal-task-create-header-title">Edit task</div>
                        <div className="modal-side-edit-header-row" style={{borderBottom: "none"}}>
                            {task.attributes.active ? <MdCheck onClick={(e) => completeTask(task)} /> : <FaReply onClick={(e) => restoreTask(task)}/> }
                            <MdOpenInNew onClick={directToTaskPage}/>
                            <FaRegTrashAlt onClick={() => deleteTask(task._id)}/>
                            <FaTimes className="project-post-form-cancel" onClick={(e) => setShowEditingModal(false)}/>
                        </div>
                    </div>
                    {/* Name */}
                    <div className="modal-workspace-component-edit-section">
                        <h4>Name</h4>
                        <input type="text" value={proposedDescription} placeholder="What do you want to accomplish?" onChange={(e) => setProposedDescription(e.target.value)}/>
                    </div>
                    {/* Date and Owner */}
                    <div className="modal-workspace-component-edit-section modal-workspace-component-edit-section-doublewide">
                        <div className="modal-workspace-component-edit-subsection">
                            <h4>Due Date</h4>
                            <input type="date" value={proposedDueDate} onChange={(e) => setProposedDueDate(e.target.value)}/>
                        </div>
                        <div className="modal-workspace-component-edit-subsection">
                            <h4>Owner</h4>
                            <select name="owner" value={proposedOwner} onChange={(e) => setProposedOwner(e.target.value)}>
                                <option value="">None</option>
                                {team.map((teammate) => (
                                    <option key={teammate._id} value={teammate._id}>{teammate.firstName} {teammate.lastName}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {/* Tab Row */}
                    <div className="modal-side-edit-tab-row">
                        <span onClick={() => setTab('details')} className={getSelectedTabClass('details')}>Details</span>
                        <span onClick={() => setTab('subtasks')} className={getSelectedTabClass('subtasks')}>Subtasks</span>
                    </div>
                    {/* Description Section */}
                    {tab === 'details' && (
                        <div className="modal-workspace-component-edit-section">
                            <textarea value={proposedDetails} placeholder="Add more information about the task." onChange={(e) => setProposedDetails(e.target.value)}></textarea>
                        </div>
                    )}

                    {/* Subtask Section */}
                    {tab === 'subtasks' && (
                        <div className="modal-workspace-component-edit-section">
                            <div className="criteria-item-new-item-container" style={{marginTop: "0px"}}>
                                <FaPlus/>
                                <input type="text" autoFocus={true} placeholder="Add new subtask" value={proposedCriteria} onKeyPress={(e) => handleNewCriteriaPress(e)} onChange={(e) => setProposedCriteria(e.target.value)}/>
                            </div>
                            <div className="criteria-item-list">
                                {criteria.length > 0 ? (
                                    <>
                                    {criteria.map((c) => (
                                        <ComponentCriteria updateCriteriaDescription={updateCriteriaDescription} deleteCriteria={deleteCriteria} updateCriteriaStatus={updateCriteriaStatus} key={c._id} data={c} />
                                    ))}
                                    </>
                                ) : (
                                    <div style={{fontSize: "14px", color: "grey"}}>Your subtasks will appear here when you have any</div>
                                )}

                            </div>
                        </div>
                    )}
                    {/* Parent */}
                    <div className="modal-workspace-component-edit-section">
                        <h4>Move task to another list</h4>
                        {moveToLists.length > 0 ? (
                            <select value={proposedParentId} onChange={(e) => setProposedParentId(e.target.value)} name="parent">
                                {moveToLists.map((listItem) => (
                                    <option value={listItem._id} key={listItem._id}>{listItem.display_name}</option>
                                ))}
                            </select>
                        ) : (
                            <div className="modal-task-select-loading"></div>
                        )}
                    </div>

                    <div className="modal-task-edit-submission-section">
                        <div></div>
                        <div className="modal-task-edit-submission-section">
                            <div className="modal-task-create-cancel" onClick={(e) => setShowEditingModal(false)}>Cancel</div>
                            <div className="modal-task-create-submit" onClick={(e) => submitNewChanges()}>Save</div>
                        </div>
                    </div>               
                </div>
        </div>
    )
}
