import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom'
import ConfirmAction from '../modals/ConfirmAction';
import Api from '../../common/APIUtils';
import { isValidEmailAddress, validateUrl } from '../../common/Helpers';
import ToggleSwitch from '../gadgets/ToggleSwitch'
import AccountBilling from '../accounts/AccountBilling';

export default function UserSettings({userData, refreshUserData, userSettings, userSettingsDispatch}) {
    const api = new Api()
    const query = new URLSearchParams(useLocation().search);
    const requestedPage = query.get("v");
    const [page, setPage] = useState(() => { return "profile" })
    const [errorText, setErrorText] = useState(() => { return "" })
    const [isShowingChangePasswordFields, setIsShowingChangePasswordFields] = useState(() => { return "" })
    const [currentPassword, setCurrentPassword] = useState(() => { return "" })
    const [proposedPassword, setProposedPassword] = useState(() => { return "" })
    const [proposedPasswordCheck, setProposedPasswordCheck] = useState(() => { return "" })
    const [apiKey, setApiKey] = useState(() => { return "" })
    const [showApiKey, setShowApiKey] = useState(() => { return false })
    const [isRegeneratingApiKey, setIsRegeneratingApiKey] = useState(() => { return false })
    const [isShowingDeleteAccount, setIsShowingDeleteAccount] = useState(() => { return false })
    const [hasUpdates, setHasUpdates] = useState(() => { return false })
    const [headline, setHeadline] = useState(() => { return userData.headline })
    const [biography, setBiography] = useState(() => { return userData.biography })
    const [website, setWebsite] = useState(() => { return userData.website })
    const [emailAddress, setEmailAddress] = useState(() => { return userData.emailAddress })
    const [firstName, setFirstName] = useState(() => { return userData.firstName })
    const [lastName, setLastName] = useState(() => { return userData.lastName })
    const [proposedHandle, setProposedHandle] = useState(() => { return userData.handle })
    const [proposedHandleIsValid, setProposedHandleIsValid] = useState(() => { return false })
    const [handleFeedback, setHandleFeedback] = useState(() => { return "" })
    const titleLastUpdatedTimestamp = useRef(false);
    const hideConfirmMenu = () => {
        setIsShowingDeleteAccount(false)
    }
    const deleteAccount = () => {
        hideConfirmMenu()
        console.log('Deleting!')
    }
    const getApiKeyDisplayStyle = () => {
        if (showApiKey) return {
            backgroundColor: "white",
            color: "black",
            fontSize: "1em",
            paddingLeft: "0px",
            fontFamily: "monospace"
        }
        return {}
    }
    const getApiKeyDisplay = () => {
        if (showApiKey && !apiKey) return "Click 'Regenerate' to create your API key"
        if (showApiKey && apiKey) return apiKey
        if (!showApiKey) return "Hidden"
        return ""
    }
    const copyKeyToClipboard = () => {
        navigator.clipboard.writeText(apiKey);
    }
    const regenerateApiKey = () => {
        setIsRegeneratingApiKey(true)
        api.regenerateApiKey()
        .then((res) => {
            if (res.data) {
                if (res.data.response) {
                    if (res.data.response.key) setApiKey(res.data.response.key)
                }
            }
        })
        .catch((err) => {
            console.log(err)
        })
        .finally(() => {
            setIsRegeneratingApiKey(false)
        })
    }
    const checkHandleAvailability = () => {
        if (proposedHandle === userData.handle) return
        if (proposedHandle === "") {
            setHandleFeedback(`You'll be stuck with @${userData.handle} if you leave this blank`)
            return
        }
        setHandleFeedback("Checking availability...")
        api.checkHandleAvailability(proposedHandle)
        .then((res) => {
            setProposedHandleIsValid(res.data)
            if (res.data === true) setHandleFeedback(<span style={{color: "green"}}>{`@${proposedHandle} is available.  Save changes to claim!`}</span>)
            if (!res.data) setHandleFeedback(<span style={{color: "red"}}>{`@${proposedHandle} is not available!`}</span>)
        })
        .catch((err) => {
            setProposedHandleIsValid(false)
            setHandleFeedback("Handle can only contain letters, numbers, periods (.), dashes (-), and underscores (_)")
        })
    }
    const updateUserSettings = () => {
        if (!hasUpdates) return
        let payload = {}
        if (proposedHandle !== userData.handle && proposedHandleIsValid) {
            payload['handle'] = proposedHandle
            setHandleFeedback("Saving...")
        }
        if (biography !== userData.biography) payload['biography'] = biography
        if (website !== userData.website && validateUrl(website)) payload['website'] = website
        if (headline !== userData.headline) payload['headline'] = headline
        if (firstName !== userData.firstName && firstName !== "") payload['firstName'] = firstName
        if (lastName !== userData.lastName && lastName !== "") payload['lastName'] = lastName
        if (emailAddress !== userData.emailAddress && emailAddress !== "" && isValidEmailAddress(emailAddress)) payload['emailAddress'] = emailAddress
        api.updateUserProfile(payload)
        .then((res) => {
            if (res.data.response.user[0].emailAddress !== emailAddress) setErrorText(`Email ${emailAddress} unavailable`)
            setEmailAddress(res.data.response.user[0].emailAddress)
            setFirstName(res.data.response.user[0].firstName)
            setLastName(res.data.response.user[0].lastName)
            setProposedHandle(res.data.response.user[0].handle)
            if (res.data.response.user[0].handle === proposedHandle) {
                setHandleFeedback("Saved!")
                localStorage.setItem("gHandle", proposedHandle)
            }
            if (res.data.response.user[0].handle !== proposedHandle) setHandleFeedback(`Your handle is @${res.data.response.user[0].handle}`)
            setHasUpdates(false)
        })
        .catch((err) => {
            console.log(err)
            setErrorText('Something went wrong')
        })
        setHasUpdates(false)
        refreshUserData()
    }
    const canChangePassword = () => {
        if (currentPassword && (proposedPassword === proposedPasswordCheck) && (proposedPassword !== "") && (proposedPassword.length > 3)) return true
    }
    const resetPassword = () => {
        if (currentPassword && (proposedPassword === proposedPasswordCheck) && (proposedPassword !== "") && (proposedPassword.length > 3)) {
            api.passwordChangeAuth({
                old_password: currentPassword,
                new_password: proposedPassword
            })
            .then((res) => {
                setIsShowingChangePasswordFields(false)
            })
            .catch((err) => {
                console.log(err)
                setErrorText('Something went wrong trying to change the password')
            })
        }
    }
    const revertUserSettings = () => {
        setEmailAddress(userData.emailAddress)
        setFirstName(userData.firstName)
        setLastName(userData.lastName)
    }
    const getLicenseTier = () => {
        if (!userData) return 'Loading...'
        if (!userData.license_tier) return 'Basic'
        return userData.license_tier
    }
    const getLicenseStatus = () => {
        if (!userData) return 'Loading...'
        if (userData.active) return 'Active'
        return 'Inactive'
    }
    const getLicenseStatusStyle = () => {
        if (!userData) return {}
        if (!userData.license_status) return {}
        if (userData.license_status === 'suspended') return {backgroundColor: "rgb(181, 40, 40)"}
    }
    const getNetworkUserNavClassName = (navName) => {
        if (navName === page) return "network-users-view-navigation-selected"
        return ""
    }
    const updateUserSetting = (settingName, settingValue) => {
        userSettingsDispatch({type: "update", name: settingName, value: settingValue })
        // Update Express
        let payload = {}
        payload[settingName] = settingValue
        api.updateUserSettings(payload)
        .then((res) => {
            return
        })
        .catch((err) => {
            return
        })
    }
    useEffect(() => {
        if (biography !== userData.biography) {
            setHasUpdates(true)
            return
        }
        if (website !== userData.website) {
            setHasUpdates(true)
            return
        }
        if (headline !== userData.headline) {
            setHasUpdates(true)
            return
        }
        if (emailAddress !== userData.emailAddress && emailAddress !== "") {
            setHasUpdates(true)
            return
        }
        if (firstName !== userData.firstName && firstName !== "") {
            setHasUpdates(true)
            return
        }
        if (lastName !== userData.lastName && lastName !== "") {
            setHasUpdates(true)
            return
        }
        if (proposedHandle !== userData.handle && proposedHandleIsValid) {
            setHasUpdates(true)
            return
        }
        setHasUpdates(false)
    // eslint-disable-next-line
    }, [emailAddress, firstName, lastName, proposedHandle, proposedHandleIsValid, headline, website, biography])
    useEffect(() => {
        if (userData.api_key) setApiKey(userData.api_key)
    }, [userData])
    useEffect(() => {
        if (['profile', 'account', 'pro', 'application'].includes(requestedPage)) setPage(requestedPage)
    }, [requestedPage])
    useEffect(() => {
        setHandleFeedback("")
        if (proposedHandle === "") setProposedHandleIsValid(false)
        titleLastUpdatedTimestamp.current = Date.now()
        const timer = setTimeout(() => {
            if (Date.now() - titleLastUpdatedTimestamp.current < 900) return
            checkHandleAvailability()
        }, 1000)
        return () => clearTimeout(timer)
    // eslint-disable-next-line
    }, [proposedHandle])
    useEffect(() => {
        try {
            var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + `?v=${page}`;
            window.history.pushState({path:newurl},'',newurl);
        } catch (error) {
            return
        }
    }, [page])
    return (
        <div className="network-page" style={{marginTop: "48px"}}>
            {isShowingDeleteAccount && <ConfirmAction mainText="Are you sure you want to leave Tetheros?  You will be removed from all workspaces, networks, and assignments." onconfirm={deleteAccount} hideMe={hideConfirmMenu} />}

            <h1>Settings</h1>

            <div className="network-users-view-navigation">
                <span onClick={() => setPage("profile")} className={getNetworkUserNavClassName("profile")}>Profile</span>
                <span onClick={() => setPage("application")} className={getNetworkUserNavClassName("application")}>Application</span>
                <span onClick={() => setPage("account")} className={getNetworkUserNavClassName("account")}>Account</span>
                <span onClick={() => setPage("pro")} className={getNetworkUserNavClassName("pro")}>Plan</span>
            </div>
            {errorText && <p style={{color: "red"}}>{errorText}</p>}

            {/* Your Profile */}
            {page === "profile" &&
            <div>
                <div className="network-settings-item">
                    <div className="network-settings-item-text">
                        <h2>Name</h2>
                        <p>How do you want to be known?</p>
                    </div>
                    <div className="network-settings-item-inputs">
                        <input className="network-settings-item-input-text" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="What is your first name?" type="text" />
                        <input className="network-settings-item-input-text" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="What is your last name?" type="text" />
                    </div>
                </div>

                <div className="network-settings-item">
                    <div className="network-settings-item-text">
                        <h2>Handle</h2>
                        <p>Your taggable identity in Tetheros</p>
                    </div>
                    <div className="network-settings-item-input" style={{position: "relative"}}>
                        <div className="network-settings-item-input network-settings-item-input-row">
                            @
                            <input className="network-settings-item-input-text" value={proposedHandle} onChange={(e) => setProposedHandle(e.target.value)} placeholder="Enter your desired handle" type="text" />
                        </div>
                        <div className="network-settings-item-input-feedback-text">{handleFeedback}</div>
                    </div>
                </div>

                <div className="network-settings-item">
                    <div className="network-settings-item-text">
                        <h2>Preferred Title</h2>
                        <p>What are you?</p>
                    </div>
                    <div className="network-settings-item-input">
                        <input className="network-settings-item-input-text" value={headline} onChange={(e) => setHeadline(e.target.value)} placeholder="e.g. Marketer, CEO, Hobbyist" type="text" maxLength={60} />
                    </div>
                </div>

                <div className="network-settings-item">
                    <div className="network-settings-item-text">
                        <h2>Website</h2>
                        <p>Invalid URLs will not be saved</p>
                    </div>
                    <div className="network-settings-item-input">
                        <input className="network-settings-item-input-text" value={website} onChange={(e) => setWebsite(e.target.value)} placeholder="Enter your website" type="text" maxLength={80} />
                    </div>
                </div>

                <div className="network-settings-item">
                    <div className="network-settings-item-text">
                        <h2>About</h2>
                    </div>
                    <div className="network-settings-item-input">
                        <textarea maxLength={2000} placeholder="Enter some information about yourself" value={biography} onChange={(e) => setBiography(e.target.value)}></textarea>
                    </div>
                </div>
            </div>
            }

            {/* Account Information */}
            {page === "account" &&
            <div>
                <div className="network-settings-item">
                    <div className="network-settings-item-text">
                        <h2>Email Address</h2>
                        <p>How do you want to log in and receive notifications?</p>
                    </div>
                    <div className="network-settings-item-input">
                        <input className="network-settings-item-input-text" value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} placeholder="What is your email address?" type="text" />
                    </div>
                </div>

                <div className="network-settings-item">
                    <div className="network-settings-item-text">
                        <h2>Reset Password</h2>
                        <p> </p>
                    </div>
                    <div className="network-settings-item-inputs">
                        {isShowingChangePasswordFields && (
                            <>
                            <input className="network-settings-item-input-text" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} placeholder="Current password" type="password" />
                            <input className="network-settings-item-input-text" value={proposedPassword} onChange={(e) => setProposedPassword(e.target.value)} placeholder="Enter new password" type="password" />
                            <input className="network-settings-item-input-text" value={proposedPasswordCheck} onChange={(e) => setProposedPasswordCheck(e.target.value)} placeholder="Enter new password again" type="password" />
                            </>
                        )}
                        
                        <div>
                            {(isShowingChangePasswordFields && canChangePassword()) && <span className="user-settings-api-key-button" onClick={() => resetPassword()}>Change Password</span>}
                            {!isShowingChangePasswordFields && <span className="user-settings-api-key-button" onClick={() => setIsShowingChangePasswordFields(true)}>Click to Update Password</span>}
                        </div>
                    </div>
                </div>

                <div className="network-settings-item">
                    <div className="network-settings-item-text">
                        <h2>API Key</h2>
                        <p>Interact with the Tetheros REST API</p>
                    </div>
                    <div className="network-settings-item-inputs">
                        <div className="user-settings-api-key" style={getApiKeyDisplayStyle()}>
                            {getApiKeyDisplay()}
                        </div>
                        <div>
                            {showApiKey ? (
                                <>
                                <span className="user-settings-api-key-button" onClick={() => setShowApiKey(false)}>Hide</span>
                                {apiKey && <span className="user-settings-api-key-button" onClick={copyKeyToClipboard}>Copy</span>}
                                {!isRegeneratingApiKey && <span className="user-settings-api-key-button" onClick={regenerateApiKey}>Regenerate</span>}
                                </>
                            ) : (
                                <span className="user-settings-api-key-button" onClick={() => setShowApiKey(true)}>Show</span>
                            )}
                            
                        </div>
                    </div>
                </div>

                <div className="network-settings-item">
                    <div className="network-settings-item-text">
                        <h2>License</h2>
                    </div>
                    <div className="network-settings-item-input">
                        <div style={{textTransform: "capitalize"}}>{getLicenseTier()}<span className="user-settings-license-status" style={getLicenseStatusStyle()}>{getLicenseStatus()}</span></div>
                    </div>
                </div>
            </div>
            }

            {/* Notifications */}
            {page === "application" &&
            <div style={{marginTop: "14px"}}>
                <div className="network-settings-item network-settings-item-toggles">
                    <div className="network-settings-item-text">
                        <h2>General</h2>
                        {/* <p>Select any events you would like to receive an email for</p> */}
                    </div>
                    <div className="network-settings-item-nested-list-outer">
                        <div className="network-settings-item-nested-list-item">
                            <div className="network-settings-item-nested-list-item-toggle"><ToggleSwitch isChecked={userSettings.tasks_auto_assign_owner} setIsChecked={(settingIsChecked) => updateUserSetting("tasks_auto_assign_owner", settingIsChecked)} /></div>
                            <div className="network-settings-item-nested-list-item-title">Assign new tasks you create to yourself if no owner is selected</div>
                        </div>
                    </div>
                </div>
                <div className="network-settings-item network-settings-item-toggles">
                    <div className="network-settings-item-text">
                        <h2>Email Notifications</h2>
                        <p>Select any events you would like to receive an email for</p>
                    </div>
                    <div className="network-settings-item-nested-list-outer">
                        <div className="network-settings-item-nested-list-item">
                            <div className="network-settings-item-nested-list-item-toggle"><ToggleSwitch isChecked={userSettings.email_new_assignments} setIsChecked={(settingIsChecked) => updateUserSetting("email_new_assignments", settingIsChecked)} /></div>
                            <div className="network-settings-item-nested-list-item-title">Someone assigns a task to you</div>
                        </div>
                        <div className="network-settings-item-nested-list-item">
                            <div className="network-settings-item-nested-list-item-toggle"><ToggleSwitch isChecked={userSettings.email_new_mentions} setIsChecked={(settingIsChecked) => updateUserSetting("email_new_mentions", settingIsChecked)} /></div>
                            <div className="network-settings-item-nested-list-item-title">Someone mentions you</div>
                        </div>

                        {/* <div className="network-settings-item-nested-list-item">
                            <div className="network-settings-item-nested-list-item-toggle"><ToggleSwitch isChecked={true} setIsChecked={() => { return false }} /></div>
                            <div className="network-settings-item-nested-list-item-title">Someone unassigns a task from you</div>
                        </div>

                        <div className="network-settings-item-nested-list-item">
                            <div className="network-settings-item-nested-list-item-toggle"><ToggleSwitch isChecked={true} setIsChecked={() => { return false }} /></div>
                            <div className="network-settings-item-nested-list-item-title">Someone else marks your task complete</div>
                        </div> */}
                    </div>
                </div>
            </div>
            }

            {page === "pro" && <AccountBilling userData={userData} /> }

            {page !== "pro" && page !== "application" &&
            <div className="network-settings-save-discard-section">
                <div className="network-settings-button network-settings-discard" onClick={revertUserSettings}>Revert Settings</div>
                {hasUpdates && <div className="network-settings-button network-settings-save" onClick={updateUserSettings}>Save Changes</div>}
            </div>
            }

        </div>
    )
}
