import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import '../../styles/blog.css';
import contentfulClient from '../../common/ContentfulApi'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import UnauthenticatedHeader from '../header/UnauthenticatedHeader'
import LandingFooter from '../landing/LandingFooter';
import LoadingSymbolFacebook from '../gadgets/LoadingSymbolFacebook';
import { updateHeadTags, updateSchemaOrgJSONLD } from '../../common/Helpers';

import { format, parseISO } from 'date-fns'

export default function BlogPost() {
    let history = useHistory();
    const { slug } = useParams();
    const [loaded, setLoaded] = useState(() => { return false })
    const [post, setPost] = useState(() => { return []})
    const fetchPost = () => {
        if (!slug) return
        try {
            contentfulClient.getEntries({
                content_type: "blogPost",
                'fields.urlSlug': slug,
                limit: 1
            }).then((response) => {
                if (response.items.length === 0) return history.push('/blog')
                setPost(response.items[0].fields)
                setLoaded(true)
                // document.title = response.items[0].fields.contentTitle
            })
            
        } catch (error) {
            
        }
    }
    const options = {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const { file, title, description } = node.data.target.fields;
                const imageUrl = file.url;
                return `<a href="${imageUrl}" target="_blank" rel="noopener noreferrer"><img src="${imageUrl}" alt="${description || title}" /></a>`
            },
            [INLINES.HYPERLINK]: (node, next) => `<a href="${node.data.uri}" target="_blank" rel="noopener noreferrer">${next(node.content)}</a>`
        }
    };
    const updateMeta = () => {
        if (!post) return
        if (!post.contentTitle) return
        let title = post.contentTitle
        let metaTags = [
            { name: 'description', content: post.tagline || 'No description provided.' },
            { name: 'author', content: post.authorName || 'Tetheros' },
            { name: 'og:title', content: post.contentTitle },
            { name: 'og:description', content: post.tagline || 'No description provided.' },
            { name: 'og:type', content: 'article' },
            { name: 'og:image', content: post.heroImage ? post.heroImage.fields.file.url : 'default-image.jpg' },
            { name: 'og:url', content: `https://www.tetheros.com/blog/${post.urlSlug}` },
            { name: 'twitter:card', content: 'summary_large_image' },
            { name: 'twitter:title', content: post.contentTitle },
            { name: 'twitter:site', content: "@tetheros" },
            { name: 'twitter:url', content: `https://www.tetheros.com/blog/${post.urlSlug}` },
            { name: 'twitter:description', content: post.tagline || 'No description provided.' },
            { name: 'twitter:image', content: post.heroImage ? post.heroImage.fields.file.url : 'default-image.jpg' },
            { name: 'twitter:image:alt', content: post.heroImage.fields.description ? post.heroImage.fields.description : 'No alt text provided' }
        ]
        const structuredData = {
            "@context": "http://schema.org",
            "@type": "BlogPosting",
            "headline": post.contentTitle,
            "image": post.heroImage ? post.heroImage.fields.file.url : undefined,
            "author": {
              "@type": "Person",
              "name": post.authorName
            },
            "publisher": {
              "@type": "Organization",
              "name": "Tetheros",
              "logo": {
                "@type": "ImageObject",
                "url": "https://storage.googleapis.com/tetheros-public/logo/bimi-tetheros-t.svg"
              }
            },
            "datePublished": post.published,
            "dateModified": post.updatedAt || post.published,
            "description": post.tagline
        };
        updateHeadTags(title, metaTags)
        updateSchemaOrgJSONLD(structuredData)
    }
    useEffect(() => {
        fetchPost()
    // eslint-disable-next-line
    }, [slug])
    useEffect(() => {
        window.scrollTo(0, 0)
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        updateMeta()
    // eslint-disable-next-line
    }, [post])
    return (
        <div>
            <UnauthenticatedHeader nonSticky={true} />
            {loaded &&
            <div className="blog-post">
                <h1>{post.contentTitle}</h1>
                <div className="blog-post-metas">
                    <div className="blog-post-meta-container">
                        <label>Published</label>
                        <p>{format(parseISO(post.published), 'PP')}</p>
                    </div>
                    <div className="blog-post-meta-container">
                        <label>Written by</label>
                        <p>{post.authorName}</p>
                    </div>
                </div>
                <div className="blog-post-tagline">{post.tagline}</div>

                <div className="blog-post-image">
                    <img src={post.heroImage.fields.file.url} alt={post.heroImage.fields.description} />
                </div>
                <div className="blog-post-content" dangerouslySetInnerHTML={{__html: documentToHtmlString(post.content, options)}}></div>
                <BlogPostFooter history={history}/>
            </div>
            }
            {!loaded &&
            <div style={{display: "flex", justifyContent: "center", width: "100%", marginTop: "200px", minHeight: "60vh"}}>
                <LoadingSymbolFacebook/>
            </div>
            }
            <LandingFooter/>
        </div>
    )
}


function BlogPostFooter({history}) {
    return (
        <div className="blog-post-footer">
            <div className="blog-post-footer-cta">
                <p>Discover better teamwork in 21 days with Tetheros</p>
                <div onClick={() => history.push('/register')}>Start for Free</div>
            </div>
            <div style={{cursor: "pointer", color: "#2a73ae", textDecoration: "underline", fontSize: "14px", fontWeight: 500}} onClick={() => history.push('/blog')}>Return to library</div>
        </div>

    )
}