import React from 'react'
import { useState, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import SidebarWorkspaceNavDropdown from './nav/SidebarWorkspaceNavDropdown';
// eslint-disable-next-line
import { BiTrendingUp, BiMessageRounded } from 'react-icons/bi'
import { FaChevronRight } from 'react-icons/fa'
import { RiListCheck2, RiDashboardLine, RiMistFill, RiFocus2Line } from 'react-icons/ri'
import { AiOutlineSetting, AiOutlineDeploymentUnit } from 'react-icons/ai'
import { IoMdAdd } from 'react-icons/io'
import { IoSearchOutline } from 'react-icons/io5'
import { hasPermission, addComponentToUncollapsedLocalStorageList, removeComponentFromUncollapsedLocalStorageList } from '../../common/Helpers';
import { MdLibraryBooks, MdVerified, MdLock } from 'react-icons/md'
import LoadingSymbolInline from '../gadgets/LoadingSymbolInline';

import WorkspaceSearch from '../workspaces/WorkspaceSearch';
import ModalCreateNewProject from '../modals/ModalCreateNewProject';

export default function SidebarWorkspaceSlim({workspace, permissions, workspaceName, components, workspaceId, componentId, setSidebarCollapsed, userWorkspaces}) {
    const history = useHistory();
    const [bonds, setBonds] = useState(() => { return [] })
    const [projects, setProjects] = useState(() => { return [] })
    const [workstreams, setWorkstreams] = useState(() => { return [] })
    const [showWorkspaceSearch, setShowWorkspaceSearch] = useState(() => { return false })
    const [showCreateNewProjectModal, setShowCreateNewProjectModal] = useState(() => { return false })
    const [isNetworkWorkspace, setIsNetworkWorkspace] = useState(() => { return false })
    const [isLoadingWorkspaceInfo, setIsLoadingWorkspaceInfo] = useState(() => { return true })
    const [searchResults, searchResultsDispatch] = useReducer((state, action) => {
        // Holds results in between renders
        if (action.type === "update_results") {
          return { ...state, results: action.results }
        }
        return state
    }, { results: [] })
    const goToWorkspaceComponent = (selComponent) => {
        history.push(`/workspaces/${selComponent.workspace_id}/c/${selComponent._id}`)
    }
    const updateNetworkWorkspaceStatus = () => {
        setIsLoadingWorkspaceInfo(true)
        if (!workspace) return
        if (!workspace._id) return
        if (workspace.network) {
            if (workspace.network.slug) {
                setIsNetworkWorkspace(true)
                setIsLoadingWorkspaceInfo(false)
                return
            }
        }
        setIsNetworkWorkspace(false)
        setIsLoadingWorkspaceInfo(false)
        return
    }
    const getNetworkName = () => {
        try {
            return <span>{workspace.network.name} Network</span>
        } catch (error) {
            return false
        }
    }
    const goToNetwork = () => {
        if (!getNetworkName()) return
        history.push(`/n/${workspace.network.slug}`)
    }
    // eslint-disable-next-line
    const goToMeasurementsPage = () => {
        history.push(`/workspaces/${workspaceId}/measurements`)
    }
    const goToLibraryPage = () => {
        history.push(`/workspaces/${workspaceId}/library`)
    }
    const goToWorkspacePage = (pageName) => {
        history.push(`/workspaces/${workspaceId}/${pageName}`)
    }
    const createNewProject = () => {
        setShowCreateNewProjectModal(true)
        // history.push(`/workspaces/${workspaceId}/new?layer=strategy&name=initiative`)
    }
    const createNewWorkstream = () => {
        history.push(`/workspaces/${workspaceId}/catalog`)
    }
    const updateProjectsAndWorkstreams = () => {
        if (!workspace) return setProjects([])
        if (!workspace.components) return setProjects([])
        let allComponents = Array.from(workspace.components)
        let foundProjects = allComponents.filter((component) => {
            if (component.status !== "active") return false
            if (component.name === "initiative") return true
            return false
        })
        let sortedProjects = foundProjects.sort((a,b) => {
            return a.display_name.localeCompare(b.display_name)
        })
        setProjects(sortedProjects)
        let foundWorkstreams = allComponents.filter((component) => {
            if (component.status !== "active") return false
            if (['board', 'list', 'timeline', 'kresult'].includes(component.name)) return true
            return false
        })
        let sortedWorkstreams = foundWorkstreams.sort((a,b) => {
            return a.display_name.localeCompare(b.display_name)
        })
        if (!workspace.bonds) return setWorkstreams(sortedWorkstreams)
        // Remove anything that's part of a project
        const childIds = workspace.bonds.map(c => c.child_id)
        sortedWorkstreams = sortedWorkstreams.filter((workstream) => {
            if (childIds.includes(workstream._id)) return false
            return true
        })
        setWorkstreams(sortedWorkstreams)
        setBonds(workspace.bonds)
    }
    useEffect(() => {
        if (!workspaceId) return
        updateProjectsAndWorkstreams()
        updateNetworkWorkspaceStatus()
    // eslint-disable-next-line
    }, [workspace])
    return (
        <div>
            {showCreateNewProjectModal && <ModalCreateNewProject workspaceId={workspace._id} hideModal={() => setShowCreateNewProjectModal(false)} />}
            {showWorkspaceSearch && <WorkspaceSearch workspace={workspace} hideWorkspaceSearch={() => setShowWorkspaceSearch(false)} searchResults={searchResults} searchResultsDispatch={searchResultsDispatch} />}
            <div className="sidebar-left-workspace-nav-dropdown-decoration">Workspace {isNetworkWorkspace && <MdVerified/>}</div>
            <SidebarWorkspaceNavDropdown currentWorkspace={workspace} workspaces={userWorkspaces} />
            
            <div className="sidebar-left-section-workspace-navigation-container" style={{minHeight: "25px"}}>
                {isLoadingWorkspaceInfo && <div className="sidebar-left-section-workspace-navigation-item"><LoadingSymbolInline/></div>}
                {!isLoadingWorkspaceInfo && isNetworkWorkspace && <div className="sidebar-left-section-workspace-navigation-item" onClick={goToNetwork}><MdVerified style={{color: "rgb(41, 115, 175)"}}/>{getNetworkName()}</div>}
                {!isLoadingWorkspaceInfo && !isNetworkWorkspace && <div className="sidebar-left-section-workspace-navigation-item sidebar-left-section-workspace-navigation-item-informational"><MdLock style={{}}/>Private Workspace</div>}
            </div>

            <div className="sidebar-left-section-workspace-navigation-container">
                <div className="sidebar-left-section-workspace-navigation-item" onClick={() => goToWorkspacePage('')}><BiMessageRounded/> Feed</div>
                <div className="sidebar-left-section-workspace-navigation-item" onClick={() => goToWorkspacePage('dashboards')}><AiOutlineDeploymentUnit/> Dashboard</div>
                <div className="sidebar-left-section-workspace-navigation-item" onClick={() => setShowWorkspaceSearch(true)}><IoSearchOutline/> Search</div>
                <div className="sidebar-left-section-workspace-navigation-item" onClick={goToLibraryPage}><MdLibraryBooks/> Library</div>
                <div className="sidebar-left-section-workspace-navigation-item" onClick={() => goToWorkspacePage('settings')}><AiOutlineSetting/>Settings</div>
            </div>

            <div style={{marginTop: "12px"}}>
                <SidebarSectionSlim componentId={componentId} permissions={permissions} goToWorkspaceComponent={goToWorkspaceComponent} createNew={createNewProject} title="Projects" components={projects} allComponents={workspace.components} bonds={bonds}/>
                <SidebarSectionSlim componentId={componentId} permissions={permissions} goToWorkspaceComponent={goToWorkspaceComponent} createNew={createNewWorkstream} title="Workstreams & Views" components={workstreams} allComponents={workspace.components} />
            </div>
        </div>
    )
}

function SidebarSectionSlim({title, components, allComponents, bonds, goToWorkspaceComponent, createNew, permissions, componentId}) {
    return (
        <div className="sidebar-slim-section">
            <div className="sidebar-slim-section-title">
                <p>{title}</p>
                {hasPermission(permissions, 'component_create') && <IoMdAdd onClick={createNew}/>}
            </div>

            <div className="sidebar-slim-components-list">
                {components.map((c) => (
                    <SidebarSectionSlimItem componentId={componentId} goToWorkspaceComponent={goToWorkspaceComponent} key={c._id} component={c} allComponents={allComponents} bonds={bonds}/>
                ))}
                {hasPermission(permissions, 'component_create') && components.length === 0 && <div style={{fontSize: "14px", color: "grey", marginLeft: "8px", marginTop: "8px", cursor: "pointer"}} onClick={createNew}>+  Create new</div> }
                {!hasPermission(permissions, 'component_create') && components.length === 0 && <div style={{fontSize: "14px", color: "grey", marginLeft: "8px", marginTop: "8px"}}>Nothing here...</div> }
            </div>

        </div>
    )
}

function SidebarSectionSlimItem({component, allComponents, bonds, goToWorkspaceComponent, componentId}) {
    const [isCollapsed, setIsCollapsed] = useState(() => {
        if (!component) return true
        if (!component._id) return true
        const expandedIds = JSON.parse(localStorage.getItem('gExpandedIds')) || [];
        if (expandedIds.includes(component._id)) return false
        return true
    })
    const [children, setChildren] = useState(() => { return false })
    const [componentBondChildrenIds, setComponentBondChildrenIds] = useState(() => { return false })
    const getComponentBonds = () => {
        try {
            if (!bonds) return false
            if (!allComponents) return false
            if (!component) return false
            if (component.name !== "initiative") return false
            const bondsMasterList = Array.from(bonds)
            let bondsWhereComponentIsParent = bondsMasterList.filter((b) => {
                if (b.parent_id === component._id) return true
                return false
            })
            if (!bondsWhereComponentIsParent) return false
            return bondsWhereComponentIsParent.map(b => b.child_id)
        } catch (error) {
            return false
        }
    }
    const getAdditionalStyle = (id) => {
        if (id === componentId) return {
            backgroundColor: "rgb(240, 240, 240)",
            fontWeight: 700
        }
    }
    const parseChildren = () => {
        const childrenIds = Array.from(componentBondChildrenIds)
        let childrenComponents = allComponents.filter((c) => {
            if (!['list', 'board'].includes(c.name)) return false
            if (c.status !== 'active') return false
            if (childrenIds.includes(c._id)) return true
            return false
        })
        childrenComponents = childrenComponents.sort((a,b) => {
            return a.display_name.localeCompare(b.display_name)
        })
        return childrenComponents
    }
    const getSymbolClass = () => {
        if (isCollapsed) return ""
        if (!isCollapsed) return "sidebar-slim-component-item-symbol-rotated"
    }
    const getRepresentativeSymbol = (componentType) => {
        if (['initiative'].includes(componentType)) return false
        if (componentType === "list") return <RiListCheck2/>
        if (componentType === "board") return <RiDashboardLine/>
        if (componentType === "timeline") return <RiMistFill/>
        if (componentType === "kresult") return <RiFocus2Line/>
    }
    const toggleCollapse = () => {
        const newCollapseState = !isCollapsed
        setIsCollapsed(!isCollapsed)
        if (!newCollapseState) addComponentToUncollapsedLocalStorageList(component._id)
        if (newCollapseState) removeComponentFromUncollapsedLocalStorageList(component._id)
    }
    useEffect(() => {
        if (!component) return
        if (!allComponents) return
        setComponentBondChildrenIds(getComponentBonds())
    // eslint-disable-next-line
    }, [component])
    useEffect(() => {
        if (!componentBondChildrenIds) return
        setChildren(parseChildren())
    // eslint-disable-next-line
    }, [componentBondChildrenIds])
    return (
        <div className="sidebar-slim-component-item-container">
            <div className="sidebar-slim-component-item-title-row" style={getAdditionalStyle(component._id)}>
                {componentBondChildrenIds.length > 0 &&
                    <div className="sidebar-slim-component-item-symbol">
                        <FaChevronRight className={getSymbolClass()} onClick={toggleCollapse}/>
                    </div>
                }
                <div className="sidebar-slim-component-item-title-text" onClick={() => goToWorkspaceComponent(component)}>
                    {getRepresentativeSymbol(component.name)}
                    {component.display_name}
                </div>
            </div>
            {!isCollapsed && children &&
            <div className="sidebar-slim-component-item-children">
                {children.map((childComponent) => (
                    <div key={childComponent._id} onClick={() => goToWorkspaceComponent(childComponent)} className="sidebar-slim-component-item-title-row" style={{paddingLeft: "14px"}}>
                        <div className="sidebar-slim-component-item-title-text sidebar-slim-component-item-child" style={getAdditionalStyle(childComponent._id)}>
                            {getRepresentativeSymbol(childComponent.name)}
                            {childComponent.display_name}
                        </div>
                    </div>
                ))}
                {children.length === 0 && <div style={{fontSize: "14px", color: "grey", marginLeft: "14px", marginTop: "8px"}}>No active workstreams</div> }
            </div>
            }
            
        </div>
    )
}