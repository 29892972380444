import React from 'react'
import { MdClose } from 'react-icons/md';
import { useState } from 'react';
import { FaTasks, FaRunning, FaThLarge } from 'react-icons/fa'
import { BiCustomize, BiTrendingUp } from 'react-icons/bi'

export default function ModalEditGoalMarker({component, updateGoalMarker, setIsEditingGoalMarker, parentId}) {
    const [targetValue, setTargetValue] = useState(() => {
        if (component.name === 'kpi') {
            return component.parent_data[parentId].target
        } else {
            return Math.round(component.parent_data[parentId].target*100)
        }
    })
    const getComponentSymbol = (name) => {
        const symbolMap = {
            list: <FaTasks className="gomano-card-component-symbol"/>,
            sprint: <FaRunning className="gomano-card-component-symbol"/>,
            kanban: <FaThLarge className="gomano-card-component-symbol"/>,
            board: <BiCustomize className="gomano-card-component-symbol"/>,
            kpi: <BiTrendingUp className="gomano-card-component-symbol"/>
        }
        return symbolMap[name]
    }
    const getProgressMetricForComponent = () => {
        if (component.progress) {
            return Math.round(component.progress*100);
        } else {
            return 0
        }
    }
    const getSelectedChildCurrentValue = () => {
        if (component.name === 'kpi') {
            return component.attributes.val_current
        } else {
            return getProgressMetricForComponent()
        }
    }
    const submitGoalMarkerChanges = () => {
        let v = targetValue;
        if (component.name === 'kpi') {
            v = targetValue
        } else {
            v = targetValue /100
        }
        const updates = {
            target: v
        }
        const parentData = {}
        parentData[parentId] = updates;
        const payload = {
            parent_data: parentData
        }
        setIsEditingGoalMarker(false);
        updateGoalMarker(payload, component._id);
    }
    const getCurrentTarget = () => {
        if (component.name === 'kpi') {
            return component.parent_data[parentId].target
        } else {
            return Math.round(component.parent_data[parentId].target*100)
        }
    }
    const getInitialValue = () => {
        if (component.name === 'kpi') {
            return component.parent_data[parentId].initial
        } else {
            return Math.round(component.parent_data[parentId].initial*100)
        }
    }
    return (
        <div className="alignment-modal-background">
            <div className="modal-task-create-container">

                <div className="goal-marker-modal-form-header">
                    <h3>Update goal marker</h3>
                    <MdClose className="goal-marker-modal-form-cancel" onClick={(e) => setIsEditingGoalMarker(false)}/>
                </div>

                {/* Component Name */}
                <div className="goal-marker-modal-form-page-description-type">
                    {getComponentSymbol(component.name)}
                    {component.name}
                </div>
                <div className="goal-marker-modal-form-page-description-names">
                    <div className="goal-marker-modal-form-page-description-text">Update goal for 
                    <span className="goal-marker-modal-form-page-description-name"> {component.display_name}</span>
                    :</div>
                </div>

                <div className="modal-workspace-component-edit-section modal-workspace-component-edit-section-doublewide">
                    <div className="modal-workspace-component-edit-subsection">
                        <h4>Current target</h4>
                        <div className="gomano-card-component-metric">{getCurrentTarget()}</div>
                    </div>
                    <div className="modal-workspace-component-edit-subsection">
                        <h4>New target</h4>
                        {component.name === 'kpi' ? (
                            <input className="gomano-card-component-metric-target-input" value={targetValue} onChange={(e) => setTargetValue(e.target.value)} autoFocus={true} min={getInitialValue()} type="number" placeholder={getSelectedChildCurrentValue()}/>
                        ) : (
                            <input className="gomano-card-component-metric-target-input" value={targetValue} onChange={(e) => setTargetValue(e.target.value)} autoFocus={true} max={100} min={getInitialValue()} type="number" placeholder={getSelectedChildCurrentValue()}/>
                        )}
                    </div>
                </div>

                {/* Submission Container */}
                <div className="modal-task-edit-submission-section">
                    <div></div>
                    <div className="modal-task-edit-submission-section">
                        <div className="modal-task-create-cancel" onClick={() => setIsEditingGoalMarker(false)}>Cancel</div>
                        <div className="modal-task-create-submit" onClick={submitGoalMarkerChanges}>Update</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
