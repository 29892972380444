export default class DataManager {

    static myInstance = null;

    _authenticated = "";

    /**
     * @returns {DataManager}
     */
    static getInstance() {
        if (DataManager.myInstance == null) {
            DataManager.myInstance = new DataManager();
        }

        return this.myInstance;
    }

    getAuth() {
        if (localStorage.getItem("auth")) {
            return true;
        } else {
            return false;
        }
        // return this._authenticated;
    }

    setAuth(auth) {
        localStorage.setItem("auth", auth)
        // this._authenticated = auth;
    }
}