import React from 'react'
import ModalSimpleHeader from './simple/ModalSimpleHeader'
import ModalSimpleSingleButton from './simple/ModalSimpleSingleButton'

export default function ModalNetworkRoleDeleteConfirm({hideModal, deleteRole, roleId, network, networkUserData}) {
    const confirmDelete = () => {
        deleteRole(roleId)
    }
    const getWorkspaceNameFromId = (workspaceId) => {
        try {
            const workspace = networkUserData.workspaces.find(w => w._id === workspaceId)
            return workspace.name
        } catch (error) {
            return false
        }
    }
    const getImpactedTeams = () => {
        try {
            let impactedTeams = []
            const allTeams = Array.from(networkUserData.teams)
            const teamsUsingRole = allTeams.filter((team) => {
                if (team.role_id === roleId) return true
                return false
            })
            if (teamsUsingRole.length === 0) return false
            for (let i = 0; i < teamsUsingRole.length; i++) {
                const impactStatement = `${teamsUsingRole[i].name} (${teamsUsingRole[i].user_ids.length} users)`
                impactedTeams.push(impactStatement)
            }
            return impactedTeams
        } catch (error) {
            return false
        }
    }
    const getImpactedUsers = () => {
        try {
            let impactedUsers = [] // String of statements - name (workspace)
            const allUsers = Array.from(networkUserData.users)
            for (let i = 0; i < allUsers.length; i++) {
                const userWorkspaceMemberships = allUsers[i].workspace_memberships
                if (userWorkspaceMemberships.length === 0) continue
                const membershipsUsingRole = userWorkspaceMemberships.filter((membership) => {
                    if (membership.role_id === roleId) return true
                    return false
                })
                if (membershipsUsingRole.length === 0) continue
                const userName = `${allUsers[i].firstName} ${allUsers[i].lastName}`
                for (let j = 0; j < membershipsUsingRole.length; j++) {
                    const workspaceName = getWorkspaceNameFromId(membershipsUsingRole[j].membership_to_id)
                    if (!workspaceName) continue
                    impactedUsers.push(`${userName} (${workspaceName})`)
                }
            }
            if (impactedUsers.length === 0) return false
            return impactedUsers
        } catch (error) {
            return false
        }
    }
    return (
        <div className="modal-mgmt-background-overlay">
            <div className="modal-mgmt-container">
                <ModalSimpleHeader title="Delete this role?" hideModal={hideModal} />
                <div className="modal-mgmt-sections">
                    {getImpactedUsers() &&
                    <div className="modal-mgmt-section">
                        <h3>These users will be impacted:</h3>
                        <div className="modal-mgmt-user-membership-teams" style={{maxHeight: "140px"}}>
                            {getImpactedUsers().map((impact) => (
                                <div style={{fontSize: "14px"}} key={impact}>{impact}</div>
                            ))}
                        </div>
                    </div>
                    }
                    {getImpactedUsers() &&
                    <div className="modal-mgmt-section">
                        <div className="modal-mgmt-instructional-text-box">
                            <p style={{fontWeight: "700", marginBottom: "4px"}}>Warning:</p>
                            <p>These users will lose workspace access if they are not on a team that has workspace access.  If they lose access, all work will be automatically unassigned.</p>
                            <p style={{fontWeight: "700", marginTop: "4px"}}>{`!`} Unassignment is irreversible.</p>
                        </div>
                    </div>
                    }
                    {getImpactedTeams() &&
                    <div className="modal-mgmt-section">
                        <h3>These teams will lose this role:</h3>
                        <div className="modal-mgmt-user-membership-teams" style={{maxHeight: "140px"}}>
                            {getImpactedTeams().map((impact) => (
                                <div style={{fontSize: "14px"}} key={impact}>{impact}</div>
                            ))}
                        </div>
                    </div>
                    }
                    {getImpactedTeams() &&
                    <div className="modal-mgmt-section">
                        <div className="modal-mgmt-instructional-text-box">
                            <p style={{fontWeight: "700", marginBottom: "4px"}}>Warning:</p>
                            <p>Impacted teams will lose permissions, but not workspace access.  User assignments won't be changed, but you may need to review user permissions.</p>
                        </div>
                    </div>
                    }
                    {!getImpactedTeams() && !getImpactedUsers() && 
                    <div className="modal-mgmt-section">
                        <div style={{fontSize: "16px", marginBottom: "20px", fontWeight: "700"}}>This role is safe to delete.</div>
                        <div style={{fontSize: "14px"}}>No users or teams use this role.</div>
                    </div>
                    }
                    <ModalSimpleSingleButton buttonText="Delete" isValid={true} onclick={confirmDelete} />
                </div>
                
            </div>
        </div>
    )
}