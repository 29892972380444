import React from 'react'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';

import PromotionCalendar from './PromotionCalendar'

export default function PublicToolBase({ authenticated }) {
    const { toolId } = useParams();
    const [toolType, setToolType] = useState(() => { return false })
    // ROUTES TO THE DIFFERENT AVAILABLE TOOL TYPES BASED ON THE TOOL TYPE TIED TO THE TOOL ID (FETCHED FROM BACKEND)
    const getToolType = () => {
        if (!toolId) return setToolType(false)
        setToolType("promotion_calendar")
    }
    useEffect(() => {
        getToolType()
    // eslint-disable-next-line
    }, [toolId])
    return (
        <div className="tools-container">
            {toolType === "promotion_calendar" && <PromotionCalendar authenticated={authenticated} toolId={toolId} />}
        </div>
    )
}
