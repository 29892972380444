import React from 'react'
import { useState, useEffect, useRef  } from 'react';
import { useHistory } from 'react-router-dom';
import Api from "../../common/APIUtils"

import { format, addDays, startOfToday, isBefore, parseISO, differenceInCalendarDays, isThisYear, isPast, differenceInDays, differenceInMinutes } from 'date-fns'
import { toCapitalize, sortByProperty, getFormattedDisplayWithUnits, getUserProfileSrcFromId, getUserNameFromId, hasPermission, sortByDateStringAttribute, isValidHttpUrl, transformUrlIntoDisplayName } from '../../common/Helpers';
import { Calendar as DateRangeCalendar } from 'react-date-range';

// import { CgDarkMode } from "react-icons/cg";
import { IoSettingsOutline, IoEllipsisHorizontal, IoTrashSharp, IoSendSharp, IoHelpSharp } from 'react-icons/io5'
import { CgMenuGridO } from "react-icons/cg";
import { GiCirclingFish, GiStairsGoal } from "react-icons/gi";
import { RiLinkUnlink, RiDeleteBin6Line } from "react-icons/ri";
import { BsPlusLg } from 'react-icons/bs';
import { BiChevronLeft, BiChevronRight, BiReset } from 'react-icons/bi';
import { MdOutlinePublic } from "react-icons/md";

import EditableComponentTitle from '../gadgets/EditableComponentTitle';
import ComponentStatusDropdown from '../gadgets/ComponentStatusDropdown';
import ComponentMainDescription from './ComponentMainDescription';
import HProgressBar from '../HProgressBar';
import ProfPicCircle from '../profiles/ProfPicCircle';
import Calendar from '../calendars/Calendar';
import ResourceReference from '../gadgets/ResourceReference';

import ModalCreateWorkstreamInProject from '../modals/ModalCreateWorkstreamInProject';
import ModalCreateNewGoalInProject from '../modals/ModalCreateNewGoalInProject';
import ModalCreateNewResourceInProject from '../modals/ModalCreateNewResourceInProject';
import ModalInitiativeSettings from '../modals/ModalInitiativeSettings';
import ConfirmAction from '../modals/ConfirmAction';

export default function WorkspaceCompInitiative({ component, permissions, team, componentData, fetchComponent, workspaceName, activeComponentId, workspace, workspaces, sidebarCollapsed }) {
    let history = useHistory();
    const api = new Api()
    const [childrenComponents, setChildrenComponents] = useState(() => { return [] })
    const [workstreams, setWorkstreams] = useState(() => { return [] })
    const [resources, setResources] = useState(() => { return [] })
    const [tutorialMode, setTutorialMode] = useState(() => { return false })
    const [tutorialStage, setTutorialStage] = useState(() => { return "none" })
    const [workspaceResources, setWorkspaceResources] = useState(() => { return [] })
    const [projectComments, setProjectComments] = useState(() => { return [] })
    const [goals, setGoals] = useState(() => { return [] })
    const [startDate, setStartDate] = useState(() => { return new Date() })
    const [projectEndDate, setProjectEndDate] = useState(() => { return false })
    const [links, setLinks] = useState(() => []);
    const [isCreatingLinks, setIsCreatingLinks] = useState(() => { return false });
    const [isShowingSettingsModal, setIsShowingSettingsModal] = useState(() => { return false })
    const [isShowingManageResourcesModal, setIsShowingManageResourcesModal] = useState(() => { return false })
    const [isShowingCreateNewGoal, setIsShowingCreateNewGoal] = useState(() => { return false })
    const [isShowingConfirmDeleteWorkstreamModal, setIsShowingConfirmDeleteWorkstreamModal] = useState(() => { return false })
    const [isShowingConfirmDeleteGoalModal, setIsShowingConfirmDeleteGoalModal] = useState(() => { return false })
    const [isCreatingNewWorkstream, setIsCreatingNewWorkstream] = useState(() => { return false })
    const [loaded, setLoaded] = useState(() => { return false })
    const [allKpis, setAllKpis] = useState(() => { return [] })
    const [tasks, setTasks] = useState(() => { return [] })
    const [targetDeleteWorkstreamId, setTargetDeleteWorkstreamId] = useState(() => { return false })
    const [targetDeleteGoalId, setTargetDeleteGoalId] = useState(() => { return false })
    const [sortedWeeklyData, setSortedWeeklyData] = useState(() => { return [] })
    const [overdue, setOverdue] = useState(() => { return [] })
    const [hubView, setHubView] = useState(() => { return "overview" })
    const updateChildrenComponents = () => {
        try {
            if (component.children) setChildrenComponents(Array.from(component.children))
        } catch (error) {
            return
        }
    }
    const updateFormattedDisplayDates = () => {
        let data = []
        try {
            // Get tasks for displayed dates
            const copyOfTasks = Array.from(tasks)
            const tasksWithDates = copyOfTasks.filter((t) => {
                if (!t.attributes) return false
                if (!t.attributes.date_due) return false
                if (t.attributes.date_due === "") return false
                if (t.status === "active") return true
                return false
            })
            // Get overdue tasks
            let overdueTasks = tasksWithDates.filter((t) => {
                if (isBefore(parseISO(t.attributes.date_due), startOfToday())) return true
                return false
            })
            overdueTasks = sortByDateStringAttribute(overdueTasks, true, "display_name")
            setOverdue(overdueTasks)
            let numberOfDays = 2
            if (overdueTasks.length === 0) numberOfDays = 3
            for (let index = 0; index < numberOfDays; index++) {
                const keyDate = format(addDays(startDate, index), 'yyyy-MM-dd')
                let tasksWithMatchingDueDate = tasksWithDates.filter((t) => {
                    if (t.attributes.date_due === keyDate) return true
                    return false
                })
                tasksWithMatchingDueDate = sortByProperty(tasksWithMatchingDueDate, "display_name")
                data.push({date: keyDate, tasks: tasksWithMatchingDueDate, key: `${keyDate}${index}`})
            }
            setSortedWeeklyData(data)
        } catch (error) {
            return setSortedWeeklyData([])
        }
    }
    const updateInitiative = (payload) => {
        if (!hasPermission(permissions, 'component_update')) return
        if (!component) return
        const path = `/workspace/components/${component._id}`;
        api.updateWorkspaceComponent(payload, path)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const updateDescription = (newDescription) => {
        updateInitiative({
            attributes: {
                description: newDescription
            }
        })
    }
    const updateWorkstreams = () => {
        if (!childrenComponents) return setWorkstreams([])
        if (childrenComponents.length === 0) return setWorkstreams([])
        try {
            const filteredWorkstreams = childrenComponents.filter((w) => {
                if (w.name === "board" || w.name === "list") return true
                return false
            })
            const alphabeticalWorkstreams = sortByProperty(filteredWorkstreams, "display_name")
            setWorkstreams(alphabeticalWorkstreams)
        } catch (error) {
            return setWorkstreams([])
        }
    }
    const updateGoals = () => {
        if (!childrenComponents) return setGoals([])
        if (childrenComponents.length === 0) return setGoals([])
        try {
            const filteredGoals = childrenComponents.filter((w) => {
                if (w.name === "kresult") return true
                return false
            })
            const alphabeticalGoals = sortByProperty(filteredGoals, "display_name")
            setGoals(alphabeticalGoals)
        } catch (error) {
            return setGoals([])
        }
    }
    const updateResources = () => {
        if (!childrenComponents) return setResources([])
        if (childrenComponents.length === 0) return setResources([])
        try {
            // Project Resources
            const filteredLinks = childrenComponents.filter((w) => {
                if (w.name === "link") return true
                if (w.name === "file") return true
                return false
            })
            const alphabeticalLinks = sortByProperty(filteredLinks, "display_name")
            setResources(alphabeticalLinks)
        } catch (error) {
            return setResources([])
        }
    }
    const getAdditionalGoalStyles = () => {
        try {
            if (goals.length < 3) return { justifyContent: "flex-start" }
            return {}
        } catch (error) {
            return {}
        }
    }
    const getKpi = (goalId) => {
        try {
            if (!component.kpis) return false
            if (component.kpis.length === 0) return false
            const kpisCopy = Array.from(component.kpis)
            let relatedKpi = false
            for (let i = 0; i < kpisCopy.length; i++) {
                const kpi = kpisCopy[i];
                if (!kpi.parent_data) continue
                if (kpi.parent_data[goalId]) {
                    relatedKpi = kpi
                    break
                }
            }
            if (relatedKpi) return relatedKpi
            return false
        } catch (error) {
            return false
        }
    }
    const updateView = (newView) => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('v', newView);
        history.push(`${window.location.pathname}?${searchParams.toString()}`);
        setHubView(newView);
    }
    const unlinkWorkstreamFromProject = (childComponentId) => {
        try {
            if (!hasPermission(permissions, 'component_update')) return
            // Find the bondId
            let bondId = false
            const allBonds = Array.from(workspace.bonds)
            const matchingBond = allBonds.find((b) => b.child_id === childComponentId)
            if (!matchingBond) return
            bondId = matchingBond._id
            if (!bondId) return
            const payload = {
                workspaceId: component.workspace_id,
                bondId: bondId
            }
            api.deleteBond(payload)
            .then((res) => {
                fetchComponent()
            })
            .catch((err) => {
                console.log(err);
            });
        } catch (error) {
            return 
        }
    }
    const linkChildToProject = (cId) => {
        try {
            const payload = {};
            payload['workspace_id'] = component.workspace_id;
            payload['child_id'] = cId;
            payload['parent_id'] = component._id
            api.createBond(payload)
            .then((res) => {
                fetchComponent();
            });   
        } catch (error) {
            return
        }
    }
    const handleDeleteWorkstreamClick = (childComponentId) => {
        setTargetDeleteWorkstreamId(childComponentId)
        setIsShowingConfirmDeleteWorkstreamModal(true)
    }
    const handleDeleteGoalClick = (childComponentId) => {
        setTargetDeleteGoalId(childComponentId)
        setIsShowingConfirmDeleteGoalModal(true)
    }
    const cancelConfirmDeleteModal = () => {
        setTargetDeleteWorkstreamId(false)
        setIsShowingConfirmDeleteWorkstreamModal(false)
        setTargetDeleteGoalId(false)
        setIsShowingConfirmDeleteGoalModal(false)
    }
    const confirmDeleteWorkstream = () => {
        setIsShowingConfirmDeleteWorkstreamModal(false)
        if (!targetDeleteWorkstreamId) return
        api.deleteWorkspaceComponent({ componentId: targetDeleteWorkstreamId })
        .then((res) => {
            fetchComponent()
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const confirmDeleteGoal = () => {
        setIsShowingConfirmDeleteGoalModal(false)
        if (!targetDeleteGoalId) return
        api.deleteWorkspaceComponent({ componentId: targetDeleteGoalId })
        .then((res) => {
            fetchComponent()
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const updateKpis = () => {
        try {
            if (!workspace) return
            if (!workspace.components) return
            const wComponents = Array.from(workspace.components)
            let onlyKpis = wComponents.filter((c) => {
                return c.name === "kpi"
            })
            setAllKpis(onlyKpis)
        } catch (error) {
            return setAllKpis([])
        }
    }
    const updateWorkspaceResources = () => {
        try {
            if (!workspace) return
            if (!workspace.components) return
            const wComponents = Array.from(workspace.components)
            const foundWorkspaceResources = wComponents.filter((c) => ["link", "file"].includes(c.name))
            setWorkspaceResources(foundWorkspaceResources)
        } catch (error) {
            return setWorkspaceResources([])
        }
    }
    const getNavClassName = (existingNavClass) => {
        if (existingNavClass === hubView) return "network-hub-header-nav-sel"
        return ""
    }
    const updateTasks = () => {
        if (!component.tasks) return
        setTasks(Array.from(component.tasks))
    }
    const updateProjectComments = () => {
        try {
            if (component.comments) return setProjectComments(component.comments.reverse())
            setProjectComments([])
        } catch (error) {
            setProjectComments([])
        }
    }
    const updateLoadedComponents = () => {
        try {
            if (!component) return setLoaded(false)
            updateChildrenComponents()
            updateTasks()
            updateResources()
            updateProjectComments()
            setLoaded(true)
        } catch (error) {
            return
        }
    }
    const resetToToday = () => {
        setStartDate(new Date())
    }
    const incrementDays = () => {
        const nextDay = new Date(startDate);
        nextDay.setDate(nextDay.getDate() + 1)
        setStartDate(nextDay)
    }
    const decrementDays = () => {
        const prevDay = new Date(startDate);
        prevDay.setDate(prevDay.getDate() - 1)
        setStartDate(prevDay)
    }
    const goToTask = (task) => {
        try {
            history.push(`/workspaces/${task.workspace_id}/c/${task._id}`)
        } catch (error) {
            return
        }
        
    }
    const closeNewWorkstreamModal = () => {
        setIsCreatingNewWorkstream(false)
        setIsShowingCreateNewGoal(false)
        fetchComponent()
    }
    const hasInitiativeId = () => {
        try {
            if (component.attributes.initiative_id.trim() === "") return false
            return true
        } catch (error) {
            return false
        }
    }
    const updateEndDate = (proposedEndDate) => {
        setProjectEndDate(proposedEndDate)
    }
    const tutorialSaveTimeline = () => {
        if (projectEndDate) updateInitiative({ attributes: { date_end: format(projectEndDate, 'yyyy-MM-dd') }})
        tutorialProceedFromTimeline()
    }
    const tutorialAcceptTrial = () => {
        setTutorialStage("timeline")
    }
    const tutorialProceedFromTimeline = () => {
        if (tutorialStage !== "timeline") return
        if (resources.length === 0) {
            setTutorialStage("resources")
        } else {
            setTutorialStage("resources")
        }
    }
    const handleLinkChange = (index, value) => {
        const newLinks = [...links];
        newLinks[index] = value;
        setLinks(newLinks);
    };
    const tutorialSaveResources = () => {
        // Handle the save action here
        if (isCreatingLinks) return
        setIsCreatingLinks(true)
        tutorialCreateResources()
        setTutorialStage("workstreams")
    };
    const tutorialCreateResources = () => {
        // loop over all the proposed resources and create them
        for (let i = 0; i < links.length; i++) {
            const linkUrl = links[i];
            let proposedUrl = linkUrl
            if (proposedUrl.startsWith("http://") || proposedUrl.startsWith("https://")) {
                proposedUrl = linkUrl
            } else {
                proposedUrl = `http://${linkUrl}`
            }
            if (!isValidHttpUrl(proposedUrl)) continue
            tutorialCreateResource(proposedUrl)
        }
    }
    const tutorialCreateResource = (url) => {
        try {
            if (!component) return
            const displayName = transformUrlIntoDisplayName(url)
            let payload = {
                workspace_id: component.workspace_id,
                is_published: true,
                display_name: displayName,
                proposed_parent_ids: [component._id],
                attributes: {
                    description: "",
                    destination: url
                }
            }
            api.createWorkspaceComponent(payload, `/components/resource/link`)
            .then( (res) => {
                fetchComponent()
            })
            .catch((err) => {
                console.log(err);
            })
        } catch (error) {
            return
        }

    }
    const createComment = (proposedComment) => {
        const payload = {
            content: proposedComment,
            target_id: component._id,
            type: "component"
        }
        api.createComponentComment(payload)
        .then((res) => {
            fetchComponent();
        })
        .catch((err) => {
            console.log(err);
        })
    }
    const getCommentStamp = (comment) => {
        try {
            const isoTimestamp = comment.createdAt
            const commentDate = parseISO(isoTimestamp);
            const now = new Date();
            const minutesDifference = differenceInMinutes(now, commentDate);
            const daysDifference = differenceInDays(now, commentDate);
            if (minutesDifference < 5) return "Just now"
            if (daysDifference > 5) return format(commentDate, 'M/d/yyyy')
            if (daysDifference === 0) return `Today at ${format(commentDate, 'p')}`
            if (daysDifference === 1) return `Yesterday at ${format(commentDate, 'p')}`
            return `${format(commentDate, 'EEEE')} at ${format(commentDate, 'p')}`
        } catch (error) {
            return ""
        }
    }
    const getCommentSize = () => {
        try {
            let commentLength = projectComments.length 
            if (commentLength === 0) return false
            if (commentLength > 99) return <div className="network-hub-header-nav-decorator" style={{fontSize: "8px"}}>99+</div> 
            return <div className="network-hub-header-nav-decorator">{commentLength}</div> 
        } catch (error) {
            return false
        }
    }
    const openPublicPage = () => {
        try {
            if (!component.is_public) return
            window.open(`https://tetheros.com/public/c/${component._id}`, '_blank', 'noopener,noreferrer');
        } catch (error) {
            return
        }
    };
    const updateTutorialModeSettings = () => {
        try {
            const storedSetting = localStorage.getItem("gTutorialMode")
            if (storedSetting === "yes") {
                setTutorialMode(true)
                setTutorialStage("timeline")
                setProjectEndDate(parseISO(component.attributes.date_end))
            }
        } catch (error) {
            return
        }
    }
    const resetTutorialMode = () => {
        setTutorialMode(true)
        setTutorialStage("timeline")
        setProjectEndDate(parseISO(component.attributes.date_end))
    }
    const finishTutorialMode = () => {
        localStorage.setItem("gTutorialMode", "no")
        setTutorialMode(false)
    }
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const initialView = searchParams.get('v') || 'overview';
        updateView(initialView);
        updateTutorialModeSettings()
    // eslint-disable-next-line
    }, []);
    useEffect(() => {
        updateLoadedComponents()
    // eslint-disable-next-line
    }, [ component, activeComponentId ])
    useEffect(() => {
        updateKpis()
        updateWorkspaceResources()
    // eslint-disable-next-line
    }, [workspace])
    useEffect(() => {
        updateWorkstreams()
        updateGoals()
        updateResources()
    // eslint-disable-next-line
    }, [childrenComponents])
    useEffect(() => {
        updateFormattedDisplayDates()
    // eslint-disable-next-line
    }, [tasks, startDate])
    return (
        <div className={`initiative initiative-theme-light`}>
            {isCreatingNewWorkstream && <ModalCreateWorkstreamInProject hideModal={closeNewWorkstreamModal} parentProjectComponent={component} /> }
            <div className={`initiative-backdrop-theme initiative-backdrop-theme-light`}></div>
            {isShowingCreateNewGoal && <ModalCreateNewGoalInProject fetchProject={fetchComponent} kpis={allKpis} parentProjectComponent={component} hideModal={closeNewWorkstreamModal}/>}
            {isShowingConfirmDeleteWorkstreamModal && <ConfirmAction hideMe={cancelConfirmDeleteModal} mainText={`Are you sure you want to delete this workstream?\n\nAll tasks will be deleted and it will be impossible to recover.`} onconfirm={confirmDeleteWorkstream} title="Delete Workstream" /> }
            {isShowingConfirmDeleteGoalModal && <ConfirmAction hideMe={cancelConfirmDeleteModal} mainText={`Are you sure you want to delete this goal?\n\nIt will be impossible to recover.`} onconfirm={confirmDeleteGoal} title="Delete Goal" /> }
            {isShowingManageResourcesModal && <ModalCreateNewResourceInProject permissions={permissions} linkResource={linkChildToProject} unlinkResource={unlinkWorkstreamFromProject} parentProjectComponent={component} hideModal={() => setIsShowingManageResourcesModal(false)} fetchAlignments={fetchComponent} projectResources={resources} workspaceResources={workspaceResources} /> }
            {isShowingSettingsModal && <ModalInitiativeSettings fetchComponent={fetchComponent} hideModal={() => setIsShowingSettingsModal(false)} component={component} permissions={permissions} team={team} />}

            {loaded &&
            <div>
                <div className="initiative-header">
                    {component.status === "archived" && <div style={{width: "100%", marginBottom: "20px", fontWeight: 700, fontSize: "14px", boxSizing: "border-box", padding: "12px", backgroundColor: "rgba(252, 99, 107, 0.182)", color: "rgb(252, 99, 107)", textAlign: "center"}}>This project is archived. You can still make changes.</div> }
                    <div className="initiative-header-row">
                        <div className="util-row util-align-center" style={{gap: "20px", flexGrow: 1}}>
                            {/* <div className="initiative-icon-button">
                                <CgDarkMode/>
                            </div> */}
                            <div style={{cursor: "pointer", flexGrow: 1}}>
                                <EditableComponentTitle canWrap={true} permissions={permissions} component={component} activeComponentId={activeComponentId} fetchComponent={fetchComponent}/>
                            </div>
                        </div>
                        <div className="util-row util-align-center" style={{gap: "18px"}}>
                            {component.is_public &&
                            <div className="initiative-icon-button initiative-icon-button-public" title="Public Project" onClick={openPublicPage}>
                                <MdOutlinePublic/>
                            </div>
                            }
                            <div>
                                <ComponentStatusDropdown component={component} permissions={permissions} updateComponent={updateInitiative} componentStatus={component.status} />
                            </div>
                            <div className="initiative-icon-button" onClick={() => setIsShowingSettingsModal(true)}>
                                <IoSettingsOutline/>
                            </div>
                            <div className="initiative-icon-button" onClick={resetTutorialMode}>
                                <IoHelpSharp/>
                            </div>
                        </div>
                    </div>

                    <div className="initiative-subheader-row">

                        <div className="initiative-subheader-item initiative-subheader-item-vertical" style={{minWidth: "120px"}}>
                            <div className="util-row util-space-between">
                                <div className="initiative-subheader-title">Progress</div>
                                <div className="initiative-subheader-title">{Math.round(component.progress*100).toFixed(0)}%</div>
                                {/* <div className="initiative-subheader-value">{Math.round(component.progress*100).toFixed(0)}%</div> */}
                            </div>
                            <HProgressBar percentage={Math.round(component.progress*100).toFixed(0)} height={6} />
                        </div>

                        {component.owner_id &&
                        <div className="initiative-subheader-item">
                            <ProfPicCircle disableClick={true} height={28} src={getUserProfileSrcFromId(team, component.owner_id)} userId={component.owner_id} userName={component.owner_name} />
                            <div className="util-row util-col" style={{gap: "1px"}}>
                                <div className="initiative-subheader-title">Project Lead</div>
                                <div className="initiative-subheader-value">{component.owner_name}</div>
                            </div>
                        </div>
                        }

                        {hasInitiativeId() &&
                        <div className="initiative-subheader-item">
                            <div className="util-row util-col" style={{gap: "1px"}}>
                                <div className="initiative-subheader-title">Project ID</div>
                                <div className="initiative-subheader-value">{component.attributes.initiative_id}</div>
                            </div>
                        </div>
                        }

                        <div className="initiative-subheader-item">
                            <div className="util-row util-col" style={{gap: "1px"}}>
                                <div className="initiative-subheader-title">Timeline</div>
                                <div className="initiative-subheader-value">{format(parseISO(component.attributes.date_start), 'MMM d, yyyy')} - {format(parseISO(component.attributes.date_end), 'MMM d, yyyy')}</div>
                            </div>
                            {tutorialMode && projectEndDate && tutorialStage === "timeline" &&
                            <div className="tutorial-tooltip-container" style={{top: "calc(20px + 100%)"}}>
                                <div className="tutorial-tooltip">
                                    <div className="tutorial-tooltip-arrow-up" style={{left: "24%"}}></div>
                                    <div className="tutorial-tooltip-skip" onClick={tutorialProceedFromTimeline}>Skip</div>
                                    <div className="tutorial-tooltip-header">
                                        <h3>Project Timeline</h3>
                                    </div>
                                    <p>Select a date for the end of the project. We'll use this to improve analytics and notifications.</p>
                                    <DateRangeCalendar date={projectEndDate} onChange={updateEndDate} minDate={addDays(parseISO(component.attributes.date_start), 1)}  />
                                    <div className="tutorial-tooltip-save" onClick={tutorialSaveTimeline}>Save</div>
                                </div>
                            </div>
                            }

                            {tutorialMode && projectEndDate && tutorialStage === "welcome" &&
                            <div style={{position: "relative"}}>
                                <div style={{ position: "fixed", left: 0, right: 0, top: 0, bottom: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <div className="tutorial-tooltip-container">
                                        <div className="tutorial-tooltip" style={{position: "unset", maxWidth: "360px"}}>
                                            <div className="tutorial-tooltip-header">
                                                <h3>Welcome to your trial of Tetheros Pro!</h3>
                                            </div>
                                            <p>We're stoked to have you here!</p>
                                            <p>Your trial wraps up in 7 days. At that point you'll be changed to a Social account and will lose access to your network.</p>
                                            <p>You will never lose access to outside active networks or workspaces.</p>
                                            <div className="tutorial-tooltip-save" style={{marginTop: "20px"}} onClick={tutorialAcceptTrial}>Jump In!</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>

                        {/* <div className="initiative-subheader-item">
                            <div className="util-row util-col" style={{gap: "1px"}}>
                                <div className="initiative-subheader-title">Budget</div>
                                <div className="initiative-subheader-value">$100,000</div>
                            </div>
                        </div> */}

                    </div>

                    <div className="initiative-navs">
                        <div className="network-hub-header-nav">
                            <span onClick={() => updateView("overview")} className={getNavClassName("overview")}>Overview</span>
                            <span onClick={() => updateView("calendar")} className={getNavClassName("calendar")}>Calendar</span>
                            <span onClick={() => updateView("discussion")} className={getNavClassName("discussion")}>Discussion {getCommentSize()}</span>
                            {/* <span onClick={() => updateView("about")} className={getNavClassName("about")}>About</span> */}
                            {/* <span onClick={() => updateView("meetings")} className={getNavClassName("meetings")}>Meetings</span> */}
                            {/* <span onClick={() => updateView("budget")} className={getNavClassName("budget")}>Budget</span> */}
                        </div>
                    </div>
                </div>

                {/* Split section */}
                {hubView === "overview" &&
                <div className="initiative-sections-side-optional-outer">

                    <div className="initiative-sections-main">

                        <div className="initiative-sections">
                            
                            {/* This Week */}
                            {tasks.length > 0 &&
                            <div className="initiative-section">
                                <div>
                                    
                                </div>
                                <div className="initiative-section-header">
                                    This Week
                                    <div className="initiative-weekly-tasks-controls">
                                        <div onClick={decrementDays}><BiChevronLeft/></div>
                                        <div onClick={resetToToday}><BiReset/></div>
                                        <div onClick={incrementDays}><BiChevronRight/></div>
                                    </div>
                                </div>
                                <div className="initiative-weekly-tasks">

                                    <div className="initiative-weekly-tasks-columns">

                                        {/* Overdue */}
                                        {overdue.length > 0 &&
                                        <div className="initiative-weekly-tasks-column">
                                            <div className="initiative-weekly-tasks-column-heading">Overdue</div>
                                            <TaskGroup workstreams={workstreams} tasks={overdue} classnames="initiative-weekly-tasks-column-task initiative-weekly-tasks-column-task-overdue" goToTask={goToTask} team={team} />
                                        </div>
                                        }

                                        {sortedWeeklyData.map((weeklyData) => (
                                            <div className="initiative-weekly-tasks-column" key={weeklyData.key}>
                                                <div className="initiative-weekly-tasks-column-heading">{format(parseISO(weeklyData.date), 'cccc')} <span>{format(parseISO(weeklyData.date), 'M/d')}</span> </div>
                                                <TaskGroup workstreams={workstreams} tasks={weeklyData.tasks} classnames="initiative-weekly-tasks-column-task" goToTask={goToTask} team={team}/>
                                                {weeklyData.tasks.length === 0 &&
                                                    <div className="initiative-weekly-tasks-column-task initiative-weekly-tasks-column-task-blank">Nothing due</div>
                                                }
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            </div>
                            }

                            {/* Workstreams */}
                            <div className="initiative-section">
                                <div className="initiative-section-header" style={{position: "relative"}}>
                                    Workstreams
                                    {tutorialMode && tutorialStage === "workstreams" &&
                                    <div className="tutorial-tooltip-container" style={{left: "148px", top: "-14px"}}>
                                        <div className="tutorial-tooltip" >
                                            <div className="tutorial-tooltip-arrow-left" style={{top: "14px"}}></div>
                                            <div className="tutorial-tooltip-header">
                                                <h3>Introducing Workstreams</h3>
                                            </div>
                                            <div className="tutorial-tooltip-workstreams-animation">
                                                <img src="https://storage.googleapis.com/tetheros-public/misc/animation-workstreams.gif" alt="Workstreams" />
                                            </div>
                                            <p>Workstreams separate your project into smaller parts.</p>
                                            <p>We created a few workstreams based on your project description. All your tasks are inside.</p>
                                            <div className="tutorial-tooltip-save" onClick={finishTutorialMode}>Continue</div>
                                        </div>
                                    </div>
                                    }
                                </div>

                                <div className="general-table-container-full-width general-table-container-bordered">
                                    {workstreams.length > 0 &&
                                    <div className="general-table-flex-row general-table-headings" style={{margin: 0}}>
                                        <div className="general-table-col general-table-col-2"></div>
                                        <div className="general-table-col general-table-col-grow">Name</div>
                                        <div  className="general-table-col general-table-col-30">Progress</div>
                                        <div className="general-table-col general-table-col-12 general-table-col-desktop-only">Status</div>
                                        <div className="general-table-col general-table-col-8"></div>
                                    </div>}
                                    {workstreams.length === 0 &&
                                    <div className="util-row util-col util-align-center util-justify-center" style={{marginTop: "28px", marginBottom: "28px", gap: "14px"}}>
                                        <div className="general-table-empty-symbol"><GiCirclingFish/></div>
                                        <div className="general-table-empty-text">Use workstreams to create, organize and track tasks for your project.</div>
                                        {hasPermission(permissions, "component_create") && <div className="general-table-empty-cta" onClick={() => setIsCreatingNewWorkstream(true)}>New Workstream</div>}
                                    </div>
                                    }
                                    {workstreams.map((workstream) => (
                                        <WorkstreamEntry workstream={workstream} history={history} key={workstream._id} unlinkWorkstreamFromProject={unlinkWorkstreamFromProject} handleDeleteWorkstreamClick={handleDeleteWorkstreamClick} permissions={permissions}/>
                                    ))}
                                    {workstreams.length > 0 && hasPermission(permissions, "component_create") && 
                                    <div className="util-row util-col util-align-center util-justify-center" style={{marginTop: "28px"}}>
                                        <div className="general-table-empty-cta" onClick={() => setIsCreatingNewWorkstream(true)}><BsPlusLg/>New Workstream</div>
                                    </div>
                                    }
                                </div>
                            </div>

                            {/* Goals */}
                            {goals.length > 0 &&
                            <div className="initiative-section">
                                <div className="initiative-section-header">Goals</div>

                                <div className="initiative-goals" style={getAdditionalGoalStyles()}>
                                    {goals.map((goal) => (
                                        <GoalDisplay permissions={permissions} key={goal._id} goal={goal} getKpi={getKpi} history={history} handleDeleteGoalClick={handleDeleteGoalClick}/>
                                    ))}
                                </div>
                                {goals.length === 0 &&
                                <div className="general-table-container-full-width general-table-container-bordered">
                                    <div className="util-row util-col util-align-center util-justify-center" style={{marginTop: "28px", marginBottom: "28px", gap: "14px"}}>
                                        <div className="general-table-empty-symbol"><GiStairsGoal style={{color: "#fc636b78"}}/></div>
                                        <div className="general-table-empty-text">Set goals for your project based on KPIs or qualitative metrics</div>
                                        {hasPermission(permissions, "component_create") && <div className="general-table-empty-cta" onClick={() => setIsShowingCreateNewGoal(true)}>New Goal</div>}
                                    </div>
                                </div>
                                }
                                {goals.length > 0 && hasPermission(permissions, "component_create") && 
                                <div className="util-row util-col util-align-center util-justify-center">
                                    <div className="general-table-empty-cta" onClick={() => setIsShowingCreateNewGoal(true)}><BsPlusLg/>New Goal</div>
                                </div>
                                }
                            </div>
                            }

                            <div className="initiative-sections-responsive initiative-section">
                                <div className="initiative-section-header" style={{position: "relative"}}>
                                    Resources
                                    {tutorialMode && tutorialStage === "resources" &&
                                    <div className="tutorial-tooltip-container" >
                                        <div className="tutorial-tooltip" style={{top: "-370px"}}>
                                            <div className="tutorial-tooltip-arrow-down"></div>
                                            <div className="tutorial-tooltip-skip" onClick={() => setTutorialStage("workstreams")}>Skip</div>
                                            <div className="tutorial-tooltip-header">
                                                <h3>Resources</h3>
                                            </div>
                                            <p>Store important Google Drive, Dropbox, Sharepoint, or other web links</p>
                                            <div className="tutorial-tooltip-resource-links">
                                                {[0, 1, 2].map((index) => (
                                                <div className="tutorial-tooltip-resource-link" key={index}>
                                                    <span>https://</span>
                                                    <input type="text" placeholder="URL" value={links[index] || ''} onChange={(e) => handleLinkChange(index, e.target.value)}/>
                                                </div>
                                                ))}
                                            </div>
                                            {links.length > 0 && <div className="tutorial-tooltip-save" onClick={tutorialSaveResources}>Add</div>}
                                            {links.length === 0 && <div className="tutorial-tooltip-save" onClick={() => setTutorialStage("workstreams")}>Skip</div>}
                                        </div>
                                    </div>
                                    }
                                </div>
                                {resources.length > 0 &&
                                <div className="initiative-section-resources initiative-section-border">
                                    {resources.map((r) => (
                                        <ResourceReference key={r._id} resource={r} removeMarker={unlinkWorkstreamFromProject} permissions={permissions} />
                                    ))}
                                    <div className="util-row util-col util-align-center util-justify-center" style={{marginTop: "12px"}}>
                                        <div className="general-table-empty-cta util-row util-align-center" onClick={() => setIsShowingManageResourcesModal(true)}><CgMenuGridO style={{fontSize: "18px"}}/>Manage Resources</div>
                                    </div>
                                </div>
                                }

                                {resources.length === 0 &&
                                <div>
                                    <div className="general-table-empty-text">Resources give your team quick access to important project links</div>
                                    {hasPermission(permissions, "component_create") && <div className="general-table-empty-cta" style={{marginTop: "8px"}} onClick={() => setIsShowingManageResourcesModal(true)}>Add Resource</div>}
                                </div>
                                }
                            </div>


                        </div>

                    </div>

                    <div className="initiative-sections-side">
                        <div className="initiative-sections">

                            {/* About */}
                            <div className="initiative-section">
                                <div className="initiative-section-header">About</div>
                                <ComponentMainDescription permissions={permissions} component={component} updateDetails={updateDescription} fontSize="14" team={team}/>
                            </div>

                            {/* Resources */}
                            <div className="initiative-section" style={{position: "relative"}}>
                                <div className="initiative-section-header" style={{position: "relative"}}>
                                    Resources
                                    {tutorialMode && tutorialStage === "resources" &&
                                    <div className="tutorial-tooltip-container" >
                                        <div className="tutorial-tooltip" style={{right: "20px", top: "4px"}}>
                                            <div className="tutorial-tooltip-arrow-right" style={{top: "24%"}}></div>
                                            <div className="tutorial-tooltip-skip" onClick={() => setTutorialStage("workstreams")}>Skip</div>
                                            <div className="tutorial-tooltip-header">
                                                <h3>Resources</h3>
                                            </div>
                                            <p>Store important Google Drive, Dropbox, Sharepoint, or other web links</p>
                                            <div className="tutorial-tooltip-resource-links">
                                                {[0, 1, 2].map((index) => (
                                                <div className="tutorial-tooltip-resource-link" key={index}>
                                                    <span>https://</span>
                                                    <input type="text" placeholder="URL" value={links[index] || ''} onChange={(e) => handleLinkChange(index, e.target.value)}/>
                                                </div>
                                                ))}
                                            </div>
                                            {links.length > 0 && <div className="tutorial-tooltip-save" onClick={tutorialSaveResources}>Add</div>}
                                            {links.length === 0 && <div className="tutorial-tooltip-save" onClick={() => setTutorialStage("workstreams")}>Skip</div>}
                                        </div>
                                    </div>
                                    }
                                </div>

                                {resources.length > 0 &&
                                <div className="initiative-section-resources initiative-section-border">
                                    {resources.map((r) => (
                                        <ResourceReference key={r._id} resource={r} removeMarker={unlinkWorkstreamFromProject} permissions={permissions} />
                                    ))}
                                    {hasPermission(permissions, "component_update") &&
                                    <div className="util-row util-col util-align-center util-justify-center" style={{marginTop: "12px"}}>
                                        <div className="general-table-empty-cta util-row util-align-center" onClick={() => setIsShowingManageResourcesModal(true)}><CgMenuGridO style={{fontSize: "18px"}}/>Manage Resources</div>
                                    </div>
                                    }
                                </div>
                                }

                                {resources.length === 0 &&
                                <div>
                                    <div className="general-table-empty-text">Resources give your team quick access to important project links</div>
                                    {hasPermission(permissions, "component_create") && <div className="general-table-empty-cta" style={{marginTop: "8px"}} onClick={() => setIsShowingManageResourcesModal(true)}>Add Resource</div>}
                                </div>
                                }
                            </div>

                        </div>
                    </div>

                </div>
                }

                {hubView === "calendar" &&
                <div style={{padding: "12px", boxSizing: "border-box"}}>
                    <Calendar team={team} fetchEvents={fetchComponent} events={tasks} vertical={true} colorByEventProperty="parent" colorCodex={["task", "list", "board", "goal", "initiative"] } componentTypeMap={{ task: "Tasks", goal: "Goals" }} workspace={workspace} permissions={permissions} workspaceWorkstreamPermissionMap={[{ workspace: workspace, workstreams: workstreams, permissions: permissions }]}/>
                </div>
                }

                {hubView === "discussion" &&
                <div className="initiative-discussions-main">
                    <div className="initiative-section" style={{position: "relative"}}>
                        {component.is_public && <div className="initiative-section-banner initiative-section-banner-warning">Public project. All comments are visible to anyone with the link.</div> }
                        <div className="initiative-discussions-proposal-input">
                            <div className="initiative-discussions-proposal-input-faded-box"></div>
                            <ProposedComment createComment={createComment} sidebarCollapsed={sidebarCollapsed} />
                        </div>

                            <div className="initiative-discussions-comments">
                                {projectComments.map((comment) => (
                                <div className="initiative-discussions-comment" key={comment._id}>
                                    <div style={{minWidth: "36px"}}>
                                        <ProfPicCircle src={comment.author_profile_pic_src} height={40} userId={comment.author_id} userName={`${comment.author_first_name} ${comment.author_last_name}`} disableClick={false} />
                                    </div>
                                    
                                    <div className="initiative-discussions-comment-content">
                                        <div className="initiative-discussions-comment-content-author">{`${comment.author_first_name} ${comment.author_last_name}`} <span>{getCommentStamp(comment)}</span></div>
                                        <div className="initiative-discussions-comment-content-content">{comment.content}</div>
                                    </div>
                                </div>
                                ))}
                            </div>

                    </div>
                </div>
                }
            </div>
            }

            {!loaded && <div>Loading...</div> }
        </div>
    )
}

function GoalDisplay({ goal, getKpi, history, handleDeleteGoalClick, permissions }) {
    const [kpi, setKpi] = useState(() => { return false })
    const updateGoalKpi = () => {
        try {
            const relatedKpi = getKpi(goal._id)
            if (!relatedKpi) return setKpi(false)
            setKpi(relatedKpi)
        } catch (error) {
            setKpi(false)
        }
    }
    const getUnits = () => {
        try {
            if (!kpi) return "%"
            return kpi.attributes.units
        } catch (error) {
            return false
        }
    }
    const getFooterDecoration = () => {
        try {
            const dueDate = parseISO(goal.attributes.date_due);
            const currentDate = new Date();
            const daysUntilDue = differenceInCalendarDays(dueDate, currentDate);
            if (goal.progress >= 1) return <div className="initiative-goal-footer-decoration initiative-goal-footer-done">Complete</div>;
            if (isThisYear(dueDate) && daysUntilDue > 14) return <div className="initiative-goal-footer-decoration">{`Due ${format(dueDate, 'MMMM do')}`}</div>
            if (isPast(dueDate)) {
                return <div className="initiative-goal-footer-decoration">{`Due ${format(dueDate, 'M/dd/yyyy')}`}</div>;
            }
            if (!isThisYear(dueDate)) return <div className="initiative-goal-footer-decoration">{`Due ${format(dueDate, 'MMMM do, yyyy')}`}</div>
            if (daysUntilDue > 0) return <div className="initiative-goal-footer-decoration">{`Due in ${daysUntilDue} days`}</div>
            return <div className="initiative-goal-footer-decoration"></div>
        } catch (error) {
            return false
        }
    }
    const handleDeleteClick = (e) => {
        e.stopPropagation()
        handleDeleteGoalClick(goal._id)
    }
    const getBadge = () => {
        try {
            if (goal.progress >= 1) return <></>
            const dueDate = parseISO(goal.attributes.date_due);
            const currentDate = new Date();
            const createdAt = parseISO(goal.createdAt);
            const daysSinceCreation = differenceInCalendarDays(currentDate, createdAt);
            if (isPast(dueDate)) return <div className="initiative-goal-badge initiative-goal-badge-past">Overdue</div>
            const daysUntilDue = differenceInDays(dueDate, currentDate);
            if (daysUntilDue <= 7 && daysUntilDue > 0) return <div className="initiative-goal-badge initiative-goal-badge-due-soon">Due Soon</div>
            const progressThreshold = 0.85;
            if (goal.progress >= progressThreshold && !isPast(dueDate)) {
                return <div className="initiative-goal-badge initiative-goal-badge-imminent">Imminent</div>
            }
            let newlyCreated = daysSinceCreation <= 7;
            if (newlyCreated) return <div className="initiative-goal-badge initiative-goal-badge-new">New</div>
            if (isPast(dueDate)) return <div className="initiative-goal-badge initiative-goal-badge-past">Overdue</div>
            return <></>
        } catch (error) {
            return <></>
        }
    }
    useEffect(() => {
        updateGoalKpi()
    // eslint-disable-next-line
    }, [goal])
    return (
        <div className="initiative-goal-container" onClick={() => history.push(`/workspaces/${goal.workspace_id}/c/${goal._id}`)}>
            {getBadge()}
            {hasPermission(permissions, "component_delete") && 
            <div className="initiative-goal-delete-button" onClick={(e) => handleDeleteClick(e)}>
                <IoTrashSharp/>
            </div>
            }
            <div className="initiative-goal-container-inner">
                {kpi && <div className="initiative-goal-kpi-value">{getFormattedDisplayWithUnits(kpi.attributes.units, kpi.attributes.val_current)}</div>}
                {!kpi && <div className="initiative-goal-kpi-value">{getFormattedDisplayWithUnits("%", goal.progress*100)}</div>}
                {kpi && <div className="initiative-goal-kpi-name">{kpi.display_name}</div>}
                {!kpi && <div className="initiative-goal-kpi-name">Progress</div>}
            </div>
            <div className="initiative-goal-container-inner">
                <div className="initiative-goal-name">{goal.display_name}</div>
                <div className="initiative-goal-progress">
                    <span className="initiative-goal-progress-val">{getFormattedDisplayWithUnits(getUnits(), goal.attributes.val_start)}</span>
                    <div className="initiative-goal-progress-bar">
                        <HProgressBar percentage={Math.round(goal.progress*100).toFixed(0)} height={6} />
                    </div>
                    <span className="initiative-goal-progress-val">{getFormattedDisplayWithUnits(getUnits(), goal.attributes.val_target)}</span>
                </div>
                {getFooterDecoration()}
            </div>

        </div>
    )
}

// function InitiativeWeeklyTasks({ tasks })
function TaskGroup({ tasks, classnames, goToTask, workstreams, team }) {
    const [shownCount, setShownCount] = useState(() => { return 5 })
    const [displayedTasks, setDisplayedTasks] = useState(() => { return tasks.slice(0, 5) })
    const getWorkstreamNameFromId = (workstreamId) => {
        try {
            const matchingWorkstream = workstreams.find((w) => w._id === workstreamId)
            if (matchingWorkstream) {
                if (matchingWorkstream.display_name) return matchingWorkstream.display_name
            }
            return false
        } catch (error) {
            return false
        }
    }
    const updateDisplayedTasks = () => {
        try {
            let taskArray = Array.from(tasks)
            let slicedArray = taskArray.slice(0, shownCount)
            setDisplayedTasks(slicedArray)
        } catch (error) {
            setDisplayedTasks(tasks)
        }
    }
    useEffect(() => {
        updateDisplayedTasks()
    // eslint-disable-next-line
    }, [tasks, shownCount])
    return (
        <div className="initiative-weekly-tasks-column-tasks">
            {displayedTasks.map((t) => (
                <div key={t._id} className={classnames} title={t.display_name} onClick={() => goToTask(t)}>
                    <div className="initiative-weekly-tasks-column-task-name">{t.display_name}</div>
                    {getWorkstreamNameFromId(t.attributes.parent) && <div className="initiative-weekly-tasks-column-task-workstream">{getWorkstreamNameFromId(t.attributes.parent)}</div>}
                    {t.owner_id &&
                    <div style={{ position: "absolute", right: 6, bottom: 6 }}>
                        <ProfPicCircle disableClick={true} height={24} src={getUserProfileSrcFromId(team, t.owner_id)} userId={t.owner_id} userName={getUserNameFromId(team, t.owner_id)} />
                    </div>
                    }
                </div>
            ))}
            {displayedTasks.length < tasks.length && <span className="initiative-weekly-tasks-column-show-more" onClick={() => setShownCount(tasks.length)}>{tasks.length - displayedTasks.length} more</span>}
        </div>
    )
}

function WorkstreamEntry({ workstream, history, unlinkWorkstreamFromProject, handleDeleteWorkstreamClick, permissions }) {
    const [manageWindowOpen, setManageWindowOpen] = useState(() => { return false })
    const initiativeWorkstreamMenuRef = useRef(null)
    const openManageWindow = (e) => {
        e.stopPropagation()
        setManageWindowOpen(!manageWindowOpen)
    }
    const getWorkstreamProgressText = () => {
        try {
            // if archived
            if (workstream.status === "archived") return "-"
            // if (workstream.status === "completed") return "100%"
            if (workstream.status === "completed") {
                return <HProgressBar percentage={100} height={6} />
            }
            return <HProgressBar percentage={Math.round(workstream.progress*100).toFixed(0)} height={6} />

            // if complete
            // return `${Math.round(workstream.progress*100).toFixed(0)}%`
        } catch (error) {
            return ""
        }
    }
    const handleAction = (e, actionName) => {
        e.stopPropagation()
        setManageWindowOpen(false)
        if (actionName === "unlink") return unlinkWorkstreamFromProject(workstream._id)
        if (actionName === "delete") return handleDeleteWorkstreamClick(workstream._id)
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (initiativeWorkstreamMenuRef.current && !initiativeWorkstreamMenuRef.current.contains(event.target)) {
                setManageWindowOpen(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [initiativeWorkstreamMenuRef])
    return (
        <div className="general-table-flex-row general-table-row-content general-table-row-content-hoverable general-table-row-content-compact" key={workstream.id} onClick={() => history.push(`/workspaces/${workstream.workspace_id}/c/${workstream._id}`)}>
            <div className="general-table-col general-table-col-2"></div>
            <div className="general-table-col general-table-col-grow" style={{flexBasis: "0%" }}>
                <div className="util-row util-col util-align-start util-justify-start" style={{gap: "4px"}}>
                    <span style={{ fontWeight: 600, fontSize: "13px", paddingRight: "8px", color: "#344563" }}>{workstream.display_name}</span>
                </div>
            </div>
            <div className="general-table-col general-table-col-30" style={{ fontWeight: 500, fontSize: "13px", paddingRight: "20px", boxSizing: "border-box"}}>{getWorkstreamProgressText()}</div>
            <div className="general-table-col general-table-col-12 general-table-col-desktop-only">
                <span className={`initiative-workstream-status-badge initiative-workstream-status-badge-${workstream.status}`} onClick={(e) => { e.stopPropagation()}}>{toCapitalize(workstream.status)}</span>
            </div>
            <div className="general-table-col general-table-col-8 general-table-col-align-right general-table-col-content-clickable-text" style={{ fontSize: "13px", paddingRight: "12px", position: "relative", overflow: "visible", zIndex: 44 }} ref={initiativeWorkstreamMenuRef}>
                {hasPermission(permissions, "component_update") && <IoEllipsisHorizontal onClick={(e) => openManageWindow(e, workstream)}/>}
                {manageWindowOpen && hasPermission(permissions, "component_update") && 
                <div className="initiative-workstream-menu-container">
                    <div className="initiative-workstream-menu-option" onClick={(e) => handleAction(e, "unlink")}><RiLinkUnlink/>Unlink from project</div>
                    {hasPermission(permissions, "component_delete") && <div className="initiative-workstream-menu-option" onClick={(e) => handleAction(e, "delete")}><RiDeleteBin6Line/>Delete workstream</div>}
                </div>
                }
            </div>
        </div>
    )
}

function ProposedComment({ createComment, sidebarCollapsed }) {
    const MINIMUM_ROW_COUNT = 1;
    const [proposedComment, setProposedComment] = useState(() => { return "" })
    const [newCommentRows, setNewCommentRows] = useState(() => { return 1 })
    const [isValid, setIsValid] = useState(() => { return false })
    const submitNewComment = () => {
        if (!proposedComment) return
        if (proposedComment.trim() === "") return
        if (!isValid) return
        createComment(proposedComment)
        setProposedComment("")
        setNewCommentRows(1)
    }
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            if (proposedComment.trim() !== '') {
                submitNewComment()
                e.preventDefault();
            }
        } else if (e.key === 'Enter' && e.shiftKey) {
            e.preventDefault();
            if (!proposedComment) return
            if (proposedComment.trim() === "") return
            setProposedComment((prevComment) => `${prevComment}\n`);
        }
    }
    const updateLines = () => {
        if (!proposedComment) {
            setIsValid(false)
            setNewCommentRows(MINIMUM_ROW_COUNT)
            return
        }
        if (proposedComment.trim() === "") {
            setIsValid(false)
            setNewCommentRows(MINIMUM_ROW_COUNT)
            return
        }
        setIsValid(true)
        let breaks = proposedComment.split(/\r\n|\r|\n/).length
        setNewCommentRows(Math.max(breaks, MINIMUM_ROW_COUNT))
    }
    useEffect(() => {
        updateLines()
    // eslint-disable-next-line
    }, [proposedComment])
    return (
      <div className="initiative-discussions-proposal-input initiative-discussions-proposal-input-float" style={sidebarCollapsed ? {left: "80px"} : {} }>
        <textarea className="initiative-discussions-textarea app-thin-scrollbar" autoFocus placeholder="Share your thoughts (use ctrl+shift to add new lines)" value={proposedComment} onChange={(e) => setProposedComment(e.target.value)} rows={newCommentRows} onKeyDown={handleKeyDown}></textarea>
        {isValid && <button className="initiative-discussions-submit-button initiative-discussions-submit-button-valid" onClick={submitNewComment}><IoSendSharp/></button>}
        {!isValid && <button className="initiative-discussions-submit-button" onClick={submitNewComment}><IoSendSharp/></button>}
      </div>
    );
}