import React from 'react'
import { BiPlus } from 'react-icons/bi'
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Api from '../../common/APIUtils';
import { format, parseISO } from 'date-fns'
import { hasPermission } from '../../common/Helpers';
import { IoRocketSharp  } from "react-icons/io5";
import ModalManageNetworkWorkspace from '../modals/ModalManageNetworkWorkspace';
import ModalImportPrivateWorkspace from '../modals/ModalImportPrivateWorkspace';
import ModalNewNetworkWorkspace from '../modals/ModalNewNetworkWorkspace';

export default function NetworkWorkspaces({ network, permissions }) {
    let history = useHistory()
    let api = new Api()
    const [workspaces, setWorkspaces] = useState(() => { return [] })
    const [isShowingCreateWorkspaceModal, setIsShowingCreateWorkspaceModal] = useState(() => { return false })
    const [selectedWorkspace, setSelectedWorkspace] = useState(() => { return "" })
    const [isShowingImportModal, setIsShowingImportModal] = useState(() => { return false })
    const [isShowingModal, setIsShowingModal] = useState(() => { return false })
    const fetchWorkspaces = () => {
        if (!network.slug) return
        api.getNetworkWorkspaces(network.slug)
        .then((res) => {
            if (res.data) {
                setWorkspaces(res.data.response.workspaces)
            }
        })
        .catch((err) => {
            if (err.response) {
                if (err.response.status === 400) {
                    // Toast
                    console.log('Have a toast')
                } else {
                    // Toast
                    console.log('Have a toast')
                }
            }
            // Toast
            console.log('Have a toast')
        }) 
    }
    // const showManageModal = (workspace) => {
    //     setSelectedWorkspace(workspace)
    //     setIsShowingModal(true)
    // }
    const hideModal = () => {
        setIsShowingModal(false)
    }
    const hideImportModal = () => {
        setIsShowingImportModal(false)
        fetchWorkspaces()
    }
    const getWorkspaceTeamCount = (workspace) => {
        try {
            if (!workspace || !network) return 0
            let totalUsers = 0
            let accountedUserIds = []
            const workspaceTeamsWithAccess = Array.from(workspace.teams)
            const workspaceTeamsWithAccessIds = workspaceTeamsWithAccess.map((t) => t._id)
            const allUserTeamMemberships = Array.from(network.user_team_memberships)
            for (let i = 0; i < allUserTeamMemberships.length; i++) {
                const membership = allUserTeamMemberships[i];
                if (workspaceTeamsWithAccessIds.includes(membership.membership_to_id) && !accountedUserIds.includes(membership.member_id)) {
                    totalUsers += 1
                    accountedUserIds.push(membership.member_id)
                }
            }
            return totalUsers
        } catch (error) {
            return 0
        }
    }
    const goToCreateNetworkWorkspace = () => {
        history.push(`/n/${network.slug}/workspaces/create`)
    }
    const showImportWorkspaceModal = () => {
        setIsShowingImportModal(true)
    }
    const getCreatedAtTime = (workspace) => {
        if (!workspace.createdAt) return ""
        return format(parseISO(workspace.createdAt), 'PPP')
    }
    const attemptWorkspaceCreation = (payload) => {
        api.createNetworkWorkspace(payload)
        .then((res) => {
            if (res.data) {
                history.push(`/workspaces/${res.data.response.workspace._id}`)
            }
        })
        .catch((err) => { return })
    }
    const handleWorkspaceClick = (workspace) => {
        return
        // try {
        //     if (hasPermission(permissions, 'network_admin')) {
        //         showManageModal(workspace)
        //     }
        // } catch (error) {
        //     return
        // }
    }
    const deleteNetworkWorkspace = (workspaceId) => {
        // doesn't delete the workspace but hides from view
        setIsShowingModal(false)
        fetchWorkspaces()
    }
    const navigateToWorkspace = (e, workspace) => {
        e.stopPropagation()
        history.push(`/workspaces/${workspace._id}`)
    }
    const updateSelectedWorkspace = () => {
        if (!selectedWorkspace) return
        if (!workspaces) return
        const selectedId = selectedWorkspace._id
        const updatedSelectedWorkspace = workspaces.filter((w) => {
            if (w._id === selectedId) return true
            return false
        })
        if (updatedSelectedWorkspace.length !== 1) return
        setSelectedWorkspace(updatedSelectedWorkspace[0])
    }
    useEffect(() => {
        fetchWorkspaces()
    // eslint-disable-next-line
    }, [network])
    useEffect(() => {
        updateSelectedWorkspace()
    // eslint-disable-next-line
    }, [workspaces])
    return (
        <div className="network-hub-page">
            {isShowingCreateWorkspaceModal && <ModalNewNetworkWorkspace cancelModal={() => setIsShowingCreateWorkspaceModal(false)} network={network} attemptWorkspaceCreation={attemptWorkspaceCreation}/> }
            {isShowingModal && <ModalManageNetworkWorkspace network={network} workspace={selectedWorkspace} fetchWorkspaces={fetchWorkspaces} hideModal={hideModal} permissions={permissions} deleteNetworkWorkspace={deleteNetworkWorkspace}/>}
            {isShowingImportModal && <ModalImportPrivateWorkspace network={network} hideModal={hideImportModal}/>}
            
            <div className="network-hub-page-header-row">
                <div className="network-hub-page-header-text">Workspaces</div>
                <div className="network-page-header-actions">
                    <div className="util-row util-row-reverse util-align-stretch" style={{flexBasis: "50%", gap: "20px"}}>
                        {(workspaces.length > 0 && hasPermission(permissions, 'network_admin')) && 
                            <div className="network-page-add-workspace" onClick={() => setIsShowingCreateWorkspaceModal(true)} >
                                <BiPlus/>
                                <span style={{minWidth: "90px"}}>Create new</span>
                            </div>
                        }
                        {/* {hasPermission(permissions, 'network_admin') && <span className="modal-task-create-submit" onClick={showImportWorkspaceModal}>Import</span>} */}
                    </div>

                </div>
            </div>

            <div className="network-page-table-container">

                {/* Empty workspaces - getting started */}
                {workspaces.length === 0 && 
                <div className="network-page-table-empty">
                    <div className="network-page-table-empty-heading">
                        <h2>Build your first workspace</h2>
                        <p>Workspaces organize your projects, events, meetings, and ongoing team operations.</p>
                    </div>
                    {hasPermission(permissions, 'network_admin') && (
                    <>
                    <div className="network-page-add-workspace" onClick={goToCreateNetworkWorkspace}>
                        <BiPlus/>
                        <span>Create your first workspace</span>
                    </div>
                    <div className="modal-task-create-submit" onClick={showImportWorkspaceModal}>
                        Import workspace
                    </div>
                    </>
                    )}
                </div>
                }
                {workspaces.length > 0 &&
                <div className="general-table-container-full-width general-table-container-bordered" style={{marginTop: "20px"}}>
                    <div className="general-table-flex-row general-table-headings" style={{margin: 0}}>
                        <div className="general-table-col general-table-col-2"></div>
                        <div className="general-table-col general-table-col-grow">Name</div>
                        <div  className="general-table-col general-table-col-30">Users</div>
                        <div className="general-table-col general-table-col-15 general-table-col-desktop-only ">Created</div>
                        <div className="general-table-col general-table-col-15"></div>
                    </div>
                    {workspaces.map((workspace) => (
                        <div className="general-table-flex-row general-table-row-content general-table-row-content-hoverable" key={workspace._id} onClick={() => handleWorkspaceClick(workspace)}>
                            <div className="general-table-col general-table-col-2"></div>
                            <div className="general-table-col general-table-col-grow" style={{fontWeight: 700, fontSize: "14px"}}>{workspace.name}</div>
                            <div className="general-table-col general-table-col-30">{getWorkspaceTeamCount(workspace)}</div>
                            <div className="general-table-col general-table-col-15 general-table-col-desktop-only">{getCreatedAtTime(workspace)}</div>
                            <div className="general-table-col general-table-col-15 general-table-col-align-right general-table-col-content-clickable-text" title="Visit Workspace" style={{ color: "var(--network-hub-color-select)", fontSize: "16px", paddingRight: "12px" }}><IoRocketSharp onClick={(e) => navigateToWorkspace(e, workspace)}/></div>
                        </div>
                    ))}
                </div>
                }
            </div>
            
        </div>
    )
}
