import React from 'react'
import { useState, useEffect, useRef } from "react"

import { getUserNameFromId, getAvailableCardColors, getColorHexMapFromName } from '../../common/Helpers'

import { MdClear, MdDateRange, MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md'
import { format, addDays, isValid } from 'date-fns';
import { Calendar } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

export default function ModalTimelineNewTask({parent, closeRequest, swimlanes, team, keyDate, createNewTask}) {
    const [proposedOwner, setProposedOwner] = useState(() => { return "" })
    const [proposedParentId, setProposedParentId] = useState(() => { return parent._id })
    const [proposedName, setProposedName] = useState(() => { return "" })
    const [proposedDescription, setProposedDescription] = useState(() => { return "" })
    const [proposedDueDate, setProposedDueDate] = useState(() => { return addDays(keyDate, 2) })
    const [proposedParentObj, setProposedParentObj] = useState(() => { return parent })
    const [proposedColorName, setProposedColorName] = useState(() => { return "none" })
    const [isShowingDatePicker, setIsShowingDatePicker] = useState(() => { return false })
    const [keepCreating, setKeepCreating] = useState(() => { return false })
    const dueDateRef = useRef(null)
    const hide = () => {
        setIsShowingDatePicker(false)
    }
    const handleDueDateChange = (d) => {
        setProposedDueDate(d)
        hide()
    }
    const clearDueDate = () => {
        setProposedDueDate("")
        hide()
    }
    const getPresentableDueDate = () => {
        if (!proposedDueDate) return "None"
        return format(proposedDueDate, 'PP')
    }
    const clearAllValues = () => {
        setProposedName("")
        setProposedDescription("")
    }
    const submitNewTask = () => {
        if (!proposedName) return // todo: display an error!
        if (!proposedParentId) return
        let effectiveDueDate = ""
        if (isValid(proposedDueDate)) effectiveDueDate = format(proposedDueDate, 'yyyy-MM-dd')
        let attributes = {
            description: proposedDescription,
            details: proposedDescription,
            date_due: effectiveDueDate,
            active: true,
            parent: proposedParentId,
            color_name: proposedColorName
        }
        if (proposedParentObj.name === "board") attributes['state'] = proposedParentObj.attributes.column_id_new
        let payload = {
            display_name: proposedName,
            owner_id: proposedOwner,
            is_published: true,
            position: 0,
            attributes: attributes
        }
        createNewTask(payload, !keepCreating)
        if (keepCreating) clearAllValues()
    }
    const getDueDateStart = () => {
        if (proposedDueDate === "" || !proposedDueDate) return new Date()
        return proposedDueDate
    }
    const getColorChangeOptionStyle = (colorName) => {
        const colorInfo = getColorHexMapFromName(colorName)
        let s = {
            backgroundColor: colorInfo.hex,
            cursor: "pointer",
            border: "1.5px solid rgb(221, 221, 221)"
        }
        if (colorName === proposedColorName) s['border'] = "1.5px solid black"
        return s
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dueDateRef.current && !dueDateRef.current.contains(event.target)) {
                hide()
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [dueDateRef])
    useEffect(() => {
        if (!swimlanes || !proposedParentId) return
        let foundParent = swimlanes.find(a => a._id === proposedParentId)
        setProposedParentObj(foundParent)
    // eslint-disable-next-line
    }, [proposedParentId])
    return (
        <div className="modal-timeline-container-outer-relative">
            <div className="modal-timeline-container-backdrop"></div>
            <div className="modal-timeline-container">
                <div className="modal-timeline-header-row">
                    <h3>New task</h3>
                    <MdClear onClick={closeRequest}/>
                </div>

                <div className="modal-timeline-new-task-inputs-scrollable">
                    <div className="modal-timeline-new-task-inputs">
                        <div className="modal-timeline-new-task-input">
                            <div className="modal-timeline-new-task-input-title">Name</div>
                            <input autoFocus={true} value={proposedName} onChange={(e) => setProposedName(e.target.value)} type="text" placeholder="Enter a task name" />
                        </div>
                        <div className="modal-timeline-new-task-input">
                            <div className="modal-timeline-new-task-input-title">Create In</div>
                            <select value={proposedParentId} onChange={(e) => setProposedParentId(e.target.value)}>
                                {swimlanes.map((swimlane) => (
                                    <option key={swimlane._id} value={swimlane._id}>{swimlane.display_name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="modal-timeline-new-task-input">
                            <div className="modal-timeline-new-task-input-title">Description</div>
                            <textarea value={proposedDescription} onChange={(e) => setProposedDescription(e.target.value)} placeholder="Enter a task description"></textarea>
                        </div>
                        <div className="modal-timeline-new-task-input">
                            <div className="modal-timeline-new-task-input-title">Due Date</div>
                            <div className="modal-timeline-new-task-date-selector-container" ref={dueDateRef}>
                                <div className="modal-timeline-new-task-date-selector-date-button" onClick={() => setIsShowingDatePicker(!isShowingDatePicker)}><MdDateRange/>{getPresentableDueDate()}</div>
                                {isShowingDatePicker && (
                                <div className="date-box-editable-calendar-picker" style={{left: "0px", top: "100%", zIndex: "12", backgroundColor: "white"}}>
                                    <Calendar date={getDueDateStart()} onChange={handleDueDateChange} />
                                    <div className="modal-timeline-new-task-clear-date-button" onClick={clearDueDate}>Clear Due Date</div>
                                </div>
                                )}
                            </div>
                        </div>
                        <div className="modal-timeline-new-task-input">
                            <div className="modal-timeline-new-task-input-title">Owner</div>
                            <select value={proposedOwner} onChange={(e) => setProposedOwner(e.target.value)}>
                                <option value="">Nobody</option>
                                {team.map((teammate) => (
                                    <option key={teammate._id} value={teammate._id}>{getUserNameFromId(team, teammate._id)}</option>
                                ))}
                            </select>
                        </div>
                        <div className="modal-timeline-new-task-input">
                            <div className="modal-timeline-new-task-input-title">Color</div>

                            <div className="modal-full-screen-edit-task-color-change-colors">
                                {getAvailableCardColors().map((colorName) => (
                                    <div style={getColorChangeOptionStyle(colorName)} className="modal-full-screen-edit-task-color-change-color-circle" key={colorName} onClick={() => setProposedColorName(colorName)}></div>
                                ))}
                            </div>

                        </div>
                    </div>      
                </div>

                <div className="modal-timeline-new-task-actions">
                    <div className="modal-timeline-new-task-additional-action">
                        {!keepCreating ? <MdCheckBoxOutlineBlank onClick={() => setKeepCreating(true)}/> : <MdCheckBox onClick={() => setKeepCreating(false)}/> }
                        <div>Create another</div>
                    </div>
                    <div className="modal-timeline-new-task-actions-buttons">
                        <div onClick={submitNewTask}>Create</div>
                        <div onClick={closeRequest}>Discard</div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}
