import React from 'react'
import { MdKeyboardBackspace } from 'react-icons/md'
import { useHistory } from 'react-router-dom';
import NetworkCatalogItem from './NetworkCatalogItem';

export default function NetworkCatalog({network}) {
    const history = useHistory();
    const goBack = () => {
        history.push(`/n/${network.slug}`);
    }
    return (
        <div className="network-page">
            <div className="network-page-action-header">
                <span onClick={goBack} className="network-page-action-header-nav-back">
                    <MdKeyboardBackspace/>
                </span>
            </div>

            <div className="network-page-header-row">
                <div className="network-page-header-text">
                    <h1>Build New Item</h1>
                    <p>Create network workspaces, components, and other items here</p>
                </div>
                <div className="network-page-header-actions">
                </div>
            </div>

            <div className="network-home-action-grid-outer">
                <NetworkCatalogItem link={`/n/${network.slug}/workspaces/create`} subtitle="A place to strategically collaborate on meaningful work" title="Workspace" symbol="workspaces"/>
                {/* <NetworkCatalogItem link={`/n/${network.slug}/build?name=goal`} subtitle="Measure shared progress of many items towards a common idea" title="Goal" symbol="goal"/> */}
                {/* <NetworkCatalogItem link={`/n/${network.slug}/build?name=initiative`} subtitle="A central area for planning, doing, and measuring your work" title="Project" symbol="initiative"/> */}
            </div>

        </div>
    )
}
