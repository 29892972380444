import React, { useState, useEffect, useReducer, useRef } from 'react'

import ModalSimpleSingleButton from './simple/ModalSimpleSingleButton'
import LexicalTextEditor from '../gadgets/LexicalTextEditor';
import ToastText from '../gadgets/ToastText';
import BlurProofInput from '../gadgets/BlurProofInput';

import { IoClose, IoCloseCircleSharp } from 'react-icons/io5';
import { FaImage } from "react-icons/fa6";

export default function ModalCreateMultiMediaWorkspacePost({ hideModal, team, createPost, workspaceId }) {
    const [canSubmit, setCanSubmit] = useState(() => { return false })
    const [showToast, setShowToast] = useState(() => { return false });
    const [toastText, setToastText] = useState(() => { return "Each file needs to be under 5 MB" });
    const [imageFiles, setImageFiles] = useState(() => { return [] });
    const [proposedPostTitle, setProposedPostTitle] = useState(() => { return "" });
    const fileInputRef = useRef(null);
    const postTitleRef = useRef(null);
    const [proposedPost, proposedPostDispatch] = useReducer((state, action) => { 
        if (action.type === "update_name") return { ...state, name: action.value }
        if (action.type === "save") {
            const safeHtml = action.value.description_html
            const editorState = action.value.description_editor
            return { ...state, description_editor: editorState, description_html: safeHtml }
        }       
        return state
    }, { description_editor: false, description_html: false })
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB
    const postContent = () => {
        if (!canSubmit) return
        const formData = new FormData();
        imageFiles.forEach(file => {
            formData.append('images', file);
        });
        formData.append('rich_text_content', JSON.stringify(proposedPost))
        formData.append('workspace_id', workspaceId)
        if (proposedPostTitle.trim() !== "") formData.append('post_title', proposedPostTitle)
        createPost(formData);
        hideModal();
    }
    const updatePostSubmissionState = () => {
        try {
            if (imageFiles.length > 0) return setCanSubmit(true)
            let proposedPostContent = ""
            if (!proposedPost.description_html) return setCanSubmit(false)
            proposedPostContent = proposedPost.description_html.replace(/<\/?[^>]+(>|$)/g, '')
            if (proposedPostContent.trim() === "") return setCanSubmit(false)
            return setCanSubmit(true)
        } catch (error) {
            setCanSubmit(false)
        }
    }
    const promptToSelectPhotos = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }
    const handleFileSelect = (event) => {
        const files = Array.from(event.target.files).slice(0, 10)
        const imageFiles = files.filter(file => file.type === 'image/jpeg' || file.type === 'image/png');
        handleMultimediaPasteAction(imageFiles)
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    }
    const handleMultimediaPasteAction = (newImages) => {
        setImageFiles((currentImages) => {
            const availableSlots = 10 - currentImages.length
            let alreadyShowedToast = false
            if (availableSlots > 0) {
                const allowedImages = newImages.filter((image) => {
                    if (!image.size) return false
                    if (image.size < MAX_FILE_SIZE) return true
                    if (!showToast && !alreadyShowedToast) {
                        setToastText("Each file needs to be under 5 MB")
                        setShowToast(true)
                        alreadyShowedToast = true
                    }
                    return false
                })
                const imagesToAdd = allowedImages.slice(0, availableSlots)
                return [...currentImages, ...imagesToAdd]
            }
            return currentImages
        })
    }
    const removeImage = (index) => {
        setImageFiles(currentFiles => currentFiles.filter((_, i) => i !== index));
    };
    useEffect(() => {
        updatePostSubmissionState()
    // eslint-disable-next-line
    }, [proposedPost, imageFiles])
    useEffect(() => {
        // Disable scrolling when modal is open
        document.body.style.overflow = 'hidden';
        return () => {
          // Enable scrolling when modal is closed
          document.body.style.overflow = 'auto';
        };
    }, []);
    useEffect(() => {
        return () => {
            imageFiles.forEach(file => URL.revokeObjectURL(file));
        };
    }, [imageFiles]);
    return (
        <div className="modal-mm-post-outer">
            {showToast && <ToastText timeout={5000} content={toastText} actionOnClick={false} setShowToast={setShowToast} />}
            <div className="modal-mm-post">

                <div className="modal-mm-post-header-row">
                    <h2>New Post</h2>
                    <IoClose onClick={hideModal}/>
                </div>

                <div className="modal-mm-text-title-container">
                    <BlurProofInput inputRef={postTitleRef}>
                        <input type="text" ref={postTitleRef} placeholder="Subject (optional)" maxLength={300} value={proposedPostTitle} onChange={(e) => setProposedPostTitle(e.target.value)} />
                    </BlurProofInput>
                </div>

                <div className="modal-mm-text-editor">
                    <LexicalTextEditor handleMultimediaPasteAction={handleMultimediaPasteAction} placeholder={"What's on your mind?"} isUpgrade={false} getInitialState={() => { return false }} mainDescriptionDispatch={proposedPostDispatch} legacyDescription={false} team={team} />
                </div>

                {imageFiles.length > 0 &&
                <div className="modal-mm-image-preview-container app-thin-scrollbar">
                    {imageFiles.map((file, index) => (
                        <div style={{position: "relative"}} onClick={() => removeImage(index)} key={index}>
                            <img src={URL.createObjectURL(file)} alt={`preview ${index}`} className="modal-mm-image-preview" />
                            <IoCloseCircleSharp className="modal-mm-image-preview-remove" />
                        </div>
                    ))}
                </div>
                }

                <div className="modal-mm-uploads">
                    <span style={{cursor: "default"}}>Add to your post</span>
                    <input type="file" style={{ display: 'none' }} ref={fileInputRef} multiple accept="image/jpeg, image/png" onChange={handleFileSelect}/>
                    <div className="modal-mm-upload-buttons">
                        <div className="modal-mm-upload-button" onClick={promptToSelectPhotos} title="Add image"><FaImage style={{color: "rgb(61, 114, 170)"}}/></div>
                    </div>
                   
                </div>

                <div className="modal-mm-buttons">
                    <ModalSimpleSingleButton isValid={canSubmit} buttonText={"POST"} onclick={postContent} />
                </div>
            </div>
        </div>
    )
}
