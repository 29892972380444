import Api from "../common/APIUtils";
import DataManager from "../common/DataManager";
import ToastText from './gadgets/ToastText';
import { useState, useEffect } from 'react';
import { isValidEmailAddress } from '../common/Helpers';
import { Link, useHistory, useLocation } from "react-router-dom";
import '../styles/forms.css';

import { registerForTetherosLinkedIn } from '../common/Api';

import UnauthenticatedHeader from './header/UnauthenticatedHeader';

const Login = ({refreshAuthStatus, authenticated}) => {
    const api = new Api();
    let history = useHistory();
    const location = useLocation()
    let dataManager = DataManager.getInstance();
    const [loginPage, setLoginPage] = useState(() => {return "landing" })
    const [emailAddress, setEmailAddress] = useState(() => {return "" })
    const [password, setPassword] = useState(() => { return ""})
    const [canSubmit, setCanSubmit] = useState(() => { return false })
    const [isRedirected, setIsRedirected] = useState(() => { return false })
    const [redirectPath, setRedirectPath] = useState(() => { return false })
    const [toastText, setToastText] = useState(() => { return "" })
    const [showToast, setShowToast] = useState(() => { return false })
    const tryToSubmit = (code) => {
        if (code !== 'Enter' && code !== "NumpadEnter") return setShowToast(false)
        if (canSubmit && loginPage === "basic") return loginUser()
        console.log('sdf')
        if (isValidEmailAddress(emailAddress) && loginPage === "landing") return setLoginPage("basic")
        
        setToastText("Invalid email address and/or password")
        if (!emailAddress && !password) setToastText("Enter an email address and password to continue")
        if (!emailAddress) setToastText("Enter an email address to continue")
        if (!password) setToastText("Enter a password to continue")
        setShowToast(true)
    }

    useEffect(() => {
        if ((isValidEmailAddress(emailAddress)) && (password !== "")) {
            setCanSubmit(true)
        } else {
            setCanSubmit(false)
        }
    // eslint-disable-next-line
    }, [emailAddress, password])
    const validateLoginInputs = () => {
        if (canSubmit) {
            loginUser()
        }
    }
    const registerWithLinkedIn = () => {
        registerForTetherosLinkedIn()
        .then((res) => { return })
        .catch((err) => { return })
    }
    const loginUser = () => {
        const payload = {
            emailAddress: emailAddress.toLowerCase(),
            password: password
        }
        api.loginUser(payload)
        .then( (res) => {
            refreshAuthStatus();
            if (res.data.status === 'success') {
                dataManager.setAuth(true);
                // history.push('/home');
            }
            if (res.data.status !== 'success') {
                setToastText("Incorrect password.  Please try again.")
                setShowToast(true)
            }
        })
        .catch((err) => {
            setToastText("Incorrect password.  Please try again.")
            setShowToast(true)
        });;
    }
    const attemptPageNav = () => {
        if (isValidEmailAddress(emailAddress) && loginPage === "landing") return setLoginPage("basic")
        return setLoginPage("landing")
    }
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const redirect = queryParams.get('redirect');
        setIsRedirected(!!redirect);
        setRedirectPath(redirect);
    }, [location]);
    useEffect(() => {
        if (authenticated) {
            if (redirectPath) {
                history.push(decodeURIComponent(redirectPath));
            } else {
                history.push('/home');
            }
        }
    // eslint-disable-next-line
    }, [authenticated, redirectPath])
    useEffect(() => {
        document.title = "Login | Tetheros"
    }, [])
    return (
        <div className="login-container">
            <UnauthenticatedHeader authenticated={authenticated} />

            {showToast && <ToastText content={toastText} setShowToast={setShowToast} />}

            <div className="login-container-panes" style={{position: "relative"}}>
                {isRedirected &&
                <div className="login-container-redirect-message">Sign in to view that resource</div>
                }

                <div className="login-container-pane login-container-pane-hero">
                    <h4 className="login-application-name">Tetheros</h4>
                    <div className="login-application-subtitle">
                        Align your effort and impact with Tetheros           
                    </div>
                </div>

                <div className="login-container-pane login-container-pane-form">
                    <div className="login-container-form">
                        <div className="login-container-hero">Log in to your account</div>
                        {loginPage === "landing" &&
                        <div className="register-form-registration-method-buttons">
                            <div className="register-form-registration-method-button" style={{backgroundColor: "#3F73AA", border: "1px solid #3f73aa", color: "rgb(246, 246, 246)"}} onClick={registerWithLinkedIn}>
                                <div className="register-form-registration-method-button-inner" style={{justifyContent: "center"}}>
                                    <img src="https://storage.googleapis.com/tetheros-public/landing/linkedin-app-white-icon%201.svg" alt="LinkedIn logo" />
                                    <span>LinkedIn</span>
                                </div>
                                
                            </div>
                            <div style={{textAlign: "left", fontSize: "14px", color: "grey", padding: "4px"}}>
                                Or with email and password
                            </div>
                            <input type="email" onKeyDown={(e) => tryToSubmit(e.code)} className="login-container-form-text" autoFocus={true} placeholder="Email" value={emailAddress} onChange={ (e) => setEmailAddress(e.target.value) } />
                            <div className={`login-container-form-button ${isValidEmailAddress(emailAddress) ? "login-container-form-button-active" : ""}`} onClick={attemptPageNav}>Next</div>
                            <Link style={{alignSelf: "flex-start"}} className="unlink login-container-form-text-link" to='/register'>Register for an account</Link>
                        </div>
                        }


                        {loginPage === "basic" &&
                        <div>
                            <input className="login-container-form-text" onKeyDown={(e) => tryToSubmit(e.code)} type="password" htmlFor="password" autoFocus={true} placeholder="Password" value={password} onChange={ (e) => setPassword(e.target.value) } />

                            <div className={`login-container-form-button ${canSubmit ? "login-container-form-button-active" : ""}`} onClick={(e) => validateLoginInputs()}>Log in</div>

                            <div style={{display: "flex", alignItems: "center", gap: "4px"}}>
                                <div className="unlink login-container-form-text-link" onClick={attemptPageNav}>Go back</div>
                                <span>/</span>
                                <Link className="unlink login-container-form-text-link" to='/forgot-password'>Reset password</Link>
                            </div>
                        </div>
                        }

                        
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Login
