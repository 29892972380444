import React from 'react'
import { IoSearchOutline } from 'react-icons/io5';
import { BiPlus } from 'react-icons/bi'
import { RiDeleteBinLine } from 'react-icons/ri'
import { MdOutlineEdit } from 'react-icons/md'
import { useState, useRef, useEffect } from 'react';
import { hasPermission } from '../../common/Helpers';
import Api from '../../common/APIUtils';
import ProfPicCircle from '../profiles/ProfPicCircle';
import { parseISO, format, isThisMonth } from 'date-fns'

import ConfirmAction from '../modals/ConfirmAction';
import ModalAddNetworkWorkspaceUser from '../modals/ModalAddNetworkWorkspaceUser';
import ModalManageNetworkUser from '../modals/ModalManageNetworkUser';
import LoadingSymbolRipple from '../gadgets/LoadingSymbolRipple';
import SlideSelector from '../gadgets/SlideSelector';

export default function NetworkUsers({ network, fetchNetwork, permissions, networkUserData, fetchNetworkUserData, networkCapacity }) {
    const api = new Api()
    const targetRemovalId = useRef()
    const [queryText, setQueryText] = useState(() => {return ""})
    const [confirmModalText, setConfirmModalText] = useState(() => {return ""})
    const [showConfirmModal, setShowConfirmModal] = useState(() => {return false})
    const [confirmModalActionFunction, setConfirmModalActionFunction] = useState(() => {return false})
    const [selectedUserId, setSelectedUserId] = useState(() => { return false })
    const [showManageUsersModal, setShowManageUsersModal] = useState(() => {return false})
    const [showManageUserModal, setShowManageUserModal] = useState(() => {return false})
    const [sortedUsers, setSortedUsers] = useState(() => {return []})
    const [userView, setUserView] = useState(() => { return "active" })
    const removeInvitation = () => {
        setShowConfirmModal(false)
        const payload = {membership_id: targetRemovalId.current, network_id: network._id}
        if (!targetRemovalId.current) return
        api.removeNetworkInvitation(payload)
        .then((res) => {
            fetchNetwork()
        })
        .catch((error) => {
            console.log(error)
        })
    }
    const proposedInvitationRemoval = (membershipId, emailAddress) => {
        targetRemovalId.current = membershipId
        setConfirmModalText(`Remove invitation for ${emailAddress}?  They will no longer be able to invited to the ${network.name} network.`)
        setConfirmModalActionFunction(() => removeInvitation)
        setShowConfirmModal(true)
    }
    const hideModal = () => {
        fetchNetwork()
        setShowManageUserModal(false)
        setShowManageUsersModal(false)
    }
    const slideOptions = [
        {_id: "active", display_name: "Active Users"},
        {_id: "pending", display_name: "Pending Invites"},
    ]
    const hasUsers = () => {
        if (!network.memberships) return false
        if (network.memberships.length === 0) return false
        return true
    }
    const updateSortedUsers = () => {
        try {
            if (!hasUsers()) return
            const activeMemberList = Array.from(network.memberships)
            const allNetworkUsers = Array.from(network.network_users)
            const activeMemberIds = activeMemberList.map(a => a.member_id)
            let networkUsersWithActiveMemberships = allNetworkUsers.filter((user) => {
                if (activeMemberIds.includes(user._id)) return true
                return false
            })
            if (!queryText || queryText === "") return setSortedUsers(networkUsersWithActiveMemberships)
            let filteredNetworkUsers = networkUsersWithActiveMemberships.filter((user) => {
                const lowercaseQuery = queryText.toLowerCase()
                const aggregateNameAndHandle = `${user.firstName}${user.lastName}${user.handle}`
                if (aggregateNameAndHandle.toLowerCase().indexOf(lowercaseQuery.toLowerCase()) > -1) return true
                return false
            })
            setSortedUsers(filteredNetworkUsers)
        } catch (error) {
            return
        }
    }
    const removeUserFromNetwork = () => {
        setShowConfirmModal(false)
        if (!targetRemovalId.current || !network) return
        const payload = { user_email: targetRemovalId.current }
        api.deleteUserFromNetworkByEmail(payload, network.slug)
        .then((res) => {
            if (res.data) {
                fetchNetwork()
            }
        })
        .catch((err) => {
            console.log(err.response)
        })
    }
    const proposedUserNetworkRemoval = (emailAddress, userName) => {
        targetRemovalId.current = emailAddress
        setConfirmModalText(`Remove ${userName} from the network?  They will no longer have any access to the ${network.name} network, will be unassigned from all current tasks, and removed from all workspaces they're currently in.`)
        setConfirmModalActionFunction(() => removeUserFromNetwork)
        setShowConfirmModal(true)
    }
    const getUserNetworkRoleName = (user) => {
        if (!user || !network) return ""
        if (network.owner_id === user._id) return "Network Owner"
        let admins = []
        let users = []
        if (network.admin_ids) admins = network.admin_ids
        if (network.user_ids) users = network.user_ids
        if (admins.includes(user._id)) return "Network Administrator"
        if (users.includes(user._id)) return "Member"
        return ""
    }
    const selectUserToEdit = (userId) => {
        setSelectedUserId(userId)
        setShowManageUserModal(true)
    }
    const getPendingMemberships = () => {
        try {
            let allPendingMemberships = Array.from(network.pending_memberships)
            return allPendingMemberships.filter((membership) => {
                if (membership.membership_type === 'network') return true
                return false
            })
        } catch (error) {
            return []
        }
        
    }
    const getLastRelevantActivity = (userId) => {
        // Reads from network.memberships to determine if user has been active recently or not
        if (!network) return "Inactive"
        if (!network.memberships) return "Inactive"
        const userMembership = network.memberships.filter((membership) => {
            return membership.member_id === userId
        })
        if (!userMembership.length === 0) return "Inactive"
        const membership = userMembership[0] // assumed to be the first one of hopefully only one
        if (!membership) return "Inactive"
        if (!membership.last_approved_action_time) return "Inactive"
        const lastActivity = format(parseISO(membership.last_approved_action_time), 'PP')
        const isActive = isThisMonth(parseISO(membership.last_approved_action_time)) // had to occur within the current month
        return (
            <div>
                <div style={{fontWeight: "600"}}>{isActive ? 'Active' : 'Inactive'}</div>
                <div>{lastActivity}</div>
            </div>
        )
    }
    const getTeamNameFromId = (teamId) => {
        try {
            const foundTeam = network.all_teams.find(t => t._id === teamId)
            return foundTeam.name
        } catch (error) {
            return false
        }
    }
    const getWorkspaceNameFromWorkspaceId = (workspaceId) => {
        try {
            if (!workspaceId) return false
            const foundWorkspace = networkUserData.workspaces.find(w => w._id === workspaceId)
            if (!foundWorkspace) return false
            return foundWorkspace.name
        } catch (error) {
            return false
        }
    }
    const getUserWorkspaceMemberships = (userId) => {
        // Combine team memberships that offer access to workspaces with explicit workspace memberships
        try {
            const userData = networkUserData.users.find(u => u._id === userId)
            const teamMemberships = userData.team_memberships
            const teamIds = teamMemberships.map(t => t.membership_to_id)
            const userTeams = networkUserData.teams.filter((team) => {
                if (teamIds.includes(team._id)) return true
                return false
            })
            const teamWorkspaceIds = userTeams.map(t => t.workspace_ids).flat()
            const explicitWorkspaceMemberships = userData.workspace_memberships.map(w => w.membership_to_id)
            const allWorkspaceIds = [...new Set(teamWorkspaceIds.concat(explicitWorkspaceMemberships))]
            return (
                <>
                {allWorkspaceIds.map((workspaceId) => (
                    <div key={workspaceId}>{getWorkspaceNameFromWorkspaceId(workspaceId)}</div>
                ))}
                </>
            )
        } catch (error) {
            return []
        }
    }
    const getUserTeamMemberships = (userId) => {
        try {
            const userData = networkUserData.users.find(u => u._id === userId)
            const teamMemberships = userData.team_memberships
            const teamIds = teamMemberships.map(t => t.membership_to_id)
            return (
                <>
                {teamIds.map((teamId) => (
                    <div key={teamId}>{getTeamNameFromId(teamId)}</div>
                ))}
                </>
            )
        } catch (error) {
            return []
        }
    }
    const networkHasCapacity = () => {
        try {
            return networkCapacity > network.network_users.length
        } catch (error) {
            return false
        }
    }
    const getNetworkUsage = () => {
        try {
            return network.network_users.length
        } catch (error) {
            return 0
        }
    }
    useEffect(() => {
        if (!network) return
        if (!network.memberships) return
        if (!network.memberships.length === 0) return
        updateSortedUsers()
    // eslint-disable-next-line
    }, [network, queryText])
    return (
        <div className="network-hub-page">
            {showManageUsersModal && <ModalAddNetworkWorkspaceUser networkId={network._id} hideModal={hideModal} network={network} networkCapacity={networkCapacity} />}
            {showManageUserModal && selectedUserId && <ModalManageNetworkUser networkUserData={networkUserData} network={network} hideModal={hideModal} userId={selectedUserId} fetchNetwork={fetchNetworkUserData} />}
            {showConfirmModal && <ConfirmAction mainText={confirmModalText} onconfirm={confirmModalActionFunction} hideMe={() => setShowConfirmModal(false)} />}

            <div className="network-hub-page-header-row">
                <div className="modal-mgmt-select-dropdown-search" style={{maxWidth: "300px"}}>
                    <IoSearchOutline/>
                    <input type="text" placeholder="Search users..." value={queryText} onChange={(e) => setQueryText(e.target.value)} />
                </div>
                <div className="util-row util-row-reverse util-align-stretch" style={{flexBasis: "50%", gap: "20px"}}>
                    {hasPermission(permissions, 'network_admin') && networkHasCapacity() &&
                    <div className="network-page-add-workspace" onClick={() => setShowManageUsersModal(true)}>
                        <BiPlus/>
                        <span>Add users</span>
                    </div>
                    }
                    {hasPermission(permissions, 'network_admin') && !networkHasCapacity() &&
                    <div className="network-page-add-workspace network-page-add-workspace-alt">
                        <BiPlus/>
                        <span>Add users</span>
                    </div>
                    }
                    <div style={{display: "flex", flexDirection: "row-reverse"}}>
                        <SlideSelector selectedSlideOption={userView} setSlideOption={setUserView} slideOptions={slideOptions} />
                    </div>
                </div>
            </div>
            {hasPermission(permissions, "network_admin") && <div className="network-users-view-license-usage-badge"> <span>{getNetworkUsage()}/{networkCapacity} seats used</span> </div>}

            <div className="network-users-container">
                {userView === "active" &&
                <div className="network-users-view-outer">
                    <div className="network-users-active-users-table general-table-container-bordered">
                        <div className="network-users-active-users-columns network-users-active-users-columns-headers">
                            <span className="network-users-col-prof"> </span>
                            <span className="network-users-col-user">User</span>
                            <span className="network-users-col-role">Permissions</span>
                            <span className="network-users-col-teams">Team Memberships</span>
                            <span className="network-users-col-workspaces">Workspace Access</span>
                            <span className="network-users-col-status">Status</span>
                            <span className="network-users-col-actions"> </span>
                        </div>

                        {networkUserData.loaded && sortedUsers.map((user) => (
                            <NetworkUserRow selectUserToEdit={selectUserToEdit} key={user._id} user={user} getUserNetworkRoleName={getUserNetworkRoleName} getUserTeamMemberships={getUserTeamMemberships} getUserWorkspaceMemberships={getUserWorkspaceMemberships} getLastRelevantActivity={getLastRelevantActivity} proposedUserNetworkRemoval={proposedUserNetworkRemoval} permissions={permissions}/>
                        ))}

                        {!networkUserData.loaded && <div style={{display: "flex", justifyContent: "center", marginTop: "40px"}}><LoadingSymbolRipple/></div> }
                    </div>
                </div>
                }

                {userView === "pending" &&
                <div className="network-users-view-outer">
                    <div>
                        {getPendingMemberships().length > 0 &&
                        <div className="network-users-active-users-table">

                            <div className="network-users-active-users-columns network-users-active-users-columns-headers">
                                <span className="network-users-pending-col-email">Email</span>
                                <span className="network-users-pending-col-teams">Teams</span>
                                <span className="network-users-pending-col-workspaces">Workspaces</span>
                                <span className="network-users-pending-col-status">Status</span>
                                <span className="network-users-pending-col-actions"> </span>
                            </div>

                            {getPendingMemberships().map((membership) => (
                                <NetworkPendingUserRow permissions={permissions} key={membership._id} membership={membership} network={network} networkUserData={networkUserData} proposedInvitationRemoval={proposedInvitationRemoval}/>
                            ))}

                        </div>
                        }

                        {getPendingMemberships().length === 0 &&
                        <div className="modal-team-no-users-found-container">
                            <div style={{fontWeight: "600"}}>No pending network invitations</div>
                        </div>
                        }
                    </div>
                    
                </div>
                }

            </div>

        </div>
    )
}

function NetworkUserRow({user, selectUserToEdit, getUserNetworkRoleName, getUserTeamMemberships, getUserWorkspaceMemberships, getLastRelevantActivity, proposedUserNetworkRemoval, permissions}) {
    return (
        <div  className="network-users-active-users-columns">
            <span className="network-users-col-prof">
                <ProfPicCircle src={user.profile_picture_url} userId={user._id} userName={`${user.firstName} ${user.lastName}`} height={40} />
            </span>

            <span className="network-users-col-user">
                <div style={{fontSize: "16px", fontWeight: 700, lineHeight: "22px", marginBottom: "8px"}}>{user.firstName} {user.lastName}</div>
                <div>@{user.handle}</div>
            </span>

            <span className="network-users-col-role">
                {getUserNetworkRoleName(user)}
            </span>
            <span className="network-users-col-teams">
                {getUserTeamMemberships(user._id)}
            </span>

            <span className="network-users-col-workspaces">
                {getUserWorkspaceMemberships(user._id)}
            </span>

            <span className="network-users-col-status">
                {getLastRelevantActivity(user._id)}
            </span>

            <span className="network-users-col-actions">
                {hasPermission(permissions, 'network_admin') && <div className="network-users-col-action-edit" onClick={() => selectUserToEdit(user._id)}><MdOutlineEdit/></div>}
                {hasPermission(permissions, 'network_admin') && getUserNetworkRoleName(user) !== "Network Owner" && <div className="network-users-col-action-edit" onClick={() => proposedUserNetworkRemoval(user.emailAddress, `${user.firstName} ${user.lastName}`)}><RiDeleteBinLine/></div>}
            </span>
        </div>
    )
}

function NetworkPendingUserRow({membership, network, networkUserData, permissions, proposedInvitationRemoval}) {
    const userId = membership.member_id
    const userData = network.network_users.find(a => a._id === userId)
    const getTeamNameFromTeamId = (teamId) => {
        if (!teamId) return ""
        const foundTeam = networkUserData.teams.find(t => t._id === teamId)
        if (!foundTeam) return ""
        return foundTeam.name
    }
    const getWorkspaceNameFromTeamId = (workspaceId) => {
        if (!workspaceId) return ""
        const foundTeam = networkUserData.workspaces.find(w => w._id === workspaceId)
        if (!foundTeam) return ""
        return foundTeam.name
    }
    const getTeamInvitationsForUser = () => {
        const userTeamPendingMemberships = Array.from(network.pending_memberships)
        const invitedUserTeamMemberships = userTeamPendingMemberships.filter((teamMembership) => {
            if (teamMembership.member_id !== membership.member_id) return false
            if (teamMembership.membership_type === 'team') return true
            return false
        })
        if (!invitedUserTeamMemberships) return <div></div>
        return (
            <>
            {invitedUserTeamMemberships.map((teamMembership) => (
                <div key={teamMembership._id}>{getTeamNameFromTeamId(teamMembership.membership_to_id)}</div>
            ))}
            </>
        )
    }
    const getWorkspaceInvitationsForUser = () => {
        const userTeamPendingMemberships = Array.from(network.pending_memberships)
        const invitedUserWorkspaceMemberships = userTeamPendingMemberships.filter((workspaceMembership) => {
            if (workspaceMembership.member_id !== membership.member_id) return false
            if (workspaceMembership.membership_type === 'network_workspace') return true
            return false
        })
        if (!invitedUserWorkspaceMemberships) return <div></div>
        return (
            <>
            {invitedUserWorkspaceMemberships.map((workspaceMemberships) => (
                <div key={workspaceMemberships._id}>{getWorkspaceNameFromTeamId(workspaceMemberships.membership_to_id)}</div>
            ))}
            </>
        )
    }
    return (
        <div className="network-users-active-users-columns">
            <span className="network-users-pending-col-email">
                <div style={{fontSize: "16px", fontWeight: 700, lineHeight: "22px", marginBottom: "8px"}}>{userData.emailAddress}</div>
            </span>

            <span className="network-users-pending-col-teams">
                {getTeamInvitationsForUser()}
            </span>

            <span className="network-users-pending-col-workspaces">
                {getWorkspaceInvitationsForUser()}
            </span>

            <span className="network-users-pending-col-status">
                <div style={{fontWeight: 600}}>Pending</div>
                <div>{format(parseISO(membership.createdAt), 'PP')}</div>
            </span>

            <span className="network-users-pending-col-actions">
                {hasPermission(permissions, 'network_admin') && <RiDeleteBinLine className="network-team-open-manage-symbol" onClick={() => proposedInvitationRemoval(membership._id, userData.emailAddress)}/>}
            </span>
        </div>
    )
}