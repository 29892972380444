import React from 'react'
import { useState, useEffect, useRef } from 'react';

export default function ComponentBearingBadge({color, background, text, updateComponent, permissions}) {
    const bearingUpdateRef = useRef(null)
    const [isShowingBearingUpdatePicker, setIsShowingBearingUpdatePicker] = useState(() => { return false })
    const [searchTerm, setSearchTerm] = useState(() => { return "" })
    const [shownText, setShownText] = useState(() => { return text })
    const [shownColor, setShownColor] = useState(() => { return color })
    const [shownBackground, setShownBackground] = useState(() => { return background })
    const DEFAULT_COLOR = "#3a3a3a"
    const DEFAULT_BACKGROUND = "#b1b1b1"
    const colorSelections = [
        {color: "#3a3a3a", background: "#b1b1b1", name: "grey"},
        {color: "#a54141", background: "#f7dcdc", name: "red"},
        {color: "#a58241", background: "#f7ecdc", name: "blonde"},
        {color: "#4170a5", background: "#dce7f7", name: "blue"},
        {color: "#41a55c", background: "#dcf7e3", name: "green"},
        {color: "#a54198", background: "#f4dcf7", name: "purple"}
    ]
    const bearingTitles = [
        'Draft',
        'New',
        'On Track',
        'At Risk',
        'Completed'
    ]
    const attemptAdjustment = () => {
        if (!permissions.includes('component_update')) return hide()
        setIsShowingBearingUpdatePicker(!isShowingBearingUpdatePicker)
    }
    const hide = () => {
        setIsShowingBearingUpdatePicker(false)
    }
    const handleInputPress = (code) => {
        if (code !== "Enter") return
        if (searchTerm.trim() === "") return
        handleClickOnBearingText(searchTerm)
        hide()
    }
    const getBadgeStyles = () => {
        let style = {
            color: DEFAULT_COLOR,
            backgroundColor: DEFAULT_BACKGROUND
        }
        if (!permissions.includes('component_update')) style['cursor'] = 'unset'
        if (shownColor) style['color'] = shownColor
        if (shownBackground) style['backgroundColor'] = shownBackground
        return style
    }
    const getFilteredBearingTitles = () => {
        if (searchTerm === "") return bearingTitles
        return bearingTitles.filter((bearingTitle) => {
            if (searchTerm) {
                const bearingTitleLowerCase = bearingTitle.toLowerCase()
                if (bearingTitleLowerCase.indexOf(searchTerm.toLowerCase()) !== -1) return true
                return false
            }
            return true
        })
    }
    const handleClickOnBearingText = (newBearingTitle) => {
        setShownText(newBearingTitle)
        setSearchTerm("")
        updateComponent({attributes: {bearing: newBearingTitle}})
        hide()
    }
    const handleClickOnBearingColors = (newBearingColor, newBearingBackground) => {
        setShownColor(newBearingColor)
        setShownBackground(newBearingBackground)
        updateComponent({attributes: {bearing_color_background: newBearingBackground, bearing_color_text: newBearingColor}})
        // hide()
    }
    const isNewBearingName = () => {
        if (searchTerm === "") return false
        if (bearingTitles.includes(searchTerm)) return false
        return true
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (bearingUpdateRef.current && !bearingUpdateRef.current.contains(event.target)) {
                hide()
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [bearingUpdateRef])
    useEffect(() => {
        setShownText(text)
        setShownColor(color)
        setShownBackground(background)
    }, [color, background, text])
    return (
        <div className="misc-bearing-badge-container" ref={bearingUpdateRef}>

            {isShowingBearingUpdatePicker &&
            <div className="misc-bearing-badge-colors">
                {colorSelections.map((colorInfo) => (
                    <span className="misc-bearing-badge-color" key={colorInfo.background} style={{color: colorInfo.color, backgroundColor: colorInfo.background}} onClick={() => handleClickOnBearingColors(colorInfo.color, colorInfo.background)}>T</span>
                ))}
            </div>
            }

            <span onClick={attemptAdjustment} className="misc-bearing-badge" style={getBadgeStyles()}>{shownText}</span>

            {isShowingBearingUpdatePicker &&
            <div className="misc-bearing-badge-texts-container" style={{zIndex: 2}}>
                <input placeholder="Enter a status..." onKeyPress={(e) => handleInputPress(e.code)} autoFocus={true} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className="misc-bearing-badge-text-search" type="text" />
                <div className="misc-bearing-badge-texts">
                    {getFilteredBearingTitles().map((title) => (
                        <span className="misc-bearing-badge-text" key={title} onClick={() => handleClickOnBearingText(title)}>{title}</span>
                    ))}
                    {isNewBearingName() && <span onClick={() => handleClickOnBearingText(searchTerm)} className="misc-bearing-badge-text">{searchTerm} (create)</span> }
                </div>
            </div>
            }

        </div>
    )
}
