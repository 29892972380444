import React from 'react'
import SidebarNetworkInfo from './SidebarNetworkInfo'
import { useState, useEffect } from 'react'
import SidebarSection from './SidebarSection';
import { Link } from 'react-router-dom';
import { BiTargetLock, BiAnchor } from 'react-icons/bi'
import { useHistory } from 'react-router-dom';
import { hasPermission } from '../../common/Helpers';

export default function SidebarNetwork({network, setSidebarCollapsed, components, permissions, networkWorkspaces}) {
    const [sidebar, setSidebar] = useState(() => {
        if (localStorage.getItem("gNetworkSidebar")) {
            return JSON.parse(localStorage.getItem("gNetworkSidebar"));
        } else {
            return {
                selected: "none",
                sections: []
            }
        } 
    })
    const history = useHistory();
    const hasGoals = () => {
        if (!components) return false
        if (!components.goal) return false
        return true
    }
    const hasInitiatives = () => {
        if (!components) return false
        if (!components.initiative) return false
        return true
    }
    const goToPage = (url) => {
        history.push(url)
    }
    const updateSidebar = () => {
        // Order of this data matters!
        const updatedSidebar = {
            selected: "none",
            sections: []
        }

        const workspacesSection = {
            key: "sidebar-workspaces",
            title: "Workspaces",
            symbol: "workspaces",
            items: []
        }

        if (networkWorkspaces) {
            for (let i = 0; i < networkWorkspaces.length; i++) {
                const workspace = networkWorkspaces[i];
                workspacesSection.items.push({
                    key: workspace._id,
                    link: `/workspaces/${workspace._id}`,
                    title: workspace.name
                })
            }
            updatedSidebar.sections.push(workspacesSection)
        }
        
        // The theory goes that this may save on performance when there are a lot of workspaces to sort through
        localStorage.setItem("gNetworkSidebar", JSON.stringify(updatedSidebar))
    }
    useEffect(() => {
        updateSidebar();
        setSidebar(JSON.parse(localStorage.getItem("gNetworkSidebar")));
    // eslint-disable-next-line
    },[networkWorkspaces])
    return (
        <>
        <SidebarNetworkInfo setSidebarCollapsed={setSidebarCollapsed} network={network} permissions={permissions}/>
        {hasPermission(permissions, 'network_user') && (
            <div className="center-children-row-vert sidebar-left-section-workspace-main">
                <Link to={`/n/${network.slug}/catalog`} className="sidebar-button-new-item unlink">Build new item</Link>
            </div>
        )}

        {sidebar.sections.map((section) => (
            <SidebarSection key={section.key} sectionData={section}/>
        ))}

        {/* Projects */}
        {hasInitiatives() && (
            <div className="sidebar-left-section" onClick={() => goToPage(`/n/${network.slug}/components?type=initiative`)}>
            {/* Section Header */}
            <div className="sidebar-left-section-heading sidebar-left-section-heading-hoverable">
                <div className="sidebar-left-section-heading-left" >
                    <div className="sidebar-left-section-heading-icon">
                        <BiAnchor />
                    </div>
                    <div className="sidebar-left-section-heading-text">Projects</div>
                </div>
            </div>
        </div>
        )}

        {/* GOALS */}
        {hasGoals() && (
            <div className="sidebar-left-section" onClick={() => goToPage(`/n/${network.slug}/components?type=goal`)}>
                {/* Section Header */}
                <div className="sidebar-left-section-heading sidebar-left-section-heading-hoverable">
                    <div className="sidebar-left-section-heading-left">
                        <div className="sidebar-left-section-heading-icon">
                            <BiTargetLock />
                        </div>
                        <div className="sidebar-left-section-heading-text">Goals</div>
                    </div>
                </div>
            </div>
        )}
        </>
    )
}
